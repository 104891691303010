/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SyoruiConfirmationSyoruis,
    SyoruiConfirmationSyoruisFromJSON,
    SyoruiConfirmationSyoruisFromJSONTyped,
    SyoruiConfirmationSyoruisToJSON,
    SyoruiConfirmationYoryos,
    SyoruiConfirmationYoryosFromJSON,
    SyoruiConfirmationYoryosFromJSONTyped,
    SyoruiConfirmationYoryosToJSON,
} from './';

/**
 * 
 * @export
 * @interface SyoruiConfirmationMember
 */
export interface SyoruiConfirmationMember {
    /**
     * ZokugaraEnum
     * @type {string}
     * @memberof SyoruiConfirmationMember
     */
    zokugara: string;
    /**
     * 
     * @type {string}
     * @memberof SyoruiConfirmationMember
     */
    zokugaraOther: string;
    /**
     * 
     * @type {string}
     * @memberof SyoruiConfirmationMember
     */
    name: string;
    /**
     * 
     * @type {Array<SyoruiConfirmationYoryos>}
     * @memberof SyoruiConfirmationMember
     */
    yoryos: Array<SyoruiConfirmationYoryos>;
    /**
     * 
     * @type {Array<SyoruiConfirmationSyoruis>}
     * @memberof SyoruiConfirmationMember
     */
    syoruis?: Array<SyoruiConfirmationSyoruis>;
}

export function SyoruiConfirmationMemberFromJSON(json: any): SyoruiConfirmationMember {
    return SyoruiConfirmationMemberFromJSONTyped(json, false);
}

export function SyoruiConfirmationMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyoruiConfirmationMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zokugara': json['zokugara'],
        'zokugaraOther': json['zokugaraOther'],
        'name': json['name'],
        'yoryos': ((json['yoryos'] as Array<any>).map(SyoruiConfirmationYoryosFromJSON)),
        'syoruis': !exists(json, 'syoruis') ? undefined : ((json['syoruis'] as Array<any>).map(SyoruiConfirmationSyoruisFromJSON)),
    };
}

export function SyoruiConfirmationMemberToJSON(value?: SyoruiConfirmationMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zokugara': value.zokugara,
        'zokugaraOther': value.zokugaraOther,
        'name': value.name,
        'yoryos': ((value.yoryos as Array<any>).map(SyoruiConfirmationYoryosToJSON)),
        'syoruis': value.syoruis === undefined ? undefined : ((value.syoruis as Array<any>).map(SyoruiConfirmationSyoruisToJSON)),
    };
}


