export enum KikanTypeEnum {
    入力期間 = "入力期間",
    申請期間 = "申請期間",
    訂正期間 = "訂正期間",
}

export function kikanTypeEnumToName(e: KikanTypeEnum): { ja: string, en: string } {
    switch (e) {
        case KikanTypeEnum.入力期間: return { ja: "入力期間", en: "Data Entry Period" };
        case KikanTypeEnum.申請期間: return { ja: "申請期間", en: "Application Period" };
        case KikanTypeEnum.訂正期間: return { ja: "訂正期間", en: "Correction Period" };
    }
}