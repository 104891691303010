import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum NyugakuryoInfoLabelTextEnum {
    免除または徴収猶予の理由 = "免除または徴収猶予の理由",
}

export function NyugakuryoInfoLabelTextEnumToNameJaAndEn(e: NyugakuryoInfoLabelTextEnum): string {
    switch (e) {
        case NyugakuryoInfoLabelTextEnum.免除または徴収猶予の理由:
            return convertEnumNameToStr({ja: "免除または徴収猶予の理由", en: "Reason for Waiver/Deferral"});
        default :
            return ""
    }
}