/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSyunyuInfo,
    SwSyunyuInfoFromJSON,
    SwSyunyuInfoFromJSONTyped,
    SwSyunyuInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwMemberSyugakusyaIgai
 */
export interface SwMemberSyugakusyaIgai {
    /**
     * 
     * @type {string}
     * @memberof SwMemberSyugakusyaIgai
     */
    jobName?: string;
    /**
     * 
     * @type {number}
     * @memberof SwMemberSyugakusyaIgai
     */
    jobYears?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SwMemberSyugakusyaIgai
     */
    syunyuAriNasi?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwMemberSyugakusyaIgai
     */
    kakuteisinkokuAriNasi?: boolean;
    /**
     * 
     * @type {SwSyunyuInfo}
     * @memberof SwMemberSyugakusyaIgai
     */
    syunyuInfo: SwSyunyuInfo;
}

export function SwMemberSyugakusyaIgaiFromJSON(json: any): SwMemberSyugakusyaIgai {
    return SwMemberSyugakusyaIgaiFromJSONTyped(json, false);
}

export function SwMemberSyugakusyaIgaiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwMemberSyugakusyaIgai {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobName': !exists(json, 'jobName') ? undefined : json['jobName'],
        'jobYears': !exists(json, 'jobYears') ? undefined : json['jobYears'],
        'syunyuAriNasi': !exists(json, 'syunyuAriNasi') ? undefined : json['syunyuAriNasi'],
        'kakuteisinkokuAriNasi': !exists(json, 'kakuteisinkokuAriNasi') ? undefined : json['kakuteisinkokuAriNasi'],
        'syunyuInfo': SwSyunyuInfoFromJSON(json['syunyuInfo']),
    };
}

export function SwMemberSyugakusyaIgaiToJSON(value?: SwMemberSyugakusyaIgai | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobName': value.jobName,
        'jobYears': value.jobYears,
        'syunyuAriNasi': value.syunyuAriNasi,
        'kakuteisinkokuAriNasi': value.kakuteisinkokuAriNasi,
        'syunyuInfo': SwSyunyuInfoToJSON(value.syunyuInfo),
    };
}


