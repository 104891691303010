import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {JugyoryoInfoVo} from "common/models/YosikiInfoModels";
import {JugyoryoInfoLabelTextEnum, JugyoryoInfoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/JugyoryoInfoLabelTextEnum";
import {JugyoryoInfoTextEnum, JugyoryoInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/JugyoryoInfoTextEnum";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {Title04} from "components/atoms/titles/Title04";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {FC} from "react";

interface JugyoryoInfoDetailSectionProps {
    className?: string
    jugyoryoInfo: JugyoryoInfoVo;
}

export const _JugyoryoInfoDetailSection: FC<JugyoryoInfoDetailSectionProps> = (props) => {

    const classes = useStyles();

    const jugyoryoInfo: JugyoryoInfoVo = props.jugyoryoInfo;

    return (
        <div id={SECTION_ID_NAME.jugyoryoInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.授業料免除徴収猶予申請理由)}
                className={"section_title"}
            />

            <FormTable
                className={"section_table"}
            >
                <FormTableRow
                    label={JugyoryoInfoLabelTextEnumToNameJaAndEn(JugyoryoInfoLabelTextEnum.免除または徴収猶予の理由)}
                >
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {JugyoryoInfoTextEnumToNameJaAndEn(JugyoryoInfoTextEnum.どういう原因で)}
                        </div>
                        <div className={"preWrap"}>
                            {jugyoryoInfo.riyu}
                        </div>
                    </div>
                </FormTableRow>

            </FormTable>

        </div>

    )
};

export const JugyoryoInfoDetailSection = React.memo<JugyoryoInfoDetailSectionProps>(_JugyoryoInfoDetailSection, (prev, next) => {
    return prev.className === next.className
        && prev.jugyoryoInfo === next.jugyoryoInfo
});

const useStyles = makeStyles({
        root: {},
    }
);

