import {RequiredSyoruisStoreVo} from "common/models/SyoruisModels";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import React, {FC} from "react";
import {StudentMainArea} from "../../atoms/StudentMainArea";
import {Box} from "@material-ui/core";
import {Title02} from "../../atoms/titles/Title02";
import {SyoruiConfirmation} from "../../molecules/summarySyoruiSection/SyoruiConfirmation";
import {SyoruiLinkList} from "../../molecules/summarySyoruiSection/SyoruiLinkList";
import Grid from "@material-ui/core/Grid";
import {YosikiStateEnum} from "../../../common/enums/YosikiStateEnum";

interface Props {
    studentInfo: StudentInfoStoreVo
    requiedSyoruis: RequiredSyoruisStoreVo
    handleClickSyoruiConfirmationForm: () => void
    handleClickSyoruiConfirmation: () => void
}

export const SummarySyoruiSection: FC<Props> = ({
                                                    studentInfo,
                                                    requiedSyoruis,
                                                    handleClickSyoruiConfirmationForm,
                                                    handleClickSyoruiConfirmation
                                                }) => {

    const isKakutei: boolean = studentInfo.yosikiState === YosikiStateEnum.確定;

    return (
        <Box>

            <Title02
                title={"各種証明書類(様式2〜13)の書類作成 / Preparation of various certification documents (Forms 2 to 13)"}
            />

            <StudentMainArea mt={2}>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Box>
                            授業料免除願・家庭状況調書(様式1)の申告内容について証明する書類です。<br/>
                            Documents certifying the contents of the declaration on the Application for Tuition
                            Exemption and Statement of Family Situation (Form 1).
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <SyoruiConfirmation
                            studentInfo={studentInfo}
                            isKakutei={isKakutei}
                            handleClickSyoruiConfirmationForm={handleClickSyoruiConfirmationForm}
                            handleClickSyoruiConfirmation={handleClickSyoruiConfirmation}
                        />
                    </Grid>

                    {
                        isKakutei && (
                            <Grid item xs={12}>
                                <SyoruiLinkList
                                    studentInfo={studentInfo}
                                    requiedSyoruis={requiedSyoruis}
                                />
                            </Grid>
                        )
                    }

                </Grid>

            </StudentMainArea>

        </Box>

    )
};

