import {announceCommonStore, AnnounceCommonStoreVo} from "stores/common/AnnounceCommonStore";
import {Announce} from "components/organisms/utilities/Announce";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import React from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import {SyoruiConfirmation} from "../../../components/molecules/syoruiConfirmation/SyoruiConfirmation";
import {SyoruiConfirmationStoreVo} from "../../../common/models/SyoruiConfirmationModels";
import {studentSyoruiConfirmationStore} from "./stores/StudentSyoruiConfirmationStore";
import {URL_PATHS_STUDENT} from "../../../common/constant/URL_PATHS_STUDENT";

const _SyoruiConfirmationPage: React.FC<RouteComponentProps<{ studentNumber: string }>> = () => {

    // NOTE: storeデータ
    const [
        syoruiConfirmationStoreState,
        updateSyoruiConfirmationStoreState
    ] = React.useState<SyoruiConfirmationStoreVo>(studentSyoruiConfirmationStore.currentState);

    // NOTE: storeデータ
    const [
        announceCommon,
        updateAnnounceCommon
    ] = React.useState<AnnounceCommonStoreVo>(announceCommonStore.currentState);

    React.useEffect(() => {
        // NOTE: 初回取得
        studentSyoruiConfirmationStore.getSyoruiConfirmation().then()
        // NOTE: 取得後にstateを更新
        return studentSyoruiConfirmationStore.listen(updateSyoruiConfirmationStoreState);
    }, []);

    React.useEffect(() => {
        return announceCommonStore.listen(updateAnnounceCommon);
    }, []);

    // NOTE: 戻る
    function handleClickBackButton() {
        window.location.assign(`${URL_PATHS_STUDENT.top}`);
    }

    return (

        <>

            <Announce
                announceCommon={announceCommon}
                updateAnnounceCommon={(value) => announceCommonStore.updateAnnounceCommon(value)}
            />

            <HeaderStudent/>

            <SyoruiConfirmation
                syoruiConfirmation={syoruiConfirmationStoreState.syoruiConfirmation}
                handleClickBackButton={handleClickBackButton}
            />

        </>

    );
};

export const SyoruiConfirmationPage = withRouter(_SyoruiConfirmationPage);

