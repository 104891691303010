/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * isSienzumi が true の場合のみ値を持つ
 * @export
 * @interface SwSienzumi
 */
export interface SwSienzumi {
    /**
     * 
     * @type {string}
     * @memberof SwSienzumi
     */
    schoolName: string;
    /**
     * 
     * @type {number}
     * @memberof SwSienzumi
     */
    startYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSienzumi
     */
    startMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSienzumi
     */
    endYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSienzumi
     */
    endMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSienzumi
     */
    months?: number;
}

export function SwSienzumiFromJSON(json: any): SwSienzumi {
    return SwSienzumiFromJSONTyped(json, false);
}

export function SwSienzumiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSienzumi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schoolName': json['schoolName'],
        'startYear': !exists(json, 'startYear') ? undefined : json['startYear'],
        'startMonth': !exists(json, 'startMonth') ? undefined : json['startMonth'],
        'endYear': !exists(json, 'endYear') ? undefined : json['endYear'],
        'endMonth': !exists(json, 'endMonth') ? undefined : json['endMonth'],
        'months': !exists(json, 'months') ? undefined : json['months'],
    };
}

export function SwSienzumiToJSON(value?: SwSienzumi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schoolName': value.schoolName,
        'startYear': value.startYear,
        'startMonth': value.startMonth,
        'endYear': value.endYear,
        'endMonth': value.endMonth,
        'months': value.months,
    };
}


