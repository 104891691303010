import React from 'react';

export const ModalContentHigaiGakuRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                火災・風水害・盗難等の被害
                を受けた世帯の被害額は、確定
                申告書(控)の雑損控除額等を
                記入すること。
            </div>

            <div className={"modalContent_item"}>
                For the damage amounts of households which havebeen affected by fires, floods, storms, or other natural
                disasters, or by theft, enter the casuaty loss deduction amount from the Tax Return(Taxpayer’s Copy).
            </div>

        </div>

    );
};
