/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InlineResponse2001Warn,
    InlineResponse2001WarnFromJSON,
    InlineResponse2001WarnFromJSONTyped,
    InlineResponse2001WarnToJSON,
    SwPostCsvErrorMessageError,
    SwPostCsvErrorMessageErrorFromJSON,
    SwPostCsvErrorMessageErrorFromJSONTyped,
    SwPostCsvErrorMessageErrorToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2001
     */
    isSuccess: boolean;
    /**
     * 
     * @type {Array<SwPostCsvErrorMessageError>}
     * @memberof InlineResponse2001
     */
    error?: Array<SwPostCsvErrorMessageError>;
    /**
     * 
     * @type {InlineResponse2001Warn}
     * @memberof InlineResponse2001
     */
    warn?: InlineResponse2001Warn;
}

export function InlineResponse2001FromJSON(json: any): InlineResponse2001 {
    return InlineResponse2001FromJSONTyped(json, false);
}

export function InlineResponse2001FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2001 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSuccess': json['isSuccess'],
        'error': !exists(json, 'error') ? undefined : ((json['error'] as Array<any>).map(SwPostCsvErrorMessageErrorFromJSON)),
        'warn': !exists(json, 'warn') ? undefined : InlineResponse2001WarnFromJSON(json['warn']),
    };
}

export function InlineResponse2001ToJSON(value?: InlineResponse2001 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSuccess': value.isSuccess,
        'error': value.error === undefined ? undefined : ((value.error as Array<any>).map(SwPostCsvErrorMessageErrorToJSON)),
        'warn': InlineResponse2001WarnToJSON(value.warn),
    };
}


