import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {ZokugaraEnum} from "common/enums/ZokugaraEnum";
import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {FamilyInfoVo, MemberVo, TokubetuKojoInfoVo, TyokiryoyoVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {Button} from "components/atoms/Button";
import {Selectbox} from "components/atoms/forms/Selectbox";
import {Modal} from "components/atoms/Modal";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {TokubetuKojoNoMemberModalContent} from "components/molecules/yosikiEdit/tokubetu_kojo/TokubetuKojoNoMemberModalContent";
import React, {useState} from 'react';
import {convertStrToNumOrUndefined} from "common/functions/type_converter/convertStrToNumOrUndefined";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface YosikiAddTyokiryoyoZokugaraModalProps {
    openState: boolean
    studentNumber: string
    tokubetuKojoInfo: TokubetuKojoInfoVo;
    updateTokubetuKojoInfo: (part: Partial<TokubetuKojoInfoVo>) => void
    familyInfo: FamilyInfoVo
    updateAddTyokiryoyoZokugaraModalOpenState: (value: boolean) => void
}

export const YosikiAddTyokiryoyoZokugaraModal: React.FC<YosikiAddTyokiryoyoZokugaraModalProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const [selectedIndex, updateSelectedIndex] = useState<number | undefined>(undefined);

    let members: Array<MemberVo> = props.familyInfo.members;

    React.useEffect(() => {
        updateSelectedIndex(members.length > 0 ? 0 : undefined)
    }, [props.openState, members]);

    function handleClickClose() {
        props.updateAddTyokiryoyoZokugaraModalOpenState(false)
    }

    // 「その他」以外の続柄の重複を削除する。
    let zokugaras: Array<ZokugaraEnum> = [];
    let filteredMember: Array<MemberVo> = [];
    members.forEach((m: MemberVo) => {
        if(m.zokugara === ZokugaraEnum.その他){
            filteredMember.push(m);
        } else if (zokugaras.indexOf(m.zokugara) === -1) {
            filteredMember.push(m);
            zokugaras.push(m.zokugara);
        }
    });

    let options: Array<React.ReactNode> = [];
    filteredMember.forEach((m: MemberVo, index: number) => {
        options.push(
            <option
                key={m.zokugara + index}
                value={index}
            >
                {convertZokugaraForStr(m.zokugara, m.zokugaraOther)}
            </option>
        )
    });

    function handleChangeSelectbox(index: string) {
        updateSelectedIndex(convertStrToNumOrUndefined(index))
    }

    function addTyokiryoyoZokugara(value: TyokiryoyoVo) {
        let tyokiryoyo: Array<TyokiryoyoVo> = props.tokubetuKojoInfo.tyokiryoyo;
        tyokiryoyo.push(value);
        const tokubetuKojoInfo: TokubetuKojoInfoVo = {
            ...props.tokubetuKojoInfo,
            tyokiryoyo: tyokiryoyo
        };
        props.updateTokubetuKojoInfo(tokubetuKojoInfo)
    }

    async function handleClickComfirm() {
        if(selectedIndex === undefined) return;
        await addTyokiryoyoZokugara(
            {
                zokugara: filteredMember[selectedIndex].zokugara as ZokugaraEnum,
                zokugaraOther: filteredMember[selectedIndex].zokugaraOther,
                type: undefined,
                startYear: "",
                startMonth: "",
                amountPerHalfYear: "",
            }
        );
        props.updateAddTyokiryoyoZokugaraModalOpenState(false)
    }

    return (

        <Modal
            openState={props.openState}
            title={CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を追加)}
        >

            {
                members.length === 0 ?
                    <TokubetuKojoNoMemberModalContent
                        handleClickClose={handleClickClose}
                    />
                    : <>

                        <FormTable>

                            <FormTableRow
                                label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.続柄)}
                                requiredType={RequiredTagTypeEnum.必須}
                            >

                                <div className={"formTableRow_item"}>

                                    <div className={`${classes.row} ${xs ? classes.xsRow : ""}`}>

                                        <Selectbox
                                            value={selectedIndex || ""}
                                            isDisabled={false}
                                            // isError={ERROR_ARRAY.length !== 0}
                                            handleChange={handleChangeSelectbox}
                                        >
                                            {options}
                                        </Selectbox>

                                    </div>

                                </div>

                            </FormTableRow>

                        </FormTable>

                        <DialogActions className={"modal_actions"}>
                            <Button
                                type={"default"}
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.キャンセル)}
                                width={"152px"}
                                handleClick={handleClickClose}
                            />
                            <Button
                                type={"primary"}
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                                width={"120px"}
                                handleClick={handleClickComfirm}
                            />
                        </DialogActions>
                    </>
            }

        </Modal>

    );
};

const useStyles = makeStyles({
        row: {
            display: "flex",
            width: "100%",
            "& > *:not(:first-child)": {
                marginLeft: 4
            }
        },
        xsRow: {
            display: "block",
            "& > *:not(:first-child)": {
                marginLeft: 0
            }
        }
    }
);
