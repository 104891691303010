export function scrollToErrorBasicInfoModal() {
    const basicInfoModal = document.getElementById("basicInfoModal");
    const table = document.getElementById("basicInfoModal_table");
    const modalContent = basicInfoModal?.getElementsByClassName("MuiPaper-root")[0];
    const elements: HTMLCollectionOf<Element> | undefined = basicInfoModal?.getElementsByClassName("isActiveErrorMessage");
    if (modalContent === undefined || elements === undefined || table === null) return;
    const modalContentHeght = modalContent.clientHeight;
    const buffer = 80;
    const positions: Array<number> = [];
    for (let i = 0; i < elements.length; i++) {
        positions.push(table.offsetHeight + elements[i].getBoundingClientRect().top - modalContentHeght - buffer);
    }
    const sorted = positions.sort((a: any, b: any) => {
        return a - b;
    });
    modalContent?.scrollTo({
        top: sorted[0],
        behavior: "smooth"
    });
}