import {Store} from "stores/Store";

export interface AnnounceCommonStoreVo {
    announce: AnnounceCommonVo;
}

export interface AnnounceCommonVo {
    openState: boolean;
    text: string;
}

class AnnounceCommonStore extends Store<AnnounceCommonStoreVo> {

    constructor() {
        super({
            announce: {
                openState: false,
                text: ""
            }
        })
    }

    // Form のアップデート
    updateAnnounceCommon(bool: boolean, text: string = "") {
        this.dispatch(() => ({
            announce: {
                openState: bool,
                text: text
            }
        }));
    }

}

export const announceCommonStore = new AnnounceCommonStore();