import React from 'react';
import {Box} from "@material-ui/core";
import {SwSyoruiConfirmationFormErrorMessageResponse} from "../../../openapi";
import {SyoruiConfirmationFormVo} from "../../../common/models/SyoruiConfirmationFormModels";
import {SinseisyaForm} from "./forms/SinseisyaForm";
import {SinseisyaRyugakuseiForm} from "./forms/SinseisyaRyugakuseiForm";
import Grid from "@material-ui/core/Grid";
import {MemberForm} from "./forms/MemberForm";
import {Title02} from "../../atoms/titles/Title02";


interface Props {
    formValues: SyoruiConfirmationFormVo | undefined
    errorMessages: SwSyoruiConfirmationFormErrorMessageResponse | undefined
    updates: (formValues: SyoruiConfirmationFormVo, errorMessages?: SwSyoruiConfirmationFormErrorMessageResponse | undefined) => void
    isSyoruiHantei: boolean
    isRyugakusei: boolean
    isJugyoryo: boolean
    isNyugakuryo: boolean
}

export const FormComponent: React.FC<Props> = ({
                                                   formValues,
                                                   errorMessages,
                                                   updates,
                                                   isSyoruiHantei,
                                                   isRyugakusei,
                                                   isJugyoryo,
                                                   isNyugakuryo
                                               }) => {

    if (formValues === undefined) return <></>

    return (

        <Box>

            <Title02
                title={"申請に必要な様式等の確認機能（入力） / Confirmation Function for Forms, etc. Requiring Application (Input)"}
            />

            <Box mt={3}>
                申請に必要な様式等を提示することができます。※提示内容は参考ですので、提示した以外の資料等が必要となる場合があります。<br/>
                すべての項目を入力し、「判定」を行うことで必要証明書類の一覧を確認することができます。<br/>
                The forms, etc. requiring application can be presented. *The information provided is for reference
                only,
                and other materials may be necessary.<br/>
                After entering all items, you can confirm the list of required certification documents by clicking
                "Determine".
            </Box>

            <Box mt={6}>

                <Grid container spacing={6} direction={"column"}>

                    { isRyugakusei ? <></> :
                    <Grid item xs>
                        <SinseisyaForm
                            formValues={formValues}
                            errorMessages={errorMessages}
                            updates={updates}
                            isSyoruiHantei={isSyoruiHantei}
                        />
                    </Grid>
                    }

                    { !isRyugakusei ? <></> :
                    <Grid item xs>
                        <SinseisyaRyugakuseiForm
                            formValues={formValues}
                            errorMessages={errorMessages}
                            updates={updates}
                            isSyoruiHantei={isSyoruiHantei}
                        />
                    </Grid>
                    }

                    {formValues.members.map((m, i) => {
                        return (
                            <Grid item xs key={i}>
                                <MemberForm
                                    index={i}
                                    member={m}
                                    formValues={formValues}
                                    errorMessages={errorMessages}
                                    updates={updates}
                                    isSyoruiHantei={isSyoruiHantei}
                                    isNyugakuryo={isNyugakuryo}
                                    isJugyoryo={isJugyoryo}
                                />
                            </Grid>
                        )
                    })}

                </Grid>

            </Box>

        </Box>

    );
};
