/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSyogakukinErrorMessages,
    SwSyogakukinErrorMessagesFromJSON,
    SwSyogakukinErrorMessagesFromJSONTyped,
    SwSyogakukinErrorMessagesToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSyogakukinInfoErrorMessage
 */
export interface SwSyogakukinInfoErrorMessage {
    /**
     * 
     * @type {Array<SwSyogakukinErrorMessages>}
     * @memberof SwSyogakukinInfoErrorMessage
     */
    syogakukins?: Array<SwSyogakukinErrorMessages>;
}

export function SwSyogakukinInfoErrorMessageFromJSON(json: any): SwSyogakukinInfoErrorMessage {
    return SwSyogakukinInfoErrorMessageFromJSONTyped(json, false);
}

export function SwSyogakukinInfoErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyogakukinInfoErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syogakukins': !exists(json, 'syogakukins') ? undefined : ((json['syogakukins'] as Array<any>).map(SwSyogakukinErrorMessagesFromJSON)),
    };
}

export function SwSyogakukinInfoErrorMessageToJSON(value?: SwSyogakukinInfoErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'syogakukins': value.syogakukins === undefined ? undefined : ((value.syogakukins as Array<any>).map(SwSyogakukinErrorMessagesToJSON)),
    };
}


