import {YosikiInfoResponseVo} from "common/models/YosikiInfoModels";
import {YosikiMastersVo} from "common/models/YosikiMastersVo";
import {SwYosikiInfoErrorMessageResponse} from "openapi/models";

export interface YosikiInfoStoreVo {
    yosikiInfo?: YosikiInfoResponseVo
    originalYosikiInfo?: YosikiInfoResponseVo
    yosikiInfoErrorMessages: SwYosikiInfoErrorMessageResponse
    commonYosikiMasters: YosikiMastersVo
}

export const INIT_YOSIKI_INFO_ERROR_MESSAGES: SwYosikiInfoErrorMessageResponse = {
    sinseisyaInfoErrorMessage: {
        kyugaku: {},
        address: {},
        addressFamily: {},
    },
    seisekiInfoErrorMessage: {},
    syogakukinInfoErrorMessage: {},
    familyInfoErrorMessage: {
        honnin: {},
        members: [],
    },
    nyugakuryoInfoErrorMessage: {},
    jugyoryoInfoErrorMessage: {},
    tokubetuKojoInfoErrorMessage: {
        bosiHusi: {},
        syogaisya: {},
        tyokiryoyo: {},
        bekkyo: {},
        saigaihigai: {},
    },
};
