import {MemberSyugakusyaVo} from "../../models/YosikiInfoModels";
import {SettiKubunEnum} from "../../enums/SettiKubunEnum";
import {SchoolTypeEnum} from "../../enums/SchoolTypeEnum";

// NOTE: 授業料免除状況の入力が必要な就学者か判定
export function canInputJugyoryo(syugakusya: MemberSyugakusyaVo, isRyugakusei: boolean) {
    // NOTE: 未選択は対象外
    if(!syugakusya.schoolType) return false
    // NOTE: 設置区分「国立」以外は対象外
    if(syugakusya.settiKubun !== SettiKubunEnum.国立) return false
    // NOTE: 「小学校」と「中学校」は無償なので対象外
    if(syugakusya.schoolType === SchoolTypeEnum.小学校 || syugakusya.schoolType === SchoolTypeEnum.中学校) return false
    // NOTE: 申請者が留学生以外の場合は、「大学(学部)」は対象外
    if (syugakusya.schoolType === SchoolTypeEnum.大学_学部 && !isRyugakusei ) return false
    return true
}