import React from 'react';
import {Title04} from "../../../atoms/titles/Title04";
import Grid from "@material-ui/core/Grid";
import {YoryoItem} from "../YoryoItem";
import {SyoruiConfirmationHonninVo} from "../../../../common/models/SyoruiConfirmationModels";

interface Props {
    honnin: SyoruiConfirmationHonninVo;
}

export const SinseisyaYoryos: React.FC<Props> = ({
                                                      honnin,
                                                  }) => {

    if (honnin.yoryos.length < 1) {
        return <></>
    }

    return (
        <Grid container spacing={3} direction={"column"}>

            <Grid item xs>
                <Title04
                    title={"申請者 / Applicant"}
                />
            </Grid>

            <Grid item xs>

                {
                    honnin.yoryos.map((h, i) => {
                        return (
                            <YoryoItem
                                key={i}
                                index={i}
                                ja={h.ja}
                                en={h.en}
                            />
                        )
                    })
                }

            </Grid>

            <Grid item xs>

                {/* NOTE: 改修2 - 7 で開発予定 */}
                {/*<SyoruiTable syoruis={honnin.syoruis} />*/}

            </Grid>

        </Grid>
    )
}

