import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {SwAYosikiFormErrorMessageResponse, SwAYosikiFormResponse} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";
import {
    StudentAYosikiFormApi,
    StudentAYosikiFormKakuteiApi,
    StudentAYosikiFormKakuteiKaijoApi
} from "../../../../openapi";
import {AYosikiFormStoreVo, AYosikiFormVo} from "../../../../common/models/AYosikiFormModels";
import {convertNumToStr} from "../../../../common/functions/type_converter/convertNumToStr";
import {convertStrToNumOrUndefined} from "../../../../common/functions/type_converter/convertStrToNumOrUndefined";
import {MosikomiTypeEnum} from "../../../../common/enums/MosikomiTypeEnum";

class StudentAYosikiFormStore extends Store<AYosikiFormStoreVo> {

    // NOTE: 使用api
    private studentAYosikiFormApi = new StudentAYosikiFormApi(apiConfiguration);
    private studentAYosikiFormKakuteiApi = new StudentAYosikiFormKakuteiApi(apiConfiguration)
    private studentAYosikiFormKakuteiKaijoApi = new StudentAYosikiFormKakuteiKaijoApi(apiConfiguration)

    constructor() {
        super({})
    }

    // NOTE: エラーメッセージの更新
    async updateAYosikiFormErrorMessages(errorMessage: SwAYosikiFormErrorMessageResponse | undefined) {
        this.dispatch(() => ({aYosikiFormErrorMessage: errorMessage}));
    }

    // NOTE: フォーム情報更新
    async updateAYosikiForm(vo: AYosikiFormVo) {
        this.dispatch(() => ({aYosikiForm: vo}));
    }

    // NOTE: フォーム情報習得
    async getAYosikiForm() {
        try {
            const res: SwAYosikiFormResponse = await this.studentAYosikiFormApi.getStudentAYosikiForm();
            const vo: AYosikiFormVo = {
                common: {
                    ...res.common,
                    birthYear: convertNumToStr(res.common.birthYear),
                    birthMonth: convertNumToStr(res.common.birthMonth),
                    birthDay: convertNumToStr(res.common.birthDay),
                    gakunen: convertNumToStr(res.common.gakunen),
                    address: {
                        ...res.common.address
                    },
                    nihonGakuseiSienKiko: {
                        ...res.common.nihonGakuseiSienKiko
                    },
                    bessi1: {
                        ...res.common.bessi1
                    },
                    bessi2: {
                        ...res.common.bessi2
                    },
                },
                aYosiki1: {
                    ...res.aYosiki1,
                    sienzumi: {
                        schoolName: res.aYosiki1.sienzumi?.schoolName || "",
                        startYear: convertNumToStr(res.aYosiki1.sienzumi?.startYear),
                        startMonth: convertNumToStr(res.aYosiki1.sienzumi?.startMonth),
                        endYear: convertNumToStr(res.aYosiki1.sienzumi?.endYear),
                        endMonth: convertNumToStr(res.aYosiki1.sienzumi?.endMonth),
                        months: convertNumToStr(res.aYosiki1.sienzumi?.months),
                    },
                    mosikomiType: res.aYosiki1.mosikomiType as MosikomiTypeEnum || "",
                },
                aYosiki2: {
                    ...res.aYosiki2,
                }
            };
            await this.updateAYosikiFormErrorMessages(undefined);
            this.updateAYosikiForm(vo).then();
        } catch (res) {
            handleStudentError(res)
        }
    }

    // NOTE: 保存
    async postAYosikiForm() {
        const form = this.currentState.aYosikiForm
        if (form === undefined) return
        return await this.studentAYosikiFormApi.postStudentAYosikiForm({
            swAYosikiFormRequest: {
                common: {
                    ...form.common,
                    birthYear: convertStrToNumOrUndefined(form.common.birthYear),
                    birthMonth: convertStrToNumOrUndefined(form.common.birthMonth),
                    birthDay: convertStrToNumOrUndefined(form.common.birthDay),
                    gakunen: convertStrToNumOrUndefined(form.common.gakunen),
                    address: {
                        ...form.common.address
                    },
                    nihonGakuseiSienKiko: {
                        ...form.common.nihonGakuseiSienKiko
                    },
                    bessi1: {
                        ...form.common.bessi1
                    },
                    bessi2: {
                        ...form.common.bessi2
                    },
                },
                aYosiki1: {
                    ...form.aYosiki1,
                    sienzumi: {
                        schoolName: form.aYosiki1.sienzumi?.schoolName || "",
                        startYear: convertStrToNumOrUndefined(form.aYosiki1.sienzumi?.startYear),
                        startMonth: convertStrToNumOrUndefined(form.aYosiki1.sienzumi?.startMonth),
                        endYear: convertStrToNumOrUndefined(form.aYosiki1.sienzumi?.endYear),
                        endMonth: convertStrToNumOrUndefined(form.aYosiki1.sienzumi?.endMonth),
                        months: convertStrToNumOrUndefined(form.aYosiki1.sienzumi?.months),
                    },
                    mosikomiType: form.aYosiki1.mosikomiType as MosikomiTypeEnum || "",
                },
                aYosiki2: {
                    ...form.aYosiki2
                }
            }
        }).then((res) => {
            this.updateAYosikiFormErrorMessages({
                ...res.error,
            })
            return res
        }).catch((res) => {
            handleStudentError(res)
        })
        // try {
        //     const res = await this.studentAYosikiFormApi.postStudentAYosikiForm({
        //         swAYosikiFormRequest: {
        //             common: {
        //                 ...form.common,
        //                 birthYear: convertStrToNumOrUndefined(form.common.birthYear),
        //                 birthMonth: convertStrToNumOrUndefined(form.common.birthMonth),
        //                 birthDay: convertStrToNumOrUndefined(form.common.birthDay),
        //                 gakunen: convertStrToNumOrUndefined(form.common.gakunen),
        //                 address: {
        //                     ...form.common.address
        //                 },
        //                 nihonGakuseiSienKiko: {
        //                     ...form.common.nihonGakuseiSienKiko
        //                 },
        //                 bessi1: {
        //                     ...form.common.bessi1
        //                 },
        //                 bessi2: {
        //                     ...form.common.bessi2
        //                 },
        //             },
        //             aYosiki1: {
        //                 ...form.aYosiki1,
        //                 sienzumi: {
        //                     schoolName: form.aYosiki1.sienzumi?.schoolName || "",
        //                     startYear: convertStrToNumOrUndefined(form.aYosiki1.sienzumi?.startYear),
        //                     startMonth: convertStrToNumOrUndefined(form.aYosiki1.sienzumi?.startMonth),
        //                     endYear: convertStrToNumOrUndefined(form.aYosiki1.sienzumi?.endYear),
        //                     endMonth: convertStrToNumOrUndefined(form.aYosiki1.sienzumi?.endMonth),
        //                     months: convertStrToNumOrUndefined(form.aYosiki1.sienzumi?.months),
        //                 },
        //                 mosikomiType: form.aYosiki1.mosikomiType as MosikomiTypeEnum || "",
        //             },
        //             aYosiki2: {
        //                 ...form.aYosiki2
        //             }
        //         }
        //     });
        //     await this.updateAYosikiFormErrorMessages({
        //         ...res.error,
        //     })
        //     return res
        // } catch (res) {
        //     handleStudentError(res)
        // }
    }

    // NOTE: 判定
    async postAYosikiFormKakutei() {
        try {
            return await this.studentAYosikiFormKakuteiApi.postStudentAYosikiFormKakutei()
        } catch (res) {
            handleStudentError(res)
        }
    }

    // NOTE: 判定解除
    async postAYosikiFormKakuteiKaijo() {
        try {
            return await this.studentAYosikiFormKakuteiKaijoApi.postStudentAYosikiFormKakuteiKaijo()
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const studentAYosikiFormStore = new StudentAYosikiFormStore();
