// 一部のモーダルはenumで管理しています。
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {SwErrorMessage} from "openapi/models";

export enum YosikiKakuteiModalType {
    kakuteiModal,
    kakuteiErrorModal,
    kakuteiKaijoModal
}

export enum YosikiEditOpenModalType {
    wordDescription,
}

export interface YosikiInfoModalStoreVo {
    backButtonUnsavedAttentionModalOpenState: boolean;
    basicInfoModalOpenState: boolean;
    deleteZokugaraModalOpenState: boolean;
    selectZokugaraModalOpenState: boolean;
    addSyogaisyaZokugaraModalOpenState: boolean;
    addTyokiryoyoZokugaraModalOpenState: boolean;
    selectedZokugaraIndex?: number;
    yosikiKakuteiOpenModalType?: YosikiKakuteiModalType
    yosikiEditOpenModalType?: YosikiEditOpenModalType
    wordDescriptionType?: WordDescriptionTypeEnum
    yosikiInfoKakuteiModalErrorMessage?: Array<SwErrorMessage>
    yosikiInfoKakuteiKaijoModalErrorMessage?: Array<SwErrorMessage>
}

export interface DeleteSyunyuHonninModalFormVo {
    selectedSyunyuIndex?: number
}