import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from "react";
import {BgColorsEnum} from "common/enums_value/ColorsEnum";

interface FollowingBottomNavigationProps {

}

export const FollowingBottomNavigation: FC<FollowingBottomNavigationProps> = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
};

const useStyles = makeStyles({
    root: {
        backgroundColor: BgColorsEnum.White,
        boxShadow: "0px 0px 8px -6px #000000",
        position: "sticky",
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px 0",
        "& >*": {   // 直下の全子要素
            margin: "0 4px",
        },
    },
});