import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from 'react';

interface ListDscProps {
    className?: string
}

export const ListDsc: FC<ListDscProps> = React.memo((props) => {
    const classes = useStyles();
    return (
        <ul className={`${classes.root} ${props.className}`}>
            {props.children}
        </ul>
    )
});

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "block",
        paddingLeft: "1.5em",
        margin: 0,
        "& > li:not(:first-child)": {
            marginTop: 4
        }
    },
}));