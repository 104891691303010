import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {announceCommonStore, AnnounceCommonStoreVo} from "stores/common/AnnounceCommonStore";
import {Announce} from "components/organisms/utilities/Announce";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import React, {useCallback} from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import {accountInfoStore} from "../../../stores/student/AccountInfoStore";
import {SyoruiConfirmationForm} from "../../../components/molecules/syoruiConfirmationForm/SyoruiConfirmationForm";
import {URL_PATHS_STUDENT} from "../../../common/constant/URL_PATHS_STUDENT";
import {
    SyoruiConfirmationFormStoreVo,
    SyoruiConfirmationFormVo
} from "../../../common/models/SyoruiConfirmationFormModels";
import {studentSyoruiConfirmationFormStore} from "./stores/StudentSyoruiConfirmationFormStore";
import {scrollToError} from "../../../common/functions/scrollToError";

const _SyoruiConfirmationFormPage: React.FC<RouteComponentProps<{ studentNumber: string }>> = () => {

    // NOTE: storeデータ
    const [
        syoruiConfirmationFormStoreState,
        updateSyoruiConfirmationFormStoreState
    ] = React.useState<SyoruiConfirmationFormStoreVo>(studentSyoruiConfirmationFormStore.currentState);

    // NOTE: storeデータ
    const [
        announceCommon,
        updateAnnounceCommon
    ] = React.useState<AnnounceCommonStoreVo>(announceCommonStore.currentState);

    // NOTE: storeデータ
    const [
        studentInfo,
        updateStudentInfo
    ] = React.useState<StudentInfoStoreVo>(accountInfoStore.currentState);

    React.useEffect(() => {
        // NOTE: 初回取得
        studentSyoruiConfirmationFormStore.getSyoruiConfirmationForm().then()
        // NOTE: 取得後にstateを更新
        return studentSyoruiConfirmationFormStore.listen(updateSyoruiConfirmationFormStoreState);
    }, []);

    React.useEffect(() => {
        return announceCommonStore.listen(updateAnnounceCommon);
    }, []);

    React.useEffect(() => {
        accountInfoStore.getStudentInfo().then();
        return accountInfoStore.listen(updateStudentInfo);
    }, []);

    // NOTE: 戻る
    const handleClickBackButton = useCallback(() => {
        window.location.assign(`${URL_PATHS_STUDENT.top}`);
    }, [])

    // NOTE: 保存
    const handleClickSave = useCallback(async (formValues?: SyoruiConfirmationFormVo) => {
        if (formValues === undefined) return
        // NOTE: storeのデータ更新
        await studentSyoruiConfirmationFormStore.updateSyoruiConfirmationForm(formValues)
        // NOTE: 保存処理
        await studentSyoruiConfirmationFormStore.postSyoruiConfirmationForm().then(() => {
            announceCommonStore.updateAnnounceCommon(true, "保存しました。")
            scrollToError()
        })
    }, [])

    // NOTE: 判定
    const handleClickSyoruiHantei = useCallback(async (formValues?: SyoruiConfirmationFormVo) => {
        if (formValues === undefined) return
        // NOTE: storeのデータ更新
        await studentSyoruiConfirmationFormStore.updateSyoruiConfirmationForm(formValues)
        // NOTE: 事前に保存。エラーがあるなら判定せず
        const re0 = await studentSyoruiConfirmationFormStore.postSyoruiConfirmationForm()
        if (!re0?.isSuccess) {
            scrollToError()
            return
        }
        // NOTE: 判定処理
        const res = await studentSyoruiConfirmationFormStore.postSyoruiConfirmationFormHantei()
        // NOTE: 事前に保存しているので失敗はしないはず..
        if (res?.isSuccess) {
          accountInfoStore.getStudentInfo().then();
          announceCommonStore.updateAnnounceCommon(true, "判定しました。/ Determined.")
        } else {
          alert(res?.error?.ja + '/ ' + res?.error?.en)
        }
    }, [])

    // NOTE: 判定解除
    const handleClickSyoruiHanteiKaijo = useCallback(async () => {
        studentSyoruiConfirmationFormStore.postSyoruiConfirmationFormHanteiKaijo().then(async () => {
            accountInfoStore.getStudentInfo().then();
            announceCommonStore.updateAnnounceCommon(true, "判定を解除しました。 / Canceled")
        })
    }, [])

    return (

        <>

            <Announce
                announceCommon={announceCommon}
                updateAnnounceCommon={(value) => announceCommonStore.updateAnnounceCommon(value)}
            />

            <HeaderStudent/>

            <SyoruiConfirmationForm
                studentInfo={studentInfo}
                syoruiConfirmationForm={syoruiConfirmationFormStoreState.syoruiConfirmationForm}
                syoruiConfirmationFormErrorMessage={syoruiConfirmationFormStoreState.syoruiConfirmationFormErrorMessage}
                handleClickBackButton={handleClickBackButton}
                handleClickSave={handleClickSave}
                handleClickSyoruiHantei={handleClickSyoruiHantei}
                handleClickSyoruiHanteiKaijo={handleClickSyoruiHanteiKaijo}
            />

        </>

    );
};

export const SyoruiConfirmationFormPage = withRouter(_SyoruiConfirmationFormPage);
