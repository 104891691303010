import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {MemberVo} from "common/models/YosikiInfoModels";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import React, {FC} from "react";

interface FamilyInfoSectionMemberDetailProps {
    index: number
    member: MemberVo
}

export const FamilyInfoSectionMemberDetail: FC<FamilyInfoSectionMemberDetailProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const member: MemberVo = props.member;

    return (
        <FormTableRow
            label={convertZokugaraForStr(member.zokugara, member.zokugaraOther)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.この続柄の方が就学者の場合)}
                </div>
                {
                    member.isSyugakusya === true ? FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.就学者) :
                        member.isSyugakusya === false ? FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.就学者以外) :
                            ""
                }
            </div>

            <div className={"formTableRow_item"}>

                <div className={`${classes.topRow} ${xs ? classes.xsTopRow : ""}`}>
                    <FieldWithLabel
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.氏名)}
                        width={"120px"}
                    >
                        {member.name}
                    </FieldWithLabel>

                    <FieldWithLabel
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.年齢)}
                        width={"80px"}
                    >
                        {member.age}歳
                    </FieldWithLabel>

                </div>

                {
                    member.syugakusyaIgai && member.isSyugakusya === false ?
                        <div>
                            <FieldWithLabel
                                label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.現在の職業)}
                                width={"224px"}
                            >
                                {member.syugakusyaIgai.jobName}
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.在職期間)}
                                width={"260px"}
                            >
                                {member.syugakusyaIgai.jobYears}年
                            </FieldWithLabel>
                        </div>
                        : null
                }

            </div>

        </FormTableRow>
    )
};

const useStyles = makeStyles({
        root: {},
        topRow: {
            display: "flex",
            width: "100%",
            "& > *:not(:first-child)": {
                marginLeft: 4
            }
        },
        xsTopRow: {
            display: "block",
            "& > *:not(:first-child)": {
                marginLeft: 0
            }
        }
    }
);
