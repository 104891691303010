// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum SyunyuKubunEnum {
    給料賃金 = "E給料賃金",
    年金恩給 = "E年金恩給",
    雇用保険 = "E雇用保険",
    各種手当 = "E各種手当",
    奨学金 = "E奨学金",
    営業 = "E営業",
    農林水産業 = "E農林水産業",
    不動産 = "E不動産",
    自由業 = "E自由業",
    利子配当 = "E利子配当",
    親戚等の援助 = "E親戚等の援助",
    預貯金から = "E預貯金から",
    その他 = "Eその他",
    退職金 = "E退職金",
    保険金 = "E保険金",
    その他臨時所得 = "Eその他臨時所得",
}

export function syunyuKubunEnumToName(e: SyunyuKubunEnum): { ja: string, en: string } {
    switch (e) {
        case SyunyuKubunEnum.給料賃金:
            return {ja: "給料・賃金", en: "Salary and wages"};
        case SyunyuKubunEnum.年金恩給:
            return {ja: "年金・恩給", en: "Pension and other types of retirement benefits"};
        case SyunyuKubunEnum.雇用保険:
            return {ja: "雇用保険", en: "Unemployment insurance"};
        case SyunyuKubunEnum.各種手当:
            return {ja: "各種手当", en: "Various allowances"};
        case SyunyuKubunEnum.奨学金:
            return {ja: "奨学金", en: "Scholarships"};
        case SyunyuKubunEnum.営業:
            return {ja: "営業", en: "Sales"};
        case SyunyuKubunEnum.農林水産業:
            return {ja: "農林水産業", en: "Agriculture, forestry and fisheries"};
        case SyunyuKubunEnum.不動産:
            return {ja: "不動産", en: "Real estate"};
        case SyunyuKubunEnum.自由業:
            return {ja: "自由業", en: "Freelance work"};
        case SyunyuKubunEnum.利子配当:
            return {ja: "利子・配当", en: "Interest and dividends"};
        case SyunyuKubunEnum.親戚等の援助:
            return {ja: "親戚等の援助", en: "Remittance, financial assistance, etc."};
        case SyunyuKubunEnum.預貯金から:
            return {ja: "預・貯金から", en: "From deposits and savings"};
        case SyunyuKubunEnum.その他:
            return {ja: "その他", en: "Other"};
        case SyunyuKubunEnum.退職金:
            return {ja: "退職金", en: "Retirement benefits"};
        case SyunyuKubunEnum.保険金:
            return {ja: "保険金", en: "Other"};
        case SyunyuKubunEnum.その他臨時所得:
            return {ja: "その他(臨時所得)", en: "Other(Incidental income)"};
    }
}