/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwKikans,
    SwKikansFromJSON,
    SwKikansFromJSONTyped,
    SwKikansToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerKiaknDetail
 */
export interface SwOfficerKiaknDetail {
    /**
     * 
     * @type {SwKikans}
     * @memberof SwOfficerKiaknDetail
     */
    kikans?: SwKikans;
}

export function SwOfficerKiaknDetailFromJSON(json: any): SwOfficerKiaknDetail {
    return SwOfficerKiaknDetailFromJSONTyped(json, false);
}

export function SwOfficerKiaknDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerKiaknDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kikans': !exists(json, 'kikans') ? undefined : SwKikansFromJSON(json['kikans']),
    };
}

export function SwOfficerKiaknDetailToJSON(value?: SwOfficerKiaknDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kikans': SwKikansToJSON(value.kikans),
    };
}


