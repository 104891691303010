import {SyunyuInfoItemOtherVo, SyunyuInfoItemVo, SyunyuInfoVo} from "../../models/YosikiInfoModels";
import {SyunyuKubunMasterVo} from "../../models/YosikiMastersVo";
import {SyunyuKubunCategoryEnum} from "../../enums/SyunyuKubunCategoryEnum";
import {SyunyuKubunEnum} from "../../enums/SyunyuKubunEnum";
import {convertStrToNumOrUndefined} from "../type_converter/convertStrToNumOrUndefined";


function addItemAmount(
    item: SyunyuInfoItemVo,
    kyuyoKubuns: Array<SyunyuKubunEnum>,
    kyuyoIgaiKubuns: Array<SyunyuKubunEnum>
): { amount: number, kubunCategory: SyunyuKubunCategoryEnum } {
    const amount = convertStrToNumOrUndefined(item.field.amount) || 0
    if (kyuyoKubuns.includes(item.kubunCode)) {
        return {amount: amount, kubunCategory: SyunyuKubunCategoryEnum.給与所得}
    }
    if (kyuyoIgaiKubuns.includes(item.kubunCode)) {
        return {amount: amount, kubunCategory: SyunyuKubunCategoryEnum.給与所得以外}
    }
    throw new Error("収入情報が不正です。");
}

function addItemOtherAmount(
    item: SyunyuInfoItemOtherVo,
    kyuyoKubuns: Array<SyunyuKubunEnum>,
    kyuyoIgaiKubuns: Array<SyunyuKubunEnum>
): { amount: number, kubunCategory: SyunyuKubunCategoryEnum } {
    let total = 0
    item.fields.forEach(f => {
        total += (convertStrToNumOrUndefined(f?.amount) || 0)
    })
    if (kyuyoKubuns.includes(item.kubunCode)) {
        return {amount: total, kubunCategory: SyunyuKubunCategoryEnum.給与所得}
    }
    if (kyuyoIgaiKubuns.includes(item.kubunCode)) {
        return {amount: total, kubunCategory: SyunyuKubunCategoryEnum.給与所得以外}
    }
    throw new Error("収入情報が不正です。");
}

// NOTE: 「収入所得」と「収入所得以外」の合計金額を計算
export function calcTotalAmounts(
    syunyuInfo?: SyunyuInfoVo,
    syunyuKubun?: Array<SyunyuKubunMasterVo>,
    kakuteisinkokuAriNasi?: boolean
) {
    let syotoku: number = 0;
    let syotokuIgai: number = 0;
    if (syunyuKubun === undefined || syunyuInfo === undefined) return {
        syotoku: syotoku,
        syotokuIgai: syotokuIgai
    };
    const kyuyoKubuns: Array<SyunyuKubunEnum> = []
    const kyuyoIgaiKubuns: Array<SyunyuKubunEnum> = []
    const amounts: Array<{ amount: number, kubunCategory: SyunyuKubunCategoryEnum }> = []

    // NOTE: カテゴリで区分を分類
    syunyuKubun.forEach((c: SyunyuKubunMasterVo) => {
        if (c.categoryCode === SyunyuKubunCategoryEnum.給与所得) {
            kyuyoKubuns.push(c.code as SyunyuKubunEnum)
        }
        if (c.categoryCode === SyunyuKubunCategoryEnum.給与所得以外) {
            kyuyoIgaiKubuns.push(c.code as SyunyuKubunEnum)
        }
    });

    // NOTE: 共通
    amounts.push(addItemAmount(syunyuInfo.kyuyo, kyuyoKubuns, kyuyoIgaiKubuns))

    // NOTE: 確定申告
    if (kakuteisinkokuAriNasi) {
        amounts.push(addItemAmount(syunyuInfo.eigyo, kyuyoKubuns, kyuyoIgaiKubuns))
        amounts.push(addItemAmount(syunyuInfo.norin, kyuyoKubuns, kyuyoIgaiKubuns))
        amounts.push(addItemAmount(syunyuInfo.hudosan, kyuyoKubuns, kyuyoIgaiKubuns))
        amounts.push(addItemAmount(syunyuInfo.risi, kyuyoKubuns, kyuyoIgaiKubuns))
        amounts.push(addItemAmount(syunyuInfo.haito, kyuyoKubuns, kyuyoIgaiKubuns))
    }

    // NOTE: その他所得
    amounts.push(addItemOtherAmount(syunyuInfo.nenkin, kyuyoKubuns, kyuyoIgaiKubuns))
    amounts.push(addItemOtherAmount(syunyuInfo.koyohoken, kyuyoKubuns, kyuyoIgaiKubuns))
    amounts.push(addItemOtherAmount(syunyuInfo.kakusyuteate, kyuyoKubuns, kyuyoIgaiKubuns))
    amounts.push(addItemOtherAmount(syunyuInfo.syogakukin, kyuyoKubuns, kyuyoIgaiKubuns))
    amounts.push(addItemOtherAmount(syunyuInfo.sinsekitounosien, kyuyoKubuns, kyuyoIgaiKubuns))
    amounts.push(addItemOtherAmount(syunyuInfo.yotyokin, kyuyoKubuns, kyuyoIgaiKubuns))
    amounts.push(addItemOtherAmount(syunyuInfo.other, kyuyoKubuns, kyuyoIgaiKubuns))
    amounts.push(addItemOtherAmount(syunyuInfo.taisyokukinn, kyuyoKubuns, kyuyoIgaiKubuns))
    amounts.push(addItemOtherAmount(syunyuInfo.hokenkin, kyuyoKubuns, kyuyoIgaiKubuns))

    amounts.forEach(a => {
        if (a.kubunCategory === SyunyuKubunCategoryEnum.給与所得) {
            syotoku += a.amount
        }
        if (a.kubunCategory === SyunyuKubunCategoryEnum.給与所得以外) {
            syotokuIgai += a.amount
        }
    })

    return {
        syotoku: syotoku,
        syotokuIgai: syotokuIgai
    }
}