import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {PostStudentLoginRequest, StudentLoginApi} from "openapi/apis";
import {SwErrorMessage} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";

export interface LoginVo {
    form: LoginFormVo
    errorMessages?: Array<SwErrorMessage>
}

export interface LoginFormVo {
    studentNumber: string
    password: string
}

export const INIT_LOGIN_FORM = {
    studentNumber: "",
    password: "",
};

class LoginStore extends Store<LoginVo> {

    private api = new StudentLoginApi(apiConfiguration);

    constructor() {
        super({
            form: INIT_LOGIN_FORM
        })
    }

    updateForm(part: Partial<LoginFormVo>) {
        const newForm = {
            ...this.currentState.form,
            ...part
        };
        this.dispatch(() => ({form: newForm}));
    }

    updateErrorMessages(errorMessages: Array<SwErrorMessage> | undefined) {
        this.dispatch(() => ({errorMessages: errorMessages}));
    }

    updateStudentNumber(value: string) {
        let part: Partial<LoginFormVo> = {
            studentNumber: value,
        };
        this.updateForm(part)
    }

    updatePassword(value: string) {
        let part: Partial<LoginFormVo> = {
            password: value,
        };
        this.updateForm(part)
    }

    async postLogin() {
        const param: PostStudentLoginRequest = {
            swStudentLoginRequest: {
                studentNumber: this.currentState.form.studentNumber,
                password: this.currentState.form.password,
            }
        };
        try {
            const res = await this.api.postStudentLogin(param);
            if(res.error !== undefined){
                this.updateErrorMessages(res.error)
            } else {
                this.updateErrorMessages(undefined);
                window.location.assign(URL_PATHS_STUDENT.top);
                this.updateForm(INIT_LOGIN_FORM);
            }
        } catch (error) {
            const res: Response = error as Response;
            res.json().then( (body) => {
                this.updateErrorMessages(body.error)
            });
            handleStudentError(res)
        }
    }

}

export const loginStore = new LoginStore();