import makeStyles from "@material-ui/core/styles/makeStyles";
import {BukyokuEnum} from "common/enums/BukyokuEnum";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {SummaryTextEnum, SummaryTextEnumToName} from "common/enums_text/SummaryTextEnum";
import {Title02} from "components/atoms/titles/Title02";
import React, {FC} from "react";

interface SummaryOfficeInfomationContentProps {
    bukyokuCode: BukyokuEnum
}

export const SummaryOfficeInfomationSection: FC<SummaryOfficeInfomationContentProps> = (props) => {

    function getOfficeInfomation(bukyokuCode: BukyokuEnum): { officeName: string, officeAddress: string, officeTel: string } {
        let officeName: string = "";
        let officeAddress: string = "";
        let officeTel: string = "";
        switch (bukyokuCode) {
            case BukyokuEnum.農学:
                officeName = "農学府・農学部";
                officeAddress = "府中地区学生支援室学生生活係";
                officeTel = "042−367−5579";
                break;
            case BukyokuEnum.農府:
                officeName = "農学府・農学部";
                officeAddress = "府中地区学生支援室学生生活係";
                officeTel = "042−367−5579";
                break;
            case BukyokuEnum.工学:
                officeName = "工学部";
                officeAddress = "小金井地区学生支援室学生生活係";
                officeTel = "042−388−7011";
                break;
            case BukyokuEnum.生府:
                officeName = "生物システム応用科学府";
                officeAddress = "小金井地区学生支援室入学試験係";
                officeTel = "042−388−7014";
                break;
            case BukyokuEnum.農博:
                officeName = "連合農学研究科";
                officeAddress = "連合大学院事務室学生係";
                officeTel = "042−367−5670";
                break;
            case BukyokuEnum.工府:
                officeName = "工学府";
                officeAddress = "小金井地区学生支援室入学試験係";
                officeTel = "042−388−7014";
                break;
        }
        return {
            officeName: officeName,
            officeAddress: officeAddress,
            officeTel: officeTel
        }
    }

    const officeInfomation = getOfficeInfomation(props.bukyokuCode);

    const classes = useStyles();
    return (
        <div>
            <Title02
                title={convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.担当窓口))}
            />

            <div className={classes.officeContent}>
                <div className={"officeContent_name"}>{officeInfomation.officeName}担当窓口</div>
                <div className={"officeContent_address"}>{officeInfomation.officeAddress}</div>
                <div className={"officeContent_tel"}>{officeInfomation.officeTel}</div>
            </div>

        </div>
    )
};

const useStyles = makeStyles({
        root: {
            fontWeight: 700,
            fontSize: 18,
            "& .kikan_head": {
                fontWeight: 400,
                fontSize: 12
            }
        },
        officeContent: {
            fontSize: 14,
            marginTop: 16,
            "& .officeContent_name": {
                fontWeight: 700,
                fontSize: 16,
            },
            "& .officeContent_address": {
                marginTop: 4,
            },
            "& .officeContent_tel": {},
        }
    }
);


