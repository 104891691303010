import makeStyles from "@material-ui/core/styles/makeStyles";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {SyoruiTextEnum, SyoruiTextEnumToName} from "common/enums_text/SyoruiTextEnum";
import {TextLink} from "components/atoms/TextLink";
import {Title02} from "components/atoms/titles/Title02";
import React, {FC} from "react";
import {TextColorsEnum} from "common/enums_value/ColorsEnum";
import { Box } from "@material-ui/core";

interface SyoruiSectionProps {
    className?: string
    handleClickSyoruiList: () => void
}

export const SyoruiSection: FC<SyoruiSectionProps> = (props) => {

    const classes = useStyles();

    return (

        <div className={`${classes.root} ${props.className && props.className}`}>

            <div className={classes.pdfDes}>

                <div className={"pdfDes_title"}>
                    <Title02
                        title={convertEnumNameToStr(SyoruiTextEnumToName(SyoruiTextEnum.書類一覧))}
                    />
                </div>

                <div className={"pdfDes_list"}>

                    <Box fontWeight={700} color={TextColorsEnum.Red}>
                        {convertEnumNameToStr(SyoruiTextEnumToName(SyoruiTextEnum.システムから正しく出力がされない場合や手書きで申請される場合はこちらから書類をダウンロードできます))}
                    </Box>

                    <div className={"pdfDes_list_item"}>
                        {/*
                        <TextLink
                            text={convertEnumNameToStr(SyoruiTextEnumToName(SyoruiTextEnum.各種証明書類等一覧))}
                            onClick={props.handleClickSyoruiList}
                        />
                          */}
                        <a href="https://www.tuat.ac.jp/campuslife_career/campuslife/fee/men_syou/menjoj/" target="_blank">
                            授業料免除・授業料徴収猶予（経済的理由かつ学業優秀）
                        </a>
                    </div>

                </div>
            </div>

        </div>

    )
};

const useStyles = makeStyles({
        root: {},
        pdfDes: {
            "& > .pdfDes_title": {},
            "& .pdfDes_title": {
                fontSize: 16,
                fontWeight: 700,
            },
            "& > .pdfDes_paragraph": {
                marginTop: 8,
            },
            "& .pdfDes_list": {
                marginTop: 8,
                "& .pdfDes_list_item": {
                    marginTop: 4,
                    "&:first-child": {
                        marginTop: 0
                    },
                },
            }
        },
    }
);
