import {SwErrorMessage} from "openapi/models";

// NOTE: 複数のSwErrorMessage配列を1つにまとめる。
// NOTE: 入力項目が複数かつエラー表示が1箇所などのケースに。
export function joinArraySwErrorMessage(array: Array<Array<SwErrorMessage> | undefined>): Array<SwErrorMessage> {
    let newSwErrorMessage: Array<SwErrorMessage> = [];
    array.forEach((a: Array<SwErrorMessage> | undefined) => {
        if (a === undefined) return;
        a.forEach(a => {
            newSwErrorMessage.push(a)
        })
    });
    // 重複を削除
    return newSwErrorMessage.filter(function(v1,i1,a1){
        return (a1.findIndex(function(v2){
            return (v1.ja===v2.ja)
        }) === i1);
    });
}
