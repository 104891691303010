import {
    convertBasicInfoSwToVo,
    convertFamilyInfoSwToVo,
    convertInfoPolicySwToVo,
    convertJugyoryoInfoSwToVo,
    convertNyugakuryoInfoSwToVo,
    convertSeisekiInfoSwToVo,
    convertSinseisyaInfoSwToVo,
    convertSyogakukinInfoSwToVo,
    convertTokubetuKojoSwToVo
} from "common/functions/yosikiInfo/convertYosikiInfoSwToVo";
import {
    convertFamilyInfoVoToSw,
    convertSeisekiInfoVoToSw,
    convertSinseisyaInfoVoToSw,
    convertSyogakukinInfoVoToSw,
    convertTokubetuKojoVoToSw
} from "common/functions/yosikiInfo/convertYosikiInfoVoToSw";
import {YosikiInfoResponseVo} from "common/models/YosikiInfoModels";
import {SwYosikiInfoRequest, SwYosikiInfoResponse} from "openapi/models";

export function convertPolicyAndBasicInfoSwToVo(response: SwYosikiInfoResponse, yosikiInfo?: YosikiInfoResponseVo): YosikiInfoResponseVo | undefined {
    if (yosikiInfo === undefined) return;
    return {
        infoPolicy: convertInfoPolicySwToVo(response.infoPolicy),
        basicInfo: convertBasicInfoSwToVo(response.basicInfo),
        sinseisyaInfo: yosikiInfo?.sinseisyaInfo,
        seisekiInfo: yosikiInfo?.seisekiInfo,
        syogakukinInfo: yosikiInfo?.syogakukinInfo,
        familyInfo: yosikiInfo?.familyInfo,
        nyugakuryoInfo: yosikiInfo?.nyugakuryoInfo,
        jugyoryoInfo: yosikiInfo?.jugyoryoInfo,
        tokubetuKojoInfo: yosikiInfo?.tokubetuKojoInfo,
    }
}

export function convertYosikiInfoSwToVo(response: SwYosikiInfoResponse): YosikiInfoResponseVo {
    return {
        infoPolicy: convertInfoPolicySwToVo(response.infoPolicy),
        basicInfo: convertBasicInfoSwToVo(response.basicInfo),
        sinseisyaInfo: convertSinseisyaInfoSwToVo(response.sinseisyaInfo),
        seisekiInfo: convertSeisekiInfoSwToVo(response.seisekiInfo),
        syogakukinInfo: convertSyogakukinInfoSwToVo(response.syogakukinInfo),
        familyInfo: convertFamilyInfoSwToVo(response.familyInfo),
        nyugakuryoInfo: convertNyugakuryoInfoSwToVo(response.nyugakuryoInfo),
        jugyoryoInfo: convertJugyoryoInfoSwToVo(response.jugyoryoInfo),
        // aYosiki1Info: convertAYosiki1InfoSwToVo(response.aYosiki1Info),
        tokubetuKojoInfo: convertTokubetuKojoSwToVo(response.tokubetuKojoInfo)
    }
}

export function convertYosikiInfoVoToSw(yosikiInfo?: YosikiInfoResponseVo): SwYosikiInfoRequest | undefined {
    if (yosikiInfo === undefined) return undefined;
    return {
        "sinseisyaInfo": convertSinseisyaInfoVoToSw(yosikiInfo.sinseisyaInfo),
        "seisekiInfo": convertSeisekiInfoVoToSw(yosikiInfo.seisekiInfo),
        "syogakukinInfo": convertSyogakukinInfoVoToSw(yosikiInfo.syogakukinInfo.syogakukins),
        "familyInfo": convertFamilyInfoVoToSw(yosikiInfo.familyInfo),
        "nyugakuryoInfo": {riyu: yosikiInfo.nyugakuryoInfo.riyu},
        "jugyoryoInfo": {riyu: yosikiInfo.jugyoryoInfo.riyu},
        // "aYosiki1Info": convertAYosiki1InfoVoToSw(yosikiInfo.aYosiki1Info),
        "tokubetuKojoInfo": convertTokubetuKojoVoToSw(yosikiInfo.tokubetuKojoInfo)
    }
}

