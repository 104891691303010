/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwSyunyuInfoItemOtherErrorMessages,
    SwSyunyuInfoItemOtherErrorMessagesFromJSON,
    SwSyunyuInfoItemOtherErrorMessagesFromJSONTyped,
    SwSyunyuInfoItemOtherErrorMessagesToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSyunyuInfoErrorMessages
 */
export interface SwSyunyuInfoErrorMessages {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyunyuInfoErrorMessages
     */
    kakuteisinkokuField?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyunyuInfoErrorMessages
     */
    gensentyosyuField?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyunyuInfoErrorMessages
     */
    otherField?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyunyuInfoErrorMessages
     */
    kyuyo?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyunyuInfoErrorMessages
     */
    eigyo?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyunyuInfoErrorMessages
     */
    norin?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyunyuInfoErrorMessages
     */
    hudosan?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyunyuInfoErrorMessages
     */
    risi?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyunyuInfoErrorMessages
     */
    haito?: Array<SwErrorMessage>;
    /**
     * 
     * @type {SwSyunyuInfoItemOtherErrorMessages}
     * @memberof SwSyunyuInfoErrorMessages
     */
    nenkin?: SwSyunyuInfoItemOtherErrorMessages;
    /**
     * 
     * @type {SwSyunyuInfoItemOtherErrorMessages}
     * @memberof SwSyunyuInfoErrorMessages
     */
    koyohoken?: SwSyunyuInfoItemOtherErrorMessages;
    /**
     * 
     * @type {SwSyunyuInfoItemOtherErrorMessages}
     * @memberof SwSyunyuInfoErrorMessages
     */
    kakusyuteate?: SwSyunyuInfoItemOtherErrorMessages;
    /**
     * 
     * @type {SwSyunyuInfoItemOtherErrorMessages}
     * @memberof SwSyunyuInfoErrorMessages
     */
    syogakukin?: SwSyunyuInfoItemOtherErrorMessages;
    /**
     * 
     * @type {SwSyunyuInfoItemOtherErrorMessages}
     * @memberof SwSyunyuInfoErrorMessages
     */
    sinsekitounosien?: SwSyunyuInfoItemOtherErrorMessages;
    /**
     * 
     * @type {SwSyunyuInfoItemOtherErrorMessages}
     * @memberof SwSyunyuInfoErrorMessages
     */
    yotyokin?: SwSyunyuInfoItemOtherErrorMessages;
    /**
     * 
     * @type {SwSyunyuInfoItemOtherErrorMessages}
     * @memberof SwSyunyuInfoErrorMessages
     */
    other?: SwSyunyuInfoItemOtherErrorMessages;
    /**
     * 
     * @type {SwSyunyuInfoItemOtherErrorMessages}
     * @memberof SwSyunyuInfoErrorMessages
     */
    taisyokukinn?: SwSyunyuInfoItemOtherErrorMessages;
    /**
     * 
     * @type {SwSyunyuInfoItemOtherErrorMessages}
     * @memberof SwSyunyuInfoErrorMessages
     */
    hokenkin?: SwSyunyuInfoItemOtherErrorMessages;
}

export function SwSyunyuInfoErrorMessagesFromJSON(json: any): SwSyunyuInfoErrorMessages {
    return SwSyunyuInfoErrorMessagesFromJSONTyped(json, false);
}

export function SwSyunyuInfoErrorMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyunyuInfoErrorMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kakuteisinkokuField': !exists(json, 'kakuteisinkokuField') ? undefined : ((json['kakuteisinkokuField'] as Array<any>).map(SwErrorMessageFromJSON)),
        'gensentyosyuField': !exists(json, 'gensentyosyuField') ? undefined : ((json['gensentyosyuField'] as Array<any>).map(SwErrorMessageFromJSON)),
        'otherField': !exists(json, 'otherField') ? undefined : ((json['otherField'] as Array<any>).map(SwErrorMessageFromJSON)),
        'kyuyo': !exists(json, 'kyuyo') ? undefined : ((json['kyuyo'] as Array<any>).map(SwErrorMessageFromJSON)),
        'eigyo': !exists(json, 'eigyo') ? undefined : ((json['eigyo'] as Array<any>).map(SwErrorMessageFromJSON)),
        'norin': !exists(json, 'norin') ? undefined : ((json['norin'] as Array<any>).map(SwErrorMessageFromJSON)),
        'hudosan': !exists(json, 'hudosan') ? undefined : ((json['hudosan'] as Array<any>).map(SwErrorMessageFromJSON)),
        'risi': !exists(json, 'risi') ? undefined : ((json['risi'] as Array<any>).map(SwErrorMessageFromJSON)),
        'haito': !exists(json, 'haito') ? undefined : ((json['haito'] as Array<any>).map(SwErrorMessageFromJSON)),
        'nenkin': !exists(json, 'nenkin') ? undefined : SwSyunyuInfoItemOtherErrorMessagesFromJSON(json['nenkin']),
        'koyohoken': !exists(json, 'koyohoken') ? undefined : SwSyunyuInfoItemOtherErrorMessagesFromJSON(json['koyohoken']),
        'kakusyuteate': !exists(json, 'kakusyuteate') ? undefined : SwSyunyuInfoItemOtherErrorMessagesFromJSON(json['kakusyuteate']),
        'syogakukin': !exists(json, 'syogakukin') ? undefined : SwSyunyuInfoItemOtherErrorMessagesFromJSON(json['syogakukin']),
        'sinsekitounosien': !exists(json, 'sinsekitounosien') ? undefined : SwSyunyuInfoItemOtherErrorMessagesFromJSON(json['sinsekitounosien']),
        'yotyokin': !exists(json, 'yotyokin') ? undefined : SwSyunyuInfoItemOtherErrorMessagesFromJSON(json['yotyokin']),
        'other': !exists(json, 'other') ? undefined : SwSyunyuInfoItemOtherErrorMessagesFromJSON(json['other']),
        'taisyokukinn': !exists(json, 'taisyokukinn') ? undefined : SwSyunyuInfoItemOtherErrorMessagesFromJSON(json['taisyokukinn']),
        'hokenkin': !exists(json, 'hokenkin') ? undefined : SwSyunyuInfoItemOtherErrorMessagesFromJSON(json['hokenkin']),
    };
}

export function SwSyunyuInfoErrorMessagesToJSON(value?: SwSyunyuInfoErrorMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kakuteisinkokuField': value.kakuteisinkokuField === undefined ? undefined : ((value.kakuteisinkokuField as Array<any>).map(SwErrorMessageToJSON)),
        'gensentyosyuField': value.gensentyosyuField === undefined ? undefined : ((value.gensentyosyuField as Array<any>).map(SwErrorMessageToJSON)),
        'otherField': value.otherField === undefined ? undefined : ((value.otherField as Array<any>).map(SwErrorMessageToJSON)),
        'kyuyo': value.kyuyo === undefined ? undefined : ((value.kyuyo as Array<any>).map(SwErrorMessageToJSON)),
        'eigyo': value.eigyo === undefined ? undefined : ((value.eigyo as Array<any>).map(SwErrorMessageToJSON)),
        'norin': value.norin === undefined ? undefined : ((value.norin as Array<any>).map(SwErrorMessageToJSON)),
        'hudosan': value.hudosan === undefined ? undefined : ((value.hudosan as Array<any>).map(SwErrorMessageToJSON)),
        'risi': value.risi === undefined ? undefined : ((value.risi as Array<any>).map(SwErrorMessageToJSON)),
        'haito': value.haito === undefined ? undefined : ((value.haito as Array<any>).map(SwErrorMessageToJSON)),
        'nenkin': SwSyunyuInfoItemOtherErrorMessagesToJSON(value.nenkin),
        'koyohoken': SwSyunyuInfoItemOtherErrorMessagesToJSON(value.koyohoken),
        'kakusyuteate': SwSyunyuInfoItemOtherErrorMessagesToJSON(value.kakusyuteate),
        'syogakukin': SwSyunyuInfoItemOtherErrorMessagesToJSON(value.syogakukin),
        'sinsekitounosien': SwSyunyuInfoItemOtherErrorMessagesToJSON(value.sinsekitounosien),
        'yotyokin': SwSyunyuInfoItemOtherErrorMessagesToJSON(value.yotyokin),
        'other': SwSyunyuInfoItemOtherErrorMessagesToJSON(value.other),
        'taisyokukinn': SwSyunyuInfoItemOtherErrorMessagesToJSON(value.taisyokukinn),
        'hokenkin': SwSyunyuInfoItemOtherErrorMessagesToJSON(value.hokenkin),
    };
}


