import makeStyles from "@material-ui/core/styles/makeStyles";
import {syogaisyaTypeEnumToName} from "common/enums/SyogaisyaTypeEnum";
import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {SyogaisyaVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {TitleNameTextEnum, TitleNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TitleNameTextEnum";
import {TokubetuKojoLabelTextEnum, TokubetuKojoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TokubetuKojoLabelTextEnum";
import {Title05} from "components/atoms/titles/Title05";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {FC} from "react";

interface TokubetuKojoSyogaisyaDetailProps {
    syogaisya: Array<SyogaisyaVo>
}

interface SyogaisyaDetailRowProps {
    syogaisya: SyogaisyaVo
    index: number
}

const SyogaisyaDetailRow: FC<SyogaisyaDetailRowProps> = (props) => {

    const syogaisya = props.syogaisya;

    return (
        <FormTableRow
            label={convertZokugaraForStr(syogaisya.zokugara, syogaisya.zokugaraOther)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}
                </div>
                {syogaisya.type ? convertEnumNameToStr(syogaisyaTypeEnumToName(syogaisya.type)) : ""} {syogaisya.typeOther}
            </div>
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.手帳番号)}
                </div>
                {syogaisya.tetyoNumber}
            </div>

        </FormTableRow>
    )

};

export const TokubetuKojoSyogaisyaDetail: FC<TokubetuKojoSyogaisyaDetailProps> = (props) => {

    const classes = useStyles();

    const syogaisya: Array<SyogaisyaVo> = props.syogaisya;

    return (

        <>
            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.障害者のいる世帯)}
                className={classes.title}
            />

            {syogaisya.length === 0 ? <div>{CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を追加してください)}</div> : null}

            <FormTable
                className={"section_table"}
            >

                {
                    syogaisya.map((s: SyogaisyaVo, index: number) => {
                        return (
                            <SyogaisyaDetailRow
                                key={s.zokugara + index}
                                index={index}
                                syogaisya={s}
                            />
                        )
                    })
                }

            </FormTable>

        </>

    )
};

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        },
        bottomRow: {
            marginTop: 4
        },
    }
);

