/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwSinseiTypes
 */
export interface SwSinseiTypes {
    /**
     * 
     * @type {boolean}
     * @memberof SwSinseiTypes
     */
    nyugakuryoMenjo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwSinseiTypes
     */
    nyugakuryoYuyo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwSinseiTypes
     */
    jugyoryoMenjo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwSinseiTypes
     */
    jugyoryoYuyo: boolean;
}

export function SwSinseiTypesFromJSON(json: any): SwSinseiTypes {
    return SwSinseiTypesFromJSONTyped(json, false);
}

export function SwSinseiTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSinseiTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nyugakuryoMenjo': json['nyugakuryoMenjo'],
        'nyugakuryoYuyo': json['nyugakuryoYuyo'],
        'jugyoryoMenjo': json['jugyoryoMenjo'],
        'jugyoryoYuyo': json['jugyoryoYuyo'],
    };
}

export function SwSinseiTypesToJSON(value?: SwSinseiTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nyugakuryoMenjo': value.nyugakuryoMenjo,
        'nyugakuryoYuyo': value.nyugakuryoYuyo,
        'jugyoryoMenjo': value.jugyoryoMenjo,
        'jugyoryoYuyo': value.jugyoryoYuyo,
    };
}


