import {BeturiTypeEnum} from "common/enums/BeturiTypeEnum";
import {JugyoryoEnum} from "common/enums/JugyoryoEnum";
import {NyugakuryoEnum} from "common/enums/NyugakuryoEnum";
import {SchoolTypeEnum} from "common/enums/SchoolTypeEnum";
import {SettiKubunEnum} from "common/enums/SettiKubunEnum";
import {SyogaisyaTypeEnum} from "common/enums/SyogaisyaTypeEnum";
import {SyunyuKubunEnum} from "common/enums/SyunyuKubunEnum";
import {TokubetuKojoEnum} from "common/enums/TokubetuKojoEnum";
import {TyokiryoyoTypeEnum} from "common/enums/TyokiryoyoTypeEnum";
import {ZokugaraEnum} from "common/enums/ZokugaraEnum";
import {JugyoryoMenjoStateEnum} from "../enums/JugyoryoMenjoStateEnum";

export interface YosikiInfoResponseVo {
    infoPolicy: InfoPolicyVo;
    basicInfo: BasicInfoVo;
    sinseisyaInfo: SinseisyaInfoVo;
    seisekiInfo: SeisekiInfoVo;
    syogakukinInfo: SyogakukinInfoVo;
    familyInfo: FamilyInfoVo;
    nyugakuryoInfo: NyugakuryoInfoVo;
    jugyoryoInfo: JugyoryoInfoVo;
    tokubetuKojoInfo: TokubetuKojoInfoVo
}

export interface InfoPolicyVo {
    requireSinseisyaInfo: InfoPolicyRequireSinseisyaInfoVo;
    requireNyugakuryoInfo: boolean;
    requireJugyoryoInfo: boolean;
    requireTokubetuKojoInfo: InfoPolicyRequireTokubetuKojoInfoVo;
}

export interface InfoPolicyRequireSinseisyaInfoVo {
    addressFamily: boolean;
    telFamily: boolean;
}

export interface InfoPolicyRequireTokubetuKojoInfoVo {
    bosiHusi: boolean;
    syogaisya: boolean;
    tyokiryoyo: boolean;
    bekkyo: boolean;
    saigaihigai: boolean;
}

export interface BasicInfoVo {
    sinseiType: BasicInfoSinseiTypeVo;
    tokubetuKojo: Array<TokubetuKojoEnum>;
}

export interface BasicInfoJassoVo {
    appliedBefore: boolean;
    apply: boolean;
}

export interface BasicInfoSinseiTypeVo {
    nyugakuryo: NyugakuryoEnum;
    jugyoryo: JugyoryoEnum;
}

export interface SinseisyaInfoVo {
    birthdayYear?: string;
    birthdayMonth?: string;
    birthdayDay?: string;
    gakunen: string
    isKyugaku?: boolean;
    kyugaku: SinseisyaInfoKyugakuVo;
    isRyunen?: boolean;
    address: AddressVo;
    telHome: string;
    telLab: string;
    sidoTeacherName: string;
    addressFamily: AddressVo;
    telFamily: string;
}

export interface SyogakukinInfoVo {
    syogakukins: Array<SyogakukinVo>
}

export interface SyogakukinVo {
    name: string
    amountPerMonth?: string
    times?: string
    startYear?: string
    startMonth?: string
    endYear?: string
    endMonth?: string
}

export interface SeisekiInfoVo {
    SA: string
    B: string
    C: string
    total: string
}

export interface SinseisyaInfoKyugakuVo {
    startYear?: string;
    startMonth?: string;
    endYear?: string;
    endMonth?: string;
    riyu: string;
}

export interface AddressVo {
    zipcode: string;
    address: string;
}

export interface FamilyInfoVo {
    honnin: FamilyInfoHonninVo
    members: Array<MemberVo>
}

export interface FamilyInfoHonninVo {
    syunyuAriNasi?: boolean;
    kakuteisinkokuAriNasi?: boolean;
    syunyuInfo: SyunyuInfoVo
    tugakuFromJitaku?: boolean;
}

export interface MemberSyugakusyaVo {
    schoolType?: SchoolTypeEnum;
    schoolName?: string;
    gakunen?: string;
    settiKubun?: SettiKubunEnum;
    tugakuFromJitaku?: boolean;
    jugyoryoMenjoState?: JugyoryoMenjoStateEnum
    jugyoryoForHalfYear?: string;
}

export interface MemberVo {
    zokugara: ZokugaraEnum;
    zokugaraOther: string;
    isSyugakusya?: boolean;
    name: string;
    age?: string;
    syugakusya?: MemberSyugakusyaVo;
    syugakusyaIgai?: MemberSyugakusyaIgaiVo;
}

export interface SyunyuInfoVo {
    kyuyo: SyunyuInfoItemVo;
    eigyo: SyunyuInfoItemVo;
    norin: SyunyuInfoItemVo;
    hudosan: SyunyuInfoItemVo;
    risi: SyunyuInfoItemVo;
    haito: SyunyuInfoItemVo;
    nenkin: SyunyuInfoItemOtherVo;
    koyohoken: SyunyuInfoItemOtherVo;
    kakusyuteate: SyunyuInfoItemOtherVo;
    syogakukin: SyunyuInfoItemOtherVo;
    sinsekitounosien: SyunyuInfoItemOtherVo;
    yotyokin: SyunyuInfoItemOtherVo;
    other: SyunyuInfoItemOtherVo;
    taisyokukinn: SyunyuInfoItemOtherVo;
    hokenkin: SyunyuInfoItemOtherVo;
}

export interface SyunyuInfoItemVo {
    kubunCode: SyunyuKubunEnum;
    field: SyunyuInfoItemFieldVo;
}

export interface SyunyuInfoItemOtherVo {
    kubunCode: SyunyuKubunEnum;
    fields: Array<SyunyuInfoItemFieldVo>;
}

export interface SyunyuInfoItemFieldVo {
    kubunOther: string;
    amount: string;
    syotokuYear: string;
    syotokuMonth: string;
}

export interface MemberSyugakusyaIgaiVo {
    jobName: string;
    jobYears?: string;
    syunyuAriNasi?: boolean;
    kakuteisinkokuAriNasi?: boolean;
    syunyuInfo: SyunyuInfoVo
}

export interface NyugakuryoInfoVo {
    riyu: string
}

export interface JugyoryoInfoVo {
    riyu: string
}

// export interface AYosiki1InfoVo {
//     agreement: boolean;
//     isSienzumi?: boolean;
//     sienzumi: SienzumiVo;
//     menjoBefore?: boolean;
//     mosikomiType?: MosikomiTypeEnum;
//     uketukeNumber: string;
//     syogakuseiNumber: string;
// }

export interface SienzumiVo {
    schoolName: string;
    startYear: string;
    startMonth: string;
    endYear: string;
    endMonth: string;
    months: string;
}

export interface TokubetuKojoInfoVo {
    bosiHusi: BosiHusiVo;
    syogaisya: Array<SyogaisyaVo>;
    seikatuhogo?: boolean;
    dokuritu?: boolean;
    tyokiryoyo: Array<TyokiryoyoVo>;
    bekkyo: BekkyoVo;
    saigaihigai: SaigaihigaiVo;
}

export interface BosiHusiVo {
    mother?: BosiHusiParentVo,
    father?: BosiHusiParentVo,
}

export interface BosiHusiParentVo {
    beturiType?: BeturiTypeEnum;
    jikiYaer: string;
    jikiMonth: string;
    riyu: string;
}

export interface SyogaisyaVo {
    zokugara: ZokugaraEnum;
    zokugaraOther: string;
    type?: SyogaisyaTypeEnum;
    typeOther: string;
    tetyoNumber: string;
}

export interface TyokiryoyoVo {
    zokugara: ZokugaraEnum;
    zokugaraOther: string;
    type?: TyokiryoyoTypeEnum | "";
    startYear: string;
    startMonth: string;
    amountPerHalfYear: string;
}

export interface BekkyoVo {
    riyu: string;
    amountPerMonth: string;
}

export interface SaigaihigaiVo {
    riyu: string;
    amount: string;
}

export function createBlankMember(): MemberVo {
    return {
        "syugakusyaIgai": {
            "jobName": "",
            "syunyuInfo": createBlankSyunyuInfo(),
            "syunyuAriNasi": undefined,
            "jobYears": undefined
        },
        "zokugara": ZokugaraEnum.父,
        "name": "",
        "zokugaraOther": "",
        "syugakusya": {
            "tugakuFromJitaku": undefined,
            "gakunen": undefined,
            "settiKubun": undefined,
            "schoolType": undefined,
            "schoolName": undefined
        },
        "age": undefined,
        "isSyugakusya": undefined
    }
}

export function createBlankSyunyuInfoField(): SyunyuInfoItemFieldVo{
    return {
        kubunOther: "",
        amount: "",
        syotokuYear: "",
        syotokuMonth: "",
    }
}

export function createBlankSyunyuInfoFieldOther(): Array<SyunyuInfoItemFieldVo>{
    return (
        [
            {
                kubunOther: "",
                amount: "",
                syotokuYear: "",
                syotokuMonth: "",
            },
            {
                kubunOther: "",
                amount: "",
                syotokuYear: "",
                syotokuMonth: "",
            },
            {
                kubunOther: "",
                amount: "",
                syotokuYear: "",
                syotokuMonth: "",
            }
        ]
    )
}

export function createBlankSyunyuInfo(): SyunyuInfoVo {
    return {
        kyuyo: {kubunCode: SyunyuKubunEnum.給料賃金, field: createBlankSyunyuInfoField()},
        eigyo: {kubunCode: SyunyuKubunEnum.営業, field: createBlankSyunyuInfoField()},
        norin: {kubunCode: SyunyuKubunEnum.農林水産業, field: createBlankSyunyuInfoField()},
        hudosan: {kubunCode: SyunyuKubunEnum.不動産, field: createBlankSyunyuInfoField()},
        risi: {kubunCode: SyunyuKubunEnum.利子配当, field: createBlankSyunyuInfoField()},
        haito: {kubunCode: SyunyuKubunEnum.利子配当, field: createBlankSyunyuInfoField()},
        nenkin: {kubunCode: SyunyuKubunEnum.年金恩給, fields: createBlankSyunyuInfoFieldOther()},
        koyohoken: {kubunCode: SyunyuKubunEnum.雇用保険, fields: createBlankSyunyuInfoFieldOther()},
        kakusyuteate: {kubunCode: SyunyuKubunEnum.各種手当, fields: createBlankSyunyuInfoFieldOther()},
        syogakukin: {kubunCode: SyunyuKubunEnum.奨学金, fields: createBlankSyunyuInfoFieldOther()},
        sinsekitounosien: {kubunCode: SyunyuKubunEnum.親戚等の援助, fields: createBlankSyunyuInfoFieldOther()},
        yotyokin: {kubunCode: SyunyuKubunEnum.預貯金から, fields: createBlankSyunyuInfoFieldOther()},
        other: {kubunCode: SyunyuKubunEnum.その他, fields: createBlankSyunyuInfoFieldOther()},
        taisyokukinn: {kubunCode: SyunyuKubunEnum.退職金, fields: createBlankSyunyuInfoFieldOther()},
        hokenkin: {kubunCode: SyunyuKubunEnum.保険金, fields: createBlankSyunyuInfoFieldOther()},
    }
}
