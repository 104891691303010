import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum AYosikiInfoTextEnum {
    私は貴学に対し大学等における修学の支援に関する法律による授業料等減免の対象者としての認定を申請します = "私は、貴学に対し、大学等における修学の支援に関する法律による授業料等減免の対象者としての認定を申請します。",
    申請にあたって私は以上の事項を確認し理解しています = "申請にあたって、私は以上の事項を確認し、理解しています。",
    この中請書の記載事項は事実と相違ありません = "この中請書の記載事項は事実と相違ありません。",
    授業料等減免の対象者の認定手続きにおいて = "授業料等減免の対象者の認定手続きにおいて",
    現在他の学校において = "現在、他の学校において",
    過去に本制度の支援を受けたことがありますか = "過去に本制度の支援を受けたことがありますか？",
    学校名 = "学校名",
    期間 = "期間",
    月数 = "月数",
    過去に本制度の入学金免除を受けたことがありますか = "過去に本制度の入学金免除を受けたことがありますか？",
    受付番号 = "受付番号",
    奨学生番号 = "奨学生番号",
}

export function AYosikiInfoTextEnumToNameJaAndEn(e: AYosikiInfoTextEnum): string {
    switch (e) {
        case AYosikiInfoTextEnum.私は貴学に対し大学等における修学の支援に関する法律による授業料等減免の対象者としての認定を申請します:
            return convertEnumNameToStr({
                ja: "私は、貴学に対し、大学等における修学の支援に関する法律による授業料等減免の対象者としての認定を申請します。",
                en: "I request approval from your University as a student eligible for reduction or waiver of tuition in accordance with the Act to Support Study at Universities, etc."
            });
        case AYosikiInfoTextEnum.この中請書の記載事項は事実と相違ありません:
            return convertEnumNameToStr({
                ja: "この中請書の記載事項は事実と相違ありません。なお、申請書の記載事項に事実と相違があった場合、認定を取り消され、減免を打ち切られることがあるとともに、在学する学校から減免を受けた金額の支払を求められることがあることを承知しています。",
                en: "All information indicated in this application is correct. Furthermore, I am aware that if there are any\n" +
                    "discrepancies between the information indicated in the application and the actual facts, my approval status may be cancelled, any reduction or waivers may be terminated, and payment of the monetary amounts for reduction or waivers may be required by the school I am enrolled in."
            });
        case AYosikiInfoTextEnum.授業料等減免の対象者の認定手続きにおいて:
            return convertEnumNameToStr({
                ja: "授業料等減免の対象者の認定手続きにおいて、独立行政法人日本学生支援機構（以下、「機構」という。）を通じ、東京農工大学が機構の保有する私の給付奨学金に関する情報の送付を受けること、及び機構が東京農工大学の保有する私の授業料等減免等に関する情報の送付を受けることに同意します。",
                en: "I agree that as part of procedures for recognition of eligibility for reduction or exemption of tuition, etc., the Tokyo University of Agriculture and Technology will, through the Japan Student Services Organization (hereinafter referred to as “the Organization”), receive information on me possessed and sent by the Organization related to scholarship grants, and that the Organization will receive information on me possessed and sent by the Tokyo University of Agriculture and Technology related to reduction or waivers of tuition, etc."
            });
        case AYosikiInfoTextEnum.現在他の学校において:
            return convertEnumNameToStr({
                ja: "現在、他の学校において、大学等における修学の支援に関する法律による授業料等減免を受けておらず、当該授業料等減免の対象者の認定申請中でもありません。",
                en: "I am currently not receiving reduction or waivers of tuition, etc. from other schools in accordance with the Act to Support Study at Universities, etc., and I am currently not in the process of applying for recognition of eligibility for such reduction or waivers of tuition, etc.\n"
            });
        case AYosikiInfoTextEnum.申請にあたって私は以上の事項を確認し理解しています:
            return convertEnumNameToStr({
                ja: "申請にあたって、私は以上の事項を確認し、理解しています。",
                en: "I have read and fully understand the following items for this application."
            });
        case AYosikiInfoTextEnum.過去に本制度の支援を受けたことがありますか:
            return convertEnumNameToStr({ja: "過去に本制度の支援を受けたことがありますか？", en: "Have you received Support by this System in the past"});
        case AYosikiInfoTextEnum.学校名:
            return convertEnumNameToStr({ja: "学校名", en: "School Name"});
        case AYosikiInfoTextEnum.期間:
            return convertEnumNameToStr({ja: "期間", en: "Period"});
        case AYosikiInfoTextEnum.月数:
            return convertEnumNameToStr({ja: "月数", en: "Number of Months"});
        case AYosikiInfoTextEnum.過去に本制度の入学金免除を受けたことがありますか:
            return convertEnumNameToStr({
                ja: "過去に本制度の入学金免除を受けたことがありますか？",
                en: "Have you received an enrollment fee reduction or waiver under this system in the past?"
            });
        case AYosikiInfoTextEnum.受付番号:
            return convertEnumNameToStr({ja: "受付番号", en: "reception number"});
        case AYosikiInfoTextEnum.奨学生番号:
            return convertEnumNameToStr({ja: "奨学生番号", en: "scholarship recipient number"});
        default :
            return ""
    }
}

// 申請にあたって、私は以上の事項を確認し、理解しています。
// I have read and fully understand the following items for this application.