/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSyunyuInfoItemField,
    SwSyunyuInfoItemFieldFromJSON,
    SwSyunyuInfoItemFieldFromJSONTyped,
    SwSyunyuInfoItemFieldToJSON,
} from './';

/**
 * その他の収入所得 入力項目は3つ固定
 * @export
 * @interface SwSyunyuInfoItemOther
 */
export interface SwSyunyuInfoItemOther {
    /**
     * enum
     * @type {string}
     * @memberof SwSyunyuInfoItemOther
     */
    kubunCode: string;
    /**
     * 3つ固定
     * @type {Array<SwSyunyuInfoItemField>}
     * @memberof SwSyunyuInfoItemOther
     */
    fields: Array<SwSyunyuInfoItemField>;
}

export function SwSyunyuInfoItemOtherFromJSON(json: any): SwSyunyuInfoItemOther {
    return SwSyunyuInfoItemOtherFromJSONTyped(json, false);
}

export function SwSyunyuInfoItemOtherFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyunyuInfoItemOther {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kubunCode': json['kubunCode'],
        'fields': ((json['fields'] as Array<any>).map(SwSyunyuInfoItemFieldFromJSON)),
    };
}

export function SwSyunyuInfoItemOtherToJSON(value?: SwSyunyuInfoItemOther | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kubunCode': value.kubunCode,
        'fields': ((value.fields as Array<any>).map(SwSyunyuInfoItemFieldToJSON)),
    };
}


