import {AuthTypeEnum} from "common/enums/AuthTypeEnum";
import {handleCommonError} from "common/functions/error_handler/handleCommonError";
import {OsirasesDetailVo} from "components/organisms/common/OsiraseDetailModal";
import {OsirasesApi} from "openapi/apis";
import {SwOsirasesDetailResponse, SwOsirasesResponse} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";

export interface OsiraseStoreVo {
    osirases: OsirasesVo;
    osiraseDetail?: OsirasesDetailVo;
    osiraseDetailModalState: boolean
}

export interface OsirasesVo {
    osirases: Array<OsiraseVo>;
}

export interface OsiraseVo {
    id: string;
    title: string;
    createdAt: string;
}

class OsiraseStore extends Store<OsiraseStoreVo> {

    private api = new OsirasesApi(apiConfiguration);

    constructor() {
        super({
            osirases: {
                osirases: []
            },
            osiraseDetail: undefined,
            osiraseDetailModalState: false
        })
    }

    updateOsirases(osirases: OsirasesVo) {
        this.dispatch(() => ({osirases: osirases}));
    }

    updateOsiraseDetail(osirasesDetail: OsirasesDetailVo) {
        this.dispatch(() => ({osiraseDetail: osirasesDetail}));
    }

    updateOsiraseDetailModalState(value: boolean) {
        this.dispatch(() => ({osiraseDetailModalState: value}));
    }

    async getOsirases(authType: AuthTypeEnum) {
        try {
            let res: SwOsirasesResponse = await this.api.getOsirases();
            this.updateOsirases(res);
        } catch (res) {
            handleCommonError(res, authType)
        }
    }

    async getOsirase(id: string, authType: AuthTypeEnum) {
        try {
            let res: SwOsirasesDetailResponse = await this.api.getOsirase({osiraseId: id});
            this.updateOsiraseDetail({...res, uploadedFiles: res.attachmentFiles});
            osiraseStore.updateOsiraseDetailModalState(true)
        } catch (res) {
            handleCommonError(res, authType)
        }
    }

}

export const osiraseStore = new OsiraseStore();
