/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SyoruiConfirmationFormHonnin,
    SyoruiConfirmationFormHonninFromJSON,
    SyoruiConfirmationFormHonninFromJSONTyped,
    SyoruiConfirmationFormHonninToJSON,
    SyoruiConfirmationFormHonninRyugakusei,
    SyoruiConfirmationFormHonninRyugakuseiFromJSON,
    SyoruiConfirmationFormHonninRyugakuseiFromJSONTyped,
    SyoruiConfirmationFormHonninRyugakuseiToJSON,
    SyoruiConfirmationFormMember,
    SyoruiConfirmationFormMemberFromJSON,
    SyoruiConfirmationFormMemberFromJSONTyped,
    SyoruiConfirmationFormMemberToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSyoruiConfirmationFormResponse
 */
export interface SwSyoruiConfirmationFormResponse {
    /**
     * 
     * @type {SyoruiConfirmationFormHonnin}
     * @memberof SwSyoruiConfirmationFormResponse
     */
    honnin: SyoruiConfirmationFormHonnin;
    /**
     * 
     * @type {SyoruiConfirmationFormHonninRyugakusei}
     * @memberof SwSyoruiConfirmationFormResponse
     */
    honninRyugakusei: SyoruiConfirmationFormHonninRyugakusei;
    /**
     * 
     * @type {Array<SyoruiConfirmationFormMember>}
     * @memberof SwSyoruiConfirmationFormResponse
     */
    members: Array<SyoruiConfirmationFormMember>;
}

export function SwSyoruiConfirmationFormResponseFromJSON(json: any): SwSyoruiConfirmationFormResponse {
    return SwSyoruiConfirmationFormResponseFromJSONTyped(json, false);
}

export function SwSyoruiConfirmationFormResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyoruiConfirmationFormResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'honnin': SyoruiConfirmationFormHonninFromJSON(json['honnin']),
        'honninRyugakusei': SyoruiConfirmationFormHonninRyugakuseiFromJSON(json['honninRyugakusei']),
        'members': ((json['members'] as Array<any>).map(SyoruiConfirmationFormMemberFromJSON)),
    };
}

export function SwSyoruiConfirmationFormResponseToJSON(value?: SwSyoruiConfirmationFormResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'honnin': SyoruiConfirmationFormHonninToJSON(value.honnin),
        'honninRyugakusei': SyoruiConfirmationFormHonninRyugakuseiToJSON(value.honninRyugakusei),
        'members': ((value.members as Array<any>).map(SyoruiConfirmationFormMemberToJSON)),
    };
}


