/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20011,
    InlineResponse20011FromJSON,
    InlineResponse20011ToJSON,
    SwOfficerMail,
    SwOfficerMailFromJSON,
    SwOfficerMailToJSON,
    SwOfficerMailDetail,
    SwOfficerMailDetailFromJSON,
    SwOfficerMailDetailToJSON,
    SwOfficerMailPostRequest,
    SwOfficerMailPostRequestFromJSON,
    SwOfficerMailPostRequestToJSON,
} from '../models';

export interface GetOfficerMailRequest {
    mailId: string;
}

export interface PostOfficerMailsRequest {
    swOfficerMailPostRequest: SwOfficerMailPostRequest;
}

/**
 * no description
 */
export class OfficerMailsApi extends runtime.BaseAPI {

    /**
     * メールの詳細情報を取得 メールの情報、送信が失敗したメールアドレス一覧を返す。 
     */
    async getOfficerMailRaw(requestParameters: GetOfficerMailRequest): Promise<runtime.ApiResponse<SwOfficerMailDetail>> {
        if (requestParameters.mailId === null || requestParameters.mailId === undefined) {
            throw new runtime.RequiredError('mailId','Required parameter requestParameters.mailId was null or undefined when calling getOfficerMail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/mails/{mailId}`.replace(`{${"mailId"}}`, encodeURIComponent(String(requestParameters.mailId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOfficerMailDetailFromJSON(jsonValue));
    }

    /**
     * メールの詳細情報を取得 メールの情報、送信が失敗したメールアドレス一覧を返す。 
     */
    async getOfficerMail(requestParameters: GetOfficerMailRequest): Promise<SwOfficerMailDetail> {
        const response = await this.getOfficerMailRaw(requestParameters);
        return await response.value();
    }

    /**
     * メール一覧 全ての送信メール(成功、失敗も含め)を返す。 順序は直近のものが上に来るように。 
     */
    async getOfficerMailsRaw(): Promise<runtime.ApiResponse<SwOfficerMail>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/mails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOfficerMailFromJSON(jsonValue));
    }

    /**
     * メール一覧 全ての送信メール(成功、失敗も含め)を返す。 順序は直近のものが上に来るように。 
     */
    async getOfficerMails(): Promise<SwOfficerMail> {
        const response = await this.getOfficerMailsRaw();
        return await response.value();
    }

    /**
     * メールの送信 ルート権限は、すべての部局へメール送信可能 職員は、アカウント権限の部局のみメール送信可能 
     */
    async postOfficerMailsRaw(requestParameters: PostOfficerMailsRequest): Promise<runtime.ApiResponse<InlineResponse20011>> {
        if (requestParameters.swOfficerMailPostRequest === null || requestParameters.swOfficerMailPostRequest === undefined) {
            throw new runtime.RequiredError('swOfficerMailPostRequest','Required parameter requestParameters.swOfficerMailPostRequest was null or undefined when calling postOfficerMails.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/officer/mails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwOfficerMailPostRequestToJSON(requestParameters.swOfficerMailPostRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20011FromJSON(jsonValue));
    }

    /**
     * メールの送信 ルート権限は、すべての部局へメール送信可能 職員は、アカウント権限の部局のみメール送信可能 
     */
    async postOfficerMails(requestParameters: PostOfficerMailsRequest): Promise<InlineResponse20011> {
        const response = await this.postOfficerMailsRaw(requestParameters);
        return await response.value();
    }

}
