/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwUploadedFile,
    SwUploadedFileFromJSON,
    SwUploadedFileFromJSONTyped,
    SwUploadedFileToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {SwUploadedFile}
     * @memberof InlineResponse20010
     */
    uploadedFile?: SwUploadedFile;
}

export function InlineResponse20010FromJSON(json: any): InlineResponse20010 {
    return InlineResponse20010FromJSONTyped(json, false);
}

export function InlineResponse20010FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20010 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uploadedFile': !exists(json, 'uploadedFile') ? undefined : SwUploadedFileFromJSON(json['uploadedFile']),
    };
}

export function InlineResponse20010ToJSON(value?: InlineResponse20010 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uploadedFile': SwUploadedFileToJSON(value.uploadedFile),
    };
}


