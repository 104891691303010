import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum LoginLabelTextEnum {
    新規アカウント登録 = "新規アカウント登録",
    学籍番号 = "学籍番号",
    ログイン画面へ戻る = "ログイン画面へ戻る",
    パスワード = "パスワード",
    パスワード確認 = "パスワード確認",
    電話番号 = "電話番号",
    ハイフンなしで入力してください = "ハイフンなしで入力してください",
    メールアドレス = "メールアドレス",
    パスワード再設定 = "パスワード再設定",
    パスワードを忘れた方はこちら = "パスワードを忘れた方はこちら",
    ログイン = "ログイン",
    はじめての方はこちら = "はじめての方はこちら",
    パスワード組み合わせ = "パスワード組み合わせ"
}

export function LoginLabelTextEnumToNameJaAndEn(e: LoginLabelTextEnum): string {
    switch (e) {
        case LoginLabelTextEnum.新規アカウント登録:
            return convertEnumNameToStr({ja: "新規アカウント登録", en: "New Account Registration"});
        case LoginLabelTextEnum.学籍番号:
            return convertEnumNameToStr({ja: "学籍番号", en: "Student Number"});
        case LoginLabelTextEnum.ログイン画面へ戻る:
            return convertEnumNameToStr({ja: "ログイン画面へ戻る", en: "Return to Login screen"});
        case LoginLabelTextEnum.パスワード:
            return convertEnumNameToStr({ja: "パスワード", en: "Password"});
        case LoginLabelTextEnum.パスワード確認:
            return convertEnumNameToStr({ja: "パスワード確認", en: "Re-enter Password"});
        case LoginLabelTextEnum.電話番号:
            return convertEnumNameToStr({ja: "電話番号", en: "Telephone Number"});
        case LoginLabelTextEnum.ハイフンなしで入力してください:
            return convertEnumNameToStr({ja: "ハイフンなしで入力してください。", en: "without hyphens"});
        case LoginLabelTextEnum.メールアドレス:
            return convertEnumNameToStr({ja: "メールアドレス", en: "E-mail Address"});
        case LoginLabelTextEnum.パスワード再設定:
            return convertEnumNameToStr({ja: "パスワード再設定", en: "Reset Password"});
        case LoginLabelTextEnum.ログイン:
            return convertEnumNameToStr({ja: "ログイン", en: "Login"});
        case LoginLabelTextEnum.はじめての方はこちら:
            return convertEnumNameToStr({ja: "はじめての方はこちら", en: "For the first time"});
        case LoginLabelTextEnum.パスワードを忘れた方はこちら:
            return convertEnumNameToStr({ja: "パスワードを忘れた方はこちら", en: "If you have forgotten your password, click here"});
        case LoginLabelTextEnum.パスワード組み合わせ:
            return convertEnumNameToStr({
                ja: "8〜24文字の半角英数字の組み合わせを入力してください。",
                en: "Please enter a combination of 8 to 24 alphanumeric characters."
            });
        default :
            return ""
    }
}

