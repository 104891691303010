import React, {FC} from "react";

export const MessageContentKikanGai: FC = () => {
    return (
        <div>期間外です。<br/>
            Outside the period</div>
    )
};

// 入力期間
export const MessageContentNyuryokuKikan: FC = () => {
    return (
        <div>入力期間内に入力を行なってください。<br/>
            入力が終わりましたら保存後、確定してください。<br/>
            Enter your application information during the data entry period.<br/>
            After you have finished entering your information, save and confirm it.
        </div>
    )
};

// 入力期間 確定
export const MessageContentNyuryokuKikanKakutei: FC = () => {
    return (
        <div>入力を確定しました。<br/>
            申請期間内に以下の「申請が必要な書類」のPDFを印刷して記入の上、担当窓口へ提出してください。<br/>
            入力内容を訂正したい場合は、事務までお問い合わせください。<br/>
            After checking the items under “Documents Requiring Application”, print the PDF files of the documents which
            must be submitted, complete, and then submit them to the appropriate administrative office.
        </div>
    )
};

// 申請期間
export const MessageContentSinseiKikan: FC = () => {
    return (
        <div>入力期間内に入力が確定されませんでした。<br/>
            申請の意思がある場合は、申請期間内に事務まで申し上げてください。<br/>
            Data entry was not confirmed within the data entry period.<br/>
            If you wish to apply, inform the administration office during the application period.
        </div>
    )
};

// 申請期間 確定
export const MessageContentSinseiKikanKakutei: FC = () => {
    return (
        <div>「申請が必要な書類」の項目を確認し、申請期間内に以下の「申請が必要な書類」のPDFを印刷して記入の上、担当窓口へ提出してください。<br/>
            申請内容を確認し、内容に不備がなければ申請が完了します。<br/>
            申請内容に不備がある場合は、訂正期間内に訂正・申請を再度行なってください。<br/>
            After checking the items under “Documents Requiring Application”, print the PDF files of the documents which
            must be submitted, complete, and then submit them to the appropriate administrative office.
        </div>
    )
};

// 訂正期間
export const MessageContentTeiseiKikan: FC = () => {
    return (
        <div>
            訂正期間内に入力して申請を行なってください。<br/>
            訂正期間内に入力できない場合は、事務までお問い合わせください。<br/>
            Enter your application information and apply during the correction period.<br/>
            If you cannot enter your information within the correction period, please contact the administration office.
        </div>
    )
};


// 訂正期間 確定
export const MessageContentTeiseiKikanKakutei: FC = () => {
    return (
        <div>
            「申請が必要な書類」の項目を確認し、訂正期間内に以下の「申請が必要な書類」のPDFを印刷して記入の上、担当窓口へ提出してください。<br/>
            申請内容を確認し、内容に不備がなければ申請が完了します。<br/>
            入力内容を訂正したい場合は、事務までお問い合わせください。<br/>
            After checking the items under “Documents Requiring Application”, print the PDF files of the documents which
            must be submitted, complete, and then submit them to the appropriate administrative office.
        </div>
    )
};

