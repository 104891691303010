/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwUploadedFile,
    SwUploadedFileFromJSON,
    SwUploadedFileFromJSONTyped,
    SwUploadedFileToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOsirasesDetailResponse
 */
export interface SwOsirasesDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof SwOsirasesDetailResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SwOsirasesDetailResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SwOsirasesDetailResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SwOsirasesDetailResponse
     */
    body: string;
    /**
     * 
     * @type {Array<SwUploadedFile>}
     * @memberof SwOsirasesDetailResponse
     */
    attachmentFiles: Array<SwUploadedFile>;
}

export function SwOsirasesDetailResponseFromJSON(json: any): SwOsirasesDetailResponse {
    return SwOsirasesDetailResponseFromJSONTyped(json, false);
}

export function SwOsirasesDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOsirasesDetailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'createdAt': json['createdAt'],
        'body': json['body'],
        'attachmentFiles': ((json['attachmentFiles'] as Array<any>).map(SwUploadedFileFromJSON)),
    };
}

export function SwOsirasesDetailResponseToJSON(value?: SwOsirasesDetailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'createdAt': value.createdAt,
        'body': value.body,
        'attachmentFiles': ((value.attachmentFiles as Array<any>).map(SwUploadedFileToJSON)),
    };
}


