import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {PostStudentCreateAccountRequest, StudentCreateAccountApi} from "openapi/apis";
import {SwStudentCreateAccountPostErrorMessage} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";
import {getParam} from "utilities/getParam";

export interface CreateAccountStoreVo {
    form: CreateAccountFormVo
    errorMessages?: SwStudentCreateAccountPostErrorMessage
}

export interface CreateAccountFormVo {
    password: string
    passwordComfirm: string
    telGakuseiNyuyroku: string
    emailGakuseiNyuyroku: string
}

class CreateAccountStore extends Store<CreateAccountStoreVo> {

    private api = new StudentCreateAccountApi(apiConfiguration);

    constructor() {
        super({
            form: {
                password: "",
                passwordComfirm: "",
                telGakuseiNyuyroku: "",
                emailGakuseiNyuyroku: ""
            },
        })
    }

    updateForm(part: Partial<CreateAccountFormVo>) {
        const newForm = {
            ...this.currentState.form,
            ...part
        };
        this.dispatch(() => ({form: newForm}));
    }

    updateErrorMessages(errorMessages: Partial<SwStudentCreateAccountPostErrorMessage> | undefined) {
        const newError: SwStudentCreateAccountPostErrorMessage | undefined = {
            ...this.currentState.errorMessages,
            ...errorMessages
        };
        this.dispatch(() => ({errorMessages: newError}));
    }

    async postStudentCreateAccount() {
        const token = getParam("token") || "";
        const param: PostStudentCreateAccountRequest = {
            swStudentCreateAccountPostRequest: {
                token: token,
                password: this.currentState.form.password,
                passwordComfirm: this.currentState.form.passwordComfirm,
                telGakuseiNyuyroku: this.currentState.form.telGakuseiNyuyroku,
                emailGakuseiNyuyroku: this.currentState.form.emailGakuseiNyuyroku,
            }
        };
        try {
            const res = await this.api.postStudentCreateAccount(param);
            if (!res.error) {
                window.location.assign(URL_PATHS_STUDENT.create_account_complete);
                this.updateErrorMessages(undefined)
            } else {
                this.updateErrorMessages(res.error)
            }
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const createAccountStore = new CreateAccountStore();
