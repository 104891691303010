/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiFormCommonBessi2ErrorMessage
 */
export interface SwAYosikiFormCommonBessi2ErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormCommonBessi2ErrorMessage
     */
    isBeforeEnteredTwoOrMore?: Array<SwErrorMessage>;
}

export function SwAYosikiFormCommonBessi2ErrorMessageFromJSON(json: any): SwAYosikiFormCommonBessi2ErrorMessage {
    return SwAYosikiFormCommonBessi2ErrorMessageFromJSONTyped(json, false);
}

export function SwAYosikiFormCommonBessi2ErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormCommonBessi2ErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isBeforeEnteredTwoOrMore': !exists(json, 'isBeforeEnteredTwoOrMore') ? undefined : ((json['isBeforeEnteredTwoOrMore'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwAYosikiFormCommonBessi2ErrorMessageToJSON(value?: SwAYosikiFormCommonBessi2ErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isBeforeEnteredTwoOrMore': value.isBeforeEnteredTwoOrMore === undefined ? undefined : ((value.isBeforeEnteredTwoOrMore as Array<any>).map(SwErrorMessageToJSON)),
    };
}


