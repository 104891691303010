import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum AYosikiInfoLabelTextEnum {
    同意 = "同意",
    過去の本制度の支援状況 = "過去の本制度の支援状況",
    過去に本制度の支援を受けた学校名期間該当者のみ入力 = "過去に本制度の支援を受けた学校名、期間(該当者のみ入力)",
    過去の本制度の入学金免除状況 = "過去の本制度の入学金免除状況",
    過去に本制度の入学金免除 = "過去に本制度の入学金免除",
}

export function AYosikiInfoLabelTextEnumToNameJaAndEn(e: AYosikiInfoLabelTextEnum): string {
    switch (e) {
        case AYosikiInfoLabelTextEnum.同意:
            return convertEnumNameToStr({ja: "同意", en: "Agreement"});
        case AYosikiInfoLabelTextEnum.過去の本制度の支援状況:
            return convertEnumNameToStr({ja: "過去の本制度の支援状況", en: "Past Assistance from this System"});
        case AYosikiInfoLabelTextEnum.過去に本制度の支援を受けた学校名期間該当者のみ入力:
            return convertEnumNameToStr({
                ja: "過去に本制度の支援を受けた学校名、期間(該当者のみ入力)",
                en: "School Name and Duration for Past Support Received by this System(Only enter information if applicable)"
            });
        case AYosikiInfoLabelTextEnum.過去の本制度の入学金免除状況:
            return convertEnumNameToStr({
                ja: "過去の本制度の入学金免除状況",
                en: "Status of enrollment fee reduction or waiver under this system in the past"
            });
        case AYosikiInfoLabelTextEnum.過去に本制度の入学金免除:
            return convertEnumNameToStr({ja: "過去に本制度の入学金免除", en: "Enrollment fee reduction or waiver under this system in the past"});
        default :
            return ""
    }
}

// Have you received an enrollment fee reduction or waiver under this system in the past?
