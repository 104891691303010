/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwMailRow
 */
export interface SwMailRow {
    /**
     * 
     * @type {string}
     * @memberof SwMailRow
     */
    id: string;
    /**
     * メールの状態を表現する GroupMailStateEnum
     * @type {string}
     * @memberof SwMailRow
     */
    stateCode: string;
    /**
     * 送信開始時間
     * @type {string}
     * @memberof SwMailRow
     */
    sendAt: string;
    /**
     * enum
     * @type {string}
     * @memberof SwMailRow
     */
    mailTo: string;
    /**
     * enum 全体の場合はundefined
     * @type {string}
     * @memberof SwMailRow
     */
    bukyokuCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SwMailRow
     */
    title: string;
}

export function SwMailRowFromJSON(json: any): SwMailRow {
    return SwMailRowFromJSONTyped(json, false);
}

export function SwMailRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwMailRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stateCode': json['stateCode'],
        'sendAt': json['sendAt'],
        'mailTo': json['mailTo'],
        'bukyokuCode': !exists(json, 'bukyokuCode') ? undefined : json['bukyokuCode'],
        'title': json['title'],
    };
}

export function SwMailRowToJSON(value?: SwMailRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'stateCode': value.stateCode,
        'sendAt': value.sendAt,
        'mailTo': value.mailTo,
        'bukyokuCode': value.bukyokuCode,
        'title': value.title,
    };
}


