/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20022,
    InlineResponse20022FromJSON,
    InlineResponse20022ToJSON,
    SwAYosikiFormRequest,
    SwAYosikiFormRequestFromJSON,
    SwAYosikiFormRequestToJSON,
    SwAYosikiFormResponse,
    SwAYosikiFormResponseFromJSON,
    SwAYosikiFormResponseToJSON,
} from '../models';

export interface PostStudentAYosikiFormRequest {
    swAYosikiFormRequest: SwAYosikiFormRequest;
}

/**
 * no description
 */
export class StudentAYosikiFormApi extends runtime.BaseAPI {

    /**
     * A様式フォームデータ
     */
    async getStudentAYosikiFormRaw(): Promise<runtime.ApiResponse<SwAYosikiFormResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/a_yosiki_form`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwAYosikiFormResponseFromJSON(jsonValue));
    }

    /**
     * A様式フォームデータ
     */
    async getStudentAYosikiForm(): Promise<SwAYosikiFormResponse> {
        const response = await this.getStudentAYosikiFormRaw();
        return await response.value();
    }

    /**
     * A様式フォームデータの保存 
     */
    async postStudentAYosikiFormRaw(requestParameters: PostStudentAYosikiFormRequest): Promise<runtime.ApiResponse<InlineResponse20022>> {
        if (requestParameters.swAYosikiFormRequest === null || requestParameters.swAYosikiFormRequest === undefined) {
            throw new runtime.RequiredError('swAYosikiFormRequest','Required parameter requestParameters.swAYosikiFormRequest was null or undefined when calling postStudentAYosikiForm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/student/a_yosiki_form`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwAYosikiFormRequestToJSON(requestParameters.swAYosikiFormRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20022FromJSON(jsonValue));
    }

    /**
     * A様式フォームデータの保存 
     */
    async postStudentAYosikiForm(requestParameters: PostStudentAYosikiFormRequest): Promise<InlineResponse20022> {
        const response = await this.postStudentAYosikiFormRaw(requestParameters);
        return await response.value();
    }

}
