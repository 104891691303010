/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyoruiConfirmationFormHonninRyugakusei
 */
export interface SyoruiConfirmationFormHonninRyugakusei {
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonninRyugakusei
     */
    isEnrolledInJapaneseUniversityBefore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonninRyugakusei
     */
    isJapaneseHaiguusyaHasIncome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonninRyugakusei
     */
    isPartTimeJob?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonninRyugakusei
     */
    isFinancialAssistanceFromRelatives?: boolean;
}

export function SyoruiConfirmationFormHonninRyugakuseiFromJSON(json: any): SyoruiConfirmationFormHonninRyugakusei {
    return SyoruiConfirmationFormHonninRyugakuseiFromJSONTyped(json, false);
}

export function SyoruiConfirmationFormHonninRyugakuseiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyoruiConfirmationFormHonninRyugakusei {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEnrolledInJapaneseUniversityBefore': !exists(json, 'isEnrolledInJapaneseUniversityBefore') ? undefined : json['isEnrolledInJapaneseUniversityBefore'],
        'isJapaneseHaiguusyaHasIncome': !exists(json, 'isJapaneseHaiguusyaHasIncome') ? undefined : json['isJapaneseHaiguusyaHasIncome'],
        'isPartTimeJob': !exists(json, 'isPartTimeJob') ? undefined : json['isPartTimeJob'],
        'isFinancialAssistanceFromRelatives': !exists(json, 'isFinancialAssistanceFromRelatives') ? undefined : json['isFinancialAssistanceFromRelatives'],
    };
}

export function SyoruiConfirmationFormHonninRyugakuseiToJSON(value?: SyoruiConfirmationFormHonninRyugakusei | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isEnrolledInJapaneseUniversityBefore': value.isEnrolledInJapaneseUniversityBefore,
        'isJapaneseHaiguusyaHasIncome': value.isJapaneseHaiguusyaHasIncome,
        'isPartTimeJob': value.isPartTimeJob,
        'isFinancialAssistanceFromRelatives': value.isFinancialAssistanceFromRelatives,
    };
}


