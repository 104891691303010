import React, {FC} from "react";
import {StudentMainArea} from "../../atoms/StudentMainArea";
import {Box} from "@material-ui/core";
import {Title02} from "../../atoms/titles/Title02";
import Grid from "@material-ui/core/Grid";
import {Title05} from "../../atoms/titles/Title05";
import {Table} from "../../atoms/Table";
import {Button} from "../../atoms/Button";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {AYosikiSyoruisSyoruisVo, OfficerSyoruisSyoruisSyoruiVo} from "../../../common/models/AYosikiSyoruisModels";

interface ListRowProps {
    xs: boolean
    syorui: OfficerSyoruisSyoruisSyoruiVo
    isHituyoKinyuSyorui?: boolean
}

const ListRow: FC<ListRowProps> = ({
                                       xs,
                                       syorui,
                                       isHituyoKinyuSyorui
                                   }) => {

    return (
        <tr>
            <td>
                <Grid container spacing={1} direction={xs ? "column" : "row"} alignItems={xs ? "flex-start" : "center"}>
                    <Grid item xs={xs ? 12 : 8}>
                        <Box>{syorui.nameJa} / {syorui.nameEn}</Box>
                        {isHituyoKinyuSyorui && <Box fontSize={12}>※ 記入の上、申請してください。 / Please print and fill out the application.</Box>}
                    </Grid>
                    <Grid item xs={xs ? 12 : 4}>
                        {/* TODO Buttonでのダウンロードの方法がわからないので調整が必要。 */}
                        {/* NOTE: 英語の書類がないので日本語のみのダウンロードのみ配置 */}
                        <Button
                            type={"secondary"}
                            label={"ダウンロード / Download"}
                            width={"100%"}
                            href={syorui.urlJa}
                        />
                    </Grid>
                </Grid>
            </td>
        </tr>

    )
};

interface Props {
    aYosikiSYoruis?: AYosikiSyoruisSyoruisVo;
    handleClickAYosikiForm: () => void
    isAYosikiKakutei: boolean
}

export const SummaryAyosikiSection: FC<Props> = ({
                                                     aYosikiSYoruis,
                                                     handleClickAYosikiForm,
                                                     isAYosikiKakutei
                                                 }) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Box>

            <Title02
                title={"授業料等減免の認定に関する書類(A様式1またはA様式2)の書類作成 / Preparation of documents related to the approval of tuition fee, etc., reduction or waiver (Form A1 or Form A2)"}
            />

            <StudentMainArea mt={2}>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Box>
                            授業料等減免の申請に必要なA様式1またはA様式2を作成できます。<br/>
                            Form A1 or Form A2 required for application for tuition fee reduction or waiver can be
                            prepared.
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box textAlign={"center"}>
                            <Button
                                type={"primary"}
                                size={"large"}
                                label={"A様式1またはA様式2の書類作成 / Preparation of Form A1 or Form A2 documents"}
                                handleClick={handleClickAYosikiForm}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1}>

                            <Grid item xs={12}>
                                <Title05
                                    title={"申請が必要な様式（PDF） / Forms Requiring Application (PDF)"}
                                />
                            </Grid>

                            {
                                !isAYosikiKakutei ? (
                                    <Grid item xs={12}>
                                        <Box>
                                            入力を確定すると書類をダウンロードできるようになります。<br/>
                                            Once you confirm your entry, you will be able to download the documents.
                                        </Box>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid item xs={12}>
                                            <Box>
                                                以下の書類を添付のうえ申請してください。<br/>
                                                Please attach the following documents to your application.
                                            </Box>
                                        </Grid>

                                        {/* NOTE: 書類一覧 */}
                                        <Grid item xs={12}>
                                            <Box mt={1}>
                                                <Table styles={{whiteSpace: "normal"}}>
                                                    <colgroup>
                                                        <col/>
                                                    </colgroup>
                                                    <tbody>
                                                    <tr>
                                                        <th>書類名 / Document Name</th>
                                                    </tr>
                                                    {
                                                        aYosikiSYoruis?.hituyoSyoruis.map((h: OfficerSyoruisSyoruisSyoruiVo, index: number) => {
                                                            return (
                                                                <ListRow
                                                                    key={index}
                                                                    xs={matches}
                                                                    syorui={h}
                                                                />
                                                            )
                                                        })
                                                    }
                                                    {
                                                        aYosikiSYoruis?.hituyoKinyuSyoruis.map((h: OfficerSyoruisSyoruisSyoruiVo, index: number) => {
                                                            return (
                                                                <ListRow
                                                                    key={index}
                                                                    xs={matches}
                                                                    syorui={h}
                                                                    isHituyoKinyuSyorui={true}
                                                                />
                                                            )
                                                        })
                                                    }
                                                    </tbody>

                                                </Table>
                                            </Box>
                                        </Grid>
                                    </>
                                )
                            }

                        </Grid>

                    </Grid>

                </Grid>

            </StudentMainArea>

        </Box>

    )
};
