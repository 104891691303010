import {createStyles, Theme} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {Title02} from "components/atoms/titles/Title02";
import React, {FC} from 'react';
import {TextColorsEnum} from "common/enums_value/ColorsEnum";

interface ModalProps {
    id?: string
    width?: string;
    openState: boolean;
    title: string;
    description?: string;
    className?: string;
    disableOverFlowY?: boolean
}

export const Modal: FC<ModalProps> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog
            id={props.id}
            open={props.openState}
            maxWidth={"lg"}
            disableBackdropClick={true}
            className={`${classes.root} ${matches && classes.xsRoot} ${props.className && props.className} ${props.disableOverFlowY && classes.disableOverFlowY}`}
        >
            <div
                className={`${classes.body} ${matches && classes.xsBody}`}
                style={{width: `${props.width}`}}
            >
                <div className={classes.title}>
                    <Title02
                        title={props.title}
                    />
                </div>
                {
                    props.description &&
                    <div className={classes.description}>
                        {props.description}
                    </div>
                }
                {props.children}
            </div>
        </Dialog>
    )
};

Modal.defaultProps = {
    width: "960px",
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        "& .modal_actions": {
            justifyContent: "center",
            padding: "32px 0 8px",
        },
        "& .modalFooterButton": {
            display: "flex",
            justifyContent: "center",
            padding: "24px 0 8px",
            "& > *:not(:first-child)": {
                marginLeft: 16
            },
        }
    },
    xsRoot: {
        "& .MuiDialog-paper": {
            margin: 16
        }
    },
    body: {
        padding: "24px 32px",
        boxSizing: "border-box",
        maxWidth: '100%'
    },
    xsBody: {
        padding: 16,
    },
    title: {
        color: TextColorsEnum.Black,
        fontSize: 18,
        fontWeight: 700,
        padding: 0,
        marginBottom: 16
    },
    description: {
        color: TextColorsEnum.Green,
        fontSize: "12px",
    },
    disableOverFlowY: {
        "& .MuiDialog-paper":{
            overflowY: "visible"
        }
    }

}));