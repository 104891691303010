import {makeStyles} from '@material-ui/core/styles';
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {LoginLabelTextEnum, LoginLabelTextEnumToNameJaAndEn} from "common/enums_text/LoginLabelTextEnum";
import {Button} from "components/atoms/Button";
import {TextField} from "components/atoms/forms/TextField";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import {Container} from "components/layouts/Container";
import {LoginContainer} from "components/layouts/LoginContainer";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import {createAccountStore, CreateAccountStoreVo} from "pages/student/createAccount/stores/CreateAccountStore";
import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from "react-router";

const _CreateAccount: React.FC<RouteComponentProps> = () => {

    const classes = useStyles();
    const [createAccount, updateCreateAccount] = React.useState<CreateAccountStoreVo>(createAccountStore.currentState);

    useEffect(() => {
        createAccountStore.updateErrorMessages(undefined);
        return createAccountStore.listen(updateCreateAccount)
    }, []);

    function handleClickLoginButton() {
        createAccountStore.postStudentCreateAccount().then()
    }

    function handleBlurPassword(v: string) {
        createAccountStore.updateForm({password: v});
        createAccountStore.updateErrorMessages({password: undefined})
    }

    function handleBlurPasswordConfirm(v: string) {
        createAccountStore.updateForm({passwordComfirm: v});
        createAccountStore.updateErrorMessages({passwordComfirm: undefined})
    }

    function handleBlurTelGakuseiNyuyroku(v: string) {
        createAccountStore.updateForm({telGakuseiNyuyroku: v});
        createAccountStore.updateErrorMessages({telGakuseiNyuyroku: undefined})
    }

    function handleBlurEmailGakuseiNyuyroku(v: string) {
        createAccountStore.updateForm({emailGakuseiNyuyroku: v});
        createAccountStore.updateErrorMessages({emailGakuseiNyuyroku: undefined})
    }

    return (

        <>

            <HeaderStudent signedIn={false}/>

            <Container
                maxWidth={'464px'}
                bgColor={"gray"}
                className={classes.container}
            >

                <div className={"container_inner"}>

                    <LoginContainer
                        className={classes.root}
                    >

                        <div className={"login_title"}>{LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.新規アカウント登録)}</div>
                        <div className={"login_form"}>

                            <FieldWithLabel
                                label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード)}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.password)}
                            >
                                <TextField
                                    value={createAccount.form.password}
                                    type={'password'}
                                    handleBlur={handleBlurPassword}
                                    isError={isErrorMessage(createAccount.errorMessages?.password)}
                                    helperText={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード組み合わせ)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード確認)}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.passwordComfirm)}
                            >
                                <TextField
                                    value={createAccount.form.passwordComfirm}
                                    type={'password'}
                                    handleBlur={handleBlurPasswordConfirm}
                                    isError={isErrorMessage(createAccount.errorMessages?.passwordComfirm)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.電話番号)}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.telGakuseiNyuyroku)}
                            >
                                <TextField
                                    value={createAccount.form.telGakuseiNyuyroku}
                                    handleBlur={handleBlurTelGakuseiNyuyroku}
                                    isError={isErrorMessage(createAccount.errorMessages?.telGakuseiNyuyroku)}
                                    helperText={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.ハイフンなしで入力してください)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.メールアドレス)}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.emailGakuseiNyuyroku)}
                            >
                                <TextField
                                    value={createAccount.form.emailGakuseiNyuyroku}
                                    handleBlur={handleBlurEmailGakuseiNyuyroku}
                                    isError={isErrorMessage(createAccount.errorMessages?.emailGakuseiNyuyroku)}
                                />
                            </FieldWithLabel>

                            <ErrorMessages
                                className={classes.errorMessages}
                                errorMessages={
                                    convertErrorMessageVoToArrayStr(createAccount.errorMessages?.base).concat(
                                        convertErrorMessageVoToArrayStr(createAccount.errorMessages?.token)
                                    )
                                }
                            />

                            <div>
                                <Button
                                    type={"primary"}
                                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                                    width={"100%"}
                                    handleClick={handleClickLoginButton}
                                />
                            </div>

                        </div>

                    </LoginContainer>

                </div>

            </Container>

        </>

    );
};

export const CreateAccount = withRouter(_CreateAccount);

const useStyles = makeStyles({
    container: {
        "& .container_inner": {
            display: "flex"
        }
    },
    root: {
        "& .login_title": {
            textAlign: "center",
            fontSize: 18,
            fontWeight: 700,
            marginBottom: 16,
        },
        "& .login_form": {
            "& > *": {
                marginTop: 16,
                "&:first-child": {
                    marginTop: 0
                }
            },
            "& .password": {
                textAlign: "center"
            }
        }
    },
    errorMessages: {}
});
