/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSinseiTypeErrorMessage
 */
export interface SwSinseiTypeErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseiTypeErrorMessage
     */
    nyugakuryo?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseiTypeErrorMessage
     */
    jugyoryo?: Array<SwErrorMessage>;
    /**
     * 入学料と授業料どちらも申請しないを選択されている場合にエラーを返す。 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseiTypeErrorMessage
     */
    either?: Array<SwErrorMessage>;
}

export function SwSinseiTypeErrorMessageFromJSON(json: any): SwSinseiTypeErrorMessage {
    return SwSinseiTypeErrorMessageFromJSONTyped(json, false);
}

export function SwSinseiTypeErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSinseiTypeErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nyugakuryo': !exists(json, 'nyugakuryo') ? undefined : ((json['nyugakuryo'] as Array<any>).map(SwErrorMessageFromJSON)),
        'jugyoryo': !exists(json, 'jugyoryo') ? undefined : ((json['jugyoryo'] as Array<any>).map(SwErrorMessageFromJSON)),
        'either': !exists(json, 'either') ? undefined : ((json['either'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwSinseiTypeErrorMessageToJSON(value?: SwSinseiTypeErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nyugakuryo': value.nyugakuryo === undefined ? undefined : ((value.nyugakuryo as Array<any>).map(SwErrorMessageToJSON)),
        'jugyoryo': value.jugyoryo === undefined ? undefined : ((value.jugyoryo as Array<any>).map(SwErrorMessageToJSON)),
        'either': value.either === undefined ? undefined : ((value.either as Array<any>).map(SwErrorMessageToJSON)),
    };
}


