/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwAddress,
    SwAddressFromJSON,
    SwAddressFromJSONTyped,
    SwAddressToJSON,
    SwSinseisyaInfoKyugaku,
    SwSinseisyaInfoKyugakuFromJSON,
    SwSinseisyaInfoKyugakuFromJSONTyped,
    SwSinseisyaInfoKyugakuToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSinseisyaInfo
 */
export interface SwSinseisyaInfo {
    /**
     * 
     * @type {number}
     * @memberof SwSinseisyaInfo
     */
    birthdayYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSinseisyaInfo
     */
    birthdayMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSinseisyaInfo
     */
    birthdayDay?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSinseisyaInfo
     */
    gakunen?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SwSinseisyaInfo
     */
    isKyugaku?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwSinseisyaInfo
     */
    isRyunen?: boolean;
    /**
     * 
     * @type {SwSinseisyaInfoKyugaku}
     * @memberof SwSinseisyaInfo
     */
    kyugaku?: SwSinseisyaInfoKyugaku;
    /**
     * 
     * @type {SwAddress}
     * @memberof SwSinseisyaInfo
     */
    address: SwAddress;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisyaInfo
     */
    telHome: string;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisyaInfo
     */
    telLab: string;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisyaInfo
     */
    sidoTeacherName: string;
    /**
     * 
     * @type {SwAddress}
     * @memberof SwSinseisyaInfo
     */
    addressFamily: SwAddress;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisyaInfo
     */
    telFamily: string;
}

export function SwSinseisyaInfoFromJSON(json: any): SwSinseisyaInfo {
    return SwSinseisyaInfoFromJSONTyped(json, false);
}

export function SwSinseisyaInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSinseisyaInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthdayYear': !exists(json, 'birthdayYear') ? undefined : json['birthdayYear'],
        'birthdayMonth': !exists(json, 'birthdayMonth') ? undefined : json['birthdayMonth'],
        'birthdayDay': !exists(json, 'birthdayDay') ? undefined : json['birthdayDay'],
        'gakunen': !exists(json, 'gakunen') ? undefined : json['gakunen'],
        'isKyugaku': !exists(json, 'isKyugaku') ? undefined : json['isKyugaku'],
        'isRyunen': !exists(json, 'isRyunen') ? undefined : json['isRyunen'],
        'kyugaku': !exists(json, 'kyugaku') ? undefined : SwSinseisyaInfoKyugakuFromJSON(json['kyugaku']),
        'address': SwAddressFromJSON(json['address']),
        'telHome': json['telHome'],
        'telLab': json['telLab'],
        'sidoTeacherName': json['sidoTeacherName'],
        'addressFamily': SwAddressFromJSON(json['addressFamily']),
        'telFamily': json['telFamily'],
    };
}

export function SwSinseisyaInfoToJSON(value?: SwSinseisyaInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthdayYear': value.birthdayYear,
        'birthdayMonth': value.birthdayMonth,
        'birthdayDay': value.birthdayDay,
        'gakunen': value.gakunen,
        'isKyugaku': value.isKyugaku,
        'isRyunen': value.isRyunen,
        'kyugaku': SwSinseisyaInfoKyugakuToJSON(value.kyugaku),
        'address': SwAddressToJSON(value.address),
        'telHome': value.telHome,
        'telLab': value.telLab,
        'sidoTeacherName': value.sidoTeacherName,
        'addressFamily': SwAddressToJSON(value.addressFamily),
        'telFamily': value.telFamily,
    };
}


