import React from 'react';
import {SwAYosikiFormErrorMessageResponse} from "../../../../openapi";
import {AYosikiFormVo} from "../../../../common/models/AYosikiFormModels";
import {Title04} from "../../../atoms/titles/Title04";
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import {isErrorMessage} from "../../../../common/functions/isErrorMessage";
import {FormTableRow} from "../../forms/FormTableRow";
import {FormTable} from "../../forms/FormTable";
import {RequiredTagTypeEnum} from "../../../../common/enums_value/RequiredTagTypeEnum";
import {
    TitleNameTextEnum,
    TitleNameTextEnumToNameJaAndEn
} from "../../../../common/enums_text/yosiki/TitleNameTextEnum";
import {Title05} from "../../../atoms/titles/Title05";
import {
    AYosikiInfoTextEnum,
    AYosikiInfoTextEnumToNameJaAndEn
} from "../../../../common/enums_text/yosiki/AYosikiInfoTextEnum";
import {ListDsc} from "../../../atoms/ListDsc";
import {CheckboxGroup} from "../../forms/CheckboxGroup";
import {Checkbox} from "../../../atoms/forms/Checkbox";
import {TextField} from "../../../atoms/forms/TextField";
import {AYosiki2Description} from "./AYosiki2Description";

interface Props {
    formValues: AYosikiFormVo
    errorMessages: SwAYosikiFormErrorMessageResponse | undefined
    updates: (formValues: AYosikiFormVo, errorMessages?: SwAYosikiFormErrorMessageResponse | undefined) => void
    isKakutei: boolean
}

export const AYosiki2Form: React.FC<Props> = ({
                                                  formValues,
                                                  errorMessages,
                                                  updates,
                                                  isKakutei
                                              }) => {

    function handleChangeAgreement(v: boolean) {
        if (!formValues) return
        formValues.aYosiki2.agreement = v
        updates(formValues, {
            ...errorMessages,
            aYosiki2: {
                ...errorMessages?.aYosiki2,
                agreement: undefined
            }
        })
    }

    function handleBlurSyogakuseiNumber(v: string) {
        if (!formValues) return
        formValues.aYosiki2.syogakuseiNumber = v
        updates(formValues, {
            ...errorMessages,
            aYosiki2: {
                ...errorMessages?.aYosiki2,
                syogakuseiNumber: undefined
            }
        })
    }

    return (
        <Grid container spacing={2} direction={"column"}>

            <Grid item xs>
                <Title04
                    title={"A様式2 / Form A2"}
                />
            </Grid>

            <Grid item xs>
                <Title05 title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.申請書作成にあたっての注意事項)}/>
                <AYosiki2Description mt={2}/>
            </Grid>

            <Grid item xs>

                <FormTable
                    labelWidth={"200px"}
                >

                    <FormTableRow
                        label={"同意 / Agreement"}
                        requiredType={RequiredTagTypeEnum.必須}
                    >

                        <Grid container spacing={1}>

                            <Grid item xs={12}>
                                <Box fontWeight={700}>
                                    {AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.私は貴学に対し大学等における修学の支援に関する法律による授業料等減免の対象者としての認定を申請します)}
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <ListDsc>
                                    <li>
                                        この中請書の記載事項は事実と相違ありません。<br />なお、申請書の記載事項に事実と相違があった場合、認定を取り消され、減免を打ち切られることがあるとともに、在学する学校から減免を受けた金額の支払を求められることがあることを承知しています。
                                    </li>
                                    <li>
                                        授業料等減免の対象者の認定手続きにおいて、独立行政法人日本学生支援機構（以下、「機構」という。）を通じ、東京農工大学が機構の保有する私の給付奨学金に関する情報の送付を受けること、及び機構が東京農工大学の保有する私の授業料等減免等に関する情報の送付を受けることに同意します。
                                    </li>
                                    <li>
                                        All information indicated in this application is correct.<br />
                                        Furthermore, I am aware that if there are any discrepancies between the information indicated in the application and the actual facts, my approval status may be cancelled, any reduction or waivers may be terminated, and payment of the monetary amounts for reduction or waivers may be required by the school I am enrolled in.
                                    </li>
                                    <li>
                                        I agree that as part of procedures for recognition of eligibility for reduction or exemption of tuition, etc., the Tokyo University of Agriculture and Technology will, through the Japan Student Services Organization (hereinafter referred to as “the Organization”), receive information on me possessed and sent by the Organization related to scholarship grants, and that the Organization will receive information on me possessed and sent by the Tokyo University of Agriculture and Technology related to reduction or waivers of tuition, etc.
                                    </li>
                                </ListDsc>
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    isKakutei ? (
                                        <CheckboxGroup
                                            vertical={true}
                                        >
                                            <Checkbox
                                                checked={formValues.aYosiki2.agreement}
                                                label={AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.申請にあたって私は以上の事項を確認し理解しています)}
                                            />
                                        </CheckboxGroup>
                                    ) : (
                                        <CheckboxGroup
                                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki2?.agreement)}
                                            vertical={true}
                                        >
                                            <Checkbox
                                                checked={formValues.aYosiki2.agreement}
                                                label={AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.申請にあたって私は以上の事項を確認し理解しています)}
                                                handleChange={() => handleChangeAgreement(!formValues.aYosiki2.agreement)}
                                                isError={isErrorMessage(errorMessages?.aYosiki2?.agreement)}
                                            />
                                        </CheckboxGroup>
                                    )
                                }
                            </Grid>

                        </Grid>

                    </FormTableRow>

                    <FormTableRow
                        label={"給付奨学金の奨学生番号 / Scholarship grant scholarship student number"}
                        requiredType={RequiredTagTypeEnum.必須}
                    >
                        {
                            isKakutei ? (
                                <Box>
                                    {formValues.aYosiki2.syogakuseiNumber}
                                </Box>
                            ) : (
                                <TextField
                                    value={formValues.aYosiki2.syogakuseiNumber}
                                    handleBlur={(value) => handleBlurSyogakuseiNumber(value)}
                                    isError={isErrorMessage(errorMessages?.aYosiki2?.syogakuseiNumber)}
                                    widthInput={"120px"}
                                    errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki2?.syogakuseiNumber)}
                                />
                            )
                        }
                    </FormTableRow>

                </FormTable>

            </Grid>

        </Grid>
    )
}

