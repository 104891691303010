/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwStudentLoginRequest
 */
export interface SwStudentLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof SwStudentLoginRequest
     */
    studentNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentLoginRequest
     */
    password: string;
}

export function SwStudentLoginRequestFromJSON(json: any): SwStudentLoginRequest {
    return SwStudentLoginRequestFromJSONTyped(json, false);
}

export function SwStudentLoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwStudentLoginRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentNumber': json['studentNumber'],
        'password': json['password'],
    };
}

export function SwStudentLoginRequestToJSON(value?: SwStudentLoginRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentNumber': value.studentNumber,
        'password': value.password,
    };
}


