// 全角のアルファベット、数字、記号、空白 を半角に変換
export const convertZenkakuToHankaku = (str: string): string => {
    return str
        .replace(
            /[０-９ａ-ｚＡ-Ｚ！＂＃＄％＆＇（）＊＋，－．／０１２３４５６７８９：；＜＝＞？＠ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺ¥［¥］＾＿｀ａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚ｛｜｝]/g,
            function (s) {
                return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
            }
        )
        .replace("　", " ");
};