import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {SwSyoruiConfirmationFormErrorMessageResponse, SwSyoruiConfirmationFormResponse} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";
import {
    SyoruiConfirmationFormStoreVo,
    SyoruiConfirmationFormVo
} from "../../../../common/models/SyoruiConfirmationFormModels";
import {ZokugaraEnum} from "../../../../common/enums/ZokugaraEnum";
import {
    StudentSyoruiConfirmationFormApi,
    StudentSyoruiConfirmationFormHanteiApi,
    StudentSyoruiConfirmationFormHanteiKaijoApi
} from "../../../../openapi";

class StudentSyoruiConfirmationFormStore extends Store<SyoruiConfirmationFormStoreVo> {

    // NOTE: 使用api
    private studentSyoruiConfirmationFormApi = new StudentSyoruiConfirmationFormApi(apiConfiguration);
    private studentSyoruiConfirmationFormHanteiApi = new StudentSyoruiConfirmationFormHanteiApi(apiConfiguration)
    private studentSyoruiConfirmationFormHanteiKaijoApi = new StudentSyoruiConfirmationFormHanteiKaijoApi(apiConfiguration)

    constructor() {
        super({})
    }

    // NOTE: エラーメッセージの更新
    async updateSyoruiConfirmationFormErrorMessages(errorMessage: SwSyoruiConfirmationFormErrorMessageResponse | undefined) {
        this.dispatch(() => ({syoruiConfirmationFormErrorMessage: errorMessage}));
    }

    // NOTE: フォーム情報更新
    async updateSyoruiConfirmationForm(vo: SyoruiConfirmationFormVo) {
        this.dispatch(() => ({syoruiConfirmationForm: vo}));
    }

    // NOTE: フォーム情報習得
    async getSyoruiConfirmationForm() {
        try {
            const res: SwSyoruiConfirmationFormResponse = await this.studentSyoruiConfirmationFormApi.getStudentSyoruiConfirmationForm();
            const vo: SyoruiConfirmationFormVo = {
                ...res,
                members: res.members.map(m => {
                    return {
                        ...m,
                        zokugara: m.zokugara as ZokugaraEnum
                    }
                })
            };
            await this.updateSyoruiConfirmationFormErrorMessages(undefined);
            this.updateSyoruiConfirmationForm(vo).then();
        } catch (res) {
            handleStudentError(res)
        }
    }

    // NOTE: 保存
    async postSyoruiConfirmationForm() {
        const form = this.currentState.syoruiConfirmationForm
        if (form === undefined) return
        try {
            const res = await this.studentSyoruiConfirmationFormApi.postStudentSyoruiConfirmationForm({
                swSyoruiConfirmationFormRequest: {
                    ...form
                }
            });
            await this.updateSyoruiConfirmationFormErrorMessages({
                ...res.error,
            })
            return res;
        } catch (res) {
            handleStudentError(res)
        }
    }

    // NOTE: 判定
    async postSyoruiConfirmationFormHantei() {
        try {
            return await this.studentSyoruiConfirmationFormHanteiApi.postStudentSyoruiConfirmationFormHantei()
        } catch (res) {
            handleStudentError(res)
        }
    }

    // NOTE: 判定解除
    async postSyoruiConfirmationFormHanteiKaijo() {
        try {
            return await this.studentSyoruiConfirmationFormHanteiKaijoApi.postStudentSyoruiConfirmationFormHanteiKaijo()
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const studentSyoruiConfirmationFormStore = new StudentSyoruiConfirmationFormStore();
