/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwFamilyInfo,
    SwFamilyInfoFromJSON,
    SwFamilyInfoFromJSONTyped,
    SwFamilyInfoToJSON,
    SwJugyoryoInfo,
    SwJugyoryoInfoFromJSON,
    SwJugyoryoInfoFromJSONTyped,
    SwJugyoryoInfoToJSON,
    SwNyugakuryoInfo,
    SwNyugakuryoInfoFromJSON,
    SwNyugakuryoInfoFromJSONTyped,
    SwNyugakuryoInfoToJSON,
    SwSeisekiInfo,
    SwSeisekiInfoFromJSON,
    SwSeisekiInfoFromJSONTyped,
    SwSeisekiInfoToJSON,
    SwSinseisyaInfo,
    SwSinseisyaInfoFromJSON,
    SwSinseisyaInfoFromJSONTyped,
    SwSinseisyaInfoToJSON,
    SwSyogakukinInfo,
    SwSyogakukinInfoFromJSON,
    SwSyogakukinInfoFromJSONTyped,
    SwSyogakukinInfoToJSON,
    SwTokubetuKojoInfo,
    SwTokubetuKojoInfoFromJSON,
    SwTokubetuKojoInfoFromJSONTyped,
    SwTokubetuKojoInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwYosikiInfoRequest
 */
export interface SwYosikiInfoRequest {
    /**
     * 
     * @type {SwSinseisyaInfo}
     * @memberof SwYosikiInfoRequest
     */
    sinseisyaInfo: SwSinseisyaInfo;
    /**
     * 
     * @type {SwSeisekiInfo}
     * @memberof SwYosikiInfoRequest
     */
    seisekiInfo: SwSeisekiInfo;
    /**
     * 
     * @type {SwSyogakukinInfo}
     * @memberof SwYosikiInfoRequest
     */
    syogakukinInfo: SwSyogakukinInfo;
    /**
     * 
     * @type {SwFamilyInfo}
     * @memberof SwYosikiInfoRequest
     */
    familyInfo: SwFamilyInfo;
    /**
     * 
     * @type {SwNyugakuryoInfo}
     * @memberof SwYosikiInfoRequest
     */
    nyugakuryoInfo: SwNyugakuryoInfo;
    /**
     * 
     * @type {SwJugyoryoInfo}
     * @memberof SwYosikiInfoRequest
     */
    jugyoryoInfo: SwJugyoryoInfo;
    /**
     * 
     * @type {SwTokubetuKojoInfo}
     * @memberof SwYosikiInfoRequest
     */
    tokubetuKojoInfo: SwTokubetuKojoInfo;
}

export function SwYosikiInfoRequestFromJSON(json: any): SwYosikiInfoRequest {
    return SwYosikiInfoRequestFromJSONTyped(json, false);
}

export function SwYosikiInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwYosikiInfoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sinseisyaInfo': SwSinseisyaInfoFromJSON(json['sinseisyaInfo']),
        'seisekiInfo': SwSeisekiInfoFromJSON(json['seisekiInfo']),
        'syogakukinInfo': SwSyogakukinInfoFromJSON(json['syogakukinInfo']),
        'familyInfo': SwFamilyInfoFromJSON(json['familyInfo']),
        'nyugakuryoInfo': SwNyugakuryoInfoFromJSON(json['nyugakuryoInfo']),
        'jugyoryoInfo': SwJugyoryoInfoFromJSON(json['jugyoryoInfo']),
        'tokubetuKojoInfo': SwTokubetuKojoInfoFromJSON(json['tokubetuKojoInfo']),
    };
}

export function SwYosikiInfoRequestToJSON(value?: SwYosikiInfoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sinseisyaInfo': SwSinseisyaInfoToJSON(value.sinseisyaInfo),
        'seisekiInfo': SwSeisekiInfoToJSON(value.seisekiInfo),
        'syogakukinInfo': SwSyogakukinInfoToJSON(value.syogakukinInfo),
        'familyInfo': SwFamilyInfoToJSON(value.familyInfo),
        'nyugakuryoInfo': SwNyugakuryoInfoToJSON(value.nyugakuryoInfo),
        'jugyoryoInfo': SwJugyoryoInfoToJSON(value.jugyoryoInfo),
        'tokubetuKojoInfo': SwTokubetuKojoInfoToJSON(value.tokubetuKojoInfo),
    };
}


