/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwKikan
 */
export interface SwKikan {
    /**
     * フロントでDate型に変換
     * @type {string}
     * @memberof SwKikan
     */
    startDate: string;
    /**
     * 00 2桁
     * @type {string}
     * @memberof SwKikan
     */
    startHour: string;
    /**
     * 00 2桁
     * @type {string}
     * @memberof SwKikan
     */
    startMinute: string;
    /**
     * フロントでDate型に変換
     * @type {string}
     * @memberof SwKikan
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof SwKikan
     */
    endHour: string;
    /**
     * 
     * @type {string}
     * @memberof SwKikan
     */
    endMinute: string;
}

export function SwKikanFromJSON(json: any): SwKikan {
    return SwKikanFromJSONTyped(json, false);
}

export function SwKikanFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwKikan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': json['startDate'],
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'endDate': json['endDate'],
        'endHour': json['endHour'],
        'endMinute': json['endMinute'],
    };
}

export function SwKikanToJSON(value?: SwKikan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate,
        'startHour': value.startHour,
        'startMinute': value.startMinute,
        'endDate': value.endDate,
        'endHour': value.endHour,
        'endMinute': value.endMinute,
    };
}


