import {schoolTypeEnumToName} from "common/enums/SchoolTypeEnum";
import {SettiKubunEnum, settiKubunEnumToName} from "common/enums/SettiKubunEnum";
import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {MemberSyugakusyaVo, MemberVo} from "common/models/YosikiInfoModels";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import React, {FC} from "react";
import {JugyoryoMenjoStateEnum, jugyoryoMenjoStateEnumToName} from "../../../common/enums/JugyoryoMenjoStateEnum";
import {convertStrToNumOrUndefined} from "../../../common/functions/type_converter/convertStrToNumOrUndefined";

interface FamilyInfoSectionSyugakusyaDetailProps {
    member: MemberVo
    members: Array<MemberVo>
    index: number
}

export const FamilyInfoSectionSyugakusyaDetail: FC<FamilyInfoSectionSyugakusyaDetailProps> = (props) => {

    const member: MemberVo = props.member;
    const syugakusya: MemberSyugakusyaVo | undefined = member.syugakusya;
    if (syugakusya === undefined) return <></>;

    return (
        <FormTableRow
            label={convertZokugaraForStr(member.zokugara, member.zokugaraOther)}
        >

            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.在学学校)}
                </div>
                {syugakusya.schoolType ? convertEnumNameToStr(schoolTypeEnumToName(syugakusya.schoolType)) : ""}
            </div>

            <div className={"formTableRow_item"}>

                <FieldWithLabel
                    label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.学校名)}
                    width={"160px"}
                >
                    {syugakusya.schoolName}
                </FieldWithLabel>

            </div>

            <div className={"formTableRow_item"}>

                <FieldWithLabel
                    label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.学年)}
                    width={"160px"}
                >
                    {syugakusya.gakunen}
                </FieldWithLabel>

            </div>

            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.設置区分)}
                </div>
                {syugakusya.settiKubun ? convertEnumNameToStr(settiKubunEnumToName(syugakusya.settiKubun)) : ""}
            </div>

            {
                syugakusya.jugyoryoMenjoState ? (
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.授業料免除状況)}
                        </div>
                        {syugakusya.jugyoryoMenjoState ? convertEnumNameToStr(jugyoryoMenjoStateEnumToName(syugakusya.jugyoryoMenjoState)) : ""}
                    </div>
                ) : <></>
            }

            {
                syugakusya.settiKubun === SettiKubunEnum.国立 &&
                syugakusya.jugyoryoMenjoState === JugyoryoMenjoStateEnum.半額 ? (
                    <FieldWithLabel
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.半年分の授業料)}
                    >
                        {convertStrToNumOrUndefined(syugakusya.jugyoryoForHalfYear)?.toLocaleString()}円
                    </FieldWithLabel>
                ) : <></>
            }


            {/*<div className={"formTableRow_item"}>*/}
            {/*    <div className={"formTableRow_item_label"}>*/}
            {/*        {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.設置区分)}*/}
            {/*    </div>*/}
            {/*    {syugakusya.settiKubun ? enumToNameBothJaAndEn(settiKubunEnumToName(syugakusya.settiKubun)) : ""}*/}
            {/*</div>*/}

            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.通学区分)}
                </div>
                {
                    syugakusya.tugakuFromJitaku === true ? FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅) :
                        syugakusya.tugakuFromJitaku === false ? FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅外) :
                            ""
                }
            </div>

        </FormTableRow>
    )
};
