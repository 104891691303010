import DialogActions from "@material-ui/core/DialogActions";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {Button} from "components/atoms/Button";
import {Modal} from "components/atoms/Modal";
import React from 'react';

interface YosikiEditBackButtonUnsavedAttentionModalProps {
    openState: boolean
    handleClickExit: () => void
    handleClickClose: () => void
}

export const YosikiEditUnsavedAttentionModal: React.FC<YosikiEditBackButtonUnsavedAttentionModalProps> = (props) => {

    async function handleClickExit() {
        props.handleClickExit();
    }

    function handleClickClose() {
        props.handleClickClose();
    }

    return (

        <Modal
            openState={props.openState}
            title={"入力内容が保存されていません。 / The changes are not saved."}
        >

            <div>
                このまま保存せずに「詳細画面」に戻りますか？ / Do you want to go back to the "detail screen" without saving it?
            </div>

            <DialogActions className={"modal_actions"}>
                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.キャンセル)}
                    width={"152px"}
                    handleClick={handleClickClose}
                />
                <Button
                    type={"primary"}
                    label={"保存せず終了 / Exit without saving"}
                    width={"256px"}
                    handleClick={handleClickExit}
                />
            </DialogActions>

        </Modal>

    );
};


