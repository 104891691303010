import {makeStyles} from '@material-ui/core/styles';
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {LoginLabelTextEnum, LoginLabelTextEnumToNameJaAndEn} from "common/enums_text/LoginLabelTextEnum";
import {Button} from "components/atoms/Button";
import {TextField} from "components/atoms/forms/TextField";
import {Container} from "components/layouts/Container";
import {LoginContainer} from "components/layouts/LoginContainer";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import {
    sendCreateAccountLinkNewStudentsStore,
    SendCreateAccountLinkNewStudentsStoreVo
} from "./stores/SendCreateAccountLinkNewStudentsStore";

const _SendCreateAccountLinkNewStudents: React.FC<RouteComponentProps> = () => {

    const classes = useStyles();

    const [sendCreateAccountLinkNewStudents, updateSendCreateAccountLinkNewStudents] = React.useState<SendCreateAccountLinkNewStudentsStoreVo>(sendCreateAccountLinkNewStudentsStore.currentState);

    useEffect(() => {
        sendCreateAccountLinkNewStudentsStore.updateErrorMessages({email: undefined});
        return sendCreateAccountLinkNewStudentsStore.listen(updateSendCreateAccountLinkNewStudents)
    }, []);

    function handleClickLoginButton() {
        sendCreateAccountLinkNewStudentsStore.postSendCreateAccountLink().then()
    }

    function handleBlurAccount(v: string) {
        sendCreateAccountLinkNewStudentsStore.updateForm({email: v});
        sendCreateAccountLinkNewStudentsStore.updateErrorMessages({email: undefined})
    }

    return (

        <>

            <HeaderStudent signedIn={false}/>

            <Container
                maxWidth={'464px'}
                bgColor={"gray"}
                className={classes.container}
            >

                <div className={"container_inner"}>

                    <LoginContainer
                        className={classes.root}
                    >
                        <div className={"login_title"}>{LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.新規アカウント登録)}</div>
                        <div>
                            メールアドレスを入力して登録してください。<br/>
                            メールアドレス宛に新規アカウント登録画面へのリンクが記載されたメールを送信します。
                        </div>
                        <div className={"mt16"}>
                            Please enter your email address to register.<br/>
                            An email with the link to the new account registration page will be sent to your email address.
                        </div>
                        <div className={"login_form"}>

                            <FieldWithLabel
                                label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.メールアドレス)}
                                errorMessages={convertErrorMessageVoToArrayStr(sendCreateAccountLinkNewStudents.errorMessages?.email)}
                            >
                                <TextField
                                    value={sendCreateAccountLinkNewStudents.form.email}
                                    handleBlur={handleBlurAccount}
                                    isError={isErrorMessage(sendCreateAccountLinkNewStudents.errorMessages?.email)}
                                />
                            </FieldWithLabel>

                            <div>
                                <Button
                                    type={"primary"}
                                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                                    width={"100%"}
                                    handleClick={handleClickLoginButton}
                                />
                            </div>

                        </div>

                    </LoginContainer>

                </div>

            </Container>

        </>

    );
};

export const SendCreateAccountLinkNewStudents = withRouter(_SendCreateAccountLinkNewStudents);

const useStyles = makeStyles({
    container: {
        "& .container_inner": {
            display: "flex"
        }
    },
    root: {
        "& .login_title": {
            textAlign: "center",
            fontSize: 18,
            fontWeight: 700,
            marginBottom: 16,
        },
        "& .login_form": {
            marginTop: 16,
            "& > *": {
                marginTop: 16,
                "&:first-child": {
                    marginTop: 0
                }
            },
            "& .password": {
                textAlign: "center"
            }
        }
    }
});
