import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {PostStudentSendCreateAccountLinkRequest, StudentSendCreateAccountLinkApi} from "openapi/apis";
import {SwStudentSendCreateAccountLinkPostErrorMessage} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";

export interface SendCreateAccountLinkStoreVo {
    form: SendCreateAccountLinkFormVo
    errorMessages?: SwStudentSendCreateAccountLinkPostErrorMessage
}

export interface SendCreateAccountLinkFormVo {
    studentNumber: string
}

class SendCreateAccountLinkStore extends Store<SendCreateAccountLinkStoreVo> {

    private api = new StudentSendCreateAccountLinkApi(apiConfiguration);

    constructor() {
        super({
            form: {
                studentNumber: "",
            },
        })
    }

    updateForm(part: Partial<SendCreateAccountLinkFormVo>) {
        const newForm = {
            ...this.currentState.form,
            ...part
        };
        this.dispatch(() => ({form: newForm}));
    }

    updateErrorMessages(errorMessages: Partial<SwStudentSendCreateAccountLinkPostErrorMessage> | undefined) {
        const newError: SwStudentSendCreateAccountLinkPostErrorMessage | undefined = {
            ...this.currentState.errorMessages,
            ...errorMessages
        };
        this.dispatch(() => ({errorMessages: newError}));
    }

    async postSendCreateAccountLink() {
        if (this.currentState.form.studentNumber === "") {
            this.updateErrorMessages({studentNumber: [{ja: "学籍番号が入力されていません。", en: "Student number has not been entered."}]})
            return;
        }
        const param: PostStudentSendCreateAccountLinkRequest = {
            studentNumber: this.currentState.form.studentNumber,
        };
        try {
            const res = await this.api.postStudentSendCreateAccountLink(param);
            if (res.isSuccess) {
                window.location.assign(URL_PATHS_STUDENT.send_create_account_link_complete);
            } else {
                this.updateErrorMessages(res.error)
            }
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const sendCreateAccountLinkStore = new SendCreateAccountLinkStore();
