/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2005,
    InlineResponse2005FromJSON,
    InlineResponse2005ToJSON,
    SwBasicInfoRequest,
    SwBasicInfoRequestFromJSON,
    SwBasicInfoRequestToJSON,
    SwBasicInfoResponse,
    SwBasicInfoResponseFromJSON,
    SwBasicInfoResponseToJSON,
} from '../models';

export interface GetBasicInfoRequest {
    studentNumber: string;
}

export interface PostBasicInfoRequest {
    studentNumber: string;
    swBasicInfoRequest: SwBasicInfoRequest;
}

/**
 * no description
 */
export class BasicInfoApi extends runtime.BaseAPI {

    /**
     * 様式1の基本情報の取得
     */
    async getBasicInfoRaw(requestParameters: GetBasicInfoRequest): Promise<runtime.ApiResponse<SwBasicInfoResponse>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling getBasicInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/basic_info/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwBasicInfoResponseFromJSON(jsonValue));
    }

    /**
     * 様式1の基本情報の取得
     */
    async getBasicInfo(requestParameters: GetBasicInfoRequest): Promise<SwBasicInfoResponse> {
        const response = await this.getBasicInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * 様式1の基本情報の更新もしくは挿入。 様式の状態が「未入力」または「未確定」の場合のみ可能。 保存の際にチェックをかけ、エラーがある場合は保存せずエラーの内容を返す。 エラーがない場合は基本情報を保存し、様式の状態が「未入力」の場合は「未確定」に更新する。 
     */
    async postBasicInfoRaw(requestParameters: PostBasicInfoRequest): Promise<runtime.ApiResponse<InlineResponse2005>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling postBasicInfo.');
        }

        if (requestParameters.swBasicInfoRequest === null || requestParameters.swBasicInfoRequest === undefined) {
            throw new runtime.RequiredError('swBasicInfoRequest','Required parameter requestParameters.swBasicInfoRequest was null or undefined when calling postBasicInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/officer/basic_info/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwBasicInfoRequestToJSON(requestParameters.swBasicInfoRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2005FromJSON(jsonValue));
    }

    /**
     * 様式1の基本情報の更新もしくは挿入。 様式の状態が「未入力」または「未確定」の場合のみ可能。 保存の際にチェックをかけ、エラーがある場合は保存せずエラーの内容を返す。 エラーがない場合は基本情報を保存し、様式の状態が「未入力」の場合は「未確定」に更新する。 
     */
    async postBasicInfo(requestParameters: PostBasicInfoRequest): Promise<InlineResponse2005> {
        const response = await this.postBasicInfoRaw(requestParameters);
        return await response.value();
    }

}
