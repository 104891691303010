/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwPostOfficerStudentsErrorMessageDeleteWithYosiki,
    SwPostOfficerStudentsErrorMessageDeleteWithYosikiFromJSON,
    SwPostOfficerStudentsErrorMessageDeleteWithYosikiFromJSONTyped,
    SwPostOfficerStudentsErrorMessageDeleteWithYosikiToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse2001Warn
 */
export interface InlineResponse2001Warn {
    /**
     * 
     * @type {Array<SwPostOfficerStudentsErrorMessageDeleteWithYosiki>}
     * @memberof InlineResponse2001Warn
     */
    deleteWithYosiki: Array<SwPostOfficerStudentsErrorMessageDeleteWithYosiki>;
}

export function InlineResponse2001WarnFromJSON(json: any): InlineResponse2001Warn {
    return InlineResponse2001WarnFromJSONTyped(json, false);
}

export function InlineResponse2001WarnFromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2001Warn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleteWithYosiki': ((json['deleteWithYosiki'] as Array<any>).map(SwPostOfficerStudentsErrorMessageDeleteWithYosikiFromJSON)),
    };
}

export function InlineResponse2001WarnToJSON(value?: InlineResponse2001Warn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deleteWithYosiki': ((value.deleteWithYosiki as Array<any>).map(SwPostOfficerStudentsErrorMessageDeleteWithYosikiToJSON)),
    };
}


