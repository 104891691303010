import React from 'react';

export const ModalContentSyogakukinJukyuJokyoRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                奨学金受給申告書(様式2)の 申告内容について記入すること。 なお、日本学術振興会研究奨励金は<span className={"underLine"}>収入・状況欄の給料・賃金欄</span>の方に記入すること。
            </div>

            <div className={"modalContent_item"}>
                Enter information on the content stated on the Scholarship Receipt Report (Form 2) Research incentives
                from the Japan Society for the Promotion of Science must be entered in the <span
                className={"underLine"}>“Salary and Wages” field of the “Status of Income” field.</span>
            </div>

        </div>

    );
};
