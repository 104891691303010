/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwOfficerOsirasePutRequest
 */
export interface SwOfficerOsirasePutRequest {
    /**
     * 
     * @type {string}
     * @memberof SwOfficerOsirasePutRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerOsirasePutRequest
     */
    body: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SwOfficerOsirasePutRequest
     */
    uploadedFileIds: Array<string>;
}

export function SwOfficerOsirasePutRequestFromJSON(json: any): SwOfficerOsirasePutRequest {
    return SwOfficerOsirasePutRequestFromJSONTyped(json, false);
}

export function SwOfficerOsirasePutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerOsirasePutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'body': json['body'],
        'uploadedFileIds': json['uploadedFileIds'],
    };
}

export function SwOfficerOsirasePutRequestToJSON(value?: SwOfficerOsirasePutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'body': value.body,
        'uploadedFileIds': value.uploadedFileIds,
    };
}


