/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwNyugakuryoInfo
 */
export interface SwNyugakuryoInfo {
    /**
     * 
     * @type {string}
     * @memberof SwNyugakuryoInfo
     */
    riyu: string;
}

export function SwNyugakuryoInfoFromJSON(json: any): SwNyugakuryoInfo {
    return SwNyugakuryoInfoFromJSONTyped(json, false);
}

export function SwNyugakuryoInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwNyugakuryoInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'riyu': json['riyu'],
    };
}

export function SwNyugakuryoInfoToJSON(value?: SwNyugakuryoInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'riyu': value.riyu,
    };
}


