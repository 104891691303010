/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwKikans,
    SwKikansFromJSON,
    SwKikansFromJSONTyped,
    SwKikansToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerKikanPostRequest
 */
export interface SwOfficerKikanPostRequest {
    /**
     * enum
     * @type {string}
     * @memberof SwOfficerKikanPostRequest
     */
    bukyokuCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof SwOfficerKikanPostRequest
     */
    isSinnyusei: boolean;
    /**
     * 
     * @type {SwKikans}
     * @memberof SwOfficerKikanPostRequest
     */
    kikans: SwKikans;
}

export function SwOfficerKikanPostRequestFromJSON(json: any): SwOfficerKikanPostRequest {
    return SwOfficerKikanPostRequestFromJSONTyped(json, false);
}

export function SwOfficerKikanPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerKikanPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bukyokuCode': json['bukyokuCode'],
        'isSinnyusei': json['isSinnyusei'],
        'kikans': SwKikansFromJSON(json['kikans']),
    };
}

export function SwOfficerKikanPostRequestToJSON(value?: SwOfficerKikanPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bukyokuCode': value.bukyokuCode,
        'isSinnyusei': value.isSinnyusei,
        'kikans': SwKikansToJSON(value.kikans),
    };
}


