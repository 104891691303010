import {createStyles} from "@material-ui/core";
import MuiButton from '@material-ui/core/Button';
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, ReactNode} from 'react';
import {BorderColorsEnum, ButtonColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";

export interface ButtonProps {
    type?: "primary" | "secondary" | "warning" | "default" | "destructive";
    size?: "small" | "medium" | "large";
    inputType?: 'submit' | 'reset' | 'button';
    className?: string;
    width?: string;
    label?: string;
    disabled?: boolean;
    href?: string;
    icon?: ReactNode;
    iconReverse?: boolean;
    handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button: FC<ButtonProps> = (props) => {
    const variant = props.type === "default" ? "outlined" : "contained";
    const classes = useStyles();
    return (
        <MuiButton
            variant={variant}
            className={`${classes.root} ${classes[props.type!]} ${classes[props.size!]} ${props.disabled && classes["disabled"]} ${props.iconReverse && classes.iconReverse} ${props.iconReverse && classes.iconReverse} ${props.className}`}
            disabled={props.disabled}
            onClick={props.handleClick}
            style={{width: props.width}}
            href={props.href}
            type={props.inputType}
        >
            {props.label}{props.icon}
        </MuiButton>
    )
};

Button.defaultProps = {
    label: "",
    width: "",
    size: "medium",
    type: "default",
    disabled: false,
};

const useStyles = makeStyles(() => createStyles({
    root: {
        maxWidth: "100%",
        borderRadius: 4,
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);",
        minWidth: "inherit",
        fontWeight: 700,
        '&:hover': {
            filter: " brightness(1.15)",
        },
        "& .MuiButton-label": {
            display: "inline-flex",
            "& .icon": {
                marginLeft: 4,
            },
        },
    },
    label: {
        fontWeight: "bold",
    },
    primary: {
        backgroundColor: ButtonColorsEnum.Primary,
        border: `1px solid ${ButtonColorsEnum.Primary}`,
        color: TextColorsEnum.White,
        '&:hover': {
            backgroundColor: ButtonColorsEnum.Primary,
        },
    },
    secondary: {
        backgroundColor: ButtonColorsEnum.Secondary,
        border: `1px solid ${ButtonColorsEnum.Secondary}`,
        color: TextColorsEnum.White,
        '&:hover': {
            backgroundColor: ButtonColorsEnum.Secondary,
        },
    },
    warning: {
        backgroundColor: ButtonColorsEnum.Warning,
        border: `1px solid ${ButtonColorsEnum.Warning}`,
        color: TextColorsEnum.White,
        '&:hover': {
            backgroundColor: ButtonColorsEnum.Warning,
        }
    },
    default: {
        backgroundColor: ButtonColorsEnum.Default,
        border: `1px solid ${BorderColorsEnum.Gray}`,
        color: TextColorsEnum.Black,
        boxSizing: "border-box",
        '&:hover': {
            backgroundColor: ButtonColorsEnum.Default,
        },
    },
    disabled: {
        '&.Mui-disabled': {
            backgroundColor: ButtonColorsEnum.Disabled,
            borderColor: ButtonColorsEnum.Disabled,
            color: TextColorsEnum.White,
            boxShadow: "0 2px 0 0 rgba(0,0,0,0.1)",
            filter: "none",
        },
    },
    destructive: {
        backgroundColor: ButtonColorsEnum.Destructive,
        border: `1px solid ${ButtonColorsEnum.Destructive}`,
        color: TextColorsEnum.White,
        '&:hover': {
            backgroundColor: ButtonColorsEnum.Destructive,
        },
    },
    small: {
        padding: "1px 6px",
        fontSize: 12,
    },
    medium: {
        padding: "5px 10px",
        fontSize: 12,
    },
    large: {
        padding: 13,
        fontSize: 12,
    },
    iconReverse: {
        "& .MuiButton-label": {
            flexFlow: "row-reverse",
            "& .icon": {
                marginLeft: 0,
                marginRight: 4,
            },
        },
    },
}));