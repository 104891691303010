/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SyoruiConfirmationFormHonninErrorMessage
 */
export interface SyoruiConfirmationFormHonninErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninErrorMessage
     */
    isTugakuFromJitakuWithoutCertificate?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninErrorMessage
     */
    isSyotokuwarigaku0yen?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninErrorMessage
     */
    isKaigoLevel3to6?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninErrorMessage
     */
    isTyokiryoyoMoreThan6month?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninErrorMessage
     */
    isKokiSinnyuseiEnrolledInJapaneseUniversityBefore?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninErrorMessage
     */
    isPartTimeJob?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninErrorMessage
     */
    isSelectedScholarshipGrant?: Array<SwErrorMessage>;
}

export function SyoruiConfirmationFormHonninErrorMessageFromJSON(json: any): SyoruiConfirmationFormHonninErrorMessage {
    return SyoruiConfirmationFormHonninErrorMessageFromJSONTyped(json, false);
}

export function SyoruiConfirmationFormHonninErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyoruiConfirmationFormHonninErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isTugakuFromJitakuWithoutCertificate': !exists(json, 'isTugakuFromJitakuWithoutCertificate') ? undefined : ((json['isTugakuFromJitakuWithoutCertificate'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isSyotokuwarigaku0yen': !exists(json, 'isSyotokuwarigaku0yen') ? undefined : ((json['isSyotokuwarigaku0yen'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isKaigoLevel3to6': !exists(json, 'isKaigoLevel3to6') ? undefined : ((json['isKaigoLevel3to6'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isTyokiryoyoMoreThan6month': !exists(json, 'isTyokiryoyoMoreThan6month') ? undefined : ((json['isTyokiryoyoMoreThan6month'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isKokiSinnyuseiEnrolledInJapaneseUniversityBefore': !exists(json, 'isKokiSinnyuseiEnrolledInJapaneseUniversityBefore') ? undefined : ((json['isKokiSinnyuseiEnrolledInJapaneseUniversityBefore'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isPartTimeJob': !exists(json, 'isPartTimeJob') ? undefined : ((json['isPartTimeJob'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isSelectedScholarshipGrant': !exists(json, 'isSelectedScholarshipGrant') ? undefined : ((json['isSelectedScholarshipGrant'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SyoruiConfirmationFormHonninErrorMessageToJSON(value?: SyoruiConfirmationFormHonninErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isTugakuFromJitakuWithoutCertificate': value.isTugakuFromJitakuWithoutCertificate === undefined ? undefined : ((value.isTugakuFromJitakuWithoutCertificate as Array<any>).map(SwErrorMessageToJSON)),
        'isSyotokuwarigaku0yen': value.isSyotokuwarigaku0yen === undefined ? undefined : ((value.isSyotokuwarigaku0yen as Array<any>).map(SwErrorMessageToJSON)),
        'isKaigoLevel3to6': value.isKaigoLevel3to6 === undefined ? undefined : ((value.isKaigoLevel3to6 as Array<any>).map(SwErrorMessageToJSON)),
        'isTyokiryoyoMoreThan6month': value.isTyokiryoyoMoreThan6month === undefined ? undefined : ((value.isTyokiryoyoMoreThan6month as Array<any>).map(SwErrorMessageToJSON)),
        'isKokiSinnyuseiEnrolledInJapaneseUniversityBefore': value.isKokiSinnyuseiEnrolledInJapaneseUniversityBefore === undefined ? undefined : ((value.isKokiSinnyuseiEnrolledInJapaneseUniversityBefore as Array<any>).map(SwErrorMessageToJSON)),
        'isPartTimeJob': value.isPartTimeJob === undefined ? undefined : ((value.isPartTimeJob as Array<any>).map(SwErrorMessageToJSON)),
        'isSelectedScholarshipGrant': value.isSelectedScholarshipGrant === undefined ? undefined : ((value.isSelectedScholarshipGrant as Array<any>).map(SwErrorMessageToJSON)),
    };
}


