import React from 'react';

export const ModalContentTugakuKubun: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                独立生計者の通学区分は自宅とすること。
            </div>

            <div className={"modalContent_item"}>
                The “School Commute Classification” of individuals in independent households should be “From family home”.
            </div>

        </div>

    );
};
