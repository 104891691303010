import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";

// react-routerで推奨されているスクロールの処理
// https://reacttraining.com/react-router/web/guides/scroll-restoration
export const ScrollTop: React.FC = () => {

    const { pathname } = useLocation();

    // パス変更時に画面トップへスクロール
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null; // 何もレンダリングしない

};

