import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {FamilyInfoVo, MemberVo} from "common/models/YosikiInfoModels";
import {YosikiMastersVo} from "common/models/YosikiMastersVo";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {TitleNameTextEnum, TitleNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TitleNameTextEnum";
import {Title04} from "components/atoms/titles/Title04";
import {Title05} from "components/atoms/titles/Title05";
import {FormTable} from "components/molecules/forms/FormTable";
import {FamilyInfoSectionMemberDetail} from "components/molecules/yosikiDetail/FamilyInfoSectionMemberDetail";
import {FamilyInfoSectionSyugakusyaDetail} from "components/molecules/yosikiDetail/FamilyInfoSectionSyugakusyaDetail";
import {
    FamilyInfoSectionSyugakusyaHoninDetail
} from "components/molecules/yosikiDetail/syunyuInfo/FamilyInfoSectionSyugakusyaHonninDetail";
import {
    FamilyInfoSectionSyunyuDetail
} from "components/molecules/yosikiDetail/syunyuInfo/FamilyInfoSectionSyunyuDetail";
import {
    FamilyInfoSectionSyunyuHoninDetail
} from "components/molecules/yosikiDetail/syunyuInfo/FamilyInfoSectionSyunyuHonninDetail";
import React, {FC, ReactNode} from "react";
import {canInputSyunyu} from "../../../common/functions/yosikiInfo/canInputSyunyu";

interface FamilyInfoDetailSectionProps {
    className?: string
    familyInfo: FamilyInfoVo
    commonYosikiMasters?: YosikiMastersVo
    isGakubu: boolean
    isDokuritu?: boolean
}

export const _FamilyInfoDetailSection: FC<FamilyInfoDetailSectionProps> = ({
                                                                               className,
                                                                               familyInfo,
                                                                               commonYosikiMasters,
                                                                               isGakubu,
                                                                               isDokuritu
                                                                           }) => {

    const classes = useStyles();

    const members = familyInfo.members;
    if (commonYosikiMasters === undefined) return <></>;

    // メンバーの一覧作成
    const memberComponents: Array<ReactNode> = [];
    members.forEach((m: MemberVo, index: number) => {
        let component: ReactNode = (
            <FamilyInfoSectionMemberDetail
                key={"memberComponents" + m.name + index}
                index={index}
                member={m}
            />
        );
        memberComponents.push(component);
    });

    // 就学者以外の収入一覧を作成
    const syunyuComponents: Array<ReactNode> = [
        <FamilyInfoSectionSyunyuHoninDetail
            key={"syunyuComponents"}
            honnin={familyInfo.honnin}
            commonYosikiMasters={commonYosikiMasters}
        />
    ];
    members.forEach((m: MemberVo, index: number) => {
        if (canInputSyunyu(m, isGakubu, isDokuritu)) {
            let component: ReactNode = (
                <FamilyInfoSectionSyunyuDetail
                    key={"syunyuComponents" + m.name + index}
                    member={m}
                    commonYosikiMasters={commonYosikiMasters}
                />
            );
            syunyuComponents.push(component);
        }
    });

    // 就学者の一覧を作成
    const syugakuComponents: Array<ReactNode> = [
        <FamilyInfoSectionSyugakusyaHoninDetail
            key={"syugakuComponents"}
            honnin={familyInfo.honnin}
        />
    ];
    members.forEach((m: MemberVo, index: number) => {
        if (m.isSyugakusya === true) {
            let component: ReactNode = (
                <FamilyInfoSectionSyugakusyaDetail
                    key={"syugakuComponents" + m.name + index}
                    index={index}
                    member={m}
                    members={members}
                />
            );
            syugakuComponents.push(component);
        }
    });

    return (

        <div id={SECTION_ID_NAME.familyInfo} className={`${classes.root} ${className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.家庭状況)}
                className={"section_title"}
            />

            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.家族構成)}
                className={classes.title}
            />

            <FormTable
                className={"section_table"}
            >
                {memberComponents}

            </FormTable>

            {
                syunyuComponents.length === 0 ?
                    null :
                    <>
                        <Title05
                            title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.収入所得)}
                            className={classes.title}
                        />
                        <FormTable
                            className={"section_table"}
                        >
                            {syunyuComponents}
                        </FormTable>
                    </>
            }

            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.家庭の就学者情報)}
                className={classes.title}
            />
            <FormTable
                className={"section_table"}
            >
                {syugakuComponents}
            </FormTable>

        </div>

    )
};

export const FamilyInfoDetailSection = React.memo<FamilyInfoDetailSectionProps>(_FamilyInfoDetailSection, (prev, next) => {
    return prev.className === next.className
        && prev.familyInfo === next.familyInfo
        && prev.commonYosikiMasters === next.commonYosikiMasters
        && prev.isGakubu === next.isGakubu
        && prev.isDokuritu === next.isDokuritu
});

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        }
    }
);

