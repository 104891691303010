import React from 'react';
import {Title04} from "../../../atoms/titles/Title04";
import Grid from "@material-ui/core/Grid";
import {ZokugaraEnum, zokugaraEnumToName} from "../../../../common/enums/ZokugaraEnum";
import {convertEnumNameToStr} from "../../../../common/functions/converter/convertEnumNameToStr";
import {SyoruiConfirmationMemberVo} from "../../../../common/models/SyoruiConfirmationModels";
import {YoryoItem} from "../YoryoItem";

interface Props {
    index: number
    member: SyoruiConfirmationMemberVo
}

export const MemberYoryos: React.FC<Props> = ({
                                                index,
                                                member,
                                            }) => {

    return (
        <Grid container spacing={3} direction={"column"}>

            <Grid item xs>
                <Title04
                    title={`${member.name} (${member.zokugara === ZokugaraEnum.その他 ? member.zokugaraOther : convertEnumNameToStr(zokugaraEnumToName(member.zokugara))})`}
                />
            </Grid>

            <Grid item xs>

                {
                    member.yoryos.map((m, i) => {
                        return (
                            <YoryoItem
                                key={i}
                                index={i}
                                ja={m.ja}
                                en={m.en}
                            />
                        )
                    })
                }

            </Grid>

            <Grid item xs>

                {/* NOTE: 改修2 - 7 で開発予定 */}
                {/*<SyoruiTable syoruis={member.syoruis} />*/}

            </Grid>

        </Grid>
    )
}

