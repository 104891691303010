// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum ZokugaraEnum {
    父 = "E父",
    母 = "E母",
    兄 = "E兄",
    姉 = "E姉",
    弟 = "E弟",
    妹 = "E妹",
    祖父 = "E祖父",
    祖母 = "E祖母",
    配偶者 = "E配偶者",
    その他 = "Eその他",
}

export function zokugaraEnumToName(e: ZokugaraEnum): { ja: string, en: string } {
    switch (e) {
        case ZokugaraEnum.父: return { ja: "父", en: "Father" };
        case ZokugaraEnum.母: return { ja: "母", en: "Mother" };
        case ZokugaraEnum.兄: return { ja: "兄", en: "Older brother" };
        case ZokugaraEnum.姉: return { ja: "姉", en: "Older sister" };
        case ZokugaraEnum.弟: return { ja: "弟", en: "Younger brother" };
        case ZokugaraEnum.妹: return { ja: "妹", en: "Younger sister" };
        case ZokugaraEnum.祖父: return { ja: "祖父", en: "Grandfather" };
        case ZokugaraEnum.祖母: return { ja: "祖母", en: "Grandmother" };
        case ZokugaraEnum.配偶者: return { ja: "配偶者", en: "Spouses" };
        case ZokugaraEnum.その他: return { ja: "その他 (自由入力)", en: "Other(Free input)" };
    }
}