import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {TokubetuKojoEnum} from "common/enums/TokubetuKojoEnum";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {BasicInfoVo, FamilyInfoVo, MemberSyugakusyaIgaiVo, MemberVo} from "common/models/YosikiInfoModels";
import {YosikiMastersVo} from "common/models/YosikiMastersVo";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {TitleNameTextEnum, TitleNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TitleNameTextEnum";
import {Button} from "components/atoms/Button";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import {AnnotationText} from "components/atoms/AnnotationText";
import {ErrorCountMessage} from "components/atoms/ErrorCountMessage";
import {Title04} from "components/atoms/titles/Title04";
import {Title05} from "components/atoms/titles/Title05";
import {FormButton} from "components/molecules/forms/FormButton";
import {FormTable} from "components/molecules/forms/FormTable";
import {FamilyInfoSectionMember} from "components/molecules/yosikiEdit/FamilyInfoSectionMember";
import {FamilyInfoSectionSyugakusya} from "components/molecules/yosikiEdit/FamilyInfoSectionSyugakusya";
import {FamilyInfoSectionSyugakusyaHonin} from "components/molecules/yosikiEdit/FamilyInfoSectionSyugakusyaHonnin";
import {FamilyInfoSectionSyunyu} from "components/molecules/yosikiEdit/syunyuInfo/FamilyInfoSectionSyunyu";
import {FamilyInfoSectionSyunyuHonnin} from "components/molecules/yosikiEdit/syunyuInfo/FamilyInfoSectionSyunyuHonnin";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {
    SwFamilyInfoErrorMessage,
    SwFamilyInfoErrorMessageHonnin,
    SwMemberErrorMessages,
    SwMemberErrorMessagesSyugakusya,
    SwMemberErrorMessagesSyugakusyaIgai
} from "openapi/models";
import React, {FC, ReactNode} from "react";
import {canInputSyunyu} from "../../../common/functions/yosikiInfo/canInputSyunyu";

interface FamilyInfoSectionProps {
    className?: string
    familyInfo: FamilyInfoVo
    familyInfoErrorMessage: SwFamilyInfoErrorMessage
    basicInfo: BasicInfoVo;
    commonYosikiMasters?: YosikiMastersVo
    updateFamilyInfo: (part: Partial<FamilyInfoVo>) => void
    updateFamilyInfoErrorMessage: (part: Partial<SwFamilyInfoErrorMessage>) => void
    updateDeleteZokugaraModalOpenState: (value: boolean) => void
    updateSelectedZokugaraIndex: (value: number) => void
    updateSelectZokugaraModalOpenState: (value: boolean) => void
    errorCount: number
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
    isGakubu: boolean
    isDokuritu?: boolean
    isRyugakusei: boolean
}

export const FamilyInfoSection: FC<FamilyInfoSectionProps> = ({
                                                                  className,
                                                                  familyInfo,
                                                                  familyInfoErrorMessage,
                                                                  basicInfo,
                                                                  commonYosikiMasters,
                                                                  updateFamilyInfo,
                                                                  updateFamilyInfoErrorMessage,
                                                                  updateDeleteZokugaraModalOpenState,
                                                                  updateSelectedZokugaraIndex,
                                                                  updateSelectZokugaraModalOpenState,
                                                                  errorCount,
                                                                  onClickWordDescriptionLink,
                                                                  isGakubu,
                                                                  isDokuritu,
                                                                  isRyugakusei
                                                              }) => {

    const classes = useStyles();

    function handleClickAddZokugara() {
        updateSelectZokugaraModalOpenState(true)
    }

    function updateMembers(partMember: Partial<MemberVo>, index: number) {
        const members = familyInfo.members;
        const member = members[index];
        members[index] = {
            ...member,
            ...partMember
        };
        let part: Partial<FamilyInfoVo> = {members: members};
        updateFamilyInfo(part)
    }

    function updateMemberAsSyugakusyaIgai(value: Partial<MemberSyugakusyaIgaiVo>, index: number) {
        const members = familyInfo.members;
        const syugakusyaIgai = members[index].syugakusyaIgai;
        const member = members[index];
        if (syugakusyaIgai === undefined) return;
        members[index] = {
            ...member,
            syugakusyaIgai: {
                ...syugakusyaIgai,
                ...value,
            },
        };
        let part: Partial<FamilyInfoVo> = {members: members};
        updateFamilyInfo(part)
    }

    // 本人のエラーを更新
    function updateHonninErrorMessages(part: Partial<SwFamilyInfoErrorMessageHonnin>) {
        if (familyInfoErrorMessage === undefined) return;
        let honnin: SwFamilyInfoErrorMessageHonnin | undefined = familyInfoErrorMessage.honnin;
        updateFamilyInfoErrorMessage({
            honnin: {
                ...honnin,
                ...part
            }
        })
    }

    // メンバーのエラーを更新
    function updateMemberErrorMessages(part: Partial<SwMemberErrorMessages>, index: number) {
        if (familyInfoErrorMessage === undefined) return;
        let member: SwMemberErrorMessages | undefined = familyInfoErrorMessage.members[index];
        member = {
            ...member,
            ...part
        };
        familyInfoErrorMessage.members.splice(index, 1, member);
        updateFamilyInfoErrorMessage({members: familyInfoErrorMessage.members})
    }

    function updateSyugakusyaIgaiErrorMessages(part: Partial<SwMemberErrorMessagesSyugakusyaIgai>, memberIndex: number) {
        if (familyInfoErrorMessage === undefined) return;
        let member: SwMemberErrorMessages | undefined = familyInfoErrorMessage.members[memberIndex];
        if (member?.syugakusyaIgai === undefined) return;
        member = {
            ...member,
            syugakusyaIgai: {
                ...member.syugakusyaIgai,
                ...part
            }
        };
        familyInfoErrorMessage.members.splice(memberIndex, 1, member);
        updateFamilyInfoErrorMessage({members: familyInfoErrorMessage.members})
    }

    function updateSyugakusyaErrorMessages(part: Partial<SwMemberErrorMessagesSyugakusya>, index: number) {
        if (familyInfoErrorMessage === undefined) return;
        let member: SwMemberErrorMessages | undefined = familyInfoErrorMessage.members[index];
        if (member?.syugakusya === undefined) return;
        member = {
            ...member,
            syugakusya: {
                ...member.syugakusyaIgai,
                ...part
            }
        };
        familyInfoErrorMessage.members.splice(index, 1, member);
        updateFamilyInfoErrorMessage({members: familyInfoErrorMessage.members})
    }

    const members = familyInfo.members;
    if (commonYosikiMasters === undefined) return <></>;

    // メンバーの一覧作成
    const memberComponents: Array<ReactNode> = [];
    members.forEach((m: MemberVo, index: number) => {
        let component: ReactNode = (
            <FamilyInfoSectionMember
                key={"memberComponents" + m.name + index}
                index={index}
                member={m}
                familyInfoErrorMessage={familyInfoErrorMessage.members[index]}
                updateMembers={updateMembers}
                updateMemberAsSyugakusyaIgai={updateMemberAsSyugakusyaIgai}
                updateMemberErrorMessages={updateMemberErrorMessages}
                updateDeleteZokugaraModalOpenState={updateDeleteZokugaraModalOpenState}
                updateSelectedZokugaraIndex={updateSelectedZokugaraIndex}
                onClickWordDescriptionLink={onClickWordDescriptionLink}
            />
        );
        memberComponents.push(component);
    });


    // 就学者以外の収入一覧を作成
    const syunyuComponents: Array<ReactNode> = [
        <FamilyInfoSectionSyunyuHonnin
            key={"syunyuComponents"}
            honnin={familyInfo.honnin}
            commonYosikiMasters={commonYosikiMasters}
            updateFamilyInfo={updateFamilyInfo}
            updateHonninErrorMessages={updateHonninErrorMessages}
            honninErrorMessages={familyInfoErrorMessage.honnin}
        />
    ];

    // 収入所得の入力
    members.forEach((m: MemberVo, index: number) => {
        if (canInputSyunyu(m, isGakubu, isDokuritu)) {
            let component: ReactNode = (
                <FamilyInfoSectionSyunyu
                    key={"syunyuComponents" + m.name + index}
                    memberIndex={index}
                    member={m}
                    members={members}
                    commonYosikiMasters={commonYosikiMasters}
                    syugakusyaIgaiErrorMessages={familyInfoErrorMessage.members[index] ? familyInfoErrorMessage.members[index].syugakusyaIgai : undefined}
                    updateFamilyInfo={updateFamilyInfo}
                    updateSyugakusyaIgaiErrorMessages={updateSyugakusyaIgaiErrorMessages}
                />
            );
            syunyuComponents.push(component);
        }
    });

    // 就学者の一覧を作成
    const syugakuComponents: Array<ReactNode> = [
        <FamilyInfoSectionSyugakusyaHonin
            key={"syugakuComponents"}
            honnin={familyInfo.honnin}
            updateFamilyInfo={updateFamilyInfo}
            updateHonninErrorMessages={updateHonninErrorMessages}
            honninErrorMessages={familyInfoErrorMessage.honnin}
            onClickWordDescriptionLink={onClickWordDescriptionLink}
        />
    ];

    members.forEach((m: MemberVo, index: number) => {
        if (m.isSyugakusya === true) {
            let component: ReactNode = (
                <FamilyInfoSectionSyugakusya
                    key={"syugakuComponents" + m.name + index}
                    index={index}
                    member={m}
                    members={members}
                    commonYosikiMasters={commonYosikiMasters}
                    syugakusyaErrorMessages={familyInfoErrorMessage.members[index]?.syugakusya}
                    updateFamilyInfo={updateFamilyInfo}
                    updateSyugakusyaErrorMessages={updateSyugakusyaErrorMessages}
                    onClickWordDescriptionLink={onClickWordDescriptionLink}
                    isRyugakusei={isRyugakusei}
                />
            );
            syugakuComponents.push(component);
        }
    });

    return (

        <div id={SECTION_ID_NAME.familyInfo} className={`${classes.root} ${className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.家庭状況)}
                className={"section_title"}
            />

            {
                basicInfo.tokubetuKojo.indexOf(TokubetuKojoEnum.独立生計者) !== -1 &&
                <AnnotationText className={"mt8"} type={'warning'}
                                text={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.独立生計者は家族構成に父母祖父祖母を選択できません続柄を削除で削除してください)}/>
            }

            {
                basicInfo.tokubetuKojo.indexOf(TokubetuKojoEnum.母子父子) !== -1 &&
                <AnnotationText className={"mt8"} type={'warning'}
                                text={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.母無の場合家族構成から母を削除してください父無の場合家族構成から父を削除してください)}/>
            }

            <ErrorCountMessage
                count={errorCount}
            />

            {
                familyInfoErrorMessage.dokuritu &&
                <ErrorMessages
                    errorMessages={convertErrorMessageVoToArrayStr(familyInfoErrorMessage.dokuritu)}
                />
            }

            {
                familyInfoErrorMessage.bosiHusi &&
                <ErrorMessages
                    errorMessages={convertErrorMessageVoToArrayStr(familyInfoErrorMessage.bosiHusi)}
                />
            }

            {
                familyInfoErrorMessage.honnin.syogakukin &&
                <ErrorMessages
                    errorMessages={convertErrorMessageVoToArrayStr(familyInfoErrorMessage.honnin.syogakukin)}
                />
            }

            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.家族構成)}
                className={classes.title}
            />

            <FormTable
                className={"section_table"}
            >
                {memberComponents}
            </FormTable>

            <FormButton
                className={"section_button"}
            >
                <Button
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を追加)}
                    handleClick={handleClickAddZokugara}
                />
            </FormButton>

            {
                syunyuComponents.length === 0 ?
                    null :
                    <>
                        <Title05
                            title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.収入所得)}
                            className={classes.title}
                        />

                        <FormTable
                            className={"section_table"}
                        >
                            {syunyuComponents}
                        </FormTable>
                    </>
            }

            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.家庭の就学者情報)}
                className={classes.title}
            />

            <FormTable
                className={"section_table"}
            >
                {syugakuComponents}
            </FormTable>

        </div>

    )
};

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        }
    }
);

