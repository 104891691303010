/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2003,
    InlineResponse2003FromJSON,
    InlineResponse2003ToJSON,
    InlineResponse2004,
    InlineResponse2004FromJSON,
    InlineResponse2004ToJSON,
    SwOfficerSinseisya,
    SwOfficerSinseisyaFromJSON,
    SwOfficerSinseisyaToJSON,
} from '../models';

export interface GetOfficerSinseisyasRequest {
    page: number;
    perPage: number;
    numberType?: string;
    studentNumber?: string;
    name?: string;
    nameKana?: string;
    gakubu?: string;
    gakka?: string;
    studentKubun?: string;
    ryugakuseiKubun?: string;
    nyugakuryoMenjo?: boolean;
    nyugakuryoYuyo?: boolean;
    jugyoryoMenjo?: boolean;
    jugyoryoYuyo?: boolean;
    sinseiState?: string;
}

export interface PostAddSinsisyaRequest {
    studentNumber: string;
}

export interface PostDeleteSinsisyaRequest {
    studentNumber: string;
}

/**
 * no description
 */
export class OfficerSinseisyasApi extends runtime.BaseAPI {

    /**
     * 申請者一覧
     */
    async getOfficerSinseisyasRaw(requestParameters: GetOfficerSinseisyasRequest): Promise<runtime.ApiResponse<SwOfficerSinseisya>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getOfficerSinseisyas.');
        }

        if (requestParameters.perPage === null || requestParameters.perPage === undefined) {
            throw new runtime.RequiredError('perPage','Required parameter requestParameters.perPage was null or undefined when calling getOfficerSinseisyas.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.numberType !== undefined) {
            queryParameters['numberType'] = requestParameters.numberType;
        }

        if (requestParameters.studentNumber !== undefined) {
            queryParameters['studentNumber'] = requestParameters.studentNumber;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.nameKana !== undefined) {
            queryParameters['nameKana'] = requestParameters.nameKana;
        }

        if (requestParameters.gakubu !== undefined) {
            queryParameters['gakubu'] = requestParameters.gakubu;
        }

        if (requestParameters.gakka !== undefined) {
            queryParameters['gakka'] = requestParameters.gakka;
        }

        if (requestParameters.studentKubun !== undefined) {
            queryParameters['studentKubun'] = requestParameters.studentKubun;
        }

        if (requestParameters.ryugakuseiKubun !== undefined) {
            queryParameters['ryugakuseiKubun'] = requestParameters.ryugakuseiKubun;
        }

        if (requestParameters.nyugakuryoMenjo !== undefined) {
            queryParameters['nyugakuryoMenjo'] = requestParameters.nyugakuryoMenjo;
        }

        if (requestParameters.nyugakuryoYuyo !== undefined) {
            queryParameters['nyugakuryoYuyo'] = requestParameters.nyugakuryoYuyo;
        }

        if (requestParameters.jugyoryoMenjo !== undefined) {
            queryParameters['jugyoryoMenjo'] = requestParameters.jugyoryoMenjo;
        }

        if (requestParameters.jugyoryoYuyo !== undefined) {
            queryParameters['jugyoryoYuyo'] = requestParameters.jugyoryoYuyo;
        }

        if (requestParameters.sinseiState !== undefined) {
            queryParameters['sinseiState'] = requestParameters.sinseiState;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/sinseisyas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOfficerSinseisyaFromJSON(jsonValue));
    }

    /**
     * 申請者一覧
     */
    async getOfficerSinseisyas(requestParameters: GetOfficerSinseisyasRequest): Promise<SwOfficerSinseisya> {
        const response = await this.getOfficerSinseisyasRaw(requestParameters);
        return await response.value();
    }

    /**
     * 申請者の追加
     */
    async postAddSinsisyaRaw(requestParameters: PostAddSinsisyaRequest): Promise<runtime.ApiResponse<InlineResponse2003>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling postAddSinsisya.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/sinseisyas/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2003FromJSON(jsonValue));
    }

    /**
     * 申請者の追加
     */
    async postAddSinsisya(requestParameters: PostAddSinsisyaRequest): Promise<InlineResponse2003> {
        const response = await this.postAddSinsisyaRaw(requestParameters);
        return await response.value();
    }

    /**
     * 申請者の削除
     */
    async postDeleteSinsisyaRaw(requestParameters: PostDeleteSinsisyaRequest): Promise<runtime.ApiResponse<InlineResponse2004>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling postDeleteSinsisya.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/sinseisyas/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2004FromJSON(jsonValue));
    }

    /**
     * 申請者の削除
     */
    async postDeleteSinsisya(requestParameters: PostDeleteSinsisyaRequest): Promise<InlineResponse2004> {
        const response = await this.postDeleteSinsisyaRaw(requestParameters);
        return await response.value();
    }

}
