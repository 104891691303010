import React, {useCallback} from 'react';
import {SwSyoruiConfirmationFormErrorMessageResponse} from "../../../../openapi";
import {SyoruiConfirmationFormVo} from "../../../../common/models/SyoruiConfirmationFormModels";
import {Title04} from "../../../atoms/titles/Title04";
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import {RadioButton} from "../../../atoms/forms/RadioButton";
import {RadioButtonGroup} from "../../forms/RadioButtonGroup";
import {CommonTextEnum} from "../../../../common/enums_text/CommonTextEnum";
import Grid from "@material-ui/core/Grid";
import {FormItem} from "../FormItem";
import {Box} from "@material-ui/core";

interface Props {
    formValues: SyoruiConfirmationFormVo | undefined
    errorMessages: SwSyoruiConfirmationFormErrorMessageResponse | undefined
    updates: (formValues: SyoruiConfirmationFormVo, errorMessages?: SwSyoruiConfirmationFormErrorMessageResponse | undefined) => void
    isSyoruiHantei: boolean
}

export const SinseisyaRyugakuseiForm: React.FC<Props> = ({
                                                             formValues,
                                                             errorMessages,
                                                             updates,
                                                             isSyoruiHantei
                                                         }) => {

    const updateIsEnrolledInJapaneseUniversityBefore = useCallback((v: boolean) => {
        if (!formValues) return
        formValues.honninRyugakusei.isEnrolledInJapaneseUniversityBefore = v
        updates(formValues, {
            ...errorMessages,
            honninRyugakusei: {...errorMessages?.honninRyugakusei, isEnrolledInJapaneseUniversityBefore: undefined}
        })
    }, [formValues, errorMessages, updates])

    const updateIsJapaneseHaiguusyaHasIncome = useCallback((v: boolean) => {
        if (!formValues) return
        formValues.honninRyugakusei.isJapaneseHaiguusyaHasIncome = v
        updates(formValues, {
            ...errorMessages,
            honninRyugakusei: {...errorMessages?.honninRyugakusei, isJapaneseHaiguusyaHasIncome: undefined}
        })
    }, [formValues, errorMessages, updates])

    const updateIsPartTimeJob = useCallback((v: boolean) => {
        if (!formValues) return
        formValues.honninRyugakusei.isPartTimeJob = v
        updates(formValues, {
            ...errorMessages,
            honninRyugakusei: {...errorMessages?.honninRyugakusei, isPartTimeJob: undefined}
        })
    }, [formValues, errorMessages, updates])

    const updateIsFinancialAssistanceFromRelatives = useCallback((v: boolean) => {
        if (!formValues) return
        formValues.honninRyugakusei.isFinancialAssistanceFromRelatives = v
        updates(formValues, {
            ...errorMessages,
            honninRyugakusei: {...errorMessages?.honninRyugakusei, isFinancialAssistanceFromRelatives: undefined}
        })
    }, [formValues, errorMessages, updates])

    return (
        <Grid container spacing={3} direction={"column"}>

            <Grid item xs>
                <Title04
                    title={"申請者(留学生) / Applicant (International Student)"}
                />
            </Grid>

            <FormItem
                label={"本学入学前に日本国内の大学に在籍していましたか？ / Were you enrolled in a university in Japan prior to admission to this University?"}
            >
                {
                    isSyoruiHantei ? (
                        <Box>
                            {formValues?.honninRyugakusei.isEnrolledInJapaneseUniversityBefore ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                        </Box>
                    ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isEnrolledInJapaneseUniversityBefore) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honninRyugakusei.isEnrolledInJapaneseUniversityBefore === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isEnrolledInJapaneseUniversityBefore).length !== 0 : false}
                                handleChange={() => {
                                    updateIsEnrolledInJapaneseUniversityBefore(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honninRyugakusei.isEnrolledInJapaneseUniversityBefore === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isEnrolledInJapaneseUniversityBefore).length !== 0 : false}
                                handleChange={() => {
                                    updateIsEnrolledInJapaneseUniversityBefore(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

            <FormItem
                label={"配偶者がいる場合、配偶者は日本に在住して、前年度に所得がありますか？ / If you have a spouse, does your spouse reside in Japan and have income from the previous fiscal year?"}
            >
                {
                    isSyoruiHantei ? (
                        <Box>
                            {formValues?.honninRyugakusei.isJapaneseHaiguusyaHasIncome ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                        </Box>
                    ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isJapaneseHaiguusyaHasIncome) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honninRyugakusei.isJapaneseHaiguusyaHasIncome === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isJapaneseHaiguusyaHasIncome).length !== 0 : false}
                                handleChange={() => {
                                    updateIsJapaneseHaiguusyaHasIncome(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honninRyugakusei.isJapaneseHaiguusyaHasIncome === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isJapaneseHaiguusyaHasIncome).length !== 0 : false}
                                handleChange={() => {
                                    updateIsJapaneseHaiguusyaHasIncome(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

            <FormItem
                label={"アルバイトをしていますか？/Do you have a part-time job?"}
            >
                {
                    isSyoruiHantei ? (
                        <Box>
                            {formValues?.honninRyugakusei.isPartTimeJob ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                        </Box>
                    ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isPartTimeJob) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honninRyugakusei.isPartTimeJob === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isPartTimeJob).length !== 0 : false}
                                handleChange={() => {
                                    updateIsPartTimeJob(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honninRyugakusei.isPartTimeJob === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isPartTimeJob).length !== 0 : false}
                                handleChange={() => {
                                    updateIsPartTimeJob(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

            <FormItem
                label={"親・親戚等からの仕送等の金銭的援助がありますか？ / Do you have any financial support from parents, relatives, etc., such as an allowance?"}
            >
                {
                    isSyoruiHantei ? (
                        <Box>
                            {formValues?.honninRyugakusei.isFinancialAssistanceFromRelatives ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                        </Box>
                    ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isFinancialAssistanceFromRelatives) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honninRyugakusei.isFinancialAssistanceFromRelatives === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isFinancialAssistanceFromRelatives).length !== 0 : false}
                                handleChange={() => {
                                    updateIsFinancialAssistanceFromRelatives(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honninRyugakusei.isFinancialAssistanceFromRelatives === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honninRyugakusei?.isFinancialAssistanceFromRelatives).length !== 0 : false}
                                handleChange={() => {
                                    updateIsFinancialAssistanceFromRelatives(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

        </Grid>
    )
}

