import React from 'react';
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {BgColorsEnum} from "../../../common/enums_value/ColorsEnum";

interface SyoruiConfirmationFormItemComponentProps {
    index: number
    ja: string
    en: string
}

export const YoryoItem: React.FC<SyoruiConfirmationFormItemComponentProps> = ({
                                                                                  index,
                                                                                  ja,
                                                                                  en,
                                                                              }) => {

    const isEven = (index + 1) % 2 === 0

    return (
        <Grid item xs>
            <Box p={2} bgcolor={isEven ? "" : BgColorsEnum.OffWhite}>
                <Box>
                    <Box dangerouslySetInnerHTML={{ __html: ja }} />
                </Box>
                <Box mt={2} >
                    <Box dangerouslySetInnerHTML={{ __html: en }} />
                </Box>
            </Box>
        </Grid>
    )
}

