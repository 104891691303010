/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwBasicInfoSinseiType,
    SwBasicInfoSinseiTypeFromJSON,
    SwBasicInfoSinseiTypeFromJSONTyped,
    SwBasicInfoSinseiTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwBasicInfo
 */
export interface SwBasicInfo {
    /**
     * 
     * @type {SwBasicInfoSinseiType}
     * @memberof SwBasicInfo
     */
    sinseiType: SwBasicInfoSinseiType;
    /**
     * 
     * @type {Array<string>}
     * @memberof SwBasicInfo
     */
    tokubetuKojo: Array<string>;
}

export function SwBasicInfoFromJSON(json: any): SwBasicInfo {
    return SwBasicInfoFromJSONTyped(json, false);
}

export function SwBasicInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwBasicInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sinseiType': SwBasicInfoSinseiTypeFromJSON(json['sinseiType']),
        'tokubetuKojo': json['tokubetuKojo'],
    };
}

export function SwBasicInfoToJSON(value?: SwBasicInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sinseiType': SwBasicInfoSinseiTypeToJSON(value.sinseiType),
        'tokubetuKojo': value.tokubetuKojo,
    };
}


