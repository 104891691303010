/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSimulationDetail,
    SwSimulationDetailFromJSON,
    SwSimulationDetailFromJSONTyped,
    SwSimulationDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSimulation
 */
export interface SwSimulation {
    /**
     * enum
     * @type {string}
     * @memberof SwSimulation
     */
    result: string;
    /**
     * 
     * @type {SwSimulationDetail}
     * @memberof SwSimulation
     */
    detail?: SwSimulationDetail;
}

export function SwSimulationFromJSON(json: any): SwSimulation {
    return SwSimulationFromJSONTyped(json, false);
}

export function SwSimulationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSimulation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': json['result'],
        'detail': !exists(json, 'detail') ? undefined : SwSimulationDetailFromJSON(json['detail']),
    };
}

export function SwSimulationToJSON(value?: SwSimulation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result,
        'detail': SwSimulationDetailToJSON(value.detail),
    };
}


