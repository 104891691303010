import {IconButton} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Close} from "@material-ui/icons";
import {AnnounceCommonStoreVo} from "stores/common/AnnounceCommonStore";
import React, {FC, useEffect} from "react";
import {BgColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";

interface AnnounceProps {
    className?: string;
    announceCommon: AnnounceCommonStoreVo;
    updateAnnounceCommon: (value: boolean) => void
}

export const Announce: FC<AnnounceProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const [openState, setOpenState] = React.useState(true);

    useEffect(() => {
        setOpenState(props.announceCommon.announce.openState);
        const timeout = setTimeout(() => {
            if (props.announceCommon.announce.openState) {
                setOpenState(false);
                props.updateAnnounceCommon(false)
            }
        }, 8000);
        return () => {
            clearTimeout(timeout);
        }
    }, [props]);

    function closeAnnounce() {
        setOpenState(false);
        props.updateAnnounceCommon(false)
    }

    return (
        <div className={`${classes.root} ${matches && classes.xsRoot} ${props.className} ${openState ? "active" : ""}`}>
            <div className={`${classes.text} ${matches && classes.xsText}`}>
                {props.announceCommon.announce.text}
            </div>
            <IconButton className={`${classes.closeButton} ${matches && classes.xsCloseButton}`} onClick={closeAnnounce}>
                <Close/>
            </IconButton>
        </div>
    )
};

const useStyles = makeStyles({
    root: {
        backgroundColor: BgColorsEnum.DarkGreen,
        color: TextColorsEnum.White,
        fontWeight: 700,
        padding: "0",
        position: "fixed",
        top: -100,
        display: 'flex',
        width: "100%",
        zIndex: 110000,
        justifyContent: "space-between",
        alignItems: "center",
        opacity: 0,
        transitionProperty: "top",
        transitionDuration: ".8s",
        transitionTimingFunction: "ease-in-out",
        "&.active": {
            opacity: 1,
            top: 0,
        }
    },
    xsRoot:{

    },
    text: {
        padding: "12px 24px",
        fontSize: 16,
        width: "100%",
        textAlign: 'center'
    },
    xsText: {
        padding: "8px 16px",
    },
    closeButton: {
        color: TextColorsEnum.White,
        textAlign: 'center',
        minWidth: 42,
        marginLeft: "auto"
    },
    xsCloseButton: {
    }
});