/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSimulation,
    SwSimulationFromJSON,
    SwSimulationFromJSONTyped,
    SwSimulationToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwStudentInfo
 */
export interface SwStudentInfo {
    /**
     * 
     * @type {string}
     * @memberof SwStudentInfo
     */
    studentNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentInfo
     */
    nameKana: string;
    /**
     * 
     * @type {number}
     * @memberof SwStudentInfo
     */
    age: number;
    /**
     * 
     * @type {string}
     * @memberof SwStudentInfo
     */
    bukyokuCode: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentInfo
     */
    gakubu: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentInfo
     */
    gakka: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentInfo
     */
    nyugakuOn: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentInfo
     */
    telGakuseiNyuyroku: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentInfo
     */
    emailGakuseiNyuyroku: string;
    /**
     * 
     * @type {boolean}
     * @memberof SwStudentInfo
     */
    isGakubu: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwStudentInfo
     */
    isSinnyusei: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwStudentInfo
     */
    isRyugakusei: boolean;
    /**
     * 私費留学生(5)以外の留学生が対象外になる
     * @type {boolean}
     * @memberof SwStudentInfo
     */
    isTaisyo: boolean;
    /**
     * A様式学生 = 「学部生」かつ「留学生区分が空」
     * @type {boolean}
     * @memberof SwStudentInfo
     */
    isAyosiki: boolean;
    /**
     * A様式の入力の確定状態
     * @type {boolean}
     * @memberof SwStudentInfo
     */
    isAYosikiKakutei: boolean;
    /**
     * enum 「未入力」「確定」「未確定」「undefined」のいずれか 
     * @type {string}
     * @memberof SwStudentInfo
     */
    yosikiState?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SwStudentInfo
     */
    yosikiHasError: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwStudentInfo
     */
    yosikiJugyoryo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwStudentInfo
     */
    yosikiNyugakuryo?: boolean;
    /**
     * 
     * @type {SwSimulation}
     * @memberof SwStudentInfo
     */
    simulation?: SwSimulation;
    /**
     * 
     * @type {boolean}
     * @memberof SwStudentInfo
     */
    isSyoruiHantei: boolean;
}

export function SwStudentInfoFromJSON(json: any): SwStudentInfo {
    return SwStudentInfoFromJSONTyped(json, false);
}

export function SwStudentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwStudentInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentNumber': json['studentNumber'],
        'name': json['name'],
        'nameKana': json['nameKana'],
        'age': json['age'],
        'bukyokuCode': json['bukyokuCode'],
        'gakubu': json['gakubu'],
        'gakka': json['gakka'],
        'nyugakuOn': json['nyugakuOn'],
        'telGakuseiNyuyroku': json['telGakuseiNyuyroku'],
        'emailGakuseiNyuyroku': json['emailGakuseiNyuyroku'],
        'isGakubu': json['isGakubu'],
        'isSinnyusei': json['isSinnyusei'],
        'isRyugakusei': json['isRyugakusei'],
        'isTaisyo': json['isTaisyo'],
        'isAyosiki': json['isAyosiki'],
        'isAYosikiKakutei': json['isAYosikiKakutei'],
        'yosikiState': !exists(json, 'yosikiState') ? undefined : json['yosikiState'],
        'yosikiHasError': json['yosikiHasError'],
        'yosikiJugyoryo': !exists(json, 'yosikiJugyoryo') ? undefined : json['yosikiJugyoryo'],
        'yosikiNyugakuryo': !exists(json, 'yosikiNyugakuryo') ? undefined : json['yosikiNyugakuryo'],
        'simulation': !exists(json, 'simulation') ? undefined : SwSimulationFromJSON(json['simulation']),
        'isSyoruiHantei': json['isSyoruiHantei'],
    };
}

export function SwStudentInfoToJSON(value?: SwStudentInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentNumber': value.studentNumber,
        'name': value.name,
        'nameKana': value.nameKana,
        'age': value.age,
        'bukyokuCode': value.bukyokuCode,
        'gakubu': value.gakubu,
        'gakka': value.gakka,
        'nyugakuOn': value.nyugakuOn,
        'telGakuseiNyuyroku': value.telGakuseiNyuyroku,
        'emailGakuseiNyuyroku': value.emailGakuseiNyuyroku,
        'isGakubu': value.isGakubu,
        'isSinnyusei': value.isSinnyusei,
        'isRyugakusei': value.isRyugakusei,
        'isTaisyo': value.isTaisyo,
        'isAyosiki': value.isAyosiki,
        'isAYosikiKakutei': value.isAYosikiKakutei,
        'yosikiState': value.yosikiState,
        'yosikiHasError': value.yosikiHasError,
        'yosikiJugyoryo': value.yosikiJugyoryo,
        'yosikiNyugakuryo': value.yosikiNyugakuryo,
        'simulation': SwSimulationToJSON(value.simulation),
        'isSyoruiHantei': value.isSyoruiHantei,
    };
}


