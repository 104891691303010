/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * isSiezumi が true の場合のみ値を持つ
 * @export
 * @interface SwSienzumiErrorMessage
 */
export interface SwSienzumiErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSienzumiErrorMessage
     */
    schoolName?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSienzumiErrorMessage
     */
    startYear?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSienzumiErrorMessage
     */
    startMonth?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSienzumiErrorMessage
     */
    endYear?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSienzumiErrorMessage
     */
    endMonth?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSienzumiErrorMessage
     */
    months?: Array<SwErrorMessage>;
}

export function SwSienzumiErrorMessageFromJSON(json: any): SwSienzumiErrorMessage {
    return SwSienzumiErrorMessageFromJSONTyped(json, false);
}

export function SwSienzumiErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSienzumiErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schoolName': !exists(json, 'schoolName') ? undefined : ((json['schoolName'] as Array<any>).map(SwErrorMessageFromJSON)),
        'startYear': !exists(json, 'startYear') ? undefined : ((json['startYear'] as Array<any>).map(SwErrorMessageFromJSON)),
        'startMonth': !exists(json, 'startMonth') ? undefined : ((json['startMonth'] as Array<any>).map(SwErrorMessageFromJSON)),
        'endYear': !exists(json, 'endYear') ? undefined : ((json['endYear'] as Array<any>).map(SwErrorMessageFromJSON)),
        'endMonth': !exists(json, 'endMonth') ? undefined : ((json['endMonth'] as Array<any>).map(SwErrorMessageFromJSON)),
        'months': !exists(json, 'months') ? undefined : ((json['months'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwSienzumiErrorMessageToJSON(value?: SwSienzumiErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schoolName': value.schoolName === undefined ? undefined : ((value.schoolName as Array<any>).map(SwErrorMessageToJSON)),
        'startYear': value.startYear === undefined ? undefined : ((value.startYear as Array<any>).map(SwErrorMessageToJSON)),
        'startMonth': value.startMonth === undefined ? undefined : ((value.startMonth as Array<any>).map(SwErrorMessageToJSON)),
        'endYear': value.endYear === undefined ? undefined : ((value.endYear as Array<any>).map(SwErrorMessageToJSON)),
        'endMonth': value.endMonth === undefined ? undefined : ((value.endMonth as Array<any>).map(SwErrorMessageToJSON)),
        'months': value.months === undefined ? undefined : ((value.months as Array<any>).map(SwErrorMessageToJSON)),
    };
}


