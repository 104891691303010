import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {FamilyInfoHonninVo, FamilyInfoVo, SyunyuInfoVo} from "common/models/YosikiInfoModels";
import {YosikiMastersVo} from "common/models/YosikiMastersVo";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {FamilyInfoLabelTextEnum, FamilyInfoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoLabelTextEnum";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {RadioButton} from "components/atoms/forms/RadioButton";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {RadioButtonGroup} from "components/molecules/forms/RadioButtonGroup";
import {SwFamilyInfoErrorMessageHonnin} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";
import {
    SwMemberErrorMessagesSyugakusyaIgaiSyunyuInfo
} from "../../../../openapi/models/SwMemberErrorMessagesSyugakusyaIgaiSyunyuInfo";
import {calcTotalAmounts} from "../../../../common/functions/yosikiInfo/calcTotalAmounts";
import {SyunyuContents} from "./SyunyuContents";

interface FamilyInfoSectionSyunyuHonninProps {
    honnin: FamilyInfoHonninVo
    commonYosikiMasters: YosikiMastersVo
    honninErrorMessages: SwFamilyInfoErrorMessageHonnin;
    updateFamilyInfo: (part: Partial<FamilyInfoVo>) => void
    updateHonninErrorMessages: (part: Partial<SwFamilyInfoErrorMessageHonnin>) => void
}

export const FamilyInfoSectionSyunyuHonnin: FC<FamilyInfoSectionSyunyuHonninProps> = ({
                                                                                          honnin,
                                                                                          commonYosikiMasters,
                                                                                          honninErrorMessages,
                                                                                          updateFamilyInfo,
                                                                                          updateHonninErrorMessages,
                                                                                      }) => {

    function handleClearSyogakukinError() {
        // NOTE: 本人の奨学金エラーをクリア
        updateHonninErrorMessages({syogakukin: undefined});
    }

    function handleChangeSyunyuAriNasi(value: boolean) {
        updateFamilyInfo({
            honnin: {
                ...honnin,
                syunyuAriNasi: value
            }
        });
        updateHonninErrorMessages({
            syogakukin: undefined,
            syunyuAriNasi: undefined,
            kakuteisinkokuAriNasi: undefined,
            syunyuInfo: {
                ...honninErrorMessages.syunyuInfo,
                kakuteisinkokuField: undefined,
                gensentyosyuField: undefined,
                otherField: undefined
            }
        });
    }

    function handleChangeKakuteisinkokuAriNasi(value: boolean) {
        updateFamilyInfo({
            honnin: {
                ...honnin,
                kakuteisinkokuAriNasi: value
            }
        });
        updateHonninErrorMessages({
            kakuteisinkokuAriNasi: undefined,
            syunyuInfo: {
                ...honninErrorMessages.syunyuInfo,
                kakuteisinkokuField: undefined,
                gensentyosyuField: undefined,
            }
        });
    }

    function updateSyunyuInfo(syunyuInfo: Partial<SyunyuInfoVo>) {
        updateFamilyInfo({
            honnin: {
                ...honnin,
                syunyuInfo: {
                    ...honnin.syunyuInfo,
                    ...syunyuInfo
                }
            }
        })
    }

    function updateSyunyuInfoErrorMessages(part: Partial<SwMemberErrorMessagesSyugakusyaIgaiSyunyuInfo>) {
        updateHonninErrorMessages({
            syunyuInfo: {
                ...honninErrorMessages?.syunyuInfo,
                ...part
            }
        })
    }

    if (commonYosikiMasters === undefined) return <></>;
    if (honnin === undefined) return <></>;

    // 計算
    const totalAmounts = calcTotalAmounts(honnin.syunyuInfo, commonYosikiMasters?.syunyuKubun, honnin.kakuteisinkokuAriNasi)

    return (
        <FormTableRow
            label={FamilyInfoLabelTextEnumToNameJaAndEn(FamilyInfoLabelTextEnum.本人)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.収入または所得がありますか)}<RequiredTag
                    type={RequiredTagTypeEnum.必須}/>
                </div>
                <RadioButtonGroup
                    errorMessages={convertErrorMessageVoToArrayStr(honninErrorMessages.syunyuAriNasi)}
                    vertical={true}
                >
                    <RadioButton
                        value={""}
                        checked={honnin.syunyuAriNasi === true}
                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.あり)}
                        isError={isErrorMessage(honninErrorMessages.syunyuAriNasi)}
                        handleChange={() => {
                            handleChangeSyunyuAriNasi(true)
                        }}
                    />
                    <RadioButton
                        value={""}
                        checked={honnin.syunyuAriNasi === false}
                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.なし)}
                        isError={isErrorMessage(honninErrorMessages.syunyuAriNasi)}
                        handleChange={() => {
                            handleChangeSyunyuAriNasi(false)
                        }}
                    />
                </RadioButtonGroup>
            </div>

            {
                honnin.syunyuAriNasi ? (
                    <SyunyuContents
                        isHonnin={true}
                        syunyuInfo={honnin.syunyuInfo}
                        syunyuInfoErrorMessages={honninErrorMessages.syunyuInfo}
                        kakuteisinkokuAriNasi={honnin?.kakuteisinkokuAriNasi}
                        kakuteisinkokuAriNasiErrorMessage={honninErrorMessages.kakuteisinkokuAriNasi}
                        totalAmounts={totalAmounts}
                        updateSyunyuInfo={updateSyunyuInfo}
                        updateSyunyuInfoErrorMessages={updateSyunyuInfoErrorMessages}
                        handleChangeKakuteisinkokuAriNasi={handleChangeKakuteisinkokuAriNasi}
                        handleClearSyogakukinError={handleClearSyogakukinError}
                    />
                ) : null
            }

        </FormTableRow>
    )
};

