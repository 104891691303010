import React from 'react';

export const ModalContentRinjiSyotokuRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                昨年4月〜本年3月に退職金、保険金、 資産譲渡、山林所得等の臨時所得があった場合は、<span
                className={"underLine"}>雇用保険受給資格証</span>退(離)職証明書(様式8)<span
                className={"underLine"}>雇用保険受給資格証</span>や<span className={"underLine"}>雇用保険受給資格証</span>確定申告書(控)<span
                className={"underLine"}>雇用保険受給資格証</span>等より取得金額及び取得時期を記入すること。
            </div>

            <div className={"modalContent_item"}>
                If there was any incidental income from sources such as retirement benefits, insurance money, capital
                gains, forestry income, etc. between April of last year and March of this year, enter the amount
                acquired and the time period of their acquisition according to the <span className={"underLine"}>Certificate of Retirement (Job
                Separation) (Form 8), Tax Return (Taxpayer’s Copy)</span>, or other applicable documents.
            </div>

        </div>

    );
};
