import DialogActions from "@material-ui/core/DialogActions";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {Button} from "components/atoms/Button";
import {Modal} from "components/atoms/Modal";
import React from 'react';
import {Box} from "@material-ui/core";

interface Props {
    openState: boolean
    handleClickClose: () => void
}

export const AYosikiKakuteiErrorModal: React.FC<Props> = (props) => {

    return (

        <Modal
            openState={props.openState}
            title={"確定できませんでした。 / Can not be confirmed"}
        >

            <Box>
                エラーが残っているため確定できませんでした。<br />
                正しく入力を行い、保存後に確定をしてください。<br />
                Could not be confirmed due to errors remaining.<br />
                Please enter the correct information, and save and confirm.
            </Box>

            <DialogActions className={"modal_actions"}>
                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.閉じる)}
                    handleClick={props.handleClickClose}
                />
            </DialogActions>

        </Modal>

    );
};


