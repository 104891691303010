import {countErrorMessage} from "common/functions/countErrorMessage";
import {SwYosikiInfoErrorMessageResponse} from "openapi/models";

export interface CountYosikiErrorMessageVo {
    sinseisyaErrorCount: number
    seisekiInfoErrorCount: number
    syogakukinErrorCount: number
    familyErrorCount: number
    nyugakuryoErrorCount: number
    jugyoryoErrorCount: number
    tokubetuKojoErrorCount: number
}

// NOTE: セクションごとのエラー数をカウント
export function countYosiErrorMessage(yosikiInfoErrorMessages: SwYosikiInfoErrorMessageResponse): CountYosikiErrorMessageVo {
    return {
        sinseisyaErrorCount: countErrorMessage(yosikiInfoErrorMessages.sinseisyaInfoErrorMessage),
        seisekiInfoErrorCount: countErrorMessage(yosikiInfoErrorMessages.seisekiInfoErrorMessage),
        syogakukinErrorCount: countErrorMessage(yosikiInfoErrorMessages.syogakukinInfoErrorMessage.syogakukins),
        familyErrorCount: countErrorMessage(yosikiInfoErrorMessages.familyInfoErrorMessage),
        nyugakuryoErrorCount: countErrorMessage(yosikiInfoErrorMessages.nyugakuryoInfoErrorMessage),
        jugyoryoErrorCount: countErrorMessage(yosikiInfoErrorMessages.jugyoryoInfoErrorMessage),
        tokubetuKojoErrorCount: countErrorMessage(yosikiInfoErrorMessages.tokubetuKojoInfoErrorMessage),
    }
}