import {useMediaQuery} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import React, {FC} from "react";
import {BgColorsEnum} from "common/enums_value/ColorsEnum";

interface LoginContainerProps {
    className?: string;
}

export const LoginContainer: FC<LoginContainerProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div className={`${classes.root} ${matches && classes.xsRoot} ${props.className}`}>
            {props.children}
        </div>
    )
};


const useStyles = makeStyles({
    root: {
        width: 504,
        minWidth: 408,
        backgroundColor: BgColorsEnum.White,
        padding: 32,
        boxSizing: "border-box"
    },
    xsRoot: {
        minWidth: "inherit",
        width: "100%",
        padding: 16,
    },
});
