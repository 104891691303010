/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwFamilyInfoHonnin,
    SwFamilyInfoHonninFromJSON,
    SwFamilyInfoHonninFromJSONTyped,
    SwFamilyInfoHonninToJSON,
    SwMember,
    SwMemberFromJSON,
    SwMemberFromJSONTyped,
    SwMemberToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwFamilyInfo
 */
export interface SwFamilyInfo {
    /**
     * 
     * @type {SwFamilyInfoHonnin}
     * @memberof SwFamilyInfo
     */
    honnin: SwFamilyInfoHonnin;
    /**
     * 
     * @type {Array<SwMember>}
     * @memberof SwFamilyInfo
     */
    members: Array<SwMember>;
}

export function SwFamilyInfoFromJSON(json: any): SwFamilyInfo {
    return SwFamilyInfoFromJSONTyped(json, false);
}

export function SwFamilyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwFamilyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'honnin': SwFamilyInfoHonninFromJSON(json['honnin']),
        'members': ((json['members'] as Array<any>).map(SwMemberFromJSON)),
    };
}

export function SwFamilyInfoToJSON(value?: SwFamilyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'honnin': SwFamilyInfoHonninToJSON(value.honnin),
        'members': ((value.members as Array<any>).map(SwMemberToJSON)),
    };
}


