/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
} from '../models';

export interface PostOfficerConvertJukenNumberByCsvRequest {
    csvfile?: Blob;
}

/**
 * no description
 */
export class OfficerConvertJukenNumberByCsvApi extends runtime.BaseAPI {

    /**
     * 受験番号変換(csv)
     */
    async postOfficerConvertJukenNumberByCsvRaw(requestParameters: PostOfficerConvertJukenNumberByCsvRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.csvfile !== undefined) {
            formParams.append('csvfile', requestParameters.csvfile as any);
        }

        const response = await this.request({
            path: `/api/officer/convert_juken_number/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     * 受験番号変換(csv)
     */
    async postOfficerConvertJukenNumberByCsv(requestParameters: PostOfficerConvertJukenNumberByCsvRequest): Promise<InlineResponse2002> {
        const response = await this.postOfficerConvertJukenNumberByCsvRaw(requestParameters);
        return await response.value();
    }

}
