import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import React, {FC} from 'react';
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";
import {ErrorMessages} from "../../atoms/ErrorMessages";

interface FieldWithLabelProps {
    className?: string;
    width?: string;
    minWidth?: string;
    labelWidth?: string;
    horizon?: boolean
    label?: string;
    labelNode?: React.ReactNode;
    errorMessages?: Array<string>;
    helperTexts?: Array<string>;
    requiredType?: RequiredTagTypeEnum
}

// 入力項目を内包する汎用的なコンポーネント (縦並び)
export const FieldWithLabel: FC<FieldWithLabelProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div
            className={`${classes.root} ${matches ? classes.xsRoot : ""} ${props.className}`}
            style={{width: props.width, minWidth: props.minWidth}}
        >

            <div className={`${props.horizon && classes.horizon}`}>
                <div className={"head"}>
                    <div className={"label"} style={{minWidth: props.labelWidth}}>
                        {props.label ? props.label : null}
                        {props.labelNode ? props.labelNode : null}

                        {
                            props.requiredType === RequiredTagTypeEnum.必須 ?
                                <RequiredTag
                                    className={"head_tag"}
                                    type={props.requiredType}
                                />
                                : null
                        }
                        {
                            props.requiredType === RequiredTagTypeEnum.任意 ?
                                <RequiredTag
                                    className={"head_tag"}
                                    type={props.requiredType}
                                />
                                : null
                        }
                        </div>

                            </div>

                            <div className={"field"}>
                        {props.children}
                    </div>

                </div>

                {
                    props.errorMessages && props.errorMessages.length > 0 ?
                        <ErrorMessages
                            className={classes.errorMessages}
                            errorMessages={props.errorMessages}
                        />
                        : null
                }

                {
                    props.helperTexts && props.helperTexts.length > 0 ?
                        <Typography className={classes.helperTexts}>
                            {props.helperTexts}
                        </Typography>
                        : null
                }

            </div>
            )
            };

            FieldWithLabel.defaultProps = {
            width: "100%",
            minWidth: "inhreit",
            errorMessages: [],
        };

            const useStyles = makeStyles({
            root: {
            maxWidth: "100%!important",
            "& .head": {
            display: "flex",
            alignItems: "baseline",
            minHeight: 24,
            "& .head_tag": {
            marginLeft: 4,
            position: "relative",
            top: -2
        },
            "& .label": {
            fontSize: 12,
            fontWeight: 700,
            padding: "6px 0"
        },
        },
            "& .field": {
            flexGrow: 1
        }
        },
            xsRoot: {
            "& .field": {
            display: "block",
            "& > *:not(:first-child)": {
            marginLeft: 0,
            marginTop: 8
        }
        }
        },
            errorMessages: {
            marginTop: 4,
        },
            helperTexts: {
            marginTop: 4,
        },
            horizon: {
            display: "flex",
            alignItems: "center",
            "& .head": {
            marginRight: 4,
            minHeight: "inherit",
            "& .label": {
            padding: 0
        },
        }
        }

        });
