import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {NumberField} from "components/atoms/forms/NumberField";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import React, {FC} from 'react';
import {TextColorsEnum} from "common/enums_value/ColorsEnum";

interface YearMonthDayProps {
    className?: string;
    width?: string;
    helperText?: string;
    errorMessages?: Array<string>;
    isError?: boolean;
    isErrorYear?: boolean;
    isErrorMonth?: boolean;
    isErrorDay?: boolean;

    valueYear?: string;
    valueMonth?: string;
    valueDay?: string;
    handleBlurYear?: (value: string) => void;
    handleBlurMonth?: (value: string) => void;
    handleBlurDay?: (value: string) => void;
}

// 一覧などの検索フォームのラベルのスタイル用コンポーネント
export const YearMonthDay: FC<YearMonthDayProps> = (props) => {

    const classes = useStyles();

    function handleBlurYear(newValue: string) {
        props.handleBlurYear && props.handleBlurYear(newValue)
    }

    function handleBlurMonth(newValue: string) {
        props.handleBlurMonth && props.handleBlurMonth(newValue)
    }

    function handleBlurDay(newValue: string) {
        props.handleBlurDay && props.handleBlurDay(newValue)
    }

    return (

        <>

            <div
                className={`${classes.root} ${props.className}`}
            >

                <NumberField
                    value={props.valueYear}
                    isError={props.isError || props.isErrorYear}
                    width={"96px"}
                    prefixText={"西暦"}
                    suffixText={"年"}
                    handleBlur={handleBlurYear}
                    maxLength={4}
                />

                <NumberField
                    value={props.valueMonth}
                    isError={props.isError || props.isErrorMonth}
                    width={"56px"}
                    suffixText={"月"}
                    handleBlur={handleBlurMonth}
                    maxLength={2}
                />

                <NumberField
                    value={props.valueDay}
                    isError={props.isError || props.isErrorDay}
                    width={"56px"}
                    suffixText={"日"}
                    handleBlur={handleBlurDay}
                    maxLength={2}
                />

            </div>

            {
                props.errorMessages &&
                <ErrorMessages
                    className={classes.errorMessages}
                    errorMessages={props.errorMessages}
                />
            }

            {
                props.helperText &&
                <Typography className={classes.helperText}>
                    {props.helperText}
                </Typography>
            }

        </>
    )
};

const useStyles = makeStyles({
    root: {
        maxWidth: "100%!important",
        display: "flex",
        boxSizing: 'border-box',
        "& > *": {
            marginRight: 8
        }
    },
    errorMessages: {
        marginTop: 4,
    },
    helperText: {
        marginTop: 4,
        color: TextColorsEnum.Green,
        fontSize: 12
    }
});