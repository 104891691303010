/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    SwOfficerLoginRequest,
    SwOfficerLoginRequestFromJSON,
    SwOfficerLoginRequestToJSON,
} from '../models';

export interface PostOfficerLoginRequest {
    swOfficerLoginRequest: SwOfficerLoginRequest;
}

/**
 * no description
 */
export class OfficerLoginApi extends runtime.BaseAPI {

    /**
     * 職員のログイン情報を送信
     */
    async postOfficerLoginRaw(requestParameters: PostOfficerLoginRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.swOfficerLoginRequest === null || requestParameters.swOfficerLoginRequest === undefined) {
            throw new runtime.RequiredError('swOfficerLoginRequest','Required parameter requestParameters.swOfficerLoginRequest was null or undefined when calling postOfficerLogin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/officer/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwOfficerLoginRequestToJSON(requestParameters.swOfficerLoginRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * 職員のログイン情報を送信
     */
    async postOfficerLogin(requestParameters: PostOfficerLoginRequest): Promise<InlineResponse200> {
        const response = await this.postOfficerLoginRaw(requestParameters);
        return await response.value();
    }

}
