import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {NyugakuryoInfoVo} from "common/models/YosikiInfoModels";
import {NyugakuryoInfoLabelTextEnum, NyugakuryoInfoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/NyugakuryoInfoLabelTextEnum";
import {NyugakuryoInfoTextEnum, NyugakuryoInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/NyugakuryoInfoTextEnum";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {Title04} from "components/atoms/titles/Title04";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {FC} from "react";

interface NyugakuryoInfoInfoDetailSectionProps {
    className?: string
    nyugakuryoInfo: NyugakuryoInfoVo;
}

export const _NyugakuryoInfoDetailSection: FC<NyugakuryoInfoInfoDetailSectionProps> = (props) => {

    const classes = useStyles();

    const nyugakuryoInfo: NyugakuryoInfoVo = props.nyugakuryoInfo;

    return (
        <div id={SECTION_ID_NAME.nyugakuryoInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.入学料免除徴収猶予申請理由)}
                className={"section_title"}
            />

            <FormTable
                className={"section_table"}
            >
                <FormTableRow
                    label={NyugakuryoInfoLabelTextEnumToNameJaAndEn(NyugakuryoInfoLabelTextEnum.免除または徴収猶予の理由)}
                >
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {NyugakuryoInfoTextEnumToNameJaAndEn(NyugakuryoInfoTextEnum.どういう原因で)}
                        </div>
                        <div className={"preWrap"}>
                            {nyugakuryoInfo.riyu}
                        </div>
                    </div>
                </FormTableRow>
            </FormTable>

        </div>

    )
};

export const NyugakuryoInfoDetailSection = React.memo<NyugakuryoInfoInfoDetailSectionProps>(_NyugakuryoInfoDetailSection, (prev, next) => {
    return prev.className === next.className
        && prev.nyugakuryoInfo === next.nyugakuryoInfo
});

const useStyles = makeStyles({
        root: {},
    }
);

