import {useMediaQuery} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import useTheme from "@material-ui/core/styles/useTheme";
import {AuthTypeEnum} from "common/enums/AuthTypeEnum";
import {commonSyoruiStore, CommonSyoruiStoreVo} from "stores/common/CommonSyoruiStore";
import {osiraseStore, OsiraseStoreVo} from "stores/common/OsiraseStore";
import {Container} from "components/layouts/Container";
import {Osirase} from "components/organisms/common/Osirase";
import {OsiraseDetailModal} from "components/organisms/common/OsiraseDetailModal";
import {SyoruiModal} from "components/organisms/common/SyoruiModal";
import {StudentLogin} from "components/organisms/student/StudentLogin";
import {SyoruiSection} from "components/organisms/summary/SyoruiSection";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import {loginStore, LoginVo} from "pages/student/login/stores/LoginStore";
import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from "react-router";

const _Login: React.FC<RouteComponentProps> = () => {

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const [osirase, updateOsirase] = React.useState<OsiraseStoreVo>(osiraseStore.currentState);
    const [syorui, updateSyorui] = React.useState<CommonSyoruiStoreVo>(commonSyoruiStore.currentState);
    const [login, updateLoginStore] = React.useState<LoginVo>(loginStore.currentState);

    useEffect(() => {
        return loginStore.listen(updateLoginStore)
    }, []);

    React.useEffect(() => {
        osiraseStore.getOsirases(AuthTypeEnum.student).then();
        return osiraseStore.listen(updateOsirase);
    }, []);

    React.useEffect(() => {
        return commonSyoruiStore.listen(updateSyorui);
    }, []);

    function handleClickOsirase(id: string) {
        osiraseStore.getOsirase(id, AuthTypeEnum.student).then()
    }

    function handleClickDetailModalClose() {
        osiraseStore.updateOsiraseDetailModalState(false)
    }

    function handleClickSyoruiList() {
        commonSyoruiStore.getSyoruis(AuthTypeEnum.student).then();
    }

    function handleClickSyoruiModalClose() {
        commonSyoruiStore.updateSyoruiModalState(false)
    }

    // login
    function handleClickLoginButton() {
        loginStore.postLogin().then()
    }

    function handleBlurAccount(v: string) {
        loginStore.updateStudentNumber(v);
        loginStore.updateErrorMessages(undefined)
    }

    function handleBlurPassword(v: string) {
        loginStore.updatePassword(v);
        loginStore.updateErrorMessages(undefined)
    }

    return (

        <>

            <OsiraseDetailModal
                openState={osirase.osiraseDetailModalState}
                osiraseDetail={osirase.osiraseDetail}
                handleClickDetailModalClose={handleClickDetailModalClose}
            />

            <SyoruiModal
                openState={syorui.syoruiModalState}
                syoruis={syorui.syoruis}
                handleClickSyoruiModalClose={handleClickSyoruiModalClose}
            />

            <HeaderStudent signedIn={false}/>

            <Container
                maxWidth={'960px'}
                bgColor={"gray"}
                className={`${classes.container} ${matches && classes.xsContainer}`}
            >

                <div className={"container_inner"}>

                    <div className={`${classes.introduce} ${matches && classes.xsIntroduce}`}>

                        <div className={"introduce_title"}>
                            <div className={"introduce_title_ja"}>入学料・授業料免除等申請システム</div>
                            <div className={"introduce_title_en"}>Enrollment Fee / Tuition Waiver, etc. Application System</div>
                        </div>

                        <div className={"introduce_body"}>
                            <div className={"introduce_body_paragraph"}>新規アカウント登録後、ログインして入力してください。<br />
                                アカウントデータは期ごとにリセットされるので、
                                毎期ごとに新規アカウント登録を行なってください。
                            </div>
                            <div className={"introduce_body_paragraph"}>
                                After registering your account, log in and enter your information.<br />
                                Account data will be reset each semester, so you will need to register a new account for
                                each semester.
                            </div>

                        </div>

                        <Osirase
                            className={'mt16'}
                            osirase={osirase.osirases}
                            handleClickOsirase={handleClickOsirase}
                        />

                        <SyoruiSection
                            className={'mt16'}
                            handleClickSyoruiList={handleClickSyoruiList}
                        />

                    </div>

                    <StudentLogin
                        className={'login'}
                        login={login}
                        handleClickLoginButton={handleClickLoginButton}
                        handleBlurAccount={handleBlurAccount}
                        handleBlurPassword={handleBlurPassword}
                    />

                </div>

            </Container>

        </>

    );
};

export const Login = withRouter(_Login);

const useStyles = makeStyles({
    container: {
        "& .container_inner": {
            display: "flex",
            alignItems: "flex-start"
        }
    },
    xsContainer: {
        "& .container_inner": {
            display: "block",
            "& .login": {
                marginTop: 32,
            }
        }
    },
    introduce: {
        marginRight: 32,
        "& .introduce_title": {
            fontSize: 28,
            fontWeight: 700,
            "& .introduce_title_ja": {
                lineHeight: 1.2,
                marginBottom: 4
            },
            "& .introduce_title_en": {
                fontSize: 16,
            }
        },
        "& .introduce_body": {
            marginTop: 16,
            "& > .introduce_body_paragraph": {
                marginTop: 8,
                "&:first-child": {
                    marginTop: 0
                }
            }
        },
        "& .introduce_pdf": {
            marginTop: 16,
            "& > .introduce_pdf_title": {},
            "& .introduce_pdf_title": {
                fontSize: 16,
                fontWeight: 700,
            },
            "& > .introduce_pdf_paragraph": {
                marginTop: 8,
            },
            "& .introduce_pdf_list": {
                marginTop: 8,
                "& .introduce_pdf_list_item": {
                    marginTop: 4,
                    "&:first-child": {
                        marginTop: 0
                    },
                },
            }
        }
    },
    xsIntroduce: {
        marginRight: 0,
        "& .introduce_title": {
            fontSize: 22,
            "& .introduce_title_en": {
                fontSize: 16,
            }
        },
    },
});
