import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {scrollToErrorBasicInfoModal} from "common/functions/scrollToErrorBasicInfoModal";
import {
    INIT_BASIC_INFO_ERROR_MESSAGE,
    YosikiBasicInfoFromVo,
    YosikiBasicInfoStoreVo
} from "common/models/yosiki/YosikiBasicInfoStoreVo";
import {PostStudentBasicInfoRequest, StudentBasicInfoApi} from "openapi/apis";
import {SwBasicInfoErrorMessageResponse, SwBasicInfoResponse} from "openapi/models";
import {accountInfoStore} from "stores/student/AccountInfoStore";
import {yosikiInfoModalStore} from "pages/student/yosiki/stores/YosikiInfoModalStore";
import {yosikiInfoStore} from "pages/student/yosiki/stores/YosikiInfoStore";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";

class YosikiBasicInfoStore extends Store<YosikiBasicInfoStoreVo> {

    constructor() {
        super({
            basicInfoForm: undefined,
            basicInfoErrorMessage: INIT_BASIC_INFO_ERROR_MESSAGE
        })
    }

    updateYosikiBasicInfoForm(part: YosikiBasicInfoFromVo | undefined) {
        if (part === undefined) {
            this.dispatch(() => ({basicInfoForm: part}));
        } else {
            let newForm: YosikiBasicInfoFromVo = {
                ...this.currentState.basicInfoForm,
                ...part
            };
            this.dispatch(() => ({basicInfoForm: newForm}));
        }
    }

    updateYosikiBasicInfoErrorMessage(part: Partial<SwBasicInfoErrorMessageResponse> | undefined) {
        if (part === undefined) return;
        let newBasicInfoErrorMessage: SwBasicInfoErrorMessageResponse = {
            ...this.currentState.basicInfoErrorMessage,
            ...part
        };
        this.dispatch(() => ({basicInfoErrorMessage: newBasicInfoErrorMessage}));
    }

    clearBasicInfoStore(){
        this.updateYosikiBasicInfoForm(undefined);
        this.updateYosikiBasicInfoErrorMessage(INIT_BASIC_INFO_ERROR_MESSAGE);
    }

    private api = new StudentBasicInfoApi(apiConfiguration);

    async getBasicInfo() {
        this.clearBasicInfoStore();
        try {
            const response: SwBasicInfoResponse = await this.api.getStudentBasicInfo();
            this.updateYosikiBasicInfoForm(response);
        } catch (res) {
            handleStudentError(res)
        }
    }

    async postFirstBasicInfo() {
        if (this.currentState.basicInfoForm === undefined) return;
        const postBasicInfoRequest: PostStudentBasicInfoRequest = {
            swBasicInfoRequest: {
                sinseiType: this.currentState.basicInfoForm.sinseiType,
                tokubetuKojo: this.currentState.basicInfoForm.tokubetuKojo,
            }
        };

        try {
            const res = await this.api.postStudentBasicInfo(postBasicInfoRequest);
            if (!res.error) {
                yosikiInfoModalStore.updateBasicInfoModalOpenState(false);
                accountInfoStore.getStudentInfo().then();
                yosikiInfoStore.getYosikiInfo().then();
            } else {
                this.updateYosikiBasicInfoErrorMessage(res.error);
                scrollToErrorBasicInfoModal();
            }
        } catch (res) {
            handleStudentError(res)
        }
    }

    async postBasicInfo() {
        if (this.currentState.basicInfoForm === undefined) return;
        const postBasicInfoRequest: PostStudentBasicInfoRequest = {
            swBasicInfoRequest: {
                sinseiType: this.currentState.basicInfoForm.sinseiType,
                tokubetuKojo: this.currentState.basicInfoForm.tokubetuKojo,
            }
        };

        try {
            const res = await this.api.postStudentBasicInfo(postBasicInfoRequest);
            if (!res.error) {
                yosikiInfoModalStore.updateBasicInfoModalOpenState(false);
                accountInfoStore.getStudentInfo().then();
                yosikiInfoStore.getYosikiInfoPolicyAndBasicInfo().then();
            } else {
                this.updateYosikiBasicInfoErrorMessage(res.error);
                scrollToErrorBasicInfoModal();
            }
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const yosikiBasicInfoStore = new YosikiBasicInfoStore();
