/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwBekkyoErrorMessage
 */
export interface SwBekkyoErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwBekkyoErrorMessage
     */
    riyu?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwBekkyoErrorMessage
     */
    amountPerMonth?: Array<SwErrorMessage>;
}

export function SwBekkyoErrorMessageFromJSON(json: any): SwBekkyoErrorMessage {
    return SwBekkyoErrorMessageFromJSONTyped(json, false);
}

export function SwBekkyoErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwBekkyoErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'riyu': !exists(json, 'riyu') ? undefined : ((json['riyu'] as Array<any>).map(SwErrorMessageFromJSON)),
        'amountPerMonth': !exists(json, 'amountPerMonth') ? undefined : ((json['amountPerMonth'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwBekkyoErrorMessageToJSON(value?: SwBekkyoErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'riyu': value.riyu === undefined ? undefined : ((value.riyu as Array<any>).map(SwErrorMessageToJSON)),
        'amountPerMonth': value.amountPerMonth === undefined ? undefined : ((value.amountPerMonth as Array<any>).map(SwErrorMessageToJSON)),
    };
}


