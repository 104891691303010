import {makeStyles} from '@material-ui/core/styles';
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {LoginLabelTextEnum, LoginLabelTextEnumToNameJaAndEn} from "common/enums_text/LoginLabelTextEnum";
import {Button} from "components/atoms/Button";
import {TextField} from "components/atoms/forms/TextField";
import {Container} from "components/layouts/Container";
import {LoginContainer} from "components/layouts/LoginContainer";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import {
    sendResetPasswordLinkStore,
    SendResetPasswordLinkStoreVo
} from "pages/student/sendResetPasswordLink/stores/SendResetPasswordLinkStore";
import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from "react-router";

const _SendResetPasswordLink: React.FC<RouteComponentProps> = () => {

    const classes = useStyles();

    const [sendResetPasswordLink, updateSendResetPasswordLink] = React.useState<SendResetPasswordLinkStoreVo>(sendResetPasswordLinkStore.currentState);

    useEffect(() => {
        sendResetPasswordLinkStore.updateErrorMessages({studentNumber: undefined});
        return sendResetPasswordLinkStore.listen(updateSendResetPasswordLink)
    }, []);

    function handleClickLoginButton() {
        sendResetPasswordLinkStore.postSendResetPasswordLink().then()
    }

    function handleBlurAccount(v: string) {
        sendResetPasswordLinkStore.updateForm({studentNumber: v});
        sendResetPasswordLinkStore.updateErrorMessages({studentNumber: undefined})
    }

    return (

        <>

            <HeaderStudent signedIn={false}/>

            <Container
                maxWidth={'464px'}
                bgColor={"gray"}
                className={classes.container}
            >

                <div className={"container_inner"}>

                    <LoginContainer
                        className={classes.root}
                    >
                        <div className={"login_title"}>{LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード再設定)}</div>
                        <div>
                            学籍番号または受験番号を入力してください。<br/>
                            学内のメールアドレス宛にパスワード再設定画面へのリンクが記載されたメールを送信します。
                        </div>
                        <div className={'mt16'}>
                            Enter your student number or examinee's number to register.<br/>
                            An e-mail containing a link to the Reset Password screen will be sent to your e-mail address.
                        </div>
                        <div className={"login_form"}>

                            <FieldWithLabel
                                label={"学籍番号・受験番号 / Student Number・examinee's number"}
                                errorMessages={convertErrorMessageVoToArrayStr(sendResetPasswordLink.errorMessages?.studentNumber)}
                            >
                                <TextField
                                    value={sendResetPasswordLink.form.studentNumber}
                                    handleBlur={handleBlurAccount}
                                    isError={isErrorMessage(sendResetPasswordLink.errorMessages?.studentNumber)}
                                />
                            </FieldWithLabel>

                            <div>
                                <Button
                                    type={"primary"}
                                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                                    width={"100%"}
                                    handleClick={handleClickLoginButton}
                                />
                            </div>

                        </div>
                    </LoginContainer>

                </div>

            </Container>

        </>

    );
};

export const SendResetPasswordLink = withRouter(_SendResetPasswordLink);

const useStyles = makeStyles({
    container: {
        "& .container_inner": {
            display: "flex"
        }
    },
    root: {
        "& .login_title": {
            textAlign: "center",
            fontSize: 18,
            fontWeight: 700,
            marginBottom: 16,
        },
        "& .login_form": {
            marginTop: 16,
            "& > *": {
                marginTop: 16,
                "&:first-child": {
                    marginTop: 0
                }
            },
            "& .password": {
                textAlign: "center"
            }
        }
    },
});
