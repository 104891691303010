import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {MemberSyugakusyaIgaiVo, MemberVo} from "common/models/YosikiInfoModels";
import {YosikiMastersVo} from "common/models/YosikiMastersVo";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {FC} from "react";
import {SyunyuContentsDetail} from "./SyunyuContentsDetail";
import {calcTotalAmounts} from "../../../../common/functions/yosikiInfo/calcTotalAmounts";

interface FamilyInfoSectionSyunyuDetailProps {
    // memberIndex: number // 就学者の就学者以外も含めたindex
    member: MemberVo
    // members: Array<MemberVo>
    commonYosikiMasters?: YosikiMastersVo
}

export const FamilyInfoSectionSyunyuDetail: FC<FamilyInfoSectionSyunyuDetailProps> = ({
                                                                                          // memberIndex,
                                                                                          member,
                                                                                          // members,
                                                                                          commonYosikiMasters
                                                                                      }) => {

    const syugakusyaIgai: MemberSyugakusyaIgaiVo | undefined = member.syugakusyaIgai;

    if (syugakusyaIgai === undefined) {
        return <></>
    }

    // 計算
    const totalAmounts = calcTotalAmounts(syugakusyaIgai?.syunyuInfo, commonYosikiMasters?.syunyuKubun, syugakusyaIgai?.kakuteisinkokuAriNasi)

    return (
        <FormTableRow
            label={convertZokugaraForStr(member.zokugara, member.zokugaraOther)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.収入または所得がありますか)}
                </div>
                {
                    syugakusyaIgai.syunyuAriNasi === true ? CommonTextEnumToNameJaAndEn(CommonTextEnum.あり) :
                        syugakusyaIgai.syunyuAriNasi === false ? CommonTextEnumToNameJaAndEn(CommonTextEnum.なし) :
                            ""
                }
            </div>

            {
                syugakusyaIgai.syunyuAriNasi ? (
                    <SyunyuContentsDetail
                        syunyuInfo={syugakusyaIgai.syunyuInfo}
                        kakuteisinkokuAriNasi={syugakusyaIgai?.kakuteisinkokuAriNasi}
                        totalAmounts={totalAmounts}
                    />
                ) : null
            }

        </FormTableRow>
    )
};

