/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwOfficerSyoruisSyoruis,
    SwOfficerSyoruisSyoruisFromJSON,
    SwOfficerSyoruisSyoruisFromJSONTyped,
    SwOfficerSyoruisSyoruisToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerSyoruis
 */
export interface SwOfficerSyoruis {
    /**
     * 
     * @type {SwOfficerSyoruisSyoruis}
     * @memberof SwOfficerSyoruis
     */
    syoruis?: SwOfficerSyoruisSyoruis;
}

export function SwOfficerSyoruisFromJSON(json: any): SwOfficerSyoruis {
    return SwOfficerSyoruisFromJSONTyped(json, false);
}

export function SwOfficerSyoruisFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerSyoruis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syoruis': !exists(json, 'syoruis') ? undefined : SwOfficerSyoruisSyoruisFromJSON(json['syoruis']),
    };
}

export function SwOfficerSyoruisToJSON(value?: SwOfficerSyoruis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'syoruis': SwOfficerSyoruisSyoruisToJSON(value.syoruis),
    };
}


