import DialogActions from "@material-ui/core/DialogActions";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {Button} from "components/atoms/Button";
import {Modal} from "components/atoms/Modal";
import React from 'react';
import {Box} from "@material-ui/core";

interface Props {
    openState: boolean
    handleClickClose: () => void
    handleClickKakuteiKaijo: () => void
}

export const AYosikiKakuteiKaijoModal: React.FC<Props> = (props) => {

    return (

        <Modal
            openState={props.openState}
            title={"確定解除 / Cancel confirmation"}
        >

            <Box>
                確定を解除しますか？<br />
                Are you sure to cancel confirmation?
            </Box>

            <DialogActions className={"modal_actions"}>
                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.閉じる)}
                    handleClick={props.handleClickClose}
                />
                <Button
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定解除)}
                    handleClick={props.handleClickKakuteiKaijo}
                />
            </DialogActions>

        </Modal>

    );
};


