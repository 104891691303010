// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum MosikomiTypeEnum {
    予約 = "E予約",
    在学 = "E在学",
}

export function mosikomiTypeEnumToName(e: MosikomiTypeEnum): { ja: string, en: string } {
    switch (e) {
        case MosikomiTypeEnum.予約: return { ja: "予約採用の申し込みを行った者", en: "Students who have applied for scheduled acceptance" };
        case MosikomiTypeEnum.在学: return { ja: "在学採用の申し込みを行った者", en: "Students who have applied for acceptance while enrolled in school" };
    }
}