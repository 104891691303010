/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwMemberErrorMessagesSyugakusya,
    SwMemberErrorMessagesSyugakusyaFromJSON,
    SwMemberErrorMessagesSyugakusyaFromJSONTyped,
    SwMemberErrorMessagesSyugakusyaToJSON,
    SwMemberErrorMessagesSyugakusyaIgai,
    SwMemberErrorMessagesSyugakusyaIgaiFromJSON,
    SwMemberErrorMessagesSyugakusyaIgaiFromJSONTyped,
    SwMemberErrorMessagesSyugakusyaIgaiToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwMemberErrorMessages
 */
export interface SwMemberErrorMessages {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessages
     */
    zokugara?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessages
     */
    zokugaraOther?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessages
     */
    isSyugakusya?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessages
     */
    name?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessages
     */
    age?: Array<SwErrorMessage>;
    /**
     * 
     * @type {SwMemberErrorMessagesSyugakusya}
     * @memberof SwMemberErrorMessages
     */
    syugakusya?: SwMemberErrorMessagesSyugakusya;
    /**
     * 
     * @type {SwMemberErrorMessagesSyugakusyaIgai}
     * @memberof SwMemberErrorMessages
     */
    syugakusyaIgai?: SwMemberErrorMessagesSyugakusyaIgai;
}

export function SwMemberErrorMessagesFromJSON(json: any): SwMemberErrorMessages {
    return SwMemberErrorMessagesFromJSONTyped(json, false);
}

export function SwMemberErrorMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwMemberErrorMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zokugara': !exists(json, 'zokugara') ? undefined : ((json['zokugara'] as Array<any>).map(SwErrorMessageFromJSON)),
        'zokugaraOther': !exists(json, 'zokugaraOther') ? undefined : ((json['zokugaraOther'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isSyugakusya': !exists(json, 'isSyugakusya') ? undefined : ((json['isSyugakusya'] as Array<any>).map(SwErrorMessageFromJSON)),
        'name': !exists(json, 'name') ? undefined : ((json['name'] as Array<any>).map(SwErrorMessageFromJSON)),
        'age': !exists(json, 'age') ? undefined : ((json['age'] as Array<any>).map(SwErrorMessageFromJSON)),
        'syugakusya': !exists(json, 'syugakusya') ? undefined : SwMemberErrorMessagesSyugakusyaFromJSON(json['syugakusya']),
        'syugakusyaIgai': !exists(json, 'syugakusyaIgai') ? undefined : SwMemberErrorMessagesSyugakusyaIgaiFromJSON(json['syugakusyaIgai']),
    };
}

export function SwMemberErrorMessagesToJSON(value?: SwMemberErrorMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zokugara': value.zokugara === undefined ? undefined : ((value.zokugara as Array<any>).map(SwErrorMessageToJSON)),
        'zokugaraOther': value.zokugaraOther === undefined ? undefined : ((value.zokugaraOther as Array<any>).map(SwErrorMessageToJSON)),
        'isSyugakusya': value.isSyugakusya === undefined ? undefined : ((value.isSyugakusya as Array<any>).map(SwErrorMessageToJSON)),
        'name': value.name === undefined ? undefined : ((value.name as Array<any>).map(SwErrorMessageToJSON)),
        'age': value.age === undefined ? undefined : ((value.age as Array<any>).map(SwErrorMessageToJSON)),
        'syugakusya': SwMemberErrorMessagesSyugakusyaToJSON(value.syugakusya),
        'syugakusyaIgai': SwMemberErrorMessagesSyugakusyaIgaiToJSON(value.syugakusyaIgai),
    };
}


