import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {KikanTypeEnum, kikanTypeEnumToName} from "common/constant/KIKAN";
import {convertKikansStudentIdKikansKikanToStr} from "common/functions/converter/convertKikansStudentIdKikansKikanToStr";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {OfficerKikansStudentIdKikansVo} from "common/models/KikansModels";
import {SummaryTextEnum, SummaryTextEnumToName} from "common/enums_text/SummaryTextEnum";
import {Title02} from "components/atoms/titles/Title02";
import React, {FC} from "react";
import {BorderColorsEnum} from "common/enums_value/ColorsEnum";

interface SummaryKikanInfoSectionProps {
    className?: string
    kikans: OfficerKikansStudentIdKikansVo | undefined
}

export const SummaryKikanInfoSection: FC<SummaryKikanInfoSectionProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const kikans = props.kikans;

    return (
        <div
            className={`${classes.root} ${xs ? classes.xsRoot : undefined} ${props.className}`}
        >

            <Title02
                title={convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.申請手順))}
            />

            {
                kikans ?
                    <div className={"tejun"}>
                        <div className={"tejunRow"}>
                            <div className={"tejunRow_title"}>
                                {convertEnumNameToStr(kikanTypeEnumToName(KikanTypeEnum.入力期間))}<br/>
                                <span className={"tejunRow_title_date"}>{convertKikansStudentIdKikansKikanToStr(kikans.nyuryokuKikan)}</span>
                            </div>
                            <div>
                                入力期間内に入力を行なってください。<br/>
                                期間内に入力を行わない場合は、申請を取り消す場合があります。<br/>
                                入力が終わりましたら保存後、確定処理を行なってください。<br/>
                                Enter your application information during the data entry period.<br />
                                If you do not enter your information during this period, your application may be cancelled.<br />
                                After you have finished entering your information, save it and proceed with confirmation.
                            </div>
                        </div>
                        <div className={"tejunRow"}>
                            <div className={"tejunRow_title"}>
                                {convertEnumNameToStr(kikanTypeEnumToName(KikanTypeEnum.申請期間))}<br/>
                                <span className={"tejunRow_title_date"}>{convertKikansStudentIdKikansKikanToStr(kikans.sinseiKikan)}</span>
                            </div>
                            <div>
                                申請が必要な書類のPDFを印刷して必要記入の上、担当窓口へ提出してください。<br/>
                                職員が申請時に申請内容を確認し、内容に問題がなければ申請が完了します。<br/>
                                申請内容に不備がある場合、訂正期間に修正の上、再度申請を行なってください。<br />
                                After printing the PDF files of the documents requiring application, complete them as necessary, and then submit them to the appropriate administrative office.<br />
                                Staff members will confirm the content of your application and if there are no issues with the content, your application will be completed.<br />
                                If there are any issues with your application content, please make corrections during the correction period and submit your application again.
                            </div>
                        </div>
                        <div className={"tejunRow"}>
                            <div className={"tejunRow_title"}>
                                {convertEnumNameToStr(kikanTypeEnumToName(KikanTypeEnum.訂正期間))}<br/>
                                <span className={"tejunRow_title_date"}>{convertKikansStudentIdKikansKikanToStr(kikans.teiseiKikan)}</span>
                            </div>
                            <div>
                                訂正期間に申請内容の訂正を行なってください。<br/>
                                訂正が終わりましたら保存後、確定処理を行なってください。<br/>
                                訂正期間終了までに申請できない場合、問い合わせてください。<br />
                                Make corrections to your application content during the correction period.<br />
                                After you have finished making corrections, save them and proceed with confirmation.<br />
                                If you cannot submit your application before the end of the correction period, please contact us.
                            </div>
                        </div>
                    </div>
                    : null
            }

        </div>

    )
};

const useStyles = makeStyles({
        root: {
            "& .tejun": {
                marginTop: 16,
                "& > *:not(:first-child)": {
                    marginTop: 32,
                    "&.tejunRow": {
                        "&:after": {
                            display: "block",
                            content: '""',
                            position: "absolute",
                            top: -24,
                            left: "50%",
                            transform: "translate(-50%, 0)",
                            width: 0,
                            height: 0,
                            borderLeft: "16px solid transparent",
                            borderRight: "16px solid transparent",
                            borderTop: `16px solid ${BorderColorsEnum.LightGray}`,
                        },
                    }
                },
                "& .tejunRow": {
                    position: "relative",
                    "& .tejunRow_title": {
                        fontSize: 16,
                        fontWeight: 700,
                        marginBottom: 4,
                        "& .tejunRow_title_date": {
                            fontSize: 16,
                        }
                    }
                }
            }
        },
        xsRoot: {
            "& .tejun": {
                "& > *:not(:first-child)": {
                    "&.tejunRow": {
                        "&:after": {},
                    }
                },
                "& .tejunRow": {
                    "& .tejunRow_title": {
                        fontSize: 14,
                    }
                }
            }
        }
    }
);

