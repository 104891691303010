import React, {FC} from "react";
import {ListDsc} from "../../../atoms/ListDsc";
import { Box } from "@material-ui/core";
import {BoxProps} from "@material-ui/core/Box";

interface Props extends BoxProps {}

export const AYosiki2Description: FC<Props> = (props) => {
    return (
        <Box {...props}>

            <ListDsc>
                <li>
                    日本学生支援機構の給付型奨学金を合わせて受けていただくことが基本です。「日本学生支援機構の給付型奨学金に関する情報」の欄を記入できない場合は、別紙を必ず提出してください。
                </li>
                <li>
                    申請書に記載された内容及び提出された書類の情報は、授業料等減免の認定及び本学が実施する経済支援のために利用しています。また、今後の授業料等減免制度の検討のため、統計資料の作成に利用する場合がありますが、作成に際しては、個人が特定できないように処理します。
                </li>
                <li>
                    給付型奨学金を受給しておらず、「機構の給付型奨学金に関する情報」の欄に記入できない場合は、(別紙1)の提出が必要です。家計急変による理由の場合は、(別紙1)に代えて(別紙2)の提出が必要です。(給付型奨学金をあわせて受給している場合は、別紙1,2の提出は不要です。)
                </li>
                <li>
                    Basically, you are required to receive a grant-type scholarship from the Japan Student Services Organization in combination. If you are unable to complete the "Information on Japan Student Services Organization Grant-Type Scholarships" section, please be sure to submit an attachment.
                </li>
                <li>
                    The information indicated on the application form and submitted documents will be used for approval of reduction or waivers of tuition, etc. and for ﬁnancial support provided by this University. It may also be used to compile statistical data for future investigation of systems for reduction or waivers of tuition, etc., but when doing so it will be processed so that individuals cannot be identiﬁed.
                </li>
                <li>
                    If you are not a recipient of a grant-type scholarship and cannot complete the "Information on Japan Student Services Organization Grant-Type Scholarships" section, you must submit (Attachment 1). If the reason is due to a sudden change in household finances, (Attachment 2) must be submitted instead of (Attachment 1). (If you are receiving a grant-type scholarship in combination, you will not need to submit Attachments 1 and 2.)
                </li>
            </ListDsc>
        </Box>
    )
};
