import React from 'react';

export const ModalContentGenzaiNoSyokugyoRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                会社員、食品小売業、小学校教諭(公務員)、アルバイト、主婦、家事手伝い、年金受給者、予備校生、無職等を具体的に記入すること。
            </div>

            <div className={"modalContent_item"}>
                Office workers, food retailers, elementary school teachers (public officials), part-time workers,
                housewives, domestic helpers, pension recipients, preparatory school students, non-employed individuals,
                etc., must enter specific information.
            </div>

        </div>

    );
};
