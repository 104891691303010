import {
    FamilyInfoVo,
    SeisekiInfoVo,
    SinseisyaInfoVo,
    SyogakukinVo,
    SyunyuInfoItemFieldVo,
    SyunyuInfoItemOtherVo,
    SyunyuInfoItemVo,
    TokubetuKojoInfoVo,
    TyokiryoyoVo
} from "common/models/YosikiInfoModels";
import {
    SwFamilyInfo,
    SwMember,
    SwSeisekiInfo,
    SwSinseisyaInfo,
    SwSyogakukinInfo,
    SwSyunyuInfoItem,
    SwSyunyuInfoItemField,
    SwSyunyuInfoItemOther,
    SwTokubetuKojoInfo
} from "openapi/models";
import {convertStrToNumOrUndefined} from "common/functions/type_converter/convertStrToNumOrUndefined";

export function convertSyunyuInfoItemVoToSw(item?: SyunyuInfoItemVo): SwSyunyuInfoItem {
    if(!item) {throw new Error('収入情報が不正です。');}
    return {
        kubunCode: item.kubunCode,
        field: convertSyunyuInfoItemFieldVoToSw(item.field)
    }
}

export function convertSyunyuInfoItemOtherVoToSw(item?: SyunyuInfoItemOtherVo): SwSyunyuInfoItemOther {
    if(!item) {throw new Error('収入情報が不正です。');}
    return {
        kubunCode: item.kubunCode,
        fields: item.fields.map(f => convertSyunyuInfoItemFieldVoToSw(f))
    }
}


export function convertSyunyuInfoItemFieldVoToSw(item: SyunyuInfoItemFieldVo): SwSyunyuInfoItemField {
    return {
        kubunOther: item.kubunOther,
        amount: convertStrToNumOrUndefined(item.amount),
        syotokuYear: convertStrToNumOrUndefined(item.syotokuYear),
        syotokuMonth: convertStrToNumOrUndefined(item.syotokuMonth),
    }
}

export function convertFamilyInfoVoToSw(familyInfo: FamilyInfoVo): SwFamilyInfo {
    const members: Array<SwMember> = [];
    familyInfo.members.forEach(m => {
        members.push({
            "zokugara": m.zokugara,
            "zokugaraOther": m.zokugaraOther,
            "isSyugakusya": m.isSyugakusya,
            "name": m.name,
            "age": convertStrToNumOrUndefined(m.age),
            "syugakusya": {
                schoolType: m.syugakusya?.schoolType,
                schoolName: m.syugakusya?.schoolName,
                gakunen: convertStrToNumOrUndefined(m.syugakusya?.gakunen),
                settiKubun: m.syugakusya?.settiKubun,
                tugakuFromJitaku: m.syugakusya?.tugakuFromJitaku,
                jugyoryoMenjoState: m.syugakusya?.jugyoryoMenjoState,
                jugyoryoForHalfYear: convertStrToNumOrUndefined(m.syugakusya?.jugyoryoForHalfYear),
            },
            "syugakusyaIgai": {
                jobName: m.syugakusyaIgai?.jobName || "",
                jobYears: convertStrToNumOrUndefined(m.syugakusyaIgai?.jobYears),
                syunyuAriNasi: m.syugakusyaIgai?.syunyuAriNasi,
                kakuteisinkokuAriNasi: m.syugakusyaIgai?.kakuteisinkokuAriNasi,
                syunyuInfo: {
                    kyuyo: convertSyunyuInfoItemVoToSw(m.syugakusyaIgai?.syunyuInfo.kyuyo),
                    eigyo: convertSyunyuInfoItemVoToSw(m.syugakusyaIgai?.syunyuInfo.eigyo),
                    norin: convertSyunyuInfoItemVoToSw(m.syugakusyaIgai?.syunyuInfo.norin),
                    hudosan: convertSyunyuInfoItemVoToSw(m.syugakusyaIgai?.syunyuInfo.hudosan),
                    risi: convertSyunyuInfoItemVoToSw(m.syugakusyaIgai?.syunyuInfo.risi),
                    haito: convertSyunyuInfoItemVoToSw(m.syugakusyaIgai?.syunyuInfo.haito),
                    nenkin: convertSyunyuInfoItemOtherVoToSw(m.syugakusyaIgai?.syunyuInfo.nenkin),
                    koyohoken: convertSyunyuInfoItemOtherVoToSw(m.syugakusyaIgai?.syunyuInfo.koyohoken),
                    kakusyuteate: convertSyunyuInfoItemOtherVoToSw(m.syugakusyaIgai?.syunyuInfo.kakusyuteate),
                    syogakukin: convertSyunyuInfoItemOtherVoToSw(m.syugakusyaIgai?.syunyuInfo.syogakukin),
                    sinsekitounosien: convertSyunyuInfoItemOtherVoToSw(m.syugakusyaIgai?.syunyuInfo.sinsekitounosien),
                    yotyokin: convertSyunyuInfoItemOtherVoToSw(m.syugakusyaIgai?.syunyuInfo.yotyokin),
                    other: convertSyunyuInfoItemOtherVoToSw(m.syugakusyaIgai?.syunyuInfo.other),
                    taisyokukinn: convertSyunyuInfoItemOtherVoToSw(m.syugakusyaIgai?.syunyuInfo.taisyokukinn),
                    hokenkin: convertSyunyuInfoItemOtherVoToSw(m.syugakusyaIgai?.syunyuInfo.hokenkin),
                },
            },
        });

    }, familyInfo.members);

    return {
        "honnin": {
            syunyuAriNasi: familyInfo.honnin.syunyuAriNasi,
            kakuteisinkokuAriNasi: familyInfo.honnin.kakuteisinkokuAriNasi,
            syunyuInfo: {
                kyuyo: convertSyunyuInfoItemVoToSw(familyInfo.honnin?.syunyuInfo.kyuyo),
                eigyo: convertSyunyuInfoItemVoToSw(familyInfo.honnin?.syunyuInfo.eigyo),
                norin: convertSyunyuInfoItemVoToSw(familyInfo.honnin?.syunyuInfo.norin),
                hudosan: convertSyunyuInfoItemVoToSw(familyInfo.honnin?.syunyuInfo.hudosan),
                risi: convertSyunyuInfoItemVoToSw(familyInfo.honnin?.syunyuInfo.risi),
                haito: convertSyunyuInfoItemVoToSw(familyInfo.honnin?.syunyuInfo.haito),
                nenkin: convertSyunyuInfoItemOtherVoToSw(familyInfo.honnin?.syunyuInfo.nenkin),
                koyohoken: convertSyunyuInfoItemOtherVoToSw(familyInfo.honnin?.syunyuInfo.koyohoken),
                kakusyuteate: convertSyunyuInfoItemOtherVoToSw(familyInfo.honnin?.syunyuInfo.kakusyuteate),
                syogakukin: convertSyunyuInfoItemOtherVoToSw(familyInfo.honnin?.syunyuInfo.syogakukin),
                sinsekitounosien: convertSyunyuInfoItemOtherVoToSw(familyInfo.honnin?.syunyuInfo.sinsekitounosien),
                yotyokin: convertSyunyuInfoItemOtherVoToSw(familyInfo.honnin?.syunyuInfo.yotyokin),
                other: convertSyunyuInfoItemOtherVoToSw(familyInfo.honnin?.syunyuInfo.other),
                taisyokukinn: convertSyunyuInfoItemOtherVoToSw(familyInfo.honnin?.syunyuInfo.taisyokukinn),
                hokenkin: convertSyunyuInfoItemOtherVoToSw(familyInfo.honnin?.syunyuInfo.hokenkin),
            },
            tugakuFromJitaku: familyInfo.honnin.tugakuFromJitaku,
        },
        "members": members
    }
}

export function convertSyogakukinInfoVoToSw(syogakukins: Array<SyogakukinVo>): SwSyogakukinInfo {
    return {
        "syogakukins": syogakukins.map(t => {
            return {
                "name": t.name,
                "amountPerMonth": convertStrToNumOrUndefined(t.amountPerMonth),
                "times": convertStrToNumOrUndefined(t.times),
                "startYear": convertStrToNumOrUndefined(t.startYear),
                "startMonth": convertStrToNumOrUndefined(t.startMonth),
                "endYear": convertStrToNumOrUndefined(t.endYear),
                "endMonth": convertStrToNumOrUndefined(t.endMonth),
            }
        })
    }
}

export function convertSeisekiInfoVoToSw(seisekiInfo: SeisekiInfoVo): SwSeisekiInfo {
    return {
        "SA": convertStrToNumOrUndefined(seisekiInfo.SA),
        "B": convertStrToNumOrUndefined(seisekiInfo.B),
        "C": convertStrToNumOrUndefined(seisekiInfo.C),
        "total": convertStrToNumOrUndefined(seisekiInfo.total),
    }
}

export function convertSinseisyaInfoVoToSw(sinseisyaInfo: SinseisyaInfoVo): SwSinseisyaInfo {
    return {
        "birthdayYear": convertStrToNumOrUndefined(sinseisyaInfo.birthdayYear),
        "birthdayMonth": convertStrToNumOrUndefined(sinseisyaInfo.birthdayMonth),
        "birthdayDay": convertStrToNumOrUndefined(sinseisyaInfo.birthdayDay),
        "gakunen": convertStrToNumOrUndefined(sinseisyaInfo.gakunen),
        "isKyugaku": sinseisyaInfo.isKyugaku,
        "kyugaku": {
            "startYear": convertStrToNumOrUndefined(sinseisyaInfo.kyugaku.startYear),
            "startMonth": convertStrToNumOrUndefined(sinseisyaInfo.kyugaku.startMonth),
            "endYear": convertStrToNumOrUndefined(sinseisyaInfo.kyugaku.endYear),
            "endMonth": convertStrToNumOrUndefined(sinseisyaInfo.kyugaku.endMonth),
            "riyu": sinseisyaInfo.kyugaku.riyu,
        },
        "isRyunen": sinseisyaInfo.isRyunen,
        "address": {
            "zipcode": sinseisyaInfo.address.zipcode,
            "address": sinseisyaInfo.address.address,
        },
        "telHome": sinseisyaInfo.telHome,
        "telLab": sinseisyaInfo.telLab,
        "sidoTeacherName": sinseisyaInfo.sidoTeacherName,
        "addressFamily": {
            "zipcode": sinseisyaInfo.addressFamily.zipcode,
            "address": sinseisyaInfo.addressFamily.address,
        },
        "telFamily": sinseisyaInfo.telFamily,
    }
}

export function convertTokubetuKojoVoToSw(tokubetuKojoInfo: TokubetuKojoInfoVo): SwTokubetuKojoInfo {
    return {
        bosiHusi: {
            mother: tokubetuKojoInfo.bosiHusi.mother ? {
                ...tokubetuKojoInfo.bosiHusi.mother,
                jikiYaer: convertStrToNumOrUndefined(tokubetuKojoInfo.bosiHusi.mother?.jikiYaer),
                jikiMonth: convertStrToNumOrUndefined(tokubetuKojoInfo.bosiHusi.mother?.jikiMonth),
                riyu: tokubetuKojoInfo.bosiHusi.mother.riyu
            } : undefined,
            father: tokubetuKojoInfo.bosiHusi.father ? {
                ...tokubetuKojoInfo.bosiHusi.father,
                jikiYaer: convertStrToNumOrUndefined(tokubetuKojoInfo.bosiHusi.father?.jikiYaer),
                jikiMonth: convertStrToNumOrUndefined(tokubetuKojoInfo.bosiHusi.father?.jikiMonth),
                riyu: tokubetuKojoInfo.bosiHusi.father.riyu
            } : undefined
        },
        syogaisya: tokubetuKojoInfo.syogaisya.map(s => {
            return {
                ...s
            }
        }),
        tyokiryoyo: tokubetuKojoInfo.tyokiryoyo.map((t: TyokiryoyoVo) => {
            return {
                ...t,
                type: t.type === "" ? undefined : t.type,
                startYear: convertStrToNumOrUndefined(t.startYear),
                startMonth: convertStrToNumOrUndefined(t.startMonth),
                amountPerHalfYear: convertStrToNumOrUndefined(t.amountPerHalfYear),
            }
        }),
        bekkyo: {
            riyu: tokubetuKojoInfo.bekkyo.riyu,
            amountPerMonth: convertStrToNumOrUndefined(tokubetuKojoInfo.bekkyo.amountPerMonth),
        },
        saigaihigai: {
            riyu: tokubetuKojoInfo.saigaihigai.riyu,
            amount: convertStrToNumOrUndefined(tokubetuKojoInfo.saigaihigai.amount)
        },
    }
}
