import React from 'react';
import {Box} from "@material-ui/core";
import {SinseisyaYoryos} from "./yoryos/SinseisyaYoryos";
import {SinseisyaRyugakuseiYoryos} from "./yoryos/SinseisyaRyugakuseiYoryos";
import Grid from "@material-ui/core/Grid";
import {MemberYoryos} from "./yoryos/MemberYoryos";
import {Title02} from "../../atoms/titles/Title02";
import {SyoruiConfirmationVo} from "../../../common/models/SyoruiConfirmationModels";
import {TextColorsEnum} from "../../../common/enums_value/ColorsEnum";


interface Props {
    syoruiConfirmation?: SyoruiConfirmationVo
}

export const SyoruiListComponent: React.FC<Props> = ({
                                                         syoruiConfirmation
                                                     }) => {

    if (syoruiConfirmation === undefined) {
        return <></>
    }

    return (

        <Box>

            <Title02
                title={"申請に必要な様式等の確認機能（参考結果表示） / Confirmation Function for Forms, etc. Requiring Application (Reference)"}
            />

            <Box mt={3}>
                <Box display={"inline"} fontWeight={700} color={TextColorsEnum.Red}>
                    これらの資料は参考で、他にも必要な書類がある可能性があります。
                </Box>
                <br/>
                重複する書類は、一つのみの申請で構いません。<br/>
                <Box display={"inline"} fontWeight={700} color={TextColorsEnum.Red}>
                    These documents are for reference only and there may be other documents required.
                </Box><br/>
                Duplicate documents may be submitted for only one application.
            </Box>

            <Box mt={6}>

                <Grid container spacing={6} direction={"column"}>

                    {
                        syoruiConfirmation.honnin && (
                            <Grid item xs>
                                <SinseisyaYoryos
                                    honnin={syoruiConfirmation.honnin}
                                />
                            </Grid>
                        )
                    }

                    {
                        syoruiConfirmation.honninRyugakusei && (
                            <Grid item xs>
                                <SinseisyaRyugakuseiYoryos
                                    honninRyugakusei={syoruiConfirmation.honninRyugakusei}
                                />
                            </Grid>
                        )
                    }

                    {
                        syoruiConfirmation.members?.map((m, i) => {
                            return (
                                <Grid item xs key={i}>
                                    <MemberYoryos
                                        index={i}
                                        member={m}
                                    />
                                </Grid>
                            )
                        })
                    }

                </Grid>

            </Box>

        </Box>

    );
};
