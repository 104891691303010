import DialogActions from "@material-ui/core/DialogActions";
import {UploadedFilesVo} from "common/models/UploadedFilesVo";
import {Button} from "components/atoms/Button";
import {Modal} from "components/atoms/Modal";
import {UploadedFileList} from "components/molecules/fileList/FileList";
import React from 'react';

interface OsiraseDetailModalProps {
    openState: boolean
    osiraseDetail: OsirasesDetailVo | undefined
    handleClickDetailModalClose: () => void
}

export interface OsirasesDetailVo {
    title: string;
    createdAt: string;
    body: string;
    uploadedFiles: Array<UploadedFilesVo>;
}

export const OsiraseDetailModal: React.FC<OsiraseDetailModalProps> = (props) => {

    const osiraseDetail = props.osiraseDetail;
    if(osiraseDetail === undefined) return <></>;

    return (

        <Modal
            openState={props.openState}
            title={osiraseDetail.title}
        >

            <div className={'mb24'}>
                作成日:{osiraseDetail.createdAt}
            </div>

            <div className={'mb24'}>
                <div className={"preWrap"}>{osiraseDetail.body}</div>
            </div>

            <div>
                <UploadedFileList
                    uploadedFiles={osiraseDetail.uploadedFiles}
                    disableEdit={true}
                />
            </div>

            <DialogActions className={"modal_actions"}>

                <Button
                    type={"default"}
                    label={"閉じる"}
                    width={"120px"}
                    handleClick={props.handleClickDetailModalClose}
                />

            </DialogActions>

        </Modal>

    );
};


