import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from 'react';

interface Title05Props {
    className?: string
    title: string;
}

export const Title05: FC<Title05Props> = React.memo((props) => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} ${props.className}`}>
            {props.title}
        </div>
    )
});

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: 16,
        fontWeight: "bold",
    },
}));