// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
//
// $ bin/rails runner script/codegen_masters.rb

import { SyunyuKubunMasterVo, YosikiMastersVo } from "./YosikiMastersVo";
import {SyunyuKubunCategoryEnum} from "common/enums/SyunyuKubunCategoryEnum";

const syunyuKubun: Array<SyunyuKubunMasterVo> = [
    { code: "E給料賃金", nameJa: "給料賃金", nameEn: "Salary and wages", categoryCode: SyunyuKubunCategoryEnum.給与所得, rinji: false },
    { code: "E年金恩給", nameJa: "年金恩給", nameEn: "Pension and other types of retirement benefits", categoryCode: SyunyuKubunCategoryEnum.給与所得, rinji: false },
    { code: "E雇用保険", nameJa: "雇用保険", nameEn: "Unemployment insurance", categoryCode: SyunyuKubunCategoryEnum.給与所得, rinji: false },
    { code: "E各種手当", nameJa: "各種手当", nameEn: "Various allowances", categoryCode: SyunyuKubunCategoryEnum.給与所得, rinji: false },
    { code: "E奨学金", nameJa: "奨学金", nameEn: "Scholarships", categoryCode: SyunyuKubunCategoryEnum.給与所得, rinji: false },
    { code: "E営業", nameJa: "営業", nameEn: "Sales", categoryCode: SyunyuKubunCategoryEnum.給与所得以外, rinji: false },
    { code: "E農林水産業", nameJa: "農林水産業", nameEn: "Agriculture, forestry and fisheries", categoryCode: SyunyuKubunCategoryEnum.給与所得以外, rinji: false },
    { code: "E不動産", nameJa: "不動産", nameEn: "Real estate", categoryCode: SyunyuKubunCategoryEnum.給与所得以外, rinji: false },
    { code: "E利子配当", nameJa: "利子配当", nameEn: "Interest and Relationship", categoryCode: SyunyuKubunCategoryEnum.給与所得以外, rinji: false },
    { code: "E親戚等の援助", nameJa: "親戚等の援助", nameEn: "Remittance, financial assistance, etc.", categoryCode: SyunyuKubunCategoryEnum.給与所得以外, rinji: false },
    { code: "E預貯金から", nameJa: "預・貯金から", nameEn: "From deposits and savings", categoryCode: SyunyuKubunCategoryEnum.給与所得以外, rinji: false },
    { code: "Eその他", nameJa: "その他", nameEn: "Other", categoryCode: SyunyuKubunCategoryEnum.給与所得以外, rinji: false },
    { code: "E退職金", nameJa: "退職金", nameEn: "Retirement benefits", categoryCode: SyunyuKubunCategoryEnum.給与所得以外, rinji: true },
    { code: "E保険金", nameJa: "保険金", nameEn: "Insurance money", categoryCode: SyunyuKubunCategoryEnum.給与所得以外, rinji: true },
]

export const yosikiMasters: YosikiMastersVo = {
    syunyuKubun: syunyuKubun
}