/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwInfoPolicyRequireTokubetuKojoInfo
 */
export interface SwInfoPolicyRequireTokubetuKojoInfo {
    /**
     * 
     * @type {boolean}
     * @memberof SwInfoPolicyRequireTokubetuKojoInfo
     */
    bosiHusi: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwInfoPolicyRequireTokubetuKojoInfo
     */
    syogaisya: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwInfoPolicyRequireTokubetuKojoInfo
     */
    tyokiryoyo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwInfoPolicyRequireTokubetuKojoInfo
     */
    bekkyo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwInfoPolicyRequireTokubetuKojoInfo
     */
    saigaihigai: boolean;
}

export function SwInfoPolicyRequireTokubetuKojoInfoFromJSON(json: any): SwInfoPolicyRequireTokubetuKojoInfo {
    return SwInfoPolicyRequireTokubetuKojoInfoFromJSONTyped(json, false);
}

export function SwInfoPolicyRequireTokubetuKojoInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwInfoPolicyRequireTokubetuKojoInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bosiHusi': json['bosiHusi'],
        'syogaisya': json['syogaisya'],
        'tyokiryoyo': json['tyokiryoyo'],
        'bekkyo': json['bekkyo'],
        'saigaihigai': json['saigaihigai'],
    };
}

export function SwInfoPolicyRequireTokubetuKojoInfoToJSON(value?: SwInfoPolicyRequireTokubetuKojoInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bosiHusi': value.bosiHusi,
        'syogaisya': value.syogaisya,
        'tyokiryoyo': value.tyokiryoyo,
        'bekkyo': value.bekkyo,
        'saigaihigai': value.saigaihigai,
    };
}


