export const URL_PATHS_OFFICER = {
    login: "#/login", // ログイン
    top: "#/", // ログイン
    syoriki_setting: "#/syoriki_setting", // 処理期設定
    kikan_setting: "#/kikan_setting", // 期間設定
    student_list: "#/student_list", // 学籍一覧
    sinseisya_list: "#/sinseisya_list", // 申請者一覧
    mail_list: "#/mail_list", // 送信メール一覧
    osirase_list: "#/osirase_list", // お知らせ一覧
    yosiki_detail: "#/yosiki_detail", // 様式詳細
    yosiki_edit: "#/yosiki_edit", // 様式入力
    summary: "#/summary", // サマリー
    syorui_confirmation_form: "#/syorui_confirmation_form", // 必要な様式の確認機能のフォーム
    syorui_confirmation: "#/syorui_confirmation", // 必要な様式の確認

    a_yosiki_form: "#/syorui_confirmation_form", // A様式フォーム

};


