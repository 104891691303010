import {TextField} from "components/atoms/forms/TextField";
import React, {FC} from 'react';
import {convertZenkakuToHankaku} from "common/functions/char_converter/convertZenkakuToHankaku";
import {trimStrForNotNumber} from "common/functions/char_converter/trimStrForNotNumber";

interface TelFieldProps {
    // option
    className?: string;
    width?: string;
    widthInput?: string;
    isError?: boolean;
    prefixText?: string;
    suffixText?: string;
    multiline?: boolean;
    rows?: number;
    min?: string;
    max?: string;
    pattern?: string;
    helperText?: string;
    errorMessages?: Array<string>;

    // form
    disabled?: boolean;
    value?: string;
    handleChange?: (value: string) => void;
    handleBlur?: (value: string) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
}

export const TelField: FC<TelFieldProps> = (props) => {

    React.useEffect(() => {
        if (props.value) setValue(props.value);
    }, [props.value]);

    const [value, setValue] = React.useState<string>("");

    function handleChange(newValue: string) {
        const trimedValue = trimStrForNotNumber(convertZenkakuToHankaku(newValue));
        setValue(trimedValue);
    }

    function handleBlur(newValue: string) {
        const trimedValue = trimStrForNotNumber(convertZenkakuToHankaku(newValue));
        setValue(trimedValue);
        if (props.handleBlur) props.handleBlur(trimedValue);
    }

    return (

        <TextField
            className={props.className}
            disabled={props.disabled}
            width={props.width}
            widthInput={props.widthInput}
            prefixText={props.prefixText}
            suffixText={props.suffixText}
            multiline={props.multiline}
            rows={props.rows}
            min={props.min}
            max={props.max}
            helperText={props.helperText}
            type={"tel"}
            value={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errorMessages={props.errorMessages}
            isError={props.errorMessages && props.errorMessages.length !== 0}
        />

    )
};

TelField.defaultProps = {
    width: "100%",
    widthInput: "100%",
    isError: false,
    disabled: false,
    multiline: false,
};


