/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SwOfficerSyoruis,
    SwOfficerSyoruisFromJSON,
    SwOfficerSyoruisToJSON,
} from '../models';

/**
 * no description
 */
export class StudentSyoruisApi extends runtime.BaseAPI {

    /**
     * 書類一覧の取得 学生の様式1入力内容から提出が必要な書類の一覧を取得する 確定状態でなければundefinedとして扱う 
     */
    async getStudentSyoruisRaw(): Promise<runtime.ApiResponse<SwOfficerSyoruis>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/syoruis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOfficerSyoruisFromJSON(jsonValue));
    }

    /**
     * 書類一覧の取得 学生の様式1入力内容から提出が必要な書類の一覧を取得する 確定状態でなければundefinedとして扱う 
     */
    async getStudentSyoruis(): Promise<SwOfficerSyoruis> {
        const response = await this.getStudentSyoruisRaw();
        return await response.value();
    }

}
