import {PictureAsPdf} from "@material-ui/icons";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {PdfEnum, PdfEnumToName} from "common/enums_text/PdfEnum";
import {SummaryTextEnum, SummaryTextEnumToName} from "common/enums_text/SummaryTextEnum";
import {TextLink} from "components/atoms/TextLink";
import React, {FC} from "react";
import {ContentBox} from "../../atoms/ContentBox";
import { Box } from "@material-ui/core";
import {TextColorsEnum} from "../../../common/enums_value/ColorsEnum";

interface Props {
    studentInfo: StudentInfoStoreVo
}

export const SyoruiListYoryo: FC<Props> = ({
                                               studentInfo,
                                           }) => {

    return (

        <ContentBox>

            <Box fontWeight={700} color={TextColorsEnum.Red}>
                {convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.書類を提出する前に必ず申請要領をお読みになってから以下の書類を印刷して申請してください))}
            </Box>

            <div className={'mt8'}>

                {
                    // NOTE: 学部の場合
                    studentInfo.isGakubu ?
                        <div>
                            <div>
                                <TextLink
                                    icon={<PictureAsPdf className={'icon'}/>}
                                    text={PdfEnumToName(PdfEnum.申請要領学部在学生新制度).ja}
                                    href={PdfEnumToName(PdfEnum.申請要領学部在学生新制度).ja_url}
                                    download={true}
                                />
                                {" / "}
                                <TextLink
                                    icon={<PictureAsPdf className={'icon'}/>}
                                    text={PdfEnumToName(PdfEnum.申請要領学部在学生新制度).en}
                                    href={PdfEnumToName(PdfEnum.申請要領学部在学生新制度).en_url}
                                    download={true}
                                />
                            </div>
                            <div>
                                <TextLink
                                    icon={<PictureAsPdf className={'icon'}/>}
                                    text={PdfEnumToName(PdfEnum.申請要領学部在学生留学生コロナ).ja}
                                    href={PdfEnumToName(PdfEnum.申請要領学部在学生留学生コロナ).ja_url}
                                    download={true}
                                />
                                {" / "}
                                <TextLink
                                    icon={<PictureAsPdf className={'icon'}/>}
                                    text={PdfEnumToName(PdfEnum.申請要領学部在学生留学生コロナ).en}
                                    href={PdfEnumToName(PdfEnum.申請要領学部在学生留学生コロナ).en_url}
                                    download={true}
                                />
                            </div>
                        </div>
                        : null
                }

                {
                    // NOTE: 学部以外の新入生
                    !studentInfo.isGakubu && studentInfo.isSinnyusei ?
                        <div>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領大学院新入生).ja}
                                href={PdfEnumToName(PdfEnum.申請要領大学院新入生).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領大学院新入生).en}
                                href={PdfEnumToName(PdfEnum.申請要領大学院新入生).en_url}
                                download={true}
                            />
                        </div>
                        : null
                }

                {
                    // NOTE: 学部以外の在学生
                    !studentInfo.isGakubu && !studentInfo.isSinnyusei ?
                        <div>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領大学院在学生).ja}
                                href={PdfEnumToName(PdfEnum.申請要領大学院在学生).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領大学院在学生).en}
                                href={PdfEnumToName(PdfEnum.申請要領大学院在学生).en_url}
                                download={true}
                            />
                        </div>
                        : null
                }

            </div>

        </ContentBox>

    )
};