export const SECTION_ID_NAME = {
    basicInfo: "basicInfo",
    sinseisyaInfo: "sinseisyaInfo",
    seisekiInfo: "seisekiInfo",
    syogakukinInfo: "syogakukinInfo",
    familyInfo: "familyInfo",
    rentaihosyoninInfo: "rentaihosyoninInfo",
    nyugakuryoInfo: "nyugakuryoInfo",
    jugyoryoInfo: "jugyoryoInfo",
    aYosiki1Info: "aYosiki1Info",
    tokubetuKojoInfo: "tokubetuKojoInfo",
};