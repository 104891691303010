/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSaigaihigaiErrorMessage
 */
export interface SwSaigaihigaiErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSaigaihigaiErrorMessage
     */
    riyu?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSaigaihigaiErrorMessage
     */
    amount?: Array<SwErrorMessage>;
}

export function SwSaigaihigaiErrorMessageFromJSON(json: any): SwSaigaihigaiErrorMessage {
    return SwSaigaihigaiErrorMessageFromJSONTyped(json, false);
}

export function SwSaigaihigaiErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSaigaihigaiErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'riyu': !exists(json, 'riyu') ? undefined : ((json['riyu'] as Array<any>).map(SwErrorMessageFromJSON)),
        'amount': !exists(json, 'amount') ? undefined : ((json['amount'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwSaigaihigaiErrorMessageToJSON(value?: SwSaigaihigaiErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'riyu': value.riyu === undefined ? undefined : ((value.riyu as Array<any>).map(SwErrorMessageToJSON)),
        'amount': value.amount === undefined ? undefined : ((value.amount as Array<any>).map(SwErrorMessageToJSON)),
    };
}


