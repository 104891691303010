import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {
    convertPolicyAndBasicInfoSwToVo,
    convertYosikiInfoSwToVo,
    convertYosikiInfoVoToSw
} from "common/functions/yosikiInfo/convertPolicyAndBasicInfoSwToVo";
import {YosikiKakuteiModalType} from "common/models/yosiki/YosikiInfoModalStoreVo";
import {INIT_YOSIKI_INFO_ERROR_MESSAGES, YosikiInfoStoreVo} from "common/models/yosiki/YosikiInfoStoreVo";
import {YosikiInfoResponseVo} from "common/models/YosikiInfoModels";
import {announceCommonStore} from "stores/common/AnnounceCommonStore";
import {
    PostStudentYosikiInfoRequest,
    StudentYosikiInfoApi,
    StudentYosikiKakuteiApi
} from "openapi/apis";
import {SwYosikiInfoErrorMessageResponse, SwYosikiInfoResponse} from "openapi/models";
import {accountInfoStore} from "stores/student/AccountInfoStore";
import {yosikiInfoModalStore} from "pages/student/yosiki/stores/YosikiInfoModalStore";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";
import { yosikiMasters } from "common/models/YosikiMastersValue";

class YosikiInfoStore extends Store<YosikiInfoStoreVo> {

    constructor() {
      super({
        commonYosikiMasters: yosikiMasters,
        yosikiInfoErrorMessages: INIT_YOSIKI_INFO_ERROR_MESSAGES
      })
    }

    updateYosikiInfo(yosikiInfo: YosikiInfoResponseVo) {
        this.dispatch(() => ({yosikiInfo: yosikiInfo}));
    }

    updateOriginalYosikiInfo(yosikiInfo: YosikiInfoResponseVo) {
        this.dispatch(() => ({originalYosikiInfo: JSON.parse(JSON.stringify(yosikiInfo))}));
    }

    updateYosikiInfoErrorMessages(yosikiInfoErrorMessages: SwYosikiInfoErrorMessageResponse | undefined) {
        this.dispatch(() => ({yosikiInfoErrorMessages: yosikiInfoErrorMessages}));
    }

    private api = new StudentYosikiInfoApi(apiConfiguration);
    private studentKakuteiApi = new StudentYosikiKakuteiApi(apiConfiguration);

    async getYosikiInfo() {
        try {
            const response: SwYosikiInfoResponse = await this.api.getStudentYosikiInfo();
            const convert = convertYosikiInfoSwToVo(response);
            this.updateYosikiInfo(convert);
            this.updateOriginalYosikiInfo(convert)
        } catch (res) {
            handleStudentError(res)
        }
    }

    async postYosikiInfoKakutei(isYosikiDetail: boolean = true) {
        try {
            const res = await this.studentKakuteiApi.postStudentYosikiStudentNumberKakutei();
            if (res.error !== undefined) {
                yosikiInfoModalStore.updateYosikiKakuteiModalType(YosikiKakuteiModalType.kakuteiErrorModal);
                yosikiInfoModalStore.updateKakuteiModalErrorMessage([res.error]);
            } else {
                if (!isYosikiDetail) {
                    window.location.assign(`${URL_PATHS_STUDENT.yosiki_detail}`);
                }
                accountInfoStore.getStudentInfo().then();
                yosikiInfoModalStore.updateYosikiKakuteiModalType(undefined);
                announceCommonStore.updateAnnounceCommon(true, "確定しました。 / Confirm completed.");
                this.getYosikiInfo()
            }
        } catch (res) {
            handleStudentError(res)
        }
    }

    async getYosikiInfoPolicyAndBasicInfo() {
        try {
            const response: SwYosikiInfoResponse = await this.api.getStudentYosikiInfo();
            // 未保存の場合に様式の全ての情報を更新すると入力内容が失われるので、policyInfoとbasicInfoのみを更新する。
            const converted = convertPolicyAndBasicInfoSwToVo(response, this.currentState.yosikiInfo);
            const convertedOriginal = convertPolicyAndBasicInfoSwToVo(response, this.currentState.originalYosikiInfo);
            if (converted === undefined || convertedOriginal === undefined) return;
            this.updateYosikiInfo(converted);
            this.updateOriginalYosikiInfo(convertedOriginal)
        } catch (res) {
            handleStudentError(res)
        }
    }

    async postYosikiInfo() {
        try {
            if (this.currentState.yosikiInfo === undefined) return;
            const swYosikiInfoRequest = convertYosikiInfoVoToSw(this.currentState.yosikiInfo);
            if (swYosikiInfoRequest === undefined) return;
            const request: PostStudentYosikiInfoRequest = {
                swYosikiInfoRequest: swYosikiInfoRequest
            };
            const res = await this.api.postStudentYosikiInfo(request);
            if (res.error !== undefined) {
                this.updateYosikiInfoErrorMessages(res.error);
                this.updateOriginalYosikiInfo(this.currentState.yosikiInfo)
            }
            announceCommonStore.updateAnnounceCommon(true, `保存が完了しました。「戻る」で入力内容を確認できます。 / Save completed. You can confirm your entries by clicking on "Back".`)
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const yosikiInfoStore = new YosikiInfoStore();
