/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwStudentSendCreateAccountLinkNewStudentsPostErrorMessage
 */
export interface SwStudentSendCreateAccountLinkNewStudentsPostErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentSendCreateAccountLinkNewStudentsPostErrorMessage
     */
    email?: Array<SwErrorMessage>;
}

export function SwStudentSendCreateAccountLinkNewStudentsPostErrorMessageFromJSON(json: any): SwStudentSendCreateAccountLinkNewStudentsPostErrorMessage {
    return SwStudentSendCreateAccountLinkNewStudentsPostErrorMessageFromJSONTyped(json, false);
}

export function SwStudentSendCreateAccountLinkNewStudentsPostErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwStudentSendCreateAccountLinkNewStudentsPostErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : ((json['email'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwStudentSendCreateAccountLinkNewStudentsPostErrorMessageToJSON(value?: SwStudentSendCreateAccountLinkNewStudentsPostErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email === undefined ? undefined : ((value.email as Array<any>).map(SwErrorMessageToJSON)),
    };
}


