import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {Container} from "components/layouts/Container";
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SyoruiConfirmationFormVo} from "../../../common/models/SyoruiConfirmationFormModels";
import {FollowingBottomNavigation} from "../utilities/FollowingBottomNavigation";
import {Button} from "../../atoms/Button";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "../../../common/enums_text/CommonTextEnum";
import {SwSyoruiConfirmationFormErrorMessageResponse} from "../../../openapi";
import lodash from "lodash";
import {FormComponent} from "./FormComponent";

interface Props {
    studentInfo: StudentInfoStoreVo
    syoruiConfirmationForm?: SyoruiConfirmationFormVo
    syoruiConfirmationFormErrorMessage?: SwSyoruiConfirmationFormErrorMessageResponse
    handleClickBackButton: () => void
    handleClickSave: (formValues?: SyoruiConfirmationFormVo) => void
    handleClickSyoruiHantei: (formValues?: SyoruiConfirmationFormVo) => void
    handleClickSyoruiHanteiKaijo: () => void
}

export const SyoruiConfirmationForm: React.FC<Props> = ({
                                                            syoruiConfirmationForm,
                                                            syoruiConfirmationFormErrorMessage,
                                                            studentInfo,
                                                            handleClickBackButton,
                                                            handleClickSave,
                                                            handleClickSyoruiHantei,
                                                            handleClickSyoruiHanteiKaijo
                                                  }) => {

    // NOTE: 入力用のフォーム情報
    const [
        formValues,
        updateFormValue
    ] = useState<SyoruiConfirmationFormVo>()

    // NOTE: フォームのエラー情報
    const [
        errorMessages,
        updateErrorMessages
    ] = useState<SwSyoruiConfirmationFormErrorMessageResponse>()

    // NOTE: storeのデータを編集使用せずにstateを作成して処理する。
    useEffect(() => {
        // NOTE: 参照しないようにオブジェクトをclone
        const copiedSyoruiConfirmationForm = lodash.cloneDeep(syoruiConfirmationForm) as SyoruiConfirmationFormVo;
        const copiedErrorMessages = lodash.cloneDeep(syoruiConfirmationFormErrorMessage) as SwSyoruiConfirmationFormErrorMessageResponse;
        // NOTE: 初期化
        updateFormValue(copiedSyoruiConfirmationForm)
        updateErrorMessages(copiedErrorMessages)
    }, [
        syoruiConfirmationForm,
        syoruiConfirmationFormErrorMessage,
        updateFormValue,
        updateErrorMessages
    ])

    // const isFormChanged = useMemo(() => {
    //     return !lodash.isEqual(formValues, syoruiConfirmationForm)
    // },[formValues, syoruiConfirmationForm])

    const updates = useCallback((formValues: SyoruiConfirmationFormVo, errorMessages?: SwSyoruiConfirmationFormErrorMessageResponse) => {
        updateFormValue({...formValues})
        updateErrorMessages({...errorMessages})
    }, [updateFormValue, updateErrorMessages])

    return (

        <>

            <Container
                maxWidth={'880px'}
            >

                <FormComponent
                    formValues={formValues}
                    errorMessages={errorMessages}
                    updates={updates}
                    isSyoruiHantei={studentInfo.isSyoruiHantei}
                    isRyugakusei={studentInfo.isRyugakusei}
                    isJugyoryo={!!studentInfo.yosikiJugyoryo}
                    isNyugakuryo={!!studentInfo.yosikiNyugakuryo}
                />

            </Container>

            <FollowingBottomNavigation>

                <Button
                    className={"search_row_button"}
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.戻る)}
                    width={"104px"}
                    handleClick={handleClickBackButton}
                />

                {
                    // NOTE: 判定前
                    studentInfo.isSyoruiHantei === false && (
                        <>
                            <Button
                                className={"search_row_button"}
                                type={"primary"}
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.保存)}
                                width={"104px"}
                                handleClick={() => handleClickSave(formValues)}
                            />
                            <Button
                                className={"search_row_button"}
                                type={"secondary"}
                                label={"判定 / DETERMINE"}
                                handleClick={() => handleClickSyoruiHantei(formValues)}
                                // disabled={isFormChanged}
                            />
                        </>
                    )
                }

                {
                    // NOTE: 判定後
                    studentInfo.isSyoruiHantei === true && (
                        <>
                            <Button
                                className={"search_row_button"}
                                type={"secondary"}
                                label={"判定解除 / Cancel"}
                                handleClick={() => handleClickSyoruiHanteiKaijo()}
                            />
                        </>
                    )
                }

            </FollowingBottomNavigation>

        </>

    );
};
