import {SchoolTypeEnum, schoolTypeEnumToName} from "common/enums/SchoolTypeEnum";
import {SettiKubunEnum, settiKubunEnumToName} from "common/enums/SettiKubunEnum";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {FamilyInfoVo, MemberSyugakusyaVo, MemberVo} from "common/models/YosikiInfoModels";
import {YosikiMastersVo} from "common/models/YosikiMastersVo";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {NumberField} from "components/atoms/forms/NumberField";
import {RadioButton} from "components/atoms/forms/RadioButton";
import {TextField} from "components/atoms/forms/TextField";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {DescriptionLink} from "components/atoms/DescriptionLink";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {RadioButtonGroup} from "components/molecules/forms/RadioButtonGroup";
import {DescriptionLinkWrap} from "components/molecules/utilities/DescriptionLinkWrap";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {SwMemberErrorMessagesSyugakusya} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";
import {JugyoryoMenjoStateEnum, jugyoryoMenjoStateEnumToName} from "../../../common/enums/JugyoryoMenjoStateEnum";
import {canInputJugyoryo} from "../../../common/functions/yosikiInfo/canInputJugyoryo";
import {Box} from "@material-ui/core";
import {TextColorsEnum} from "../../../common/enums_value/ColorsEnum";

interface FamilyInfoSectionSyugakusyaProps {
    member: MemberVo
    members: Array<MemberVo>
    index: number
    commonYosikiMasters?: YosikiMastersVo
    updateFamilyInfo: (part: Partial<FamilyInfoVo>) => void
    updateSyugakusyaErrorMessages: (part: Partial<SwMemberErrorMessagesSyugakusya>, index: number) => void
    syugakusyaErrorMessages?: SwMemberErrorMessagesSyugakusya
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
    isRyugakusei: boolean
}

export const FamilyInfoSectionSyugakusya: FC<FamilyInfoSectionSyugakusyaProps> = (props) => {

    function updateSyugakusya(part: Partial<MemberSyugakusyaVo>, index: number) {
        const members = props.members;
        const syugakusya = members[index].syugakusya;
        const member = members[index];
        members[index] = {
            ...member,
            syugakusya: {
                ...syugakusya,
                ...part
            }
        };
        props.updateFamilyInfo({members: members})
    }

    function updateSyugakusyaErrorMessages(part: Partial<SwMemberErrorMessagesSyugakusya>, index: number) {
        const syugakusyaErrorMessages = props.syugakusyaErrorMessages;
        let newMessage = {
            ...syugakusyaErrorMessages,
            ...part
        };
        props.updateSyugakusyaErrorMessages(newMessage, index)
    }

    function handleChangeSchoolType(value: string) {
        updateSyugakusya({schoolType: value as SchoolTypeEnum}, props.index);
        updateSyugakusyaErrorMessages({schoolType: undefined}, props.index);
    }

    function handleBlurSchoolName(value: string) {
        updateSyugakusya({schoolName: value}, props.index);
        updateSyugakusyaErrorMessages({schoolName: undefined}, props.index);
    }

    function handleBlurGakunen(value: string) {
        updateSyugakusya({gakunen: value}, props.index);
        updateSyugakusyaErrorMessages({gakunen: undefined}, props.index);
    }

    function handleChangeSettiKubun(value: string) {
        updateSyugakusya({settiKubun: value as SettiKubunEnum}, props.index);
        updateSyugakusyaErrorMessages({settiKubun: undefined}, props.index);
    }

    function handleChangeTugakuFromJitaku(value: boolean) {
        updateSyugakusya({tugakuFromJitaku: value}, props.index);
        updateSyugakusyaErrorMessages({tugakuFromJitaku: undefined}, props.index);
    }

    function handleChangeJugyoryoMenjoState(value: JugyoryoMenjoStateEnum) {
        updateSyugakusya({jugyoryoMenjoState: value}, props.index);
        updateSyugakusyaErrorMessages({jugyoryoMenjoState: undefined}, props.index);
    }

    function handleBlurJugyoryoForHalfYear(value: string) {
        updateSyugakusya({jugyoryoForHalfYear: value}, props.index);
        updateSyugakusyaErrorMessages({jugyoryoForHalfYear: undefined}, props.index);
    }


    const member: MemberVo = props.member;
    const syugakusya: MemberSyugakusyaVo | undefined = member.syugakusya;
    if (syugakusya === undefined || props.commonYosikiMasters === undefined) return <></>;

    const syugakusyaErrorMessages = props.syugakusyaErrorMessages;

    return (
        <FormTableRow
            label={convertZokugaraForStr(member.zokugara, member.zokugaraOther)}
        >

            <div className={"formTableRow_item"}>
                <Box color={TextColorsEnum.Red} fontWeight={700} mb={1}>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.時点の情報を)}</Box>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.在学学校)}<RequiredTag
                    type={RequiredTagTypeEnum.必須}/>
                </div>
                <RadioButtonGroup
                    errorMessages={convertErrorMessageVoToArrayStr(syugakusyaErrorMessages?.schoolType)}
                    vertical={true}
                >
                    {
                        Object.entries(SchoolTypeEnum).map(([, value]) => {
                            return (
                                <RadioButton
                                    key={value}
                                    value={value}
                                    checked={syugakusya.schoolType === value}
                                    label={convertEnumNameToStr(schoolTypeEnumToName(value))}
                                    isError={isErrorMessage(syugakusyaErrorMessages?.schoolType)}
                                    handleChange={handleChangeSchoolType}
                                />
                            )
                        })
                    }
                </RadioButtonGroup>

            </div>

            <div className={"formTableRow_item"}>

                <FieldWithLabel
                    label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.学校名)}
                    requiredType={RequiredTagTypeEnum.必須}
                    width={"160px"}
                    errorMessages={convertErrorMessageVoToArrayStr(syugakusyaErrorMessages?.schoolName)}
                >
                    <TextField
                        value={syugakusya.schoolName}
                        handleBlur={(value) => handleBlurSchoolName(value)}
                        isError={isErrorMessage(syugakusyaErrorMessages?.schoolName)}
                    />
                </FieldWithLabel>

            </div>

            <div className={"formTableRow_item"}>

                <FieldWithLabel
                    label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.学年)}
                    requiredType={RequiredTagTypeEnum.必須}
                    width={"160px"}
                    errorMessages={convertErrorMessageVoToArrayStr(syugakusyaErrorMessages?.gakunen)}
                >
                    <NumberField
                        value={syugakusya.gakunen}
                        isError={isErrorMessage(syugakusyaErrorMessages?.gakunen)}
                        width={"64px"}
                        suffixText={"年"}
                        handleBlur={handleBlurGakunen}
                    />
                </FieldWithLabel>

            </div>

            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.設置区分)}<RequiredTag
                    type={RequiredTagTypeEnum.必須}/>
                </div>
                <RadioButtonGroup
                    errorMessages={convertErrorMessageVoToArrayStr(syugakusyaErrorMessages?.settiKubun)}
                    vertical={true}
                >
                    {
                        Object.entries(SettiKubunEnum).map(([, value]) => {
                            return (
                                <RadioButton
                                    key={value}
                                    value={value}
                                    checked={syugakusya.settiKubun === value}
                                    label={convertEnumNameToStr(settiKubunEnumToName(value))}
                                    isError={isErrorMessage(syugakusyaErrorMessages?.settiKubun)}
                                    handleChange={handleChangeSettiKubun}
                                />
                            )
                        })
                    }
                </RadioButtonGroup>
            </div>

            {
                canInputJugyoryo(syugakusya, props.isRyugakusei) ? (
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.授業料免除状況)}<RequiredTag
                            type={RequiredTagTypeEnum.必須}/>
                        </div>
                        <RadioButtonGroup
                            errorMessages={convertErrorMessageVoToArrayStr(syugakusyaErrorMessages?.jugyoryoMenjoState)}
                            vertical={true}
                        >
                            <RadioButton
                                value={convertEnumNameToStr(jugyoryoMenjoStateEnumToName(JugyoryoMenjoStateEnum.全額))}
                                checked={syugakusya.jugyoryoMenjoState === JugyoryoMenjoStateEnum.全額}
                                label={convertEnumNameToStr(jugyoryoMenjoStateEnumToName(JugyoryoMenjoStateEnum.全額))}
                                isError={isErrorMessage(syugakusyaErrorMessages?.jugyoryoMenjoState)}
                                handleChange={() => handleChangeJugyoryoMenjoState(JugyoryoMenjoStateEnum.全額)}
                            />
                            <RadioButton
                                value={convertEnumNameToStr(jugyoryoMenjoStateEnumToName(JugyoryoMenjoStateEnum.半額))}
                                checked={syugakusya.jugyoryoMenjoState === JugyoryoMenjoStateEnum.半額}
                                label={convertEnumNameToStr(jugyoryoMenjoStateEnumToName(JugyoryoMenjoStateEnum.半額))}
                                isError={isErrorMessage(syugakusyaErrorMessages?.jugyoryoMenjoState)}
                                handleChange={() => handleChangeJugyoryoMenjoState(JugyoryoMenjoStateEnum.半額)}
                            />
                            <RadioButton
                                value={convertEnumNameToStr(jugyoryoMenjoStateEnumToName(JugyoryoMenjoStateEnum.無し))}
                                checked={syugakusya.jugyoryoMenjoState === JugyoryoMenjoStateEnum.無し}
                                label={convertEnumNameToStr(jugyoryoMenjoStateEnumToName(JugyoryoMenjoStateEnum.無し))}
                                isError={isErrorMessage(syugakusyaErrorMessages?.jugyoryoMenjoState)}
                                handleChange={() => handleChangeJugyoryoMenjoState(JugyoryoMenjoStateEnum.無し)}
                            />
                        </RadioButtonGroup>
                    </div>
                ) : <></>
            }


            {
                canInputJugyoryo(syugakusya, props.isRyugakusei) &&
                syugakusya.jugyoryoMenjoState === JugyoryoMenjoStateEnum.半額 ? (
                    <FieldWithLabel
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.半年分の授業料)}
                        requiredType={RequiredTagTypeEnum.必須}
                        errorMessages={convertErrorMessageVoToArrayStr(syugakusyaErrorMessages?.jugyoryoForHalfYear)}
                    >
                        <NumberField
                            value={syugakusya.jugyoryoForHalfYear}
                            handleBlur={(value) => handleBlurJugyoryoForHalfYear(value)}
                            isError={isErrorMessage(syugakusyaErrorMessages?.jugyoryoForHalfYear)}
                            width={"120px"}
                            suffixText={"円"}
                        />
                    </FieldWithLabel>
                ) : <></>
            }

            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.通学区分)}<RequiredTag
                    type={RequiredTagTypeEnum.必須}/>
                </div>
                <RadioButtonGroup
                    errorMessages={convertErrorMessageVoToArrayStr(syugakusyaErrorMessages?.tugakuFromJitaku)}
                    vertical={true}
                >
                    <RadioButton
                        value={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅)}
                        checked={syugakusya.tugakuFromJitaku === true}
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅)}
                        isError={isErrorMessage(syugakusyaErrorMessages?.tugakuFromJitaku)}
                        handleChange={() => handleChangeTugakuFromJitaku(true)}
                    />
                    <RadioButton
                        value={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅外)}
                        checked={syugakusya.tugakuFromJitaku === false}
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅外)}
                        isError={isErrorMessage(syugakusyaErrorMessages?.tugakuFromJitaku)}
                        handleChange={() => handleChangeTugakuFromJitaku(false)}
                    />
                </RadioButtonGroup>

                <DescriptionLinkWrap>
                    <DescriptionLink
                        onClickWordDescriptionLink={props.onClickWordDescriptionLink}
                        type={WordDescriptionTypeEnum.通学区分}
                    />
                </DescriptionLinkWrap>

            </div>

        </FormTableRow>
    )
};
