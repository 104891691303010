import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {SyunyuInfoVo} from "common/models/YosikiInfoModels";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {Button} from "components/atoms/Button";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import {SwSyunyuInfoErrorMessages} from "openapi/models";
import React, {FC, useCallback, useState} from "react";
import {Box} from "@material-ui/core";
import {TextColorsEnum} from "../../../../common/enums_value/ColorsEnum";
import Grid from "@material-ui/core/Grid";
import {OtherSyunyuFieldModal} from "./OtherSyunyuFieldModal";

interface Props {
    isHonnin: boolean
    syunyuInfo: SyunyuInfoVo
    updateSyunyuInfo: (syunyuInfo: SyunyuInfoVo) => void
    updateSyunyuInfoErrorMessages: (errorMessages: SwSyunyuInfoErrorMessages) => void
    syunyuInfoErrorMessages?: SwSyunyuInfoErrorMessages
    handleClearSyogakukinError?: () => void
}

export const OtherSyunyuField: FC<Props> = ({
                                                isHonnin,
                                                syunyuInfo,
                                                updateSyunyuInfo,
                                                updateSyunyuInfoErrorMessages,
                                                syunyuInfoErrorMessages,
                                                handleClearSyogakukinError
                                            }) => {

    const [isModalOpen, updateIsModalOpen] = useState(false)

    const handleOpenModal = useCallback(() => {
        updateIsModalOpen(true)
        updateSyunyuInfoErrorMessages({...syunyuInfoErrorMessages, otherField: undefined})
    }, [updateIsModalOpen, updateSyunyuInfoErrorMessages, syunyuInfoErrorMessages])

    const handleCloseModal = useCallback(() => {
        updateIsModalOpen(false)
    }, [updateIsModalOpen])

    const handleConfirm = useCallback(async (v: SyunyuInfoVo, errorMessages?: SwSyunyuInfoErrorMessages) => {
        updateSyunyuInfo(v)
        updateIsModalOpen(false)
        errorMessages && updateSyunyuInfoErrorMessages(errorMessages)
    }, [updateSyunyuInfo, updateSyunyuInfoErrorMessages])

    return (
        <>
            <Grid container spacing={1} direction={"column"}>
                <Grid item>
                    <Box color={TextColorsEnum.Red} fontWeight={700}>
                        {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.確定申告書又は源泉徴収票に記載されていない収入や所得については下のその他の収入または所得を入力から該当する金額を入力してください)}
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            type={"primary"}
                            label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.その他の収入または所得を入力)}
                            handleClick={handleOpenModal}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <ErrorMessages
                            errorMessages={convertErrorMessageVoToArrayStr(syunyuInfoErrorMessages?.otherField)}
                        />
                    </Box>
                </Grid>
            </Grid>
            <OtherSyunyuFieldModal
                isHonnin={isHonnin}
                syunyuInfo={syunyuInfo}
                openState={isModalOpen}
                handleCloseModal={handleCloseModal}
                handleConfirm={handleConfirm}
                syunyuInfoErrorMessages={syunyuInfoErrorMessages}
                handleClearSyogakukinError={handleClearSyogakukinError}
            />
        </>
    )
}

