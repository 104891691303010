import {createStyles, makeStyles} from "@material-ui/core";
import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {LoginLabelTextEnum, LoginLabelTextEnumToNameJaAndEn} from "common/enums_text/LoginLabelTextEnum";
import {TextLink} from "components/atoms/TextLink";
import {Container} from "components/layouts/Container";
import {LoginContainer} from "components/layouts/LoginContainer";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import React, {FC} from 'react';
import {RouteComponentProps, withRouter} from "react-router";

const _CreateAccountNewStudentsComplete: FC<RouteComponentProps> = () => {

    const classes = useStyles();

    return (
        <>

            <HeaderStudent signedIn={false}/>

            <Container
                maxWidth={'464px'}
                bgColor={"gray"}
                className={classes.container}
            >

                <div className={"container_inner"}>

                    <LoginContainer
                        className={classes.root}
                    >

                        <div className={"login_title"}>{LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.新規アカウント登録)}</div>
                        <div>
                            新規アカウント登録が完了しました。<br/>
                            ログイン画面からログインしてください。
                        </div>
                        <div className={"mt16"}>
                            New account registration has been completed.<br/>
                            Log in to your account from the Login screen.
                        </div>
                        <div className={"login_form"}>
                            <TextLink
                                text={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.ログイン画面へ戻る)}
                                href={URL_PATHS_STUDENT.login}
                            />
                        </div>

                    </LoginContainer>

                </div>

            </Container>

        </>
    )
};

const useStyles = makeStyles(() =>
    createStyles({
        container: {
        },
        root: {
            "& .login_title": {
                textAlign: "center",
                fontSize: 18,
                fontWeight: 700,
                marginBottom: 16,
            },
            "& .login_form": {
                marginTop: 16,
                textAlign: "center"
            }
        },
    })
);

export const CreateAccountNewStudentsComplete = withRouter(_CreateAccountNewStudentsComplete);
