import {FormControlLabel} from "@material-ui/core";
import {default as MuiCheckbox} from "@material-ui/core/Checkbox";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from 'react';
import {trimStrForSpaceBeforeAndAfter} from "common/functions/char_converter/trimStrForSpaceBeforeAndAfter";
import {BgColorsEnum, CheckBoxColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";
import {TextField} from "./TextField";

interface CheckboxProps {
    label?: string;
    value?: string;
    className?: string;
    width?: string;
    otherTextFieldEnabled?: boolean
    textFieldWidth?: string
    isError?: boolean | false

    // form
    checked: boolean;
    valueText?: string;
    disabled?: boolean;
    handleChange?: (value: string) => void;
    handleChangeText?: (value: string) => void
}

export const Checkbox: FC<CheckboxProps> = (props) => {

    const classes = useStyles();

    function handleChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        props.handleChange && props.handleChange(e.currentTarget.value)
    }

    function handleChangeText(value: string) {
        props.handleChangeText && props.handleChangeText(value)
    }

    function handleBlurText() {
        if (props.valueText === undefined) return;
        let value = props.valueText;
        let trimmedSpace = trimStrForSpaceBeforeAndAfter(value);
        props.handleChangeText && props.handleChangeText(trimmedSpace)
    }

    return (

        <div
            className={`${classes.root} ${props.disabled && classes.disabledRoot} ${props.className}`}
        >

            <FormControlLabel
                className={`${classes.root} ${props.className}`}
                style={{
                    width: props.width
                }}
                control={
                    <MuiCheckbox
                        className={`${props.isError ? classes.error : ""}`}
                        checked={props.checked}
                        onChange={handleChange}
                        value={props.value}
                        color={"primary"}
                        disabled={props.disabled}
                    />
                }
                label={props.label}
            />

            {
                (props.otherTextFieldEnabled && props.valueText !== undefined) &&
                <TextField
                    className={"textField"}
                    disabled={props.disabled}
                    value={props.valueText}
                    width={props.textFieldWidth}
                    handleChange={handleChangeText}
                    handleBlur={handleBlurText}
                />
            }

        </div>


    )

};

Checkbox.defaultProps = {
    disabled: false
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center",
        "& .MuiFormControlLabel-root": {
            marginLeft: -6,
            marginRight: 0,
            "& .MuiFormControlLabel-label": {
                color: TextColorsEnum.Black,
                fontSize: 12,
                marginLeft: -1,
                position: "relative",
                top: 1,
            },
            "& .MuiSvgIcon-root": {
                width: 16,
                height: 16,
            },
            "& .MuiCheckbox-root": {
                padding: "6px 4px",
                "&.Mui-checked": {
                    color: CheckBoxColorsEnum.Primary,
                },
            },
        },
    },
    disabledRoot: {
        "& .MuiFormControlLabel-root": {
            "& .MuiFormControlLabel-label": {
                color: TextColorsEnum.Gray,
            },
        },
    },
    error: {
        color: BgColorsEnum.Red,
    }
});