import makeStyles from "@material-ui/core/styles/makeStyles";
import {countYosiErrorMessage, CountYosikiErrorMessageVo} from "common/functions/yosikiInfo/countYosiErrorMessage";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {YosikiInfoModalStoreVo} from "common/models/yosiki/YosikiInfoModalStoreVo";
import {YosikiInfoStoreVo} from "common/models/yosiki/YosikiInfoStoreVo";
import {
    FamilyInfoVo,
    InfoPolicyVo,
    JugyoryoInfoVo,
    NyugakuryoInfoVo,
    SeisekiInfoVo,
    SinseisyaInfoVo,
    SyogakukinInfoVo,
    TokubetuKojoInfoVo,
    YosikiInfoResponseVo
} from "common/models/YosikiInfoModels";
import {Title02} from "components/atoms/titles/Title02";
import {BasicInfoSection} from "components/organisms/yosikiEdit/BasicInfoSection";
import {FamilyInfoSection} from "components/organisms/yosikiEdit/FamilyInfoSection";
import {JugyoryoInfoSection} from "components/organisms/yosikiEdit/JugyoryoInfoSection";
import {YosikiAddSyogaisyaZokugaraModal} from "components/organisms/yosikiEdit/modal/YosikiAddSyogaisyaZokugaraModal";
import {YosikiAddTyokiryoyoZokugaraModal} from "components/organisms/yosikiEdit/modal/YosikiAddTyokiryoyoZokugaraModal";
import {YosikiEditDeleteZokugaraModal} from "components/organisms/yosikiEdit/modal/YosikiEditDeleteZokugaraModal";
import {YosikiEditSelectZokugaraModal} from "components/organisms/yosikiEdit/modal/YosikiEditSelectZokugaraModal";
import {YosikiEditUnsavedAttentionModal} from "components/organisms/yosikiEdit/modal/YosikiEditUnsavedAttentionModal";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {NyugakuryoInfoSection} from "components/organisms/yosikiEdit/NyugakuryoInfoSection";
import {SeisekiInfoSection} from "components/organisms/yosikiEdit/SeisekiInfoInfoSection";
import {SinseisyaInfoSection} from "components/organisms/yosikiEdit/SinseisyaInfoSection";
import {SyogakukinInfoSection} from "components/organisms/yosikiEdit/SyogakukinInfoSection";
import {TokubetuKojoInfoSection} from "components/organisms/yosikiEdit/TokubetuKojoInfoSection";
import {
    SwFamilyInfoErrorMessage,
    SwJugyoryoInfoErrorMessage,
    SwNyugakuryoInfoErrorMessage,
    SwSeisekiInfoErrorMessage,
    SwSinseisyaInfoErrorMessage,
    SwSyogakukinInfoErrorMessage,
    SwTokubetuKojoInfoErrorMessage,
    SwYosikiInfoErrorMessageResponse
} from "openapi/models";
import React, {FC} from "react";
import {TokubetuKojoEnum} from "../../../common/enums/TokubetuKojoEnum";

interface YosikiInfoEditComponentProps {
    className?: string
    yosikiInfo: YosikiInfoStoreVo
    studentInfo: StudentInfoStoreVo
    yosikiInfoModal: YosikiInfoModalStoreVo
    handleClickEditBasicInfoButton: () => void
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
    updateYosikiInfo: (yosikiInfo: YosikiInfoResponseVo) => void
    updateYosikiInfoErrorMessages: (yosikiInfoErrorMessage: SwYosikiInfoErrorMessageResponse) => void
    updateSelectZokugaraModalOpenState: (value: boolean) => void
    updateDeleteZokugaraModalOpenState: (value: boolean) => void
    // updateDeleteSyunyuModalOpenState: (value: boolean) => void
    // updateDeleteSyunyuHonninModalOpenState: (value: boolean) => void
    updateAddSyogaisyaZokugaraModalOpenState: (value: boolean) => void
    updateAddTyokiryoyoZokugaraModalOpenState: (value: boolean) => void
    updateSelectedZokugaraIndex: (value: number) => void
    isDisplayBasicInfoTokubetuKojo: boolean
    handleUnsavedAttentionModalExit: () => void
    handleUnsavedAttentionModalClose: () => void
}

export const YosikiInfoEditComponent: FC<YosikiInfoEditComponentProps> = ({

                                                                              className,
                                                                              yosikiInfo,
                                                                              studentInfo,
                                                                              yosikiInfoModal,
                                                                              handleClickEditBasicInfoButton,
                                                                              onClickWordDescriptionLink,
                                                                              updateYosikiInfo,
                                                                              updateYosikiInfoErrorMessages,
                                                                              updateSelectZokugaraModalOpenState,
                                                                              updateDeleteZokugaraModalOpenState,
                                                                              // updateDeleteSyunyuModalOpenState,
                                                                              // updateDeleteSyunyuHonninModalOpenState,
                                                                              updateAddSyogaisyaZokugaraModalOpenState,
                                                                              updateAddTyokiryoyoZokugaraModalOpenState,
                                                                              updateSelectedZokugaraIndex,
                                                                              isDisplayBasicInfoTokubetuKojo,
                                                                              handleUnsavedAttentionModalExit,
                                                                              handleUnsavedAttentionModalClose,

                                                                          }) => {

    const classes = useStyles();

    if (yosikiInfo.yosikiInfo === undefined) return <></>;
    const infoPolicy: InfoPolicyVo = yosikiInfo.yosikiInfo.infoPolicy;

    // 特別控除のセクションの表示
    const isDisplayTokubetuKojoInfo: boolean | undefined =
        infoPolicy.requireTokubetuKojoInfo?.bosiHusi
        || infoPolicy.requireTokubetuKojoInfo?.syogaisya
        || infoPolicy.requireTokubetuKojoInfo?.tyokiryoyo
        || infoPolicy.requireTokubetuKojoInfo?.bekkyo
        || infoPolicy.requireTokubetuKojoInfo?.saigaihigai;

    const errorCounts: CountYosikiErrorMessageVo = countYosiErrorMessage(yosikiInfo.yosikiInfoErrorMessages);

    // 申請者情報
    function updateSinseisyaInfo(part: Partial<SinseisyaInfoVo>) {
        if (yosikiInfo.yosikiInfo === undefined) return;
        let sinseisyaInfo = yosikiInfo.yosikiInfo.sinseisyaInfo;
        const newYosikiInfo: YosikiInfoResponseVo = {
            ...yosikiInfo.yosikiInfo,
            sinseisyaInfo: {
                ...sinseisyaInfo,
                ...part
            }
        };
        updateYosikiInfo(newYosikiInfo)
    }

    function updateSinseisyaInfoErrorMessage(part: Partial<SwSinseisyaInfoErrorMessage>) {
        let sinseisyaInfoErrorMessage = yosikiInfo.yosikiInfoErrorMessages.sinseisyaInfoErrorMessage;
        const newYosikiInfoError: SwYosikiInfoErrorMessageResponse = {
            ...yosikiInfo.yosikiInfoErrorMessages,
            sinseisyaInfoErrorMessage: {
                ...sinseisyaInfoErrorMessage,
                ...part
            }
        };
        updateYosikiInfoErrorMessages(newYosikiInfoError)
    }


    // 成績情報
    function updateSeisekiInfo(part: Partial<SeisekiInfoVo>) {
        if (yosikiInfo.yosikiInfo === undefined) return;
        const newYosikiInfo: YosikiInfoResponseVo = {
            ...yosikiInfo.yosikiInfo,
            seisekiInfo: {
                ...yosikiInfo.yosikiInfo.seisekiInfo,
                ...part
            }
        };
        updateYosikiInfo(newYosikiInfo)
    }

    function updateSeisekiInfoErrorMessage(part: Partial<SwSeisekiInfoErrorMessage>) {
        let seisekiInfoErrorMessage = yosikiInfo.yosikiInfoErrorMessages.seisekiInfoErrorMessage;
        const newYosikiInfoError: SwYosikiInfoErrorMessageResponse = {
            ...yosikiInfo.yosikiInfoErrorMessages,
            seisekiInfoErrorMessage: {
                ...seisekiInfoErrorMessage,
                ...part
            }
        };
        updateYosikiInfoErrorMessages(newYosikiInfoError)
    }

    // 奨学金情報
    function updateSyogakukinInfo(part: Partial<SyogakukinInfoVo>) {
        if (yosikiInfo.yosikiInfo === undefined) return;
        let syogakukinInfo = yosikiInfo.yosikiInfo.syogakukinInfo;
        const newYosikiInfo: YosikiInfoResponseVo = {
            ...yosikiInfo.yosikiInfo,
            syogakukinInfo: {
                ...syogakukinInfo,
                ...part
            }
        };
        updateYosikiInfo(newYosikiInfo)
    }

    function updateSyogakukinInfoErrorMessage(part: Partial<SwSyogakukinInfoErrorMessage>) {
        let syogakukinInfoErrorMessage = yosikiInfo.yosikiInfoErrorMessages.syogakukinInfoErrorMessage;
        const newYosikiInfoError: SwYosikiInfoErrorMessageResponse = {
            ...yosikiInfo.yosikiInfoErrorMessages,
            syogakukinInfoErrorMessage: {
                ...syogakukinInfoErrorMessage,
                ...part
            }
        };
        updateYosikiInfoErrorMessages(newYosikiInfoError)
    }

    // 家族構成
    function updateFamilyInfo(part: Partial<FamilyInfoVo>) {
        if (yosikiInfo.yosikiInfo === undefined) return;
        let familyInfo = yosikiInfo.yosikiInfo.familyInfo;
        const newYosikiInfo: YosikiInfoResponseVo = {
            ...yosikiInfo.yosikiInfo,
            familyInfo: {
                ...familyInfo,
                ...part
            }
        };
        updateYosikiInfo(newYosikiInfo)
    }

    function updateFamilyInfoErrorMessage(part: Partial<SwFamilyInfoErrorMessage>) {
        let familyInfoErrorMessage = yosikiInfo.yosikiInfoErrorMessages.familyInfoErrorMessage;
        const newYosikiInfoError: SwYosikiInfoErrorMessageResponse = {
            ...yosikiInfo.yosikiInfoErrorMessages,
            familyInfoErrorMessage: {
                ...familyInfoErrorMessage,
                ...part,
            }
        };
        updateYosikiInfoErrorMessages(newYosikiInfoError)
    }

    // 入学料免除・徴収猶予
    function updateNyugakuryoInfo(part: Partial<NyugakuryoInfoVo>) {
        if (yosikiInfo.yosikiInfo === undefined) return;
        const newYosikiInfo: YosikiInfoResponseVo = {
            ...yosikiInfo.yosikiInfo,
            nyugakuryoInfo: {
                ...yosikiInfo.yosikiInfo.nyugakuryoInfo,
                ...part
            }
        };
        updateYosikiInfo(newYosikiInfo)
    }

    function updateNyugakuryoInfoErrorMessage(part: Partial<SwNyugakuryoInfoErrorMessage>) {
        const newYosikiInfoError: SwYosikiInfoErrorMessageResponse = {
            ...yosikiInfo.yosikiInfoErrorMessages,
            nyugakuryoInfoErrorMessage: {
                ...yosikiInfo.yosikiInfoErrorMessages.nyugakuryoInfoErrorMessage,
                ...part
            }
        };
        updateYosikiInfoErrorMessages(newYosikiInfoError)
    }

    // 授業料免除・徴収猶予
    function updateJugyoryoInfo(part: Partial<JugyoryoInfoVo>) {
        if (yosikiInfo.yosikiInfo === undefined) return;
        const newYosikiInfo: YosikiInfoResponseVo = {
            ...yosikiInfo.yosikiInfo,
            jugyoryoInfo: {
                ...yosikiInfo.yosikiInfo.jugyoryoInfo,
                ...part
            }
        };
        updateYosikiInfo(newYosikiInfo)
    }

    function updateJugyoryoInfoErrorMessage(part: Partial<SwJugyoryoInfoErrorMessage>) {
        const newYosikiInfoError: SwYosikiInfoErrorMessageResponse = {
            ...yosikiInfo.yosikiInfoErrorMessages,
            jugyoryoInfoErrorMessage: {
                ...yosikiInfo.yosikiInfoErrorMessages.jugyoryoInfoErrorMessage,
                ...part
            }
        };
        updateYosikiInfoErrorMessages(newYosikiInfoError)
    }

    // 特別控除
    function updateTokubetuKojoInfo(part: Partial<TokubetuKojoInfoVo>) {
        if (yosikiInfo.yosikiInfo === undefined) return;
        let tokubetuKojoInfo = yosikiInfo.yosikiInfo.tokubetuKojoInfo;
        const newYosikiInfo: YosikiInfoResponseVo = {
            ...yosikiInfo.yosikiInfo,
            tokubetuKojoInfo: {
                ...tokubetuKojoInfo,
                ...part
            }
        };
        updateYosikiInfo(newYosikiInfo)
    }

    function updateTokubetuKojoInfoErrorMessage(part: Partial<SwTokubetuKojoInfoErrorMessage>) {
        let tokubetuKojoInfoErrorMessage = yosikiInfo.yosikiInfoErrorMessages.tokubetuKojoInfoErrorMessage;
        const newYosikiInfoError: SwYosikiInfoErrorMessageResponse = {
            ...yosikiInfo.yosikiInfoErrorMessages,
            tokubetuKojoInfoErrorMessage: {
                ...tokubetuKojoInfoErrorMessage,
                ...part
            }
        };
        updateYosikiInfoErrorMessages(newYosikiInfoError)
    }

    return (

        <div className={`${classes.root} ${className}`}>

            <YosikiEditUnsavedAttentionModal
                openState={yosikiInfoModal.backButtonUnsavedAttentionModalOpenState}
                handleClickExit={handleUnsavedAttentionModalExit}
                handleClickClose={handleUnsavedAttentionModalClose}
            />

            <YosikiEditSelectZokugaraModal
                openState={yosikiInfoModal.selectZokugaraModalOpenState}
                studentNumber={studentInfo.studentNumber}
                updateSelectZokugaraModalOpenState={(value) => updateSelectZokugaraModalOpenState(value)}
                familyInfo={yosikiInfo.yosikiInfo.familyInfo}
                updateFamilyInfo={updateFamilyInfo}
            />

            <YosikiEditDeleteZokugaraModal
                openState={yosikiInfoModal.deleteZokugaraModalOpenState}
                selectedZokugaraIndex={yosikiInfoModal.selectedZokugaraIndex}
                familyInfo={yosikiInfo.yosikiInfo.familyInfo}
                updateFamilyInfo={updateFamilyInfo}
                updateDeleteZokugaraModalOpenState={(value) => updateDeleteZokugaraModalOpenState(value)}
                updateFamilyInfoErrorMessage={updateFamilyInfoErrorMessage}
                familyInfoErrorMessage={yosikiInfo.yosikiInfoErrorMessages.familyInfoErrorMessage}
            />

            {/*<YosikiEditDeleteSyunyuModal*/}
            {/*    openState={yosikiInfoModal.deleteSyunyuModalOpenState}*/}
            {/*    studentNumber={studentInfo.studentNumber}*/}
            {/*    familyInfo={yosikiInfo.yosikiInfo.familyInfo}*/}
            {/*    updateFamilyInfo={updateFamilyInfo}*/}
            {/*    updateDeleteSyunyuModalOpenState={(value) => updateDeleteSyunyuModalOpenState(value)}*/}
            {/*    deleteSyunyuModalForm={yosikiInfoModal.deleteSyunyuModalForm}*/}
            {/*    updateFamilyInfoErrorMessage={updateFamilyInfoErrorMessage}*/}
            {/*    familyInfoErrorMessage={yosikiInfo.yosikiInfoErrorMessages.familyInfoErrorMessage}*/}
            {/*/>*/}

            {/*<YosikiEditDeleteSyunyuHonninModal*/}
            {/*    openState={yosikiInfoModal.deleteSyunyuHonninModalOpenState}*/}
            {/*    studentNumber={studentInfo.studentNumber}*/}
            {/*    familyInfo={yosikiInfo.yosikiInfo.familyInfo}*/}
            {/*    updateFamilyInfo={updateFamilyInfo}*/}
            {/*    updateDeleteSyunyuHonninModalOpenState={(value) => updateDeleteSyunyuHonninModalOpenState(value)}*/}
            {/*    deleteSyunyuHonninModalForm={yosikiInfoModal.deleteSyunyuHonninModalForm}*/}
            {/*    updateFamilyInfoErrorMessage={updateFamilyInfoErrorMessage}*/}
            {/*    familyInfoErrorMessage={yosikiInfo.yosikiInfoErrorMessages.familyInfoErrorMessage}*/}
            {/*/>*/}

            <YosikiAddSyogaisyaZokugaraModal
                openState={yosikiInfoModal.addSyogaisyaZokugaraModalOpenState}
                studentNumber={studentInfo.studentNumber}
                tokubetuKojoInfo={yosikiInfo.yosikiInfo.tokubetuKojoInfo}
                updateTokubetuKojoInfo={updateTokubetuKojoInfo}
                familyInfo={yosikiInfo.yosikiInfo.familyInfo}
                updateAddSyogaisyaZokugaraModalOpenState={(value) => updateAddSyogaisyaZokugaraModalOpenState(value)}
            />

            <YosikiAddTyokiryoyoZokugaraModal
                openState={yosikiInfoModal.addTyokiryoyoZokugaraModalOpenState}
                studentNumber={studentInfo.studentNumber}
                tokubetuKojoInfo={yosikiInfo.yosikiInfo.tokubetuKojoInfo}
                updateTokubetuKojoInfo={updateTokubetuKojoInfo}
                familyInfo={yosikiInfo.yosikiInfo.familyInfo}
                updateAddTyokiryoyoZokugaraModalOpenState={(value) => updateAddTyokiryoyoZokugaraModalOpenState(value)}
            />

            <Title02
                title={"様式1入力画面 / Form 1 Entry Screen"}
                className={classes.title}
            />

            <div className={classes.sectionWrap}>

                <BasicInfoSection
                    className={"section"}
                    basicInfo={yosikiInfo.yosikiInfo.basicInfo}
                    handleClickEditBasicInfoButton={handleClickEditBasicInfoButton}
                    isDisplayBasicInfoTokubetuKojo={isDisplayBasicInfoTokubetuKojo}
                />

                <SinseisyaInfoSection
                    className={"section"}
                    requireSinseisyaInfo={yosikiInfo.yosikiInfo.infoPolicy.requireSinseisyaInfo}
                    studentInfo={studentInfo}
                    sinseisyaInfo={yosikiInfo.yosikiInfo.sinseisyaInfo}
                    sinseisyaInfoErrorMessage={yosikiInfo.yosikiInfoErrorMessages.sinseisyaInfoErrorMessage}
                    updateSinseisyaInfo={updateSinseisyaInfo}
                    updateSinseisyaInfoErrorMessage={updateSinseisyaInfoErrorMessage}
                    errorCount={errorCounts.sinseisyaErrorCount}
                    onClickWordDescriptionLink={onClickWordDescriptionLink}
                />

                <SeisekiInfoSection
                    className={"section"}
                    seisekiInfo={yosikiInfo.yosikiInfo.seisekiInfo}
                    updateSeisekiInfo={updateSeisekiInfo}
                    updateSeisekiInfoErrorMessage={updateSeisekiInfoErrorMessage}
                    errorCount={errorCounts.seisekiInfoErrorCount}
                />

                <SyogakukinInfoSection
                    className={"section"}
                    syogakukins={yosikiInfo.yosikiInfo.syogakukinInfo.syogakukins}
                    syogakukinErrorMessages={yosikiInfo.yosikiInfoErrorMessages.syogakukinInfoErrorMessage.syogakukins || []}
                    updateSyogakukinInfo={updateSyogakukinInfo}
                    updateSyogakukinInfoErrorMessage={updateSyogakukinInfoErrorMessage}
                    errorCount={errorCounts.syogakukinErrorCount}
                    onClickWordDescriptionLink={onClickWordDescriptionLink}
                />

                <FamilyInfoSection
                    className={"section"}
                    familyInfo={yosikiInfo.yosikiInfo.familyInfo}
                    familyInfoErrorMessage={yosikiInfo.yosikiInfoErrorMessages.familyInfoErrorMessage}
                    basicInfo={yosikiInfo.yosikiInfo.basicInfo}
                    commonYosikiMasters={yosikiInfo.commonYosikiMasters}
                    updateFamilyInfo={updateFamilyInfo}
                    updateFamilyInfoErrorMessage={updateFamilyInfoErrorMessage}
                    updateDeleteZokugaraModalOpenState={(value) => updateDeleteZokugaraModalOpenState(value)}
                    updateSelectedZokugaraIndex={(value) => updateSelectedZokugaraIndex(value)}
                    updateSelectZokugaraModalOpenState={(value) => updateSelectZokugaraModalOpenState(value)}
                    errorCount={errorCounts.familyErrorCount}
                    onClickWordDescriptionLink={onClickWordDescriptionLink}
                    isGakubu={studentInfo.isGakubu}
                    isDokuritu={yosikiInfo.yosikiInfo.basicInfo.tokubetuKojo.includes(TokubetuKojoEnum.独立生計者)}
                    isRyugakusei={studentInfo.isRyugakusei}
                />

                {
                    yosikiInfo.yosikiInfo.infoPolicy.requireNyugakuryoInfo &&
                    <NyugakuryoInfoSection
                        className={"section"}
                        nyugakuryoInfo={yosikiInfo.yosikiInfo.nyugakuryoInfo}
                        nyugakuryoInfoErrorMessage={yosikiInfo.yosikiInfoErrorMessages.nyugakuryoInfoErrorMessage}
                        updateNyugakuryoInfo={updateNyugakuryoInfo}
                        updateNyugakuryoInfoErrorMessage={updateNyugakuryoInfoErrorMessage}
                        errorCount={errorCounts.nyugakuryoErrorCount}
                    />
                }

                {
                    yosikiInfo.yosikiInfo.infoPolicy.requireJugyoryoInfo &&
                    <JugyoryoInfoSection
                        className={"section"}
                        jugyoryoInfo={yosikiInfo.yosikiInfo.jugyoryoInfo}
                        jugyoryoInfoErrorMessage={yosikiInfo.yosikiInfoErrorMessages.jugyoryoInfoErrorMessage}
                        updateJugyoryoInfo={updateJugyoryoInfo}
                        updateJugyoryoInfoErrorMessage={updateJugyoryoInfoErrorMessage}
                        errorCount={errorCounts.jugyoryoErrorCount}
                    />
                }

                {/*{*/}
                {/*    yosikiInfo.yosikiInfo.infoPolicy.requireAyosiki1Info &&*/}
                {/*    <AYosiki1InfoSection*/}
                {/*        className={"section"}*/}
                {/*        aYosiki1Info={yosikiInfo.yosikiInfo.aYosiki1Info}*/}
                {/*        aYosiki1InfoErrorMessage={yosikiInfo.yosikiInfoErrorMessages.aYosiki1InfoErrorMessage}*/}
                {/*        updateAYosiki1Info={updateAYosiki1Info}*/}
                {/*        updateAYosiki1InfoErrorMessage={updateAYosiki1InfoErrorMessage}*/}
                {/*        errorCount={errorCounts.aYosiki1ErrorCount}*/}
                {/*    />*/}
                {/*}*/}

                {
                    isDisplayTokubetuKojoInfo ?
                        <TokubetuKojoInfoSection
                            className={"section"}
                            requireTokubetuKojoInfo={yosikiInfo.yosikiInfo.infoPolicy.requireTokubetuKojoInfo}
                            tokubetuKojoInfo={yosikiInfo.yosikiInfo.tokubetuKojoInfo}
                            tokubetuKojoInfoErrorMessage={yosikiInfo.yosikiInfoErrorMessages.tokubetuKojoInfoErrorMessage}
                            updateTokubetuKojoInfo={updateTokubetuKojoInfo}
                            updateTokubetuKojoInfoErrorMessage={updateTokubetuKojoInfoErrorMessage}
                            updateAddSyogaisyaZokugaraModalOpenState={(value) => updateAddSyogaisyaZokugaraModalOpenState(value)}
                            updateAddTyokiryoyoZokugaraModalOpenState={(value) => updateAddTyokiryoyoZokugaraModalOpenState(value)}
                            errorCount={errorCounts.tokubetuKojoErrorCount}
                            onClickWordDescriptionLink={onClickWordDescriptionLink}
                        />
                        : null
                }

            </div>

        </div>

    )
};

const useStyles = makeStyles({
        root: {},
        sectionWrap: {
            "& .section": {
                marginTop: 32,
                "& .section_table": {
                    marginTop: 8
                },
                "& .section_button": {
                    marginTop: 8
                }
            },
        },
        title: {
            marginBottom: 24
        },
    }
);

