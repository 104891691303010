import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

interface DescriptionLinkWrapProps {
    mT?: number
}

export const DescriptionLinkWrap: React.FC<DescriptionLinkWrapProps> = (props) => {

    const classes = useStyles();

    return (
        <div style={{marginTop: props.mT ? props.mT : 8}} className={classes.root}>
            {props.children}
        </div>
    )
};

const useStyles = makeStyles({
        root: {
            "& > *:not(:first-child)": {
                marginTop: 4
            }
        },
    }
);

