// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum BeturiTypeEnum {
    死別 = "E死別",
    生別 = "E生別",
}

export function beturiTypeEnumToName(e: BeturiTypeEnum): { ja: string, en: string } {
    switch (e) {
        case BeturiTypeEnum.死別: return { ja: "死別", en: "Death" };
        case BeturiTypeEnum.生別: return { ja: "生別", en: "Divorce" };
    }
}