import {createStyles} from "@material-ui/core";
import MuiContainer from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import React, {FC} from 'react';
import {BgColorsEnum, ContentBackColorsEnum} from "../../common/enums_value/ColorsEnum";

interface ContainerProps {
    maxWidth?: string
    minWidth?: string
    bgColor?: "white" | "gray";
    className?: string;
}

export const Container: FC<ContainerProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div
            className={`${classes.root} ${xs && classes.xsRoot} ${props.className} ${props.bgColor === "gray" ? classes.gray : ""}`}>
            <MuiContainer style={{maxWidth: props.maxWidth, minWidth: xs ? "inherit" : props.minWidth}} fixed={true}>
                {props.children}
            </MuiContainer>
        </div>
    );
};

Container.defaultProps = {
    maxWidth: "1280px",
    bgColor: "white",
};

const useStyles = makeStyles((props: ContainerProps) => createStyles({
    root: {
        backgroundColor: BgColorsEnum.White,
        minHeight: "100vh", // headerの高さを引く
        boxSizing: "border-box",
        paddingBottom: 64,
        padding: "40px 0",
    },
    xsRoot: {
        paddingTop: 72,
    },
    gray: {
        backgroundColor: ContentBackColorsEnum.Gray,
    },
}));