import {FamilyInfoHonninVo} from "common/models/YosikiInfoModels";
import {YosikiMastersVo} from "common/models/YosikiMastersVo";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {FamilyInfoLabelTextEnum, FamilyInfoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoLabelTextEnum";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {FC} from "react";
import {SyunyuContentsDetail} from "./SyunyuContentsDetail";
import {calcTotalAmounts} from "../../../../common/functions/yosikiInfo/calcTotalAmounts";

interface FamilyInfoSectionSyunyuHoninDetailProps {
    honnin: FamilyInfoHonninVo
    commonYosikiMasters: YosikiMastersVo
}

export const FamilyInfoSectionSyunyuHoninDetail: FC<FamilyInfoSectionSyunyuHoninDetailProps> = ({
                                                                                                    honnin,
                                                                                                    commonYosikiMasters
                                                                                                }) => {

    if (commonYosikiMasters === undefined) return <></>;

    // 計算
    const totalAmounts = calcTotalAmounts(honnin?.syunyuInfo, commonYosikiMasters?.syunyuKubun, honnin?.kakuteisinkokuAriNasi)

    return (
        <FormTableRow
            label={FamilyInfoLabelTextEnumToNameJaAndEn(FamilyInfoLabelTextEnum.本人)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.収入または所得がありますか)}
                </div>
                {
                    honnin.syunyuAriNasi === true ? CommonTextEnumToNameJaAndEn(CommonTextEnum.あり) :
                        honnin.syunyuAriNasi === false ? CommonTextEnumToNameJaAndEn(CommonTextEnum.なし) :
                            ""
                }
            </div>
            {
                honnin.syunyuAriNasi ? (
                    <SyunyuContentsDetail
                        syunyuInfo={honnin.syunyuInfo}
                        kakuteisinkokuAriNasi={honnin?.kakuteisinkokuAriNasi}
                        totalAmounts={totalAmounts}
                    />
                ) : null
            }
        </FormTableRow>
    )
};

