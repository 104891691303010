import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";
import { StudentSendCreateAccountLinkNewStudentsApi, PostStudentSendCreateAccountLinkNewStudentsRequest } from "openapi/apis/StudentSendCreateAccountLinkNewStudentsApi";
import { SwStudentSendCreateAccountLinkNewStudentsPostErrorMessage } from "openapi/models/SwStudentSendCreateAccountLinkNewStudentsPostErrorMessage";

export interface SendCreateAccountLinkNewStudentsStoreVo {
    form: SendCreateAccountLinkNewStudentsFormVo
    errorMessages?: SwStudentSendCreateAccountLinkNewStudentsPostErrorMessage
}

export interface SendCreateAccountLinkNewStudentsFormVo {
    email: string
}

class SendCreateAccountLinkNewStudentsStore extends Store<SendCreateAccountLinkNewStudentsStoreVo> {

    private api = new StudentSendCreateAccountLinkNewStudentsApi(apiConfiguration);

    constructor() {
        super({
            form: {
                email: "",
            },
        })
    }

    updateForm(part: Partial<SendCreateAccountLinkNewStudentsFormVo>) {
        const newForm = {
            ...this.currentState.form,
            ...part
        };
        this.dispatch(() => ({form: newForm}));
    }

    updateErrorMessages(errorMessages: Partial<SwStudentSendCreateAccountLinkNewStudentsPostErrorMessage> | undefined) {
        this.dispatch(() => ({errorMessages: {
                ...this.currentState.errorMessages,
                ...errorMessages
            }
        }));
    }

    async postSendCreateAccountLink() {
        if (this.currentState.form.email === "") {
            this.updateErrorMessages({email: [{ja: "メールアドレスが入力されていません。", en: "email has not been entered."}]})
            return;
        }
        const param: PostStudentSendCreateAccountLinkNewStudentsRequest = {
            email: this.currentState.form.email,
        };
        try {
            const res = await this.api.postStudentSendCreateAccountLinkNewStudents(param);
            if (res.isSuccess) {
                window.location.assign(URL_PATHS_STUDENT.send_create_account_link_new_students_complete);
            } else {
                this.updateErrorMessages(res.error)
            }
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const sendCreateAccountLinkNewStudentsStore = new SendCreateAccountLinkNewStudentsStore();
