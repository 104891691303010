/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwSyogaisya
 */
export interface SwSyogaisya {
    /**
     * enum
     * @type {string}
     * @memberof SwSyogaisya
     */
    zokugara: string;
    /**
     * 
     * @type {string}
     * @memberof SwSyogaisya
     */
    zokugaraOther: string;
    /**
     * enum
     * @type {string}
     * @memberof SwSyogaisya
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SwSyogaisya
     */
    typeOther: string;
    /**
     * 
     * @type {string}
     * @memberof SwSyogaisya
     */
    tetyoNumber: string;
}

export function SwSyogaisyaFromJSON(json: any): SwSyogaisya {
    return SwSyogaisyaFromJSONTyped(json, false);
}

export function SwSyogaisyaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyogaisya {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zokugara': json['zokugara'],
        'zokugaraOther': json['zokugaraOther'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'typeOther': json['typeOther'],
        'tetyoNumber': json['tetyoNumber'],
    };
}

export function SwSyogaisyaToJSON(value?: SwSyogaisya | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zokugara': value.zokugara,
        'zokugaraOther': value.zokugaraOther,
        'type': value.type,
        'typeOther': value.typeOther,
        'tetyoNumber': value.tetyoNumber,
    };
}


