/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwStudentRow,
    SwStudentRowFromJSON,
    SwStudentRowFromJSONTyped,
    SwStudentRowToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerStudents
 */
export interface SwOfficerStudents {
    /**
     * 
     * @type {number}
     * @memberof SwOfficerStudents
     */
    totalCount: number;
    /**
     * 
     * @type {Array<SwStudentRow>}
     * @memberof SwOfficerStudents
     */
    students: Array<SwStudentRow>;
}

export function SwOfficerStudentsFromJSON(json: any): SwOfficerStudents {
    return SwOfficerStudentsFromJSONTyped(json, false);
}

export function SwOfficerStudentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerStudents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'students': ((json['students'] as Array<any>).map(SwStudentRowFromJSON)),
    };
}

export function SwOfficerStudentsToJSON(value?: SwOfficerStudents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'students': ((value.students as Array<any>).map(SwStudentRowToJSON)),
    };
}


