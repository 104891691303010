/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20018,
    InlineResponse20018FromJSON,
    InlineResponse20018ToJSON,
} from '../models';

export interface PostStudentSendCreateAccountLinkNewStudentsRequest {
    email: string;
}

/**
 * no description
 */
export class StudentSendCreateAccountLinkNewStudentsApi extends runtime.BaseAPI {

    /**
     * 新入生のアカウント作成のリンクをメール送信
     */
    async postStudentSendCreateAccountLinkNewStudentsRaw(requestParameters: PostStudentSendCreateAccountLinkNewStudentsRequest): Promise<runtime.ApiResponse<InlineResponse20018>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling postStudentSendCreateAccountLinkNewStudents.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/send_create_account_link_new_students/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20018FromJSON(jsonValue));
    }

    /**
     * 新入生のアカウント作成のリンクをメール送信
     */
    async postStudentSendCreateAccountLinkNewStudents(requestParameters: PostStudentSendCreateAccountLinkNewStudentsRequest): Promise<InlineResponse20018> {
        const response = await this.postStudentSendCreateAccountLinkNewStudentsRaw(requestParameters);
        return await response.value();
    }

}
