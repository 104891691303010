import React from 'react';

export const ModalContentSyugakusyaWoNozokuKazokuRan: React.FC = () => {
    return (

        // 説明がややこしく、就学者の説明と重複するので使用しない。
        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                就学者以外の家族(同居・別居を問わず生計を一にする者全員)を記入すること。<br />
                ただし、留学生は在日する家族のみを記入すること。<br />
                就学者は、就学者欄の方に記入すること。<br />
                ただし、各種学校等(例;予備校)の在学者はこの欄に記入すること。
            </div>

            <div className={"modalContent_item"}>
                Enter information on family members other than “students” (all persons who are members of the same financial household, regardless of whether they live in the same or separate residence).
                However, international students should only enter information on family members residing in Japan.<br />
                Information on “students” must be entered in the “Students” field. However, information on “enrollees in various schools” (such as preparatory schools) must be entered in this field.
            </div>

        </div>

    );
};
