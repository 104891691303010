import {makeStyles} from '@material-ui/core/styles';
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {LoginLabelTextEnum, LoginLabelTextEnumToNameJaAndEn} from "common/enums_text/LoginLabelTextEnum";
import {Button} from "components/atoms/Button";
import {TextField} from "components/atoms/forms/TextField";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import {Container} from "components/layouts/Container";
import {LoginContainer} from "components/layouts/LoginContainer";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import React, {useCallback, useEffect} from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import {createAccountNewStudentsStore, CreateAccountNewStudentsStoreVo} from "./stores/CreateAccountNewStudentsStore";
import {YearMonthDay} from "../../../components/molecules/forms/YearMonthDay";
import {BukyokuEnum, bukyokuEnumToName} from "../../../common/enums/BukyokuEnum";
import {Selectbox} from "../../../components/atoms/forms/Selectbox";
import {convertEnumNameToStr} from "../../../common/functions/converter/convertEnumNameToStr";
import {RadioButton} from "../../../components/atoms/forms/RadioButton";
import {RadioButtonGroup} from "../../../components/molecules/forms/RadioButtonGroup";
import {joinArraySwErrorMessage} from "../../../common/functions/converter/joinArraySwErrorMessage";
import { GakubuEnum, gakubuEnumToName } from 'common/enums/GakubuEnum';

const _CreateAccountNewStudents: React.FC<RouteComponentProps> = () => {

    const classes = useStyles();
    const [createAccount, updateCreateAccount] = React.useState<CreateAccountNewStudentsStoreVo>(createAccountNewStudentsStore.currentState);

    useEffect(() => {
        createAccountNewStudentsStore.updateErrorMessages(undefined);
        return createAccountNewStudentsStore.listen(updateCreateAccount)
    }, []);

    function handleClickLoginButton() {
        createAccountNewStudentsStore.postStudentCreateAccount().then()
    }

    function handleBlurJukenNumber(v: string) {
        createAccountNewStudentsStore.updateForm({jukenNumber: v});
        createAccountNewStudentsStore.updateErrorMessages({jukenNumber: undefined})
    }

    function handleBlurName(v: string) {
        createAccountNewStudentsStore.updateForm({name: v});
        createAccountNewStudentsStore.updateErrorMessages({name: undefined})
    }

    function handleBlurNameKana(v: string) {
        createAccountNewStudentsStore.updateForm({nameKana: v});
        createAccountNewStudentsStore.updateErrorMessages({nameKana: undefined})
    }

    function handleBlurTel(v: string) {
        createAccountNewStudentsStore.updateForm({tel: v});
        createAccountNewStudentsStore.updateErrorMessages({tel: undefined})
    }

    function handleBlurBukyokuCode(v: string, name?: string) {
        createAccountNewStudentsStore.updateForm({bukyokuCode: v});
        createAccountNewStudentsStore.updateErrorMessages({bukyokuCode: undefined})
    }

    function handleBlurGakka(v: string) {
        createAccountNewStudentsStore.updateForm({gakka: v});
        createAccountNewStudentsStore.updateErrorMessages({gakka: undefined})
    }

    function handleBlurBirthYear(v: string) {
        createAccountNewStudentsStore.updateForm({birthYear: v});
        createAccountNewStudentsStore.updateErrorMessages({birthYear: undefined})
    }

    function handleBlurBirthMonth(v: string) {
        createAccountNewStudentsStore.updateForm({birthMonth: v});
        createAccountNewStudentsStore.updateErrorMessages({birthMonth: undefined})
    }

    function handleBlurBirthDay(v: string) {
        createAccountNewStudentsStore.updateForm({birthDay: v});
        createAccountNewStudentsStore.updateErrorMessages({birthDay: undefined})
    }

    function handleBlurAge(v: string) {
        createAccountNewStudentsStore.updateForm({age: v});
        createAccountNewStudentsStore.updateErrorMessages({age: undefined})
    }

    const updateIsRyugakusei = useCallback((v: boolean) => {
        createAccountNewStudentsStore.updateForm({isRyugakusei: v});
        createAccountNewStudentsStore.updateErrorMessages({isRyugakusei: undefined})
    }, [])

    const updateIsEijusya = useCallback((v: boolean) => {
        createAccountNewStudentsStore.updateForm({isEijusya: v});
        createAccountNewStudentsStore.updateErrorMessages({isEijusya: undefined})
    }, [])

    function handleBlurPassword(v: string) {
        createAccountNewStudentsStore.updateForm({password: v});
        createAccountNewStudentsStore.updateErrorMessages({password: undefined})
    }

    function handleBlurPasswordConfirm(v: string) {
        createAccountNewStudentsStore.updateForm({passwordComfirm: v});
        createAccountNewStudentsStore.updateErrorMessages({passwordComfirm: undefined})
    }


    let gakubuOptions: Array<React.ReactNode> = [];
    Object.entries(GakubuEnum).forEach(([, value]) => {
        gakubuOptions.push(
            <option
                value={value}
                key={value}
            >
                {convertEnumNameToStr(gakubuEnumToName(value))}
            </option>
        )
    });
        gakubuOptions.unshift(<option></option>)

    return (

        <>

            <HeaderStudent signedIn={false}/>

            <Container
                maxWidth={'464px'}
                bgColor={"gray"}
                className={classes.container}
            >

                <div className={"container_inner"}>

                    <LoginContainer
                        className={classes.root}
                    >

                        <div className={"login_title"}>
                            {LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.新規アカウント登録)}
                        </div>

                        <div className={"login_form"}>

                            <FieldWithLabel
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.受験番号)}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.jukenNumber)}
                            >
                                <TextField
                                    value={createAccount.form.jukenNumber}
                                    handleBlur={handleBlurJukenNumber}
                                    isError={isErrorMessage(createAccount.errorMessages?.jukenNumber)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={"氏名 / Name"}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.name)}
                            >
                                <TextField
                                    value={createAccount.form.name}
                                    handleBlur={handleBlurName}
                                    isError={isErrorMessage(createAccount.errorMessages?.name)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={"氏名フリガナ / Name Furigana"}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.nameKana)}
                            >
                                <TextField
                                    value={createAccount.form.nameKana}
                                    handleBlur={handleBlurNameKana}
                                    isError={isErrorMessage(createAccount.errorMessages?.nameKana)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={"生年月日 / Birthday"}
                            >
                                <YearMonthDay
                                    valueYear={createAccount.form.birthYear}
                                    valueMonth={createAccount.form.birthMonth}
                                    valueDay={createAccount.form.birthDay}
                                    errorMessages={convertErrorMessageVoToArrayStr(
                                        joinArraySwErrorMessage(
                                            [
                                                createAccount.errorMessages?.birthYear,
                                                createAccount.errorMessages?.birthMonth,
                                                createAccount.errorMessages?.birthDay
                                            ]
                                        )
                                    )}
                                    isErrorYear={isErrorMessage(createAccount.errorMessages?.birthYear)}
                                    isErrorMonth={isErrorMessage(createAccount.errorMessages?.birthMonth)}
                                    isErrorDay={isErrorMessage(createAccount.errorMessages?.birthDay)}
                                    handleBlurYear={handleBlurBirthYear}
                                    handleBlurMonth={handleBlurBirthMonth}
                                    handleBlurDay={handleBlurBirthDay}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={"年齢 / Age"}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.age)}
                            >
                                <TextField
                                    value={createAccount.form.age}
                                    handleBlur={handleBlurAge}
                                    isError={isErrorMessage(createAccount.errorMessages?.age)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={"電話番号 / Telephone Number"}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.tel)}
                            >
                                <TextField
                                    value={createAccount.form.tel}
                                    handleBlur={handleBlurTel}
                                    isError={isErrorMessage(createAccount.errorMessages?.tel)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={"学部 / Faculty"}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.bukyokuCode)}
                            >
                                <Selectbox
                                    value={createAccount.form.bukyokuCode}
                                    isError={isErrorMessage(createAccount.errorMessages?.bukyokuCode)}
                                    handleChange={handleBlurBukyokuCode}
                                >
                                    {gakubuOptions}
                                </Selectbox>
                            </FieldWithLabel>

                          {/* TODO: 学科を新しく扱う 選択されている部局によって選択肢をフィルターする */}
                          {/*
                            <FieldWithLabel
                                label={"学科 / Department"}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.gakka)}
                            >
                                <Selectbox
                                    value={createAccount.form.gakka}
                                    isError={isErrorMessage(createAccount.errorMessages?.gakka)}
                                    handleChange={handleBlurGakka}
                                >
                                    {gakubuOptions}
                                </Selectbox>
                            </FieldWithLabel>
                            */}
                            {/* 2024/07/09 そもそも現状単なる文字列として持っており表示にしか使ってないので文字列入力で良いのでは?? */}
                            <FieldWithLabel
                                label={"学科 / Department"}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.gakka)}
                            >
                                <TextField
                                    value={createAccount.form.gakka}
                                    handleBlur={handleBlurGakka}
                                    isError={isErrorMessage(createAccount.errorMessages?.gakka)}
                                />
                            </FieldWithLabel>


                            <FieldWithLabel
                                label={"留学生ですか？ / Are you an international student?"}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.isRyugakusei)}
                            >
                                <RadioButtonGroup
                                    vertical={true}
                                >
                                    <RadioButton
                                        value={""}
                                        checked={createAccount.form?.isRyugakusei === true}
                                        label={CommonTextEnum.はい}
                                        isError={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.isRyugakusei).length !== 0}
                                        handleChange={() => {
                                            updateIsRyugakusei(true)
                                        }}
                                    />
                                    <RadioButton
                                        value={""}
                                        checked={createAccount.form?.isRyugakusei === false}
                                        label={CommonTextEnum.いいえ}
                                        isError={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.isRyugakusei).length !== 0}
                                        handleChange={() => {
                                            updateIsRyugakusei(false)
                                        }}
                                    />
                                </RadioButtonGroup>
                            </FieldWithLabel>

                            {
                                createAccount.form?.isRyugakusei ?
                                    <FieldWithLabel
                                        label={"永住者ですか？ / Are you a permanent resident?"}
                                        errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.isEijusya)}
                                    >
                                        <RadioButtonGroup
                                            vertical={true}
                                        >
                                            <RadioButton
                                                value={""}
                                                checked={createAccount.form?.isEijusya === true}
                                                label={CommonTextEnum.はい}
                                                isError={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.isEijusya).length !== 0}
                                                handleChange={() => {
                                                    updateIsEijusya(true)
                                                }}
                                            />
                                            <RadioButton
                                                value={""}
                                                checked={createAccount.form?.isEijusya === false}
                                                label={CommonTextEnum.いいえ}
                                                isError={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.isEijusya).length !== 0}
                                                handleChange={() => {
                                                    updateIsEijusya(false)
                                                }}
                                            />
                                        </RadioButtonGroup>
                                    </FieldWithLabel> : null
                            }


                            <FieldWithLabel
                                label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード)}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.password)}
                            >
                                <TextField
                                    value={createAccount.form.password}
                                    type={'password'}
                                    handleBlur={handleBlurPassword}
                                    isError={isErrorMessage(createAccount.errorMessages?.password)}
                                    helperText={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード組み合わせ)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード確認)}
                                errorMessages={convertErrorMessageVoToArrayStr(createAccount.errorMessages?.passwordComfirm)}
                            >
                                <TextField
                                    value={createAccount.form.passwordComfirm}
                                    type={'password'}
                                    handleBlur={handleBlurPasswordConfirm}
                                    isError={isErrorMessage(createAccount.errorMessages?.passwordComfirm)}
                                />
                            </FieldWithLabel>

                            <ErrorMessages
                                errorMessages={
                                    convertErrorMessageVoToArrayStr(
                                        joinArraySwErrorMessage([
                                            createAccount.errorMessages?.base,
                                            createAccount.errorMessages?.token,
                                        ])
                                    )
                                }
                            />

                            <div>
                                <Button
                                    type={"primary"}
                                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                                    width={"100%"}
                                    handleClick={handleClickLoginButton}
                                />
                            </div>

                        </div>

                    </LoginContainer>

                </div>

            </Container>

        </>

    );
};

export const CreateAccountNewStudents = withRouter(_CreateAccountNewStudents);

const useStyles = makeStyles({
    container: {
        "& .container_inner": {
            display: "flex"
        }
    },
    root: {
        "& .login_title": {
            textAlign: "center",
            fontSize: 18,
            fontWeight: 700,
            marginBottom: 16,
        },
        "& .login_form": {
            "& > *": {
                marginTop: 16,
                "&:first-child": {
                    marginTop: 0
                }
            },
            "& .password": {
                textAlign: "center"
            }
        }
    },
    errorMessages: {}
});
