import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {Container} from "components/layouts/Container";
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AYosikiFormVo} from "../../../common/models/AYosikiFormModels";
import {FollowingBottomNavigation} from "../utilities/FollowingBottomNavigation";
import {Button} from "../../atoms/Button";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "../../../common/enums_text/CommonTextEnum";
import {SwAYosikiFormErrorMessageResponse} from "../../../openapi";
import lodash from "lodash";
import {FormComponent} from "./FormComponent";

interface Props {
    studentInfo: StudentInfoStoreVo
    aYosikiForm?: AYosikiFormVo
    aYosikiFormErrorMessage?: SwAYosikiFormErrorMessageResponse
    handleClickBackButton: () => void
    handleClickSave: (formValues?: AYosikiFormVo) => void
    handleClickKakutei: () => void
    handleClickKakuteiKaijo: () => void
}

export const AYosikiForm: React.FC<Props> = ({
                                                 aYosikiForm,
                                                 aYosikiFormErrorMessage,
                                                 studentInfo,
                                                 handleClickBackButton,
                                                 handleClickSave,
                                                 handleClickKakutei,
                                                 handleClickKakuteiKaijo,
                                             }) => {

    // NOTE: 入力用のフォーム情報
    const [
        formValues,
        updateFormValue
    ] = useState<AYosikiFormVo>()

    // NOTE: フォームのエラー情報
    const [
        errorMessages,
        updateErrorMessages
    ] = useState<SwAYosikiFormErrorMessageResponse>()

    // NOTE: storeのデータを編集使用せずにstateを作成して処理する。
    useEffect(() => {
        // NOTE: 参照しないようにオブジェクトをclone
        const copiedAYosikiForm = lodash.cloneDeep(aYosikiForm) as AYosikiFormVo;
        const copiedErrorMessages = lodash.cloneDeep(aYosikiFormErrorMessage) as SwAYosikiFormErrorMessageResponse;
        // NOTE: 初期化
        updateFormValue(copiedAYosikiForm)
        updateErrorMessages(copiedErrorMessages)
    }, [
        aYosikiForm,
        aYosikiFormErrorMessage,
        updateFormValue,
        updateErrorMessages
    ])

    const isFormChanged = useMemo(() => {
        return !lodash.isEqual(formValues, aYosikiForm)
    }, [formValues, aYosikiForm])

    const updates = useCallback((formValues: AYosikiFormVo, errorMessages?: SwAYosikiFormErrorMessageResponse) => {
        updateFormValue({...formValues})
        updateErrorMessages({...errorMessages})
    }, [updateFormValue, updateErrorMessages])

    if(formValues === undefined) return <></>

    return (

        <>


            <Container
                maxWidth={'880px'}
            >

                <FormComponent
                    formValues={formValues}
                    errorMessages={errorMessages}
                    updates={updates}
                    isKakutei={studentInfo.isAYosikiKakutei}
                />

            </Container>

            <FollowingBottomNavigation>

                <Button
                    className={"search_row_button"}
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.戻る)}
                    width={"104px"}
                    handleClick={handleClickBackButton}
                />

                {
                    // NOTE: 判定前
                    studentInfo.isAYosikiKakutei === false && (
                        <>
                            <Button
                                className={"search_row_button"}
                                type={"primary"}
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.保存)}
                                width={"104px"}
                                handleClick={() => handleClickSave(formValues)}
                            />
                            <Button
                                className={"search_row_button"}
                                type={"secondary"}
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                                handleClick={handleClickKakutei}
                                disabled={isFormChanged}
                            />
                        </>
                    )
                }

                {
                    // NOTE: 判定後
                    studentInfo.isAYosikiKakutei === true && (
                        <>
                            <Button
                                className={"search_row_button"}
                                type={"secondary"}
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定解除)}
                                handleClick={() => handleClickKakuteiKaijo()}
                            />
                        </>
                    )
                }


            </FollowingBottomNavigation>

        </>

    );
};


