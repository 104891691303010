/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAddressErrorMessage
 */
export interface SwAddressErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAddressErrorMessage
     */
    zipcode?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAddressErrorMessage
     */
    address?: Array<SwErrorMessage>;
}

export function SwAddressErrorMessageFromJSON(json: any): SwAddressErrorMessage {
    return SwAddressErrorMessageFromJSONTyped(json, false);
}

export function SwAddressErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAddressErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zipcode': !exists(json, 'zipcode') ? undefined : ((json['zipcode'] as Array<any>).map(SwErrorMessageFromJSON)),
        'address': !exists(json, 'address') ? undefined : ((json['address'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwAddressErrorMessageToJSON(value?: SwAddressErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zipcode': value.zipcode === undefined ? undefined : ((value.zipcode as Array<any>).map(SwErrorMessageToJSON)),
        'address': value.address === undefined ? undefined : ((value.address as Array<any>).map(SwErrorMessageToJSON)),
    };
}


