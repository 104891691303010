import {SwBasicInfoErrorMessageResponse} from "openapi/models";
import {SinseiTypeVo} from "pages/officer/yosiki/stores/YosikiBasicInfoStore";

export interface YosikiBasicInfoStoreVo {
    basicInfoForm?: YosikiBasicInfoFromVo;
    basicInfoErrorMessage: SwBasicInfoErrorMessageResponse;
}

export interface YosikiBasicInfoFromVo {
    sinseiType: SinseiTypeVo;
    nationality?: string;
    tokubetuKojo?: Array<string>;
}

export const INIT_BASIC_INFO_ERROR_MESSAGE = {
    sinseiType: undefined,
    nationality: undefined,
    aYosiki1SinseiState: undefined,
    jasso: undefined,
    transferState: undefined,
    tokubetuKojo: undefined,
};

