import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum FamilyInfoLabelTextEnum {
    本人 = "本人",
}

export function FamilyInfoLabelTextEnumToNameJaAndEn(e: FamilyInfoLabelTextEnum): string {
    switch (e) {
        case FamilyInfoLabelTextEnum.本人:
            return convertEnumNameToStr({ja: "本人", en: "Applicant"});
        default :
            return ""
    }
}