import {useMediaQuery} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {joinArraySwErrorMessage} from "common/functions/converter/joinArraySwErrorMessage";
import {ErrorMessageVo} from "common/models/ErrorMessageVo";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {InfoPolicyRequireSinseisyaInfoVo, SinseisyaInfoVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {SinseisyaInfoLabelTextEnum, SinseisyaInfoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SinseisyaInfoLabelTextEnum";
import {SinseisyaInfoTextEnum, SinseisyaInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SinseisyaInfoTextEnum";
import {NumberField} from "components/atoms/forms/NumberField";
import {RadioButton} from "components/atoms/forms/RadioButton";
import {TelField} from "components/atoms/forms/TelField";
import {TextField} from "components/atoms/forms/TextField";
import {DescriptionLink} from "components/atoms/DescriptionLink";
import {ErrorCountMessage} from "components/atoms/ErrorCountMessage";
import {Title04} from "components/atoms/titles/Title04";
import {AddressField} from "components/molecules/forms/AddressField";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {RadioButtonGroup} from "components/molecules/forms/RadioButtonGroup";
import {YearMonth} from "components/molecules/forms/YearMonth";
import {YearMonthDay} from "components/molecules/forms/YearMonthDay";
import {DescriptionLinkWrap} from "components/molecules/utilities/DescriptionLinkWrap";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {SwSinseisyaInfoErrorMessage} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface SinseisyaInfoSectionProps {
    className?: string
    studentInfo: StudentInfoStoreVo
    sinseisyaInfo: SinseisyaInfoVo
    requireSinseisyaInfo: InfoPolicyRequireSinseisyaInfoVo;
    sinseisyaInfoErrorMessage: SwSinseisyaInfoErrorMessage
    updateSinseisyaInfo: (part: Partial<SinseisyaInfoVo>) => void
    updateSinseisyaInfoErrorMessage: (part: Partial<SwSinseisyaInfoErrorMessage>) => void
    errorCount: number
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
}

export const SinseisyaInfoSection: FC<SinseisyaInfoSectionProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    function handleBlurBirthdayYear(value: string) {
        props.updateSinseisyaInfo({
            ...props.sinseisyaInfo,
            birthdayYear: value
        });
        props.updateSinseisyaInfoErrorMessage({
            ...props.sinseisyaInfoErrorMessage,
            birthdayYear: undefined
        })
    }

    function handleBlurBirthdayMonth(value: string) {
        props.updateSinseisyaInfo({
            ...props.sinseisyaInfo,
            birthdayMonth: value
        });
        props.updateSinseisyaInfoErrorMessage({
            ...props.sinseisyaInfoErrorMessage,
            birthdayMonth: undefined
        })
    }

    function handleBlurBirthdayDay(value: string) {
        props.updateSinseisyaInfo({
            ...props.sinseisyaInfo,
            birthdayDay: value
        });
        props.updateSinseisyaInfoErrorMessage({
            ...props.sinseisyaInfoErrorMessage,
            birthdayDay: undefined
        })
    }

    function handleBlurGakunen(value: string) {
        props.updateSinseisyaInfo({
            ...props.sinseisyaInfo,
            gakunen: value
        });
        props.updateSinseisyaInfoErrorMessage({
            ...props.sinseisyaInfoErrorMessage,
            gakunen: undefined
        })
    }

    function handleChangeKyugakuFlag(value: boolean) {
        props.updateSinseisyaInfo({
            ...props.sinseisyaInfo,
            isKyugaku: value
        });
        props.updateSinseisyaInfoErrorMessage({
            ...props.sinseisyaInfoErrorMessage,
            isKyugaku: undefined
        })
    }

    function handleBlurKyugakuStartYear(value: string) {
        props.updateSinseisyaInfo({
            ...props.sinseisyaInfo,
            kyugaku: {
                ...props.sinseisyaInfo.kyugaku,
                startYear: value
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            ...props.sinseisyaInfoErrorMessage,
            kyugaku: {
                ...props.sinseisyaInfoErrorMessage.kyugaku,
                startYear: undefined
            }
        })
    }

    function handleBlurKyugakuStartMonth(value: string) {
        props.updateSinseisyaInfo({
            kyugaku: {
                ...props.sinseisyaInfo.kyugaku,
                startMonth: value
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            kyugaku: {
                ...props.sinseisyaInfoErrorMessage.kyugaku,
                startMonth: undefined
            }
        });
    }

    function handleBlurKyugakuEndYear(value: string) {
        props.updateSinseisyaInfo({
            kyugaku: {
                ...props.sinseisyaInfo.kyugaku,
                endYear: value
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            kyugaku: {
                ...props.sinseisyaInfoErrorMessage.kyugaku,
                endYear: undefined
            }
        });
    }

    function handleBlurKyugakuEndMonth(value: string) {
        props.updateSinseisyaInfo({
            kyugaku: {
                ...props.sinseisyaInfo.kyugaku,
                endMonth: value
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            kyugaku: {
                ...props.sinseisyaInfoErrorMessage.kyugaku,
                endMonth: undefined
            }
        });
    }

    function handleBlurKyugakuRiyu(value: string) {
        props.updateSinseisyaInfo({
            kyugaku: {
                ...props.sinseisyaInfo.kyugaku,
                riyu: value
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            kyugaku: {
                ...props.sinseisyaInfoErrorMessage.kyugaku,
                riyu: undefined
            }
        });
    }

    function handleChangeRyunenFlag(value: boolean) {
        props.updateSinseisyaInfo({
            ...props.sinseisyaInfo,
            isRyunen: value
        });
        props.updateSinseisyaInfoErrorMessage({
            ...props.sinseisyaInfoErrorMessage,
            isRyunen: undefined
        })
    }

    function handleBlurAddressZipCode(value: string) {
        props.updateSinseisyaInfo({
            address: {
                ...props.sinseisyaInfo.address,
                zipcode: value
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            address: {
                ...props.sinseisyaInfoErrorMessage.address,
                zipcode: undefined
            }
        });
    }

    function handleBlurAddressAddress(value: string) {
        props.updateSinseisyaInfo({
            address: {
                ...props.sinseisyaInfo.address,
                address: value
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            address: {
                ...props.sinseisyaInfoErrorMessage.address,
                address: undefined
            }
        });
    }

    function handleBlurZipCodeAndAddress(zipCode: string, address: string) {
        props.updateSinseisyaInfo({
            address: {
                ...props.sinseisyaInfo.address,
                zipcode: zipCode,
                address: address
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            address: {
                ...props.sinseisyaInfoErrorMessage.address,
                zipcode: undefined,
                address: undefined
            }
        });
    }

    function handleBlurTelHome(value: string) {
        props.updateSinseisyaInfo({telHome: value});
        props.updateSinseisyaInfoErrorMessage({telHome: undefined})
    }

    function handleBlurTelLab(value: string) {
        props.updateSinseisyaInfo({telLab: value});
        props.updateSinseisyaInfoErrorMessage({telLab: undefined})
    }

    function handleBlurSidoTeacherName(value: string) {
        props.updateSinseisyaInfo({sidoTeacherName: value});
        props.updateSinseisyaInfoErrorMessage({sidoTeacherName: undefined})
    }

    function handleBlurAddressFamilyZipCode(value: string) {
        props.updateSinseisyaInfo({
            addressFamily: {
                ...props.sinseisyaInfo.addressFamily,
                zipcode: value
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            addressFamily: {
                ...props.sinseisyaInfoErrorMessage.addressFamily,
                zipcode: undefined
            }
        });
    }

    function handleBlurAddressFamilyAddress(value: string) {
        props.updateSinseisyaInfo({
            addressFamily: {
                ...props.sinseisyaInfo.addressFamily,
                address: value
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            addressFamily: {
                ...props.sinseisyaInfoErrorMessage.addressFamily,
                address: undefined
            }
        });
    }

    function handleBlurZipCodeAndAddressFamily(zipCode: string, address: string) {
        props.updateSinseisyaInfo({
            addressFamily: {
                ...props.sinseisyaInfo.addressFamily,
                zipcode: zipCode,
                address: address
            }
        });
        props.updateSinseisyaInfoErrorMessage({
            addressFamily: {
                ...props.sinseisyaInfoErrorMessage.addressFamily,
                zipcode: undefined,
                address: undefined
            }
        });
    }

    function handleBlurTelFamily(value: string) {
        props.updateSinseisyaInfo({telFamily: value});
        props.updateSinseisyaInfoErrorMessage({telFamily: undefined})
    }

    const sinseisyaInfo: SinseisyaInfoVo = props.sinseisyaInfo;
    const sinseisyaInfoErrorMessage: SwSinseisyaInfoErrorMessage = props.sinseisyaInfoErrorMessage;

    const birthError: boolean = sinseisyaInfoErrorMessage.birthdayYear !== undefined ||
        sinseisyaInfoErrorMessage.birthdayMonth !== undefined ||
        sinseisyaInfoErrorMessage.birthdayDay !== undefined;

    let birthErrorMessages: Array<ErrorMessageVo> = [];
    if (birthError) {
        birthErrorMessages = joinArraySwErrorMessage([
            sinseisyaInfoErrorMessage.birthdayYear,
            sinseisyaInfoErrorMessage.birthdayMonth,
            sinseisyaInfoErrorMessage.birthdayDay
        ])
    }

    const kyugakuError: boolean =
        sinseisyaInfoErrorMessage.kyugaku.startYear !== undefined ||
        sinseisyaInfoErrorMessage.kyugaku.startMonth !== undefined ||
        sinseisyaInfoErrorMessage.kyugaku.endYear !== undefined ||
        sinseisyaInfoErrorMessage.kyugaku.endMonth !== undefined;

    let kyugakuErrorMessages: Array<ErrorMessageVo> = [];
    if (kyugakuError) {
        kyugakuErrorMessages = joinArraySwErrorMessage([
            sinseisyaInfoErrorMessage.kyugaku.startYear,
            sinseisyaInfoErrorMessage.kyugaku.startMonth,
            sinseisyaInfoErrorMessage.kyugaku.endYear,
            sinseisyaInfoErrorMessage.kyugaku.endMonth,
        ]);
    }

    const studentInfo = props.studentInfo;

    return (
        <div id={SECTION_ID_NAME.sinseisyaInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.申請者情報)}
                className={"section_title"}
            />

            <ErrorCountMessage
                count={props.errorCount}
            />

            <FormTable
                className={"section_table"}
            >

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.学籍番号)}
                >
                    {studentInfo.studentNumber}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.氏名)}
                >
                    {studentInfo.name}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.氏名フリガナ)}
                >
                    {studentInfo.nameKana}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.生年月日)}
                    requiredType={RequiredTagTypeEnum.必須}
                >
                    <YearMonthDay
                        valueYear={sinseisyaInfo.birthdayYear}
                        valueMonth={sinseisyaInfo.birthdayMonth}
                        valueDay={sinseisyaInfo.birthdayDay}
                        errorMessages={convertErrorMessageVoToArrayStr(birthErrorMessages)}
                        isErrorYear={isErrorMessage(sinseisyaInfoErrorMessage.birthdayYear)}
                        isErrorMonth={isErrorMessage(sinseisyaInfoErrorMessage.birthdayMonth)}
                        isErrorDay={isErrorMessage(sinseisyaInfoErrorMessage.birthdayDay)}
                        handleBlurYear={handleBlurBirthdayYear}
                        handleBlurMonth={handleBlurBirthdayMonth}
                        handleBlurDay={handleBlurBirthdayDay}
                    />
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.年齢)}
                >
                    {studentInfo.age}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.学府研究科学部)}
                >
                    {studentInfo.gakubu}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.専攻学科)}
                >
                    {studentInfo.gakka}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.学年)}
                    requiredType={RequiredTagTypeEnum.必須}
                >
                    <NumberField
                        value={sinseisyaInfo.gakunen}
                        errorMessages={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.gakunen)}
                        isError={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.gakunen).length !== 0}
                        widthInput={"48px"}
                        suffixText={"年次"}
                        handleBlur={handleBlurGakunen}
                    />
                        <div className={"mt4"}>※{SinseisyaInfoTextEnumToNameJaAndEn(SinseisyaInfoTextEnum.博士一貫制課程の学生は)}</div>
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.入学年月)}
                >
                    {studentInfo.nyugakuOn}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.休学)}
                >
                    <FieldWithLabel
                        label={SinseisyaInfoTextEnumToNameJaAndEn(SinseisyaInfoTextEnum.休学)}
                        requiredType={RequiredTagTypeEnum.必須}
                    >
                        <RadioButtonGroup
                            errorMessages={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.isKyugaku)}
                        >
                            <RadioButton
                                value={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                checked={sinseisyaInfo.isKyugaku === true}
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                isError={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.isKyugaku).length !== 0}
                                handleChange={() => handleChangeKyugakuFlag(true)}
                            />
                            <RadioButton
                                value={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                checked={sinseisyaInfo.isKyugaku === false}
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                isError={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.isKyugaku).length !== 0}
                                handleChange={() => handleChangeKyugakuFlag(false)}
                            />
                        </RadioButtonGroup>
                    </FieldWithLabel>
                </FormTableRow>

                {
                    sinseisyaInfo.isKyugaku ?
                        <FormTableRow
                            label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.休学歴)}
                        >
                            <FieldWithLabel
                                label={SinseisyaInfoTextEnumToNameJaAndEn(SinseisyaInfoTextEnum.休学期間)}
                                requiredType={RequiredTagTypeEnum.必須}
                                errorMessages={convertErrorMessageVoToArrayStr(kyugakuErrorMessages)}
                            >
                                <div className={`${classes.kikan} ${xs && classes.xsKikan}`}>
                                    <YearMonth
                                        prefixTextYear={"西暦"}
                                        valueYear={sinseisyaInfo.kyugaku.startYear}
                                        valueMonth={sinseisyaInfo.kyugaku.startMonth}
                                        isErrorYear={isErrorMessage(sinseisyaInfoErrorMessage.kyugaku.startYear)}
                                        isErrorMonth={isErrorMessage(sinseisyaInfoErrorMessage.kyugaku.startMonth)}
                                        handleBlurYear={handleBlurKyugakuStartYear}
                                        handleBlurMonth={handleBlurKyugakuStartMonth}
                                    />
                                    <div>〜</div>
                                    <YearMonth
                                        prefixTextYear={"西暦"}
                                        valueYear={sinseisyaInfo.kyugaku.endYear}
                                        valueMonth={sinseisyaInfo.kyugaku.endMonth}
                                        isErrorYear={isErrorMessage(sinseisyaInfoErrorMessage.kyugaku.endYear)}
                                        isErrorMonth={isErrorMessage(sinseisyaInfoErrorMessage.kyugaku.endMonth)}
                                        handleBlurYear={handleBlurKyugakuEndYear}
                                        handleBlurMonth={handleBlurKyugakuEndMonth}
                                    />
                                </div>
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={SinseisyaInfoTextEnumToNameJaAndEn(SinseisyaInfoTextEnum.休学理由)}
                                requiredType={RequiredTagTypeEnum.必須}
                            >
                                <TextField
                                    value={sinseisyaInfo.kyugaku.riyu}
                                    width={"400px"}
                                    errorMessages={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.kyugaku.riyu)}
                                    isError={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.kyugaku.riyu).length !== 0}
                                    handleBlur={handleBlurKyugakuRiyu}
                                />
                            </FieldWithLabel>
                        </FormTableRow>
                        : null
                }

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.留年)}
                >
                    <FieldWithLabel
                        label={SinseisyaInfoTextEnumToNameJaAndEn(SinseisyaInfoTextEnum.留年)}
                        requiredType={RequiredTagTypeEnum.必須}
                    >
                        <RadioButtonGroup
                            errorMessages={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.isRyunen)}
                        >
                            <RadioButton
                                value={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                checked={sinseisyaInfo.isRyunen === true}
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                isError={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.isRyunen).length !== 0}
                                handleChange={() => handleChangeRyunenFlag(true)}
                            />
                            <RadioButton
                                value={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                checked={sinseisyaInfo.isRyunen === false}
                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                isError={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.isRyunen).length !== 0}
                                handleChange={() => handleChangeRyunenFlag(false)}
                            />
                        </RadioButtonGroup>
                    </FieldWithLabel>
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.住所)}
                >
                    <AddressField
                        valueZip={sinseisyaInfo.address.zipcode}
                        valueAddress={sinseisyaInfo.address.address}
                        requiredTypeZip={RequiredTagTypeEnum.必須}
                        requiredTypeAddress={RequiredTagTypeEnum.必須}
                        handleBlurZipCode={handleBlurAddressZipCode}
                        handleBlurAddress={handleBlurAddressAddress}
                        handleBlurZipCodeAndAddress={handleBlurZipCodeAndAddress}
                        errorMessagesZip={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.address.zipcode)}
                        errorMessagesAddress={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.address.address)}
                    />
                    <DescriptionLinkWrap>
                        <DescriptionLink
                            onClickWordDescriptionLink={props.onClickWordDescriptionLink}
                            type={WordDescriptionTypeEnum.住所等欄の家族欄}
                        />
                    </DescriptionLinkWrap>
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.自宅電話番号)}
                    requiredType={RequiredTagTypeEnum.任意}
                >
                    <TelField
                        value={sinseisyaInfo.telHome}
                        handleBlur={handleBlurTelHome}
                        errorMessages={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.telHome)}
                        isError={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.telHome).length !== 0}
                    />
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.携帯電話番号)}
                >
                    {studentInfo.telGakuseiNyuyroku}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.メールアドレス)}
                >
                    {studentInfo.emailGakuseiNyuyroku}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.研究室電話番号)}
                    requiredType={RequiredTagTypeEnum.任意}
                >
                    <TelField
                        value={sinseisyaInfo.telLab}
                        handleBlur={handleBlurTelLab}
                        errorMessages={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.telLab)}
                        isError={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.telLab).length !== 0}
                    />
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.指導教員名)}
                    requiredType={RequiredTagTypeEnum.任意}
                >
                    <TextField
                        value={sinseisyaInfo.sidoTeacherName}
                        width={"400px"}
                        errorMessages={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.sidoTeacherName)}
                        handleBlur={handleBlurSidoTeacherName}
                        isError={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.sidoTeacherName).length !== 0}
                    />
                </FormTableRow>

                {
                    props.requireSinseisyaInfo.addressFamily ?
                        <FormTableRow
                            label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.住所家族)}
                        >
                            <AddressField
                                valueZip={sinseisyaInfo.addressFamily.zipcode}
                                valueAddress={sinseisyaInfo.addressFamily.address}
                                requiredTypeZip={RequiredTagTypeEnum.必須}
                                requiredTypeAddress={RequiredTagTypeEnum.必須}
                                handleBlurZipCode={handleBlurAddressFamilyZipCode}
                                handleBlurAddress={handleBlurAddressFamilyAddress}
                                handleBlurZipCodeAndAddress={handleBlurZipCodeAndAddressFamily}
                                errorMessagesZip={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.addressFamily.zipcode)}
                                errorMessagesAddress={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.addressFamily.address)}
                            />
                        </FormTableRow>
                        : null
                }


                {
                    props.requireSinseisyaInfo.telFamily ?
                        <FormTableRow
                            label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.電話番号家族)}
                            requiredType={RequiredTagTypeEnum.必須}
                        >
                            <TelField
                                value={sinseisyaInfo.telFamily}
                                handleBlur={handleBlurTelFamily}
                                errorMessages={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.telFamily)}
                                isError={convertErrorMessageVoToArrayStr(sinseisyaInfoErrorMessage.telFamily).length !== 0}
                            />
                        </FormTableRow>
                        : null
                }

            </FormTable>

        </div>

    )
};

const useStyles = makeStyles({
        root: {},
        kikan: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            "& > *:not(:first-child)": {
                marginLeft: 4
            }
        },
        xsKikan: {
            flexWrap: "wrap",
            "& > *:last-child": {
                marginTop: 4,
                marginLeft: 0
            }
        }
    }
);

