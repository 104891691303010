export enum TextColorsEnum {
    Black = "#222",
    White = "#FFF",
    OffWhite = "#F9F9F9",
    DarkGray = "#666",
    Gray = "#888",
    LightGray = "#dedede",
    Red = "#C6414E",
    LightRed = "#EC5C5C",
    MuiBlue = "#315fb5",
    Blue = "#5497BA",
    Green = "#54B99A",
    Primary = "#004EA2",
    Secondary = "#16c13b",
    Warning = "#f19524",
}

export enum HeaderBgColorEnum {
    Blue = "#546C85",
}

export enum BgColorsEnum {
    Black = "#222",
    White = "#FFF",
    OffWhite = "#F9F9F9",
    DarkGray = "#666",
    Gray = "#888",
    LightGray = "#dedede",
    Red = "#C6414E",
    LightRed = "#EC5C5C",
    LightRed02 = "#ffdede",
    Blue = "#004EA2",
    DarkGreen = "#54B99A",
    Green = "#84D2BB",
    LightGreen = "#DDF3EA",
    TableHead = "#76899D",
}

export enum BorderColorsEnum {
    Red = "#C6414E",
    Black = "#222",
    White = "#FFF",
    DarkGray = "#666",
    Gray = "#888",
    LightGray = "#dedede",
    MuiBlue = "#315fb5"
}

export enum TagColorsEnum {
    White = "#FFF",
    DarkGray = "#666",
    Gray = "#888",
    LightGray = "#dedede",
    Red = "#E41313",
    Yellow = "#EECB6A",
    Green = "#A6D283",
    Blue = "#77C7D2",
    Purple = "#BCA9F2",
    LightGreen = "#84D3BB",
    Pink = "#F2A9CC",
}

export enum ButtonColorsEnum {
    Primary = "#004EA2",
    Secondary = "#16c13b",
    Warning = "#f19524",
    Default = "#FFF",
    Disabled = "#888",
    Destructive = "#ff4600",
}

export enum DisabledColorsEnum {
    Disabled = "#BFBFBF",
}

export enum CheckBoxColorsEnum {
    Primary = "#004EA2",
}

export enum RadioBoxColorsEnum {
    Primary = "#004EA2",
}

export enum ContentBackColorsEnum {
    Gray = "#ECECEC",
}