import {makeStyles} from '@material-ui/core/styles';
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {OfficerKikansStudentIdKikansKikanVo, OfficerKikansStudentIdKikansVo} from "common/models/KikansModels";
import {RequiredSyoruisStoreVo} from "common/models/SyoruisModels";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {CommonSyoruisSyoruiVo} from "stores/common/CommonSyoruiStore";
import {OsiraseStoreVo} from "stores/common/OsiraseStore";
import {MessageBox} from "components/atoms/MessageBox";
import {Osirase} from "components/organisms/common/Osirase";
import {OsiraseDetailModal} from "components/organisms/common/OsiraseDetailModal";
import {SyoruiModal} from "components/organisms/common/SyoruiModal";
import {SummaryKikanInfoSection} from "components/organisms/summary/SummaryKikanInfoSection";
import {SummaryOfficeInfomationSection} from 'components/organisms/summary/SummaryOfficeInfomationSection';
import {SummaryStudentInfoSection} from "components/organisms/summary/SummaryStudentInfoSection";
import {SyoruiSection} from "components/organisms/summary/SyoruiSection";
import React, {useEffect, useState} from 'react';
import {SimulationModal} from "../common/SimulationModal";
import Grid from "@material-ui/core/Grid";
import {SummarySyoruiSection} from "./SummarySyoruiSection";
import {SummaryAyosikiSection} from "./SummaryAyosikiSection";
import {AYosikiSyoruisSyoruisVo} from "../../../common/models/AYosikiSyoruisModels";

interface SummaryPageComponentProps {
    handleClickSyoruiConfirmationForm: () => void
    handleClickSyoruiConfirmation: () => void
    osirase: OsiraseStoreVo
    handleClickOsirase: (id: string) => void
    handleClickDetailModalClose: () => void
    currentKikan: { name: string, kikan: OfficerKikansStudentIdKikansKikanVo } | undefined
    studentInfo: StudentInfoStoreVo
    requiedSyoruis: RequiredSyoruisStoreVo
    kikans: OfficerKikansStudentIdKikansVo | undefined
    handleClickYosikiDetail: () => void
    syoruis: Array<CommonSyoruisSyoruiVo>;
    syoruiModalState: boolean;
    handleClickSyoruiModalClose: () => void
    handleClickSyoruiList: () => void
    handleClickAYosikiForm: () => void
    aYosikiSYoruis?: AYosikiSyoruisSyoruisVo;
}

export const SummaryPageComponent: React.FC<SummaryPageComponentProps> = ({
                                                                              handleClickSyoruiConfirmationForm,
                                                                              handleClickSyoruiConfirmation,
                                                                              osirase,
                                                                              handleClickOsirase,
                                                                              handleClickDetailModalClose,
                                                                              currentKikan,
                                                                              studentInfo,
                                                                              requiedSyoruis,
                                                                              kikans,
                                                                              handleClickYosikiDetail,
                                                                              syoruis,
                                                                              syoruiModalState,
                                                                              handleClickSyoruiModalClose,
                                                                              handleClickSyoruiList,
                                                                              handleClickAYosikiForm,
                                                                              aYosikiSYoruis
                                                                          }) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const [simulationModalState, updateSimulationModalState] = useState(false)
    const [isAYosikiStudent, updateIsAYosikiStudent] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        updateIsAYosikiStudent(studentInfo.isAyosiki)
    }, [studentInfo.isAyosiki]);

    // NOTE: 対象外の学生に対しての表示
    if (!studentInfo.isTaisyo) {
        return <MessageBox>
            私費留学生以外の留学生（国費、政府派遣等）の方は申請できません。 <br/>
            詳細は事務までお問い合わせください。<br/> International students other than privately-financed international
            students (government-sponsored, government-dispatched, etc.) may not apply.<br/>
            For details, please inquire with the administration office.
        </MessageBox>
    }

    return (

        <div className={`${classes.root} ${xs && classes.xsRoot}`}>

            <SyoruiModal
                openState={syoruiModalState}
                syoruis={syoruis}
                handleClickSyoruiModalClose={handleClickSyoruiModalClose}
            />

            <OsiraseDetailModal
                openState={osirase.osiraseDetailModalState}
                osiraseDetail={osirase.osiraseDetail}
                handleClickDetailModalClose={handleClickDetailModalClose}
            />

            <SimulationModal
                openState={simulationModalState}
                simulation={studentInfo.simulation}
                handleClickSimulationModalClose={() => updateSimulationModalState(false)}
            />

            <Grid container spacing={4}>

                <Grid item xs={12}>
                    {/*　NOTE: お知らせ */}
                    <Osirase
                        osirase={osirase.osirases}
                        handleClickOsirase={handleClickOsirase}
                    />
                </Grid>

                {/*　NOTE: A様式学生 */}
                {
                    isAYosikiStudent === true && (
                        <Grid item xs={12}>
                            <SummaryAyosikiSection
                                aYosikiSYoruis={aYosikiSYoruis}
                                handleClickAYosikiForm={handleClickAYosikiForm}
                                isAYosikiKakutei={studentInfo.isAYosikiKakutei}
                            />
                        </Grid>
                    )
                }

                {/*　NOTE: 様式1学生 */}
                {
                    isAYosikiStudent === false && (
                        <>
                            <Grid item xs={12}>
                                {/*　NOTE: 学生、入力に関する一覧 */}
                                <SummaryStudentInfoSection
                                    currentKikan={currentKikan}
                                    studentInfo={studentInfo}
                                    requiedSyoruis={requiedSyoruis}
                                    handleClickYosikiDetail={handleClickYosikiDetail}
                                    handleClickSimulation={() => updateSimulationModalState(true)}
                                    handleClickSyoruiConfirmationForm={handleClickSyoruiConfirmationForm}
                                    handleClickSyoruiConfirmation={handleClickSyoruiConfirmation}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                {/*　NOTE: 各種証明書類 */}
                                <SummarySyoruiSection
                                    studentInfo={studentInfo}
                                    requiedSyoruis={requiedSyoruis}
                                    handleClickSyoruiConfirmationForm={handleClickSyoruiConfirmationForm}
                                    handleClickSyoruiConfirmation={handleClickSyoruiConfirmation}
                                />
                            </Grid>
                        </>
                    )
                }

                <Grid item xs={12}>
                    {/*　NOTE: 書類一覧 */}
                    <SyoruiSection
                        handleClickSyoruiList={handleClickSyoruiList}
                    />
                </Grid>

                <Grid item xs={12}>
                    {/*　NOTE: 期間の情報 */}
                    <SummaryKikanInfoSection
                        kikans={kikans}
                    />
                </Grid>

                <Grid item xs={12}>
                    {/*　NOTE: 担当窓口の情報 */}
                    <SummaryOfficeInfomationSection
                        bukyokuCode={studentInfo.bukyokuCode}
                    />
                </Grid>

            </Grid>

        </div>

    );
};

const useStyles = makeStyles({
    root: {
        "& .studentInfo": {
            border: `16px solid #F2EDDA`,
            padding: 32,
            fontSize: 14
        },
    },
    xsRoot: {
        "& .studentInfo": {
            padding: 12,
            border: `8px solid #F2EDDA`,
        },
    },
    inner: {
        "& > *:not(:first-child)": {
            marginTop: 32
        }
    }
});


