import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum BasicInfoLTextEnum {
    入学料について該当する項目を選択してください = "入学料について該当する項目を選択してください",
    授業料について該当する項目を選択してください = "授業料について該当する項目を選択してください",
    国籍永住者配偶者定住者 = "国籍・永住者・配偶者・定住者",
    提出済み = "提出済み",
    未提出 = "未提出",
    日本学生支援機構1 = "日本学生支援機構1",
    日本学生支援機構2 = "日本学生支援機構2",
    編入学又は転学 = "編入学又は転学"
}

export function BasicInfoLTextEnumToNameJaAndEn(e: BasicInfoLTextEnum): string {
    switch (e) {
        case BasicInfoLTextEnum.入学料について該当する項目を選択してください:
            return convertEnumNameToStr({
                ja: "入学料について該当する項目を選択してください。",
                en: "Select the item regarding enrollment fees which applies to you."
            });
        case BasicInfoLTextEnum.授業料について該当する項目を選択してください:
            return convertEnumNameToStr({
                ja: "授業料について該当する項目を選択してください。",
                en: "Select the item regarding tuition which applies to you."
            });
        case BasicInfoLTextEnum.国籍永住者配偶者定住者:
            return convertEnumNameToStr({
                ja: "該当する項目を選択してください。",
                en: "Select the applicable item."
            });
        case BasicInfoLTextEnum.提出済み:
            return convertEnumNameToStr({
                ja: "提出済み",
                en: "Submitted"
            });
        case BasicInfoLTextEnum.未提出:
            return convertEnumNameToStr({
                ja: "未提出",
                en: "Not submitted"
            });
        case BasicInfoLTextEnum.日本学生支援機構1:
            return convertEnumNameToStr({
                ja: "過去に日本学生支援機構への給付奨学金の申し込みを行いましたか？",
                en: "In the past, have you ever applied for a scholarship grant from the Japan Student Services Organization?"
            });
        case BasicInfoLTextEnum.日本学生支援機構2:
            return convertEnumNameToStr({
                ja: "日本学生支援機構への給付奨学金の申し込みを行いますか？",
                en: "Will you be applying for a scholarship grant from the Japan Student Services Organization?"
            });
        case BasicInfoLTextEnum.編入学又は転学:
            return convertEnumNameToStr({
                ja: "本学に編入学又は転学(若しくは専攻科に入学)した学生であって、編入学又は転学(若しくは専攻科に入学)する前に在学していた学校(大学、短大、高専、専門学校)が2つ以上ありますか？",
                en: "Are you a student who entered this school by mid-course entry or by transfer (or admission to a non-degree course for graduates), and were you enrolled in 2 or more schools (universities, junior colleges, colleges of technology, professional training colleges) before entering this school by mid-course entry or by transfer (or admission to a non-degree course for graduates)?"
            });

        // case BasicInfoLTextEnum.A様式1提出状況:
        //     return enumToNameBothJaAndEn({ja: "A様式1提出状況", en: "A style 1 submission situation"});
        // case BasicInfoLTextEnum.日本学生支援機構:
        //     return enumToNameBothJaAndEn({ja: "日本学生支援機構", en: "Japan Student Services Organization"});
        // case BasicInfoLTextEnum.編入学又は転学:
        //     return enumToNameBothJaAndEn({ja: "編入学又は転学", en: "Transfer students"});
        // case BasicInfoLTextEnum.特別控除:
        //     return enumToNameBothJaAndEn({ja: "特別控除", en: "Special Deductions"});
    }
}