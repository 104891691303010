import {useMediaQuery} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import React, {FC} from "react";

interface FormButtonProps {
    className?: string;
}

export const FormButton: FC<FormButtonProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    return (

        <div className={`${classes.root} ${xs && classes.xsRoot} ${props.className} `}>
            {
                props.children
            }
        </div>
    )
};

const useStyles = makeStyles({
        root: {
            display: "flex",
            justifyContent: "center"
        },
        xsRoot: {}
    }
);

