import makeStyles from "@material-ui/core/styles/makeStyles";
import {TyokiryoyoTypeEnum, tyokiryoyoTypeEnumToName} from "common/enums/TyokiryoyoTypeEnum";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {joinArraySwErrorMessage} from "common/functions/converter/joinArraySwErrorMessage";
import {ErrorMessageVo} from "common/models/ErrorMessageVo";
import {TyokiryoyoVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {TitleNameTextEnum, TitleNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TitleNameTextEnum";
import {TokubetuKojoLabelTextEnum, TokubetuKojoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TokubetuKojoLabelTextEnum";
import {Button} from "components/atoms/Button";
import {NumberField} from "components/atoms/forms/NumberField";
import {RadioButton} from "components/atoms/forms/RadioButton";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {AnnotationText} from "components/atoms/AnnotationText";
import {Title05} from "components/atoms/titles/Title05";
import {FormButton} from "components/molecules/forms/FormButton";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {RadioButtonGroup} from "components/molecules/forms/RadioButtonGroup";
import {YearMonth} from "components/molecules/forms/YearMonth";
import {SwTyokiryoyoError, SwTyokiryoyoErrorMessage} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface TokubetuKojoTyokiryoyoProps {
    tyokiryoyo: Array<TyokiryoyoVo>
    handleChangeTyokiryoyo: (part: Partial<TyokiryoyoVo>, index: number) => void

    handleClickDeleteTyokiryoyo: (index: number) => void
    handleClickAddTyokiryoyoZokugara: () => void
    tyokiryoyoErrorMessage: SwTyokiryoyoErrorMessage
    clearTyokiryoyoErrorMessage: (part: Partial<SwTyokiryoyoError>, index: number) => void
    clearDeleteTyokiryoyoErrorMessage: (index: number) => void
}

interface TyokiryoyoRowProps {
    tyokiryoyo: TyokiryoyoVo
    handleChangeTyokiryoyo: (part: Partial<TyokiryoyoVo>, index: number) => void
    handleClickDeleteTyokiryoyo: (index: number) => void
    index: number
    tyokiryoyoError: SwTyokiryoyoError | undefined
    clearTyokiryoyoErrorMessage: (part: Partial<SwTyokiryoyoError>, index: number) => void
    clearDeleteTyokiryoyoErrorMessage: (index: number) => void
}

const TyokiryoyoRow: FC<TyokiryoyoRowProps> = (props) => {

    const classes = useStyles();

    const tyokiryoyo = props.tyokiryoyo;
    const tyokiryoyoError = props.tyokiryoyoError;

    function handleChangeTyokiryoyoType(value: string) {
        props.handleChangeTyokiryoyo({type: value as TyokiryoyoTypeEnum}, props.index);
        props.clearTyokiryoyoErrorMessage({type: undefined}, props.index)
    }

    function handleBlurStartYear(value: string) {
        props.handleChangeTyokiryoyo({startYear: value}, props.index);
        props.clearTyokiryoyoErrorMessage({startYear: undefined, startMonth: undefined}, props.index)
    }

    function handleBlurStartMonth(value: string) {
        props.handleChangeTyokiryoyo({startMonth: value}, props.index);
        props.clearTyokiryoyoErrorMessage({startYear: undefined, startMonth: undefined}, props.index)
    }

    function handleBlurAmountPerHalfYear(value: string) {
        props.handleChangeTyokiryoyo({amountPerHalfYear: value}, props.index);
        props.clearTyokiryoyoErrorMessage({amountPerHalfYear: undefined}, props.index)
    }

    function handleClickDeleteTyokiryoyo() {
        props.handleClickDeleteTyokiryoyo(props.index);
        props.clearDeleteTyokiryoyoErrorMessage(props.index)
    }

    let kikanError: boolean = false;
    if (tyokiryoyoError !== undefined) {
        kikanError = tyokiryoyoError.startYear !== undefined ||
            tyokiryoyoError.startMonth !== undefined;
    }

    let kikanErrorMessages: Array<ErrorMessageVo> = [];
    kikanErrorMessages = joinArraySwErrorMessage([
        kikanErrorMessages,
        tyokiryoyoError?.startYear,
        tyokiryoyoError?.startMonth,
    ]);

    return (
        <FormTableRow
            label={convertZokugaraForStr(tyokiryoyo.zokugara, tyokiryoyo.zokugaraOther)}
        >
            <div className={"formTableRow_item"}>
                <AnnotationText
                    type={"warning"}
                    text={CommonTextEnumToNameJaAndEn(CommonTextEnum.わからない場合は空欄にしてください)}
                />
                <div className={"formTableRow_item_label"}>
                    {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}<RequiredTag type={RequiredTagTypeEnum.任意}/>
                </div>
                <RadioButtonGroup
                    errorMessages={tyokiryoyoError ? convertErrorMessageVoToArrayStr(tyokiryoyoError.type) : []}
                    vertical={true}
                >
                    <RadioButton
                        value={""}
                        checked={tyokiryoyo.type === ""}
                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.未選択)}
                        isError={tyokiryoyoError && isErrorMessage(tyokiryoyoError.type)}
                        handleChange={() => {
                            handleChangeTyokiryoyoType("")
                        }}
                    />
                    <RadioButton
                        value={""}
                        checked={tyokiryoyo.type === TyokiryoyoTypeEnum.入院}
                        label={convertEnumNameToStr(tyokiryoyoTypeEnumToName(TyokiryoyoTypeEnum.入院))}
                        isError={tyokiryoyoError && isErrorMessage(tyokiryoyoError.type)}
                        handleChange={() => {
                            handleChangeTyokiryoyoType(TyokiryoyoTypeEnum.入院)
                        }}
                    />
                    <RadioButton
                        value={""}
                        checked={tyokiryoyo.type === TyokiryoyoTypeEnum.通院}
                        label={convertEnumNameToStr(tyokiryoyoTypeEnumToName(TyokiryoyoTypeEnum.通院))}
                        isError={tyokiryoyoError && isErrorMessage(tyokiryoyoError.type)}
                        handleChange={() => {
                            handleChangeTyokiryoyoType(TyokiryoyoTypeEnum.通院)
                        }}
                    />
                    <RadioButton
                        value={""}
                        checked={tyokiryoyo.type === TyokiryoyoTypeEnum.自宅}
                        label={convertEnumNameToStr(tyokiryoyoTypeEnumToName(TyokiryoyoTypeEnum.自宅))}
                        isError={tyokiryoyoError && isErrorMessage(tyokiryoyoError.type)}
                        handleChange={() => {
                            handleChangeTyokiryoyoType(TyokiryoyoTypeEnum.自宅)
                        }}
                    />
                </RadioButtonGroup>
            </div>

            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.療養開始時期)}<RequiredTag type={RequiredTagTypeEnum.任意}/>
                </div>
                <YearMonth
                    prefixTextYear={"西暦"}
                    valueYear={tyokiryoyo.startYear}
                    valueMonth={tyokiryoyo.startMonth}
                    errorMessages={convertErrorMessageVoToArrayStr(kikanErrorMessages)}
                    isError={kikanError}
                    handleBlurYear={(value) => handleBlurStartYear(value)}
                    handleBlurMonth={(value) => handleBlurStartMonth(value)}
                />
            </div>

            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.半年当たりの療養費)}<RequiredTag type={RequiredTagTypeEnum.任意}/>
                </div>
                <NumberField
                    value={tyokiryoyo.amountPerHalfYear}
                    handleBlur={(value) => handleBlurAmountPerHalfYear(value)}
                    errorMessages={tyokiryoyoError ? convertErrorMessageVoToArrayStr(tyokiryoyoError.amountPerHalfYear) : []}
                    isError={tyokiryoyoError && isErrorMessage(tyokiryoyoError.amountPerHalfYear)}
                    suffixText={"円"}
                    widthInput={"80px"}
                />
            </div>

            <div className={`${classes.bottomRow}`}>
                <Button
                    size={'small'}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を削除)}
                    type={"warning"}
                    handleClick={handleClickDeleteTyokiryoyo}
                />
            </div>

        </FormTableRow>
    )

};

export const TokubetuKojoTyokiryoyo: FC<TokubetuKojoTyokiryoyoProps> = (props) => {

    const classes = useStyles();

    const tyokiryoyo: Array<TyokiryoyoVo> = props.tyokiryoyo;

    return (

        <>
            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.長期療養者のいる世帯)}
                className={classes.title}
            />

            {tyokiryoyo.length === 0 ? <div>{CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を追加してください)}</div> : null}

            {
                <ErrorMessages
                    errorMessages={convertErrorMessageVoToArrayStr(props.tyokiryoyoErrorMessage.policy)}
                />
            }

            <FormTable
                className={"section_table"}
            >

                {
                    tyokiryoyo.map((t: TyokiryoyoVo, index: number) => {
                        return (
                            <TyokiryoyoRow
                                key={t.zokugara + index}
                                index={index}
                                tyokiryoyo={t}
                                tyokiryoyoError={props.tyokiryoyoErrorMessage.tyokiryoyoErrors && props.tyokiryoyoErrorMessage.tyokiryoyoErrors[index]}
                                clearTyokiryoyoErrorMessage={props.clearTyokiryoyoErrorMessage}
                                handleChangeTyokiryoyo={props.handleChangeTyokiryoyo}
                                handleClickDeleteTyokiryoyo={props.handleClickDeleteTyokiryoyo}
                                clearDeleteTyokiryoyoErrorMessage={props.clearDeleteTyokiryoyoErrorMessage}
                            />
                        )
                    })
                }

            </FormTable>

            <FormButton
                className={"section_button"}
            >
                <Button
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を追加)}
                    handleClick={props.handleClickAddTyokiryoyoZokugara}
                />
            </FormButton>

        </>

    )
};

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        },
        bottomRow: {
            marginTop: 4
        },
    }
);

