/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSinseiTypes,
    SwSinseiTypesFromJSON,
    SwSinseiTypesFromJSONTyped,
    SwSinseiTypesToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSinseisya
 */
export interface SwSinseisya {
    /**
     * 
     * @type {string}
     * @memberof SwSinseisya
     */
    numberType: string;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisya
     */
    studentNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisya
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisya
     */
    nameKana: string;
    /**
     * 
     * @type {number}
     * @memberof SwSinseisya
     */
    birthYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSinseisya
     */
    birthMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSinseisya
     */
    birthDay?: number;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisya
     */
    gakubu: string;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisya
     */
    gakka: string;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisya
     */
    studentKubun: string;
    /**
     * 
     * @type {string}
     * @memberof SwSinseisya
     */
    ryugakuseiKubun: string;
    /**
     * 
     * @type {boolean}
     * @memberof SwSinseisya
     */
    isJugyoryoMino: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwSinseisya
     */
    isRyunen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwSinseisya
     */
    isAyosiki: boolean;
    /**
     * 
     * @type {SwSinseiTypes}
     * @memberof SwSinseisya
     */
    sinseiTypes: SwSinseiTypes;
    /**
     * enum
     * @type {string}
     * @memberof SwSinseisya
     */
    sinseiState: string;
}

export function SwSinseisyaFromJSON(json: any): SwSinseisya {
    return SwSinseisyaFromJSONTyped(json, false);
}

export function SwSinseisyaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSinseisya {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberType': json['numberType'],
        'studentNumber': json['studentNumber'],
        'name': json['name'],
        'nameKana': json['nameKana'],
        'birthYear': !exists(json, 'birthYear') ? undefined : json['birthYear'],
        'birthMonth': !exists(json, 'birthMonth') ? undefined : json['birthMonth'],
        'birthDay': !exists(json, 'birthDay') ? undefined : json['birthDay'],
        'gakubu': json['gakubu'],
        'gakka': json['gakka'],
        'studentKubun': json['studentKubun'],
        'ryugakuseiKubun': json['ryugakuseiKubun'],
        'isJugyoryoMino': json['isJugyoryoMino'],
        'isRyunen': !exists(json, 'isRyunen') ? undefined : json['isRyunen'],
        'isAyosiki': json['isAyosiki'],
        'sinseiTypes': SwSinseiTypesFromJSON(json['sinseiTypes']),
        'sinseiState': json['sinseiState'],
    };
}

export function SwSinseisyaToJSON(value?: SwSinseisya | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberType': value.numberType,
        'studentNumber': value.studentNumber,
        'name': value.name,
        'nameKana': value.nameKana,
        'birthYear': value.birthYear,
        'birthMonth': value.birthMonth,
        'birthDay': value.birthDay,
        'gakubu': value.gakubu,
        'gakka': value.gakka,
        'studentKubun': value.studentKubun,
        'ryugakuseiKubun': value.ryugakuseiKubun,
        'isJugyoryoMino': value.isJugyoryoMino,
        'isRyunen': value.isRyunen,
        'isAyosiki': value.isAyosiki,
        'sinseiTypes': SwSinseiTypesToJSON(value.sinseiTypes),
        'sinseiState': value.sinseiState,
    };
}


