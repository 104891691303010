import {Severity} from "@sentry/types";
import {URL_PATHS_OFFICER} from "common/constant/URL_PATHS_OFFICER";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {sentryLog} from "utilities/sentryLog";

export function handleOfficerError(res: any) {
    switch (res.status) {
        case 400:
            console.log(res);
            break;
        case 401:
            console.log(res);
            window.location.assign(URL_PATHS_OFFICER.login);
            break;
        default:
            console.log(res);
            sentryLog("officer error", res, Severity.Error);
            alert(CommonTextEnumToNameJaAndEn(CommonTextEnum.申し訳ありません問題が発生しました));
    }
}