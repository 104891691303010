import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum NyugakuryoInfoTextEnum {
    どういう原因で = "「どういう原因で」「いつごろから」困難なのかなどといった経済的事情を具体的かつ詳細に記入してください。",
    字以上入力してください = "200字以上入力してください"
}

export function NyugakuryoInfoTextEnumToNameJaAndEn(e: NyugakuryoInfoTextEnum): string {
    switch (e) {
        case NyugakuryoInfoTextEnum.どういう原因で:
            return convertEnumNameToStr({
                ja: "「経済的に授業料の納入が困難なため」 といった漠然とした理由では無く、「どういう原因で」「いつごろから」困難なのかなどといった経済的事情を具体的かつ詳細に記入すること。",
                en: "Rather than indicating a general, vague reason such as “Because it has become financially difficult for me to pay tuition”, enter specific and detailed information on your financial difficulties, such as the causes behind them and approximately when they began."
            });
        case NyugakuryoInfoTextEnum.字以上入力してください:
            return convertEnumNameToStr({
                ja: "200字以上入力してください。",
                en: "Please enter at least 200 characters."
            });
        default :
            return ""
    }
}