/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SwOsirasesDetailResponse,
    SwOsirasesDetailResponseFromJSON,
    SwOsirasesDetailResponseToJSON,
    SwOsirasesResponse,
    SwOsirasesResponseFromJSON,
    SwOsirasesResponseToJSON,
} from '../models';

export interface GetOsiraseRequest {
    osiraseId: string;
}

/**
 * no description
 */
export class OsirasesApi extends runtime.BaseAPI {

    /**
     * お知らせ情報の取得
     */
    async getOsiraseRaw(requestParameters: GetOsiraseRequest): Promise<runtime.ApiResponse<SwOsirasesDetailResponse>> {
        if (requestParameters.osiraseId === null || requestParameters.osiraseId === undefined) {
            throw new runtime.RequiredError('osiraseId','Required parameter requestParameters.osiraseId was null or undefined when calling getOsirase.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/common/osirases/{osiraseId}`.replace(`{${"osiraseId"}}`, encodeURIComponent(String(requestParameters.osiraseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOsirasesDetailResponseFromJSON(jsonValue));
    }

    /**
     * お知らせ情報の取得
     */
    async getOsirase(requestParameters: GetOsiraseRequest): Promise<SwOsirasesDetailResponse> {
        const response = await this.getOsiraseRaw(requestParameters);
        return await response.value();
    }

    /**
     * お知らせ一覧 全件を作成日(createdAt)の新しい順で。 
     */
    async getOsirasesRaw(): Promise<runtime.ApiResponse<SwOsirasesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/common/osirases`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOsirasesResponseFromJSON(jsonValue));
    }

    /**
     * お知らせ一覧 全件を作成日(createdAt)の新しい順で。 
     */
    async getOsirases(): Promise<SwOsirasesResponse> {
        const response = await this.getOsirasesRaw();
        return await response.value();
    }

}
