import {accountInfoStore} from "stores/student/AccountInfoStore";
import React from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import { initGlobalSyoriki } from "common/functions/globalSyoriki";


const _RouteWrapper: React.FC<RouteComponentProps<{ studentNumber: string }>> = (props) => {

    // ログイン後のページで必要な情報を取得
    React.useEffect(() => {
        accountInfoStore.getStudentInfo().then();
        // グローバル処理期の初期化
        initGlobalSyoriki()
    }, []);

    return (

        <>
            {props.children}
        </>

    );
};

export const RouteWrapper = withRouter(_RouteWrapper);
