/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwStudentCreateAccountNewStudentsPostErrorMessage,
    SwStudentCreateAccountNewStudentsPostErrorMessageFromJSON,
    SwStudentCreateAccountNewStudentsPostErrorMessageFromJSONTyped,
    SwStudentCreateAccountNewStudentsPostErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20020
     */
    isSuccess: boolean;
    /**
     * 
     * @type {SwStudentCreateAccountNewStudentsPostErrorMessage}
     * @memberof InlineResponse20020
     */
    error?: SwStudentCreateAccountNewStudentsPostErrorMessage;
}

export function InlineResponse20020FromJSON(json: any): InlineResponse20020 {
    return InlineResponse20020FromJSONTyped(json, false);
}

export function InlineResponse20020FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20020 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSuccess': json['isSuccess'],
        'error': !exists(json, 'error') ? undefined : SwStudentCreateAccountNewStudentsPostErrorMessageFromJSON(json['error']),
    };
}

export function InlineResponse20020ToJSON(value?: InlineResponse20020 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSuccess': value.isSuccess,
        'error': SwStudentCreateAccountNewStudentsPostErrorMessageToJSON(value.error),
    };
}


