/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwSyogaisyaError,
    SwSyogaisyaErrorFromJSON,
    SwSyogaisyaErrorFromJSONTyped,
    SwSyogaisyaErrorToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSyogaisyaErrorMessage
 */
export interface SwSyogaisyaErrorMessage {
    /**
     * 障害者が有効で続柄が選択されていない場合にエラーを返す。 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogaisyaErrorMessage
     */
    policy?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwSyogaisyaError>}
     * @memberof SwSyogaisyaErrorMessage
     */
    syogaisyaErrors?: Array<SwSyogaisyaError>;
}

export function SwSyogaisyaErrorMessageFromJSON(json: any): SwSyogaisyaErrorMessage {
    return SwSyogaisyaErrorMessageFromJSONTyped(json, false);
}

export function SwSyogaisyaErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyogaisyaErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policy': !exists(json, 'policy') ? undefined : ((json['policy'] as Array<any>).map(SwErrorMessageFromJSON)),
        'syogaisyaErrors': !exists(json, 'syogaisyaErrors') ? undefined : ((json['syogaisyaErrors'] as Array<any>).map(SwSyogaisyaErrorFromJSON)),
    };
}

export function SwSyogaisyaErrorMessageToJSON(value?: SwSyogaisyaErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policy': value.policy === undefined ? undefined : ((value.policy as Array<any>).map(SwErrorMessageToJSON)),
        'syogaisyaErrors': value.syogaisyaErrors === undefined ? undefined : ((value.syogaisyaErrors as Array<any>).map(SwSyogaisyaErrorToJSON)),
    };
}


