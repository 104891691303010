/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwFamilyInfoErrorMessage,
    SwFamilyInfoErrorMessageFromJSON,
    SwFamilyInfoErrorMessageFromJSONTyped,
    SwFamilyInfoErrorMessageToJSON,
    SwJugyoryoInfoErrorMessage,
    SwJugyoryoInfoErrorMessageFromJSON,
    SwJugyoryoInfoErrorMessageFromJSONTyped,
    SwJugyoryoInfoErrorMessageToJSON,
    SwNyugakuryoInfoErrorMessage,
    SwNyugakuryoInfoErrorMessageFromJSON,
    SwNyugakuryoInfoErrorMessageFromJSONTyped,
    SwNyugakuryoInfoErrorMessageToJSON,
    SwSeisekiInfoErrorMessage,
    SwSeisekiInfoErrorMessageFromJSON,
    SwSeisekiInfoErrorMessageFromJSONTyped,
    SwSeisekiInfoErrorMessageToJSON,
    SwSinseisyaInfoErrorMessage,
    SwSinseisyaInfoErrorMessageFromJSON,
    SwSinseisyaInfoErrorMessageFromJSONTyped,
    SwSinseisyaInfoErrorMessageToJSON,
    SwSyogakukinInfoErrorMessage,
    SwSyogakukinInfoErrorMessageFromJSON,
    SwSyogakukinInfoErrorMessageFromJSONTyped,
    SwSyogakukinInfoErrorMessageToJSON,
    SwTokubetuKojoInfoErrorMessage,
    SwTokubetuKojoInfoErrorMessageFromJSON,
    SwTokubetuKojoInfoErrorMessageFromJSONTyped,
    SwTokubetuKojoInfoErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwYosikiInfoErrorMessageResponse
 */
export interface SwYosikiInfoErrorMessageResponse {
    /**
     * 
     * @type {SwSinseisyaInfoErrorMessage}
     * @memberof SwYosikiInfoErrorMessageResponse
     */
    sinseisyaInfoErrorMessage: SwSinseisyaInfoErrorMessage;
    /**
     * 
     * @type {SwSeisekiInfoErrorMessage}
     * @memberof SwYosikiInfoErrorMessageResponse
     */
    seisekiInfoErrorMessage: SwSeisekiInfoErrorMessage;
    /**
     * 
     * @type {SwSyogakukinInfoErrorMessage}
     * @memberof SwYosikiInfoErrorMessageResponse
     */
    syogakukinInfoErrorMessage: SwSyogakukinInfoErrorMessage;
    /**
     * 
     * @type {SwFamilyInfoErrorMessage}
     * @memberof SwYosikiInfoErrorMessageResponse
     */
    familyInfoErrorMessage: SwFamilyInfoErrorMessage;
    /**
     * 
     * @type {SwNyugakuryoInfoErrorMessage}
     * @memberof SwYosikiInfoErrorMessageResponse
     */
    nyugakuryoInfoErrorMessage: SwNyugakuryoInfoErrorMessage;
    /**
     * 
     * @type {SwJugyoryoInfoErrorMessage}
     * @memberof SwYosikiInfoErrorMessageResponse
     */
    jugyoryoInfoErrorMessage: SwJugyoryoInfoErrorMessage;
    /**
     * 
     * @type {SwTokubetuKojoInfoErrorMessage}
     * @memberof SwYosikiInfoErrorMessageResponse
     */
    tokubetuKojoInfoErrorMessage: SwTokubetuKojoInfoErrorMessage;
}

export function SwYosikiInfoErrorMessageResponseFromJSON(json: any): SwYosikiInfoErrorMessageResponse {
    return SwYosikiInfoErrorMessageResponseFromJSONTyped(json, false);
}

export function SwYosikiInfoErrorMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwYosikiInfoErrorMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sinseisyaInfoErrorMessage': SwSinseisyaInfoErrorMessageFromJSON(json['sinseisyaInfoErrorMessage']),
        'seisekiInfoErrorMessage': SwSeisekiInfoErrorMessageFromJSON(json['seisekiInfoErrorMessage']),
        'syogakukinInfoErrorMessage': SwSyogakukinInfoErrorMessageFromJSON(json['syogakukinInfoErrorMessage']),
        'familyInfoErrorMessage': SwFamilyInfoErrorMessageFromJSON(json['familyInfoErrorMessage']),
        'nyugakuryoInfoErrorMessage': SwNyugakuryoInfoErrorMessageFromJSON(json['nyugakuryoInfoErrorMessage']),
        'jugyoryoInfoErrorMessage': SwJugyoryoInfoErrorMessageFromJSON(json['jugyoryoInfoErrorMessage']),
        'tokubetuKojoInfoErrorMessage': SwTokubetuKojoInfoErrorMessageFromJSON(json['tokubetuKojoInfoErrorMessage']),
    };
}

export function SwYosikiInfoErrorMessageResponseToJSON(value?: SwYosikiInfoErrorMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sinseisyaInfoErrorMessage': SwSinseisyaInfoErrorMessageToJSON(value.sinseisyaInfoErrorMessage),
        'seisekiInfoErrorMessage': SwSeisekiInfoErrorMessageToJSON(value.seisekiInfoErrorMessage),
        'syogakukinInfoErrorMessage': SwSyogakukinInfoErrorMessageToJSON(value.syogakukinInfoErrorMessage),
        'familyInfoErrorMessage': SwFamilyInfoErrorMessageToJSON(value.familyInfoErrorMessage),
        'nyugakuryoInfoErrorMessage': SwNyugakuryoInfoErrorMessageToJSON(value.nyugakuryoInfoErrorMessage),
        'jugyoryoInfoErrorMessage': SwJugyoryoInfoErrorMessageToJSON(value.jugyoryoInfoErrorMessage),
        'tokubetuKojoInfoErrorMessage': SwTokubetuKojoInfoErrorMessageToJSON(value.tokubetuKojoInfoErrorMessage),
    };
}


