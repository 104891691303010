import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from "react";
import {TagColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface RequiredTagProps {
    type?: RequiredTagTypeEnum,
    className?: string
}

export const RequiredTag: FC<RequiredTagProps> = (props) => {

    const classes = tagStyles();

    let text: "必須" | "任意" | "";
    let type: "red" | "gray" | "none";
    switch (props.type) {
        case RequiredTagTypeEnum.必須:
            type = "red";
            text = "必須";
            break;
        case RequiredTagTypeEnum.任意:
            type = "gray";
            text = "任意";
            break;
        default:
            type = "none";
            text = "";
    }

    return (
        <div className={`requiredTag ${classes.root} ${props.className} ${classes[type]}`}>
            {text}
        </div>
    )
};

const tagStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        fontSize: 10,
        padding: "4px",
        verticalAlign: 'middle',
        boxSizing: 'border-box',
        display: "inline-block",
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: "8px",
    },
    red: {
        minWidth: 28,
        backgroundColor: TagColorsEnum.Red,
        color: TextColorsEnum.White,
    },
    gray: {
        minWidth: 28,
        backgroundColor: TagColorsEnum.LightGray,
        color: TextColorsEnum.DarkGray,
    },
    none: {
        display: "none",
    },
}));