/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SwOfficerKikansStudentId,
    SwOfficerKikansStudentIdFromJSON,
    SwOfficerKikansStudentIdToJSON,
} from '../models';

/**
 * no description
 */
export class StudentKikansApi extends runtime.BaseAPI {

    /**
     * 期間情報の取得 学生のログイン情報から部局、在校生か新入生か判断する 現在の時間がいずれかの期間に該当する場合、isCurrentがtrueとなる 期間がまだ設定されていない場合undefinedとして扱う 
     */
    async getStudentKikansRaw(): Promise<runtime.ApiResponse<SwOfficerKikansStudentId>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/kikans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOfficerKikansStudentIdFromJSON(jsonValue));
    }

    /**
     * 期間情報の取得 学生のログイン情報から部局、在校生か新入生か判断する 現在の時間がいずれかの期間に該当する場合、isCurrentがtrueとなる 期間がまだ設定されていない場合undefinedとして扱う 
     */
    async getStudentKikans(): Promise<SwOfficerKikansStudentId> {
        const response = await this.getStudentKikansRaw();
        return await response.value();
    }

}
