import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import React, {FC} from 'react';
import {BorderColorsEnum} from "common/enums_value/ColorsEnum";

interface Title02Props {
    className?: string
    title: string;
}

export const Title02: FC<Title02Props> = React.memo((props) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <div className={`${classes.root} ${matches && classes.xsRoot} ${props.className}`}>
            {props.title}
        </div>
    )
});

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        borderBottom: `4px solid ${BorderColorsEnum.LightGray}`,
        padding: "8px 0",
        fontSize: 24,
        fontWeight: "bold",
        position: 'relative',
        "&:after": {
            content: '""',
            position: "absolute",
            left: 0,
            bottom: -4,
            width: "24%",
            height: 0,
            borderBottom: `4px solid ${BorderColorsEnum.MuiBlue}`,
        },
    },
    xsRoot: {
        fontSize: 20,
    }
}));