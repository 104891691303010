/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SyoruiConfirmationFormMemberErrorMessage
 */
export interface SyoruiConfirmationFormMemberErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    zokugara?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    zokugaraOther?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    name?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    isJuminhyoDifference?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    isMusyokusya?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    isFinancialAssistanceFromRelatives?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    isIncomeFromInsuranceMoney?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    isRinjiIncome?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    isTaisyokuJiki?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    isPlanToFindJob?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    isKakeisijisya?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormMemberErrorMessage
     */
    isTaisyokuJikiForMusyokusya?: Array<SwErrorMessage>;
}

export function SyoruiConfirmationFormMemberErrorMessageFromJSON(json: any): SyoruiConfirmationFormMemberErrorMessage {
    return SyoruiConfirmationFormMemberErrorMessageFromJSONTyped(json, false);
}

export function SyoruiConfirmationFormMemberErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyoruiConfirmationFormMemberErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zokugara': !exists(json, 'zokugara') ? undefined : ((json['zokugara'] as Array<any>).map(SwErrorMessageFromJSON)),
        'zokugaraOther': !exists(json, 'zokugaraOther') ? undefined : ((json['zokugaraOther'] as Array<any>).map(SwErrorMessageFromJSON)),
        'name': !exists(json, 'name') ? undefined : ((json['name'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isJuminhyoDifference': !exists(json, 'isJuminhyoDifference') ? undefined : ((json['isJuminhyoDifference'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isMusyokusya': !exists(json, 'isMusyokusya') ? undefined : ((json['isMusyokusya'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isFinancialAssistanceFromRelatives': !exists(json, 'isFinancialAssistanceFromRelatives') ? undefined : ((json['isFinancialAssistanceFromRelatives'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isIncomeFromInsuranceMoney': !exists(json, 'isIncomeFromInsuranceMoney') ? undefined : ((json['isIncomeFromInsuranceMoney'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isRinjiIncome': !exists(json, 'isRinjiIncome') ? undefined : ((json['isRinjiIncome'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isTaisyokuJiki': !exists(json, 'isTaisyokuJiki') ? undefined : ((json['isTaisyokuJiki'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isPlanToFindJob': !exists(json, 'isPlanToFindJob') ? undefined : ((json['isPlanToFindJob'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isKakeisijisya': !exists(json, 'isKakeisijisya') ? undefined : ((json['isKakeisijisya'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isTaisyokuJikiForMusyokusya': !exists(json, 'isTaisyokuJikiForMusyokusya') ? undefined : ((json['isTaisyokuJikiForMusyokusya'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SyoruiConfirmationFormMemberErrorMessageToJSON(value?: SyoruiConfirmationFormMemberErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zokugara': value.zokugara === undefined ? undefined : ((value.zokugara as Array<any>).map(SwErrorMessageToJSON)),
        'zokugaraOther': value.zokugaraOther === undefined ? undefined : ((value.zokugaraOther as Array<any>).map(SwErrorMessageToJSON)),
        'name': value.name === undefined ? undefined : ((value.name as Array<any>).map(SwErrorMessageToJSON)),
        'isJuminhyoDifference': value.isJuminhyoDifference === undefined ? undefined : ((value.isJuminhyoDifference as Array<any>).map(SwErrorMessageToJSON)),
        'isMusyokusya': value.isMusyokusya === undefined ? undefined : ((value.isMusyokusya as Array<any>).map(SwErrorMessageToJSON)),
        'isFinancialAssistanceFromRelatives': value.isFinancialAssistanceFromRelatives === undefined ? undefined : ((value.isFinancialAssistanceFromRelatives as Array<any>).map(SwErrorMessageToJSON)),
        'isIncomeFromInsuranceMoney': value.isIncomeFromInsuranceMoney === undefined ? undefined : ((value.isIncomeFromInsuranceMoney as Array<any>).map(SwErrorMessageToJSON)),
        'isRinjiIncome': value.isRinjiIncome === undefined ? undefined : ((value.isRinjiIncome as Array<any>).map(SwErrorMessageToJSON)),
        'isTaisyokuJiki': value.isTaisyokuJiki === undefined ? undefined : ((value.isTaisyokuJiki as Array<any>).map(SwErrorMessageToJSON)),
        'isPlanToFindJob': value.isPlanToFindJob === undefined ? undefined : ((value.isPlanToFindJob as Array<any>).map(SwErrorMessageToJSON)),
        'isKakeisijisya': value.isKakeisijisya === undefined ? undefined : ((value.isKakeisijisya as Array<any>).map(SwErrorMessageToJSON)),
        'isTaisyokuJikiForMusyokusya': value.isTaisyokuJikiForMusyokusya === undefined ? undefined : ((value.isTaisyokuJikiForMusyokusya as Array<any>).map(SwErrorMessageToJSON)),
    };
}


