import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {SwSyoruiConfirmationResponse} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";
import {
    SyoruiConfirmationStoreVo,
    SyoruiConfirmationVo,
} from "../../../../common/models/SyoruiConfirmationModels";
import {ZokugaraEnum} from "../../../../common/enums/ZokugaraEnum";
import {SyoruiConfirmationApi} from "../../../../openapi/apis/SyoruiConfirmationApi";

// NOTE: 書類確認機能のstore
class StudentSyoruiConfirmationStore extends Store<SyoruiConfirmationStoreVo> {

    // NOTE: 使用api
    private syoruiConfirmationApi = new SyoruiConfirmationApi(apiConfiguration);

    constructor() {
        super({})
    }

    // // NOTE: エラーメッセージの更新
    // async updateSyoruiConfirmationErrorMessages(errorMessage: SwSyoruiConfirmationErrorMessageResponse | undefined) {
    //     this.dispatch(() => ({syoruiConfirmationFormErrorMessage: errorMessage}));
    // }

    // NOTE: フォーム情報更新
    async updateSyoruiConfirmation(vo: SyoruiConfirmationVo) {
        this.dispatch(() => ({syoruiConfirmation: vo}));
    }

    // NOTE: フォーム情報習得
    async getSyoruiConfirmation() {
        try {
            const res: SwSyoruiConfirmationResponse = await this.syoruiConfirmationApi.getSyoruiConfirmation();
            const vo: SyoruiConfirmationVo = {
                ...res,
                members: res.members?.map(m => {
                    return {
                        ...m,
                        zokugara: m.zokugara as ZokugaraEnum
                    }
                })
            };
            // await this.updateSyoruiConfirmationErrorMessages(undefined);
            this.updateSyoruiConfirmation(vo).then();
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const studentSyoruiConfirmationStore = new StudentSyoruiConfirmationStore();
