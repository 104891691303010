/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwOsirasePutErrorMessage,
    SwOsirasePutErrorMessageFromJSON,
    SwOsirasePutErrorMessageFromJSONTyped,
    SwOsirasePutErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20013
     */
    isSuccess: boolean;
    /**
     * 
     * @type {SwOsirasePutErrorMessage}
     * @memberof InlineResponse20013
     */
    error?: SwOsirasePutErrorMessage;
}

export function InlineResponse20013FromJSON(json: any): InlineResponse20013 {
    return InlineResponse20013FromJSONTyped(json, false);
}

export function InlineResponse20013FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20013 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSuccess': json['isSuccess'],
        'error': !exists(json, 'error') ? undefined : SwOsirasePutErrorMessageFromJSON(json['error']),
    };
}

export function InlineResponse20013ToJSON(value?: InlineResponse20013 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSuccess': value.isSuccess,
        'error': SwOsirasePutErrorMessageToJSON(value.error),
    };
}


