/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwSaigaihigai
 */
export interface SwSaigaihigai {
    /**
     * 
     * @type {string}
     * @memberof SwSaigaihigai
     */
    riyu: string;
    /**
     * 
     * @type {number}
     * @memberof SwSaigaihigai
     */
    amount?: number;
}

export function SwSaigaihigaiFromJSON(json: any): SwSaigaihigai {
    return SwSaigaihigaiFromJSONTyped(json, false);
}

export function SwSaigaihigaiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSaigaihigai {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'riyu': json['riyu'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function SwSaigaihigaiToJSON(value?: SwSaigaihigai | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'riyu': value.riyu,
        'amount': value.amount,
    };
}


