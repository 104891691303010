/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwSyunyuInfoErrorMessages,
    SwSyunyuInfoErrorMessagesFromJSON,
    SwSyunyuInfoErrorMessagesFromJSONTyped,
    SwSyunyuInfoErrorMessagesToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwFamilyInfoErrorMessageHonnin
 */
export interface SwFamilyInfoErrorMessageHonnin {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwFamilyInfoErrorMessageHonnin
     */
    syunyuAriNasi?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwFamilyInfoErrorMessageHonnin
     */
    kakuteisinkokuAriNasi?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwFamilyInfoErrorMessageHonnin
     */
    syogakukin?: Array<SwErrorMessage>;
    /**
     * 
     * @type {SwSyunyuInfoErrorMessages}
     * @memberof SwFamilyInfoErrorMessageHonnin
     */
    syunyuInfo?: SwSyunyuInfoErrorMessages;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwFamilyInfoErrorMessageHonnin
     */
    tugakuFromJitaku?: Array<SwErrorMessage>;
}

export function SwFamilyInfoErrorMessageHonninFromJSON(json: any): SwFamilyInfoErrorMessageHonnin {
    return SwFamilyInfoErrorMessageHonninFromJSONTyped(json, false);
}

export function SwFamilyInfoErrorMessageHonninFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwFamilyInfoErrorMessageHonnin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syunyuAriNasi': !exists(json, 'syunyuAriNasi') ? undefined : ((json['syunyuAriNasi'] as Array<any>).map(SwErrorMessageFromJSON)),
        'kakuteisinkokuAriNasi': !exists(json, 'kakuteisinkokuAriNasi') ? undefined : ((json['kakuteisinkokuAriNasi'] as Array<any>).map(SwErrorMessageFromJSON)),
        'syogakukin': !exists(json, 'syogakukin') ? undefined : ((json['syogakukin'] as Array<any>).map(SwErrorMessageFromJSON)),
        'syunyuInfo': !exists(json, 'syunyuInfo') ? undefined : SwSyunyuInfoErrorMessagesFromJSON(json['syunyuInfo']),
        'tugakuFromJitaku': !exists(json, 'tugakuFromJitaku') ? undefined : ((json['tugakuFromJitaku'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwFamilyInfoErrorMessageHonninToJSON(value?: SwFamilyInfoErrorMessageHonnin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'syunyuAriNasi': value.syunyuAriNasi === undefined ? undefined : ((value.syunyuAriNasi as Array<any>).map(SwErrorMessageToJSON)),
        'kakuteisinkokuAriNasi': value.kakuteisinkokuAriNasi === undefined ? undefined : ((value.kakuteisinkokuAriNasi as Array<any>).map(SwErrorMessageToJSON)),
        'syogakukin': value.syogakukin === undefined ? undefined : ((value.syogakukin as Array<any>).map(SwErrorMessageToJSON)),
        'syunyuInfo': SwSyunyuInfoErrorMessagesToJSON(value.syunyuInfo),
        'tugakuFromJitaku': value.tugakuFromJitaku === undefined ? undefined : ((value.tugakuFromJitaku as Array<any>).map(SwErrorMessageToJSON)),
    };
}


