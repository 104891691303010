import React, {FC} from "react";
import {BorderColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";
import {Box} from "@material-ui/core";
import {Button} from "../../atoms/Button";
import {StudentInfoStoreVo} from "../../../common/models/yosiki/StudentInfoStoreVo";
import {ContentBox} from "../../atoms/ContentBox";
import Grid from "@material-ui/core/Grid";

interface Props {
    studentInfo: StudentInfoStoreVo
    isKakutei: boolean
    handleClickSyoruiConfirmationForm: () => void
    handleClickSyoruiConfirmation: () => void
}

export const SyoruiConfirmation: FC<Props> = ({
                                                  studentInfo,
                                                  isKakutei,
                                                  handleClickSyoruiConfirmationForm,
                                                  handleClickSyoruiConfirmation
                                              }) => {

    return (

        <>

            <Box pt={2} borderTop={`1px solid ${BorderColorsEnum.LightGray}`}>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Box color={TextColorsEnum.Secondary} fontSize={20} fontWeight={700}>
                            [新機能]申請に必要な様式等の確認機能 / [New Function] Confirmation Function for Forms, etc.
                            Requiring
                            Application
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <ContentBox fontSize={12}>
                            <Box>
                                <Box>
                                    様式1の入力確定後に、申請に必要な様式等を提示することができます。<br/>
                                    ※提示内容は参考ですので、提示した以外の資料等が必要となる場合があります。
                                </Box>
                                <Box mt={1}>
                                    After the input of Form 1 is finalized, the forms, etc. requiring application can be
                                    presented.<br/>
                                    *The information provided is for reference only and other materials may be required.
                                </Box>
                            </Box>
                            <Box mt={1}>
                                <Box textAlign={"center"}>
                                    <Button
                                        className={"search_row_button"}
                                        type={"secondary"}
                                        size={"large"}
                                        width={"100%"}
                                        label={"申請に必要な様式等の確認機能 / Confirmation Function for Forms, etc. Requiring Application"}
                                        handleClick={handleClickSyoruiConfirmationForm}
                                        disabled={!isKakutei}
                                    />
                                </Box>
                                <Box textAlign={"center"} mt={1}>
                                    <Button
                                        className={"search_row_button"}
                                        type={"primary"}
                                        size={"large"}
                                        label={"申請に必要な様式等の確認機能（参考） / Confirmation Function for Forms, etc. Requiring Application (Reference)"}
                                        handleClick={handleClickSyoruiConfirmation}
                                        disabled={!studentInfo.isSyoruiHantei}
                                    />
                                </Box>

                            </Box>
                        </ContentBox>
                    </Grid>

                </Grid>

            </Box>

        </>

    )
};

