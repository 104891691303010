/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwOfficerMailPostRequest
 */
export interface SwOfficerMailPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SwOfficerMailPostRequest
     */
    mailTo: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerMailPostRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerMailPostRequest
     */
    body: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SwOfficerMailPostRequest
     */
    uploadedFileIds?: Array<string>;
}

export function SwOfficerMailPostRequestFromJSON(json: any): SwOfficerMailPostRequest {
    return SwOfficerMailPostRequestFromJSONTyped(json, false);
}

export function SwOfficerMailPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerMailPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mailTo': json['mailTo'],
        'title': json['title'],
        'body': json['body'],
        'uploadedFileIds': !exists(json, 'uploadedFileIds') ? undefined : json['uploadedFileIds'],
    };
}

export function SwOfficerMailPostRequestToJSON(value?: SwOfficerMailPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mailTo': value.mailTo,
        'title': value.title,
        'body': value.body,
        'uploadedFileIds': value.uploadedFileIds,
    };
}


