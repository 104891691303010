import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {
    BekkyoVo,
    BosiHusiVo,
    InfoPolicyRequireTokubetuKojoInfoVo,
    SaigaihigaiVo,
    SyogaisyaVo,
    TokubetuKojoInfoVo,
    TyokiryoyoVo
} from "common/models/YosikiInfoModels";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {ErrorCountMessage} from "components/atoms/ErrorCountMessage";
import {Title04} from "components/atoms/titles/Title04";
import {TokubetuKojoBekkyo} from "components/molecules/yosikiEdit/tokubetu_kojo/TokubetuKojoBekkyo";
import {TokubetuKojoBoshiHusi} from "components/molecules/yosikiEdit/tokubetu_kojo/TokubetuKojoBoshiHusi";
import {TokubetuKojoSaigaihigai} from "components/molecules/yosikiEdit/tokubetu_kojo/TokubetuKojoSaigaihigai";
import {TokubetuKojoSyogaisya} from "components/molecules/yosikiEdit/tokubetu_kojo/TokubetuKojoSyogaisya";
import {TokubetuKojoTyokiryoyo} from "components/molecules/yosikiEdit/tokubetu_kojo/TokubetuKojoTyokiryoyo";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {
    SwBekkyoErrorMessage,
    SwBosiHusiErrorMessage,
    SwSyogaisyaError,
    SwTokubetuKojoInfoErrorMessage,
    SwTyokiryoyoError
} from "openapi/models";
import React, {FC} from "react";

interface TokubetuKojoInfoSectionProps {
    className?: string
    requireTokubetuKojoInfo: InfoPolicyRequireTokubetuKojoInfoVo
    tokubetuKojoInfo: TokubetuKojoInfoVo;
    tokubetuKojoInfoErrorMessage: SwTokubetuKojoInfoErrorMessage
    updateTokubetuKojoInfo: (part: Partial<TokubetuKojoInfoVo>) => void
    updateTokubetuKojoInfoErrorMessage: (part: Partial<SwTokubetuKojoInfoErrorMessage>) => void
    updateAddSyogaisyaZokugaraModalOpenState: (value: boolean) => void
    updateAddTyokiryoyoZokugaraModalOpenState: (value: boolean) => void
    errorCount: number
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
}

export const _TokubetuKojoInfoSection: FC<TokubetuKojoInfoSectionProps> = (props) => {

    const classes = useStyles();

    // 母子父子
    function updateBosiHusi(part: Partial<BosiHusiVo>) {
        let tokubetuKojoInfo = props.tokubetuKojoInfo;
        let newMessage: TokubetuKojoInfoVo = {
            ...tokubetuKojoInfo,
            bosiHusi: {...tokubetuKojoInfo.bosiHusi, ...part}
        };
        props.updateTokubetuKojoInfo(newMessage);
    }

    function updateBosiHusiErrorMessage(part: Partial<SwBosiHusiErrorMessage>) {
        let tokubetuKojoInfoErrorMessage = props.tokubetuKojoInfoErrorMessage;
        let newMessage: SwTokubetuKojoInfoErrorMessage = {
            ...tokubetuKojoInfoErrorMessage,
            bosiHusi: {...tokubetuKojoInfoErrorMessage.bosiHusi, ...part}
        };
        props.updateTokubetuKojoInfoErrorMessage(newMessage);
    }

    function clearBosiHusiErrorMessage(part: Partial<SwBosiHusiErrorMessage>) {
        updateBosiHusiErrorMessage(part);
    }

    function handleChangeBosiHusi(part: Partial<BosiHusiVo>) {
        updateBosiHusi(part);
    }

    // 障害者
    function updateSyogaisya(part: Partial<SyogaisyaVo>, index: number) {
        let syogaisya = props.tokubetuKojoInfo.syogaisya;
        const newSyogaisya = {
            ...syogaisya[index],
            ...part
        };
        syogaisya.splice(index, 1, newSyogaisya);
        const tokubetuKojoInfo: TokubetuKojoInfoVo = {
            ...props.tokubetuKojoInfo,
            syogaisya: syogaisya
        };
        props.updateTokubetuKojoInfo(tokubetuKojoInfo)
    }

    function deleteSyogaisya(index: number) {
        let syogaisya = props.tokubetuKojoInfo.syogaisya;
        syogaisya.splice(index, 1);
        const tokubetuKojoInfo: TokubetuKojoInfoVo = {
            ...props.tokubetuKojoInfo,
            syogaisya: syogaisya
        };
        props.updateTokubetuKojoInfo(tokubetuKojoInfo)
    }

    function handleChangeSyogaisya(part: Partial<SyogaisyaVo>, index: number) {
        updateSyogaisya(part, index);
    }

    function handleClickDeleteSyogaisya(index: number) {
        deleteSyogaisya(index);
    }

    function handleClickAddSyogaisyaZokugara() {
        props.updateAddSyogaisyaZokugaraModalOpenState(true);
        let newMessage: SwTokubetuKojoInfoErrorMessage = {
            ...props.tokubetuKojoInfoErrorMessage,
            syogaisya: {
                ...props.tokubetuKojoInfoErrorMessage.syogaisya,
                policy: undefined
            }
        };
        props.updateTokubetuKojoInfoErrorMessage(newMessage)
    }

    function clearSyogaisyaErrorMessage(part: Partial<SwSyogaisyaError>, index: number) {
        let tokubetuKojoInfoErrorMessage = props.tokubetuKojoInfoErrorMessage;
        let syogaisyaErrors: Array<SwSyogaisyaError> | undefined = props.tokubetuKojoInfoErrorMessage.syogaisya.syogaisyaErrors;
        if (syogaisyaErrors === undefined) return;
        let targetSyogaisya = syogaisyaErrors[index];
        let newSyogaisyaErrorMessage: SwSyogaisyaError = {
            ...targetSyogaisya,
            ...part
        };
        syogaisyaErrors.splice(index, 1, newSyogaisyaErrorMessage);
        let newMessage: SwTokubetuKojoInfoErrorMessage = {
            ...tokubetuKojoInfoErrorMessage,
            syogaisya: {
                ...tokubetuKojoInfoErrorMessage.syogaisya,
                ...syogaisyaErrors
            }
        };
        props.updateTokubetuKojoInfoErrorMessage(newMessage)
    }

    function clearDeleteSyogaisyaErrorMessage(index: number) {
        let tokubetuKojoInfoErrorMessage = props.tokubetuKojoInfoErrorMessage;
        let syogaisyaErrors: Array<SwSyogaisyaError> | undefined = props.tokubetuKojoInfoErrorMessage.syogaisya.syogaisyaErrors;
        if (syogaisyaErrors === undefined) return;
        syogaisyaErrors.splice(index, 1,);
        let newMessage: Partial<SwTokubetuKojoInfoErrorMessage> = {
            syogaisya: {
                ...tokubetuKojoInfoErrorMessage.syogaisya,
                syogaisyaErrors: syogaisyaErrors
            }
        };
        props.updateTokubetuKojoInfoErrorMessage(newMessage)
    }

    // 長期療養
    function handleChangeTyokiryoyo(part: Partial<TyokiryoyoVo>, index: number) {
        let tyokiryoyo = props.tokubetuKojoInfo.tyokiryoyo;
        const newTyokiryoyo: TyokiryoyoVo = {
            ...tyokiryoyo[index],
            ...part
        };
        tyokiryoyo.splice(index, 1, newTyokiryoyo);
        const tokubetuKojoInfo: TokubetuKojoInfoVo = {
            ...props.tokubetuKojoInfo,
            tyokiryoyo: tyokiryoyo
        };
        props.updateTokubetuKojoInfo(tokubetuKojoInfo)
    }

    function handleClickDeleteTyokiryoyo(index: number) {
        let tyokiryoyo = props.tokubetuKojoInfo.tyokiryoyo;
        tyokiryoyo.splice(index, 1);
        const tokubetuKojoInfo: TokubetuKojoInfoVo = {
            ...props.tokubetuKojoInfo,
            tyokiryoyo: tyokiryoyo
        };
        props.updateTokubetuKojoInfo(tokubetuKojoInfo);
    }

    function handleClickAddTyokiryoyoZokugara() {
        props.updateAddTyokiryoyoZokugaraModalOpenState(true);
        let newMessage: SwTokubetuKojoInfoErrorMessage = {
            ...props.tokubetuKojoInfoErrorMessage,
            tyokiryoyo: {
                ...props.tokubetuKojoInfoErrorMessage.tyokiryoyo,
                policy: undefined
            }
        };
        props.updateTokubetuKojoInfoErrorMessage(newMessage)
    }

    function clearTyokiryoyoErrorMessage(part: Partial<SwTyokiryoyoError>, index: number) {
        let tokubetuKojoInfoErrorMessage = props.tokubetuKojoInfoErrorMessage;
        let tyokiryoyoErrors: Array<SwTyokiryoyoError> | undefined = props.tokubetuKojoInfoErrorMessage.tyokiryoyo.tyokiryoyoErrors;
        if (tyokiryoyoErrors === undefined) return;
        let targetTyokiryoyo: SwTyokiryoyoError = tyokiryoyoErrors[index];
        let newTyokiryoyoErrorMessage: SwTyokiryoyoError = {
            ...targetTyokiryoyo,
            ...part
        };
        tyokiryoyoErrors.splice(index, 1, newTyokiryoyoErrorMessage);
        let newMessage: Partial<SwTokubetuKojoInfoErrorMessage> = {
            tyokiryoyo: {
                ...tokubetuKojoInfoErrorMessage.tyokiryoyo,
                tyokiryoyoErrors: tyokiryoyoErrors
            }
        };
        props.updateTokubetuKojoInfoErrorMessage(newMessage);
    }

    function clearDeleteTyokiryoyoErrorMessage(index: number) {
        let tokubetuKojoInfoErrorMessage = props.tokubetuKojoInfoErrorMessage;
        let tyokiryoyoErrors: Array<SwTyokiryoyoError> | undefined = props.tokubetuKojoInfoErrorMessage.tyokiryoyo.tyokiryoyoErrors;
        if (tyokiryoyoErrors === undefined) return;
        tyokiryoyoErrors.splice(index, 1);
        let newMessage: Partial<SwTokubetuKojoInfoErrorMessage> = {
            tyokiryoyo: {
                ...tokubetuKojoInfoErrorMessage.tyokiryoyo,
                tyokiryoyoErrors: tyokiryoyoErrors
            }
        };
        props.updateTokubetuKojoInfoErrorMessage(newMessage)
    }

    // 別居
    function handleBlurBekkyo(part: Partial<BekkyoVo>) {
        let bekkyo = props.tokubetuKojoInfo.bekkyo;
        const tokubetuKojoInfo: TokubetuKojoInfoVo = {
            ...props.tokubetuKojoInfo,
            bekkyo: {
                ...bekkyo,
                ...part
            }
        };
        props.updateTokubetuKojoInfo(tokubetuKojoInfo)
    }

    function clearBekkyoErrorMessage(part: Partial<SwBekkyoErrorMessage>) {
        let tokubetuKojoInfoErrorMessage = props.tokubetuKojoInfoErrorMessage;
        let newMessage: SwTokubetuKojoInfoErrorMessage = {
            ...tokubetuKojoInfoErrorMessage,
            bekkyo: {...tokubetuKojoInfoErrorMessage.bekkyo, ...part}
        };
        props.updateTokubetuKojoInfoErrorMessage(newMessage);
    }

    // 災害被害
    function handleBlurSaigaihigai(part: Partial<SaigaihigaiVo>) {
        let saigaihigai = props.tokubetuKojoInfo.saigaihigai;
        const tokubetuKojoInfo: TokubetuKojoInfoVo = {
            ...props.tokubetuKojoInfo,
            saigaihigai: {
                ...saigaihigai,
                ...part
            }
        };
        props.updateTokubetuKojoInfo(tokubetuKojoInfo);
    }

    function clearSaigaihigaiErrorMessage(part: Partial<SwBekkyoErrorMessage>) {
        let tokubetuKojoInfoErrorMessage = props.tokubetuKojoInfoErrorMessage;
        let newMessage: SwTokubetuKojoInfoErrorMessage = {
            ...tokubetuKojoInfoErrorMessage,
            saigaihigai: {...tokubetuKojoInfoErrorMessage.saigaihigai, ...part}
        };
        props.updateTokubetuKojoInfoErrorMessage(newMessage);
    }

    const tokubetuKojoInfo: TokubetuKojoInfoVo = props.tokubetuKojoInfo;
    const tokubetuKojoErrorMessage: SwTokubetuKojoInfoErrorMessage = props.tokubetuKojoInfoErrorMessage;

    const bosiHusi = tokubetuKojoInfo.bosiHusi;
    const bosiHusiErrorMessage = tokubetuKojoErrorMessage.bosiHusi;

    const syogaisya = tokubetuKojoInfo.syogaisya;
    const syogaisyaErrorMessage = tokubetuKojoErrorMessage.syogaisya;

    const tyokiryoyo = tokubetuKojoInfo.tyokiryoyo;
    const tyokiryoyoErrorMessage = tokubetuKojoErrorMessage.tyokiryoyo;

    const bekkyo = tokubetuKojoInfo.bekkyo;
    const bekkyoErrorMessage = tokubetuKojoErrorMessage.bekkyo;

    const saigaihigai = tokubetuKojoInfo.saigaihigai;
    const saigaihigaiErrorMessage = tokubetuKojoErrorMessage.saigaihigai;

    return (

        <div id={SECTION_ID_NAME.tokubetuKojoInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.特別控除)}
                className={"section_title"}
            />

            <ErrorCountMessage
                count={props.errorCount}
            />

            {
                props.requireTokubetuKojoInfo.bosiHusi &&
                <TokubetuKojoBoshiHusi
                    bosiHusi={bosiHusi}
                    bosiHusiErrorMessage={bosiHusiErrorMessage}
                    handleChangeBosiHusi={handleChangeBosiHusi}
                    clearBosiHusiErrorMessage={clearBosiHusiErrorMessage}
                />
            }

            {
                props.requireTokubetuKojoInfo.syogaisya &&
                <TokubetuKojoSyogaisya
                    syogaisya={syogaisya}
                    syogaisyaErrorMessage={syogaisyaErrorMessage}
                    handleChangeSyogaisya={handleChangeSyogaisya}
                    handleClickDeleteSyogaisya={handleClickDeleteSyogaisya}
                    handleClickAddSyogaisyaZokugara={handleClickAddSyogaisyaZokugara}
                    clearSyogaisyaErrorMessage={clearSyogaisyaErrorMessage}
                    clearDeleteSyogaisyaErrorMessage={clearDeleteSyogaisyaErrorMessage}
                />
            }

            {
                props.requireTokubetuKojoInfo.tyokiryoyo &&
                <TokubetuKojoTyokiryoyo
                    tyokiryoyo={tyokiryoyo}
                    tyokiryoyoErrorMessage={tyokiryoyoErrorMessage}
                    handleChangeTyokiryoyo={handleChangeTyokiryoyo}
                    handleClickDeleteTyokiryoyo={handleClickDeleteTyokiryoyo}
                    handleClickAddTyokiryoyoZokugara={handleClickAddTyokiryoyoZokugara}
                    clearTyokiryoyoErrorMessage={clearTyokiryoyoErrorMessage}
                    clearDeleteTyokiryoyoErrorMessage={clearDeleteTyokiryoyoErrorMessage}
                />
            }

            {
                props.requireTokubetuKojoInfo.bekkyo &&
                <TokubetuKojoBekkyo
                    bekkyo={bekkyo}
                    handleBlurBekkyo={handleBlurBekkyo}
                    clearBekkyoErrorMessage={clearBekkyoErrorMessage}
                    bekkyoErrorMessage={bekkyoErrorMessage}
                />
            }

            {
                props.requireTokubetuKojoInfo.saigaihigai &&
                <TokubetuKojoSaigaihigai
                    saigaihigai={saigaihigai}
                    handleBlurSaigaihigai={handleBlurSaigaihigai}
                    saigaihigaiErrorMessage={saigaihigaiErrorMessage}
                    clearSaigaihigaiErrorMessage={clearSaigaihigaiErrorMessage}
                    onClickWordDescriptionLink={props.onClickWordDescriptionLink}
                />
            }

        </div>

    )
};

export const TokubetuKojoInfoSection = React.memo<TokubetuKojoInfoSectionProps>(_TokubetuKojoInfoSection, (prev, next) => {
    return prev.className === next.className
        && prev.requireTokubetuKojoInfo === next.requireTokubetuKojoInfo
        && prev.tokubetuKojoInfo === next.tokubetuKojoInfo
        && prev.tokubetuKojoInfoErrorMessage === next.tokubetuKojoInfoErrorMessage
        && prev.updateTokubetuKojoInfo === next.updateTokubetuKojoInfo
        && prev.updateTokubetuKojoInfoErrorMessage === next.updateTokubetuKojoInfoErrorMessage
        && prev.updateAddSyogaisyaZokugaraModalOpenState === next.updateAddSyogaisyaZokugaraModalOpenState
        && prev.updateAddTyokiryoyoZokugaraModalOpenState === next.updateAddTyokiryoyoZokugaraModalOpenState
        && prev.errorCount === next.errorCount
});

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        }
    }
);

