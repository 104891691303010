/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwOfficerSyorikiInfoPostRequest
 */
export interface SwOfficerSyorikiInfoPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SwOfficerSyorikiInfoPostRequest
     */
    year: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerSyorikiInfoPostRequest
     */
    gakki: string;
}

export function SwOfficerSyorikiInfoPostRequestFromJSON(json: any): SwOfficerSyorikiInfoPostRequest {
    return SwOfficerSyorikiInfoPostRequestFromJSONTyped(json, false);
}

export function SwOfficerSyorikiInfoPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerSyorikiInfoPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'gakki': json['gakki'],
    };
}

export function SwOfficerSyorikiInfoPostRequestToJSON(value?: SwOfficerSyorikiInfoPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'gakki': value.gakki,
    };
}


