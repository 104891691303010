import {
    YosikiEditOpenModalType,
    YosikiInfoModalStoreVo,
    YosikiKakuteiModalType
} from "common/models/yosiki/YosikiInfoModalStoreVo";
import {SwErrorMessage} from "openapi/models";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {Store} from "stores/Store";

class YosikiInfoModalStore extends Store<YosikiInfoModalStoreVo> {

    constructor() {
        super({
            backButtonUnsavedAttentionModalOpenState: false,
            basicInfoModalOpenState: false,
            deleteZokugaraModalOpenState: false,
            selectZokugaraModalOpenState: false,
            addSyogaisyaZokugaraModalOpenState: false,
            addTyokiryoyoZokugaraModalOpenState: false,
            selectedZokugaraIndex: undefined,
        })
    }

    updateBackButtonUnsavedAttentionModalOpenState(value: boolean) {
        this.dispatch(() => ({backButtonUnsavedAttentionModalOpenState: value}));
    }

    updateBasicInfoModalOpenState(value: boolean) {
        this.dispatch(() => ({basicInfoModalOpenState: value}));
    }

    updateSelectZokugaraModalOpenState(value: boolean) {
        this.dispatch(() => ({selectZokugaraModalOpenState: value}));
    }

    updateDeleteZokugaraModalOpenState(value: boolean) {
        this.dispatch(() => ({deleteZokugaraModalOpenState: value}));
    }

    updateAddSyogaisyaZokugaraModalOpenState(value: boolean) {
        this.dispatch(() => ({addSyogaisyaZokugaraModalOpenState: value}));
    }

    updateAddTyokiryoyoZokugaraModalOpenState(value: boolean) {
        this.dispatch(() => ({addTyokiryoyoZokugaraModalOpenState: value}));
    }

    updateSelectedZokugaraIndex(value: number) {
        this.dispatch(() => ({selectedZokugaraIndex: value}));
    }

    updateYosikiKakuteiModalType(openModalType: YosikiKakuteiModalType | undefined) {
        this.dispatch(() => ({yosikiKakuteiOpenModalType: openModalType}));
    }

    updateYosikiEditOpenModalType(openModalType: YosikiEditOpenModalType | undefined) {
        this.dispatch(() => ({yosikiEditOpenModalType: openModalType}));
    }

    updateWordDescriptionType(wordDescriptionType: WordDescriptionTypeEnum | undefined) {
        this.dispatch(() => ({wordDescriptionType: wordDescriptionType}));
    }

    updateKakuteiModalErrorMessage(errorMessage: Array<SwErrorMessage> | undefined){
        this.dispatch(() => ({yosikiInfoKakuteiModalErrorMessage: errorMessage}));
    }

    updateKakuteiKaijoModalErrorMessage(errorMessage: Array<SwErrorMessage> | undefined){
        this.dispatch(() => ({yosikiInfoKakuteiKaijoModalErrorMessage: errorMessage}));
    }

}

export const yosikiInfoModalStore = new YosikiInfoModalStore();
