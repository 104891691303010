// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum YosikiStateEnum {
    未入力 = "E未入力",
    未確定 = "E未確定",
    確定 = "E確定",
}

export function yosikiStateEnumToName(e: YosikiStateEnum): { ja: string, en: string } {
    switch (e) {
        case YosikiStateEnum.未入力: return { ja: "未入力", en: "Not entered" };
        case YosikiStateEnum.未確定: return { ja: "未確定", en: "Not confirmed" };
        case YosikiStateEnum.確定: return { ja: "確定", en: "Confirmed" };
    }
}