import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "../../../../common/enums_text/yosiki/FamilyInfoTextEnum";
import {RequiredTag} from "../../../atoms/tags/RequiredTag";
import {RequiredTagTypeEnum} from "../../../../common/enums_value/RequiredTagTypeEnum";
import {RadioButtonGroup} from "../../forms/RadioButtonGroup";
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import {RadioButton} from "../../../atoms/forms/RadioButton";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "../../../../common/enums_text/CommonTextEnum";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {KakuteisinkokuField} from "./KakuteisinkokuField";
import {GensentyosyuField} from "./GensentyosyuField";
import {OtherSyunyuField} from "./OtherSyunyuField";
import {SyunyuInfoList} from "./SyunyuInfoList";
import React, {FC} from "react";
import {SyunyuInfoVo} from "../../../../common/models/YosikiInfoModels";
import {SwErrorMessage, SwSyunyuInfoErrorMessages} from "../../../../openapi";

interface Props {
    isHonnin: boolean
    syunyuInfo: SyunyuInfoVo
    syunyuInfoErrorMessages?: SwSyunyuInfoErrorMessages
    kakuteisinkokuAriNasi?: boolean
    kakuteisinkokuAriNasiErrorMessage?: Array<SwErrorMessage>
    totalAmounts: {
        syotoku: number
        syotokuIgai: number
    }
    updateSyunyuInfo: (syunyuInfo: SyunyuInfoVo) => void
    updateSyunyuInfoErrorMessages: (errorMessages: SwSyunyuInfoErrorMessages) => void
    handleChangeKakuteisinkokuAriNasi: (value: boolean) => void
    handleClearSyogakukinError?: () => void
}

export const SyunyuContents: FC<Props> = ({
                                              isHonnin,
                                              syunyuInfo,
                                              syunyuInfoErrorMessages,
                                              kakuteisinkokuAriNasi,
                                              kakuteisinkokuAriNasiErrorMessage,
                                              updateSyunyuInfo,
                                              updateSyunyuInfoErrorMessages,
                                              handleChangeKakuteisinkokuAriNasi,
                                              totalAmounts,
                                              handleClearSyogakukinError
                                          }) => {
    return (
        <>
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.この続柄の方の令和5年分の確定申告の有無について入力してください)}<RequiredTag
                    type={RequiredTagTypeEnum.必須}/>
                </div>
                <RadioButtonGroup
                    errorMessages={convertErrorMessageVoToArrayStr(kakuteisinkokuAriNasiErrorMessage)}
                    vertical={true}
                >
                    <RadioButton
                        value={""}
                        checked={kakuteisinkokuAriNasi === true}
                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.あり)}
                        isError={kakuteisinkokuAriNasiErrorMessage && kakuteisinkokuAriNasiErrorMessage.length !== 0}
                        handleChange={() => {
                            handleChangeKakuteisinkokuAriNasi(true)
                        }}
                    />
                    <RadioButton
                        value={""}
                        checked={kakuteisinkokuAriNasi === false}
                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.なし)}
                        isError={kakuteisinkokuAriNasiErrorMessage && kakuteisinkokuAriNasiErrorMessage.length !== 0}
                        handleChange={() => {
                            handleChangeKakuteisinkokuAriNasi(false)
                        }}
                    />
                </RadioButtonGroup>
            </div>

            <Box mt={1}>
                <Grid container spacing={1} direction={"column"}>
                    {
                        // NOTE: 確定申告から入力
                        kakuteisinkokuAriNasi === true ? <>
                            <Grid item>
                                <KakuteisinkokuField
                                    syunyuInfo={syunyuInfo}
                                    syunyuInfoErrorMessages={syunyuInfoErrorMessages}
                                    updateSyunyuInfo={updateSyunyuInfo}
                                    updateSyunyuInfoErrorMessages={updateSyunyuInfoErrorMessages}
                                />
                            </Grid>
                        </> : null
                    }
                    {
                        // NOTE: 源泉徴収から入力
                        kakuteisinkokuAriNasi === false ? <>
                            <Grid item>
                                <GensentyosyuField
                                    syunyuInfo={syunyuInfo}
                                    syunyuInfoErrorMessages={syunyuInfoErrorMessages}
                                    updateSyunyuInfo={updateSyunyuInfo}
                                    updateSyunyuInfoErrorMessages={updateSyunyuInfoErrorMessages}
                                />
                            </Grid>
                        </> : null
                    }
                    {/* NOTE: その他所得入力 */}
                    <Grid item>
                        <OtherSyunyuField
                            isHonnin={isHonnin}
                            syunyuInfo={syunyuInfo}
                            syunyuInfoErrorMessages={syunyuInfoErrorMessages}
                            updateSyunyuInfo={updateSyunyuInfo}
                            updateSyunyuInfoErrorMessages={updateSyunyuInfoErrorMessages}
                            handleClearSyogakukinError={handleClearSyogakukinError}
                        />
                    </Grid>
                </Grid>
            </Box>
            {/* NOTE: 金額一覧 */}
            <div className={"formTableRow_item"}>
                <SyunyuInfoList
                    syunyuInfo={syunyuInfo}
                    kakuteisinkokuAriNasi={kakuteisinkokuAriNasi}
                />
            </div>
            {/* NOTE: 金額合計 */}
            <div className={"formTableRow_item formTableRow_item-borederTop"}>
                <div>
                    <div>
                        <span>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.給与所得の収入金額税込合計)}</span><br/>{totalAmounts.syotoku.toLocaleString()}円
                    </div>
                    <div>
                        <span>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.給与所得以外の所得金額税込合計)}</span><br/>{totalAmounts.syotokuIgai.toLocaleString()}円
                    </div>
                </div>
            </div>

        </>
    )
}