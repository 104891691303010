import makeStyles from "@material-ui/core/styles/makeStyles";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {SaigaihigaiVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {
    TokubetuKojoLabelTextEnum,
    TokubetuKojoLabelTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/TokubetuKojoLabelTextEnum";
import {
    TitleNameTextEnum,
    TitleNameTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/TitleNameTextEnum";
import {NumberField} from "components/atoms/forms/NumberField";
import {TextField} from "components/atoms/forms/TextField";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {DescriptionLink} from "components/atoms/DescriptionLink";
import {AnnotationText} from "components/atoms/AnnotationText";
import {Title05} from "components/atoms/titles/Title05";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {DescriptionLinkWrap} from "components/molecules/utilities/DescriptionLinkWrap";
import {SwSaigaihigaiErrorMessage} from "openapi/models";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface TokubetuKojoSaigaihigaiProps {
    saigaihigai: SaigaihigaiVo
    handleBlurSaigaihigai: (part: Partial<SaigaihigaiVo>) => void
    saigaihigaiErrorMessage: SwSaigaihigaiErrorMessage
    clearSaigaihigaiErrorMessage: (prat: Partial<SwSaigaihigaiErrorMessage>) => void
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
}

export const TokubetuKojoSaigaihigai: FC<TokubetuKojoSaigaihigaiProps> = (props) => {

    const classes = useStyles();

    function handleBlurSaigaihigaiRiyu(value: string) {
        props.handleBlurSaigaihigai({riyu: value});
        props.clearSaigaihigaiErrorMessage({riyu: undefined});
    }

    function handleBlurSaigaihigaiAmount(value: string) {
        props.handleBlurSaigaihigai({amount: value});
        props.clearSaigaihigaiErrorMessage({amount: undefined});
    }

    const saigaihigai: SaigaihigaiVo = props.saigaihigai;
    const saigaihigaiErrorMessage: SwSaigaihigaiErrorMessage = props.saigaihigaiErrorMessage;

    return (

        <>
            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.火災風水害盗難等の被害を受けた世帯)}
                className={classes.title}
            />

            <FormTable
                className={"section_table"}
            >
                <FormTableRow
                    label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.火災風水害盗難等の被害を受けた世帯)}
                >
                    <AnnotationText
                        type={"warning"}
                        text={CommonTextEnumToNameJaAndEn(CommonTextEnum.わからない場合は空欄にしてください)}
                    />
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.被害内容)}<RequiredTag type={RequiredTagTypeEnum.任意}/>
                        </div>
                        <TextField
                            value={saigaihigai.riyu}
                            handleBlur={(value) => handleBlurSaigaihigaiRiyu(value)}
                            errorMessages={convertErrorMessageVoToArrayStr(saigaihigaiErrorMessage.riyu)}
                            isError={convertErrorMessageVoToArrayStr(saigaihigaiErrorMessage.riyu).length !== 0}
                        />
                    </div>
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.被害額)}<RequiredTag type={RequiredTagTypeEnum.任意}/>
                        </div>
                        <NumberField
                            value={saigaihigai.amount}
                            handleBlur={(value) => handleBlurSaigaihigaiAmount(value)}
                            errorMessages={convertErrorMessageVoToArrayStr(saigaihigaiErrorMessage.amount)}
                            isError={convertErrorMessageVoToArrayStr(saigaihigaiErrorMessage.amount).length !== 0}
                            suffixText={"円"}
                            widthInput={"80px"}
                        />
                        <DescriptionLinkWrap>
                            <DescriptionLink
                                onClickWordDescriptionLink={props.onClickWordDescriptionLink}
                                type={WordDescriptionTypeEnum.被害額欄}
                            />
                        </DescriptionLinkWrap>

                    </div>
                </FormTableRow>

            </FormTable>
        </>

    )
};

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        }
    }
);

