// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum SchoolTypeEnum {
    小学校 = "E小学校",
    中学校 = "E中学校",
    高校 = "E高校",
    大学_学部 = "E大学_学部",
    大学_大学院 = "E大学_大学院",
    高専 = "E高専",
    専修高等 = "E専修高等",
    専修専門 = "E専修専門",
}

export function schoolTypeEnumToName(e: SchoolTypeEnum): { ja: string, en: string } {
    switch (e) {
        case SchoolTypeEnum.小学校: return { ja: "小学校", en: "Elementary school" };
        case SchoolTypeEnum.中学校: return { ja: "中学校", en: "Junior high school" };
        case SchoolTypeEnum.高校: return { ja: "高校", en: "High school" };
        case SchoolTypeEnum.大学_学部: return { ja: "大学(学部)", en: "University (undergraduate)" };
        case SchoolTypeEnum.大学_大学院: return { ja: "大学(大学院)", en: "University (graduate)" };
        case SchoolTypeEnum.高専: return { ja: "高専", en: "College of technology" };
        case SchoolTypeEnum.専修高等: return { ja: "専修学校（高等課程）", en: "Specialized trainingcollege (upper secondary course)" };
        case SchoolTypeEnum.専修専門: return { ja: "専修学校（専門課程）", en: "Specialized training college (post secondary course)" };
    }
}