/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20014,
    InlineResponse20014FromJSON,
    InlineResponse20014ToJSON,
    SwOfficerKiaknDetail,
    SwOfficerKiaknDetailFromJSON,
    SwOfficerKiaknDetailToJSON,
    SwOfficerKiakns,
    SwOfficerKiaknsFromJSON,
    SwOfficerKiaknsToJSON,
    SwOfficerKikanPostRequest,
    SwOfficerKikanPostRequestFromJSON,
    SwOfficerKikanPostRequestToJSON,
    SwOfficerKikansStudentId,
    SwOfficerKikansStudentIdFromJSON,
    SwOfficerKikansStudentIdToJSON,
} from '../models';

export interface GetOfficerKikansBukyokuCodeRequest {
    bukyokuCode: string;
    isSinnyusei?: boolean;
}

export interface GetOfficerKikansStudentIdRequest {
    studentNumber: string;
}

export interface PostOfficerKikansRequest {
    swOfficerKikanPostRequest: SwOfficerKikanPostRequest;
}

/**
 * no description
 */
export class OfficerKikansApi extends runtime.BaseAPI {

    /**
     * 期間一覧 bukyokuEnumの順番 値がない場合は全部局の空の値を返す 秋の場合は新入生は大学院のみ返す 
     */
    async getOfficerKikansRaw(): Promise<runtime.ApiResponse<SwOfficerKiakns>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/kikans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOfficerKiaknsFromJSON(jsonValue));
    }

    /**
     * 期間一覧 bukyokuEnumの順番 値がない場合は全部局の空の値を返す 秋の場合は新入生は大学院のみ返す 
     */
    async getOfficerKikans(): Promise<SwOfficerKiakns> {
        const response = await this.getOfficerKikansRaw();
        return await response.value();
    }

    /**
     * 部局と学生(新入生 or 在学生)で特定の期間情報を返す 
     */
    async getOfficerKikansBukyokuCodeRaw(requestParameters: GetOfficerKikansBukyokuCodeRequest): Promise<runtime.ApiResponse<SwOfficerKiaknDetail>> {
        if (requestParameters.bukyokuCode === null || requestParameters.bukyokuCode === undefined) {
            throw new runtime.RequiredError('bukyokuCode','Required parameter requestParameters.bukyokuCode was null or undefined when calling getOfficerKikansBukyokuCode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.isSinnyusei !== undefined) {
            queryParameters['isSinnyusei'] = requestParameters.isSinnyusei;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/kikans_for_bukyoku/{bukyokuCode}`.replace(`{${"bukyokuCode"}}`, encodeURIComponent(String(requestParameters.bukyokuCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOfficerKiaknDetailFromJSON(jsonValue));
    }

    /**
     * 部局と学生(新入生 or 在学生)で特定の期間情報を返す 
     */
    async getOfficerKikansBukyokuCode(requestParameters: GetOfficerKikansBukyokuCodeRequest): Promise<SwOfficerKiaknDetail> {
        const response = await this.getOfficerKikansBukyokuCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * 期間情報の取得 学籍番号から学生の部局、在校生か新入生か判断する 現在の時間がいずれかの期間に該当する場合、isCurrentがtrueとなる 期間がまだ設定されていない場合undefinedとして扱う 
     */
    async getOfficerKikansStudentIdRaw(requestParameters: GetOfficerKikansStudentIdRequest): Promise<runtime.ApiResponse<SwOfficerKikansStudentId>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling getOfficerKikansStudentId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/kikans_for_student/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOfficerKikansStudentIdFromJSON(jsonValue));
    }

    /**
     * 期間情報の取得 学籍番号から学生の部局、在校生か新入生か判断する 現在の時間がいずれかの期間に該当する場合、isCurrentがtrueとなる 期間がまだ設定されていない場合undefinedとして扱う 
     */
    async getOfficerKikansStudentId(requestParameters: GetOfficerKikansStudentIdRequest): Promise<SwOfficerKikansStudentId> {
        const response = await this.getOfficerKikansStudentIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * 期間の保存 各期間は重複不可 開始と終了が同一時刻でもエラーとならない 開始は以上で判定、終了は未満で判定なので、期間の終了と次の期間の開始が同一でもエラーではない 
     */
    async postOfficerKikansRaw(requestParameters: PostOfficerKikansRequest): Promise<runtime.ApiResponse<InlineResponse20014>> {
        if (requestParameters.swOfficerKikanPostRequest === null || requestParameters.swOfficerKikanPostRequest === undefined) {
            throw new runtime.RequiredError('swOfficerKikanPostRequest','Required parameter requestParameters.swOfficerKikanPostRequest was null or undefined when calling postOfficerKikans.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/officer/kikans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwOfficerKikanPostRequestToJSON(requestParameters.swOfficerKikanPostRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20014FromJSON(jsonValue));
    }

    /**
     * 期間の保存 各期間は重複不可 開始と終了が同一時刻でもエラーとならない 開始は以上で判定、終了は未満で判定なので、期間の終了と次の期間の開始が同一でもエラーではない 
     */
    async postOfficerKikans(requestParameters: PostOfficerKikansRequest): Promise<InlineResponse20014> {
        const response = await this.postOfficerKikansRaw(requestParameters);
        return await response.value();
    }

}
