import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {FamilyInfoHonninVo, FamilyInfoVo} from "common/models/YosikiInfoModels";
import {FamilyInfoLabelTextEnum, FamilyInfoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoLabelTextEnum";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {RadioButton} from "components/atoms/forms/RadioButton";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {DescriptionLink} from "components/atoms/DescriptionLink";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {RadioButtonGroup} from "components/molecules/forms/RadioButtonGroup";
import {DescriptionLinkWrap} from "components/molecules/utilities/DescriptionLinkWrap";
import {SwFamilyInfoErrorMessageHonnin} from "openapi/models";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface FamilyInfoSectionSyugakusyaHoninProps {
    honnin: FamilyInfoHonninVo
    updateFamilyInfo: (part: Partial<FamilyInfoVo>) => void
    updateHonninErrorMessages: (part: Partial<SwFamilyInfoErrorMessageHonnin>) => void
    honninErrorMessages: SwFamilyInfoErrorMessageHonnin;
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
}

export const FamilyInfoSectionSyugakusyaHonin: FC<FamilyInfoSectionSyugakusyaHoninProps> = (props) => {

    function handleChangeTugakuFromJitaku(value: boolean) {
        let honnin = props.honnin;
        honnin = {
            ...honnin,
            tugakuFromJitaku: value
        };
        let part: Partial<FamilyInfoVo> = {honnin: honnin};
        props.updateFamilyInfo(part);
        props.updateHonninErrorMessages({tugakuFromJitaku: undefined})
    }

    const honnin: FamilyInfoHonninVo = props.honnin;

    return (
        <FormTableRow
            label={FamilyInfoLabelTextEnumToNameJaAndEn(FamilyInfoLabelTextEnum.本人)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.通学区分)}<RequiredTag
                    type={RequiredTagTypeEnum.必須}/>
                </div>
                <RadioButtonGroup
                    errorMessages={convertErrorMessageVoToArrayStr(props.honninErrorMessages.tugakuFromJitaku)}
                    vertical={true}
                >
                    <RadioButton
                        value={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅)}
                        checked={honnin.tugakuFromJitaku === true}
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅)}
                        isError={props.honninErrorMessages.tugakuFromJitaku && props.honninErrorMessages.tugakuFromJitaku.length !== 0}
                        handleChange={() => handleChangeTugakuFromJitaku(true)}
                    />
                    <RadioButton
                        value={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅外)}
                        checked={honnin.tugakuFromJitaku === false}
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅外)}
                        isError={props.honninErrorMessages.tugakuFromJitaku && props.honninErrorMessages?.tugakuFromJitaku.length !== 0}
                        handleChange={() => handleChangeTugakuFromJitaku(false)}
                    />
                </RadioButtonGroup>

                <DescriptionLinkWrap>
                    <DescriptionLink
                        onClickWordDescriptionLink={props.onClickWordDescriptionLink}
                        type={WordDescriptionTypeEnum.通学区分}
                    />
                </DescriptionLinkWrap>

            </div>

        </FormTableRow>
    )
};

