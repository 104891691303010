import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import React, {FC} from "react";
import {BgColorsEnum, BorderColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";

interface SelectboxProps {
    isDisabled?: boolean;
    isError?: boolean;
    name?: string;
    value: string | number;
    handleChange: (value: string, name?: string) => void
    prefixText?: string;
    suffixText?: string;
    open?: boolean;
    onClose?: (event: React.ChangeEvent<{}>) => void;
    onOpen?: (event: React.ChangeEvent<{}>) => void;
    errorMessages?: Array<string>;
}

export const Selectbox: FC<SelectboxProps> = (props) => {
    const classes = useStyles();
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        props.handleChange(event.target.value as string, event.target.name)
    };

    return (
        <div>

            <div className={classes.root}>
                {
                    props.prefixText &&
                    <div className={classes.prefixText}>
                        {props.prefixText}
                    </div>
                }
                <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${props.isDisabled && classes.disabled} ${props.isError && classes.error}`}
                    disabled={props.isDisabled}
                    error={props.isError}
                >
                    <InputLabel ref={inputLabel}/>
                    <Select
                        native={!props.open}
                        value={props.value}
                        onChange={handleChange}
                        open={props.open}
                        onClose={props.onClose}
                        onOpen={props.onOpen}
                        input={
                            <OutlinedInput
                                name={props.name}
                                labelWidth={labelWidth}
                                id="outlined-age-native-simple"
                            />
                        }
                    >
                        {props.children}
                    </Select>
                </FormControl>
                {
                    props.suffixText &&
                    <div className={classes.suffixText}>
                        {props.suffixText}
                    </div>
                }
            </div>

            {
                props.errorMessages && props.errorMessages.length > 0 ?
                    <ErrorMessages
                        className={classes.errorMessages}
                        errorMessages={props.errorMessages}
                    />
                    : null
            }

        </div>
    )
};

Selectbox.defaultProps = {
    isDisabled: false,
    isError: false,
    prefixText: "",
    suffixText: "",
};


const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center",
    },
    prefixText: {
        marginRight: "8px",
    },
    suffixText: {
        marginLeft: "8px",
    },
    formControl: {
        '& .MuiOutlinedInput-input': {
            padding: "10px 8px",
            paddingRight: 24,
            backgroundColor: BgColorsEnum.White,
        },
        "& .MuiSelect-icon": {
            top: "calc(50% - 9px)",
            right: 3,
        }
    },
    error: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: `${BorderColorsEnum.Red}!important`,
        },
    },
    disabled: {
        '& .MuiOutlinedInput-input': {
            backgroundColor: BgColorsEnum.LightGray,
            color: TextColorsEnum.Black,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: BorderColorsEnum.LightGray,
        },
    },
    errorMessages: {
        marginTop: 4,
    },
});



