import makeStyles from "@material-ui/core/styles/makeStyles";
import {tyokiryoyoTypeEnumToName} from "common/enums/TyokiryoyoTypeEnum";
import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {TyokiryoyoVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {TitleNameTextEnum, TitleNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TitleNameTextEnum";
import {TokubetuKojoLabelTextEnum, TokubetuKojoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TokubetuKojoLabelTextEnum";
import {Title05} from "components/atoms/titles/Title05";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {FC} from "react";
import {convertStrToNumOrUndefined} from "common/functions/type_converter/convertStrToNumOrUndefined";

interface TokubetuKojoTyokiryoyoDetailProps {
    tyokiryoyo: Array<TyokiryoyoVo>
}

interface TyokiryoyoRowDetailProps {
    tyokiryoyo: TyokiryoyoVo
    index: number
}

const TyokiryoyoDetailRow: FC<TyokiryoyoRowDetailProps> = (props) => {

    const tyokiryoyo = props.tyokiryoyo;

    return (
        <FormTableRow
            label={convertZokugaraForStr(tyokiryoyo.zokugara, tyokiryoyo.zokugaraOther)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}
                </div>
                {tyokiryoyo.type ? convertEnumNameToStr(tyokiryoyoTypeEnumToName(tyokiryoyo.type)) : ""}
            </div>

            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.療養開始時期)}
                </div>
                {tyokiryoyo.startYear}年
                {tyokiryoyo.startMonth}月
            </div>

            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.半年当たりの療養費)}
                </div>
                {convertStrToNumOrUndefined(tyokiryoyo.amountPerHalfYear)?.toLocaleString()}円
            </div>

        </FormTableRow>
    )

};

export const TokubetuKojoTyokiryoyoDetail: FC<TokubetuKojoTyokiryoyoDetailProps> = (props) => {

    const classes = useStyles();
    const tyokiryoyo: Array<TyokiryoyoVo> = props.tyokiryoyo;

    return (

        <>
            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.長期療養者のいる世帯)}
                className={classes.title}
            />

            {tyokiryoyo.length === 0 ? <div>{CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を追加してください)}</div> : null}

            <FormTable
                className={"section_table"}
            >

                {
                    tyokiryoyo.map((t: TyokiryoyoVo, index: number) => {
                        return (
                            <TyokiryoyoDetailRow
                                key={t.zokugara + index}
                                index={index}
                                tyokiryoyo={t}
                            />
                        )
                    })
                }

            </FormTable>

        </>

    )
};

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        },
        bottomRow: {
            marginTop: 4
        },
    }
);

