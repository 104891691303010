/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSyunyuInfoItem,
    SwSyunyuInfoItemFromJSON,
    SwSyunyuInfoItemFromJSONTyped,
    SwSyunyuInfoItemToJSON,
    SwSyunyuInfoItemOther,
    SwSyunyuInfoItemOtherFromJSON,
    SwSyunyuInfoItemOtherFromJSONTyped,
    SwSyunyuInfoItemOtherToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSyunyuInfo
 */
export interface SwSyunyuInfo {
    /**
     * 
     * @type {SwSyunyuInfoItem}
     * @memberof SwSyunyuInfo
     */
    kyuyo: SwSyunyuInfoItem;
    /**
     * 
     * @type {SwSyunyuInfoItem}
     * @memberof SwSyunyuInfo
     */
    eigyo: SwSyunyuInfoItem;
    /**
     * 
     * @type {SwSyunyuInfoItem}
     * @memberof SwSyunyuInfo
     */
    norin: SwSyunyuInfoItem;
    /**
     * 
     * @type {SwSyunyuInfoItem}
     * @memberof SwSyunyuInfo
     */
    hudosan: SwSyunyuInfoItem;
    /**
     * 
     * @type {SwSyunyuInfoItem}
     * @memberof SwSyunyuInfo
     */
    risi: SwSyunyuInfoItem;
    /**
     * 
     * @type {SwSyunyuInfoItem}
     * @memberof SwSyunyuInfo
     */
    haito: SwSyunyuInfoItem;
    /**
     * 
     * @type {SwSyunyuInfoItemOther}
     * @memberof SwSyunyuInfo
     */
    nenkin: SwSyunyuInfoItemOther;
    /**
     * 
     * @type {SwSyunyuInfoItemOther}
     * @memberof SwSyunyuInfo
     */
    koyohoken: SwSyunyuInfoItemOther;
    /**
     * 
     * @type {SwSyunyuInfoItemOther}
     * @memberof SwSyunyuInfo
     */
    kakusyuteate: SwSyunyuInfoItemOther;
    /**
     * 
     * @type {SwSyunyuInfoItemOther}
     * @memberof SwSyunyuInfo
     */
    syogakukin: SwSyunyuInfoItemOther;
    /**
     * 
     * @type {SwSyunyuInfoItemOther}
     * @memberof SwSyunyuInfo
     */
    sinsekitounosien: SwSyunyuInfoItemOther;
    /**
     * 
     * @type {SwSyunyuInfoItemOther}
     * @memberof SwSyunyuInfo
     */
    yotyokin: SwSyunyuInfoItemOther;
    /**
     * 
     * @type {SwSyunyuInfoItemOther}
     * @memberof SwSyunyuInfo
     */
    other: SwSyunyuInfoItemOther;
    /**
     * 
     * @type {SwSyunyuInfoItemOther}
     * @memberof SwSyunyuInfo
     */
    taisyokukinn: SwSyunyuInfoItemOther;
    /**
     * 
     * @type {SwSyunyuInfoItemOther}
     * @memberof SwSyunyuInfo
     */
    hokenkin: SwSyunyuInfoItemOther;
}

export function SwSyunyuInfoFromJSON(json: any): SwSyunyuInfo {
    return SwSyunyuInfoFromJSONTyped(json, false);
}

export function SwSyunyuInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyunyuInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kyuyo': SwSyunyuInfoItemFromJSON(json['kyuyo']),
        'eigyo': SwSyunyuInfoItemFromJSON(json['eigyo']),
        'norin': SwSyunyuInfoItemFromJSON(json['norin']),
        'hudosan': SwSyunyuInfoItemFromJSON(json['hudosan']),
        'risi': SwSyunyuInfoItemFromJSON(json['risi']),
        'haito': SwSyunyuInfoItemFromJSON(json['haito']),
        'nenkin': SwSyunyuInfoItemOtherFromJSON(json['nenkin']),
        'koyohoken': SwSyunyuInfoItemOtherFromJSON(json['koyohoken']),
        'kakusyuteate': SwSyunyuInfoItemOtherFromJSON(json['kakusyuteate']),
        'syogakukin': SwSyunyuInfoItemOtherFromJSON(json['syogakukin']),
        'sinsekitounosien': SwSyunyuInfoItemOtherFromJSON(json['sinsekitounosien']),
        'yotyokin': SwSyunyuInfoItemOtherFromJSON(json['yotyokin']),
        'other': SwSyunyuInfoItemOtherFromJSON(json['other']),
        'taisyokukinn': SwSyunyuInfoItemOtherFromJSON(json['taisyokukinn']),
        'hokenkin': SwSyunyuInfoItemOtherFromJSON(json['hokenkin']),
    };
}

export function SwSyunyuInfoToJSON(value?: SwSyunyuInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kyuyo': SwSyunyuInfoItemToJSON(value.kyuyo),
        'eigyo': SwSyunyuInfoItemToJSON(value.eigyo),
        'norin': SwSyunyuInfoItemToJSON(value.norin),
        'hudosan': SwSyunyuInfoItemToJSON(value.hudosan),
        'risi': SwSyunyuInfoItemToJSON(value.risi),
        'haito': SwSyunyuInfoItemToJSON(value.haito),
        'nenkin': SwSyunyuInfoItemOtherToJSON(value.nenkin),
        'koyohoken': SwSyunyuInfoItemOtherToJSON(value.koyohoken),
        'kakusyuteate': SwSyunyuInfoItemOtherToJSON(value.kakusyuteate),
        'syogakukin': SwSyunyuInfoItemOtherToJSON(value.syogakukin),
        'sinsekitounosien': SwSyunyuInfoItemOtherToJSON(value.sinsekitounosien),
        'yotyokin': SwSyunyuInfoItemOtherToJSON(value.yotyokin),
        'other': SwSyunyuInfoItemOtherToJSON(value.other),
        'taisyokukinn': SwSyunyuInfoItemOtherToJSON(value.taisyokukinn),
        'hokenkin': SwSyunyuInfoItemOtherToJSON(value.hokenkin),
    };
}


