import {Button} from "components/atoms/Button";
import React, {FC, useCallback, useEffect, useState} from "react";
import {Modal} from "components/atoms/Modal";
import DialogActions from "@material-ui/core/DialogActions";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "../../../../common/enums_text/CommonTextEnum";
import {NumberField} from "../../../atoms/forms/NumberField";
import {SyunyuKubunEnum, syunyuKubunEnumToName} from "../../../../common/enums/SyunyuKubunEnum";
import {toStrJaEn} from "../../../../common/functions/converter/toStrJaEn";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import {SyunyuInfoItemFieldVo, SyunyuInfoVo} from "../../../../common/models/YosikiInfoModels";
import lodash from 'lodash';
import {BgColorsEnum} from "../../../../common/enums_value/ColorsEnum";
import {TextField} from "../../../atoms/forms/TextField";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "../../../../common/enums_text/yosiki/FamilyInfoTextEnum";
import {YearMonth} from "../../forms/YearMonth";
import {SwSyunyuInfoErrorMessages} from "../../../../openapi";
import {isErrorMessage} from "../../../../common/functions/isErrorMessage";
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import {ErrorMessages} from "../../../atoms/ErrorMessages";

interface ItemProps {
    label: string
    noteJa: React.ReactNode
    noteEn: React.ReactNode
    bg?: boolean
    matches: boolean
}

const FieldItem: FC<ItemProps> = ({
                                      label,
                                      noteJa,
                                      noteEn,
                                      bg,
                                      matches,
                                      children
                                  }) => {
    return (
        <Box py={2} px={matches ? 1 : 2} bgcolor={bg ? BgColorsEnum.OffWhite : "none"}>
            <Box fontWeight={700} fontSize={14}>{label}</Box>
            {noteJa ? <Box mt={1}>{noteJa}</Box> : null}
            {noteEn ? <Box mt={0.5}>{noteEn}</Box> : null}
            <Box mt={1}>
                {children}
            </Box>
        </Box>
    )
}


interface Props {
    isHonnin: boolean
    openState: boolean
    handleCloseModal: () => void
    handleConfirm: (v: SyunyuInfoVo, errorMessages?: SwSyunyuInfoErrorMessages) => void
    syunyuInfo: SyunyuInfoVo
    syunyuInfoErrorMessages?: SwSyunyuInfoErrorMessages
    handleClearSyogakukinError?: () => void
}

export const OtherSyunyuFieldModal: FC<Props> = ({
                                                     isHonnin,
                                                     openState,
                                                     handleCloseModal,
                                                     handleConfirm,
                                                     syunyuInfo,
                                                     syunyuInfoErrorMessages,
                                                     handleClearSyogakukinError
                                                 }) => {

    const [formValues, updateFormValue] = useState<SyunyuInfoVo>()
    const [errorMessages, updateErrorMessages] = useState<SwSyunyuInfoErrorMessages>()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        if (openState) {
            // NOTE: 参照しないようにオブジェクトをclone
            const copiedSyunyuInfo = lodash.cloneDeep(syunyuInfo) as SyunyuInfoVo;
            const copiedErrorMessages = lodash.cloneDeep(syunyuInfoErrorMessages) as SwSyunyuInfoErrorMessages;
            // NOTE: 初期化
            updateFormValue(copiedSyunyuInfo)
            updateErrorMessages(copiedErrorMessages)
        }
    }, [openState, syunyuInfo, syunyuInfoErrorMessages])

    const updates = useCallback((formValues: SyunyuInfoVo, errorMessages?: SwSyunyuInfoErrorMessages) => {
        updateFormValue({...formValues})
        updateErrorMessages({...errorMessages})
    }, [updateFormValue, updateErrorMessages])

    const updateNenkin = useCallback((v: string, i: number) => {
        if (!formValues) return
        formValues.nenkin.fields[i].amount = v
        errorMessages?.nenkin?.fields.splice(i, 1, [])
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateKoyohoken = useCallback((v: string, i: number) => {
        if (!formValues) return
        formValues.koyohoken.fields[i].amount = v
        errorMessages?.koyohoken?.fields.splice(i, 1, [])
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateKakusyuteate = useCallback((v: string, i: number) => {
        if (!formValues) return
        formValues.kakusyuteate.fields[i].amount = v
        errorMessages?.kakusyuteate?.fields.splice(i, 1, [])
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateSyogakukin = useCallback((v: string, i: number) => {
        if (!formValues) return
        formValues.syogakukin.fields[i].amount = v
        errorMessages?.syogakukin?.fields.splice(i, 1, [])
        updates(formValues, errorMessages)
        if (isHonnin) {
            // NOTE: 本人で奨学金エラーがあればクリア
            handleClearSyogakukinError && handleClearSyogakukinError()
        }
    }, [formValues, errorMessages, updates, isHonnin, handleClearSyogakukinError])

    const updateSinsekitounosien = useCallback((v: string, i: number) => {
        if (!formValues) return
        formValues.sinsekitounosien.fields[i].amount = v
        errorMessages?.sinsekitounosien?.fields.splice(i, 1, [])
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateYotyokin = useCallback((v: string, i: number) => {
        if (!formValues) return
        formValues.yotyokin.fields[i].amount = v
        errorMessages?.yotyokin?.fields.splice(i, 1, [])
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateOther = useCallback((p: Partial<SyunyuInfoItemFieldVo>, i: number) => {
        if (!formValues) return
        formValues.other.fields[i] = {...formValues.other.fields[i], ...p}
        errorMessages?.other?.fields.splice(i, 1, [])
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateTaisyokukinn = useCallback((p: Partial<SyunyuInfoItemFieldVo>, i: number) => {
        if (!formValues) return
        formValues.taisyokukinn.fields[i] = {...formValues.taisyokukinn.fields[i], ...p}
        errorMessages?.taisyokukinn?.fields.splice(i, 1, [])
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateHokenkin = useCallback((p: Partial<SyunyuInfoItemFieldVo>, i: number) => {
        if (!formValues) return
        formValues.hokenkin.fields[i] = {...formValues.hokenkin.fields[i], ...p}
        errorMessages?.hokenkin?.fields.splice(i, 1, [])
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    if (formValues === undefined) {
        return <></>
    }

    return (
        <Modal
            openState={openState}
            title={"その他の収入または所得を入力 / Enter other revenue or income"}
        >

            <Box mb={4}>
                以下の区分に該当する所得がある場合は、金額を入力してください。<br/>
                入力欄が足りない場合は、合計金額を入力してください。<br/>
                If you have income that falls into any of the following classifications, please enter the amount.<br/>
                If there are not enough input fields, please enter the total amount.
            </Box>

            <Box>

                <FieldItem
                    matches={matches}
                    label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.年金恩給))}
                    noteJa={"最新の支払い通知書等から算出される受給(見込)年額"}
                    noteEn={"Annual amount of (estimated) benefits calculated from the most recent payment notice, etc."}
                    bg={true}
                >
                    <Grid container spacing={1}>
                        {formValues.nenkin.fields.map((f, i) => {
                            const errors = errorMessages?.nenkin?.fields[i]
                            return (
                                <Grid key={i} item xs={12}>
                                    <NumberField
                                        widthInput={"120px"}
                                        value={f.amount}
                                        handleBlur={(v) => updateNenkin(v, i)}
                                        suffixText={"円"}
                                        isError={isErrorMessage(errors)}
                                        errorMessages={convertErrorMessageVoToArrayStr(errors)}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </FieldItem>

                <FieldItem
                    matches={matches}
                    label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.雇用保険))}
                    noteJa={"雇用保険受給資格証の“基本手当日額”× “所定給付日数”の額"}
                    noteEn={"Amount of \"basic allowance daily amount\" × \"prescribed number of benefit days\" on the unemployment insurance qualification certificate"}
                >
                    <Grid container spacing={1}>
                        {formValues.koyohoken.fields.map((f, i) => {
                            const errors = errorMessages?.koyohoken?.fields[i]
                            return (
                                <Grid key={i} item xs={12}>
                                    <NumberField
                                        widthInput={"120px"}
                                        value={f.amount}
                                        handleBlur={(v) => updateKoyohoken(v, i)}
                                        suffixText={"円"}
                                        isError={isErrorMessage(errors)}
                                        errorMessages={convertErrorMessageVoToArrayStr(errors)}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </FieldItem>
                <FieldItem
                    matches={matches}
                    label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.各種手当))}
                    noteJa={"受領額が確認できる証明書類に記載の金額"}
                    noteEn={"The amount stated on the certification document confirming the amount received"}
                    bg={true}
                >
                    <Grid container spacing={1}>
                        {formValues.kakusyuteate.fields.map((f, i) => {
                            const errors = errorMessages?.kakusyuteate?.fields[i]
                            return (
                                <Grid key={i} item xs={12}>
                                    <NumberField
                                        widthInput={"120px"}
                                        value={f.amount}
                                        handleBlur={(v) => updateKakusyuteate(v, i)}
                                        suffixText={"円"}
                                        isError={isErrorMessage(errors)}
                                        errorMessages={convertErrorMessageVoToArrayStr(errors)}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </FieldItem>
                <FieldItem
                    matches={matches}
                    label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.奨学金))}
                    noteJa={<span>自己申告(<span className={"fw700"}>年間の支給額を記入してください。</span>)</span>}
                    noteEn={"Self-assessment (Please enter the allowance paid per year.)"}
                >
                    <Grid container spacing={1}>
                        {formValues.syogakukin.fields.map((f, i) => {
                            const errors = errorMessages?.syogakukin?.fields[i]
                            return (
                                <Grid key={i} item xs={12}>
                                    <NumberField
                                        widthInput={"120px"}
                                        value={f.amount}
                                        handleBlur={(v) => updateSyogakukin(v, i)}
                                        suffixText={"円"}
                                        isError={isErrorMessage(errors)}
                                        errorMessages={convertErrorMessageVoToArrayStr(errors)}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </FieldItem>
                <FieldItem
                    matches={matches}
                    label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.親戚等の援助))}
                    noteJa={"自己申告"}
                    noteEn={"Self-assessment"}
                    bg={true}
                >
                    <Grid container spacing={1}>
                        {formValues.sinsekitounosien.fields.map((f, i) => {
                            const errors = errorMessages?.sinsekitounosien?.fields[i]
                            return (
                                <Grid key={i} item xs={12}>
                                    <NumberField
                                        widthInput={"120px"}
                                        value={f.amount}
                                        handleBlur={(v) => updateSinsekitounosien(v, i)}
                                        suffixText={"円"}
                                        isError={isErrorMessage(errors)}
                                        errorMessages={convertErrorMessageVoToArrayStr(errors)}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </FieldItem>

                <FieldItem
                    matches={matches}
                    label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.預貯金から))}
                    noteJa={"自己申告"}
                    noteEn={"Self-assessment"}
                >
                    <Grid container spacing={1}>
                        {formValues.yotyokin.fields.map((f, i) => {
                            const errors = errorMessages?.yotyokin?.fields[i]
                            return (
                                <Grid key={i} item xs={12}>
                                    <NumberField
                                        widthInput={"120px"}
                                        value={f.amount}
                                        handleBlur={(v) => updateYotyokin(v, i)}
                                        suffixText={"円"}
                                        isError={isErrorMessage(errors)}
                                        errorMessages={convertErrorMessageVoToArrayStr(errors)}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </FieldItem>

                <FieldItem
                    matches={matches}
                    label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.その他))}
                    noteJa={"自己申告"}
                    noteEn={"Self-assessment"}
                    bg={true}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box display={"flex"} style={{gap: "4px"}}>
                                <Box width={"200px"}
                                     fontWeight={700}>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.名称)}</Box>
                                <Box width={"120px"}
                                     minWidth={"96px"}
                                     fontWeight={700}>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.金額)}</Box>
                            </Box>
                        </Grid>
                        {formValues.other.fields.map((f, i) => {
                            const errors = errorMessages?.other?.fields[i]
                            return (

                                <Grid key={i} item xs={12}>
                                    <Box display={"flex"} style={{gap: "4px"}}>
                                        <Box width={"200px"}>
                                            <TextField
                                                value={f.kubunOther}
                                                handleBlur={(v) => updateOther({kubunOther: v}, i)}
                                                isError={isErrorMessage(errors)}
                                            />
                                        </Box>
                                        <Box width={"120px"}
                                             minWidth={"96px"}>
                                            <NumberField
                                                value={f.amount}
                                                handleBlur={(v) => updateOther({amount: v}, i)}
                                                suffixText={"円"}
                                                isError={isErrorMessage(errors)}
                                            />
                                        </Box>
                                    </Box>
                                    {
                                        errors &&
                                        <Box>
                                            <ErrorMessages
                                                errorMessages={convertErrorMessageVoToArrayStr(errors)}
                                            />
                                        </Box>
                                    }
                                </Grid>
                            )
                        })}
                    </Grid>
                </FieldItem>

                <FieldItem
                    matches={matches}
                    label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.退職金))}
                    noteJa={"昨年4月〜本年3月に退職金の臨時所得があった場合、退職所得の源泉徴収票に記載の金額"}
                    noteEn={"If there was extraordinary income from retirement benefits from April last year to March this year, the amount shown on your retirement income withholding tax certificate"}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box display={"flex"} style={{gap: "4px"}}>
                                <Box width={"120px"}
                                     minWidth={"96px"}
                                     fontWeight={700}>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.金額)}</Box>
                                <Box width={"auto"}
                                     fontWeight={700}>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.所得時期)}</Box>
                            </Box>
                        </Grid>
                        {formValues.taisyokukinn.fields.map((f, i) => {
                            const errors = errorMessages?.taisyokukinn?.fields[i]
                            return (
                                <Grid key={i} item xs={12}>
                                    <Box display={"flex"} style={{gap: "4px"}}>
                                        <Box width={"120px"}
                                             minWidth={"96px"}>
                                            <NumberField
                                                value={f.amount}
                                                handleBlur={(v) => updateTaisyokukinn({amount: v}, i)}
                                                suffixText={"円"}
                                                isError={isErrorMessage(errors)}
                                            />
                                        </Box>
                                        <Box>
                                            <YearMonth
                                                valueYear={f.syotokuYear}
                                                valueMonth={f.syotokuMonth}
                                                suffixText={"所得"}
                                                handleBlurYear={(v) => updateTaisyokukinn({syotokuYear: v}, i)}
                                                handleBlurMonth={(v) => updateTaisyokukinn({syotokuMonth: v}, i)}
                                                isError={isErrorMessage(errors)}
                                            />
                                        </Box>
                                    </Box>
                                    {
                                        errors &&
                                        <Box>
                                            <ErrorMessages
                                                errorMessages={convertErrorMessageVoToArrayStr(errors)}
                                            />
                                        </Box>
                                    }
                                </Grid>
                            )
                        })}
                    </Grid>
                </FieldItem>

                <FieldItem
                    matches={matches}
                    label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.保険金))}
                    noteJa={"自己申告"}
                    noteEn={"Self-assessment"}
                    bg={true}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box display={"flex"} style={{gap: "4px"}}>
                                <Box width={"120px"}
                                     minWidth={"96px"}
                                     fontWeight={700}>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.金額)}</Box>
                                <Box width={"auto"}
                                     fontWeight={700}>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.所得時期)}</Box>
                            </Box>
                        </Grid>
                        {formValues.hokenkin.fields.map((f, i) => {
                            const errors = errorMessages?.hokenkin?.fields[i]
                            return (
                                <Grid key={i} item xs={12}>
                                    <Box display={"flex"} style={{gap: "4px"}}>
                                        <Box width={"120px"}
                                             minWidth={"96px"}>
                                            <NumberField
                                                value={f.amount}
                                                handleBlur={(v) => updateHokenkin({amount: v}, i)}
                                                suffixText={"円"}
                                                isError={isErrorMessage(errors)}
                                            />
                                        </Box>
                                        <Box>
                                            <YearMonth
                                                valueYear={f.syotokuYear}
                                                valueMonth={f.syotokuMonth}
                                                suffixText={"所得"}
                                                handleBlurYear={(v) => updateHokenkin({syotokuYear: v}, i)}
                                                handleBlurMonth={(v) => updateHokenkin({syotokuMonth: v}, i)}
                                                isError={isErrorMessage(errors)}
                                            />
                                        </Box>
                                    </Box>
                                    {
                                        errors &&
                                        <Box>
                                            <ErrorMessages
                                                errorMessages={convertErrorMessageVoToArrayStr(errors)}
                                            />
                                        </Box>
                                    }
                                </Grid>
                            )
                        })}
                    </Grid>
                </FieldItem>

            </Box>

            <Box textAlign={"center"} mt={4}>
                「確定」を押すと入力内容が反映されます。「閉じる」を押すと入力内容は反映されません。<br/>
                Click "Confirm" to apply the information you have entered. If you click "Close", the entered information
                will not be reflected.
            </Box>

            <DialogActions className={"modal_actions"}>

                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.閉じる)}
                    width={"120px"}
                    handleClick={handleCloseModal}
                />

                <Button
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                    width={"120px"}
                    handleClick={() => handleConfirm(formValues, errorMessages)}
                />

            </DialogActions>

        </Modal>
    )
}

