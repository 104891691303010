/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2009,
    InlineResponse2009FromJSON,
    InlineResponse2009ToJSON,
    SwOfficerSyorikiInfoPostRequest,
    SwOfficerSyorikiInfoPostRequestFromJSON,
    SwOfficerSyorikiInfoPostRequestToJSON,
    SwSyorikiInfo,
    SwSyorikiInfoFromJSON,
    SwSyorikiInfoToJSON,
} from '../models';

export interface PostOfficerSyorikiInfoRequest {
    swOfficerSyorikiInfoPostRequest: SwOfficerSyorikiInfoPostRequest;
}

/**
 * no description
 */
export class OfficerSyorikiInfoApi extends runtime.BaseAPI {

    /**
     * 処理期の情報を取得
     */
    async getOfficerSyorikiInfoRaw(): Promise<runtime.ApiResponse<SwSyorikiInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/syoriki_info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwSyorikiInfoFromJSON(jsonValue));
    }

    /**
     * 処理期の情報を取得
     */
    async getOfficerSyorikiInfo(): Promise<SwSyorikiInfo> {
        const response = await this.getOfficerSyorikiInfoRaw();
        return await response.value();
    }

    /**
     * 処理期の情報を保存 
     */
    async postOfficerSyorikiInfoRaw(requestParameters: PostOfficerSyorikiInfoRequest): Promise<runtime.ApiResponse<InlineResponse2009>> {
        if (requestParameters.swOfficerSyorikiInfoPostRequest === null || requestParameters.swOfficerSyorikiInfoPostRequest === undefined) {
            throw new runtime.RequiredError('swOfficerSyorikiInfoPostRequest','Required parameter requestParameters.swOfficerSyorikiInfoPostRequest was null or undefined when calling postOfficerSyorikiInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/officer/syoriki_info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwOfficerSyorikiInfoPostRequestToJSON(requestParameters.swOfficerSyorikiInfoPostRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2009FromJSON(jsonValue));
    }

    /**
     * 処理期の情報を保存 
     */
    async postOfficerSyorikiInfo(requestParameters: PostOfficerSyorikiInfoRequest): Promise<InlineResponse2009> {
        const response = await this.postOfficerSyorikiInfoRaw(requestParameters);
        return await response.value();
    }

}
