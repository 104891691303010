/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwOfficerMailRecipient
 */
export interface SwOfficerMailRecipient {
    /**
     * 
     * @type {string}
     * @memberof SwOfficerMailRecipient
     */
    studentNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerMailRecipient
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerMailRecipient
     */
    mailAddress: string;
    /**
     * メール単体の状態を表現する GroupMailRecipientStateEnum
     * @type {string}
     * @memberof SwOfficerMailRecipient
     */
    stateCode: string;
}

export function SwOfficerMailRecipientFromJSON(json: any): SwOfficerMailRecipient {
    return SwOfficerMailRecipientFromJSONTyped(json, false);
}

export function SwOfficerMailRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerMailRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentNumber': json['studentNumber'],
        'name': json['name'],
        'mailAddress': json['mailAddress'],
        'stateCode': json['stateCode'],
    };
}

export function SwOfficerMailRecipientToJSON(value?: SwOfficerMailRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentNumber': value.studentNumber,
        'name': value.name,
        'mailAddress': value.mailAddress,
        'stateCode': value.stateCode,
    };
}


