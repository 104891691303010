import {Container} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Drawer from "@material-ui/core/Drawer";
import {makeStyles} from '@material-ui/core/styles';
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {Button} from "components/atoms/Button";
import {OfficerAccountInfoStoreVo} from "stores/officer/OfficerAccountInfoStore";
import {accountInfoStore} from "stores/student/AccountInfoStore";
import React, {FC, useEffect, useState} from 'react';
import {BgColorsEnum, HeaderBgColorEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";

export interface HeaderStudentProps {
    signedIn?: boolean,
    menuContent?: React.ReactNode
    accountInfo?: OfficerAccountInfoStoreVo
}

const _HeaderStudent: FC<HeaderStudentProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const [menuOpenState, setMenuOpenState] = useState<boolean>(false);
    const [accountInfo, updateAccountInfo] = useState<StudentInfoStoreVo>(accountInfoStore.currentState);

    useEffect(() => {
        return accountInfoStore.listen(updateAccountInfo);
    }, []);

    function handleClickLogputButton() {
        accountInfoStore.postLogout().then()
    }

    type DrawerSide = 'top' | 'left' | 'bottom' | 'right';

    const toggleDrawer = (side: DrawerSide, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setMenuOpenState(open)
    };

    function handleClickTitle() {
        props.signedIn ?
            window.location.assign(URL_PATHS_STUDENT.top)
            : window.location.assign(URL_PATHS_STUDENT.login)
    }

    if (accountInfo === undefined) return <></>;

    const sideList = (side: DrawerSide) => {
        return (
            <div
                className={classes.sideList}
                role="presentation"
                onClick={toggleDrawer(side, false)}
                onKeyDown={toggleDrawer(side, false)}
            >
                <div className={classes.sideListInfo}>
                    <div className={"sideListInfo_row"}>
                        <div>{accountInfo.gakubu}</div>
                        <div>{accountInfo.gakka}</div>
                        <div>{accountInfo.studentNumber}</div>
                        <div>{accountInfo.name}</div>
                    </div>
                    <div className={"sideListInfo_row"}>
                        <Button
                            type={"default"}
                            label={"ログアウト / Log Out"}
                            handleClick={handleClickLogputButton}
                            width={"100%"}
                        />
                    </div>
                </div>
                {props.menuContent && props.menuContent}
            </div>
        )
    };

    return (

        <AppBar position="static" className={`${classes.root} ${xs && classes.xsRoot}`}>

            <Container fixed={true} className={"container"}>

                <div className={"inner"}>
                    <div
                        className={"title"}
                        onClick={handleClickTitle}
                    >
                        入学料・授業料免除等申請システム
                    </div>

                    {
                        xs ?
                            null :
                            <div className={"info"}>

                                {
                                    props.signedIn ?
                                        <div className={"info_account"}>
                                            {accountInfo.gakubu} {accountInfo.gakka} {accountInfo.studentNumber} {accountInfo.name}｜<span
                                            className={"info_account_logout"}
                                            onClick={handleClickLogputButton}>ログアウト / Log Out</span>
                                        </div>
                                        : null
                                }

                            </div>
                    }

                    {
                        xs && props.signedIn ?
                            <div className={classes.menu}>
                                <label
                                    onClick={toggleDrawer('bottom', true)}
                                    className={"menuIcon"}
                                >
                                    <span
                                        onClick={toggleDrawer('bottom', true)}
                                        className={"menuIcon_item"}
                                    />
                                </label>
                            </div> :
                            null
                    }

                </div>

            </Container>

            {
                props.signedIn ?
                    <Drawer anchor="right" open={menuOpenState} onClose={toggleDrawer('right', false)}>
                        {sideList('right')}
                    </Drawer>
                    : null
            }

        </AppBar>

    );
};

_HeaderStudent.defaultProps = {
    signedIn: true
};

const useStyles = makeStyles({
    sideListInfo: {
        padding: "12px",
        "& .sideListInfo_row": {
            fontSize: 14,
            fontWeight: 700,
            "&:not(:first-child)": {
                marginTop: 8
            }
        },
    },
    root: {
        backgroundColor: HeaderBgColorEnum.Blue,
        boxShadow: "none",
        padding: "8px 0",
        "& .container": {
            maxWidth: 1280,
            "& .inner": {
                display: "flex",
                alignItems: "center",
                "& .title": {
                    fontSize: 18,
                    fontWeight: 700,
                    cursor: "pointer"
                },
                "& .info": {
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                    "& .info_account": {
                        marginLeft: 8,
                        "& .info_account_logout": {
                            cursor: "pointer"
                        }
                    }
                },
            },
        },
    },
    xsRoot: {
        position: "fixed",
        boxShadow: "none",
        padding: "8px 0",
        "& .container": {
            "& .inner": {
                display: "flex",
                alignItems: "center",
                "& .title": {
                    fontSize: 16,
                },
                "& .info": {
                    display: "none",
                },
            },
            "& .title": {
                display: "flex",
                alignItems: "center",
                "& .title_text": {
                    color: TextColorsEnum.White,
                    fontSize: 12,
                    fontWeight: "bold",
                },
            },
        },
    },
    button: {
        backgroundColor: BgColorsEnum.Black,
        color: TextColorsEnum.White,
    },
    sideList: {
        width: 250,
    },
    menu: {
        width: "25px",
        height: "20px",
        marginLeft: "auto",
        "& .menuIcon": {
            width: 26,
            height: 20,
            position: "relative",
            "& .menuIcon_item": {
                position: "absolute",
                height: 3,/*線の太さ*/
                width: 25,/*長さ*/
                borderRadius: 3,
                background: "#fff",
                display: "block",
                content: '',
                cursor: "pointer",
                "&:before": {
                    position: "absolute",
                    height: 3,/*線の太さ*/
                    width: 25,/*長さ*/
                    borderRadius: 3,
                    background: "#fff",
                    display: "block",
                    content: '""',
                    cursor: "pointer",
                    bottom: -8
                },
                "&:after": {
                    position: "absolute",
                    height: 3,/*線の太さ*/
                    width: 25,/*長さ*/
                    borderRadius: 3,
                    background: "#fff",
                    display: "block",
                    content: '""',
                    cursor: "pointer",
                    bottom: -16
                },
            }
        }
    }
});

export const HeaderStudent = React.memo(_HeaderStudent, (prev, next) => {
    return (
        prev.signedIn === next.signedIn
        && prev.menuContent === next.menuContent
        && prev.accountInfo === next.accountInfo
    );
});
