/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSeisekiInfoErrorMessage
 */
export interface SwSeisekiInfoErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSeisekiInfoErrorMessage
     */
    SA?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSeisekiInfoErrorMessage
     */
    B?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSeisekiInfoErrorMessage
     */
    C?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSeisekiInfoErrorMessage
     */
    all?: Array<SwErrorMessage>;
}

export function SwSeisekiInfoErrorMessageFromJSON(json: any): SwSeisekiInfoErrorMessage {
    return SwSeisekiInfoErrorMessageFromJSONTyped(json, false);
}

export function SwSeisekiInfoErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSeisekiInfoErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SA': !exists(json, 'SA') ? undefined : ((json['SA'] as Array<any>).map(SwErrorMessageFromJSON)),
        'B': !exists(json, 'B') ? undefined : ((json['B'] as Array<any>).map(SwErrorMessageFromJSON)),
        'C': !exists(json, 'C') ? undefined : ((json['C'] as Array<any>).map(SwErrorMessageFromJSON)),
        'all': !exists(json, 'All') ? undefined : ((json['All'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwSeisekiInfoErrorMessageToJSON(value?: SwSeisekiInfoErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SA': value.SA === undefined ? undefined : ((value.SA as Array<any>).map(SwErrorMessageToJSON)),
        'B': value.B === undefined ? undefined : ((value.B as Array<any>).map(SwErrorMessageToJSON)),
        'C': value.C === undefined ? undefined : ((value.C as Array<any>).map(SwErrorMessageToJSON)),
        'All': value.all === undefined ? undefined : ((value.all as Array<any>).map(SwErrorMessageToJSON)),
    };
}


