import {KikanTypeEnum} from "common/constant/KIKAN";
import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {getCurrentKikan} from "common/functions/getCurrentKikan";
import {getIsEnteredYosiki} from "common/functions/getIsEnteredYosiki";
import {canButtonsDisplayYOsikiDetail} from "common/functions/yosikiInfo/canButtonsDisplayYOsikiDetail";
import {
    OfficerKikansStoreVo,
    OfficerKikansStudentIdKikansKikanVo,
    OfficerKikansStudentIdKikansVo
} from "common/models/KikansModels";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {YosikiInfoModalStoreVo, YosikiKakuteiModalType} from "common/models/yosiki/YosikiInfoModalStoreVo";
import {YosikiInfoStoreVo} from "common/models/yosiki/YosikiInfoStoreVo";
import {announceCommonStore, AnnounceCommonStoreVo} from "stores/common/AnnounceCommonStore";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {Button} from "components/atoms/Button";
import {MessageBox} from "components/atoms/MessageBox";
import {Container} from "components/layouts/Container";
import {SideListSectionList} from "components/molecules/sideList/SideListSectionList";
import {FollowingBottomNavigation} from "components/molecules/utilities/FollowingBottomNavigation";
import {Announce} from "components/organisms/utilities/Announce";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import {YosikiInfoDetailComponent} from "components/organisms/yosikiDetail/YosikiInfoDetailComponent";
import {YosikiKakuteiErrorModal} from "components/organisms/yosikiEdit/modal/YosikiKakuteiErrorModal";
import {YosikiKakuteiModal} from "components/organisms/yosikiEdit/modal/YosikiKakuteiModal";
import {studentKikansStore} from "pages/student/summary/stores/StudentKikansStore";
import {accountInfoStore} from "stores/student/AccountInfoStore";
import {yosikiInfoModalStore} from "pages/student/yosiki/stores/YosikiInfoModalStore";
import {yosikiInfoStore} from "pages/student/yosiki/stores/YosikiInfoStore";
import React from 'react';
import {RouteComponentProps, withRouter} from "react-router";

const _YosikiDetail: React.FC<RouteComponentProps> = () => {

    const [studentInfo, updateStudentInfo] = React.useState<StudentInfoStoreVo>(accountInfoStore.currentState);
    const [yosikiInfo, updateYosikiInfo] = React.useState<YosikiInfoStoreVo>(yosikiInfoStore.currentState);
    const [yosikiInfoModal, updateYosikiInfoModal] = React.useState<YosikiInfoModalStoreVo>(yosikiInfoModalStore.currentState);
    const [announceCommon, updateAnnounceCommon] = React.useState<AnnounceCommonStoreVo>(announceCommonStore.currentState);
    const [studentkikans, updateKikans] = React.useState<OfficerKikansStoreVo>(studentKikansStore.currentState);

    const kikans: OfficerKikansStudentIdKikansVo | undefined = studentkikans.kikans;
    const currentKikan: { name: string, kikan: OfficerKikansStudentIdKikansKikanVo } | undefined = getCurrentKikan(kikans);

    const isEnteredYosiki: boolean = getIsEnteredYosiki(studentInfo.yosikiState);
    const displayButtonPolicy = canButtonsDisplayYOsikiDetail(studentInfo.yosikiState);

    React.useEffect(() => {
        accountInfoStore.getStudentInfo().then();
        return accountInfoStore.listen(updateStudentInfo);
    }, []);

    React.useEffect(() => {
        return yosikiInfoStore.listen(updateYosikiInfo);
    }, []);

    React.useEffect(() => {
        return yosikiInfoModalStore.listen(updateYosikiInfoModal);
    }, []);

    React.useEffect(() => {
        return announceCommonStore.listen(updateAnnounceCommon);
    }, []);

    React.useEffect(() => {
        if (isEnteredYosiki) {
            yosikiInfoStore.getYosikiInfo().then()
        }
    }, [isEnteredYosiki, studentInfo.yosikiState,]);

    React.useEffect(() => {
        studentKikansStore.getKikansStudentId().then();
        return studentKikansStore.listen(updateKikans);
    }, []);

    // 確定モーダル
    function handleClickKakuteiModalCancel() {
        yosikiInfoModalStore.updateYosikiKakuteiModalType(undefined);
    }

    function handleClickKakuteiModalKakutei() {
        yosikiInfoStore.postYosikiInfoKakutei().then();
    }

    // 確定モーダル - エラー
    function handleClickKakuteiModalClose() {
        yosikiInfoModalStore.updateYosikiKakuteiModalType(undefined);
    }

    // yosikiDetail
    function handleClickBack() {
        window.location.assign(`${URL_PATHS_STUDENT.top}`);
    }

    function handleClickYosikiEdit() {
        window.location.assign(`${URL_PATHS_STUDENT.yosiki_edit}`);
    }

    function handleClickKakutei() {
        yosikiInfoModalStore.updateYosikiKakuteiModalType(YosikiKakuteiModalType.kakuteiModal);
    }

    // 特別控除の表示
    const isDisplayBasicInfoTokubetuKojo: boolean =
        yosikiInfo.yosikiInfo?.basicInfo.tokubetuKojo ?
            yosikiInfo.yosikiInfo?.basicInfo.tokubetuKojo.length > 0
            : false;

    let isDisplayNyuryokuAndKakuteiButton = false; // 学生は入力期間または様式が存在する場合は訂正期間に入力、確定が行える
    if (currentKikan?.name === KikanTypeEnum.入力期間 || (currentKikan?.name === KikanTypeEnum.訂正期間 && studentInfo?.yosikiState !== undefined)) {
        isDisplayNyuryokuAndKakuteiButton = true
    }

    return (

        <>

            <YosikiKakuteiErrorModal
                openState={yosikiInfoModal.yosikiKakuteiOpenModalType}
                handleClickClose={handleClickKakuteiModalClose}
                errorMessage={yosikiInfoModal.yosikiInfoKakuteiModalErrorMessage}
            />

            <YosikiKakuteiModal
                openState={yosikiInfoModal.yosikiKakuteiOpenModalType}
                handleClickCancel={handleClickKakuteiModalCancel}
                handleClickKakutei={handleClickKakuteiModalKakutei}
            />

            <Announce
                announceCommon={announceCommon}
                updateAnnounceCommon={(value) => announceCommonStore.updateAnnounceCommon(value)}
            />

            <HeaderStudent
                menuContent={
                    <SideListSectionList
                        yosikiInfoErrorMessages={yosikiInfo.yosikiInfoErrorMessages}
                        infoPolicy={yosikiInfo.yosikiInfo?.infoPolicy}
                        isDisplayBasicInfoTokubetuKojo={isDisplayBasicInfoTokubetuKojo}
                    />
                }
            />

            <Container
                maxWidth={'864px'}
                minWidth={'864px'}
            >

                {
                    isEnteredYosiki && yosikiInfo.yosikiInfo !== undefined ?
                        // 様式が存在している場合は詳細情報を表示
                        <YosikiInfoDetailComponent
                            yosikiInfo={yosikiInfo}
                            studentInfo={studentInfo}
                            sinseisyaInfo={yosikiInfo.yosikiInfo?.sinseisyaInfo}
                            isDisplayBasicInfoTokubetuKojo={isDisplayBasicInfoTokubetuKojo}
                        />
                        : isDisplayNyuryokuAndKakuteiButton ?
                        <MessageBox>{CommonTextEnumToNameJaAndEn(CommonTextEnum.画面下の入力ボタンを押して入力してください)}</MessageBox>
                        : <MessageBox>{CommonTextEnumToNameJaAndEn(CommonTextEnum.現在入力できません)}</MessageBox>
                }

            </Container>


            <FollowingBottomNavigation>
                <Button
                    className={"search_row_button"}
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.戻る)}
                    width={"104px"}
                    handleClick={() => handleClickBack()}
                />

                {
                    isDisplayNyuryokuAndKakuteiButton ?
                        <>

                            {
                                displayButtonPolicy.isDisplayNyuryokuButton ?
                                    <Button
                                        className={"search_row_button"}
                                        type={"primary"}
                                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.入力)}
                                        width={"104px"}
                                        handleClick={() => handleClickYosikiEdit()}
                                    /> : null
                            }

                            {
                                displayButtonPolicy.isDisplayKakuteiButton ?
                                    <Button
                                        className={"search_row_button"}
                                        type={"secondary"}
                                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                                        width={"120px"}
                                        handleClick={() => handleClickKakutei()}
                                    /> : null
                            }
                        </>
                        : null
                }


            </FollowingBottomNavigation>

        </>

    );
};

export const YosikiDetail = withRouter(_YosikiDetail);

