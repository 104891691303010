/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwOfficerSyoruisSyoruisHituyoSyorui,
    SwOfficerSyoruisSyoruisHituyoSyoruiFromJSON,
    SwOfficerSyoruisSyoruisHituyoSyoruiFromJSONTyped,
    SwOfficerSyoruisSyoruisHituyoSyoruiToJSON,
    SwOfficerSyoruisSyoruisSyorui,
    SwOfficerSyoruisSyoruisSyoruiFromJSON,
    SwOfficerSyoruisSyoruisSyoruiFromJSONTyped,
    SwOfficerSyoruisSyoruisSyoruiToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerSyoruisSyoruis
 */
export interface SwOfficerSyoruisSyoruis {
    /**
     * 入力内容から入力内容が反映された提出が必要な書類の情報を返す 入力内容が反映されるので英語の情報は不要 
     * @type {Array<SwOfficerSyoruisSyoruisHituyoSyorui>}
     * @memberof SwOfficerSyoruisSyoruis
     */
    hituyoSyoruis: Array<SwOfficerSyoruisSyoruisHituyoSyorui>;
    /**
     * 入力内容から記入と提出が必要な書類の情報を返す 
     * @type {Array<SwOfficerSyoruisSyoruisSyorui>}
     * @memberof SwOfficerSyoruisSyoruis
     */
    hituyoKinyuSyoruis: Array<SwOfficerSyoruisSyoruisSyorui>;
    /**
     * すべての任意の書類の情報を返す 
     * @type {Array<SwOfficerSyoruisSyoruisSyorui>}
     * @memberof SwOfficerSyoruisSyoruis
     */
    ninniSyoruis: Array<SwOfficerSyoruisSyoruisSyorui>;
}

export function SwOfficerSyoruisSyoruisFromJSON(json: any): SwOfficerSyoruisSyoruis {
    return SwOfficerSyoruisSyoruisFromJSONTyped(json, false);
}

export function SwOfficerSyoruisSyoruisFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerSyoruisSyoruis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hituyoSyoruis': ((json['hituyoSyoruis'] as Array<any>).map(SwOfficerSyoruisSyoruisHituyoSyoruiFromJSON)),
        'hituyoKinyuSyoruis': ((json['hituyoKinyuSyoruis'] as Array<any>).map(SwOfficerSyoruisSyoruisSyoruiFromJSON)),
        'ninniSyoruis': ((json['ninniSyoruis'] as Array<any>).map(SwOfficerSyoruisSyoruisSyoruiFromJSON)),
    };
}

export function SwOfficerSyoruisSyoruisToJSON(value?: SwOfficerSyoruisSyoruis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hituyoSyoruis': ((value.hituyoSyoruis as Array<any>).map(SwOfficerSyoruisSyoruisHituyoSyoruiToJSON)),
        'hituyoKinyuSyoruis': ((value.hituyoKinyuSyoruis as Array<any>).map(SwOfficerSyoruisSyoruisSyoruiToJSON)),
        'ninniSyoruis': ((value.ninniSyoruis as Array<any>).map(SwOfficerSyoruisSyoruisSyoruiToJSON)),
    };
}


