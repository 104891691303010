/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwBasicInfoSinseiType
 */
export interface SwBasicInfoSinseiType {
    /**
     * enum
     * @type {string}
     * @memberof SwBasicInfoSinseiType
     */
    nyugakuryo: string;
    /**
     * enum
     * @type {string}
     * @memberof SwBasicInfoSinseiType
     */
    jugyoryo: string;
}

export function SwBasicInfoSinseiTypeFromJSON(json: any): SwBasicInfoSinseiType {
    return SwBasicInfoSinseiTypeFromJSONTyped(json, false);
}

export function SwBasicInfoSinseiTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwBasicInfoSinseiType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nyugakuryo': json['nyugakuryo'],
        'jugyoryo': json['jugyoryo'],
    };
}

export function SwBasicInfoSinseiTypeToJSON(value?: SwBasicInfoSinseiType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nyugakuryo': value.nyugakuryo,
        'jugyoryo': value.jugyoryo,
    };
}


