import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {SeisekiInfoVo} from "common/models/YosikiInfoModels";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {SeisekiInfoLabelTextEnum, SeisekiInfoLabelEnumToNameJaAndEn} from "common/enums_text/yosiki/SeisekiInfoLabelTextEnum";
import {NumberField} from "components/atoms/forms/NumberField";
import {ErrorCountMessage} from "components/atoms/ErrorCountMessage";
import {Title04} from "components/atoms/titles/Title04";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {SwSeisekiInfoErrorMessage} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface SeisekiInfoSectionProps {
    className?: string
    seisekiInfo: SeisekiInfoVo
    updateSeisekiInfo: (part: Partial<SeisekiInfoVo>) => void
    updateSeisekiInfoErrorMessage: (part: Partial<SwSeisekiInfoErrorMessage>) => void
    errorCount: number
}

export const _SeisekiInfoSection: FC<SeisekiInfoSectionProps> = (props) => {

    const classes = useStyles();

    function handleBlurSA(value: string) {
        props.updateSeisekiInfo({SA: value})
    }

    function handleBlurB(value: string) {
        props.updateSeisekiInfo({B: value})
    }

    function handleBlurC(value: string) {
        props.updateSeisekiInfo({C: value})
    }

    function handleBlurTotal(value: string) {
        props.updateSeisekiInfo({total: value})
    }

    const seisekiInfo: SeisekiInfoVo = props.seisekiInfo;

    return (
        <div id={SECTION_ID_NAME.seisekiInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.学業成績)}
                className={"section_title"}
            />

            <ErrorCountMessage
                count={props.errorCount}
            />

            <FormTable
                className={"section_table"}
            >

                <FormTableRow
                    label={SeisekiInfoLabelEnumToNameJaAndEn(SeisekiInfoLabelTextEnum.前年度習得単位数)}
                >

                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {SeisekiInfoLabelEnumToNameJaAndEn(SeisekiInfoLabelTextEnum.学部1年生は無記入とし)}
                        </div>

                        <div className={`${classes.topRow}`}>
                            <FieldWithLabel
                                label={"S・A"}
                                requiredType={RequiredTagTypeEnum.任意}
                                width={"72px"}
                            >
                                <NumberField
                                    value={seisekiInfo.SA}
                                    handleBlur={handleBlurSA}
                                    suffixText={"単位"}
                                    isSyosutenPermitted={true}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={"B"}
                                requiredType={RequiredTagTypeEnum.任意}
                                width={"72px"}
                            >
                                <NumberField
                                    value={seisekiInfo.B}
                                    handleBlur={handleBlurB}
                                    suffixText={"単位"}
                                    isSyosutenPermitted={true}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={"C"}
                                requiredType={RequiredTagTypeEnum.任意}
                                width={"72px"}
                            >
                                <NumberField
                                    value={seisekiInfo.C}
                                    handleBlur={handleBlurC}
                                    suffixText={"単位"}
                                    isSyosutenPermitted={true}
                                />
                            </FieldWithLabel>
                        </div>

                    </div>

                    <div className={"formTableRow_item"}>
                        <FieldWithLabel
                            label={SeisekiInfoLabelEnumToNameJaAndEn(SeisekiInfoLabelTextEnum.前年度までの全学年での総修得単位数)}
                            requiredType={RequiredTagTypeEnum.任意}
                        >
                            <NumberField
                                value={seisekiInfo.total}
                                handleBlur={handleBlurTotal}
                                width={"72px"}
                                suffixText={"単位"}
                                isSyosutenPermitted={true}
                            />
                        </FieldWithLabel>
                    </div>

                </FormTableRow>


            </FormTable>

        </div>

    )

};

export const SeisekiInfoSection = React.memo<SeisekiInfoSectionProps>(_SeisekiInfoSection, (prev, next) => {
    return prev.className === next.className
        && prev.seisekiInfo === next.seisekiInfo
        && prev.updateSeisekiInfo === next.updateSeisekiInfo
        && prev.updateSeisekiInfoErrorMessage === next.updateSeisekiInfoErrorMessage
        && prev.errorCount === next.errorCount
});

const useStyles = makeStyles({
        root: {},
        topRow: {
            display: "flex",
            alignItems: "baseline",
            "& > *:not(:first-child)": {
                marginLeft: 4
            }
        },
    }
);

