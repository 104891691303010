/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwStudentCreateAccountPostErrorMessage,
    SwStudentCreateAccountPostErrorMessageFromJSON,
    SwStudentCreateAccountPostErrorMessageFromJSONTyped,
    SwStudentCreateAccountPostErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20021
     */
    isSuccess: boolean;
    /**
     * 
     * @type {SwStudentCreateAccountPostErrorMessage}
     * @memberof InlineResponse20021
     */
    error?: SwStudentCreateAccountPostErrorMessage;
}

export function InlineResponse20021FromJSON(json: any): InlineResponse20021 {
    return InlineResponse20021FromJSONTyped(json, false);
}

export function InlineResponse20021FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20021 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSuccess': json['isSuccess'],
        'error': !exists(json, 'error') ? undefined : SwStudentCreateAccountPostErrorMessageFromJSON(json['error']),
    };
}

export function InlineResponse20021ToJSON(value?: InlineResponse20021 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSuccess': value.isSuccess,
        'error': SwStudentCreateAccountPostErrorMessageToJSON(value.error),
    };
}


