import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {NumberField} from "components/atoms/forms/NumberField";
import {TextField} from "components/atoms/forms/TextField";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import React, {FC} from 'react';
import {trimStrForNotNumber} from "common/functions/char_converter/trimStrForNotNumber";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface AddressFieldProps {
    disabled?: boolean;
    valueZip?: string;
    valueAddress?: string;
    requiredTypeZip?: RequiredTagTypeEnum
    requiredTypeAddress?: RequiredTagTypeEnum
    handleBlurZipCode?: (value: string) => void;
    handleBlurAddress?: (value: string) => void;
    handleBlurZipCodeAndAddress?: (zipCode: string, address: string) => void;
    errorMessagesZip?: Array<string>
    errorMessagesAddress?: Array<string>
}

export interface ZipCodeJson {
    prefecture_jis_code: string;
    city_jis_code: string;
    zip_code: string;
    prefecture_name_kana: string;
    city_name_kana: string;
    town_name_kana: string;
    prefecture_name: string;
    city_name: string;
    town_name: string;
}

export interface PrefectureJson {
    prefecture_jis_code: string;
    prefecture_name_kana: string;
    prefecture_name: string;
}

export interface CityJson {
    prefecture_jis_code: string;
    city_jis_code: string;
    prefecture_name_kana: string;
    city_name_kana: string;
    prefecture_name: string;
    city_name: string;
}

export interface TownJson {
    prefecture_jis_code: string;
    city_jis_code: string;
    town_name_kana: string;
    town_name: string;
    zip_code: string;
}

export interface ZipCodeJpInterface {
    setRootUrl: (url: string) => void;

    getAddressesOfZipCode: (
        zipCode: string,
        cb: (err: any, addresses: ZipCodeJson[]) => void
    ) => void;

    getPrefectures: (
        cb: (err: any, prefectures: PrefectureJson[]) => void
    ) => void;

    getCitiesOfPrefecture: (
        prefectureJisCode: string,
        cb: (err: any, cities: CityJson[]) => void
    ) => void;

    getTownsOfCity: (
        cityJisCode: string,
        cb: (err: any, towns: TownJson[]) => void
    ) => void;

}

declare var ZipCodeJp: ZipCodeJpInterface;

// 一覧などの検索フォームのラベルのスタイル用コンポーネント
export const _AddressField: FC<AddressFieldProps> = (props) => {

    const [zipCode, updateZipCode] = React.useState<string>("");
    const [address, updateAddress] = React.useState<string>("");

    React.useEffect(() => {
        updateZipCode(props.valueZip ? props.valueZip : "");
    }, [props.valueZip]);

    React.useEffect(() => {
        updateAddress(props.valueAddress ? props.valueAddress : "")
    }, [props.valueAddress]);

    async function getAddressByZipCode(value: string) {
        if (value.length !== 7 || value === props.valueZip) {
            props.handleBlurZipCodeAndAddress && props.handleBlurZipCodeAndAddress(value, address);
            return
        }
        ZipCodeJp.setRootUrl(
            `${process.env.PUBLIC_URL}/zipcode_jp/docs/`
        );
        ZipCodeJp.getAddressesOfZipCode(
            value,
            function (err, resultAddresses: ZipCodeJson[]) {
                if (resultAddresses.length !== 0) {
                    props.handleBlurZipCodeAndAddress && props.handleBlurZipCodeAndAddress(
                        value,
                        resultAddresses[0].prefecture_name + resultAddresses[0].city_name + resultAddresses[0].town_name
                    );
                } else {
                    // 見つからない場合はstateの値でupdate
                    props.handleBlurZipCodeAndAddress && props.handleBlurZipCodeAndAddress(value, address);
                }
            }
        );
    }

    async function handleBlurZipCodeField(newValue: string) {
        const tried = trimStrForNotNumber(newValue);
        updateZipCode(tried);
        await getAddressByZipCode(tried).then();
    }

    function handleBlurAddressField(newValue: string) {
        updateAddress(newValue);
        props.handleBlurAddress && props.handleBlurAddress(newValue)
    }

    return (
        <div>

            <FieldWithLabel
                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.郵便番号ハイフンなしで入力してください)}
                requiredType={props.requiredTypeZip}
            >
                <NumberField
                    width={"120px"}
                    value={zipCode}
                    handleBlur={handleBlurZipCodeField}
                    errorMessages={props.errorMessagesZip}
                    isError={props.errorMessagesZip && props.errorMessagesZip.length !== 0}
                    disabled={props.disabled}
                />
            </FieldWithLabel>

            <FieldWithLabel
                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.住所)}
                requiredType={props.requiredTypeAddress}
            >
                <TextField
                    value={address}
                    handleBlur={handleBlurAddressField}
                    errorMessages={props.errorMessagesAddress}
                    isError={props.errorMessagesAddress && props.errorMessagesAddress.length !== 0}
                    disabled={props.disabled}
                />
            </FieldWithLabel>

        </div>

    )
};

export const AddressField = React.memo<AddressFieldProps>(_AddressField, (prev, next) => {
    return prev.disabled === next.disabled
        && prev.valueZip === next.valueZip
        && prev.valueAddress === next.valueAddress
        && prev.requiredTypeZip === next.requiredTypeZip
        && prev.requiredTypeAddress === next.requiredTypeAddress
        && prev.handleBlurZipCode === next.handleBlurZipCode
        && prev.handleBlurAddress === next.handleBlurAddress
        && prev.errorMessagesZip === next.errorMessagesZip
        && prev.errorMessagesAddress === next.errorMessagesAddress
});

