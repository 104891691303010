import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {MemberSyugakusyaIgaiVo, MemberVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {Button} from "components/atoms/Button";
import {NumberField} from "components/atoms/forms/NumberField";
import {RadioButton} from "components/atoms/forms/RadioButton";
import {TextField} from "components/atoms/forms/TextField";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {DescriptionLink} from "components/atoms/DescriptionLink";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {RadioButtonGroup} from "components/molecules/forms/RadioButtonGroup";
import {DescriptionLinkWrap} from "components/molecules/utilities/DescriptionLinkWrap";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {SwMemberErrorMessages} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";
import {TextColorsEnum} from "../../../common/enums_value/ColorsEnum";
import {Box} from "@material-ui/core";

interface FamilyInfoSectionMemberProps {
    index: number
    member: MemberVo
    familyInfoErrorMessage: SwMemberErrorMessages
    updateMembers: (partMember: Partial<MemberVo>, index: number) => void
    updateMemberAsSyugakusyaIgai: (value: Partial<MemberSyugakusyaIgaiVo>, index: number) => void
    updateMemberErrorMessages: (part: Partial<SwMemberErrorMessages>, index: number) => void
    updateDeleteZokugaraModalOpenState: (value: boolean) => void
    updateSelectedZokugaraIndex: (value: number) => void
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
}

export const FamilyInfoSectionMember: FC<FamilyInfoSectionMemberProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    function handleBlurName(value: string) {
        props.updateMembers({name: value}, props.index);
        props.updateMemberErrorMessages({name: undefined}, props.index)
    }

    function handleBlurAge(value: string) {
        props.updateMembers({age: value}, props.index);
        props.updateMemberErrorMessages({age: undefined}, props.index)
    }

    function handleBlurJobName(value: string) {
        props.updateMemberAsSyugakusyaIgai({jobName: value}, props.index)
    }

    function handleBlurJobYears(value: string) {
        props.updateMemberAsSyugakusyaIgai({jobYears: value}, props.index)
    }

    function handleClickDeleteZokugara() {
        props.updateDeleteZokugaraModalOpenState(true);
        props.updateSelectedZokugaraIndex(props.index)
    }

    function handleChangeSyugakusyaFlag(value: boolean) {
        props.updateMembers({isSyugakusya: value}, props.index);
        props.updateMemberErrorMessages({isSyugakusya: undefined}, props.index)
    }

    const member: MemberVo = props.member;
    const errorMessage: SwMemberErrorMessages | undefined = props.familyInfoErrorMessage;

    return (
        <FormTableRow
            label={convertZokugaraForStr(member.zokugara, member.zokugaraOther)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    <Box display={"inline"} color={TextColorsEnum.Red}>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.時点の情報を)}</Box>
                    <RequiredTag
                    type={RequiredTagTypeEnum.必須}/>
                </div>
                <RadioButtonGroup
                    errorMessages={errorMessage ? convertErrorMessageVoToArrayStr(errorMessage.isSyugakusya) : []}
                    vertical={true}
                >
                    <RadioButton
                        value={""}
                        checked={member.isSyugakusya === false}
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.就学者以外)}
                        isError={errorMessage ? convertErrorMessageVoToArrayStr(errorMessage.isSyugakusya).length !== 0 : false}
                        handleChange={() => {
                            handleChangeSyugakusyaFlag(false)
                        }}
                    />
                    <RadioButton
                        value={""}
                        checked={member.isSyugakusya === true}
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.就学者)}
                        isError={errorMessage ? convertErrorMessageVoToArrayStr(errorMessage.isSyugakusya).length !== 0 : false}
                        handleChange={() => {
                            handleChangeSyugakusyaFlag(true)
                        }}
                    />
                </RadioButtonGroup>

                <DescriptionLinkWrap mT={0}>
                    <DescriptionLink
                        onClickWordDescriptionLink={props.onClickWordDescriptionLink}
                        type={WordDescriptionTypeEnum.就学者欄}
                    />
                </DescriptionLinkWrap>
            </div>

            <div className={"formTableRow_item"}>

                <div className={`${classes.topRow} ${xs ? classes.xsTopRow : ""}`}>
                    <FieldWithLabel
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.氏名)}
                        requiredType={RequiredTagTypeEnum.必須}
                        width={"120px"}
                    >
                        <TextField
                            value={member.name}
                            errorMessages={errorMessage ? convertErrorMessageVoToArrayStr(errorMessage.name) : []}
                            isError={errorMessage && isErrorMessage(errorMessage.name)}
                            handleBlur={(value) => handleBlurName(value)}
                        />
                    </FieldWithLabel>

                    <FieldWithLabel
                        label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.年齢)}
                        requiredType={RequiredTagTypeEnum.必須}
                        width={"96px"}
                    >
                        <NumberField
                            value={member.age}
                            handleBlur={(value) => handleBlurAge(value)}
                            errorMessages={errorMessage ? convertErrorMessageVoToArrayStr(errorMessage.age) : []}
                            isError={errorMessage && isErrorMessage(errorMessage.age)}
                            suffixText={"歳"}
                            widthInput={"40px"}
                        />
                    </FieldWithLabel>
                </div>

                {
                    member.syugakusyaIgai && member.isSyugakusya === false ?
                        <div className={`${classes.topRow} ${xs ? classes.xsTopRow : ""}`}>
                            <FieldWithLabel
                                label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.現在の職業)}
                                requiredType={RequiredTagTypeEnum.任意}
                                width={"224px"}
                            >
                                <TextField
                                    value={member.syugakusyaIgai.jobName}
                                    handleBlur={(value) => handleBlurJobName(value)}
                                    errorMessages={errorMessage ? convertErrorMessageVoToArrayStr(errorMessage.syugakusyaIgai?.jobName) : []}
                                    isError={errorMessage && isErrorMessage(errorMessage.syugakusyaIgai?.jobName)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.在職期間)}
                                requiredType={RequiredTagTypeEnum.任意}
                                width={"260px"}
                            >
                                <NumberField
                                    value={member.syugakusyaIgai.jobYears}
                                    handleBlur={(value) => handleBlurJobYears(value)}
                                    errorMessages={errorMessage ? convertErrorMessageVoToArrayStr(errorMessage.syugakusyaIgai?.jobYears) : []}
                                    isError={errorMessage && isErrorMessage(errorMessage.syugakusyaIgai?.jobYears)}
                                    suffixText={"年"}
                                    widthInput={"40px"}
                                />
                            </FieldWithLabel>
                        </div>
                        : null
                }
                <DescriptionLinkWrap>
                    <DescriptionLink
                        onClickWordDescriptionLink={props.onClickWordDescriptionLink}
                        type={WordDescriptionTypeEnum.現在の職業欄}
                    />
                </DescriptionLinkWrap>
            </div>
            <div className={"formTableRow_item mb8"}>
                <Button
                    size={'small'}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を削除)}
                    type={"warning"}
                    handleClick={() => handleClickDeleteZokugara()}
                />
            </div>

        </FormTableRow>
    )
};

const useStyles = makeStyles({
        root: {},
        topRow: {
            display: "flex",
            width: "100%",
            "& > *:not(:first-child)": {
                marginLeft: 4
            }
        },
        xsTopRow: {
            display: "block",
            "& > *:not(:first-child)": {
                marginLeft: 0
            }
        }
    }
);
