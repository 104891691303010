import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum SinseisyaInfoLabelTextEnum {
    学籍番号 = "学籍番号",
    氏名 = "氏名",
    氏名フリガナ = "氏名フリガナ",
    生年月日 = "生年月日",
    年齢 = "年齢",
    学府研究科学部 = "学府・研究科・学部",
    専攻学科 = "専攻・学科",
    学年 = "学年",
    入学年月 = "入学年月",
    休学 = "休学",
    休学歴 = "休学歴",
    留年 = "留年",
    住所 = "住所",
    自宅電話番号 = "自宅電話番号",
    携帯電話番号 = "携帯電話番号",
    メールアドレス = "メールアドレス",
    研究室電話番号 = "研究室電話番号",
    指導教員名 = "指導教員名",
    住所家族 = "住所(家族)",
    電話番号家族 = "電話番号(家族)",
}


export function SinseisyaInfoLabelTextEnumToNameJaAndEn(e: SinseisyaInfoLabelTextEnum): string {
    switch (e) {
        case SinseisyaInfoLabelTextEnum.学籍番号:
            return convertEnumNameToStr({ja: "学籍番号", en: "Student Number"});
        case SinseisyaInfoLabelTextEnum.氏名:
            return convertEnumNameToStr({ja: "氏名", en: "Name"});
        case SinseisyaInfoLabelTextEnum.氏名フリガナ:
            return convertEnumNameToStr({ja: "氏名フリガナ", en: "Name Furigana"});
        case SinseisyaInfoLabelTextEnum.生年月日:
            return convertEnumNameToStr({ja: "生年月日", en: "Birthday"});
        case SinseisyaInfoLabelTextEnum.年齢:
            return convertEnumNameToStr({ja: "年齢", en: "Age"});
        case SinseisyaInfoLabelTextEnum.学府研究科学部:
            return convertEnumNameToStr({ja: "学府・研究科・学部", en: "Faculty"});
        case SinseisyaInfoLabelTextEnum.専攻学科:
            return convertEnumNameToStr({ja: "専攻・学科", en: "Department"});
        case SinseisyaInfoLabelTextEnum.学年:
            return convertEnumNameToStr({ja: "学年", en: "Academic Year"});
        case SinseisyaInfoLabelTextEnum.入学年月:
            return convertEnumNameToStr({ja: "入学年月", en: "Date of enrollment"});
        case SinseisyaInfoLabelTextEnum.休学:
            return convertEnumNameToStr({ja: "休学", en: "Leaves of Absence from School"});
        case SinseisyaInfoLabelTextEnum.留年:
            return convertEnumNameToStr({ja: "留年", en: "Repetition"});
        case SinseisyaInfoLabelTextEnum.住所:
            return convertEnumNameToStr({ja: "住所", en: "Address"});
        case SinseisyaInfoLabelTextEnum.自宅電話番号:
            return convertEnumNameToStr({ja: "自宅電話番号", en: "Home Telephone"});
        case SinseisyaInfoLabelTextEnum.携帯電話番号:
            return convertEnumNameToStr({ja: "携帯電話番号", en: "Mobile Telephone"});
        case SinseisyaInfoLabelTextEnum.メールアドレス:
            return convertEnumNameToStr({ja: "メールアドレス", en: "E-mail"});
        case SinseisyaInfoLabelTextEnum.研究室電話番号:
            return convertEnumNameToStr({ja: "研究室電話番号", en: "Research Office Telephone"});
        case SinseisyaInfoLabelTextEnum.指導教員名:
            return convertEnumNameToStr({ja: "指導教員名", en: "Academic Advisor"});
        case SinseisyaInfoLabelTextEnum.住所家族:
            return convertEnumNameToStr({ja: "住所(家族)", en: "Family Address"});
        case SinseisyaInfoLabelTextEnum.電話番号家族:
            return convertEnumNameToStr({ja: "電話番号(家族)", en: "Family Telephone"});
        case SinseisyaInfoLabelTextEnum.休学歴:
            return convertEnumNameToStr({ja: "休学歴", en: "History of Leaves of Absence from School"});
        default :
            return ""
    }
}

