/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwOfficerSyoruisSyoruisSyorui,
    SwOfficerSyoruisSyoruisSyoruiFromJSON,
    SwOfficerSyoruisSyoruisSyoruiFromJSONTyped,
    SwOfficerSyoruisSyoruisSyoruiToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiSyoruisSyoruis
 */
export interface SwAYosikiSyoruisSyoruis {
    /**
     * A様式1、A様式2 
     * @type {Array<SwOfficerSyoruisSyoruisSyorui>}
     * @memberof SwAYosikiSyoruisSyoruis
     */
    hituyoSyoruis: Array<SwOfficerSyoruisSyoruisSyorui>;
    /**
     * 別紙書類、今後の改修次第で全てhituyoSyoruisで扱う可能性がある。 
     * @type {Array<SwOfficerSyoruisSyoruisSyorui>}
     * @memberof SwAYosikiSyoruisSyoruis
     */
    hituyoKinyuSyoruis: Array<SwOfficerSyoruisSyoruisSyorui>;
}

export function SwAYosikiSyoruisSyoruisFromJSON(json: any): SwAYosikiSyoruisSyoruis {
    return SwAYosikiSyoruisSyoruisFromJSONTyped(json, false);
}

export function SwAYosikiSyoruisSyoruisFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiSyoruisSyoruis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hituyoSyoruis': ((json['hituyoSyoruis'] as Array<any>).map(SwOfficerSyoruisSyoruisSyoruiFromJSON)),
        'hituyoKinyuSyoruis': ((json['hituyoKinyuSyoruis'] as Array<any>).map(SwOfficerSyoruisSyoruisSyoruiFromJSON)),
    };
}

export function SwAYosikiSyoruisSyoruisToJSON(value?: SwAYosikiSyoruisSyoruis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hituyoSyoruis': ((value.hituyoSyoruis as Array<any>).map(SwOfficerSyoruisSyoruisSyoruiToJSON)),
        'hituyoKinyuSyoruis': ((value.hituyoKinyuSyoruis as Array<any>).map(SwOfficerSyoruisSyoruisSyoruiToJSON)),
    };
}


