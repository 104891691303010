export type Listener<T> = (newValue: T) => void;
export type Action<T> = (current: Readonly<T>) => Partial<T>;
export type Unsubscribe = () => void;

export class Store<T extends Object> {

    protected state: T;
    protected listeners: Listener<T>[] = [];

    constructor(initial: T) {
        this.state = initial;
    }

    get currentState(): T {
        return this.state;
    }

    // renderFlagでlistenerを無視してrenderを制御する
    dispatch(update: Action<T>, renderFlag: boolean = true): void {
        this.state = {...this.state, ...update(this.state)};
        if (renderFlag) {
            this.listeners.forEach(l => l(this.state));
        }
    }

    listen(onUpdate: Listener<T>): Unsubscribe {
        // restore view with current state
        onUpdate(this.state);
        this.listeners.push(onUpdate);
        return () => {
            this.listeners = this.listeners.filter(l => l !== onUpdate)
        };
    }

}