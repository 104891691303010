/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwAYosikiFormAYosiki1,
    SwAYosikiFormAYosiki1FromJSON,
    SwAYosikiFormAYosiki1FromJSONTyped,
    SwAYosikiFormAYosiki1ToJSON,
    SwAYosikiFormAYosiki2,
    SwAYosikiFormAYosiki2FromJSON,
    SwAYosikiFormAYosiki2FromJSONTyped,
    SwAYosikiFormAYosiki2ToJSON,
    SwAYosikiFormCommon,
    SwAYosikiFormCommonFromJSON,
    SwAYosikiFormCommonFromJSONTyped,
    SwAYosikiFormCommonToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiFormRequest
 */
export interface SwAYosikiFormRequest {
    /**
     * 
     * @type {SwAYosikiFormCommon}
     * @memberof SwAYosikiFormRequest
     */
    common: SwAYosikiFormCommon;
    /**
     * 
     * @type {SwAYosikiFormAYosiki1}
     * @memberof SwAYosikiFormRequest
     */
    aYosiki1: SwAYosikiFormAYosiki1;
    /**
     * 
     * @type {SwAYosikiFormAYosiki2}
     * @memberof SwAYosikiFormRequest
     */
    aYosiki2: SwAYosikiFormAYosiki2;
}

export function SwAYosikiFormRequestFromJSON(json: any): SwAYosikiFormRequest {
    return SwAYosikiFormRequestFromJSONTyped(json, false);
}

export function SwAYosikiFormRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'common': SwAYosikiFormCommonFromJSON(json['common']),
        'aYosiki1': SwAYosikiFormAYosiki1FromJSON(json['aYosiki1']),
        'aYosiki2': SwAYosikiFormAYosiki2FromJSON(json['aYosiki2']),
    };
}

export function SwAYosikiFormRequestToJSON(value?: SwAYosikiFormRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'common': SwAYosikiFormCommonToJSON(value.common),
        'aYosiki1': SwAYosikiFormAYosiki1ToJSON(value.aYosiki1),
        'aYosiki2': SwAYosikiFormAYosiki2ToJSON(value.aYosiki2),
    };
}


