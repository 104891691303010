/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwSyunyuInfoItemField
 */
export interface SwSyunyuInfoItemField {
    /**
     * 
     * @type {string}
     * @memberof SwSyunyuInfoItemField
     */
    kubunOther: string;
    /**
     * 
     * @type {number}
     * @memberof SwSyunyuInfoItemField
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSyunyuInfoItemField
     */
    syotokuYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSyunyuInfoItemField
     */
    syotokuMonth?: number;
}

export function SwSyunyuInfoItemFieldFromJSON(json: any): SwSyunyuInfoItemField {
    return SwSyunyuInfoItemFieldFromJSONTyped(json, false);
}

export function SwSyunyuInfoItemFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyunyuInfoItemField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kubunOther': json['kubunOther'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'syotokuYear': !exists(json, 'syotokuYear') ? undefined : json['syotokuYear'],
        'syotokuMonth': !exists(json, 'syotokuMonth') ? undefined : json['syotokuMonth'],
    };
}

export function SwSyunyuInfoItemFieldToJSON(value?: SwSyunyuInfoItemField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kubunOther': value.kubunOther,
        'amount': value.amount,
        'syotokuYear': value.syotokuYear,
        'syotokuMonth': value.syotokuMonth,
    };
}


