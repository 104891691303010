/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwBosiHusiFatherErrorMessage
 */
export interface SwBosiHusiFatherErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwBosiHusiFatherErrorMessage
     */
    beturiType?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwBosiHusiFatherErrorMessage
     */
    jikiYaer?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwBosiHusiFatherErrorMessage
     */
    jikiMonth?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwBosiHusiFatherErrorMessage
     */
    riyu?: Array<SwErrorMessage>;
}

export function SwBosiHusiFatherErrorMessageFromJSON(json: any): SwBosiHusiFatherErrorMessage {
    return SwBosiHusiFatherErrorMessageFromJSONTyped(json, false);
}

export function SwBosiHusiFatherErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwBosiHusiFatherErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'beturiType': !exists(json, 'beturiType') ? undefined : ((json['beturiType'] as Array<any>).map(SwErrorMessageFromJSON)),
        'jikiYaer': !exists(json, 'jikiYaer') ? undefined : ((json['jikiYaer'] as Array<any>).map(SwErrorMessageFromJSON)),
        'jikiMonth': !exists(json, 'jikiMonth') ? undefined : ((json['jikiMonth'] as Array<any>).map(SwErrorMessageFromJSON)),
        'riyu': !exists(json, 'riyu') ? undefined : ((json['riyu'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwBosiHusiFatherErrorMessageToJSON(value?: SwBosiHusiFatherErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'beturiType': value.beturiType === undefined ? undefined : ((value.beturiType as Array<any>).map(SwErrorMessageToJSON)),
        'jikiYaer': value.jikiYaer === undefined ? undefined : ((value.jikiYaer as Array<any>).map(SwErrorMessageToJSON)),
        'jikiMonth': value.jikiMonth === undefined ? undefined : ((value.jikiMonth as Array<any>).map(SwErrorMessageToJSON)),
        'riyu': value.riyu === undefined ? undefined : ((value.riyu as Array<any>).map(SwErrorMessageToJSON)),
    };
}


