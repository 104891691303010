/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwBekkyoErrorMessage,
    SwBekkyoErrorMessageFromJSON,
    SwBekkyoErrorMessageFromJSONTyped,
    SwBekkyoErrorMessageToJSON,
    SwBosiHusiErrorMessage,
    SwBosiHusiErrorMessageFromJSON,
    SwBosiHusiErrorMessageFromJSONTyped,
    SwBosiHusiErrorMessageToJSON,
    SwSaigaihigaiErrorMessage,
    SwSaigaihigaiErrorMessageFromJSON,
    SwSaigaihigaiErrorMessageFromJSONTyped,
    SwSaigaihigaiErrorMessageToJSON,
    SwSyogaisyaErrorMessage,
    SwSyogaisyaErrorMessageFromJSON,
    SwSyogaisyaErrorMessageFromJSONTyped,
    SwSyogaisyaErrorMessageToJSON,
    SwTyokiryoyoErrorMessage,
    SwTyokiryoyoErrorMessageFromJSON,
    SwTyokiryoyoErrorMessageFromJSONTyped,
    SwTyokiryoyoErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwTokubetuKojoInfoErrorMessage
 */
export interface SwTokubetuKojoInfoErrorMessage {
    /**
     * 
     * @type {SwBosiHusiErrorMessage}
     * @memberof SwTokubetuKojoInfoErrorMessage
     */
    bosiHusi: SwBosiHusiErrorMessage;
    /**
     * 
     * @type {SwSyogaisyaErrorMessage}
     * @memberof SwTokubetuKojoInfoErrorMessage
     */
    syogaisya: SwSyogaisyaErrorMessage;
    /**
     * 
     * @type {SwTyokiryoyoErrorMessage}
     * @memberof SwTokubetuKojoInfoErrorMessage
     */
    tyokiryoyo: SwTyokiryoyoErrorMessage;
    /**
     * 
     * @type {SwBekkyoErrorMessage}
     * @memberof SwTokubetuKojoInfoErrorMessage
     */
    bekkyo: SwBekkyoErrorMessage;
    /**
     * 
     * @type {SwSaigaihigaiErrorMessage}
     * @memberof SwTokubetuKojoInfoErrorMessage
     */
    saigaihigai: SwSaigaihigaiErrorMessage;
}

export function SwTokubetuKojoInfoErrorMessageFromJSON(json: any): SwTokubetuKojoInfoErrorMessage {
    return SwTokubetuKojoInfoErrorMessageFromJSONTyped(json, false);
}

export function SwTokubetuKojoInfoErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwTokubetuKojoInfoErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bosiHusi': SwBosiHusiErrorMessageFromJSON(json['bosiHusi']),
        'syogaisya': SwSyogaisyaErrorMessageFromJSON(json['syogaisya']),
        'tyokiryoyo': SwTyokiryoyoErrorMessageFromJSON(json['tyokiryoyo']),
        'bekkyo': SwBekkyoErrorMessageFromJSON(json['bekkyo']),
        'saigaihigai': SwSaigaihigaiErrorMessageFromJSON(json['saigaihigai']),
    };
}

export function SwTokubetuKojoInfoErrorMessageToJSON(value?: SwTokubetuKojoInfoErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bosiHusi': SwBosiHusiErrorMessageToJSON(value.bosiHusi),
        'syogaisya': SwSyogaisyaErrorMessageToJSON(value.syogaisya),
        'tyokiryoyo': SwTyokiryoyoErrorMessageToJSON(value.tyokiryoyo),
        'bekkyo': SwBekkyoErrorMessageToJSON(value.bekkyo),
        'saigaihigai': SwSaigaihigaiErrorMessageToJSON(value.saigaihigai),
    };
}


