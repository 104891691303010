import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {OsirasesVo, OsiraseVo} from "stores/common/OsiraseStore";
import {TextLink} from "components/atoms/TextLink";
import {Title02} from "components/atoms/titles/Title02";
import React, {FC} from "react";
import {BorderColorsEnum} from "common/enums_value/ColorsEnum";

interface OsiraseProps {
    className?: string
    osirase: OsirasesVo
    handleClickOsirase: (id: string) => void
}

export const Osirase: FC<OsiraseProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const osirase = props.osirase;

    if (osirase.osirases.length <= 0) return <></>;

    return (

        <div className={`${classes.root} ${matches && classes.xsRoot} ${props.className}`}>
            <Title02
                title={"お知らせ / Announcements"}
            />
            <div className={"osirase_list"}>

                {
                    osirase.osirases.map((o: OsiraseVo, index: number) => {
                        return (
                            <div key={o.createdAt + index} className={"osirase_list_item"}>
                                <div className={"osirase_list_item_date"}>{o.createdAt}</div>
                                <div className={"osirase_list_item_title"}>
                                    <TextLink
                                        className={"breakAll"}
                                        text={o.title}
                                        onClick={() => props.handleClickOsirase(o.id)}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
};

Osirase.defaultProps = {
    className: "",
};

const useStyles = makeStyles({
    root: {
        width: "100%",
        "& .osirase_title": {
            fontSize: 16,
            fontWeight: 700,
        },
        "& .osirase_list": {
            maxHeight: 200,
            overflowY: "scroll",
            "& .osirase_list_item": {
                padding: "8px 0",
                borderBottom: `1px solid ${BorderColorsEnum.LightGray}`,
                display: "flex",
                alignItems: "top",
                "& .osirase_list_item_date": {
                    minWidth: 112,
                    paddingTop: 2,
                },
                "& .osirase_list_item_title": {
                    width: "calc(100% - 38px)"
                },
            }
        }
    },
    xsRoot: {
        "& .osirase_list": {
            "& .osirase_list_item": {
                display: "block",
                "& .osirase_list_item_date": {
                    minWidth: "inhreit",
                },
                "& .osirase_list_item_title": {
                    width: "100%"
                },
            }
        }
    }
});