import React, {useCallback} from 'react';
import {SwSyoruiConfirmationFormErrorMessageResponse} from "../../../../openapi";
import {SyoruiConfirmationFormVo} from "../../../../common/models/SyoruiConfirmationFormModels";
import {Title04} from "../../../atoms/titles/Title04";
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import {RadioButton} from "../../../atoms/forms/RadioButton";
import {RadioButtonGroup} from "../../forms/RadioButtonGroup";
import {CommonTextEnum} from "../../../../common/enums_text/CommonTextEnum";
import Grid from "@material-ui/core/Grid";
import {FormItem} from "../FormItem";
import {Box} from "@material-ui/core";

interface Props {
    formValues: SyoruiConfirmationFormVo | undefined
    errorMessages: SwSyoruiConfirmationFormErrorMessageResponse | undefined
    updates: (formValues: SyoruiConfirmationFormVo, errorMessages?: SwSyoruiConfirmationFormErrorMessageResponse | undefined) => void
    isSyoruiHantei: boolean
}

export const SinseisyaForm: React.FC<Props> = ({
                                                   formValues,
                                                   errorMessages,
                                                   updates,
                                                   isSyoruiHantei
                                               }) => {

    const updateIsTugakuFromJitakuWithoutCertificate = useCallback((v: boolean) => {
        if (!formValues) return
        formValues.honnin.isTugakuFromJitakuWithoutCertificate = v
        updates(formValues, {
            ...errorMessages,
            honnin: {...errorMessages?.honnin, isTugakuFromJitakuWithoutCertificate: undefined}
        })
    }, [formValues, errorMessages, updates])

    const updateIsSyotokuwarigaku0yen = useCallback((v: boolean) => {
        if (!formValues) return
        formValues.honnin.isSyotokuwarigaku0yen = v
        updates(formValues, {...errorMessages, honnin: {...errorMessages?.honnin, isSyotokuwarigaku0yen: undefined}})
    }, [formValues, errorMessages, updates])

    const updateIsKaigoLevel3to6 = useCallback((v: boolean) => {
        if (!formValues) return
        formValues.honnin.isKaigoLevel3to6 = v
        updates(formValues, {...errorMessages, honnin: {...errorMessages?.honnin, isKaigoLevel3to6: undefined}})
    }, [formValues, errorMessages, updates])

    const updateIsTyokiryoyoMoreThan6month = useCallback((v: boolean) => {
        if (!formValues) return
        formValues.honnin.isTyokiryoyoMoreThan6month = v
        updates(formValues, {
            ...errorMessages,
            honnin: {...errorMessages?.honnin, isTyokiryoyoMoreThan6month: undefined}
        })
    }, [formValues, errorMessages, updates])

    const updateIsKokiSinnyuseiEnrolledInJapaneseUniversityBefore = useCallback((v: boolean) => {
        if (!formValues) return
        formValues.honnin.isKokiSinnyuseiEnrolledInJapaneseUniversityBefore = v
        updates(formValues, {
            ...errorMessages,
            honnin: {...errorMessages?.honnin, isKokiSinnyuseiEnrolledInJapaneseUniversityBefore: undefined}
        })
    }, [formValues, errorMessages, updates])

    const updateIsPartTimeJob = useCallback((v: boolean) => {
        if (!formValues) return
        formValues.honnin.isPartTimeJob = v
        updates(formValues, {...errorMessages, honnin: {...errorMessages?.honnin, isPartTimeJob: undefined}})
    }, [formValues, errorMessages, updates])

    // const updateIsSelectedScholarshipGrant = useCallback((v: boolean) => {
    //     if (!formValues) return
    //     formValues.honnin.isSelectedScholarshipGrant = v
    //     updates(formValues, {
    //         ...errorMessages,
    //         honnin: {...errorMessages?.honnin, isSelectedScholarshipGrant: undefined}
    //     })
    // }, [formValues, errorMessages, updates])

    return (
        <Grid container spacing={3} direction={"column"}>

            <Grid item xs>
                <Title04
                    title={"申請者 / Applicant"}
                />
            </Grid>

            <FormItem
                label={"実家から住民票を移さずに自宅外通学を行なっていますか？ / Are you commuting to school from other than home without moving your certificate of residence from your family home?"}
            >
                {
                    isSyoruiHantei ? (
                        <Box>
                            {formValues?.honnin.isTugakuFromJitakuWithoutCertificate ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                        </Box>
                    ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isTugakuFromJitakuWithoutCertificate) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isTugakuFromJitakuWithoutCertificate === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isTugakuFromJitakuWithoutCertificate).length !== 0 : false}
                                handleChange={() => {
                                    updateIsTugakuFromJitakuWithoutCertificate(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isTugakuFromJitakuWithoutCertificate === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isTugakuFromJitakuWithoutCertificate).length !== 0 : false}
                                handleChange={() => {
                                    updateIsTugakuFromJitakuWithoutCertificate(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }

            </FormItem>

            <FormItem
                label={"住民票に記載されている者全員の住民税の所得割の税額が0円である世帯ですか？/ Is this a household in which the amount of the income-based tax rate of the resident tax for all the persons listed on the certificate of residence is 0 yen?"}
            >
                {
                    isSyoruiHantei ? (
                            <Box>
                                {formValues?.honnin.isSyotokuwarigaku0yen ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                            </Box>
                        ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isSyotokuwarigaku0yen) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isSyotokuwarigaku0yen === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isSyotokuwarigaku0yen).length !== 0 : false}
                                handleChange={() => {
                                    updateIsSyotokuwarigaku0yen(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isSyotokuwarigaku0yen === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isSyotokuwarigaku0yen).length !== 0 : false}
                                handleChange={() => {
                                    updateIsSyotokuwarigaku0yen(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

            <FormItem
                label={"介護保険制度における要介護度3〜6の者がいる世帯ですか？/ Does the household have a person requiring Level 3 to 6 of care in the long-term care insurance system?"}
            >
                {
                    isSyoruiHantei ? (
                            <Box>
                                {formValues?.honnin.isKaigoLevel3to6 ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                            </Box>
                        ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isKaigoLevel3to6) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isKaigoLevel3to6 === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isKaigoLevel3to6).length !== 0 : false}
                                handleChange={() => {
                                    updateIsKaigoLevel3to6(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isKaigoLevel3to6 === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isKaigoLevel3to6).length !== 0 : false}
                                handleChange={() => {
                                    updateIsKaigoLevel3to6(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

            <FormItem
                label={"6ヶ月以上の治療を要する長期療養者がいる世帯ですか？ / Does the household have a long-term care patient requiring treatment for more than 6 months?"}
            >
                {
                    isSyoruiHantei ? (
                            <Box>
                                {formValues?.honnin.isTyokiryoyoMoreThan6month ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                            </Box>
                        ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isTyokiryoyoMoreThan6month) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isTyokiryoyoMoreThan6month === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isTyokiryoyoMoreThan6month).length !== 0 : false}
                                handleChange={() => {
                                    updateIsTyokiryoyoMoreThan6month(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isTyokiryoyoMoreThan6month === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isTyokiryoyoMoreThan6month).length !== 0 : false}
                                handleChange={() => {
                                    updateIsTyokiryoyoMoreThan6month(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

            <FormItem
                label={"今期入学した1年次生であり、本学入学前に日本国内の大学に在籍していましたか？ / Are you a first-year student admitted in this semester, and were you enrolled in a university in Japan prior to admission to this University?"}
            >
                {
                    isSyoruiHantei ? (
                            <Box>
                                {formValues?.honnin.isKokiSinnyuseiEnrolledInJapaneseUniversityBefore ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                            </Box>
                        ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isKokiSinnyuseiEnrolledInJapaneseUniversityBefore) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isKokiSinnyuseiEnrolledInJapaneseUniversityBefore === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isKokiSinnyuseiEnrolledInJapaneseUniversityBefore).length !== 0 : false}
                                handleChange={() => {
                                    updateIsKokiSinnyuseiEnrolledInJapaneseUniversityBefore(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isKokiSinnyuseiEnrolledInJapaneseUniversityBefore === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isKokiSinnyuseiEnrolledInJapaneseUniversityBefore).length !== 0 : false}
                                handleChange={() => {
                                    updateIsKokiSinnyuseiEnrolledInJapaneseUniversityBefore(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

            <FormItem
                label={"アルバイトをしていますか？/Do you have a part-time job?"}
            >
                {
                    isSyoruiHantei ? (
                            <Box>
                                {formValues?.honnin.isPartTimeJob ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                            </Box>
                        ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isPartTimeJob) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isPartTimeJob === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isPartTimeJob).length !== 0 : false}
                                handleChange={() => {
                                    updateIsPartTimeJob(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isPartTimeJob === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isPartTimeJob).length !== 0 : false}
                                handleChange={() => {
                                    updateIsPartTimeJob(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

          {/*
            <FormItem
                label={"高等教育の修学支援新制度（給付奨学金）に採用されていますか？ / Have you been selected for the New Financial Support System for Higher Education (scholarship grant)?"}
            >
                {
                    isSyoruiHantei ? (
                            <Box>
                                {formValues?.honnin.isSelectedScholarshipGrant ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                            </Box>
                        ) : (

                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isSelectedScholarshipGrant) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isSelectedScholarshipGrant === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isSelectedScholarshipGrant).length !== 0 : false}
                                handleChange={() => {
                                    updateIsSelectedScholarshipGrant(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={formValues?.honnin.isSelectedScholarshipGrant === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(errorMessages.honnin?.isSelectedScholarshipGrant).length !== 0 : false}
                                handleChange={() => {
                                    updateIsSelectedScholarshipGrant(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>
            */}

        </Grid>
    )
}
