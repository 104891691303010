/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwAYosikiFormCommonNihonGakuseiSienKiko
 */
export interface SwAYosikiFormCommonNihonGakuseiSienKiko {
    /**
     * 
     * @type {boolean}
     * @memberof SwAYosikiFormCommonNihonGakuseiSienKiko
     */
    isReceivedAlready?: boolean;
}

export function SwAYosikiFormCommonNihonGakuseiSienKikoFromJSON(json: any): SwAYosikiFormCommonNihonGakuseiSienKiko {
    return SwAYosikiFormCommonNihonGakuseiSienKikoFromJSONTyped(json, false);
}

export function SwAYosikiFormCommonNihonGakuseiSienKikoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormCommonNihonGakuseiSienKiko {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isReceivedAlready': !exists(json, 'isReceivedAlready') ? undefined : json['isReceivedAlready'],
    };
}

export function SwAYosikiFormCommonNihonGakuseiSienKikoToJSON(value?: SwAYosikiFormCommonNihonGakuseiSienKiko | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isReceivedAlready': value.isReceivedAlready,
    };
}


