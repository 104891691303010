/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20019,
    InlineResponse20019FromJSON,
    InlineResponse20019ToJSON,
} from '../models';

export interface PostStudentSendCreateAccountLinkRequest {
    studentNumber: string;
}

/**
 * no description
 */
export class StudentSendCreateAccountLinkApi extends runtime.BaseAPI {

    /**
     * 学生のアカウント作成のリンクをメール送信
     */
    async postStudentSendCreateAccountLinkRaw(requestParameters: PostStudentSendCreateAccountLinkRequest): Promise<runtime.ApiResponse<InlineResponse20019>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling postStudentSendCreateAccountLink.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/send_create_account_link/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20019FromJSON(jsonValue));
    }

    /**
     * 学生のアカウント作成のリンクをメール送信
     */
    async postStudentSendCreateAccountLink(requestParameters: PostStudentSendCreateAccountLinkRequest): Promise<InlineResponse20019> {
        const response = await this.postStudentSendCreateAccountLinkRaw(requestParameters);
        return await response.value();
    }

}
