import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {InfoPolicyRequireSinseisyaInfoVo, SinseisyaInfoVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {
    SinseisyaInfoLabelTextEnum,
    SinseisyaInfoLabelTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/SinseisyaInfoLabelTextEnum";
import {SinseisyaInfoTextEnum, SinseisyaInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SinseisyaInfoTextEnum";
import {Title04} from "components/atoms/titles/Title04";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import React, {FC} from "react";

interface SinseisyaInfoDetailSectionProps {
    className?: string
    studentInfo: StudentInfoStoreVo
    sinseisyaInfo: SinseisyaInfoVo
    requireSinseisyaInfo: InfoPolicyRequireSinseisyaInfoVo;
}

export const _SinseisyaInfoDetailSection: FC<SinseisyaInfoDetailSectionProps> = (props) => {

    const classes = useStyles();
    const sinseisyaInfo: SinseisyaInfoVo = props.sinseisyaInfo;

    const studentInfo = props.studentInfo;

    return (
        <div id={SECTION_ID_NAME.sinseisyaInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.申請者情報)}
                className={"section_title"}
            />

            <FormTable
                className={"section_table"}
            >

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.学籍番号)}
                >
                    {studentInfo.studentNumber}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.氏名)}
                >
                    {studentInfo.name}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.氏名フリガナ)}
                >
                    {studentInfo.nameKana}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.生年月日)}
                >
                    {sinseisyaInfo.birthdayYear}年{sinseisyaInfo.birthdayMonth}月{sinseisyaInfo.birthdayDay}日
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.年齢)}
                >
                    {studentInfo.age}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.学府研究科学部)}
                >
                    {studentInfo.gakubu}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.専攻学科)}
                >
                    {studentInfo.gakka}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.学年)}
                >
                     {sinseisyaInfo.gakunen}年次
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.入学年月)}
                >
                    {studentInfo.nyugakuOn}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.休学)}
                >
                    <FieldWithLabel
                        label={SinseisyaInfoTextEnumToNameJaAndEn(SinseisyaInfoTextEnum.休学)}
                    >
                        {
                            sinseisyaInfo.isKyugaku === true ? CommonTextEnumToNameJaAndEn(CommonTextEnum.はい) :
                                sinseisyaInfo.isKyugaku === false ? CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ) :
                                    ""
                        }
                    </FieldWithLabel>
                </FormTableRow>

                {
                    sinseisyaInfo.isKyugaku ?
                        <FormTableRow
                            label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.休学歴)}
                        >
                            <FieldWithLabel
                                label={SinseisyaInfoTextEnumToNameJaAndEn(SinseisyaInfoTextEnum.休学期間)}
                            >
                                {sinseisyaInfo.kyugaku.startYear}年
                                {sinseisyaInfo.kyugaku.startMonth}月
                                〜
                                {sinseisyaInfo.kyugaku.endYear}年
                                {sinseisyaInfo.kyugaku.endMonth}月
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={SinseisyaInfoTextEnumToNameJaAndEn(SinseisyaInfoTextEnum.休学理由)}
                            >
                                {sinseisyaInfo.kyugaku.riyu}
                            </FieldWithLabel>
                        </FormTableRow>
                        : null
                }

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.留年)}
                >
                    <FieldWithLabel
                        label={SinseisyaInfoTextEnumToNameJaAndEn(SinseisyaInfoTextEnum.留年)}
                    >
                        {
                            sinseisyaInfo.isRyunen === true ? CommonTextEnumToNameJaAndEn(CommonTextEnum.はい) :
                                sinseisyaInfo.isRyunen === false ? CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ) :
                                    ""
                        }
                    </FieldWithLabel>
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.住所)}
                >
                    <div>{sinseisyaInfo.address.zipcode}</div>
                    <div>{sinseisyaInfo.address.address}</div>
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.自宅電話番号)}
                >
                    {sinseisyaInfo.telHome}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.携帯電話番号)}
                >
                    {studentInfo.telGakuseiNyuyroku}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.メールアドレス)}
                >
                    {studentInfo.emailGakuseiNyuyroku}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.研究室電話番号)}
                >
                    {sinseisyaInfo.telLab}
                </FormTableRow>

                <FormTableRow
                    label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.指導教員名)}
                >
                    {sinseisyaInfo.sidoTeacherName}
                </FormTableRow>

                {
                    props.requireSinseisyaInfo.addressFamily ?
                        <FormTableRow
                            label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.住所家族)}
                        >
                            <div>{sinseisyaInfo.addressFamily.zipcode}</div>
                            <div>{sinseisyaInfo.addressFamily.address}</div>
                        </FormTableRow>
                        : null
                }


                {
                    props.requireSinseisyaInfo.telFamily ?
                        <FormTableRow
                            label={SinseisyaInfoLabelTextEnumToNameJaAndEn(SinseisyaInfoLabelTextEnum.電話番号家族)}
                        >
                            {sinseisyaInfo.telFamily}
                        </FormTableRow>
                        : null
                }

            </FormTable>

        </div>

    )
};

export const SinseisyaInfoDetailSection = React.memo<SinseisyaInfoDetailSectionProps>(_SinseisyaInfoDetailSection, (prev, next) => {
    return prev.className === next.className
        && prev.studentInfo === next.studentInfo
        && prev.sinseisyaInfo === next.sinseisyaInfo
});

const useStyles = makeStyles({
        root: {},
        kikan: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            "& > *:not(:first-child)": {
                marginLeft: 4
            }
        }
    }
);

