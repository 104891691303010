/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwSyogakukin
 */
export interface SwSyogakukin {
    /**
     * 
     * @type {string}
     * @memberof SwSyogakukin
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SwSyogakukin
     */
    amountPerMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSyogakukin
     */
    times?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSyogakukin
     */
    startYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSyogakukin
     */
    startMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSyogakukin
     */
    endYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SwSyogakukin
     */
    endMonth?: number;
}

export function SwSyogakukinFromJSON(json: any): SwSyogakukin {
    return SwSyogakukinFromJSONTyped(json, false);
}

export function SwSyogakukinFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyogakukin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'amountPerMonth': !exists(json, 'amountPerMonth') ? undefined : json['amountPerMonth'],
        'times': !exists(json, 'times') ? undefined : json['times'],
        'startYear': !exists(json, 'startYear') ? undefined : json['startYear'],
        'startMonth': !exists(json, 'startMonth') ? undefined : json['startMonth'],
        'endYear': !exists(json, 'endYear') ? undefined : json['endYear'],
        'endMonth': !exists(json, 'endMonth') ? undefined : json['endMonth'],
    };
}

export function SwSyogakukinToJSON(value?: SwSyogakukin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'amountPerMonth': value.amountPerMonth,
        'times': value.times,
        'startYear': value.startYear,
        'startMonth': value.startMonth,
        'endYear': value.endYear,
        'endMonth': value.endMonth,
    };
}


