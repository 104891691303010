import React, {FC} from 'react';
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {Box} from "@material-ui/core";
import {BoxProps} from "@material-ui/core/Box";

export interface Props extends BoxProps {
}

export const StudentMainArea: FC<Props> = (props) => {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Box
            p={xs ? 1.5 : 4}
            fontSize={14}
            border={xs ? `8px solid #F2EDDA` : `16px solid #F2EDDA`}
            {...props}
        >
            {props.children}
        </Box>
    )
};
