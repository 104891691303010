import React from 'react';

export const ModalContentJusyoTouRanNoKazokuRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                留学生は母国の家族住所を記入すること。
            </div>

            <div className={"modalContent_item"}>
                International students must enter the address of their family residence in their home country.
            </div>

        </div>

    );
};
