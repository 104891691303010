import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {joinArraySwErrorMessage} from "common/functions/converter/joinArraySwErrorMessage";
import {ErrorMessageVo} from "common/models/ErrorMessageVo";
import {SyogakukinInfoVo, SyogakukinVo} from "common/models/YosikiInfoModels";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {
    SyogakukinInfoLabelTextEnum,
    SyogakukinInfoLabelTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/SyogakukinInfoLabelTextEnum";
import {SyogakukinInfoTextEnum, SyogakukinInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SyogakukinInfoTextEnum";
import {Button} from "components/atoms/Button";
import {NumberField} from "components/atoms/forms/NumberField";
import {TextField} from "components/atoms/forms/TextField";
import {DescriptionLink} from "components/atoms/DescriptionLink";
import {ErrorCountMessage} from "components/atoms/ErrorCountMessage";
import {Title04} from "components/atoms/titles/Title04";
import {FormButton} from "components/molecules/forms/FormButton";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {YearMonth} from "components/molecules/forms/YearMonth";
import {DescriptionLinkWrap} from "components/molecules/utilities/DescriptionLinkWrap";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {SwSyogakukinErrorMessages, SwSyogakukinInfoErrorMessage} from "openapi/models";
import React, {FC} from "react";
import {BorderColorsEnum} from "common/enums_value/ColorsEnum";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface SyogakukinInfoSectionProps {
    className?: string
    syogakukins: Array<SyogakukinVo>
    syogakukinErrorMessages: Array<SwSyogakukinErrorMessages>
    updateSyogakukinInfo: (part: Partial<SyogakukinInfoVo>) => void
    updateSyogakukinInfoErrorMessage: (part: Partial<SwSyogakukinInfoErrorMessage>) => void
    errorCount: number
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
}

export const _SyogakukinInfoSection: FC<SyogakukinInfoSectionProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const syogakukins = props.syogakukins;
    const syogakukinErrorMessages = props.syogakukinErrorMessages;

    function updateSyogakukin(part: Partial<SyogakukinVo>, index: number) {
        const copied: Array<SyogakukinVo> = syogakukins.slice();
        const newSyogakukin: SyogakukinVo = {
            ...copied[index],
            ...part
        };
        copied.splice(index, 1, newSyogakukin);
        props.updateSyogakukinInfo({syogakukins: copied});
    }

    function updateSyogakukinErrorMessage(part: SwSyogakukinErrorMessages, index: number) {
        const copied: Array<SwSyogakukinErrorMessages> = props.syogakukinErrorMessages.slice();
        const newMessage = {
            ...copied[index],
            ...part
        };
        copied.splice(index, 1, newMessage);
        props.updateSyogakukinInfoErrorMessage({syogakukins: copied});
    }

    function addSyogakukin(syogakukins: Array<SyogakukinVo> | undefined) {
        if (syogakukins === undefined) return;
        const newSyogakukins: Array<SyogakukinVo> = syogakukins.concat(createSyogakukin());
        const syogakukinErrorMessages: Array<SwSyogakukinErrorMessages> = props.syogakukinErrorMessages.concat(createSyogakukinErrorMessage());
        props.updateSyogakukinInfo({
            syogakukins: newSyogakukins
        });
        props.updateSyogakukinInfoErrorMessage({
            syogakukins: syogakukinErrorMessages
        });
    }

    function createSyogakukin(): SyogakukinVo {
        return (
            {
                name: "",
                amountPerMonth: "",
                times: "",
                startYear: "",
                startMonth: "",
                endYear: "",
                endMonth: "",
            }
        )
    }

    function createSyogakukinErrorMessage(): SwSyogakukinErrorMessages {
        return (
            {
                name: undefined,
                amountPerMonth: undefined,
                times: undefined,
                startYear: undefined,
                startMonth: undefined,
                endYear: undefined,
                endMonth: undefined,
            }
        )
    }

    function handleClickAddSyogakukin() {
        addSyogakukin(syogakukins)
    }

    function handleBlurName(value: string, index: number) {
        updateSyogakukin({name: value}, index);
        updateSyogakukinErrorMessage({name: undefined}, index)
    }

    function handleBlurAmountPerMonth(value: string, index: number) {
        updateSyogakukin({amountPerMonth: value}, index);
        updateSyogakukinErrorMessage({amountPerMonth: undefined}, index)
    }

    function handleBlurTimes(value: string, index: number) {
        updateSyogakukin({times: value}, index);
        updateSyogakukinErrorMessage({times: undefined}, index)
    }

    function handleBlurStartYear(value: string, index: number) {
        updateSyogakukin({startYear: value}, index);
        updateSyogakukinErrorMessage({startYear: undefined}, index)
    }

    function handleBlurStartMonth(value: string, index: number) {
        updateSyogakukin({startMonth: value}, index);
        updateSyogakukinErrorMessage({startMonth: undefined}, index)
    }

    function handleBlurEndYear(value: string, index: number) {
        updateSyogakukin({endYear: value}, index);
        updateSyogakukinErrorMessage({endYear: undefined}, index)
    }

    function handleBlurEndMonth(value: string, index: number) {
        updateSyogakukin({endMonth: value}, index);
        updateSyogakukinErrorMessage({endMonth: undefined}, index)
    }

    return (

        <div id={SECTION_ID_NAME.syogakukinInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.奨学金情報)}
                className={"section_title"}
            />

            <div className={"mt8"}>{SyogakukinInfoTextEnumToNameJaAndEn(SyogakukinInfoTextEnum.説明文)}</div>

            <DescriptionLinkWrap>
                <DescriptionLink
                    onClickWordDescriptionLink={props.onClickWordDescriptionLink}
                    type={WordDescriptionTypeEnum.奨学金受給状況欄}
                />
            </DescriptionLinkWrap>

            <ErrorCountMessage
                count={props.errorCount}
            />

            <FormTable
                className={"section_table"}
            >

                <FormTableRow
                    label={SyogakukinInfoLabelTextEnumToNameJaAndEn(SyogakukinInfoLabelTextEnum.奨学金)}
                >

                    <div className={classes.syogakukinWrap}>

                        {
                            props.syogakukins.map((syogakukin, index) => {

                                const errorMessages: SwSyogakukinErrorMessages | undefined = syogakukinErrorMessages[index];

                                const kikanError: boolean =
                                    errorMessages?.startYear !== undefined ||
                                    errorMessages?.startMonth !== undefined ||
                                    errorMessages?.endYear !== undefined ||
                                    errorMessages?.endMonth !== undefined;

                                let kikanErrorMessages: Array<ErrorMessageVo> = [];
                                if (kikanError) {
                                    kikanErrorMessages = joinArraySwErrorMessage([
                                        kikanErrorMessages,
                                        errorMessages?.startYear,
                                        errorMessages?.startMonth,
                                        errorMessages?.endYear,
                                        errorMessages?.endMonth,
                                    ])
                                }

                                return (

                                    <div className={"syogakukinItem"} key={`index${index}`}>

                                        {/*<div className={"syogakukinItem_title"}>{SyogakukinInfoTextEnumToNameJaAndEn(SyogakukinInfoTextEnum.奨学金)}{index + 1}</div>*/}

                                        <div className={`${classes.rowTop} ${xs ? classes.xsRowTop : ""}`}>

                                            <FieldWithLabel
                                                label={SyogakukinInfoTextEnumToNameJaAndEn(SyogakukinInfoTextEnum.名称)}
                                                requiredType={RequiredTagTypeEnum.任意}
                                                width={"184px"}
                                                className={"syogakukinName"}
                                            >
                                                <TextField
                                                    value={syogakukin.name}
                                                    handleBlur={(value) => handleBlurName(value, index)}
                                                    errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.name)}
                                                    isError={isErrorMessage(errorMessages?.name)}
                                                />
                                            </FieldWithLabel>

                                            <FieldWithLabel
                                                label={SyogakukinInfoTextEnumToNameJaAndEn(SyogakukinInfoTextEnum.金額)}
                                                requiredType={RequiredTagTypeEnum.任意}
                                                width={"176px"}
                                                minWidth={"40px"}
                                                className={"syogakukinPrice"}
                                            >
                                                <NumberField
                                                    widthInput={"96px"}
                                                    prefixText={"1回あたりの受給額"}
                                                    suffixText={"円"}
                                                    value={syogakukin.amountPerMonth}
                                                    handleBlur={(value) => handleBlurAmountPerMonth(value, index)}
                                                    errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.amountPerMonth)}
                                                    isError={isErrorMessage(errorMessages?.amountPerMonth)}
                                                />
                                            </FieldWithLabel>

                                            <FieldWithLabel
                                                label={SyogakukinInfoTextEnumToNameJaAndEn(SyogakukinInfoTextEnum.受給回数)}
                                                requiredType={RequiredTagTypeEnum.任意}
                                                width={"224px"}
                                            >
                                                <NumberField
                                                    widthInput={"40px"}
                                                    value={syogakukin.times}
                                                    suffixText={"回"}
                                                    handleBlur={(value) => handleBlurTimes(value, index)}
                                                    errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.times)}
                                                    isError={isErrorMessage(errorMessages?.times)}
                                                />
                                            </FieldWithLabel>

                                        </div>

                                        <div className={classes.rowBottom}>

                                            <FieldWithLabel
                                                label={SyogakukinInfoTextEnumToNameJaAndEn(SyogakukinInfoTextEnum.受給期間)}
                                                requiredType={RequiredTagTypeEnum.任意}
                                                errorMessages={convertErrorMessageVoToArrayStr(kikanErrorMessages)}
                                            >
                                                <div className={`${classes.kikan} ${xs && classes.xsKikan}`}>

                                                    <YearMonth
                                                        prefixTextYear={"西暦"}
                                                        valueYear={syogakukin.startYear}
                                                        valueMonth={syogakukin.startMonth}
                                                        isErrorYear={isErrorMessage(errorMessages?.startYear)}
                                                        isErrorMonth={isErrorMessage(errorMessages?.startMonth)}
                                                        handleBlurYear={(value) => handleBlurStartYear(value, index)}
                                                        handleBlurMonth={(value) => handleBlurStartMonth(value, index)}
                                                    />
                                                    <div>〜</div>
                                                    <YearMonth
                                                        prefixTextYear={"西暦"}
                                                        valueYear={syogakukin.endYear}
                                                        valueMonth={syogakukin.endMonth}
                                                        isErrorYear={isErrorMessage(errorMessages?.endYear)}
                                                        isErrorMonth={isErrorMessage(errorMessages?.endMonth)}
                                                        handleBlurYear={(value) => handleBlurEndYear(value, index)}
                                                        handleBlurMonth={(value) => handleBlurEndMonth(value, index)}
                                                    />
                                                </div>

                                            </FieldWithLabel>

                                        </div>

                                    </div>

                                )
                            })
                        }
                    </div>

                </FormTableRow>

            </FormTable>

            <FormButton
                className={"section_button"}
            >
                <Button
                    type={"primary"}
                    label={"奨学金を追加 / Add scholarship field"}
                    handleClick={handleClickAddSyogakukin}
                />
            </FormButton>

        </div>

    )
};

export const SyogakukinInfoSection = React.memo<SyogakukinInfoSectionProps>(_SyogakukinInfoSection, (prev, next) => {
    return prev.className === next.className
        && prev.syogakukins === next.syogakukins
        && prev.syogakukinErrorMessages === next.syogakukinErrorMessages
        && prev.errorCount === next.errorCount
        && prev.onClickWordDescriptionLink === next.onClickWordDescriptionLink
});

const useStyles = makeStyles({
        root: {},
        rowTop: {
            display: "flex",
            "& > *:not(:first-child)": {
                marginLeft: 8,
            }
        },
        syogakukinWrap: {
            "& .syogakukinItem": {
                paddingBottom: 8,
                "& .syogakukinItem_title": {
                    fontWeight: 700,
                }
            },
            "& > .syogakukinItem:not(:first-child)": {
                borderTop: `1px solid ${BorderColorsEnum.LightGray}`,
                paddingTop: 8
            }
        },
        rowBottom: {
            display: "flex",
            alignItems: "center",
        },
        kikan: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            "& > *:not(:first-child)": {
                marginLeft: 4
            }
        },
        xsKikan: {
            flexWrap: "wrap",
            "& > *:last-child": {
                marginTop: 4,
                marginLeft: 0
            }
        },
        xsRowTop: {
            display: "block",
            flexWrap: "wrap",
            "& .syogakukinName": {
                minWidth: "100%"
            },
            "& > *:not(:first-child)": {
                marginLeft: 0,
            }
        }
    }
);

