/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwYosikiInfoErrorMessageResponse,
    SwYosikiInfoErrorMessageResponseFromJSON,
    SwYosikiInfoErrorMessageResponseFromJSONTyped,
    SwYosikiInfoErrorMessageResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2006
     */
    isSuccess: boolean;
    /**
     * 
     * @type {SwYosikiInfoErrorMessageResponse}
     * @memberof InlineResponse2006
     */
    error?: SwYosikiInfoErrorMessageResponse;
}

export function InlineResponse2006FromJSON(json: any): InlineResponse2006 {
    return InlineResponse2006FromJSONTyped(json, false);
}

export function InlineResponse2006FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2006 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSuccess': json['isSuccess'],
        'error': !exists(json, 'error') ? undefined : SwYosikiInfoErrorMessageResponseFromJSON(json['error']),
    };
}

export function InlineResponse2006ToJSON(value?: InlineResponse2006 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSuccess': value.isSuccess,
        'error': SwYosikiInfoErrorMessageResponseToJSON(value.error),
    };
}


