/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwOfficerKikansStudentIdKikansKikan,
    SwOfficerKikansStudentIdKikansKikanFromJSON,
    SwOfficerKikansStudentIdKikansKikanFromJSONTyped,
    SwOfficerKikansStudentIdKikansKikanToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerKikansStudentIdKikans
 */
export interface SwOfficerKikansStudentIdKikans {
    /**
     * 
     * @type {SwOfficerKikansStudentIdKikansKikan}
     * @memberof SwOfficerKikansStudentIdKikans
     */
    nyuryokuKikan: SwOfficerKikansStudentIdKikansKikan;
    /**
     * 
     * @type {SwOfficerKikansStudentIdKikansKikan}
     * @memberof SwOfficerKikansStudentIdKikans
     */
    sinseiKikan: SwOfficerKikansStudentIdKikansKikan;
    /**
     * 
     * @type {SwOfficerKikansStudentIdKikansKikan}
     * @memberof SwOfficerKikansStudentIdKikans
     */
    teiseiKikan: SwOfficerKikansStudentIdKikansKikan;
}

export function SwOfficerKikansStudentIdKikansFromJSON(json: any): SwOfficerKikansStudentIdKikans {
    return SwOfficerKikansStudentIdKikansFromJSONTyped(json, false);
}

export function SwOfficerKikansStudentIdKikansFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerKikansStudentIdKikans {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nyuryokuKikan': SwOfficerKikansStudentIdKikansKikanFromJSON(json['nyuryokuKikan']),
        'sinseiKikan': SwOfficerKikansStudentIdKikansKikanFromJSON(json['sinseiKikan']),
        'teiseiKikan': SwOfficerKikansStudentIdKikansKikanFromJSON(json['teiseiKikan']),
    };
}

export function SwOfficerKikansStudentIdKikansToJSON(value?: SwOfficerKikansStudentIdKikans | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nyuryokuKikan': SwOfficerKikansStudentIdKikansKikanToJSON(value.nyuryokuKikan),
        'sinseiKikan': SwOfficerKikansStudentIdKikansKikanToJSON(value.sinseiKikan),
        'teiseiKikan': SwOfficerKikansStudentIdKikansKikanToJSON(value.teiseiKikan),
    };
}


