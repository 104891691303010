import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {SyogakukinVo} from "common/models/YosikiInfoModels";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {
    SyogakukinInfoLabelTextEnum,
    SyogakukinInfoLabelTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/SyogakukinInfoLabelTextEnum";
import {SyogakukinInfoTextEnum, SyogakukinInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SyogakukinInfoTextEnum";
import {Title04} from "components/atoms/titles/Title04";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {SwSyogakukinErrorMessages} from "openapi/models";
import React, {FC} from "react";
import {convertStrToNumOrUndefined} from "common/functions/type_converter/convertStrToNumOrUndefined";
import {BorderColorsEnum} from "common/enums_value/ColorsEnum";

interface SyogakukinInfoDetailSectionProps {
    className?: string
    syogakukins: Array<SyogakukinVo>
    syogakukinErrorMessages: Array<SwSyogakukinErrorMessages>
}

export const _SyogakukinInfoDetailSection: FC<SyogakukinInfoDetailSectionProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("xs"));

    return (

        <div id={SECTION_ID_NAME.syogakukinInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.奨学金情報)}
                className={"section_title"}
            />

            <FormTable
                className={"section_table"}
            >

                <FormTableRow
                    label={SyogakukinInfoLabelTextEnumToNameJaAndEn(SyogakukinInfoLabelTextEnum.奨学金)}
                >

                    <div className={classes.syogakukinWrap}>

                        {
                            props.syogakukins.map((syogakukin, index) => {

                                return (

                                    <div className={"syogakukinItem"} key={`index${index}`}>

                                        {/*<div className={"syogakukinItem_title"}>奨学金{index + 1}</div>*/}

                                        <div className={`${classes.rowTop} ${matches ? classes.xsRowTop : ""}`}>

                                            <FieldWithLabel
                                                label={SyogakukinInfoTextEnumToNameJaAndEn(SyogakukinInfoTextEnum.名称)}
                                                minWidth={"184px"}
                                                className={"syogakukinName"}
                                            >
                                                {syogakukin.name}
                                            </FieldWithLabel>

                                            <FieldWithLabel
                                                label={SyogakukinInfoTextEnumToNameJaAndEn(SyogakukinInfoTextEnum.金額)}
                                                minWidth={"184px"}
                                                className={"syogakukinPrice"}
                                            >
                                                1回あたりの受給額 {syogakukin.amountPerMonth ? convertStrToNumOrUndefined(syogakukin.amountPerMonth)?.toLocaleString() : ""}円
                                            </FieldWithLabel>

                                            <FieldWithLabel
                                                label={SyogakukinInfoTextEnumToNameJaAndEn(SyogakukinInfoTextEnum.受給回数)}
                                            >
                                                {syogakukin.times}回
                                            </FieldWithLabel>

                                        </div>

                                        <div className={classes.rowBottom}>

                                            <FieldWithLabel
                                                label={SyogakukinInfoTextEnumToNameJaAndEn(SyogakukinInfoTextEnum.受給期間)}
                                            >
                                                {syogakukin.startYear}年
                                                {syogakukin.startMonth}月
                                                〜
                                                {syogakukin.endYear}年
                                                {syogakukin.endMonth}月

                                            </FieldWithLabel>

                                        </div>

                                    </div>

                                )
                            })
                        }
                    </div>

                </FormTableRow>

            </FormTable>

        </div>

    )
};

export const SyogakukinInfoDetailSection = React.memo<SyogakukinInfoDetailSectionProps>(_SyogakukinInfoDetailSection, (prev, next) => {
    return prev.className === next.className
        && prev.syogakukins === next.syogakukins
        && prev.syogakukinErrorMessages === next.syogakukinErrorMessages
});

const useStyles = makeStyles({
        root: {},
        rowTop: {
            display: "flex",
            "& > *:not(:first-child)": {
                marginLeft: 8,
            }
        },
        syogakukinWrap: {
            "& .syogakukinItem": {
                paddingBottom: 8,
                "& .syogakukinItem_title": {
                    fontWeight: 700,
                }
            },
            "& > .syogakukinItem:not(:first-child)": {
                borderTop: `1px solid ${BorderColorsEnum.LightGray}`,
                paddingTop: 8
            }
        },
        rowBottom: {
            display: "flex",
            alignItems: "center",
        },
        kikan: {
            display: "flex",
            alignItems: "center",
            "& > *:not(:first-child)": {
                marginLeft: 8
            }
        },
        xsRowTop: {
            display: "block",
            flexWrap: "wrap",
            "& .syogakukinName": {
                minWidth: "100%"
            },
            "& > *:not(:first-child)": {
                marginLeft: 0,
            }
        }
    }
);

