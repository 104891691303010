import React, {FC} from "react";
import {ListDsc} from "../../../atoms/ListDsc";
import { Box } from "@material-ui/core";
import {BoxProps} from "@material-ui/core/Box";

interface Props extends BoxProps {}

export const AYosiki1Description: FC<Props> = (props) => {
    return (
        <Box {...props}>

            <ListDsc>
                <li>
                    大学等における修学の支援に関する法律による修学支援は、授業料等減免と給付奨学金により行うこととしております。<br/>
                    このため、<span
                    className={"underLine"}>あらかじめ機構に給付奨学金の申込みを行ってください。</span><br/>
                    給付奨学金の申込みがない場合、授業料等減免の認定が遅れる等の原因になります。<br/>
                    給付奨学金の申込みを行わず、「機構の給付奨学金に関する情報」の欄を記入できない場合は、別紙1の提出が必要です。<br/>
                    更に、本学に編入学又は転学(若しくは専攻科に入学)した学生であって、編入学又は転学(若しくは専攻科に入学)する前に在学していた学校(大学、短大、高専、専門学校)が2つ以上ある場合は、あわせて別紙2の提出が必要です。<br/>
                    家計急変による申込を行う場合は、あわせて別紙3の提出が必要です。(給付奨学金をあわせて申し込む(既に申し込んでいる)場合は、別紙1~3の提出は不要です。)<br/>
                    なお、給付奨学金と授業料等減免の認定の要件は同一であるため、給付奨学金に申し込んだ結果、認定を受けることができなかった(給付奨学生として採用されなかった)場合は、同じ期間、授業料等減免の支援についても受けることはできません。<br/>
                </li>
                <li>
                    「機構の給付奨学金に関する情報」の欄について、予約採用における採用候補者は、採用候補者決定通知の受付番号を記人するとともに、採用候補者決定通知のコピーを必ず添付してください。
                </li>
                <li>
                    過去に、大学等における修学の支援に関する法律による授業料等減免の支援を受けたことがある場合には、当該期間の月数を申告してください。
                </li>
                <li>
                    入学年月について、編入学又は転学等により入学した場合は、その年月を記入してください。<br/>
                    専攻科に在学している場合は、専攻科に入学した年月を記入してください。
                </li>
                <li>
                    申請書に記載された内容及び提出された書類の情報は、授業料等減免の認定及び本学が実施する経済支援のために利用します。<br/>
                    また、今後の授業料等減免制度の検討のため、統計資料の作成に利用する場合がありますが、作成に際しては個人が特定できないように処理します。
                </li>
                <li>
                    Academic financial support according to the Act to Support Study at Universities, etc. is provided
                    by means of reduction and waivers of tuition, etc., and scholarship grants. <br />
                    Therefore, <span
                    className={"underLine"}>applications for
                scholarship grants should be made to the Organization in advance.</span> <br />
                    If applications for scholarship grants are not made, approval of reduction or waivers of tuition, etc. may be delayed.<br/>
                    If you have not applied for a scholarship and cannot complete the “Information on Scholarship Grants
                    from the Organization” field, you will need to submit Attachment 1. <br />
                    Furthermore, if you are a student who entered this school by mid-course entry or by transfer
                    (or enrollment in a non-degree course for graduates), and were enrolled in two or more schools
                    (universities, junior colleges, colleges of technology, professional training colleges)
                    before entering this school by mid-course entry or by transfer (or enrollment in a non-degree course for graduates),
                    you will also need to submit Attachment 2.<br/>
                    If you are applying due to a sudden change in the financial situation of your family, you will
                    also need to submit Attachment 3. (If you are applying (or have already applied) for a scholarship grant
                    together with this, you will not need to submit Attachments 1 - 3)<br/>
                    Since the requirements for approval of reduction or waivers of tuition, etc. are the same as those
                    for scholarship grants, if the applicant was unable to obtain approval after applying for a scholarship
                    grant (was not accepted as a scholarship grant recipient), the applicant will not be able to receive
                    support for reduction or waivers of tuition, etc. for the same semester.
                </li>
                <li>
                    In the “Information on Scholarship Grants from the Organization” field, candidates for acceptance by
                    scheduled acceptance must enter the acceptance number of the Notice on Determination as an
                    Acceptance Candidate, and must also attach a photocopy of the Notice on Determination as an Acceptance
                    Candidate.
                </li>
                <li>
                    If you have received past support for reduction or waivers of tuition, etc. under the Act to Support
                    Study at Universities, etc., state the number of months in the duration of the applicable support.
                </li>
                <li>
                    For the Date of Enrollment, if you entered this school by mid-course entry or by transfer, enter that date.<br/>
                    If you are enrolled in a non-degree course for graduates, enter the date of your enrollment in that non-degree
                    course for graduates.
                </li>
                <li>
                    The information indicated on the application form and submitted documents will be used for approval
                    of
                    reduction or waivers of tuition, etc. and for financial support provided by this University.<br/>
                    It may also be used to compile statistical data for future investigation of systems for reduction or waivers of
                    tuition, etc., but when doing so it will be processed so that individuals cannot be identified.
                </li>
            </ListDsc>
        </Box>
    )
};
