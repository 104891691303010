/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SyoruiConfirmationSyoruis,
    SyoruiConfirmationSyoruisFromJSON,
    SyoruiConfirmationSyoruisFromJSONTyped,
    SyoruiConfirmationSyoruisToJSON,
    SyoruiConfirmationYoryos,
    SyoruiConfirmationYoryosFromJSON,
    SyoruiConfirmationYoryosFromJSONTyped,
    SyoruiConfirmationYoryosToJSON,
} from './';

/**
 * 
 * @export
 * @interface SyoruiConfirmationHonnin
 */
export interface SyoruiConfirmationHonnin {
    /**
     * 
     * @type {Array<SyoruiConfirmationYoryos>}
     * @memberof SyoruiConfirmationHonnin
     */
    yoryos: Array<SyoruiConfirmationYoryos>;
    /**
     * 
     * @type {Array<SyoruiConfirmationSyoruis>}
     * @memberof SyoruiConfirmationHonnin
     */
    syoruis?: Array<SyoruiConfirmationSyoruis>;
}

export function SyoruiConfirmationHonninFromJSON(json: any): SyoruiConfirmationHonnin {
    return SyoruiConfirmationHonninFromJSONTyped(json, false);
}

export function SyoruiConfirmationHonninFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyoruiConfirmationHonnin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'yoryos': ((json['yoryos'] as Array<any>).map(SyoruiConfirmationYoryosFromJSON)),
        'syoruis': !exists(json, 'syoruis') ? undefined : ((json['syoruis'] as Array<any>).map(SyoruiConfirmationSyoruisFromJSON)),
    };
}

export function SyoruiConfirmationHonninToJSON(value?: SyoruiConfirmationHonnin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'yoryos': ((value.yoryos as Array<any>).map(SyoruiConfirmationYoryosToJSON)),
        'syoruis': value.syoruis === undefined ? undefined : ((value.syoruis as Array<any>).map(SyoruiConfirmationSyoruisToJSON)),
    };
}


