import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {BukyokuEnum} from "common/enums/BukyokuEnum";
import {YosikiStateEnum} from "common/enums/YosikiStateEnum";
import {getIsEnteredYosiki} from "common/functions/getIsEnteredYosiki";
import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {StudentAccountInfoApi, StudentLogoutApi} from "openapi/apis";
import {SwStudentInfo} from "openapi/models";
import {yosikiInfoModalStore} from "pages/student/yosiki/stores/YosikiInfoModalStore";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";

const INIT_STUDENT_INFO = {
    studentNumber: "",
    name: "",
    nameKana: "",
    age: 0,
    bukyokuCode: BukyokuEnum.農学,
    gakubu: "",
    gakka: "",
    nyugakuOn: "",
    telGakuseiNyuyroku: "",
    emailGakuseiNyuyroku: "",
    isGakubu: false,
    isSinnyusei: false,
    isRyugakusei: false,
    isTaisyo: true,
    isAyosiki: false,
    yosikiHasError: false,
    isSyoruiHantei: false,
    isAYosikiKakutei: false,
};

class AccountInfoStore extends Store<StudentInfoStoreVo> {

    private api = new StudentAccountInfoApi(apiConfiguration);
    private studentLogoutApi = new StudentLogoutApi(apiConfiguration);

    constructor() {
        super(INIT_STUDENT_INFO)
    }

    async updateStudentInfo(part: Partial<StudentInfoStoreVo>) {
        const newForm = {
            ...this.currentState,
            ...part
        };
        this.dispatch(() => (newForm));
    }

    clearAccountInfo() {
        this.dispatch(() => (INIT_STUDENT_INFO));
    }

    async getStudentInfo() {
        try {
            const res: SwStudentInfo = await this.api.getStudentAccountInfo();
            this.updateStudentInfo({
                ...res,
                yosikiState: res.yosikiState as YosikiStateEnum,
                bukyokuCode: res.bukyokuCode as BukyokuEnum
            });
            yosikiInfoModalStore.updateBasicInfoModalOpenState(!getIsEnteredYosiki(res.yosikiState as YosikiStateEnum))
        } catch (res) {
            handleStudentError(res)
        }
    }

    async postLogout() {
        await this.studentLogoutApi.postStudentLogout();
        this.clearAccountInfo();
        window.location.assign(URL_PATHS_STUDENT.login)
    }

}

export const accountInfoStore = new AccountInfoStore();

