import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import React, {FC} from "react";
import {BorderColorsEnum} from "common/enums_value/ColorsEnum";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface FormTableRowProps {
    requiredType?: RequiredTagTypeEnum
    label: string
}

export const FormTableRow: FC<FormTableRowProps> = (props) => {
    const classes = useStyles();

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    // const result = props.result;


    return (

        <tr className={`${classes.root} ${xs && classes.xsRoot}`}>
            <th>
                <div className={"headInner"}>
                    <div>{props.label}</div>
                    {
                        props.requiredType &&
                        <RequiredTag
                            className={"headInner_tag"}
                            type={props.requiredType}
                        />
                    }
                </div>
            </th>
            <td>
                {props.children}
            </td>
        </tr>


    )
};

const useStyles = makeStyles({
        root: {
            "& > th": {
                wordBreak: "break-word",
                "& .headInner": {
                    display: "flex",
                    alignItems: "center",
                    "& .headInner_tag": {
                        marginLeft: "auto",
                    }
                }
            },
            "& > td": {
                "& > .formTableRow_item:not(:first-child)": {
                    marginTop: 4
                },
                "& > .formTableRow_item": {
                    paddingTop: 4,
                    "& .formTableRow_item_label": {
                        fontWeight: 700,
                        marginBottom: 2,
                        "& .requiredTag": {
                            marginLeft: 4,
                            marginTop: -2,
                        }
                    }
                },
                "& > .formTableRow_item-borederTop": {
                    borderTop: `1px solid ${BorderColorsEnum.LightGray}`,
                    paddingTop: 8,
                    paddingBottom: 8
                },

            }
        },
        xsRoot: {
            "& > th": {
                "& .headInner": {
                    "& .headInner_tag": {
                        marginLeft: "8px",
                    }
                }
            },
        }
    }
);

