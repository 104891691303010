/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSyogaisyaError
 */
export interface SwSyogaisyaError {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogaisyaError
     */
    zokugara?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogaisyaError
     */
    zokugaraOther?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogaisyaError
     */
    type?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogaisyaError
     */
    typeOther?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogaisyaError
     */
    tetyoNumber?: Array<SwErrorMessage>;
}

export function SwSyogaisyaErrorFromJSON(json: any): SwSyogaisyaError {
    return SwSyogaisyaErrorFromJSONTyped(json, false);
}

export function SwSyogaisyaErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyogaisyaError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zokugara': !exists(json, 'zokugara') ? undefined : ((json['zokugara'] as Array<any>).map(SwErrorMessageFromJSON)),
        'zokugaraOther': !exists(json, 'zokugaraOther') ? undefined : ((json['zokugaraOther'] as Array<any>).map(SwErrorMessageFromJSON)),
        'type': !exists(json, 'type') ? undefined : ((json['type'] as Array<any>).map(SwErrorMessageFromJSON)),
        'typeOther': !exists(json, 'typeOther') ? undefined : ((json['typeOther'] as Array<any>).map(SwErrorMessageFromJSON)),
        'tetyoNumber': !exists(json, 'tetyoNumber') ? undefined : ((json['tetyoNumber'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwSyogaisyaErrorToJSON(value?: SwSyogaisyaError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zokugara': value.zokugara === undefined ? undefined : ((value.zokugara as Array<any>).map(SwErrorMessageToJSON)),
        'zokugaraOther': value.zokugaraOther === undefined ? undefined : ((value.zokugaraOther as Array<any>).map(SwErrorMessageToJSON)),
        'type': value.type === undefined ? undefined : ((value.type as Array<any>).map(SwErrorMessageToJSON)),
        'typeOther': value.typeOther === undefined ? undefined : ((value.typeOther as Array<any>).map(SwErrorMessageToJSON)),
        'tetyoNumber': value.tetyoNumber === undefined ? undefined : ((value.tetyoNumber as Array<any>).map(SwErrorMessageToJSON)),
    };
}


