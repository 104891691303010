/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SwAYosikiSyoruis,
    SwAYosikiSyoruisFromJSON,
    SwAYosikiSyoruisToJSON,
} from '../models';

/**
 * no description
 */
export class StudentAYosikiSyoruisApi extends runtime.BaseAPI {

    /**
     * A様式学生の書類一覧の取得 A様式の入力内容から提出が必要な書類の一覧を取得する 確定状態でなければundefinedとして扱う 
     */
    async getStudentAYosikiSyoruisRaw(): Promise<runtime.ApiResponse<SwAYosikiSyoruis>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/a_yosiki_syoruis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwAYosikiSyoruisFromJSON(jsonValue));
    }

    /**
     * A様式学生の書類一覧の取得 A様式の入力内容から提出が必要な書類の一覧を取得する 確定状態でなければundefinedとして扱う 
     */
    async getStudentAYosikiSyoruis(): Promise<SwAYosikiSyoruis> {
        const response = await this.getStudentAYosikiSyoruisRaw();
        return await response.value();
    }

}
