import {Help} from "@material-ui/icons";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {TextLink} from "components/atoms/TextLink";
import {WordDescriptionTypeEnum, WordDescriptionTypeEnumToName} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import React from "react";

interface DescriptionLinkProps {
    type: WordDescriptionTypeEnum
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
}

export const DescriptionLink: React.FC<DescriptionLinkProps> = (props) => {
    return (
        <div>
            <TextLink
                icon={<Help/>}
                text={convertEnumNameToStr(WordDescriptionTypeEnumToName(props.type))}
                onClick={() => props.onClickWordDescriptionLink(props.type)}
                iconReverse={true}
            />
        </div>
    )
};
