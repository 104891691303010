/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiFormCommonBessi1ErrorMessage
 */
export interface SwAYosikiFormCommonBessi1ErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormCommonBessi1ErrorMessage
     */
    isApply?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormCommonBessi1ErrorMessage
     */
    isOnlyTuitionWaiver?: Array<SwErrorMessage>;
}

export function SwAYosikiFormCommonBessi1ErrorMessageFromJSON(json: any): SwAYosikiFormCommonBessi1ErrorMessage {
    return SwAYosikiFormCommonBessi1ErrorMessageFromJSONTyped(json, false);
}

export function SwAYosikiFormCommonBessi1ErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormCommonBessi1ErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isApply': !exists(json, 'isApply') ? undefined : ((json['isApply'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isOnlyTuitionWaiver': !exists(json, 'isOnlyTuitionWaiver') ? undefined : ((json['isOnlyTuitionWaiver'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwAYosikiFormCommonBessi1ErrorMessageToJSON(value?: SwAYosikiFormCommonBessi1ErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isApply': value.isApply === undefined ? undefined : ((value.isApply as Array<any>).map(SwErrorMessageToJSON)),
        'isOnlyTuitionWaiver': value.isOnlyTuitionWaiver === undefined ? undefined : ((value.isOnlyTuitionWaiver as Array<any>).map(SwErrorMessageToJSON)),
    };
}


