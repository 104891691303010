import {useMediaQuery} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import React, {FC} from "react";
import {BgColorsEnum, BorderColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";

interface FormTableProps {
    id?: string;
    className?: string;
    labelWidth?: string
}

export const FormTable: FC<FormTableProps> = ({
                                                  id,
                                                  className,
                                                  labelWidth,
                                                  children
                                              }) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    return (

        <table id={id} className={`${classes.root} ${xs && classes.xsRoot} ${className} `}>

            <colgroup>
                <col width={labelWidth ? labelWidth : "160px"}/>
                <col/>
            </colgroup>

            <tbody>
            {children}
            </tbody>

        </table>


    )
};

const useStyles = makeStyles({
        root: {
            tableLayout: "fixed",
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "8px",
            borderTop: `1px solid ${BorderColorsEnum.LightGray}`,
            "& > tbody": {
                "& > tr": {
                    borderBottom: `1px solid ${BorderColorsEnum.LightGray}`,
                    "& > th": {
                        backgroundColor: BgColorsEnum.TableHead,
                        color: TextColorsEnum.White,
                        padding: "4px 8px",
                        textAlign: "left",
                        "&.check": {
                            textAlign: "center"
                        },
                    },
                    "& > td": {
                        padding: "4px 8px",
                        "&.check": {
                            textAlign: "center"
                        },
                    },
                },
            },
            "&:not(:first-child)": {
                marginTop: 0
            },
        },
        xsRoot: {
            display: "block",
            "& > tbody": {
                display: "block",
                "& > tr": {
                    display: "block",
                    borderBottom: "none",
                    "&:not(:first-child)": {
                        "& > th": {
                            marginTop: 8
                        },
                    },
                    "& > th": {
                        display: "block",
                    },
                    "& > td": {
                        display: "block",
                        padding: 8,
                    },
                },
            },
        }
    }
);


