/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwOsirase,
    SwOsiraseFromJSON,
    SwOsiraseFromJSONTyped,
    SwOsiraseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOsirasesResponse
 */
export interface SwOsirasesResponse {
    /**
     * 
     * @type {Array<SwOsirase>}
     * @memberof SwOsirasesResponse
     */
    osirases: Array<SwOsirase>;
}

export function SwOsirasesResponseFromJSON(json: any): SwOsirasesResponse {
    return SwOsirasesResponseFromJSONTyped(json, false);
}

export function SwOsirasesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOsirasesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'osirases': ((json['osirases'] as Array<any>).map(SwOsiraseFromJSON)),
    };
}

export function SwOsirasesResponseToJSON(value?: SwOsirasesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'osirases': ((value.osirases as Array<any>).map(SwOsiraseToJSON)),
    };
}


