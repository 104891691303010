/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSinseiType,
    SwSinseiTypeFromJSON,
    SwSinseiTypeFromJSONTyped,
    SwSinseiTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwBasicInfoResponse
 */
export interface SwBasicInfoResponse {
    /**
     * 
     * @type {SwSinseiType}
     * @memberof SwBasicInfoResponse
     */
    sinseiType: SwSinseiType;
    /**
     * 
     * @type {Array<string>}
     * @memberof SwBasicInfoResponse
     */
    tokubetuKojo?: Array<string>;
}

export function SwBasicInfoResponseFromJSON(json: any): SwBasicInfoResponse {
    return SwBasicInfoResponseFromJSONTyped(json, false);
}

export function SwBasicInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwBasicInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sinseiType': SwSinseiTypeFromJSON(json['sinseiType']),
        'tokubetuKojo': !exists(json, 'tokubetuKojo') ? undefined : json['tokubetuKojo'],
    };
}

export function SwBasicInfoResponseToJSON(value?: SwBasicInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sinseiType': SwSinseiTypeToJSON(value.sinseiType),
        'tokubetuKojo': value.tokubetuKojo,
    };
}


