import {FamilyInfoHonninVo} from "common/models/YosikiInfoModels";
import {FamilyInfoLabelTextEnum, FamilyInfoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoLabelTextEnum";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {FC} from "react";

interface FamilyInfoSectionSyugakusyaHoninDetailProps {
    honnin: FamilyInfoHonninVo
}

export const FamilyInfoSectionSyugakusyaHoninDetail: FC<FamilyInfoSectionSyugakusyaHoninDetailProps> = (props) => {

    const honnin: FamilyInfoHonninVo = props.honnin;

    return (
        <FormTableRow
            label={FamilyInfoLabelTextEnumToNameJaAndEn(FamilyInfoLabelTextEnum.本人)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.通学区分)}
                </div>
                {
                    honnin.tugakuFromJitaku === true ? FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅):
                        honnin.tugakuFromJitaku === false ? FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.自宅外):
                            ""
                }
            </div>
        </FormTableRow>
    )
};

