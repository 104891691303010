/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyoruiConfirmationFormMember
 */
export interface SyoruiConfirmationFormMember {
    /**
     * ZokugaraEnum
     * @type {string}
     * @memberof SyoruiConfirmationFormMember
     */
    zokugara: string;
    /**
     * 
     * @type {string}
     * @memberof SyoruiConfirmationFormMember
     */
    zokugaraOther: string;
    /**
     * 
     * @type {string}
     * @memberof SyoruiConfirmationFormMember
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormMember
     */
    isJuminhyoDifference?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormMember
     */
    isMusyokusya?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormMember
     */
    isFinancialAssistanceFromRelatives?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormMember
     */
    isIncomeFromInsuranceMoney?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormMember
     */
    isRinjiIncome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormMember
     */
    isTaisyokuJiki?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormMember
     */
    isPlanToFindJob?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormMember
     */
    isKakeisijisya?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormMember
     */
    isTaisyokuJikiForMusyokusya?: boolean;
}

export function SyoruiConfirmationFormMemberFromJSON(json: any): SyoruiConfirmationFormMember {
    return SyoruiConfirmationFormMemberFromJSONTyped(json, false);
}

export function SyoruiConfirmationFormMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyoruiConfirmationFormMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zokugara': json['zokugara'],
        'zokugaraOther': json['zokugaraOther'],
        'name': json['name'],
        'isJuminhyoDifference': !exists(json, 'isJuminhyoDifference') ? undefined : json['isJuminhyoDifference'],
        'isMusyokusya': !exists(json, 'isMusyokusya') ? undefined : json['isMusyokusya'],
        'isFinancialAssistanceFromRelatives': !exists(json, 'isFinancialAssistanceFromRelatives') ? undefined : json['isFinancialAssistanceFromRelatives'],
        'isIncomeFromInsuranceMoney': !exists(json, 'isIncomeFromInsuranceMoney') ? undefined : json['isIncomeFromInsuranceMoney'],
        'isRinjiIncome': !exists(json, 'isRinjiIncome') ? undefined : json['isRinjiIncome'],
        'isTaisyokuJiki': !exists(json, 'isTaisyokuJiki') ? undefined : json['isTaisyokuJiki'],
        'isPlanToFindJob': !exists(json, 'isPlanToFindJob') ? undefined : json['isPlanToFindJob'],
        'isKakeisijisya': !exists(json, 'isKakeisijisya') ? undefined : json['isKakeisijisya'],
        'isTaisyokuJikiForMusyokusya': !exists(json, 'isTaisyokuJikiForMusyokusya') ? undefined : json['isTaisyokuJikiForMusyokusya'],
    };
}

export function SyoruiConfirmationFormMemberToJSON(value?: SyoruiConfirmationFormMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zokugara': value.zokugara,
        'zokugaraOther': value.zokugaraOther,
        'name': value.name,
        'isJuminhyoDifference': value.isJuminhyoDifference,
        'isMusyokusya': value.isMusyokusya,
        'isFinancialAssistanceFromRelatives': value.isFinancialAssistanceFromRelatives,
        'isIncomeFromInsuranceMoney': value.isIncomeFromInsuranceMoney,
        'isRinjiIncome': value.isRinjiIncome,
        'isTaisyokuJiki': value.isTaisyokuJiki,
        'isPlanToFindJob': value.isPlanToFindJob,
        'isKakeisijisya': value.isKakeisijisya,
        'isTaisyokuJikiForMusyokusya': value.isTaisyokuJikiForMusyokusya,
    };
}


