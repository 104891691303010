import {Container} from "components/layouts/Container";
import React from 'react';
import {FollowingBottomNavigation} from "../utilities/FollowingBottomNavigation";
import {Button} from "../../atoms/Button";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "../../../common/enums_text/CommonTextEnum";
import {SyoruiListComponent} from "./SyoruiListComponent";
import {SyoruiConfirmationVo} from "../../../common/models/SyoruiConfirmationModels";

interface Props {
    syoruiConfirmation?: SyoruiConfirmationVo
    handleClickBackButton: () =>  void
}

export const SyoruiConfirmation: React.FC<Props> = ({
                                                        syoruiConfirmation,
                                                        handleClickBackButton
                                                  }) => {

    return (

        <>

            <Container
                maxWidth={'880px'}
            >

                <SyoruiListComponent
                    syoruiConfirmation={syoruiConfirmation}
                />

            </Container>

            <FollowingBottomNavigation>

                <Button
                    className={"search_row_button"}
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.戻る)}
                    width={"104px"}
                    handleClick={handleClickBackButton}
                />

            </FollowingBottomNavigation>

        </>

    );
};


