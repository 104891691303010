import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "../../../../common/enums_text/yosiki/FamilyInfoTextEnum";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "../../../../common/enums_text/CommonTextEnum";
import React, {FC} from "react";
import {SyunyuInfoVo} from "../../../../common/models/YosikiInfoModels";
import {SyunyuInfoList} from "../../yosikiEdit/syunyuInfo/SyunyuInfoList";

interface Props {
    syunyuInfo: SyunyuInfoVo
    kakuteisinkokuAriNasi?: boolean
    totalAmounts: {
        syotoku: number
        syotokuIgai: number
    }
}

export const SyunyuContentsDetail: FC<Props> = ({
                                              syunyuInfo,
                                              kakuteisinkokuAriNasi,
                                              totalAmounts,
                                          }) => {
    return (
        <>
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.この続柄の方の令和5年分の確定申告の有無について入力してください)}
                </div>
                {
                    kakuteisinkokuAriNasi === true ? CommonTextEnumToNameJaAndEn(CommonTextEnum.あり) :
                        kakuteisinkokuAriNasi === false ? CommonTextEnumToNameJaAndEn(CommonTextEnum.なし) :
                            ""
                }
            </div>
            {/* NOTE: 金額一覧 */}
            <div className={"formTableRow_item"}>
                <SyunyuInfoList
                    syunyuInfo={syunyuInfo}
                    kakuteisinkokuAriNasi={kakuteisinkokuAriNasi}
                />
            </div>
            {/* NOTE: 金額合計 */}
            <div className={"formTableRow_item formTableRow_item-borederTop"}>
                <div>
                    <div>
                        <span>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.給与所得の収入金額税込合計)}</span><br/>{totalAmounts.syotoku.toLocaleString()}円
                    </div>
                    <div>
                        <span>{FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.給与所得以外の所得金額税込合計)}</span><br/>{totalAmounts.syotokuIgai.toLocaleString()}円
                    </div>
                </div>
            </div>

        </>
    )
}