import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {PostStudentResetPasswordRequest, StudentResetPasswordApi} from "openapi/apis";
import {SwStudentResetPasswordPutErrorMessage} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";
import {getParam} from "utilities/getParam";

export interface ResetPasswordStoreVo {
    form: ResetPasswordFormVo
    errorMessages?: SwStudentResetPasswordPutErrorMessage
}

export interface ResetPasswordFormVo {
    password: string
    passwordComfirm: string
}

class ResetPasswordStore extends Store<ResetPasswordStoreVo> {

    private api = new StudentResetPasswordApi(apiConfiguration);

    constructor() {
        super({
            form: {
                password: "",
                passwordComfirm: ""
            },
        })
    }

    updateForm(part: Partial<ResetPasswordFormVo>) {
        const newForm = {
            ...this.currentState.form,
            ...part
        };
        this.dispatch(() => ({form: newForm}));
    }

    updateErrorMessages(errorMessages: Partial<SwStudentResetPasswordPutErrorMessage> | undefined) {
        const newError: SwStudentResetPasswordPutErrorMessage | undefined = {
            ...this.currentState.errorMessages,
            ...errorMessages
        };
        this.dispatch(() => ({errorMessages: newError}));
    }

    async postSendResetPasswordLink() {
        const token = getParam("token") || "";
        const param: PostStudentResetPasswordRequest = {
            swStudentResetPasswordPutRequest: {
                token: token,
                password: this.currentState.form.password,
                passwordComfirm: this.currentState.form.passwordComfirm,
            }
        };
        try {
            const res = await this.api.postStudentResetPassword(param);
            if (!res.error) {
                window.location.assign(URL_PATHS_STUDENT.reset_password_complete);
                this.updateErrorMessages(undefined)
            } else {
                this.updateErrorMessages(res.error)
            }
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const resetPasswordStore = new ResetPasswordStore();
