/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwPostCsvErrorMessageError
 */
export interface SwPostCsvErrorMessageError {
    /**
     * 
     * @type {number}
     * @memberof SwPostCsvErrorMessageError
     */
    rowNo: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SwPostCsvErrorMessageError
     */
    errors: Array<string>;
}

export function SwPostCsvErrorMessageErrorFromJSON(json: any): SwPostCsvErrorMessageError {
    return SwPostCsvErrorMessageErrorFromJSONTyped(json, false);
}

export function SwPostCsvErrorMessageErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwPostCsvErrorMessageError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowNo': json['rowNo'],
        'errors': json['errors'],
    };
}

export function SwPostCsvErrorMessageErrorToJSON(value?: SwPostCsvErrorMessageError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowNo': value.rowNo,
        'errors': value.errors,
    };
}


