// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum SyunyuKubunCategoryEnum {
    給与所得 = "E給与所得",
    給与所得以外 = "E給与所得以外",
}

export function syunyuKubunCategoryEnumToName(e: SyunyuKubunCategoryEnum): { ja: string, en: string } {
    switch (e) {
        case SyunyuKubunCategoryEnum.給与所得: return { ja: "給与所得", en: "Wage Income" };
        case SyunyuKubunCategoryEnum.給与所得以外: return { ja: "給与所得以外", en: "Other than Wage Income" };
    }
}