/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwStudentCreateAccountNewStudentsPostRequest
 */
export interface SwStudentCreateAccountNewStudentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    jukenNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    nameKana: string;
    /**
     * 
     * @type {number}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    birthYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    birthMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    birthDay?: number;
    /**
     * 
     * @type {number}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    age?: number;
    /**
     * 
     * @type {string}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    tel: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    bukyokuCode: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    gakka: string;
    /**
     * 
     * @type {boolean}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    isRyugakusei?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    isEijusya?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentCreateAccountNewStudentsPostRequest
     */
    passwordComfirm: string;
}

export function SwStudentCreateAccountNewStudentsPostRequestFromJSON(json: any): SwStudentCreateAccountNewStudentsPostRequest {
    return SwStudentCreateAccountNewStudentsPostRequestFromJSONTyped(json, false);
}

export function SwStudentCreateAccountNewStudentsPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwStudentCreateAccountNewStudentsPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'jukenNumber': json['jukenNumber'],
        'name': json['name'],
        'nameKana': json['nameKana'],
        'birthYear': !exists(json, 'birthYear') ? undefined : json['birthYear'],
        'birthMonth': !exists(json, 'birthMonth') ? undefined : json['birthMonth'],
        'birthDay': !exists(json, 'birthDay') ? undefined : json['birthDay'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'tel': json['tel'],
        'bukyokuCode': json['bukyokuCode'],
        'gakka': json['gakka'],
        'isRyugakusei': !exists(json, 'isRyugakusei') ? undefined : json['isRyugakusei'],
        'isEijusya': !exists(json, 'isEijusya') ? undefined : json['isEijusya'],
        'password': json['password'],
        'passwordComfirm': json['passwordComfirm'],
    };
}

export function SwStudentCreateAccountNewStudentsPostRequestToJSON(value?: SwStudentCreateAccountNewStudentsPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'jukenNumber': value.jukenNumber,
        'name': value.name,
        'nameKana': value.nameKana,
        'birthYear': value.birthYear,
        'birthMonth': value.birthMonth,
        'birthDay': value.birthDay,
        'age': value.age,
        'tel': value.tel,
        'bukyokuCode': value.bukyokuCode,
        'gakka': value.gakka,
        'isRyugakusei': value.isRyugakusei,
        'isEijusya': value.isEijusya,
        'password': value.password,
        'passwordComfirm': value.passwordComfirm,
    };
}


