import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import { SyorikiModel } from "common/models/SyorikiModel";
import { getGlobalSyoriki } from "common/functions/globalSyoriki";

export enum FamilyInfoTextEnum {
    この続柄の方が就学者の場合 = "説明1",
    時点の情報を = "時点の情報を",
    氏名 = "氏名",
    年齢 = "年齢",
    現在の職業 = "現在の職業",
    在職期間 = "在職期間",
    就学者以外 = "就学者以外",
    就学者 = "就学者",
    収入または所得がありますか = "収入または所得がありますか？",
    この続柄の方の令和5年分の確定申告の有無について入力してください = "この続柄の方の令和5年分の確定申告の有無について入力してください",
    下の確定申告書を元に収入所得を入力から該当する項目についての金額を入力してください = "下の確定申告書を元に収入所得を入力から該当する項目についての金額を入力してください",
    下の源泉徴収票を元に収入所得を入力から該当する項目についての金額を入力してください = "下の源泉徴収票を元に収入所得を入力から該当する項目についての金額を入力してください",
    確定申告書又は源泉徴収票に記載されていない収入や所得については下のその他の収入または所得を入力から該当する金額を入力してください = "確定申告書又は源泉徴収票に記載されていない収入や所得については下のその他の収入または所得を入力から該当する金額を入力してください",
    確定申告書を元に収入所得を入力 = "確定申告書を元に収入所得を入力",
    源泉徴収票を元に収入所得を入力 = "源泉徴収票を元に収入所得を入力",
    その他の収入または所得を入力 = "その他の収入または所得を入力",
    区分 = "区分",
    金額 = "金額",
    名称 = "名称",
    所得時期 = "所得時期",
    給与所得の収入金額税込合計 = "給与所得の収入金額(税込)合計",
    給与所得以外の所得金額税込合計 = "給与所得以外の所得金額(税込)合計",
    通学区分 = "通学区分",
    在学学校 = "在学学校",
    授業料免除状況 = "授業料免除状況",
    半年分の授業料 = "半年分の授業料(免除適用後に実際に納付した金額)",
    学校名 = "学校名",
    学年 = "学年",
    設置区分 = "設置区分",
    自宅 = "自宅",
    自宅外 = "自宅外",
    続柄 = "続柄",
    独立生計者は家族構成に父母祖父祖母を選択できません続柄を削除で削除してください = "独立生計者は家族構成に父・母・祖父・祖母を選択できません。「続柄を削除」で削除してください。",
    母無の場合家族構成から母を削除してください父無の場合家族構成から父を削除してください = "「母無」の場合、家族構成から「母」を削除してください。「父無」の場合、家族構成から「父」を削除してください。"
}

export function FamilyInfoTextEnumToNameJaAndEn(e: FamilyInfoTextEnum): string {
    const s = getGlobalSyoriki();
    const y = s.yearModel
    switch (e) {
        case FamilyInfoTextEnum.この続柄の方が就学者の場合:
            return convertEnumNameToStr({
                ja: `${y.西暦年}年4月時点でこの続柄の方が就学者の場合「就学者」を選択してください。`,
                en: `If this person is a student as of April ${y.西暦年}, please select "Student".`
            });
        case FamilyInfoTextEnum.時点の情報を:
            return convertEnumNameToStr({
                ja: `${y.西暦年}年4月時点の情報を入力してください。`,
                en: `Please enter information as of April ${y.西暦年}.`
            });
        case FamilyInfoTextEnum.氏名:
            return convertEnumNameToStr({ja: "氏名", en: "Name"});
        case FamilyInfoTextEnum.年齢:
            return convertEnumNameToStr({ja: "年齢", en: "Age"});
        case FamilyInfoTextEnum.現在の職業:
            return convertEnumNameToStr({ja: "現在の職業", en: "Current Occupation"});
        case FamilyInfoTextEnum.在職期間:
            return convertEnumNameToStr({ja: "在職期間", en: "Length of Employment"});
        case FamilyInfoTextEnum.就学者以外:
            return convertEnumNameToStr({ja: "就学者以外", en: "Other than student"});
        case FamilyInfoTextEnum.就学者:
            return convertEnumNameToStr({ja: "就学者", en: "Student"});
        case FamilyInfoTextEnum.収入または所得がありますか:
            return convertEnumNameToStr({ja: "収入または所得がありますか？", en: "Do you have Revenue or income?"});
        case FamilyInfoTextEnum.この続柄の方の令和5年分の確定申告の有無について入力してください:
            return convertEnumNameToStr({
                ja: `この続柄の方の${y.dec().和暦年}分確定申告の有無について入力してください。不明な場合は必ず確認してから入力してください。 `,
                en: `Please enter whether or not this person filed a tax return for the ${y.dec().西暦年} tax year. If you are unsure, be sure to check before entering the information.`
            });
        case FamilyInfoTextEnum.下の確定申告書を元に収入所得を入力から該当する項目についての金額を入力してください:
            return convertEnumNameToStr({
                ja: "下の「確定申告書を元に収入・所得を入力」から該当する項目についての金額を入力してください。 ",
                en: "Enter the amount for the appropriate item from \"Enter revenue and income based on tax returns\" below."
            });
        case FamilyInfoTextEnum.下の源泉徴収票を元に収入所得を入力から該当する項目についての金額を入力してください:
            return convertEnumNameToStr({
                ja: "下の「源泉徴収票を元に収入・所得を入力」から該当する項目についての金額を入力してください。",
                en: "Enter the amount for the appropriate item from \"Enter revenue and income based on withholding tax certificates\" below."
            });
        case FamilyInfoTextEnum.確定申告書又は源泉徴収票に記載されていない収入や所得については下のその他の収入または所得を入力から該当する金額を入力してください:
            return convertEnumNameToStr({
                ja: "確定申告書又は源泉徴収票に記載されていない収入や所得については、下の「その他の収入または所得を入力」から該当する金額を入力してください。",
                en: "For revenue or income not shown on a tax return or withholding tax certificate, please enter the applicable amount from \"Enter other revenue or income\" below."
            });
        case FamilyInfoTextEnum.確定申告書を元に収入所得を入力:
            return convertEnumNameToStr({
                ja: "確定申告書を元に収入・所得を入力",
                en: "Enter revenue and income based on tax returns"
            });
        case FamilyInfoTextEnum.源泉徴収票を元に収入所得を入力:
            return convertEnumNameToStr({
                ja: "源泉徴収票を元に収入・所得を入力",
                en: "Enter income and income based on Withholding Tax."
            });
        case FamilyInfoTextEnum.その他の収入または所得を入力:
            return convertEnumNameToStr({
                ja: "その他の収入または所得を入力",
                en: "Enter other revenue or income"
            });
        case FamilyInfoTextEnum.区分:
            return convertEnumNameToStr({ja: "区分", en: "Classification"});
        case FamilyInfoTextEnum.金額:
            return convertEnumNameToStr({ja: "金額", en: "Amount"});
        case FamilyInfoTextEnum.名称:
            return convertEnumNameToStr({ja: "名称", en: "Title"});
        case FamilyInfoTextEnum.所得時期:
            return convertEnumNameToStr({ja: "所得時期", en: "Period of Income"});
        case FamilyInfoTextEnum.給与所得の収入金額税込合計:
            return convertEnumNameToStr({ja: "給与所得の収入金額(税込)合計", en: "Amount of Revenue from Wage Income"});
        case FamilyInfoTextEnum.給与所得以外の所得金額税込合計:
            return convertEnumNameToStr({ja: "給与所得以外の所得金額(税込)合計", en: "Amount of Income Other than Wage Income"});
        case FamilyInfoTextEnum.通学区分:
            return convertEnumNameToStr({ja: "通学区分", en: "School Commute Classification"});
        case FamilyInfoTextEnum.在学学校:
            return convertEnumNameToStr({ja: "在学学校", en: "School Attended"});
        case FamilyInfoTextEnum.授業料免除状況:
            return convertEnumNameToStr({ja: "授業料免除状況", en: "Tuition waiver status"});
        case FamilyInfoTextEnum.半年分の授業料:
            return convertEnumNameToStr({ja: "半年分の授業料(免除適用後に実際に納付した金額)", en: "Tuition for 6 months (the amount actually paid after the waiver is applied)"});
        case FamilyInfoTextEnum.学校名:
            return convertEnumNameToStr({ja: "学校名", en: "School Name"});
        case FamilyInfoTextEnum.学年:
            return convertEnumNameToStr({ja: "学年", en: "Academic Year"});
        case FamilyInfoTextEnum.設置区分:
            return convertEnumNameToStr({ja: "設置区分", en: "Classification of Establishment"});
        case FamilyInfoTextEnum.自宅:
            return convertEnumNameToStr({ja: "自宅", en: "From family home"});
        case FamilyInfoTextEnum.自宅外:
            return convertEnumNameToStr({ja: "自宅外", en: "Not from family home"});
        case FamilyInfoTextEnum.続柄:
            return convertEnumNameToStr({ja: "続柄", en: "Relationship"});
        case FamilyInfoTextEnum.独立生計者は家族構成に父母祖父祖母を選択できません続柄を削除で削除してください:
            return convertEnumNameToStr({
                ja: "独立生計者は家族構成に父・母・祖父・祖母を選択できません。「家族構成」に表示されている「父」・「母」は「続柄を削除」で削除してください。",
                en: `Individuals in Independent Households cannot select "Father", "Mother", "Grandfather" or "Grandmother" for "Family Members". Please delete "Father" and "Mother" in the "Family Members" section by clicking "Delete Relationship".`
            });
        case FamilyInfoTextEnum.母無の場合家族構成から母を削除してください父無の場合家族構成から父を削除してください:
            return convertEnumNameToStr({
                ja: "「母無」の場合、「家族構成」から「母」を「続柄を削除」で削除してください。「父無」の場合、「家族構成」から「父」を「続柄を削除」で削除してください。",
                en: `If you selected "Mother is not present", please delete "Mother" from "Family Members" by clicking "Delete Relationship". If you selected "Father is not present", please delete "Father" from "Family Members" by clicking on "Delete Relationship".`
            });

        default :
            return ""
    }
}
