/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwCommonSyoruisSyorui,
    SwCommonSyoruisSyoruiFromJSON,
    SwCommonSyoruisSyoruiFromJSONTyped,
    SwCommonSyoruisSyoruiToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwCommonSyoruisResponse
 */
export interface SwCommonSyoruisResponse {
    /**
     * 
     * @type {Array<SwCommonSyoruisSyorui>}
     * @memberof SwCommonSyoruisResponse
     */
    syoruis: Array<SwCommonSyoruisSyorui>;
}

export function SwCommonSyoruisResponseFromJSON(json: any): SwCommonSyoruisResponse {
    return SwCommonSyoruisResponseFromJSONTyped(json, false);
}

export function SwCommonSyoruisResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwCommonSyoruisResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syoruis': ((json['syoruis'] as Array<any>).map(SwCommonSyoruisSyoruiFromJSON)),
    };
}

export function SwCommonSyoruisResponseToJSON(value?: SwCommonSyoruisResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'syoruis': ((value.syoruis as Array<any>).map(SwCommonSyoruisSyoruiToJSON)),
    };
}


