import {SyunyuInfoVo} from "../../../../common/models/YosikiInfoModels";
import React, {FC} from "react";
import {Box} from "@material-ui/core";
import {toStrJaEn} from "../../../../common/functions/converter/toStrJaEn";
import {SyunyuKubunEnum, syunyuKubunEnumToName} from "../../../../common/enums/SyunyuKubunEnum";
import {BorderColorsEnum} from "../../../../common/enums_value/ColorsEnum";
import {calcRisiHaito} from "../../../../common/functions/yosikiInfo/calcRisiHaito";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {convertStrToNumOrUndefined} from "../../../../common/functions/type_converter/convertStrToNumOrUndefined";

interface SyunyuInfoListItemProps {
    amount: string
    matches: boolean
    label: string
    subLabel?: string
    fieldNo?: number
    syotokuYear?: string
    syotokuMonth?: string
    labelWidth: string
    amountWidth: string
    syotokuJikiWidth: string
}

const SyunyuInfoListItem: FC<SyunyuInfoListItemProps> = ({
                                                             amount,
                                                             matches,
                                                             label,
                                                             subLabel,
                                                             fieldNo,
                                                             syotokuYear,
                                                             syotokuMonth,
                                                             labelWidth,
                                                             amountWidth,
                                                             syotokuJikiWidth
                                                         }) => {

    //  NOTE: 0円と入力がない場合は一覧に表示しない
    if (amount === "0" || amount === "") {
        return <></>
    }

    // NOTE: 所得時期の表示判定
    const isDiplaySyotokuJiki = !!syotokuYear || !!syotokuMonth

    return (
        <Box display={matches ? "block" : "flex"} mt={1} pt={1} borderTop={`1px solid ${BorderColorsEnum.LightGray}`}>
            <Box
                minWidth={labelWidth}
                width={labelWidth}
            >
                <Box fontWeight={700}>{label}{fieldNo !== undefined && ` ${fieldNo}`}</Box>
                {subLabel && <Box>{subLabel}</Box>}
            </Box>
            <Box display={"flex"} style={{gap: "8px"}}>
                <Box
                    minWidth={amountWidth}
                    width={amountWidth}
                    textAlign={"right"}
                >{convertStrToNumOrUndefined(amount)?.toLocaleString()}円</Box>
                <Box
                    minWidth={syotokuJikiWidth}
                    width={syotokuJikiWidth}
                    textAlign={"right"}
                >{
                    isDiplaySyotokuJiki && <>
                        {!!syotokuYear && `${syotokuYear}年`}
                        {!!syotokuMonth && `${syotokuMonth}月`}
                        所得
                    </>
                }</Box>
            </Box>
        </Box>
    )
}


interface Props {
    syunyuInfo: SyunyuInfoVo
    kakuteisinkokuAriNasi?: boolean
}

export const SyunyuInfoList: FC<Props> = ({syunyuInfo, kakuteisinkokuAriNasi}) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const labelWidth = matches ? "calc(100% - 120px)" : "376px"
    const amountWidth = matches ? "auto" : "120px"
    const syotokuJikiWidth = matches ? "auto" : "96px"

    return (
        <>
            <Box display={"flex"} fontWeight={700} mt={2}>
                <Box minWidth={labelWidth}
                     width={labelWidth}>区分 / Classificatio</Box>
                <Box
                    minWidth={amountWidth}
                    width={amountWidth}
                    textAlign={"right"}
                >金額 / Amount</Box>
            </Box>
            <SyunyuInfoListItem
                label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.給料賃金))}
                amount={syunyuInfo.kyuyo.field.amount}
                matches={matches}
                labelWidth={labelWidth}
                amountWidth={amountWidth}
                syotokuJikiWidth={syotokuJikiWidth}
            />
            {
                // NOTE: 確定申告だけの項目
                kakuteisinkokuAriNasi &&
                <>
                    <SyunyuInfoListItem
                        label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.営業))}
                        amount={syunyuInfo.eigyo.field.amount}
                        matches={matches}
                        labelWidth={labelWidth}
                        amountWidth={amountWidth}
                        syotokuJikiWidth={syotokuJikiWidth}
                    />
                    <SyunyuInfoListItem
                        label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.農林水産業))}
                        amount={syunyuInfo.norin.field.amount}
                        matches={matches}
                        labelWidth={labelWidth}
                        amountWidth={amountWidth}
                        syotokuJikiWidth={syotokuJikiWidth}
                    />
                    <SyunyuInfoListItem
                        label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.不動産))}
                        amount={syunyuInfo.hudosan.field.amount}
                        matches={matches}
                        labelWidth={labelWidth}
                        amountWidth={amountWidth}
                        syotokuJikiWidth={syotokuJikiWidth}
                    />
                    <SyunyuInfoListItem
                        label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.利子配当))}
                        amount={calcRisiHaito(syunyuInfo.risi.field.amount, syunyuInfo.haito.field.amount).toString()}
                        matches={matches}
                        labelWidth={labelWidth}
                        amountWidth={amountWidth}
                        syotokuJikiWidth={syotokuJikiWidth}
                    />

                </>
            }

            {/* NOTE: 以下その他項目 */}
            {
                syunyuInfo.nenkin.fields.map((f, i) => {
                    return (
                        <SyunyuInfoListItem
                            key={i}
                            fieldNo={i + 1}
                            label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.年金恩給))}
                            amount={f.amount}
                            matches={matches}
                            labelWidth={labelWidth}
                            amountWidth={amountWidth}
                            syotokuJikiWidth={syotokuJikiWidth}
                        />
                    )
                })
            }

            {
                syunyuInfo.koyohoken.fields.map((f, i) => {
                    return (
                        <SyunyuInfoListItem
                            key={i}
                            fieldNo={i + 1}
                            label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.雇用保険))}
                            amount={f.amount}
                            matches={matches}
                            labelWidth={labelWidth}
                            amountWidth={amountWidth}
                            syotokuJikiWidth={syotokuJikiWidth}
                        />
                    )
                })
            }
            {
                syunyuInfo.kakusyuteate.fields.map((f, i) => {
                    return (
                        <SyunyuInfoListItem
                            key={i}
                            fieldNo={i + 1}
                            label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.各種手当))}
                            amount={f.amount}
                            matches={matches}
                            labelWidth={labelWidth}
                            amountWidth={amountWidth}
                            syotokuJikiWidth={syotokuJikiWidth}
                        />
                    )
                })
            }

            {
                syunyuInfo.syogakukin.fields.map((f, i) => {
                    return (
                        <SyunyuInfoListItem
                            key={i}
                            fieldNo={i + 1}
                            label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.奨学金))}
                            amount={f.amount}
                            matches={matches}
                            labelWidth={labelWidth}
                            amountWidth={amountWidth}
                            syotokuJikiWidth={syotokuJikiWidth}
                        />
                    )
                })
            }

            {
                syunyuInfo.sinsekitounosien.fields.map((f, i) => {
                    return (
                        <SyunyuInfoListItem
                            key={i}
                            fieldNo={i + 1}
                            label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.親戚等の援助))}
                            amount={f.amount}
                            matches={matches}
                            labelWidth={labelWidth}
                            amountWidth={amountWidth}
                            syotokuJikiWidth={syotokuJikiWidth}
                        />
                    )
                })
            }
            {
                syunyuInfo.yotyokin.fields.map((f, i) => {
                    return (
                        <SyunyuInfoListItem
                            key={i}
                            fieldNo={i + 1}
                            label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.預貯金から))}
                            amount={f.amount}
                            matches={matches}
                            labelWidth={labelWidth}
                            amountWidth={amountWidth}
                            syotokuJikiWidth={syotokuJikiWidth}
                        />
                    )
                })
            }
            {
                syunyuInfo.other.fields.map((f, i) => {
                    return (
                        <SyunyuInfoListItem
                            key={i}
                            fieldNo={i + 1}
                            label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.その他))}
                            subLabel={f.kubunOther}
                            amount={f.amount}
                            matches={matches}
                            labelWidth={labelWidth}
                            amountWidth={amountWidth}
                            syotokuJikiWidth={syotokuJikiWidth}
                        />
                    )
                })
            }
            {
                syunyuInfo.taisyokukinn.fields.map((f, i) => {
                    return (
                        <SyunyuInfoListItem
                            key={i}
                            fieldNo={i + 1}
                            label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.退職金))}
                            subLabel={f.kubunOther}
                            amount={f.amount}
                            syotokuYear={f.syotokuYear}
                            syotokuMonth={f.syotokuMonth}
                            matches={matches}
                            labelWidth={labelWidth}
                            amountWidth={amountWidth}
                            syotokuJikiWidth={syotokuJikiWidth}
                        />
                    )
                })
            }
            {
                syunyuInfo.hokenkin.fields.map((f, i) => {
                    return (
                        <SyunyuInfoListItem
                            key={i}
                            fieldNo={i + 1}
                            label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.保険金))}
                            subLabel={f.kubunOther}
                            amount={f.amount}
                            syotokuYear={f.syotokuYear}
                            syotokuMonth={f.syotokuMonth}
                            matches={matches}
                            labelWidth={labelWidth}
                            amountWidth={amountWidth}
                            syotokuJikiWidth={syotokuJikiWidth}
                        />
                    )
                })
            }
        </>
    )
}

