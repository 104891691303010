import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {YosikiKakuteiModalType} from "common/models/yosiki/YosikiInfoModalStoreVo";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {Button} from "components/atoms/Button";
import {Modal} from "components/atoms/Modal";
import {SwErrorMessage} from "openapi/models";
import React from 'react';
import {TextColorsEnum} from "common/enums_value/ColorsEnum";

interface YosikiDetailKakuteiErrorModalProps {
    openState?: YosikiKakuteiModalType
    handleClickClose: () => void
    errorMessage?: Array<SwErrorMessage>
}

export const _YosikiDetailKakuteiErrorModal: React.FC<YosikiDetailKakuteiErrorModalProps> = (props) => {

    const classes = useStyles();

    if (props.openState !== YosikiKakuteiModalType.kakuteiErrorModal) return <div/>;

    let errorTextJa: Array<string> = [];
    let errorTextEn: Array<string> = [];
    props.errorMessage?.forEach(e => {
        errorTextJa.push(e.ja);
        errorTextEn.push(e.en)
    });

    return (

        <Modal
            openState={props.openState === YosikiKakuteiModalType.kakuteiErrorModal}
            title={"様式1を確定できませんでした。 / Can not be confirmed"}
        >

            <div className={'mb16'}>
                <div className={'mb8'}>
                    <div className={classes.warnigText}>様式1を確定できませんでした。</div>
                    {errorTextJa.map((e, i) => {
                        return <div key={i}>{e}</div>
                    })}
                </div>
                <div>
                    <div className={classes.warnigText}>Cannot be confirmed.</div>
                    {errorTextEn.map((e, i) => {
                        return <div key={i}>{e}</div>
                    })}
                </div>
            </div>

            <DialogActions className={"modal_actions"}>
                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.閉じる)}
                    width={"120px"}
                    handleClick={props.handleClickClose}
                />
            </DialogActions>

        </Modal>

    );
};

const useStyles = makeStyles({
    warnigText: {
        color: TextColorsEnum.Red,
        fontWeight: 700
    },
});

export const YosikiKakuteiErrorModal = React.memo<YosikiDetailKakuteiErrorModalProps>(_YosikiDetailKakuteiErrorModal, (prev, next) => {
    return prev.openState === next.openState
        && prev.handleClickClose === next.handleClickClose
        && prev.errorMessage === next.errorMessage
});

