/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwBekkyo,
    SwBekkyoFromJSON,
    SwBekkyoFromJSONTyped,
    SwBekkyoToJSON,
    SwBosiHusi,
    SwBosiHusiFromJSON,
    SwBosiHusiFromJSONTyped,
    SwBosiHusiToJSON,
    SwSaigaihigai,
    SwSaigaihigaiFromJSON,
    SwSaigaihigaiFromJSONTyped,
    SwSaigaihigaiToJSON,
    SwSyogaisya,
    SwSyogaisyaFromJSON,
    SwSyogaisyaFromJSONTyped,
    SwSyogaisyaToJSON,
    SwTyokiryoyo,
    SwTyokiryoyoFromJSON,
    SwTyokiryoyoFromJSONTyped,
    SwTyokiryoyoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwTokubetuKojoInfo
 */
export interface SwTokubetuKojoInfo {
    /**
     * 
     * @type {SwBosiHusi}
     * @memberof SwTokubetuKojoInfo
     */
    bosiHusi: SwBosiHusi;
    /**
     * 
     * @type {Array<SwSyogaisya>}
     * @memberof SwTokubetuKojoInfo
     */
    syogaisya: Array<SwSyogaisya>;
    /**
     * 
     * @type {Array<SwTyokiryoyo>}
     * @memberof SwTokubetuKojoInfo
     */
    tyokiryoyo: Array<SwTyokiryoyo>;
    /**
     * 
     * @type {SwBekkyo}
     * @memberof SwTokubetuKojoInfo
     */
    bekkyo: SwBekkyo;
    /**
     * 
     * @type {SwSaigaihigai}
     * @memberof SwTokubetuKojoInfo
     */
    saigaihigai: SwSaigaihigai;
}

export function SwTokubetuKojoInfoFromJSON(json: any): SwTokubetuKojoInfo {
    return SwTokubetuKojoInfoFromJSONTyped(json, false);
}

export function SwTokubetuKojoInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwTokubetuKojoInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bosiHusi': SwBosiHusiFromJSON(json['bosiHusi']),
        'syogaisya': ((json['syogaisya'] as Array<any>).map(SwSyogaisyaFromJSON)),
        'tyokiryoyo': ((json['tyokiryoyo'] as Array<any>).map(SwTyokiryoyoFromJSON)),
        'bekkyo': SwBekkyoFromJSON(json['bekkyo']),
        'saigaihigai': SwSaigaihigaiFromJSON(json['saigaihigai']),
    };
}

export function SwTokubetuKojoInfoToJSON(value?: SwTokubetuKojoInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bosiHusi': SwBosiHusiToJSON(value.bosiHusi),
        'syogaisya': ((value.syogaisya as Array<any>).map(SwSyogaisyaToJSON)),
        'tyokiryoyo': ((value.tyokiryoyo as Array<any>).map(SwTyokiryoyoToJSON)),
        'bekkyo': SwBekkyoToJSON(value.bekkyo),
        'saigaihigai': SwSaigaihigaiToJSON(value.saigaihigai),
    };
}


