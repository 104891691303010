/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20015,
    InlineResponse20015FromJSON,
    InlineResponse20015ToJSON,
    SwSyoruiConfirmationFormRequest,
    SwSyoruiConfirmationFormRequestFromJSON,
    SwSyoruiConfirmationFormRequestToJSON,
    SwSyoruiConfirmationFormResponse,
    SwSyoruiConfirmationFormResponseFromJSON,
    SwSyoruiConfirmationFormResponseToJSON,
} from '../models';

export interface PostStudentSyoruiConfirmationFormRequest {
    swSyoruiConfirmationFormRequest: SwSyoruiConfirmationFormRequest;
}

/**
 * no description
 */
export class StudentSyoruiConfirmationFormApi extends runtime.BaseAPI {

    /**
     * 必要書類の確認機能のフォームデータ
     */
    async getStudentSyoruiConfirmationFormRaw(): Promise<runtime.ApiResponse<SwSyoruiConfirmationFormResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/syorui_confirmation_form`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwSyoruiConfirmationFormResponseFromJSON(jsonValue));
    }

    /**
     * 必要書類の確認機能のフォームデータ
     */
    async getStudentSyoruiConfirmationForm(): Promise<SwSyoruiConfirmationFormResponse> {
        const response = await this.getStudentSyoruiConfirmationFormRaw();
        return await response.value();
    }

    /**
     * 必要書類の確認機能のフォームデータの保存 
     */
    async postStudentSyoruiConfirmationFormRaw(requestParameters: PostStudentSyoruiConfirmationFormRequest): Promise<runtime.ApiResponse<InlineResponse20015>> {
        if (requestParameters.swSyoruiConfirmationFormRequest === null || requestParameters.swSyoruiConfirmationFormRequest === undefined) {
            throw new runtime.RequiredError('swSyoruiConfirmationFormRequest','Required parameter requestParameters.swSyoruiConfirmationFormRequest was null or undefined when calling postStudentSyoruiConfirmationForm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/student/syorui_confirmation_form`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwSyoruiConfirmationFormRequestToJSON(requestParameters.swSyoruiConfirmationFormRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20015FromJSON(jsonValue));
    }

    /**
     * 必要書類の確認機能のフォームデータの保存 
     */
    async postStudentSyoruiConfirmationForm(requestParameters: PostStudentSyoruiConfirmationFormRequest): Promise<InlineResponse20015> {
        const response = await this.postStudentSyoruiConfirmationFormRaw(requestParameters);
        return await response.value();
    }

}
