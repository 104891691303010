import React from 'react';
import {Box} from "@material-ui/core";
import {SwAYosikiFormErrorMessageResponse} from "../../../openapi";
import {AYosikiFormVo} from "../../../common/models/AYosikiFormModels";
import Grid from "@material-ui/core/Grid";
import {Title02} from "../../atoms/titles/Title02";
import {AYosikiCommonForm} from "./forms/AYosikiCommonForm";
import {AYosiki1Form} from "./forms/AYosiki1Form";
import {AYosiki2Form} from "./forms/AYosiki2Form";

interface Props {
    formValues: AYosikiFormVo | undefined
    errorMessages: SwAYosikiFormErrorMessageResponse | undefined
    updates: (formValues: AYosikiFormVo, errorMessages?: SwAYosikiFormErrorMessageResponse | undefined) => void
    isKakutei: boolean
}

export const FormComponent: React.FC<Props> = ({
                                                   formValues,
                                                   errorMessages,
                                                   updates,
                                                   isKakutei
                                               }) => {

    if (formValues === undefined) return <></>

    return (

        <Box>

            <Title02
                title={"A様式1またはA様式2入力画面 / Form A1 or Form A2 Input Screen"}
            />

            <Box mt={3}>
                申請に必要な様式等を提示することができます。※提示内容は参考ですので、提示した以外の資料等が必要となる場合があります。<br/>
                すべての項目を入力し、「判定」を行うことで必要証明書類の一覧を確認することができます。<br/>
                The forms, etc. requiring application can be presented. *The information provided is for reference
                only, and other materials may be necessary.<br/>
                After entering all items, you can confirm the list of required certification documents by clicking
                "Determine".
            </Box>

            <Box mt={2}>

                <Grid container spacing={6} direction={"column"}>

                    <Grid item xs>
                        <AYosikiCommonForm
                            formValues={formValues}
                            errorMessages={errorMessages}
                            updates={updates}
                            isKakutei={isKakutei}
                        />
                    </Grid>

                    {
                        formValues.common.nihonGakuseiSienKiko.isReceivedAlready === false && (
                            <Grid item xs>
                                <AYosiki1Form
                                    formValues={formValues}
                                    errorMessages={errorMessages}
                                    updates={updates}
                                    isKakutei={isKakutei}
                                />
                            </Grid>
                        )
                    }

                    {
                        formValues.common.nihonGakuseiSienKiko.isReceivedAlready === true && (
                            <Grid item xs>
                                <AYosiki2Form
                                    formValues={formValues}
                                    errorMessages={errorMessages}
                                    updates={updates}
                                    isKakutei={isKakutei}
                                />
                            </Grid>
                        )
                    }

                </Grid>

            </Box>

        </Box>

    );
};

