/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSyunyuKubunMaster,
    SwSyunyuKubunMasterFromJSON,
    SwSyunyuKubunMasterFromJSONTyped,
    SwSyunyuKubunMasterToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwYosikiMasters
 */
export interface SwYosikiMasters {
    /**
     * 
     * @type {Array<SwSyunyuKubunMaster>}
     * @memberof SwYosikiMasters
     */
    syunyuKubun: Array<SwSyunyuKubunMaster>;
}

export function SwYosikiMastersFromJSON(json: any): SwYosikiMasters {
    return SwYosikiMastersFromJSONTyped(json, false);
}

export function SwYosikiMastersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwYosikiMasters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syunyuKubun': ((json['syunyuKubun'] as Array<any>).map(SwSyunyuKubunMasterFromJSON)),
    };
}

export function SwYosikiMastersToJSON(value?: SwYosikiMasters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'syunyuKubun': ((value.syunyuKubun as Array<any>).map(SwSyunyuKubunMasterToJSON)),
    };
}


