import DialogActions from "@material-ui/core/DialogActions";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {Button} from "components/atoms/Button";
import {Modal} from "components/atoms/Modal";
import React from 'react';
import {BgColorsEnum} from "common/enums_value/ColorsEnum";
import {SimulationTextEnum, SimulationTextEnumToName} from "../../../common/enums_text/SimulationTextEnum";
import {Box} from "@material-ui/core";
import {SimulationVo} from "../../../common/models/yosiki/StudentInfoStoreVo";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import {
    JugyoryoMenjoHanteiResultEnum,
    jugyoryoMenjoHanteiResultEnumToName
} from "../../../common/enums/JugyoryoMenjoHanteiResultEnum";
import {isProd} from "../../../utilities/isProd";

interface SimulationModalProps {
    openState: boolean
    simulation?: SimulationVo
    handleClickSimulationModalClose: () => void
}

export const SimulationModal: React.FC<SimulationModalProps> = (props) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const simulation = props.simulation;
    if (simulation === undefined) return <></>;

    let text = jugyoryoMenjoHanteiResultEnumToName(simulation.result as JugyoryoMenjoHanteiResultEnum)

    return (

        <Modal
            openState={props.openState}
            title={convertEnumNameToStr(SimulationTextEnumToName(SimulationTextEnum.免除結果シミュレーション))}
        >

            <Box bgcolor={BgColorsEnum.OffWhite} textAlign={"center"} py={matches ? 2 : 4}>
                <Box fontSize={matches ? 13 : 20} fontWeight={700}>
                    {text.ja}
                </Box>
                <Box fontSize={matches ? 13 : 20} fontWeight={700} mt={1}>
                    {text.en}
                </Box>
            </Box>

            <Box mt={1}>
                <Box>
                    シミュレーション結果は、入力内容を元に簡易的に判定したものです。<br/>
                    申請者数、申請内容、学力基準、予算等により実際の免除結果とは異なる場合があります。<br/>
                    入学料免除についてはシミュレーションの対象外です。
                </Box>
                <Box>
                    The simulation results are a simplified judgment based on the input information.<br/>
                    Actual waiver results may differ depending on the number of applicants, application contents,
                    academic standards, budget, etc.<br/>
                    Enrollment fee waiver is not a simulation target.
                </Box>
            </Box>

            {isProd() ? <></> : (
                <Box mt={1}>
                    <Box>シミュレーション機能の計算結果(本番環境では非表示対応)</Box>
                    <Box>a(世帯の総所得金額) = {simulation.detail?.a}</Box>
                    <Box>b(世帯の特別控除額) = {simulation.detail?.b}</Box>
                    <Box>c1(収入基準額(全額)) = {simulation.detail?.c1}</Box>
                    <Box>c2(収入基準額(半額)) = {simulation.detail?.c2}</Box>

                    <Box mt={2}>
                        <Box fontWeight={700}>半額免除の計算結果</Box>
                        <Box>a - b - c1
                            = {(simulation.detail?.a || 0) - (simulation.detail?.b || 0) - (simulation.detail?.c1 || 0)}</Box>
                    </Box>

                    <Box mt={2}>
                        <Box fontWeight={700}>全額免除の計算結果</Box>
                        <Box>a - b - c1
                            = {(simulation.detail?.a || 0) - (simulation.detail?.b || 0) - (simulation.detail?.c2 || 0)}</Box>
                    </Box>

                </Box>
            )}

    <DialogActions className={"modal_actions"}>

        <Button
            type={"default"}
            label={"閉じる"}
            width={"120px"}
            handleClick={props.handleClickSimulationModalClose}
        />

    </DialogActions>

</Modal>

)
    ;
};

