/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    SwOfficerStudents,
    SwOfficerStudentsFromJSON,
    SwOfficerStudentsToJSON,
} from '../models';

export interface GetOfficerStudentsRequest {
    page: number;
    perPage: number;
    numberType?: string;
    studentNumber?: string;
    name?: string;
    nameKana?: string;
    age?: string;
    gakubu?: string;
    gakka?: string;
    studentKubun?: string;
    ryugakuseiKubun?: string;
    nyugakuOn?: string;
    email?: string;
}

export interface PostOfficerStudentsRequest {
    csvfile?: Blob;
    force?: boolean;
}

/**
 * no description
 */
export class OfficerStudentsApi extends runtime.BaseAPI {

    /**
     * 学籍一覧
     */
    async getOfficerStudentsRaw(requestParameters: GetOfficerStudentsRequest): Promise<runtime.ApiResponse<SwOfficerStudents>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getOfficerStudents.');
        }

        if (requestParameters.perPage === null || requestParameters.perPage === undefined) {
            throw new runtime.RequiredError('perPage','Required parameter requestParameters.perPage was null or undefined when calling getOfficerStudents.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.numberType !== undefined) {
            queryParameters['numberType'] = requestParameters.numberType;
        }

        if (requestParameters.studentNumber !== undefined) {
            queryParameters['studentNumber'] = requestParameters.studentNumber;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.nameKana !== undefined) {
            queryParameters['nameKana'] = requestParameters.nameKana;
        }

        if (requestParameters.age !== undefined) {
            queryParameters['age'] = requestParameters.age;
        }

        if (requestParameters.gakubu !== undefined) {
            queryParameters['gakubu'] = requestParameters.gakubu;
        }

        if (requestParameters.gakka !== undefined) {
            queryParameters['gakka'] = requestParameters.gakka;
        }

        if (requestParameters.studentKubun !== undefined) {
            queryParameters['studentKubun'] = requestParameters.studentKubun;
        }

        if (requestParameters.ryugakuseiKubun !== undefined) {
            queryParameters['ryugakuseiKubun'] = requestParameters.ryugakuseiKubun;
        }

        if (requestParameters.nyugakuOn !== undefined) {
            queryParameters['nyugakuOn'] = requestParameters.nyugakuOn;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/students`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOfficerStudentsFromJSON(jsonValue));
    }

    /**
     * 学籍一覧
     */
    async getOfficerStudents(requestParameters: GetOfficerStudentsRequest): Promise<SwOfficerStudents> {
        const response = await this.getOfficerStudentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * 学生の情報をアップロード
     */
    async postOfficerStudentsRaw(requestParameters: PostOfficerStudentsRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.csvfile !== undefined) {
            formParams.append('csvfile', requestParameters.csvfile as any);
        }

        if (requestParameters.force !== undefined) {
            formParams.append('force', requestParameters.force as any);
        }

        const response = await this.request({
            path: `/api/officer/students`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * 学生の情報をアップロード
     */
    async postOfficerStudents(requestParameters: PostOfficerStudentsRequest): Promise<InlineResponse2001> {
        const response = await this.postOfficerStudentsRaw(requestParameters);
        return await response.value();
    }

}
