/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20021,
    InlineResponse20021FromJSON,
    InlineResponse20021ToJSON,
    SwStudentCreateAccountPostRequest,
    SwStudentCreateAccountPostRequestFromJSON,
    SwStudentCreateAccountPostRequestToJSON,
} from '../models';

export interface PostStudentCreateAccountRequest {
    swStudentCreateAccountPostRequest: SwStudentCreateAccountPostRequest;
}

/**
 * no description
 */
export class StudentCreateAccountApi extends runtime.BaseAPI {

    /**
     * 学生のアカウント作成 招待メールにはトークン付きのリンクがあり、 そのトークンを取得して本APIの request json に含める。 トークンは不正なものでないか、期限が切れていないか、 既に利用されたかどうかが確認される。 
     */
    async postStudentCreateAccountRaw(requestParameters: PostStudentCreateAccountRequest): Promise<runtime.ApiResponse<InlineResponse20021>> {
        if (requestParameters.swStudentCreateAccountPostRequest === null || requestParameters.swStudentCreateAccountPostRequest === undefined) {
            throw new runtime.RequiredError('swStudentCreateAccountPostRequest','Required parameter requestParameters.swStudentCreateAccountPostRequest was null or undefined when calling postStudentCreateAccount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/student/create_account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwStudentCreateAccountPostRequestToJSON(requestParameters.swStudentCreateAccountPostRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20021FromJSON(jsonValue));
    }

    /**
     * 学生のアカウント作成 招待メールにはトークン付きのリンクがあり、 そのトークンを取得して本APIの request json に含める。 トークンは不正なものでないか、期限が切れていないか、 既に利用されたかどうかが確認される。 
     */
    async postStudentCreateAccount(requestParameters: PostStudentCreateAccountRequest): Promise<InlineResponse20021> {
        const response = await this.postStudentCreateAccountRaw(requestParameters);
        return await response.value();
    }

}
