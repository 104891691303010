import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {announceCommonStore, AnnounceCommonStoreVo} from "stores/common/AnnounceCommonStore";
import {Announce} from "components/organisms/utilities/Announce";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import React, {useCallback, useState} from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import {accountInfoStore} from "../../../stores/student/AccountInfoStore";
import {URL_PATHS_STUDENT} from "../../../common/constant/URL_PATHS_STUDENT";
import {scrollToError} from "../../../common/functions/scrollToError";
import {studentAYosikiFormStore} from "./stores/StudentAYosikiFormStore";
import {AYosikiForm} from "../../../components/molecules/summaryAyosikiForm/AYosikiForm";
import {AYosikiFormStoreVo, AYosikiFormVo} from "../../../common/models/AYosikiFormModels";
import {AYosikiKakuteiModal} from "../../../components/molecules/summaryAyosikiForm/AYosikiKakuteiModal";
import {AYosikiKakuteiKaijoModal} from "../../../components/molecules/summaryAyosikiForm/AYosikiKakuteiKaijoModal";
import {AYosikiKakuteiErrorModal} from "../../../components/molecules/summaryAyosikiForm/AYosikiKakuteiErrorModal";

const _StudentAYosikiFormPage: React.FC<RouteComponentProps<{ studentNumber: string }>> = () => {

    // NOTE: storeデータ
    const [
        aYosikiFormStoreState,
        updateAYosikiFormStoreState
    ] = React.useState<AYosikiFormStoreVo>(studentAYosikiFormStore.currentState);

    // NOTE: storeデータ
    const [
        announceCommon,
        updateAnnounceCommon
    ] = React.useState<AnnounceCommonStoreVo>(announceCommonStore.currentState);

    // NOTE: storeデータ
    const [
        studentInfo,
        updateStudentInfo
    ] = React.useState<StudentInfoStoreVo>(accountInfoStore.currentState);

    // NOTE: 確定モーダル state
    const [kakuteiModalOpenState, updateKakuteiModalOpenState] = useState(false)

    // NOTE: 確定解除モーダル state
    const [kakuteiKaijoModalOpenState, updateKakuteiKaijoModalOpenState] = useState(false)

    // NOTE: 確定エラーモーダル state
    const [kakuteiErrorModalOpenState, updateKakuteiErrorModalOpenState] = useState(false)


    React.useEffect(() => {
        // NOTE: 初回取得
        studentAYosikiFormStore.getAYosikiForm().then()
        // NOTE: 取得後にstateを更新
        return studentAYosikiFormStore.listen(updateAYosikiFormStoreState);
    }, []);

    React.useEffect(() => {
        return announceCommonStore.listen(updateAnnounceCommon);
    }, []);

    React.useEffect(() => {
        accountInfoStore.getStudentInfo().then();
        return accountInfoStore.listen(updateStudentInfo);
    }, []);

    // NOTE: 戻る
    const handleClickBackButton = useCallback(() => {
        window.location.assign(`${URL_PATHS_STUDENT.top}`);
    }, [])

    // NOTE: 保存
    const handleClickSave = useCallback(async (formValues?: AYosikiFormVo) => {
        if (formValues === undefined) return
        // NOTE: storeのデータ更新
        await studentAYosikiFormStore.updateAYosikiForm(formValues)
        // NOTE: 保存処理
        await studentAYosikiFormStore.postAYosikiForm().then(() => {
            announceCommonStore.updateAnnounceCommon(true, "保存しました。")
            scrollToError()
        })
    }, [])

    const handleClickKakutei = useCallback(async () => {
        // NOTE: 確定モーダル true
        updateKakuteiModalOpenState(true)
    }, [])

    const handleClickKakuteiKaijo = useCallback(async () => {
        // NOTE: 確定解除モーダル true
        updateKakuteiKaijoModalOpenState(true)
    }, [])

    // NOTE: 確定モーダル 確定
    const handleClickKakuteiModalKakutei = useCallback(async () => {
        // NOTE: 保存処理
        await studentAYosikiFormStore.postAYosikiForm().then((res) => {
            if (res && res.error) {
                // NOTE: エラーがあればエラーモーダル表示
                updateKakuteiModalOpenState(false)
                updateKakuteiErrorModalOpenState(true)
            } else {
                // NOTE: エラーがなければ確定処理
                studentAYosikiFormStore.postAYosikiFormKakutei().then(async () => {
                    await accountInfoStore.getStudentInfo().then();
                    updateKakuteiModalOpenState(false)
                    announceCommonStore.updateAnnounceCommon(true, "確定しました。/ Determined.")
                })
            }
        })
    }, [])

    // NOTE: 確定解除モーダル 閉じる
    const handleClickKakuteiKaijoModalClose = useCallback(() => {
        updateKakuteiKaijoModalOpenState(false)
    }, [])

    // NOTE: 確定解除モーダル 確定
    const handleClickKakuteiKaijoModalKakutei = useCallback(async () => {
        studentAYosikiFormStore.postAYosikiFormKakuteiKaijo().then(async () => {
            accountInfoStore.getStudentInfo().then();
            updateKakuteiKaijoModalOpenState(false)
            announceCommonStore.updateAnnounceCommon(true, "確定を解除しました。 / Canceled")
        })
    }, [])

    // NOTE: 確定モーダル 閉じる
    const handleClickKakuteiModalClose = useCallback(() => {
        updateKakuteiModalOpenState(false)
    }, [])

    // NOTE: 確定エラーモーダル 閉じる
    const handleClickKakuteiErrorModalClose = useCallback(() => {
        updateKakuteiErrorModalOpenState(false)
        scrollToError()
    }, [])

    return (

        <>

            <AYosikiKakuteiModal
                openState={kakuteiModalOpenState}
                handleClickClose={handleClickKakuteiModalClose}
                handleClickKakutei={handleClickKakuteiModalKakutei}
            />

            <AYosikiKakuteiKaijoModal
                openState={kakuteiKaijoModalOpenState}
                handleClickClose={handleClickKakuteiKaijoModalClose}
                handleClickKakuteiKaijo={handleClickKakuteiKaijoModalKakutei}
            />

            <AYosikiKakuteiErrorModal
                openState={kakuteiErrorModalOpenState}
                handleClickClose={handleClickKakuteiErrorModalClose}
            />

            <Announce
                announceCommon={announceCommon}
                updateAnnounceCommon={(value) => announceCommonStore.updateAnnounceCommon(value)}
            />

            <HeaderStudent/>

            <AYosikiForm
                studentInfo={studentInfo}
                aYosikiForm={aYosikiFormStoreState.aYosikiForm}
                aYosikiFormErrorMessage={aYosikiFormStoreState.aYosikiFormErrorMessage}
                handleClickBackButton={handleClickBackButton}
                handleClickSave={handleClickSave}
                handleClickKakutei={handleClickKakutei}
                handleClickKakuteiKaijo={handleClickKakuteiKaijo}
            />

        </>

    );
};

export const StudentAYosikiFormPage = withRouter(_StudentAYosikiFormPage);

