import makeStyles from "@material-ui/core/styles/makeStyles";
import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {LoginLabelTextEnum, LoginLabelTextEnumToNameJaAndEn} from "common/enums_text/LoginLabelTextEnum";
import {Button} from "components/atoms/Button";
import {TextLink} from "components/atoms/TextLink";
import {TextField} from "components/atoms/forms/TextField";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import {LoginContainer} from "components/layouts/LoginContainer";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {LoginVo} from "pages/student/login/stores/LoginStore";
import React, {FC} from "react";
import {BorderColorsEnum} from "common/enums_value/ColorsEnum";
import Box from "@material-ui/core/Box";

interface StudentLoginProps {
    className?: string
    login: LoginVo
    handleClickLoginButton: () => void
    handleBlurAccount: (v: string) => void
    handleBlurPassword: (v: string) => void
}

export const StudentLogin: FC<StudentLoginProps> = (props) => {

    const classes = useStyles();

    return (
        <LoginContainer
            className={`${classes.root} ${props.className}`}
        >

            <div>

                <div className={"login_title"}>アカウント作成 / Account Registration</div>
                <div className={"login_form"}>

                    <div className={"mt16"}>
                        <Box fontWeight={700} textAlign={"center"}>新入生はこちらから / New students click here</Box>
                        <div className={"mt4"}>
                            <Button
                                type={"secondary"}
                                label={"新入生アカウント登録 / New Student Account Registration"}
                                width={"100%"}
                                href={URL_PATHS_STUDENT.send_create_account_link_new_students}
                            />
                        </div>
                    </div>

                    <div className={"mt16"}>
                        <Box fontWeight={700} textAlign={"center"}>在校生はこちらから / Current students click here</Box>
                        <div className={"mt4"}>
                            <Button
                                type={"secondary"}
                                label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.新規アカウント登録)}
                                width={"100%"}
                                href={URL_PATHS_STUDENT.send_create_account_link}
                            />
                        </div>
                    </div>

                </div>

            </div>

            <div className={classes.line}></div>

            <div>

                <div className={"login_title"}>{LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.ログイン)}</div>
                <div className={"login_form"}>

                    <FieldWithLabel
                        label={"学籍番号または受験番号 / Student Number or examinee's (seat) number"}
                    >
                        <TextField
                            value={props.login.form.studentNumber}
                            handleBlur={props.handleBlurAccount}
                            isError={isErrorMessage(props.login.errorMessages)}
                        />
                    </FieldWithLabel>

                    <FieldWithLabel
                        label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード)}
                    >
                        <TextField
                            value={props.login.form.password}
                            type={"password"}
                            handleBlur={props.handleBlurPassword}
                            isError={isErrorMessage(props.login.errorMessages)}
                        />
                    </FieldWithLabel>

                    <ErrorMessages
                        errorMessages={convertErrorMessageVoToArrayStr(props.login.errorMessages)}
                    />

                    <div>
                        <Button
                            type={"primary"}
                            label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.ログイン)}
                            width={"100%"}
                            handleClick={props.handleClickLoginButton}
                        />
                    </div>

                    <div className={"password"}>
                        <TextLink
                            text={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワードを忘れた方はこちら)}
                            href={URL_PATHS_STUDENT.send_reset_password_link}
                        />
                    </div>

                </div>

            </div>

        </LoginContainer>
    )
};

StudentLogin.defaultProps = {
    className: "",
};

const useStyles = makeStyles({
    root: {
        "& .login_title": {
            textAlign: "center",
            fontSize: 16,
            fontWeight: 700,
        },
        "& .login_form": {
            "& > *": {
                marginTop: 16,
                "&:first-child": {
                    marginTop: 0
                }
            },
            "& .password": {
                textAlign: "center"
            }
        }
    },
    line: {
        margin: "32px 0 24px",
        borderTop: `1px solid ${BorderColorsEnum.LightGray}`
    }
});