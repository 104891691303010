import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {KikanTypeEnum, kikanTypeEnumToName} from "common/constant/KIKAN";
import {YosikiStateEnum, yosikiStateEnumToName} from "common/enums/YosikiStateEnum";
import {
    convertKikansStudentIdKikansKikanToStr
} from "common/functions/converter/convertKikansStudentIdKikansKikanToStr";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {strArrayToStrWithSlash} from "common/functions/converter/strArrayWithSlash";
import {OfficerKikansStudentIdKikansKikanVo} from "common/models/KikansModels";
import {RequiredSyoruisStoreVo, RequiredSyoruisSyoruisHituyoSyoruiVo} from "common/models/SyoruisModels";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {SummaryTextEnum, SummaryTextEnumToName} from "common/enums_text/SummaryTextEnum";
import {Button} from "components/atoms/Button";
import {
    MessageContentKikanGai,
    MessageContentNyuryokuKikan,
    MessageContentNyuryokuKikanKakutei,
    MessageContentSinseiKikan,
    MessageContentSinseiKikanKakutei,
    MessageContentTeiseiKikan,
    MessageContentTeiseiKikanKakutei
} from "components/organisms/summary/SummaryMessageContent";
import React, {FC, useCallback} from "react";
import {BgColorsEnum, BorderColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";
import {Box} from "@material-ui/core";
import {StudentMainArea} from "../../atoms/StudentMainArea";
import {Title02} from "../../atoms/titles/Title02";
import {Table} from "../../atoms/Table";
import Grid from "@material-ui/core/Grid";
import {Title05} from "../../atoms/titles/Title05";

interface ListRowProps {
    xs: boolean
    syorui: RequiredSyoruisSyoruisHituyoSyoruiVo
}

const ListRow: FC<ListRowProps> = ({
                                       xs,
                                       syorui
                                   }) => {

    return (
        <tr>
            <td>
                <Grid container spacing={1} direction={xs ? "column" : "row"} alignItems={xs ? "flex-start" : "center"}>
                    <Grid item xs={xs ? 12 : 8}>
                        <Box>{syorui.name}</Box>
                    </Grid>
                    <Grid item xs={xs ? 12 : 4}>
                        {/* FIXME: Buttonでのダウンロードの方法がわからないので調整が必要。 */}
                        <Button
                            type={"secondary"}
                            label={"ダウンロード / Download"}
                            width={"100%"}
                            href={syorui.url}
                        />
                    </Grid>
                </Grid>
            </td>
        </tr>

    )
};


interface SummaryStudentInfoSectionProps {
    className?: string
    currentKikan: { name: string, kikan: OfficerKikansStudentIdKikansKikanVo } | undefined
    studentInfo: StudentInfoStoreVo
    requiedSyoruis: RequiredSyoruisStoreVo
    handleClickYosikiDetail: () => void
    handleClickSimulation: () => void
    handleClickSyoruiConfirmationForm: () => void
    handleClickSyoruiConfirmation: () => void
}

export const SummaryStudentInfoSection: FC<SummaryStudentInfoSectionProps> = ({
                                                                                  className,
                                                                                  currentKikan,
                                                                                  studentInfo,
                                                                                  requiedSyoruis,
                                                                                  handleClickYosikiDetail,
                                                                                  handleClickSimulation,
                                                                                  handleClickSyoruiConfirmationForm,
                                                                                  handleClickSyoruiConfirmation
                                                                              }) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const isKakutei: boolean = studentInfo.yosikiState === YosikiStateEnum.確定;

    const currentKikanEnName = useCallback(() => {
        switch (currentKikan?.name) {
            case KikanTypeEnum.入力期間:
                return kikanTypeEnumToName(KikanTypeEnum.入力期間).en;
            case KikanTypeEnum.訂正期間:
                return kikanTypeEnumToName(KikanTypeEnum.訂正期間).en;
            case KikanTypeEnum.申請期間:
                return kikanTypeEnumToName(KikanTypeEnum.申請期間).en;
            default:
                return ""
        }
    }, [currentKikan])

    let message = <MessageContentKikanGai/>;
    if (isKakutei) {
        switch (currentKikan?.name) {
            case KikanTypeEnum.入力期間:
                message = <MessageContentNyuryokuKikanKakutei/>;
                break;
            case KikanTypeEnum.申請期間:
                message = <MessageContentSinseiKikanKakutei/>;
                break;
            case KikanTypeEnum.訂正期間:
                message = <MessageContentTeiseiKikanKakutei/>;
                break;
        }
    } else {
        switch (currentKikan?.name) {
            case KikanTypeEnum.入力期間:
                message = <MessageContentNyuryokuKikan/>;
                break;
            case KikanTypeEnum.申請期間:
                message = <MessageContentSinseiKikan/>;
                break;
            case KikanTypeEnum.訂正期間:
                message = <MessageContentTeiseiKikan/>;
                break;
        }
    }

    return (

        <Box>

            <Title02
                title={"入学料・授業料の免除と徴収猶予に関する書類(様式1等)の書類作成 / Preparation of documents (Form 1, etc.) related to waiver and deferment of enrollment fees and tuition"}
            />

            <StudentMainArea mt={2}>

                <Box>
                    入学料・授業料の免除と徴収猶予に関する書類を作成できます。<br/>
                    家計困窮度と学力優秀度の概要等について申告します。<br/>
                    Documents related to waiver and deferment of enrollment fees and tuition can be prepared.<br/>
                    The declaration will include a summary of the family financial situation difficulty level and
                    academic excellence level.
                </Box>

                <div
                    className={`${classes.kikan} ${xs ? classes.xsKikan : undefined}`}
                >
                    <div
                        className={"kikan_head"}>{convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.現在の期間))}</div>
                    {
                        currentKikan ?
                            <div className={"kikan_name"}>
                            <span
                                className={"mr8"}>{strArrayToStrWithSlash(currentKikan.name, currentKikanEnName())}</span>
                                <br className={"pcNone"}/>
                                {convertKikansStudentIdKikansKikanToStr(currentKikan.kikan)}
                            </div>
                            : convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.期間外))
                    }
                </div>

                <div className={`${classes.status} ${xs ? classes.xsStatus : undefined}`}>
                    <div className={"status_user"}>
                        <div className={"status_user_icon"}>
                            <img src={`${process.env.PUBLIC_URL}/images/icon_person.png`} alt="ユーザーのアイコン"
                                 className={"status_user_icon_img"}/>
                        </div>
                        <div className={"status_user_info"}>
                            <div>{studentInfo.gakubu} {studentInfo.gakka}</div>
                            <div className={"fw700"}>{studentInfo.studentNumber}</div>
                            <div className={"fw700"}>{studentInfo.name}</div>
                        </div>
                    </div>
                    <div className={"status_yosiki"}>
                        <div className={"status_yosiki_col"}>

                            <div>
                                {convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.入力状態))}：
                                {
                                    isKakutei && studentInfo.yosikiState ?
                                        <Box display={'inline'}
                                             color={TextColorsEnum.MuiBlue}>{convertEnumNameToStr(yosikiStateEnumToName(studentInfo.yosikiState))}</Box> :
                                        <Box display={'inline'}
                                             color={TextColorsEnum.Red}>{studentInfo.yosikiState ? convertEnumNameToStr(yosikiStateEnumToName(studentInfo.yosikiState)) : convertEnumNameToStr(yosikiStateEnumToName(YosikiStateEnum.未入力))}</Box>
                                }
                            </div>
                            <div>
                                {convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.エラー))}：
                                {
                                    studentInfo.yosikiHasError ?
                                        <Box display={'inline'}
                                             color={TextColorsEnum.Red}>{CommonTextEnumToNameJaAndEn(CommonTextEnum.あり)}</Box> :
                                        <Box display={'inline'}
                                             color={TextColorsEnum.MuiBlue}>{CommonTextEnumToNameJaAndEn(CommonTextEnum.なし)}</Box>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.massagesWrap}>

                    <div
                        className={`${classes.message} ${xs && classes.xsMessage} ${(isKakutei && currentKikan) && classes.kakuteiMessage}`}>
                        <div
                            className={"message_title"}>{convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.メッセージ))}</div>
                        {message}
                    </div>


                    {
                        studentInfo.yosikiHasError ?
                            <div
                                className={`${classes.errorMessage} ${xs && classes.xsErrorMessage}`}>
                                <div>正しく入力されていない項目があります。<br/>
                                    入力画面にてエラーメッセージを確認し、もう一度入力してください。<br/>
                                    Some items have not been entered correctly.<br/>
                                    Check the error messages on the data entry screen, and enter the information again.
                                </div>
                            </div>
                            : null
                    }

                </div>

                <div className={classes.buttonArea}>
                    <Button
                        className={"search_row_button"}
                        type={"primary"}
                        size={"large"}
                        width={"400px"}
                        label={convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.新規入力入力内容確認))}
                        handleClick={handleClickYosikiDetail}
                    />
                </div>

                {
                    !studentInfo.isAyosiki ? (
                        <>
                            <Box textAlign={"center"} mt={2}>
                                <Button
                                    className={"search_row_button"}
                                    type={"secondary"}
                                    size={"large"}
                                    width={"400px"}
                                    label={convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.免除結果シミュレーション))}
                                    handleClick={handleClickSimulation}
                                    disabled={!studentInfo.simulation}
                                />
                            </Box>
                            <Box textAlign={"center"} fontSize={12} mt={2}>
                                <Box>{SummaryTextEnumToName(SummaryTextEnum.入力の確定後に免除結果シミュレーションを使用できます).ja}</Box>
                                <Box>{SummaryTextEnumToName(SummaryTextEnum.入力の確定後に免除結果シミュレーションを使用できます).en}</Box>
                            </Box>

                        </>
                    ) : <></>
                }


                <Box mt={2} pt={2} borderTop={`1px solid ${BorderColorsEnum.LightGray}`} >

                    <Title05 title={convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.申請が必要なPDF))}/>

                    <Box mt={1}>
                        <Table>
                            <colgroup>
                                <col/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th>書類名 / Document Name</th>
                            </tr>
                            {
                                requiedSyoruis.syoruis?.hituyoSyoruis.map((h: RequiredSyoruisSyoruisHituyoSyoruiVo, index: number) => {
                                    return (
                                        <ListRow
                                            key={index}
                                            xs={xs}
                                            syorui={h}
                                        />
                                    )
                                })
                            }
                            </tbody>

                        </Table>
                    </Box>

                </Box>


            </StudentMainArea>

        </Box>

    )
};

const useStyles = makeStyles({
        kikan: {
            fontWeight: 700,
            fontSize: 18,
            marginTop: 16,
            paddingTop: 16,
            borderTop: `1px solid ${BorderColorsEnum.LightGray}`,
            "& .kikan_head": {
                fontSize: 14,
                fontWeight: 400,
            }
        },
        xsKikan: {
            fontSize: 14
        },
        status: {
            padding: `16px 0`,
            borderBottom: `1px solid ${BorderColorsEnum.LightGray}`,
            display: "flex",
            "& .status_user": {
                display: "flex",
                alignItems: "center",
                minWidth: 168,
                maxWidth: 240,
                marginRight: 24,
                "& > *:not(:first-child)": {
                    marginTop: 4,
                },
                "& .status_user_icon": {
                    minWidth: 60,
                    maxWidth: 60,
                    display: "flex",
                    "& .status_user_icon_img": {
                        maxWidth: "100%",
                        height: 60,
                    },
                },
                "& .status_user_info": {
                    "& .status_user_info_name": {
                        fontWeight: 700,
                    }
                },
            },
            "& .status_yosiki": {
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
                border: `8px solid ${BorderColorsEnum.LightGray}`,
                padding: "8px 0px",
                // marginLeft: 24,
                "& > *:not(:first-child)": {
                    borderLeft: `1px solid ${BorderColorsEnum.LightGray}`,
                },
                "& .status_yosiki_col": {
                    fontWeight: 700,
                    flexGrow: 1,
                    textAlign: "center",
                }
            },
        },
        xsStatus: {
            marginTop: 12,
            display: "block",
            "& .status_user": {
                minWidth: "inherit",
                maxWidth: "inherit",
                marginRight: 0,
                "& > *:not(:first-child)": {},
                "& .status_user_icon": {
                    minWidth: "60px",
                    maxWidth: "60px",
                    "& .status_user_icon_img": {
                        height: "60px"
                    },
                },
            },
            "& .status_yosiki": {
                marginTop: 16,
                marginLeft: 0,
            },
        },
        massagesWrap: {
            marginTop: 16,
            "& > *:not(:first-child)": {
                marginTop: 8,
            },
        },
        message: {
            border: `4px solid ${BorderColorsEnum.DarkGray}`,
            padding: `16px 18px`,
            position: "relative",
            "& .message_title": {
                color: TextColorsEnum.Black,
                fontWeight: 700,
                fontSize: 18,
                position: "absolute",
                top: -18,
                left: 8,
                padding: "0 8px",
                backgroundColor: BgColorsEnum.White
            }
        },
        xsMessage: {
            padding: `10px 16px`,
            "& .message_title": {
                fontSize: 16,
            }
        },
        errorMessage: {
            border: `4px solid ${BorderColorsEnum.Red}`,
            color: `${BorderColorsEnum.Red}`,
            fontWeight: 700,
            padding: `8px 16px`,
        },
        xsErrorMessage: {},
        kakuteiMessage: {
            border: `4px solid ${BorderColorsEnum.MuiBlue}`,
            color: `${BorderColorsEnum.MuiBlue}`,
            fontWeight: 700,
        },
        buttonArea: {
            marginTop: 16,
            display: "flex",
            justifyContent: "center",
            "& .buttonArea_link": {
                maxWidth: "100%"
            }
        },
    }
);

