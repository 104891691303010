import React from 'react';

export const ModalContentDokurituSeikeisyaRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                留学生は独立生計者として申請すること。<br/>
                ただし、在日する家族に収入のある留学生は世帯生計とする。
            </div>

            <div className={"modalContent_item"}>
                International students must apply as individuals in independent households.<br/>
                However, international students with family members living in Japan who have an income are considered to be in family households.
            </div>

        </div>

    );
};
