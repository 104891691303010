import {MemberVo} from "../../models/YosikiInfoModels";
import {ZokugaraEnum} from "../../enums/ZokugaraEnum";

// NOTE: 収入の入力が必要な続柄か判定
export const canInputSyunyu = (m: MemberVo, isGakubu: boolean, isDokuritu?: boolean): boolean => {
    // 就学者以外は入力可能
    if (m.isSyugakusya === false) {
        return true
    }

    // 例外として、申請者が大学院かつ独立生計者で配偶者が学生の場合に入力可能。
    const haigusyaIsStudent = (m.zokugara === ZokugaraEnum.配偶者) && (m.isSyugakusya === true)
    if(haigusyaIsStudent && !isGakubu && isDokuritu) {
        return true
    }

    return false
}