/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwAYosikiFormAYosiki2
 */
export interface SwAYosikiFormAYosiki2 {
    /**
     * 
     * @type {boolean}
     * @memberof SwAYosikiFormAYosiki2
     */
    agreement: boolean;
    /**
     * 
     * @type {string}
     * @memberof SwAYosikiFormAYosiki2
     */
    syogakuseiNumber: string;
}

export function SwAYosikiFormAYosiki2FromJSON(json: any): SwAYosikiFormAYosiki2 {
    return SwAYosikiFormAYosiki2FromJSONTyped(json, false);
}

export function SwAYosikiFormAYosiki2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormAYosiki2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreement': json['agreement'],
        'syogakuseiNumber': json['syogakuseiNumber'],
    };
}

export function SwAYosikiFormAYosiki2ToJSON(value?: SwAYosikiFormAYosiki2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreement': value.agreement,
        'syogakuseiNumber': value.syogakuseiNumber,
    };
}


