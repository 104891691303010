// ページをロード時に走らせる。
// 呼び出しツリーのおくまったところに処理期にアクセスするコードが大量にある
// 呼び出しツリーに沿って渡すのはあまりにも面倒のためglobalで取得できるようにする。
// 利用途中で変わることはない(ただし職員が処理期を更新した時は再度 initGlobalSyoriki を実行する必要あり)。

import { SyorikiModel } from "common/models/SyorikiModel";
import { YearModel } from "common/models/YearModel";
import { GakkiEnum } from "common/enums/GakkiEnum";

export async function initGlobalSyoriki() {
    try {
        const res = await fetch('/api/syoriki_info');
        if (res.status != 200) throw new Error();
        const json = await res.json();
        const syoriki = {
            yearModel: new YearModel(json.year),
            gakki: json.gakki as GakkiEnum,
        };
        console.log("syoriki");
        console.log(syoriki);
        (window as any)._syoriki = syoriki;
    } catch (error) {
        alert("エラーが発生しました。 / Error occured.")
    }
}

export function getGlobalSyoriki(): SyorikiModel {
    const syoriki = (window as any)._syoriki;
    if (syoriki == undefined) alert("エラーが発生しました。 / Error occured.");
    return syoriki
}
