import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {FC} from 'react';
import {BgColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";

interface ErrorMessagesProps {
    className?: string;
    errorMessages: Array<string>;
}

export const ErrorMessages: FC<ErrorMessagesProps> = (props) => {
    const classes = useStyles();

    if(props.errorMessages.length === 0) return <></>

    return (
        <div className={`${classes.root} ${props.className ? props.className : ''}`}>
            {props.errorMessages.map((e: string, i: number) => {
                return <Typography key={`${e + i}`} className={'isActiveErrorMessage'}>{e}</Typography>
            })}
        </div>
    )
};

const useStyles = makeStyles({
    root: {
        backgroundColor: BgColorsEnum.LightRed02,
        "& .MuiTypography-body1": {
            color: TextColorsEnum.Red,
            fontSize: 12,
        },
    },
});