import {createStyles} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from 'react';
import {BorderColorsEnum} from "common/enums_value/ColorsEnum";

interface MessageBoxProps {
    className?: string;
}

export const MessageBox: FC<MessageBoxProps> = (props) => {

    const classes = useStyles();

    return (
        <div className={`${classes.root} ${props.className}`}>
            {props.children}
        </div>
    )

};

const useStyles = makeStyles(() => createStyles({
    root: {
        border: `4px solid ${BorderColorsEnum.LightGray}`,
        padding: `8px 16px`,
        position: "relative",
    },
}));