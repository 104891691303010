/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwAYosikiFormCommonBessi1,
    SwAYosikiFormCommonBessi1FromJSON,
    SwAYosikiFormCommonBessi1FromJSONTyped,
    SwAYosikiFormCommonBessi1ToJSON,
    SwAYosikiFormCommonBessi2,
    SwAYosikiFormCommonBessi2FromJSON,
    SwAYosikiFormCommonBessi2FromJSONTyped,
    SwAYosikiFormCommonBessi2ToJSON,
    SwAYosikiFormCommonNihonGakuseiSienKiko,
    SwAYosikiFormCommonNihonGakuseiSienKikoFromJSON,
    SwAYosikiFormCommonNihonGakuseiSienKikoFromJSONTyped,
    SwAYosikiFormCommonNihonGakuseiSienKikoToJSON,
    SwAddress,
    SwAddressFromJSON,
    SwAddressFromJSONTyped,
    SwAddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiFormCommon
 */
export interface SwAYosikiFormCommon {
    /**
     * 
     * @type {number}
     * @memberof SwAYosikiFormCommon
     */
    birthYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SwAYosikiFormCommon
     */
    birthMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof SwAYosikiFormCommon
     */
    birthDay?: number;
    /**
     * 
     * @type {number}
     * @memberof SwAYosikiFormCommon
     */
    gakunen?: number;
    /**
     * 
     * @type {SwAddress}
     * @memberof SwAYosikiFormCommon
     */
    address: SwAddress;
    /**
     * 
     * @type {SwAYosikiFormCommonNihonGakuseiSienKiko}
     * @memberof SwAYosikiFormCommon
     */
    nihonGakuseiSienKiko: SwAYosikiFormCommonNihonGakuseiSienKiko;
    /**
     * 
     * @type {SwAYosikiFormCommonBessi1}
     * @memberof SwAYosikiFormCommon
     */
    bessi1: SwAYosikiFormCommonBessi1;
    /**
     * 
     * @type {SwAYosikiFormCommonBessi2}
     * @memberof SwAYosikiFormCommon
     */
    bessi2: SwAYosikiFormCommonBessi2;
}

export function SwAYosikiFormCommonFromJSON(json: any): SwAYosikiFormCommon {
    return SwAYosikiFormCommonFromJSONTyped(json, false);
}

export function SwAYosikiFormCommonFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormCommon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthYear': !exists(json, 'birthYear') ? undefined : json['birthYear'],
        'birthMonth': !exists(json, 'birthMonth') ? undefined : json['birthMonth'],
        'birthDay': !exists(json, 'birthDay') ? undefined : json['birthDay'],
        'gakunen': !exists(json, 'gakunen') ? undefined : json['gakunen'],
        'address': SwAddressFromJSON(json['address']),
        'nihonGakuseiSienKiko': SwAYosikiFormCommonNihonGakuseiSienKikoFromJSON(json['nihonGakuseiSienKiko']),
        'bessi1': SwAYosikiFormCommonBessi1FromJSON(json['bessi1']),
        'bessi2': SwAYosikiFormCommonBessi2FromJSON(json['bessi2']),
    };
}

export function SwAYosikiFormCommonToJSON(value?: SwAYosikiFormCommon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthYear': value.birthYear,
        'birthMonth': value.birthMonth,
        'birthDay': value.birthDay,
        'gakunen': value.gakunen,
        'address': SwAddressToJSON(value.address),
        'nihonGakuseiSienKiko': SwAYosikiFormCommonNihonGakuseiSienKikoToJSON(value.nihonGakuseiSienKiko),
        'bessi1': SwAYosikiFormCommonBessi1ToJSON(value.bessi1),
        'bessi2': SwAYosikiFormCommonBessi2ToJSON(value.bessi2),
    };
}


