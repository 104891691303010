/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwOfficerMailRecipient,
    SwOfficerMailRecipientFromJSON,
    SwOfficerMailRecipientFromJSONTyped,
    SwOfficerMailRecipientToJSON,
    SwUploadedFile,
    SwUploadedFileFromJSON,
    SwUploadedFileFromJSONTyped,
    SwUploadedFileToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerMailDetail
 */
export interface SwOfficerMailDetail {
    /**
     * 
     * @type {string}
     * @memberof SwOfficerMailDetail
     */
    id: string;
    /**
     * メールの状態を表現する GroupMailStateEnum
     * @type {string}
     * @memberof SwOfficerMailDetail
     */
    stateCode: string;
    /**
     * 送信開始時間
     * @type {string}
     * @memberof SwOfficerMailDetail
     */
    sendAt: string;
    /**
     * enum
     * @type {string}
     * @memberof SwOfficerMailDetail
     */
    mailTo: string;
    /**
     * enum
     * @type {string}
     * @memberof SwOfficerMailDetail
     */
    bukyokuCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerMailDetail
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerMailDetail
     */
    body: string;
    /**
     * 
     * @type {Array<SwUploadedFile>}
     * @memberof SwOfficerMailDetail
     */
    uploadedFiles?: Array<SwUploadedFile>;
    /**
     * 
     * @type {Array<SwOfficerMailRecipient>}
     * @memberof SwOfficerMailDetail
     */
    recipients: Array<SwOfficerMailRecipient>;
}

export function SwOfficerMailDetailFromJSON(json: any): SwOfficerMailDetail {
    return SwOfficerMailDetailFromJSONTyped(json, false);
}

export function SwOfficerMailDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerMailDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stateCode': json['stateCode'],
        'sendAt': json['sendAt'],
        'mailTo': json['mailTo'],
        'bukyokuCode': !exists(json, 'bukyokuCode') ? undefined : json['bukyokuCode'],
        'title': json['title'],
        'body': json['body'],
        'uploadedFiles': !exists(json, 'uploadedFiles') ? undefined : ((json['uploadedFiles'] as Array<any>).map(SwUploadedFileFromJSON)),
        'recipients': ((json['recipients'] as Array<any>).map(SwOfficerMailRecipientFromJSON)),
    };
}

export function SwOfficerMailDetailToJSON(value?: SwOfficerMailDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'stateCode': value.stateCode,
        'sendAt': value.sendAt,
        'mailTo': value.mailTo,
        'bukyokuCode': value.bukyokuCode,
        'title': value.title,
        'body': value.body,
        'uploadedFiles': value.uploadedFiles === undefined ? undefined : ((value.uploadedFiles as Array<any>).map(SwUploadedFileToJSON)),
        'recipients': ((value.recipients as Array<any>).map(SwOfficerMailRecipientToJSON)),
    };
}


