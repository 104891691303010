import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum TokubetuKojoLabelTextEnum {
    母子父子世帯 = "母子・父子世帯",
    母無 = "母無",
    父無 = "父無",
    時期 = "時期",
    理由 = "理由",
    生活保護世帯 = "生活保護世帯",
    申請者本人が独立生計者 = "申請者本人が独立生計者",
    主たる家計支持者別居の世帯 = "主たる家計支持者別居の世帯",
    火災風水害盗難等の被害を受けた世帯 = "火災・風水害・盗難等の被害を受けた世帯",
    手帳番号 = "手帳番号",
    有り = "有り",
    無し = "無し",
    該当する = "該当する",
    該当しない = "該当しない",
    療養開始時期 = "療養開始時期",
    半年当たりの療養費 = "半年当たりの療養費",
    別居理由 = "別居理由",
    カ月当たり住居光熱費等 = "1カ月当たり住居・光熱費等",
    被害内容 = "被害内容",
    被害額 = "被害額",
}

export function TokubetuKojoLabelTextEnumToNameJaAndEn(e: TokubetuKojoLabelTextEnum): string {
    switch (e) {
        case TokubetuKojoLabelTextEnum.母子父子世帯:
            return convertEnumNameToStr({ja: "母子・父子世帯", en: "Single-parent households"});
        case TokubetuKojoLabelTextEnum.母無:
            return convertEnumNameToStr({ja: "母無", en: "Mother is not present"});
        case TokubetuKojoLabelTextEnum.父無:
            return convertEnumNameToStr({ja: "父無", en: "Father is not present"});
        case TokubetuKojoLabelTextEnum.時期:
            return convertEnumNameToStr({ja: "時期", en: "Period"});
        case TokubetuKojoLabelTextEnum.理由:
            return convertEnumNameToStr({ja: "理由", en: "Reason"});
        case TokubetuKojoLabelTextEnum.生活保護世帯:
            return convertEnumNameToStr({ja: "生活保護世帯", en: "Households receiving public welfare assistance"});
        case TokubetuKojoLabelTextEnum.申請者本人が独立生計者:
            return convertEnumNameToStr({ja: "申請者本人が独立生計者", en: "Applicant is an individual in independent household"});
        case TokubetuKojoLabelTextEnum.主たる家計支持者別居の世帯:
            return convertEnumNameToStr({ja: "主たる家計支持者別居の世帯", en: "Households in which the primary household supporter lives separately from the family"});
        case TokubetuKojoLabelTextEnum.火災風水害盗難等の被害を受けた世帯:
            return convertEnumNameToStr({ja: "火災・風水害・盗難等の被害を受けた世帯", en: "Households affected by a fire, flood, storm, or other natural disaster, or by theft"});
        case TokubetuKojoLabelTextEnum.手帳番号:
            return convertEnumNameToStr({ja: "手帳番号", en: "Certificate Number"});
        case TokubetuKojoLabelTextEnum.有り:
            return convertEnumNameToStr({ja: "有り", en: "Yes"});
        case TokubetuKojoLabelTextEnum.無し:
            return convertEnumNameToStr({ja: "無し", en: "No"});
        case TokubetuKojoLabelTextEnum.該当する:
            return convertEnumNameToStr({ja: "該当する", en: "Applicable"});
        case TokubetuKojoLabelTextEnum.該当しない:
            return convertEnumNameToStr({ja: "該当しない", en: "Not applicable"});
        case TokubetuKojoLabelTextEnum.療養開始時期:
            return convertEnumNameToStr({ja: "療養開始時期", en: "Period of medical treatment"});
        case TokubetuKojoLabelTextEnum.半年当たりの療養費:
            return convertEnumNameToStr({ja: "半年当たりの療養費", en: "Medical expenses per half year"});
        case TokubetuKojoLabelTextEnum.別居理由:
            return convertEnumNameToStr({ja: "半年当たりの療養費", en: "Reason for Living Separately"});
        case TokubetuKojoLabelTextEnum.カ月当たり住居光熱費等:
            return convertEnumNameToStr({ja: "1カ月当たり住居・光熱費等", en: "Monthly Expenses for Housing, Utilities, etc."});
        case TokubetuKojoLabelTextEnum.被害内容:
            return convertEnumNameToStr({ja: "被害内容", en: "Details of Damage"});
        case TokubetuKojoLabelTextEnum.被害額:
            return convertEnumNameToStr({ja: "被害額", en: "Amount of Damage"});
        default :
            return ""
    }
}