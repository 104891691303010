// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum SettiKubunEnum {
    国立 = "E国立",
    公立 = "E公立",
    私立 = "E私立",
}

export function settiKubunEnumToName(e: SettiKubunEnum): { ja: string, en: string } {
    switch (e) {
        case SettiKubunEnum.国立: return { ja: "国立", en: "National" };
        case SettiKubunEnum.公立: return { ja: "公立", en: "Public" };
        case SettiKubunEnum.私立: return { ja: "私立", en: "Private" };
    }
}