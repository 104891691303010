/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwSinseiTypeErrorMessage,
    SwSinseiTypeErrorMessageFromJSON,
    SwSinseiTypeErrorMessageFromJSONTyped,
    SwSinseiTypeErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwBasicInfoErrorMessageResponse
 */
export interface SwBasicInfoErrorMessageResponse {
    /**
     * 
     * @type {SwSinseiTypeErrorMessage}
     * @memberof SwBasicInfoErrorMessageResponse
     */
    sinseiType?: SwSinseiTypeErrorMessage;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwBasicInfoErrorMessageResponse
     */
    tokubetuKojo?: Array<SwErrorMessage>;
}

export function SwBasicInfoErrorMessageResponseFromJSON(json: any): SwBasicInfoErrorMessageResponse {
    return SwBasicInfoErrorMessageResponseFromJSONTyped(json, false);
}

export function SwBasicInfoErrorMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwBasicInfoErrorMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sinseiType': !exists(json, 'sinseiType') ? undefined : SwSinseiTypeErrorMessageFromJSON(json['sinseiType']),
        'tokubetuKojo': !exists(json, 'tokubetuKojo') ? undefined : ((json['tokubetuKojo'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwBasicInfoErrorMessageResponseToJSON(value?: SwBasicInfoErrorMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sinseiType': SwSinseiTypeErrorMessageToJSON(value.sinseiType),
        'tokubetuKojo': value.tokubetuKojo === undefined ? undefined : ((value.tokubetuKojo as Array<any>).map(SwErrorMessageToJSON)),
    };
}


