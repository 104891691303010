import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from 'react';
import {BorderColorsEnum} from "common/enums_value/ColorsEnum";

interface Title04Props {
    title: string;
    className?: string;
}

export const Title04: FC<Title04Props> = (props) => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} ${props.className}`}>
            {props.title}
        </div>
    )
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: "0 12px",
        borderLeft: `4px solid ${BorderColorsEnum.MuiBlue}`,
        boxSizing: 'border-box',
        fontSize: 18,
        fontWeight: "bold",
    },
}));