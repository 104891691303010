import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {AuthTypeEnum} from "common/enums/AuthTypeEnum";
import {getCurrentKikan} from "common/functions/getCurrentKikan";
import {
    OfficerKikansStoreVo,
    OfficerKikansStudentIdKikansKikanVo,
    OfficerKikansStudentIdKikansVo
} from "common/models/KikansModels";
import {RequiredSyoruisStoreVo} from "common/models/SyoruisModels";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {announceCommonStore, AnnounceCommonStoreVo} from "stores/common/AnnounceCommonStore";
import {osiraseStore, OsiraseStoreVo} from "stores/common/OsiraseStore";
import {commonSyoruiStore, CommonSyoruiStoreVo} from "stores/common/CommonSyoruiStore";
import {Container} from "components/layouts/Container";
import {SummaryPageComponent} from "components/organisms/summary/SummaryPageComponent";
import {Announce} from "components/organisms/utilities/Announce";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import {studentKikansStore} from "pages/student/summary/stores/StudentKikansStore";
import {studentRequiedSyoruisStore} from "pages/student/summary/stores/StudentRequiedSyoruisStore";
import {accountInfoStore} from "stores/student/AccountInfoStore";
import React, {useCallback} from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import {studentAYosikiSyoruisStore} from "./stores/StudentAYosikiSyoruisStore";
import {AYosikiSyoruisStoreVo} from "../../../common/models/AYosikiSyoruisModels";

const _Summary: React.FC<RouteComponentProps<{ studentNumber: string }>> = () => {

    const [osirase, updateOsirase] = React.useState<OsiraseStoreVo>(osiraseStore.currentState);
    const [studentRequiredSyoruis, updateStudentRequiredSyoruis] = React.useState<RequiredSyoruisStoreVo>(studentRequiedSyoruisStore.currentState);
    const [studentkikans, updateKikans] = React.useState<OfficerKikansStoreVo>(studentKikansStore.currentState);
    const [accountInfo, updateAccountInfo] = React.useState<StudentInfoStoreVo>(accountInfoStore.currentState);
    const [commonSyorui, updateCommonSyorui] = React.useState<CommonSyoruiStoreVo>(commonSyoruiStore.currentState);
    const [announceCommon, updateAnnounceCommon] = React.useState<AnnounceCommonStoreVo>(announceCommonStore.currentState);

    const [studentAYosikiSyoruisStoreState, updateStudentAYosikiSyoruisStoreState] = React.useState<AYosikiSyoruisStoreVo>(studentAYosikiSyoruisStore.currentState);


    React.useEffect(() => {
        osiraseStore.getOsirases(AuthTypeEnum.student).then();
        return osiraseStore.listen(updateOsirase);
    }, []);

    React.useEffect(() => {
        studentRequiedSyoruisStore.getSyoruisStudentId().then();
        return studentRequiedSyoruisStore.listen(updateStudentRequiredSyoruis);
    }, []);

    React.useEffect(() => {
        studentKikansStore.getKikansStudentId().then();
        return studentKikansStore.listen(updateKikans);
    }, []);

    React.useEffect(() => {
        accountInfoStore.getStudentInfo().then();
        return accountInfoStore.listen(updateAccountInfo);
    }, []);

    React.useEffect(() => {
        if(accountInfo.isAYosikiKakutei){
            studentAYosikiSyoruisStore.getAYosikiSyoruis().then();
        }
        return studentAYosikiSyoruisStore.listen(updateStudentAYosikiSyoruisStoreState);
    }, [accountInfo.isAYosikiKakutei])

    React.useEffect(() => {
        return commonSyoruiStore.listen(updateCommonSyorui);
    }, []);

    React.useEffect(() => {
        return announceCommonStore.listen(updateAnnounceCommon);
    }, []);

    function handleClickYosikiDetail() {
        window.location.assign(`${URL_PATHS_STUDENT.yosiki_detail}`);
    }

    // osiraseStore
    function handleClickOsirase(id: string) {
        osiraseStore.getOsirase(id, AuthTypeEnum.student).then()
    }

    function handleClickDetailModalClose() {
        osiraseStore.updateOsiraseDetailModalState(false)
    }

    // commonSyoruiStore
    function handleClickSyoruiList() {
        commonSyoruiStore.getSyoruis(AuthTypeEnum.student).then();
    }

    function handleClickSyoruiModalClose() {
        commonSyoruiStore.updateSyoruiModalState(false)
    }

    // 必要書類確認機能のフォームに遷移
    const handleClickSyoruiConfirmationForm = useCallback(() => {
        // NOTE: 学生リンク
        window.location.assign(`${URL_PATHS_STUDENT.syorui_confirmation_form}`);
    }, [])

    // 必要書類確認機能の書類一覧に遷移
    const handleClickSyoruiConfirmation = useCallback(() => {
        // NOTE: 学生リンク
        window.location.assign(`${URL_PATHS_STUDENT.syorui_confirmation}`);
    }, [])

    // NOTE: A様式フォームへ遷移
    const handleClickAYosikiForm = useCallback(() => {
        window.location.assign(`${URL_PATHS_STUDENT.a_yosiki_form}`);
    }, [])

    const kikans: OfficerKikansStudentIdKikansVo | undefined = studentkikans.kikans;
    const currentKikan: { name: string, kikan: OfficerKikansStudentIdKikansKikanVo } | undefined = getCurrentKikan(kikans);

    return (

        <>

            <Announce
                announceCommon={announceCommon}
                updateAnnounceCommon={(value) => announceCommonStore.updateAnnounceCommon(value)}
            />

            <HeaderStudent/>

            <Container
                maxWidth={'880px'}
            >

                <SummaryPageComponent
                    handleClickSyoruiConfirmationForm={handleClickSyoruiConfirmationForm}
                    handleClickSyoruiConfirmation={handleClickSyoruiConfirmation}
                    osirase={osirase}
                    handleClickOsirase={handleClickOsirase}
                    handleClickDetailModalClose={handleClickDetailModalClose}
                    handleClickYosikiDetail={handleClickYosikiDetail}
                    currentKikan={currentKikan}
                    studentInfo={accountInfo}
                    requiedSyoruis={studentRequiredSyoruis}
                    kikans={kikans}
                    syoruis={commonSyorui.syoruis}
                    syoruiModalState={commonSyorui.syoruiModalState}
                    aYosikiSYoruis={studentAYosikiSyoruisStoreState.syoruis}
                    handleClickSyoruiModalClose={handleClickSyoruiModalClose}
                    handleClickSyoruiList={handleClickSyoruiList}
                    handleClickAYosikiForm={handleClickAYosikiForm}
                />

            </Container>

        </>

    );
};

export const Summary = withRouter(_Summary);

