/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwKikans,
    SwKikansFromJSON,
    SwKikansFromJSONTyped,
    SwKikansToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwKikanRaw
 */
export interface SwKikanRaw {
    /**
     * 
     * @type {string}
     * @memberof SwKikanRaw
     */
    kubun: string;
    /**
     * enum
     * @type {string}
     * @memberof SwKikanRaw
     */
    bukyokuCode: string;
    /**
     * 
     * @type {SwKikans}
     * @memberof SwKikanRaw
     */
    kikans?: SwKikans;
}

export function SwKikanRawFromJSON(json: any): SwKikanRaw {
    return SwKikanRawFromJSONTyped(json, false);
}

export function SwKikanRawFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwKikanRaw {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kubun': json['kubun'],
        'bukyokuCode': json['bukyokuCode'],
        'kikans': !exists(json, 'kikans') ? undefined : SwKikansFromJSON(json['kikans']),
    };
}

export function SwKikanRawToJSON(value?: SwKikanRaw | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kubun': value.kubun,
        'bukyokuCode': value.bukyokuCode,
        'kikans': SwKikansToJSON(value.kikans),
    };
}


