import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {NumberField} from "components/atoms/forms/NumberField";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import React, {FC} from 'react';
import {TextColorsEnum} from "common/enums_value/ColorsEnum";

interface YearMonthProps {
    className?: string;
    width?: string;
    widthYear?: string;
    widthMonth?: string;
    suffixText?: string
    helperText?: string;
    prefixTextYear?: string
    errorMessages?: Array<string>;
    isError?: boolean;
    isErrorYear?: boolean;
    isErrorMonth?: boolean

    valueYear?: string;
    valueMonth?: string;
    handleBlurYear?: (value: string) => void;
    handleBlurMonth?: (value: string) => void;
}

// 一覧などの検索フォームのラベルのスタイル用コンポーネント
export const YearMonth: FC<YearMonthProps> = (props) => {

    const classes = useStyles();

    function handleBlurYear(newValue: string) {
        props.handleBlurYear && props.handleBlurYear(newValue)
    }

    function handleBlurMonth(newValue: string) {
        props.handleBlurMonth && props.handleBlurMonth(newValue)
    }

    return (
        <>

            <div
                className={`${classes.root} ${props.className}`}
            >

                <NumberField
                    value={props.valueYear}
                    isError={props.isErrorYear || props.isError}
                    width={props.widthYear ? props.widthYear : "80px"}
                    prefixText={props.prefixTextYear}
                    suffixText={"年"}
                    handleBlur={handleBlurYear}
                    maxLength={4}
                />

                <NumberField
                    value={props.valueMonth}
                    isError={props.isErrorMonth || props.isError}
                    width={props.widthMonth ? props.widthMonth : "48px"}
                    suffixText={"月"}
                    handleBlur={handleBlurMonth}
                    maxLength={2}
                />

                {
                    props.suffixText &&
                    <div className={"suffixText"}>
                        {props.suffixText}
                    </div>
                }

            </div>

            {
                props.errorMessages &&
                <ErrorMessages
                    className={classes.errorMessages}
                    errorMessages={props.errorMessages}
                />
            }

            {
                props.helperText &&
                <Typography className={classes.helperText}>
                    {props.helperText}
                </Typography>
            }

        </>

    )
};

YearMonth.defaultProps = {};

const useStyles = makeStyles({
    root: {
        maxWidth: "100%!important",
        display: "flex",
        alignItems: "center",
        boxSizing: 'border-box',
        "& > *:not(:first-child)": {
            marginLeft: 8
        }
    },
    errorMessages: {
        marginTop: 4,
    },
    helperText: {
        marginTop: 4,
        color: TextColorsEnum.Green,
        fontSize: 12
    }
});