import React from 'react';
import {SwAYosikiFormErrorMessageResponse} from "../../../../openapi";
import {AYosikiFormVo} from "../../../../common/models/AYosikiFormModels";
import {Title04} from "../../../atoms/titles/Title04";
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import {isErrorMessage} from "../../../../common/functions/isErrorMessage";
import {joinArraySwErrorMessage} from "../../../../common/functions/converter/joinArraySwErrorMessage";
import {FormTableRow} from "../../forms/FormTableRow";
import {FormTable} from "../../forms/FormTable";
import {RequiredTagTypeEnum} from "../../../../common/enums_value/RequiredTagTypeEnum";
import {NumberField} from "../../../atoms/forms/NumberField";
import {
    TitleNameTextEnum,
    TitleNameTextEnumToNameJaAndEn
} from "../../../../common/enums_text/yosiki/TitleNameTextEnum";
import {Title05} from "../../../atoms/titles/Title05";
import {AYosiki1Description} from "./AYosiki1Description";
import {
    AYosikiInfoTextEnum,
    AYosikiInfoTextEnumToNameJaAndEn
} from "../../../../common/enums_text/yosiki/AYosikiInfoTextEnum";
import {RequiredTag} from "../../../atoms/tags/RequiredTag";
import {ListDsc} from "../../../atoms/ListDsc";
import {CheckboxGroup} from "../../forms/CheckboxGroup";
import {Checkbox} from "../../../atoms/forms/Checkbox";
import {FieldWithLabel} from "../../utilities/FieldWithLabel";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "../../../../common/enums_text/CommonTextEnum";
import {RadioButtonGroup} from "../../forms/RadioButtonGroup";
import {RadioButton} from "../../../atoms/forms/RadioButton";
import {
    AYosikiInfoLabelTextEnum,
    AYosikiInfoLabelTextEnumToNameJaAndEn
} from "../../../../common/enums_text/yosiki/AYosikiInfoLabelTextEnum";
import {TextField} from "../../../atoms/forms/TextField";
import {YearMonth} from "../../forms/YearMonth";
import {MosikomiTypeEnum, mosikomiTypeEnumToName} from "../../../../common/enums/MosikomiTypeEnum";
import {convertEnumNameToStr} from "../../../../common/functions/converter/convertEnumNameToStr";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

interface Props {
    formValues: AYosikiFormVo
    errorMessages: SwAYosikiFormErrorMessageResponse | undefined
    updates: (formValues: AYosikiFormVo, errorMessages?: SwAYosikiFormErrorMessageResponse | undefined) => void
    isKakutei: boolean
}

export const AYosiki1Form: React.FC<Props> = ({
                                                  formValues,
                                                  errorMessages,
                                                  updates,
                                                  isKakutei
                                              }) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    function handleChangeAgreement(v: boolean) {
        if (!formValues) return
        formValues.aYosiki1.agreement = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                agreement: undefined
            }
        })
    }

    function handleChangeIsSienzumi(v: boolean) {
        if (!formValues) return
        formValues.aYosiki1.isSienzumi = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                isSienzumi: undefined
            }
        })
    }

    function handleChangeMenjoBefore(v: boolean) {
        if (!formValues) return
        formValues.aYosiki1.menjoBefore = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                menjoBefore: undefined
            }
        })
    }

    function handleBlurSienzumiSchoolName(v: string) {
        if (!formValues) return
        formValues.aYosiki1.sienzumi.schoolName = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                sienzumi: {
                    ...errorMessages?.aYosiki1?.sienzumi,
                    schoolName: undefined
                }
            }
        })
    }

    function handleBlurSienzumiStartYear(v: string) {
        if (!formValues) return
        formValues.aYosiki1.sienzumi.startYear = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                sienzumi: {
                    ...errorMessages?.aYosiki1?.sienzumi,
                    startYear: undefined
                }
            }
        })
    }

    function handleBlurSienzumiStartMonth(v: string) {
        if (!formValues) return
        formValues.aYosiki1.sienzumi.startMonth = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                sienzumi: {
                    ...errorMessages?.aYosiki1?.sienzumi,
                    startMonth: undefined
                }
            }
        })
    }

    function handleBlurSienzumiEndYear(v: string) {
        if (!formValues) return
        formValues.aYosiki1.sienzumi.endYear = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                sienzumi: {
                    ...errorMessages?.aYosiki1?.sienzumi,
                    endYear: undefined
                }
            }
        })
    }

    function handleBlurSienzumiEndMonth(v: string) {
        if (!formValues) return
        formValues.aYosiki1.sienzumi.endMonth = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                sienzumi: {
                    ...errorMessages?.aYosiki1?.sienzumi,
                    endMonth: undefined
                }
            }
        })
    }

    function handleBlurSienzumiMonths(v: string) {
        if (!formValues) return
        formValues.aYosiki1.sienzumi.months = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                sienzumi: {
                    ...errorMessages?.aYosiki1?.sienzumi,
                    months: undefined
                }
            }
        })
    }

    function handleChangeMosikomiType(v: string) {
        if (!formValues) return
        formValues.aYosiki1.mosikomiType = v as MosikomiTypeEnum
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                mosikomiType: undefined
            }
        })
    }

    function handleBlurUketukeNumber(v: string) {
        if (!formValues) return
        formValues.aYosiki1.uketukeNumber = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                uketukeNumber: undefined
            }
        })
    }

    function handleBlurSyogakuseiNumber(v: string) {
        if (!formValues) return
        formValues.aYosiki1.syogakuseiNumber = v
        updates(formValues, {
            ...errorMessages,
            aYosiki1: {
                ...errorMessages?.aYosiki1,
                syogakuseiNumber: undefined
            }
        })
    }

    return (
        <Grid container spacing={2} direction={"column"}>

            <Grid item xs>
                <Title04
                    title={"A様式1 / Form A1"}
                />
            </Grid>

            <Grid item xs>
                <Title05 title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.申請書作成にあたっての注意事項)}/>
                <AYosiki1Description mt={2}/>
            </Grid>

            <Grid item xs>

                <FormTable
                    labelWidth={"200px"}
                >

                    <FormTableRow
                        label={"同意 / Agreement"}
                        requiredType={RequiredTagTypeEnum.必須}
                    >

                        <Grid container spacing={1}>

                            <Grid item xs={12}>
                                <Box fontWeight={700}>
                                    <Box>私は、貴学に対し、大学等における修学の支援に関する法律による授業料等減免の対象者としての認定を申請します。</Box>
                                    <Box>I request approval from your University as a student eligible for reduction or waiver of tuition in accordance with the Act to Support Study at Universities, etc.</Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <ListDsc>
                                    <li>
                                        この中請書の記載事項は事実と相違ありません。なお、申請書の記載事項に事実と相違があった場合、認定を取り消され、減免を打ち切られることがあるとともに、在学する学校から減免を受けた金額の支払を求められることがあることを承知しています。
                                    </li>
                                    <li>
                                        授業料等減免の対象者の認定手続きにおいて、独立行政法人日本学生支援機構（以下、「機構」という。）を通じ、東京農工大学が機構の保有する私の給付奨学金に関する情報の送付を受けること、及び機構が東京農工大学の保有する私の授業料等減免等に関する情報の送付を受けることに同意します。
                                    </li>
                                    <li>
                                        現在、他の学校において、大学等における修学の支援に関する法律による授業料等減免を受けておらず、当該授業料等減免の対象者の認定申請中でもありません。
                                    </li>
                                    <li>
                                        All information indicated in this application is correct. Furthermore, I am aware that if there are any discrepancies between the information indicated in the application and the actual facts, my approval status may be cancelled, any reduction or waivers may be terminated, and payment of the monetary amounts for reduction or waivers may be required by the school I am enrolled in.
                                    </li>
                                    <li>
                                        I agree that as part of procedures for recognition of eligibility for reduction or exemption of tuition, etc., the Tokyo University of Agriculture and Technology will, through the Japan Student Services Organization (hereinafter referred to as “the Organization”), receive information on me possessed and sent by the Organization related to scholarship grants, and that the Organization will receive information on me possessed and sent by the Tokyo University of Agriculture and Technology related to reduction or waivers of tuition, etc.
                                    </li>
                                    <li>
                                        I am currently not receiving reduction or waivers of tuition, etc. from other schools in accordance with the Act to Support Study at Universities, etc., and I am currently not in the process of applying for recognition of eligibility for such reduction or waivers of tuition, etc.
                                    </li>
                                </ListDsc>
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    isKakutei ? (
                                        <CheckboxGroup
                                            vertical={true}
                                        >
                                            <Checkbox
                                                checked={formValues.aYosiki1.agreement}
                                                label={AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.申請にあたって私は以上の事項を確認し理解しています)}
                                            />
                                        </CheckboxGroup>
                                    ) : (
                                        <CheckboxGroup
                                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki1?.agreement)}
                                            vertical={true}
                                        >
                                            <Checkbox
                                                checked={formValues.aYosiki1.agreement}
                                                label={AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.申請にあたって私は以上の事項を確認し理解しています)}
                                                handleChange={() => handleChangeAgreement(!formValues.aYosiki1.agreement)}
                                                isError={isErrorMessage(errorMessages?.aYosiki1?.agreement)}
                                            />
                                        </CheckboxGroup>
                                    )
                                }
                            </Grid>

                        </Grid>

                    </FormTableRow>

                    <FormTableRow
                        label={AYosikiInfoLabelTextEnumToNameJaAndEn(AYosikiInfoLabelTextEnum.過去の本制度の支援状況)}
                    >
                        <FieldWithLabel
                            label={AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.過去に本制度の支援を受けたことがありますか)}
                            requiredType={RequiredTagTypeEnum.必須}
                        >
                            {
                                isKakutei ? (
                                    <Box>
                                        {formValues.aYosiki1.isSienzumi ? CommonTextEnum.ある : CommonTextEnum.ない}
                                    </Box>
                                ) : (
                                    <RadioButtonGroup
                                        errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki1?.isSienzumi)}
                                        vertical={true}
                                    >
                                        <RadioButton
                                            value={CommonTextEnumToNameJaAndEn(CommonTextEnum.ある)}
                                            checked={formValues.aYosiki1.isSienzumi === true}
                                            label={CommonTextEnumToNameJaAndEn(CommonTextEnum.ある)}
                                            isError={isErrorMessage(errorMessages?.aYosiki1?.isSienzumi)}
                                            handleChange={() => handleChangeIsSienzumi(true)}
                                        />
                                        <RadioButton
                                            value={CommonTextEnumToNameJaAndEn(CommonTextEnum.ない)}
                                            checked={formValues.aYosiki1.isSienzumi === false}
                                            label={CommonTextEnumToNameJaAndEn(CommonTextEnum.ない)}
                                            isError={isErrorMessage(errorMessages?.aYosiki1?.isSienzumi)}
                                            handleChange={() => handleChangeIsSienzumi(false)}
                                        />
                                    </RadioButtonGroup>
                                )
                            }
                        </FieldWithLabel>
                    </FormTableRow>

                    {
                        formValues.aYosiki1.isSienzumi ?

                            <FormTableRow
                                label={AYosikiInfoLabelTextEnumToNameJaAndEn(AYosikiInfoLabelTextEnum.過去に本制度の支援を受けた学校名期間該当者のみ入力)}
                            >

                                <Grid container spacing={1}>

                                    <Grid item xs={12}>
                                        <FieldWithLabel
                                            label={AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.学校名)}
                                            requiredType={RequiredTagTypeEnum.必須}
                                            width={"160px"}
                                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki1?.sienzumi?.schoolName)}
                                        >
                                            {
                                                isKakutei ? (
                                                    <Box>
                                                        {formValues.aYosiki1.sienzumi.schoolName}
                                                    </Box>
                                                ) : (
                                                    <TextField
                                                        value={formValues.aYosiki1.sienzumi.schoolName}
                                                        handleBlur={(value) => handleBlurSienzumiSchoolName(value)}
                                                        isError={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki1?.sienzumi?.schoolName).length !== 0}
                                                    />
                                                )
                                            }
                                        </FieldWithLabel>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FieldWithLabel
                                            label={AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.期間)}
                                            requiredType={RequiredTagTypeEnum.必須}
                                            errorMessages={convertErrorMessageVoToArrayStr(
                                                joinArraySwErrorMessage([
                                                    errorMessages?.aYosiki1?.sienzumi?.startYear,
                                                    errorMessages?.aYosiki1?.sienzumi?.startMonth,
                                                    errorMessages?.aYosiki1?.sienzumi?.endYear,
                                                    errorMessages?.aYosiki1?.sienzumi?.endMonth,
                                                ])
                                            )}
                                        >
                                            {
                                                isKakutei ? (
                                                    <Box
                                                        display={"flex"}
                                                        alignItems={"center"}
                                                        style={{gap: "8px"}}
                                                    >
                                                        <div>{formValues.aYosiki1.sienzumi.startYear}年</div>
                                                        <div>{formValues.aYosiki1.sienzumi.startMonth}月</div>
                                                        <div>〜</div>
                                                        <div>{formValues.aYosiki1.sienzumi.endYear}年</div>
                                                        <div>{formValues.aYosiki1.sienzumi.endMonth}月</div>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={matches ? "column" : "row"}
                                                        alignItems={matches ? "flex-start" : "center"}
                                                        style={{gap: "8px"}}
                                                        width={"100%"}
                                                    >
                                                        <YearMonth
                                                            prefixTextYear={"西暦"}
                                                            widthYear={"96px"}
                                                            widthMonth={"48px"}
                                                            valueYear={formValues.aYosiki1.sienzumi.startYear}
                                                            valueMonth={formValues.aYosiki1.sienzumi.startMonth}
                                                            isErrorYear={isErrorMessage(errorMessages?.aYosiki1?.sienzumi?.startYear)}
                                                            isErrorMonth={isErrorMessage(errorMessages?.aYosiki1?.sienzumi?.startMonth)}
                                                            handleBlurYear={(value) => handleBlurSienzumiStartYear(value)}
                                                            handleBlurMonth={(value) => handleBlurSienzumiStartMonth(value)}
                                                        />
                                                        <div>〜</div>
                                                        <YearMonth
                                                            prefixTextYear={"西暦"}
                                                            widthYear={"96px"}
                                                            widthMonth={"48px"}
                                                            valueYear={formValues.aYosiki1.sienzumi.endYear}
                                                            valueMonth={formValues.aYosiki1.sienzumi.endMonth}
                                                            isErrorYear={isErrorMessage(errorMessages?.aYosiki1?.sienzumi?.endYear)}
                                                            isErrorMonth={isErrorMessage(errorMessages?.aYosiki1?.sienzumi?.endMonth)}
                                                            handleBlurYear={(value) => handleBlurSienzumiEndYear(value)}
                                                            handleBlurMonth={(value) => handleBlurSienzumiEndMonth(value)}
                                                        />
                                                    </Box>
                                                )
                                            }
                                        </FieldWithLabel>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FieldWithLabel
                                            label={AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.月数)}
                                            requiredType={RequiredTagTypeEnum.必須}
                                        >
                                            {
                                                isKakutei ? (
                                                    <Box>
                                                        {formValues.aYosiki1.sienzumi.months && `${formValues.aYosiki1.sienzumi.months}月`}
                                                    </Box>
                                                ) : (
                                                    <NumberField
                                                        value={formValues.aYosiki1.sienzumi.months}
                                                        errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki1?.sienzumi?.months)}
                                                        isError={isErrorMessage(errorMessages?.aYosiki1?.sienzumi?.months)}
                                                        widthInput={"32px"}
                                                        suffixText={"月"}
                                                        handleBlur={handleBlurSienzumiMonths}
                                                    />
                                                )
                                            }
                                        </FieldWithLabel>
                                    </Grid>

                                </Grid>

                            </FormTableRow>
                            : null
                    }


                    <FormTableRow
                        label={AYosikiInfoLabelTextEnumToNameJaAndEn(AYosikiInfoLabelTextEnum.過去の本制度の入学金免除状況)}
                    >
                        <Grid container spacing={1}>

                            <Grid item xs={12}>
                                <Box fontWeight={700}>
                                    {AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.過去に本制度の入学金免除を受けたことがありますか)}<RequiredTag
                                    type={RequiredTagTypeEnum.必須}/>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    isKakutei ? (
                                        <Box>
                                            {formValues.aYosiki1.menjoBefore ? CommonTextEnum.ある : CommonTextEnum.ない}
                                        </Box>
                                    ) : (
                                        <RadioButtonGroup
                                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki1?.menjoBefore)}
                                            vertical={true}
                                        >
                                            <RadioButton
                                                value={""}
                                                checked={formValues.aYosiki1.menjoBefore === true}
                                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.ある)}
                                                isError={isErrorMessage(errorMessages?.aYosiki1?.menjoBefore)}
                                                handleChange={() => handleChangeMenjoBefore(true)}
                                            />
                                            <RadioButton
                                                value={""}
                                                checked={formValues.aYosiki1.menjoBefore === false}
                                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.ない)}
                                                isError={isErrorMessage(errorMessages?.aYosiki1?.menjoBefore)}
                                                handleChange={() => handleChangeMenjoBefore(false)}
                                            />
                                        </RadioButtonGroup>
                                    )
                                }
                            </Grid>

                        </Grid>
                    </FormTableRow>

                    <FormTableRow
                        label={AYosikiInfoLabelTextEnumToNameJaAndEn(AYosikiInfoLabelTextEnum.過去に本制度の入学金免除)}
                    >

                        <Grid container spacing={1}>

                            <Grid item xs={12}>
                                <Box fontWeight={700}>
                                    {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}<RequiredTag
                                    type={RequiredTagTypeEnum.必須}/>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    isKakutei ? (
                                        <Box>
                                            {formValues.aYosiki1.mosikomiType && (
                                                convertEnumNameToStr(mosikomiTypeEnumToName(formValues.aYosiki1.mosikomiType))
                                            )}
                                        </Box>
                                    ) : (
                                        <RadioButtonGroup
                                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki1?.mosikomiType)}
                                            vertical={true}
                                        >
                                            <RadioButton
                                                value={MosikomiTypeEnum.予約}
                                                checked={formValues.aYosiki1.mosikomiType === MosikomiTypeEnum.予約}
                                                label={convertEnumNameToStr(mosikomiTypeEnumToName(MosikomiTypeEnum.予約))}
                                                isError={isErrorMessage(errorMessages?.aYosiki1?.mosikomiType)}
                                                handleChange={handleChangeMosikomiType}
                                            />
                                            <RadioButton
                                                value={MosikomiTypeEnum.在学}
                                                checked={formValues.aYosiki1.mosikomiType === MosikomiTypeEnum.在学}
                                                label={convertEnumNameToStr(mosikomiTypeEnumToName(MosikomiTypeEnum.在学))}
                                                isError={isErrorMessage(errorMessages?.aYosiki1?.mosikomiType)}
                                                handleChange={handleChangeMosikomiType}
                                            />
                                        </RadioButtonGroup>
                                    )
                                }

                            </Grid>

                            {
                                formValues.aYosiki1.mosikomiType === MosikomiTypeEnum.予約 ?
                                    <Grid item xs={12}>
                                        <FieldWithLabel
                                            label={AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.受付番号)}
                                            requiredType={RequiredTagTypeEnum.任意}
                                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki1?.uketukeNumber)}
                                        >
                                            {
                                                isKakutei ? (
                                                    <Box>
                                                        {formValues.aYosiki1.uketukeNumber}
                                                    </Box>
                                                ) : (
                                                    <TextField
                                                        value={formValues.aYosiki1.uketukeNumber}
                                                        handleBlur={(value) => handleBlurUketukeNumber(value)}
                                                        isError={isErrorMessage(errorMessages?.aYosiki1?.uketukeNumber)}
                                                        widthInput={"120px"}
                                                    />
                                                )
                                            }
                                        </FieldWithLabel>
                                    </Grid>
                                    : null
                            }

                            {
                                formValues.aYosiki1.mosikomiType === MosikomiTypeEnum.在学 ?
                                    <Grid item xs={12}>
                                        <FieldWithLabel
                                            label={AYosikiInfoTextEnumToNameJaAndEn(AYosikiInfoTextEnum.奨学生番号)}
                                            requiredType={RequiredTagTypeEnum.任意}
                                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.aYosiki1?.syogakuseiNumber)}
                                        >
                                            {
                                                isKakutei ? (
                                                    <Box>
                                                        {formValues.aYosiki1.syogakuseiNumber}
                                                    </Box>
                                                ) : (
                                                    <TextField
                                                        value={formValues.aYosiki1.syogakuseiNumber}
                                                        handleBlur={(value) => handleBlurSyogakuseiNumber(value)}
                                                        isError={isErrorMessage(errorMessages?.aYosiki1?.syogakuseiNumber)}
                                                        widthInput={"120px"}
                                                    />
                                                )
                                            }
                                        </FieldWithLabel>
                                    </Grid>
                                    : null
                            }

                        </Grid>

                    </FormTableRow>

                </FormTable>

            </Grid>

        </Grid>
)
}

