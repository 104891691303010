/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwKikanErrorMessage
 */
export interface SwKikanErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwKikanErrorMessage
     */
    zengoKankei?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwKikanErrorMessage
     */
    startDate?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwKikanErrorMessage
     */
    startHour?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwKikanErrorMessage
     */
    startMinute?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwKikanErrorMessage
     */
    endDate?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwKikanErrorMessage
     */
    endHour?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwKikanErrorMessage
     */
    endMinute?: Array<SwErrorMessage>;
}

export function SwKikanErrorMessageFromJSON(json: any): SwKikanErrorMessage {
    return SwKikanErrorMessageFromJSONTyped(json, false);
}

export function SwKikanErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwKikanErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zengoKankei': !exists(json, 'zengoKankei') ? undefined : ((json['zengoKankei'] as Array<any>).map(SwErrorMessageFromJSON)),
        'startDate': !exists(json, 'startDate') ? undefined : ((json['startDate'] as Array<any>).map(SwErrorMessageFromJSON)),
        'startHour': !exists(json, 'startHour') ? undefined : ((json['startHour'] as Array<any>).map(SwErrorMessageFromJSON)),
        'startMinute': !exists(json, 'startMinute') ? undefined : ((json['startMinute'] as Array<any>).map(SwErrorMessageFromJSON)),
        'endDate': !exists(json, 'endDate') ? undefined : ((json['endDate'] as Array<any>).map(SwErrorMessageFromJSON)),
        'endHour': !exists(json, 'endHour') ? undefined : ((json['endHour'] as Array<any>).map(SwErrorMessageFromJSON)),
        'endMinute': !exists(json, 'endMinute') ? undefined : ((json['endMinute'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwKikanErrorMessageToJSON(value?: SwKikanErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zengoKankei': value.zengoKankei === undefined ? undefined : ((value.zengoKankei as Array<any>).map(SwErrorMessageToJSON)),
        'startDate': value.startDate === undefined ? undefined : ((value.startDate as Array<any>).map(SwErrorMessageToJSON)),
        'startHour': value.startHour === undefined ? undefined : ((value.startHour as Array<any>).map(SwErrorMessageToJSON)),
        'startMinute': value.startMinute === undefined ? undefined : ((value.startMinute as Array<any>).map(SwErrorMessageToJSON)),
        'endDate': value.endDate === undefined ? undefined : ((value.endDate as Array<any>).map(SwErrorMessageToJSON)),
        'endHour': value.endHour === undefined ? undefined : ((value.endHour as Array<any>).map(SwErrorMessageToJSON)),
        'endMinute': value.endMinute === undefined ? undefined : ((value.endMinute as Array<any>).map(SwErrorMessageToJSON)),
    };
}


