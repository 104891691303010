/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwCommonSyoruisSyorui
 */
export interface SwCommonSyoruisSyorui {
    /**
     * 
     * @type {string}
     * @memberof SwCommonSyoruisSyorui
     */
    nameJa: string;
    /**
     * 
     * @type {string}
     * @memberof SwCommonSyoruisSyorui
     */
    nameEn: string;
    /**
     * 
     * @type {string}
     * @memberof SwCommonSyoruisSyorui
     */
    urlJa: string;
    /**
     * 
     * @type {string}
     * @memberof SwCommonSyoruisSyorui
     */
    urlEn: string;
}

export function SwCommonSyoruisSyoruiFromJSON(json: any): SwCommonSyoruisSyorui {
    return SwCommonSyoruisSyoruiFromJSONTyped(json, false);
}

export function SwCommonSyoruisSyoruiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwCommonSyoruisSyorui {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nameJa': json['nameJa'],
        'nameEn': json['nameEn'],
        'urlJa': json['urlJa'],
        'urlEn': json['urlEn'],
    };
}

export function SwCommonSyoruisSyoruiToJSON(value?: SwCommonSyoruisSyorui | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nameJa': value.nameJa,
        'nameEn': value.nameEn,
        'urlJa': value.urlJa,
        'urlEn': value.urlEn,
    };
}


