import {Severity} from "@sentry/types";
import {URL_PATHS_OFFICER} from "common/constant/URL_PATHS_OFFICER";
import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {AuthTypeEnum} from "common/enums/AuthTypeEnum";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {sentryLog} from "utilities/sentryLog";

export function handleCommonError(res: any, authType: AuthTypeEnum) {
    switch (res.status) {
        case 401:
            console.log(res);
            if (authType === AuthTypeEnum.student){
                window.location.assign(URL_PATHS_STUDENT.login);
            }
            if (authType === AuthTypeEnum.officer){
                window.location.assign(URL_PATHS_OFFICER.login);
            }
            return;
        default:
            console.log(res);
            sentryLog("common error", res, Severity.Error);
            alert(CommonTextEnumToNameJaAndEn(CommonTextEnum.申し訳ありません問題が発生しました));
            return;
    }
}