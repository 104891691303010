import makeStyles from "@material-ui/core/styles/makeStyles";
import {BekkyoVo} from "common/models/YosikiInfoModels";
import {
    TokubetuKojoLabelTextEnum,
    TokubetuKojoLabelTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/TokubetuKojoLabelTextEnum";
import {
    TitleNameTextEnum,
    TitleNameTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/TitleNameTextEnum";
import {Title05} from "components/atoms/titles/Title05";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {FC} from "react";
import {convertStrToNumOrUndefined} from "common/functions/type_converter/convertStrToNumOrUndefined";

interface TokubetuKojoBekkyoDetailProps {
    bekkyo: BekkyoVo
}

export const TokubetuKojoBekkyoDetail: FC<TokubetuKojoBekkyoDetailProps> = (props) => {

    const classes = useStyles();

    const bekkyo: BekkyoVo = props.bekkyo;

    return (

        <>
            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.主たる家計支持者別居の世帯)}
                className={classes.title}
            />

            <FormTable
                className={"section_table"}
            >
                <FormTableRow
                    label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.主たる家計支持者別居の世帯)}
                >
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.理由)}
                        </div>
                        {bekkyo.riyu}
                    </div>
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.カ月当たり住居光熱費等)}
                        </div>
                        {convertStrToNumOrUndefined(bekkyo.amountPerMonth)?.toLocaleString()}円
                    </div>
                </FormTableRow>

            </FormTable>
        </>

    )
};

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        }
    }
);

