/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwStudentRow
 */
export interface SwStudentRow {
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    numberType: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    studentNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    nameKana: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    age: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    gakubu: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    gakka: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    studentKubun: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    ryugakuseiKubun: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SwStudentRow
     */
    nyugakuOn: string;
}

export function SwStudentRowFromJSON(json: any): SwStudentRow {
    return SwStudentRowFromJSONTyped(json, false);
}

export function SwStudentRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwStudentRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberType': json['numberType'],
        'studentNumber': json['studentNumber'],
        'name': json['name'],
        'nameKana': json['nameKana'],
        'age': json['age'],
        'gakubu': json['gakubu'],
        'gakka': json['gakka'],
        'studentKubun': json['studentKubun'],
        'ryugakuseiKubun': json['ryugakuseiKubun'],
        'email': json['email'],
        'nyugakuOn': json['nyugakuOn'],
    };
}

export function SwStudentRowToJSON(value?: SwStudentRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberType': value.numberType,
        'studentNumber': value.studentNumber,
        'name': value.name,
        'nameKana': value.nameKana,
        'age': value.age,
        'gakubu': value.gakubu,
        'gakka': value.gakka,
        'studentKubun': value.studentKubun,
        'ryugakuseiKubun': value.ryugakuseiKubun,
        'email': value.email,
        'nyugakuOn': value.nyugakuOn,
    };
}


