import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import React, {FC} from 'react';
import {TextColorsEnum} from "common/enums_value/ColorsEnum";
import {ErrorMessages} from "../../atoms/ErrorMessages";

interface CheckboxGroupProps {
    className?: string
    top?: string;
    helperText?: string;
    errorMessages?: Array<string>;
    vertical?: boolean
}

export const CheckboxGroup: FC<CheckboxGroupProps> = (props) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const classes = useStyles();
    return (
        <>
            <div
                className={`${classes.root} ${props.vertical && classes.vertical} ${props.className} ${matches ? classes.xsRoot : ""}`}
                style={{top: props.top}}
            >
                {props.children}
            </div>

            {
                props.errorMessages !== undefined && props.errorMessages!!.length !== 0 ?
                    <ErrorMessages
                        className={classes.errorMessages}
                        errorMessages={props.errorMessages}
                    />
                    : null
            }

            {
                props.helperText &&
                <Typography className={classes.helperText}>
                    {props.helperText}
                </Typography>
            }
        </>


    )
};

CheckboxGroup.defaultProps = {
    className: "",
    vertical: false
};

const useStyles = makeStyles({

    root: {
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
        "& .MuiFormControlLabel-root": {
            display: "block",
        },
        "& > *:not(:first-child)": {
            marginLeft: 8,
        },
    },
    vertical: {
        display: "block",
        "& > *:not(:first-child)": {
            marginLeft: 0,
        },
    },
    xsRoot: {
        display: "block",
        "& > *:not(:first-child)": {
            marginLeft: 0,
        },
    },
    errorMessages: {
        marginTop: 4,
    },
    helperText: {
        marginTop: 4,
        color: TextColorsEnum.Green,
        fontSize: 12
    }

});


