/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 要領の内容
 * @export
 * @interface SyoruiConfirmationYoryos
 */
export interface SyoruiConfirmationYoryos {
    /**
     * 
     * @type {string}
     * @memberof SyoruiConfirmationYoryos
     */
    ja: string;
    /**
     * 
     * @type {string}
     * @memberof SyoruiConfirmationYoryos
     */
    en: string;
}

export function SyoruiConfirmationYoryosFromJSON(json: any): SyoruiConfirmationYoryos {
    return SyoruiConfirmationYoryosFromJSONTyped(json, false);
}

export function SyoruiConfirmationYoryosFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyoruiConfirmationYoryos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ja': json['ja'],
        'en': json['en'],
    };
}

export function SyoruiConfirmationYoryosToJSON(value?: SyoruiConfirmationYoryos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ja': value.ja,
        'en': value.en,
    };
}


