// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum TyokiryoyoTypeEnum {
    入院 = "E入院",
    通院 = "E通院",
    自宅 = "E自宅",
}

export function tyokiryoyoTypeEnumToName(e: TyokiryoyoTypeEnum): { ja: string, en: string } {
    switch (e) {
        case TyokiryoyoTypeEnum.入院: return { ja: "入院", en: "Hospitalization" };
        case TyokiryoyoTypeEnum.通院: return { ja: "通院", en: "Outpatient treatment" };
        case TyokiryoyoTypeEnum.自宅: return { ja: "自宅療養", en: "Home treatment" };
    }
}