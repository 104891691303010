import {Button} from "components/atoms/Button";
import React, {FC, useCallback, useEffect, useState} from "react";
import {Modal} from "components/atoms/Modal";
import DialogActions from "@material-ui/core/DialogActions";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "../../../../common/enums_text/CommonTextEnum";
import {NumberField} from "../../../atoms/forms/NumberField";
import {SyunyuKubunEnum, syunyuKubunEnumToName} from "../../../../common/enums/SyunyuKubunEnum";
import {toStrJaEn} from "../../../../common/functions/converter/toStrJaEn";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import {SyunyuInfoVo} from "../../../../common/models/YosikiInfoModels";
import lodash from 'lodash';
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "../../../../common/functions/isErrorMessage";
import {SwSyunyuInfoErrorMessages} from "../../../../openapi";

interface ItemProps {
    mt?: number
    label: string
    note?: string
}

const FieldItem: FC<ItemProps> = ({label, mt, note, children}) => {
    return (
        <Box mt={mt}>
            <Box mt={0.5} fontWeight={700}>{label}</Box>
            <Box mt={0.5}>
                {children}
            </Box>
            {note ? <Box mt={0.5}>{note}</Box> : null}
        </Box>
    )
}


interface Props {
    openState: boolean
    handleCloseModal: () => void
    handleConfirm: (v: SyunyuInfoVo, errorMessages?: SwSyunyuInfoErrorMessages) => void
    syunyuInfo: SyunyuInfoVo
    syunyuInfoErrorMessages?: SwSyunyuInfoErrorMessages
}

export const GensentyosyuFieldModal: FC<Props> = ({openState, handleCloseModal, handleConfirm, syunyuInfo, syunyuInfoErrorMessages}) => {

    const [formValues, updateFormValues] = useState<SyunyuInfoVo>()
    const [errorMessages, updateErrorMessages] = useState<SwSyunyuInfoErrorMessages>()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    useEffect(() => {
        if (openState) {
            // NOTE: 参照しないようにオブジェクトをclone
            const copiedSyunyuInfo = lodash.cloneDeep(syunyuInfo) as SyunyuInfoVo;
            const copiedErrorMessages = lodash.cloneDeep(syunyuInfoErrorMessages) as SwSyunyuInfoErrorMessages;
            // NOTE: 初期化
            updateFormValues(copiedSyunyuInfo)
            updateErrorMessages(copiedErrorMessages)
        }
    }, [openState, syunyuInfo, syunyuInfoErrorMessages])

    const updates = useCallback((formValues: SyunyuInfoVo, errorMessages?: SwSyunyuInfoErrorMessages) => {
        updateFormValues({...formValues})
        updateErrorMessages({...errorMessages})
    }, [updateFormValues, updateErrorMessages])

    const updateKyuyo = useCallback((v: string) => {
        if (!formValues) return
        formValues.kyuyo.field.amount = v
        if(errorMessages){
            errorMessages.kyuyo = undefined
        }
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    if (formValues === undefined) {
        return <></>
    }

    return (
        <Modal
            openState={openState}
            title={"源泉徴収票を元に収入・所得を入力 / Enter revenue and income based on withholding tax certificates"}
        >

            <Box mb={4}>源泉徴収票を用意し、画像を参考に金額を入力してください。 / Please prepare withholding tax
                certificates and enter the amount with reference to the image.</Box>

            <Grid container={true} spacing={4}>
                <Grid item xs={matches ? 12 : 7}>
                    <img src={`${process.env.PUBLIC_URL}/images/yosikiEdit/img_gensentyosyu.png`}
                         alt=""/>
                </Grid>
                <Grid item xs={matches ? 12 : 5}>
                    <FieldItem
                        label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.給料賃金))}
                        note={"①の金額 / Amount of ①"}
                    >
                        <NumberField
                            widthInput={"120px"}
                            value={formValues.kyuyo.field.amount}
                            handleBlur={(v) => updateKyuyo(v)}
                            suffixText={"円"}
                            isError={isErrorMessage(errorMessages?.kyuyo)}
                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.kyuyo)}
                        />
                    </FieldItem>

                </Grid>
            </Grid>

            <Box textAlign={"center"} mt={4}>
                「確定」を押すと入力内容が反映されます。「閉じる」を押すと入力内容は反映されません。<br/>
                Click "Confirm" to apply the information you have entered. If you click "Close", the entered information
                will not be reflected.
            </Box>

            <DialogActions className={"modal_actions"}>

                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.閉じる)}
                    width={"120px"}
                    handleClick={handleCloseModal}
                />

                <Button
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                    width={"120px"}
                    handleClick={() => handleConfirm(formValues, errorMessages)}
                />

            </DialogActions>

        </Modal>
    )
}

