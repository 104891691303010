/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    SwStudentLoginRequest,
    SwStudentLoginRequestFromJSON,
    SwStudentLoginRequestToJSON,
} from '../models';

export interface PostStudentLoginRequest {
    swStudentLoginRequest: SwStudentLoginRequest;
}

/**
 * no description
 */
export class StudentLoginApi extends runtime.BaseAPI {

    /**
     * 学生のログイン
     */
    async postStudentLoginRaw(requestParameters: PostStudentLoginRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.swStudentLoginRequest === null || requestParameters.swStudentLoginRequest === undefined) {
            throw new runtime.RequiredError('swStudentLoginRequest','Required parameter requestParameters.swStudentLoginRequest was null or undefined when calling postStudentLogin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/student/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwStudentLoginRequestToJSON(requestParameters.swStudentLoginRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * 学生のログイン
     */
    async postStudentLogin(requestParameters: PostStudentLoginRequest): Promise<InlineResponse200> {
        const response = await this.postStudentLoginRaw(requestParameters);
        return await response.value();
    }

}
