/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwKikanErrorMessage,
    SwKikanErrorMessageFromJSON,
    SwKikanErrorMessageFromJSONTyped,
    SwKikanErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwKikansErrorMessage
 */
export interface SwKikansErrorMessage {
    /**
     * 
     * @type {SwKikanErrorMessage}
     * @memberof SwKikansErrorMessage
     */
    nyuryokuKikan?: SwKikanErrorMessage;
    /**
     * 
     * @type {SwKikanErrorMessage}
     * @memberof SwKikansErrorMessage
     */
    sinseiKikan?: SwKikanErrorMessage;
    /**
     * 
     * @type {SwKikanErrorMessage}
     * @memberof SwKikansErrorMessage
     */
    teiseiKikan?: SwKikanErrorMessage;
}

export function SwKikansErrorMessageFromJSON(json: any): SwKikansErrorMessage {
    return SwKikansErrorMessageFromJSONTyped(json, false);
}

export function SwKikansErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwKikansErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nyuryokuKikan': !exists(json, 'nyuryokuKikan') ? undefined : SwKikanErrorMessageFromJSON(json['nyuryokuKikan']),
        'sinseiKikan': !exists(json, 'sinseiKikan') ? undefined : SwKikanErrorMessageFromJSON(json['sinseiKikan']),
        'teiseiKikan': !exists(json, 'teiseiKikan') ? undefined : SwKikanErrorMessageFromJSON(json['teiseiKikan']),
    };
}

export function SwKikansErrorMessageToJSON(value?: SwKikansErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nyuryokuKikan': SwKikanErrorMessageToJSON(value.nyuryokuKikan),
        'sinseiKikan': SwKikanErrorMessageToJSON(value.sinseiKikan),
        'teiseiKikan': SwKikanErrorMessageToJSON(value.teiseiKikan),
    };
}


