import {createStyles} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {DeleteForever} from "@material-ui/icons";
import {UploadedFilesVo} from "common/models/UploadedFilesVo";
import React, {FC} from 'react';

interface FileListProps {
    uploadedFiles: Array<UploadedFilesVo>
    handleDeleteFile?: (index: number) => void
    disableEdit?: boolean
}

export const UploadedFileList: FC<FileListProps> = (props) => {

    const classes = useStyles();

    if (props.uploadedFiles.length === 0) return <></>;

    function handleDeleteFile(index: number) {
        props.handleDeleteFile && props.handleDeleteFile(index)
    }

    return (
        <div className={classes.root}>
            {
                props.uploadedFiles.map((u, index) => {
                    return (
                        <div className={classes.fileList} key={u.id + index}>
                            <a href={process.env.PUBLIC_URL + u.url} target="_blank" rel="noopener noreferrer">{u.name}</a>
                            {
                                !props.disableEdit ?
                                    <div onClick={() => handleDeleteFile(index)}><DeleteForever className={'icon'}/>
                                    </div>
                                    : null
                            }
                        </div>
                    )
                })
            }
        </div>
    )
};

const useStyles = makeStyles(() => createStyles({
    root: {
        marginBottom: 8,
        "& > *:not(:first-child)": {
            marginTop: 4
        }
    },
    fileList: {
        display: "flex",
        "& .icon": {
            cursor: "pointer"
        }
    }
}));
