import {ListDsc} from "components/atoms/ListDsc";
import React from 'react';

export const ModalContentKyuyoTinginRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                以下の金額を記入すること
                <ListDsc className={"mt8"}>
                    <li><span className={"underLine"}>源泉徴収票</span>の支払金額(本人のアルバイトの場合も含む) <span
                        className={"fw700"}>→ 下の例1を参照</span></li>
                    <li><span className={"underLine"}>給与見込証明書(様式6)</span>の給与・賞与支給見込額</li>
                    <li><span className={"underLine"}>アルバイト証明書(様式5)</span>の給与(見込)年額</li>
                    <li><span className={"underLine"}>確定申告書(控)</span>の給与の収入金額</li>
                </ListDsc>
                <div className={"mt8"}>
                    注)専従者給与、役員報酬、日本学術振興会研究 奨励金、TA・RA手当もこの給与・賃金欄に記入すること。
                </div>
                <div className={"mt8"}>
                    <img src={`${process.env.PUBLIC_URL}/images/yosikiEdit/wordDescription/img_kyuyoTinginRan_01.png`}
                         alt=""/>
                </div>
            </div>

            <div className={"modalContent_item"}>
                Enter the amount as indicated below.
                <ListDsc className={"mt8"}>
                    <li>Amount of payment indicated on Certificate of Withholding (including cases for the applicant’s
                        part-time job)  Refer to Example (1) below.
                    </li>
                    <li>Expected amounts of wages and bonuses indicated on Certificate of Expected Wages (Form 6).</li>
                    <li>Annual wages (expected) indicated on Part-time Job Certificate (Form 5).</li>
                    <li>Amount of income from wages indicated on Tax Return (Taxpayer’s Copy).</li>
                </ListDsc>
                Note) Pay for family employees, remuneration for directors, research incentives from the Japan Society
                for the Promotion of Science, and TA/RA allowances must also be entered in this “Salary and Wages”
                field.
                <div className={"mt8"}>
                    <img src={`${process.env.PUBLIC_URL}/images/yosikiEdit/wordDescription/img_kyuyoTinginRan_01_en.png`}
                         alt=""/>
                </div>
            </div>

        </div>

    );
};
