import React, {useCallback, useMemo} from 'react';
import {
    SwSyoruiConfirmationFormErrorMessageResponse,
    SyoruiConfirmationFormMemberErrorMessage
} from "../../../../openapi";
import {
    SyoruiConfirmationFormMemberVo,
    SyoruiConfirmationFormVo
} from "../../../../common/models/SyoruiConfirmationFormModels";
import {Title04} from "../../../atoms/titles/Title04";
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import {RadioButton} from "../../../atoms/forms/RadioButton";
import {RadioButtonGroup} from "../../forms/RadioButtonGroup";
import {CommonTextEnum} from "../../../../common/enums_text/CommonTextEnum";
import Grid from "@material-ui/core/Grid";
import {FormItem} from "../FormItem";
import {ZokugaraEnum, zokugaraEnumToName} from "../../../../common/enums/ZokugaraEnum";
import {convertEnumNameToStr} from "../../../../common/functions/converter/convertEnumNameToStr";
import {Box} from "@material-ui/core";
import { getGlobalSyoriki } from 'common/functions/globalSyoriki';
import { GakkiEnum } from 'common/enums/GakkiEnum';

interface Props {
    index: number
    member: SyoruiConfirmationFormMemberVo
    formValues: SyoruiConfirmationFormVo | undefined
    errorMessages: SwSyoruiConfirmationFormErrorMessageResponse | undefined
    updates: (formValues: SyoruiConfirmationFormVo, errorMessages?: SwSyoruiConfirmationFormErrorMessageResponse | undefined) => void
    isSyoruiHantei: boolean
    isNyugakuryo: boolean
    isJugyoryo: boolean
}

export const MemberForm: React.FC<Props> = ({
    index,
    member,
    formValues,
    errorMessages,
    updates,
    isSyoruiHantei,
    isNyugakuryo,
    isJugyoryo
}) => {

    const memberErrorMessages = useMemo(() => {
        return errorMessages?.members?.[index]
    }, [errorMessages, index])

    const updateMembers = useCallback((member: SyoruiConfirmationFormMemberVo, error: SyoruiConfirmationFormMemberErrorMessage | undefined) => {
        if (formValues === undefined) return
        formValues?.members.splice(index, 1, member);
        errorMessages?.members?.splice(index, 1, {...error});
        updates(formValues, {...errorMessages})
    }, [formValues, errorMessages, index, updates])

    const updateIsJuminhyoDifference = useCallback((v: boolean) => {
        member.isJuminhyoDifference = v
        updateMembers(member, {...memberErrorMessages, isJuminhyoDifference: undefined})
    }, [member, updateMembers, memberErrorMessages])

    const updateIsMusyokusya = useCallback((v: boolean) => {
        member.isMusyokusya = v
        updateMembers(member, {
            ...memberErrorMessages,
            isMusyokusya: undefined,
            isPlanToFindJob: undefined,
            isKakeisijisya: undefined,
            isTaisyokuJikiForMusyokusya: undefined,
            isFinancialAssistanceFromRelatives: undefined,
            isIncomeFromInsuranceMoney: undefined,
            isRinjiIncome: undefined,
            isTaisyokuJiki: undefined,
        })
    }, [member, updateMembers, memberErrorMessages])

    // 無職者 === true
    const updateIsPlanToFindJob = useCallback((v: boolean) => {
        member.isPlanToFindJob = v
        updateMembers(member, {...memberErrorMessages, isPlanToFindJob: undefined})
    }, [member, updateMembers, memberErrorMessages])

    const updateIsKakeisijisya = useCallback((v: boolean) => {
        member.isKakeisijisya = v
        updateMembers(member, {...memberErrorMessages, isKakeisijisya: undefined})
    }, [member, updateMembers, memberErrorMessages])

    const updateIsTaisyokuJikiForMusyokusya = useCallback((v: boolean) => {
        member.isTaisyokuJikiForMusyokusya = v
        updateMembers(member, {...memberErrorMessages, isTaisyokuJikiForMusyokusya: undefined})
    }, [member, updateMembers, memberErrorMessages])

    // 無職者 === false
    const updateIsFinancialAssistanceFromRelatives = useCallback((v: boolean) => {
        member.isFinancialAssistanceFromRelatives = v
        updateMembers(member, {...memberErrorMessages, isFinancialAssistanceFromRelatives: undefined})
    }, [member, updateMembers, memberErrorMessages])

    const updateIsIncomeFromInsuranceMoney = useCallback((v: boolean) => {
        member.isIncomeFromInsuranceMoney = v
        updateMembers(member, {...memberErrorMessages, isIncomeFromInsuranceMoney: undefined})
    }, [member, updateMembers, memberErrorMessages])

    const updateIsRinjiIncome = useCallback((v: boolean) => {
        member.isRinjiIncome = v
        updateMembers(member, {...memberErrorMessages, isRinjiIncome: undefined})
    }, [member, updateMembers, memberErrorMessages])

    const updateIsTaisyokuJiki = useCallback((v: boolean) => {
        member.isTaisyokuJiki = v
        updateMembers(member, {...memberErrorMessages, isTaisyokuJiki: undefined})
    }, [member, updateMembers, memberErrorMessages])


    // 自動計算
    // https://confluence.mmj.ne.jp/pages/viewpage.action?pageId=127704794
    //
    // 入学料/前期の場合
    // (処理期年度 - 1)年4月
    //
    // 入学料/後期の場合
    // (処理期年度 - 1)年10月
    //
    // 授業料/前期の場合
    // (処理期年度 - 1)年10月
    //
    // 授業料/後期の場合
    // (処理期年度)年4月
    //
    // 入学料と授業料併用の場合は、入学料を採用
    const syoriki = getGlobalSyoriki()
    const y = syoriki.yearModel
    let monthJa = ""
    let monthEn = ""
    if (isNyugakuryo && syoriki.gakki == GakkiEnum.前期) {
        monthJa = `${y.dec().和暦年}4月`
        monthEn = `April ${y.dec().西暦年}`
    } else if (isNyugakuryo && syoriki.gakki == GakkiEnum.後期) {
        monthJa = `${y.dec().和暦年}10月`
        monthEn = `October ${y.dec().西暦年}`
    } else if (isJugyoryo && syoriki.gakki == GakkiEnum.前期) {
        monthJa = `${y.dec().和暦年}10月`
        monthEn = `October ${y.dec().西暦年}`
    } else if (isJugyoryo && syoriki.gakki == GakkiEnum.後期) {
        monthJa = `${y.和暦年}4月`
        monthEn = `April ${y.西暦年}`
    }

    return (
        <Grid container spacing={3} direction={"column"}>

            <Grid item xs>
                <Title04
                    title={`${member.name} (${member.zokugara === ZokugaraEnum.その他 ? member.zokugaraOther : convertEnumNameToStr(zokugaraEnumToName(member.zokugara))})`}
                />
            </Grid>

            <FormItem
                label={"住民票の記載事項と実態が異なりますか？(例:住民票を移さずに別居別生計をしている) / Is there a difference between the information on your certificate of residence and your actual situation? (Example: Living separately and making a separate livelihood without transferring the certificate of residence)"}
            >
                {
                    isSyoruiHantei ? (
                        <Box>
                            {member?.isJuminhyoDifference ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                        </Box>
                    ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isJuminhyoDifference) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={member?.isJuminhyoDifference === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isJuminhyoDifference).length !== 0 : false}
                                handleChange={() => {
                                    updateIsJuminhyoDifference(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={member?.isJuminhyoDifference === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isJuminhyoDifference).length !== 0 : false}
                                handleChange={() => {
                                    updateIsJuminhyoDifference(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

            <FormItem
                label={"無職者ですか？(年金受給者、障害者、長期療養者は含みません) / Are you an unemployed person? (Does not include pensioners, persons with disabilities, and long-term care patients)"}
            >
                {
                    isSyoruiHantei ? (
                        <Box>
                            {member?.isMusyokusya ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                        </Box>
                    ) : (
                        <RadioButtonGroup
                            errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isMusyokusya) : []}
                        >
                            <RadioButton
                                value={""}
                                checked={member?.isMusyokusya === true}
                                label={CommonTextEnum.はい}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isMusyokusya).length !== 0 : false}
                                handleChange={() => {
                                    updateIsMusyokusya(true)
                                }}
                            />
                            <RadioButton
                                value={""}
                                checked={member?.isMusyokusya === false}
                                label={CommonTextEnum.いいえ}
                                isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isMusyokusya).length !== 0 : false}
                                handleChange={() => {
                                    updateIsMusyokusya(false)
                                }}
                            />
                        </RadioButtonGroup>
                    )
                }
            </FormItem>

            {

                member.isMusyokusya === true && (

                    <>
                        {/* NOTE: 無職者 === true */}
                        <FormItem
                            label={"今後、就職する予定はありますか？ / Do you plan to find employment in the future?"}
                            indent={3}
                        >
                            {
                                isSyoruiHantei ? (
                                    <Box>
                                        {member?.isPlanToFindJob ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                    </Box>
                                ) : (
                                    <RadioButtonGroup
                                        errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isPlanToFindJob) : []}
                                    >
                                        <RadioButton
                                            value={""}
                                            checked={member?.isPlanToFindJob === true}
                                            label={CommonTextEnum.はい}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isPlanToFindJob).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsPlanToFindJob(true)
                                            }}
                                        />
                                        <RadioButton
                                            value={""}
                                            checked={member?.isPlanToFindJob === false}
                                            label={CommonTextEnum.いいえ}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isPlanToFindJob).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsPlanToFindJob(false)
                                            }}
                                        />
                                    </RadioButtonGroup>
                                )
                            }
                        </FormItem>

                        <FormItem
                            label={"家計支持者ですか？ / Are you the supporter of the household finances?"}
                            indent={3}
                        >
                            {
                                isSyoruiHantei ? (
                                    <Box>
                                        {member?.isKakeisijisya ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                    </Box>
                                ) : (
                                    <RadioButtonGroup
                                        errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isKakeisijisya) : []}
                                    >
                                        <RadioButton
                                            value={""}
                                            checked={member?.isKakeisijisya === true}
                                            label={CommonTextEnum.はい}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isKakeisijisya).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsKakeisijisya(true)
                                            }}
                                        />
                                        <RadioButton
                                            value={""}
                                            checked={member?.isKakeisijisya === false}
                                            label={CommonTextEnum.いいえ}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isKakeisijisya).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsKakeisijisya(false)
                                            }}
                                        />
                                    </RadioButtonGroup>
                                )
                            }
                        </FormItem>

                        <FormItem
                            label={`退職者の場合、退職時期は${monthJa}以降ですか？ / If you are a retired person, did you retire in ${monthEn} or later?`}
                            indent={3}
                        >
                            {
                                isSyoruiHantei ? (
                                    <Box>
                                        {member?.isTaisyokuJikiForMusyokusya ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                    </Box>
                                ) : (
                                    <RadioButtonGroup
                                        errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isTaisyokuJikiForMusyokusya) : []}
                                    >
                                        <RadioButton
                                            value={""}
                                            checked={member?.isTaisyokuJikiForMusyokusya === true}
                                            label={CommonTextEnum.はい}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isTaisyokuJikiForMusyokusya).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsTaisyokuJikiForMusyokusya(true)
                                            }}
                                        />
                                        <RadioButton
                                            value={""}
                                            checked={member?.isTaisyokuJikiForMusyokusya === false}
                                            label={CommonTextEnum.いいえ}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isTaisyokuJikiForMusyokusya).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsTaisyokuJikiForMusyokusya(false)
                                            }}
                                        />
                                    </RadioButtonGroup>
                                )
                            }
                        </FormItem>

                    </>

                )

            }

            {
                member.isMusyokusya === false && (

                    <>

                        {/* NOTE: 無職者 === false */}
                        <FormItem
                            label={"親族等からの金銭的援助を受けていますか？(借入金は含みません) / Do you receive financial assistance from relatives, etc.? (Does not include borrowing)"}
                            indent={3}
                        >
                            {
                                isSyoruiHantei ? (
                                    <Box>
                                        {member?.isFinancialAssistanceFromRelatives ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                    </Box>
                                ) : (
                                    <RadioButtonGroup
                                        errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isFinancialAssistanceFromRelatives) : []}
                                    >
                                        <RadioButton
                                            value={""}
                                            checked={member?.isFinancialAssistanceFromRelatives === true}
                                            label={CommonTextEnum.はい}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isFinancialAssistanceFromRelatives).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsFinancialAssistanceFromRelatives(true)
                                            }}
                                        />
                                        <RadioButton
                                            value={""}
                                            checked={member?.isFinancialAssistanceFromRelatives === false}
                                            label={CommonTextEnum.いいえ}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isFinancialAssistanceFromRelatives).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsFinancialAssistanceFromRelatives(false)
                                            }}
                                        />
                                    </RadioButtonGroup>
                                )
                            }
                        </FormItem>

                        <FormItem
                            label={`${monthJa}以降に保険金による所得がありましたか？ / Did you receive any income from insurance money ${monthEn} or later?`}
                            indent={3}
                        >
                            {
                                isSyoruiHantei ? (
                                    <Box>
                                        {member?.isIncomeFromInsuranceMoney ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                    </Box>
                                ) : (
                                    <RadioButtonGroup
                                        errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isIncomeFromInsuranceMoney) : []}
                                    >
                                        <RadioButton
                                            value={""}
                                            checked={member?.isIncomeFromInsuranceMoney === true}
                                            label={CommonTextEnum.はい}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isIncomeFromInsuranceMoney).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsIncomeFromInsuranceMoney(true)
                                            }}
                                        />
                                        <RadioButton
                                            value={""}
                                            checked={member?.isIncomeFromInsuranceMoney === false}
                                            label={CommonTextEnum.いいえ}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isIncomeFromInsuranceMoney).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsIncomeFromInsuranceMoney(false)
                                            }}
                                        />
                                    </RadioButtonGroup>
                                )
                            }
                        </FormItem>

                        <FormItem
                            label={`${monthJa}以降に臨時所得(譲渡所得、山林所得等)がありましたか？ / Did you receive any extraordinary income (capital gains, forestry income, etc.) ${monthEn} or later?`}
                            indent={3}
                        >
                            {
                                isSyoruiHantei ? (
                                    <Box>
                                        {member?.isRinjiIncome ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                    </Box>
                                ) : (
                                    <RadioButtonGroup
                                        errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isRinjiIncome) : []}
                                    >
                                        <RadioButton
                                            value={""}
                                            checked={member?.isRinjiIncome === true}
                                            label={CommonTextEnum.はい}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isRinjiIncome).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsRinjiIncome(true)
                                            }}
                                        />
                                        <RadioButton
                                            value={""}
                                            checked={member?.isRinjiIncome === false}
                                            label={CommonTextEnum.いいえ}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isRinjiIncome).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsRinjiIncome(false)
                                            }}
                                        />
                                    </RadioButtonGroup>
                                )
                            }
                        </FormItem>

                        <FormItem
                            label={`退職者の場合、退職時期は${monthJa}以降ですか？ / If you are a retired person, when did you retire ${monthEn} or later?`}
                            indent={3}
                        >
                            {
                                isSyoruiHantei ? (
                                    <Box>
                                        {member?.isTaisyokuJiki ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                    </Box>
                                ) : (
                                    <RadioButtonGroup
                                        errorMessages={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isTaisyokuJiki) : []}
                                    >
                                        <RadioButton
                                            value={""}
                                            checked={member?.isTaisyokuJiki === true}
                                            label={CommonTextEnum.はい}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isTaisyokuJiki).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsTaisyokuJiki(true)
                                            }}
                                        />
                                        <RadioButton
                                            value={""}
                                            checked={member?.isTaisyokuJiki === false}
                                            label={CommonTextEnum.いいえ}
                                            isError={errorMessages ? convertErrorMessageVoToArrayStr(memberErrorMessages?.isTaisyokuJiki).length !== 0 : false}
                                            handleChange={() => {
                                                updateIsTaisyokuJiki(false)
                                            }}
                                        />
                                    </RadioButtonGroup>
                                )
                            }
                        </FormItem>
                    </>

                )

            }

        </Grid>
    )
}
