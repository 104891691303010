/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SyoruiConfirmationFormHonninRyugakuseiErrorMessage
 */
export interface SyoruiConfirmationFormHonninRyugakuseiErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninRyugakuseiErrorMessage
     */
    isEnrolledInJapaneseUniversityBefore?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninRyugakuseiErrorMessage
     */
    isJapaneseHaiguusyaHasIncome?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninRyugakuseiErrorMessage
     */
    isPartTimeJob?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SyoruiConfirmationFormHonninRyugakuseiErrorMessage
     */
    isFinancialAssistanceFromRelatives?: Array<SwErrorMessage>;
}

export function SyoruiConfirmationFormHonninRyugakuseiErrorMessageFromJSON(json: any): SyoruiConfirmationFormHonninRyugakuseiErrorMessage {
    return SyoruiConfirmationFormHonninRyugakuseiErrorMessageFromJSONTyped(json, false);
}

export function SyoruiConfirmationFormHonninRyugakuseiErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyoruiConfirmationFormHonninRyugakuseiErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEnrolledInJapaneseUniversityBefore': !exists(json, 'isEnrolledInJapaneseUniversityBefore') ? undefined : ((json['isEnrolledInJapaneseUniversityBefore'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isJapaneseHaiguusyaHasIncome': !exists(json, 'isJapaneseHaiguusyaHasIncome') ? undefined : ((json['isJapaneseHaiguusyaHasIncome'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isPartTimeJob': !exists(json, 'isPartTimeJob') ? undefined : ((json['isPartTimeJob'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isFinancialAssistanceFromRelatives': !exists(json, 'isFinancialAssistanceFromRelatives') ? undefined : ((json['isFinancialAssistanceFromRelatives'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SyoruiConfirmationFormHonninRyugakuseiErrorMessageToJSON(value?: SyoruiConfirmationFormHonninRyugakuseiErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isEnrolledInJapaneseUniversityBefore': value.isEnrolledInJapaneseUniversityBefore === undefined ? undefined : ((value.isEnrolledInJapaneseUniversityBefore as Array<any>).map(SwErrorMessageToJSON)),
        'isJapaneseHaiguusyaHasIncome': value.isJapaneseHaiguusyaHasIncome === undefined ? undefined : ((value.isJapaneseHaiguusyaHasIncome as Array<any>).map(SwErrorMessageToJSON)),
        'isPartTimeJob': value.isPartTimeJob === undefined ? undefined : ((value.isPartTimeJob as Array<any>).map(SwErrorMessageToJSON)),
        'isFinancialAssistanceFromRelatives': value.isFinancialAssistanceFromRelatives === undefined ? undefined : ((value.isFinancialAssistanceFromRelatives as Array<any>).map(SwErrorMessageToJSON)),
    };
}


