import {SyunyuInfoVo} from "common/models/YosikiInfoModels";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {Button} from "components/atoms/Button";
import React, {FC, useCallback, useState} from "react";
import {Box} from "@material-ui/core";
import {TextColorsEnum} from "../../../../common/enums_value/ColorsEnum";
import Grid from "@material-ui/core/Grid";
import {KakuteisinkokuFieldModal} from "./KakuteisinkokuFieldModal";
import {ErrorMessages} from "../../../atoms/ErrorMessages";
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import {SwSyunyuInfoErrorMessages} from "../../../../openapi";

interface Props {
    syunyuInfo: SyunyuInfoVo
    updateSyunyuInfo: (syunyuInfo: SyunyuInfoVo) => void
    updateSyunyuInfoErrorMessages: (errorMessages: SwSyunyuInfoErrorMessages) => void
    syunyuInfoErrorMessages?: SwSyunyuInfoErrorMessages
}

export const KakuteisinkokuField: FC<Props> = ({syunyuInfo, updateSyunyuInfo, syunyuInfoErrorMessages, updateSyunyuInfoErrorMessages}) => {

    const [isModalOpen, updateIsModalOpen] = useState(false)

    const handleOpenModal = useCallback(() => {
        updateIsModalOpen(true)
        updateSyunyuInfoErrorMessages({...syunyuInfoErrorMessages, kakuteisinkokuField: undefined})
    }, [updateIsModalOpen, updateSyunyuInfoErrorMessages, syunyuInfoErrorMessages])

    const handleCloseModal = useCallback(() => {
        updateIsModalOpen(false)
    }, [updateIsModalOpen])

    const handleConfirm = useCallback(async (v: SyunyuInfoVo, errorMessages?: SwSyunyuInfoErrorMessages) => {
        updateSyunyuInfo(v)
        errorMessages && updateSyunyuInfoErrorMessages(errorMessages)
        updateIsModalOpen(false)
    }, [updateIsModalOpen, updateSyunyuInfo, updateSyunyuInfoErrorMessages])

    return (
        <>
            <Grid container spacing={1} direction={"column"}>
                <Grid item>
                    <Box color={TextColorsEnum.Red} fontWeight={700}>
                        {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.下の確定申告書を元に収入所得を入力から該当する項目についての金額を入力してください)}
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <Button
                            type={"primary"}
                            label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.確定申告書を元に収入所得を入力)}
                            handleClick={handleOpenModal}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <ErrorMessages
                            errorMessages={convertErrorMessageVoToArrayStr(syunyuInfoErrorMessages?.kakuteisinkokuField)}
                        />
                    </Box>
                </Grid>
            </Grid>
            <KakuteisinkokuFieldModal
                syunyuInfo={syunyuInfo}
                openState={isModalOpen}
                handleCloseModal={handleCloseModal}
                handleConfirm={handleConfirm}
                syunyuInfoErrorMessages={syunyuInfoErrorMessages}
            />
        </>
    )
}

