/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2007,
    InlineResponse2007FromJSON,
    InlineResponse2007ToJSON,
} from '../models';

/**
 * no description
 */
export class StudentSyoruiConfirmationFormHanteiKaijoApi extends runtime.BaseAPI {

    /**
     * 必要書類の確認機能の判定解除 
     */
    async postStudentSyoruiConfirmationFormHanteiKaijoRaw(): Promise<runtime.ApiResponse<InlineResponse2007>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/syorui_confirmation_form/hantei_kaijo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2007FromJSON(jsonValue));
    }

    /**
     * 必要書類の確認機能の判定解除 
     */
    async postStudentSyoruiConfirmationFormHanteiKaijo(): Promise<InlineResponse2007> {
        const response = await this.postStudentSyoruiConfirmationFormHanteiKaijoRaw();
        return await response.value();
    }

}
