// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum SyogaisyaTypeEnum {
    障害者 = "E障害者",
    原爆被爆者 = "E原爆被爆者",
    その他 = "Eその他",
}

export function syogaisyaTypeEnumToName(e: SyogaisyaTypeEnum): { ja: string, en: string } {
    switch (e) {
        case SyogaisyaTypeEnum.障害者: return { ja: "障害者", en: "Handicapped" };
        case SyogaisyaTypeEnum.原爆被爆者: return { ja: "原爆被爆者", en: "Atomic bomb victim" };
        case SyogaisyaTypeEnum.その他: return { ja: "その他", en: "Other" };
    }
}