/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    SwConvertJukenNumberRequest,
    SwConvertJukenNumberRequestFromJSON,
    SwConvertJukenNumberRequestToJSON,
} from '../models';

export interface PostOfficerConvertJukenNumberRequest {
    jukenNumber: string;
    swConvertJukenNumberRequest: SwConvertJukenNumberRequest;
}

/**
 * no description
 */
export class OfficerConvertJukenNumberApi extends runtime.BaseAPI {

    /**
     * 受験番号変換(個別)
     */
    async postOfficerConvertJukenNumberRaw(requestParameters: PostOfficerConvertJukenNumberRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.jukenNumber === null || requestParameters.jukenNumber === undefined) {
            throw new runtime.RequiredError('jukenNumber','Required parameter requestParameters.jukenNumber was null or undefined when calling postOfficerConvertJukenNumber.');
        }

        if (requestParameters.swConvertJukenNumberRequest === null || requestParameters.swConvertJukenNumberRequest === undefined) {
            throw new runtime.RequiredError('swConvertJukenNumberRequest','Required parameter requestParameters.swConvertJukenNumberRequest was null or undefined when calling postOfficerConvertJukenNumber.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/officer/convert_juken_number/{jukenNumber}`.replace(`{${"jukenNumber"}}`, encodeURIComponent(String(requestParameters.jukenNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwConvertJukenNumberRequestToJSON(requestParameters.swConvertJukenNumberRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * 受験番号変換(個別)
     */
    async postOfficerConvertJukenNumber(requestParameters: PostOfficerConvertJukenNumberRequest): Promise<InlineResponse200> {
        const response = await this.postOfficerConvertJukenNumberRaw(requestParameters);
        return await response.value();
    }

}
