// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum GakkiEnum {
    前期 = "E前期",
    後期 = "E後期",
}

export function gakkiEnumToName(e: GakkiEnum): { ja: string, en: string } {
    switch (e) {
        case GakkiEnum.前期: return { ja: "前期", en: "" };
        case GakkiEnum.後期: return { ja: "後期", en: "" };
    }
}