/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwMemberSyugakusya,
    SwMemberSyugakusyaFromJSON,
    SwMemberSyugakusyaFromJSONTyped,
    SwMemberSyugakusyaToJSON,
    SwMemberSyugakusyaIgai,
    SwMemberSyugakusyaIgaiFromJSON,
    SwMemberSyugakusyaIgaiFromJSONTyped,
    SwMemberSyugakusyaIgaiToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwMember
 */
export interface SwMember {
    /**
     * enum
     * @type {string}
     * @memberof SwMember
     */
    zokugara: string;
    /**
     * 
     * @type {string}
     * @memberof SwMember
     */
    zokugaraOther: string;
    /**
     * 
     * @type {boolean}
     * @memberof SwMember
     */
    isSyugakusya?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SwMember
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SwMember
     */
    age?: number;
    /**
     * 
     * @type {SwMemberSyugakusya}
     * @memberof SwMember
     */
    syugakusya?: SwMemberSyugakusya;
    /**
     * 
     * @type {SwMemberSyugakusyaIgai}
     * @memberof SwMember
     */
    syugakusyaIgai?: SwMemberSyugakusyaIgai;
}

export function SwMemberFromJSON(json: any): SwMember {
    return SwMemberFromJSONTyped(json, false);
}

export function SwMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zokugara': json['zokugara'],
        'zokugaraOther': json['zokugaraOther'],
        'isSyugakusya': !exists(json, 'isSyugakusya') ? undefined : json['isSyugakusya'],
        'name': json['name'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'syugakusya': !exists(json, 'syugakusya') ? undefined : SwMemberSyugakusyaFromJSON(json['syugakusya']),
        'syugakusyaIgai': !exists(json, 'syugakusyaIgai') ? undefined : SwMemberSyugakusyaIgaiFromJSON(json['syugakusyaIgai']),
    };
}

export function SwMemberToJSON(value?: SwMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zokugara': value.zokugara,
        'zokugaraOther': value.zokugaraOther,
        'isSyugakusya': value.isSyugakusya,
        'name': value.name,
        'age': value.age,
        'syugakusya': SwMemberSyugakusyaToJSON(value.syugakusya),
        'syugakusyaIgai': SwMemberSyugakusyaIgaiToJSON(value.syugakusyaIgai),
    };
}


