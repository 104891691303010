/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwStudentSendResetPasswordLinkPostErrorMessage,
    SwStudentSendResetPasswordLinkPostErrorMessageFromJSON,
    SwStudentSendResetPasswordLinkPostErrorMessageFromJSONTyped,
    SwStudentSendResetPasswordLinkPostErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20016
     */
    isSuccess: boolean;
    /**
     * 
     * @type {SwStudentSendResetPasswordLinkPostErrorMessage}
     * @memberof InlineResponse20016
     */
    error?: SwStudentSendResetPasswordLinkPostErrorMessage;
}

export function InlineResponse20016FromJSON(json: any): InlineResponse20016 {
    return InlineResponse20016FromJSONTyped(json, false);
}

export function InlineResponse20016FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20016 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSuccess': json['isSuccess'],
        'error': !exists(json, 'error') ? undefined : SwStudentSendResetPasswordLinkPostErrorMessageFromJSON(json['error']),
    };
}

export function InlineResponse20016ToJSON(value?: InlineResponse20016 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSuccess': value.isSuccess,
        'error': SwStudentSendResetPasswordLinkPostErrorMessageToJSON(value.error),
    };
}


