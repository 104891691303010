import {createStyles, makeStyles} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {YosikiEditOpenModalType} from "common/models/yosiki/YosikiInfoModalStoreVo";
import {Button} from "components/atoms/Button";
import {Modal} from "components/atoms/Modal";
import {ModalContentDokurituSeikeisyaRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentDokurituSeikeisyaRan";
import {ModalContentGenzaiNoSyokugyoRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentGenzaiNoSyokugyoRan";
import {ModalContentHigaiGakuRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentHigaiGakuRan";
import {ModalContentJusyoTouRanNoKazokuRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentJusyoTouRanNoKazokuRan";
import {ModalContentKoyohokenRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentKoyohokenRan";
import {ModalContentKyuyosyotokuIgaiNoSyotokuKingakuRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentKyuyosyotokuIgaiNoSyotokuKingakuRan";
import {ModalContentKyuyoTinginRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentKyuyoTinginRan";
import {ModalContentNenkinOnkyuRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentNenkinOnkyuRan";
import {ModalContentRinjiSyotokuRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentRinjiSyotokuRan";
import {ModalContentSyogakukinJukyuJokyoRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentSyogakukinJukyuJokyoRan";
import {ModalContentSyugakusyaRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentSyugakusyaRan";
import {ModalContentSyugakusyaWoNozokuKazokuRan} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentSyugakusyaWoNozokuKazokuRan";
import {ModalContentTugakuKubun} from "components/molecules/yosikiEdit/wordDescriptionModalCotent/ModalContentTugakuKubun";
import React from 'react';
import {BorderColorsEnum} from "common/enums_value/ColorsEnum";

export enum WordDescriptionTypeEnum {
    住所等欄の家族欄 = `住所等欄の家族欄`,
    現在の職業欄 = `現在の職業`,
    就学者を除く家族欄 = `就学者を除く家族欄`,
    給与賃金欄 = `給与・賃金欄`,
    年金恩給欄 = `年金・恩給欄`,
    雇用保険欄 = `雇用保険欄`,
    臨時所得欄 = `臨時所得欄`,
    給与所得以外の所得金額欄 = `給与所得以外の所得金額欄`,
    就学者欄 = `就学者欄`,
    通学区分 = `通学区分`,
    奨学金受給状況欄 = `奨学金受給状況欄`,
    独立生計者欄 = `独立生計者欄`,
    被害額欄 = `被害額欄`,
}

// 説明のモーダルのenum
export function WordDescriptionTypeEnumToName(e: WordDescriptionTypeEnum): { ja: string, en: string } {
    switch (e) {
        case WordDescriptionTypeEnum.住所等欄の家族欄:
            return {ja: "住所等欄の家族欄", en: "“Family” field in “Address, etc.” field"};
        case WordDescriptionTypeEnum.現在の職業欄:
            return {ja: "現在の職業", en: "“Current Occupation” field"};
        case WordDescriptionTypeEnum.就学者を除く家族欄:
            return {ja: "就学者以外欄", en: "“Other than student” field"};
        case WordDescriptionTypeEnum.給与賃金欄:
            return {ja: "給与・賃金欄", en: "“Salary and Wages” field"};
        case WordDescriptionTypeEnum.年金恩給欄:
            return {ja: "年金・恩給欄", en: "“Pension and Other Types of Retirement Benefits” field"};
        case WordDescriptionTypeEnum.雇用保険欄:
            return {ja: "雇用保険欄", en: "“Unemployment Insurance” field"};
        case WordDescriptionTypeEnum.臨時所得欄:
            return {ja: "臨時所得欄", en: "“Incidental Income” field"};
        case WordDescriptionTypeEnum.給与所得以外の所得金額欄:
            return {ja: "給与所得以外の所得金額欄", en: "“Amount of Income Other than Wage Income” field"};
        case WordDescriptionTypeEnum.就学者欄:
            return {ja: "就学者欄", en: "“Scholarship Receipt Status” field"};
        case WordDescriptionTypeEnum.通学区分:
            return {ja: "通学区分", en: "School Commute Classification"};
        case WordDescriptionTypeEnum.奨学金受給状況欄:
            return {ja: "奨学金受給状況欄", en: "“Scholarship Receipt Status” field"};
        case WordDescriptionTypeEnum.独立生計者欄:
            return {ja: "独立生計者欄", en: "“Individuals in Independent Households” field"};
        case WordDescriptionTypeEnum.被害額欄:
            return {ja: "被害額欄", en: "“Amount of Damage” field"};
    }


}

interface YosikiWordDescriptionModalProps {
    openState: boolean
    descriptionType?: WordDescriptionTypeEnum
    updateYosikiEditOpenModalType: (value?: YosikiEditOpenModalType) => void
}

export const YosikiWordDescriptionModal: React.FC<YosikiWordDescriptionModalProps> = (props) => {

    const classes = useStyles();

    function handleClickClose() {
        props.updateYosikiEditOpenModalType(undefined)
    }

    if (props.descriptionType === undefined) return <div/>;

    let content: React.ReactNode = <div/>;
    switch (props.descriptionType) {
        case WordDescriptionTypeEnum.住所等欄の家族欄:
            content = <ModalContentJusyoTouRanNoKazokuRan/>;
            break;
        case WordDescriptionTypeEnum.現在の職業欄:
            content = <ModalContentGenzaiNoSyokugyoRan/>;
            break;
        case WordDescriptionTypeEnum.就学者を除く家族欄:
            content = <ModalContentSyugakusyaWoNozokuKazokuRan/>;
            break;
        case WordDescriptionTypeEnum.給与賃金欄:
            content = <ModalContentKyuyoTinginRan/>;
            break;
        case WordDescriptionTypeEnum.年金恩給欄:
            content = <ModalContentNenkinOnkyuRan/>;
            break;
        case WordDescriptionTypeEnum.雇用保険欄:
            content = <ModalContentKoyohokenRan/>;
            break;
        case WordDescriptionTypeEnum.臨時所得欄:
            content = <ModalContentRinjiSyotokuRan/>;
            break;
        case WordDescriptionTypeEnum.給与所得以外の所得金額欄:
            content = <ModalContentKyuyosyotokuIgaiNoSyotokuKingakuRan/>;
            break;
        case WordDescriptionTypeEnum.就学者欄:
            content = <ModalContentSyugakusyaRan/>;
            break;
        case WordDescriptionTypeEnum.通学区分:
            content = <ModalContentTugakuKubun/>;
            break;
        case WordDescriptionTypeEnum.奨学金受給状況欄:
            content = <ModalContentSyogakukinJukyuJokyoRan/>;
            break;
        case WordDescriptionTypeEnum.独立生計者欄:
            content = <ModalContentDokurituSeikeisyaRan/>;
            break;
        case WordDescriptionTypeEnum.被害額欄:
            content = <ModalContentHigaiGakuRan/>;
            break;
    }

    return (

        <Modal
            openState={props.openState}
            title={convertEnumNameToStr(WordDescriptionTypeEnumToName(props.descriptionType))}
        >

            <div className={classes.modalContent}>
                {content}
            </div>

            <DialogActions className={"modal_actions"}>

                <Button
                    type={"primary"}
                    label={"閉じる"}
                    width={"120px"}
                    handleClick={handleClickClose}
                />

            </DialogActions>

        </Modal>

    );
};

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        modalContent: {
            "& .modalContent_item:not(:first-child)": {
                paddingTop: 16,
                borderTop: `1px ${BorderColorsEnum.LightGray} solid`,
                marginTop: 16,
            }
        },
    })
);