/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwAddressErrorMessage,
    SwAddressErrorMessageFromJSON,
    SwAddressErrorMessageFromJSONTyped,
    SwAddressErrorMessageToJSON,
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwSinseisyaInfoErrorMessageKyugaku,
    SwSinseisyaInfoErrorMessageKyugakuFromJSON,
    SwSinseisyaInfoErrorMessageKyugakuFromJSONTyped,
    SwSinseisyaInfoErrorMessageKyugakuToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSinseisyaInfoErrorMessage
 */
export interface SwSinseisyaInfoErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    birthdayYear?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    birthdayMonth?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    birthdayDay?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    gakunen?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    isKyugaku?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    isRyunen?: Array<SwErrorMessage>;
    /**
     * 
     * @type {SwSinseisyaInfoErrorMessageKyugaku}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    kyugaku: SwSinseisyaInfoErrorMessageKyugaku;
    /**
     * 
     * @type {SwAddressErrorMessage}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    address: SwAddressErrorMessage;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    telHome?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    telLab?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    sidoTeacherName?: Array<SwErrorMessage>;
    /**
     * 
     * @type {SwAddressErrorMessage}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    addressFamily: SwAddressErrorMessage;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSinseisyaInfoErrorMessage
     */
    telFamily?: Array<SwErrorMessage>;
}

export function SwSinseisyaInfoErrorMessageFromJSON(json: any): SwSinseisyaInfoErrorMessage {
    return SwSinseisyaInfoErrorMessageFromJSONTyped(json, false);
}

export function SwSinseisyaInfoErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSinseisyaInfoErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthdayYear': !exists(json, 'birthdayYear') ? undefined : ((json['birthdayYear'] as Array<any>).map(SwErrorMessageFromJSON)),
        'birthdayMonth': !exists(json, 'birthdayMonth') ? undefined : ((json['birthdayMonth'] as Array<any>).map(SwErrorMessageFromJSON)),
        'birthdayDay': !exists(json, 'birthdayDay') ? undefined : ((json['birthdayDay'] as Array<any>).map(SwErrorMessageFromJSON)),
        'gakunen': !exists(json, 'gakunen') ? undefined : ((json['gakunen'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isKyugaku': !exists(json, 'isKyugaku') ? undefined : ((json['isKyugaku'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isRyunen': !exists(json, 'isRyunen') ? undefined : ((json['isRyunen'] as Array<any>).map(SwErrorMessageFromJSON)),
        'kyugaku': SwSinseisyaInfoErrorMessageKyugakuFromJSON(json['kyugaku']),
        'address': SwAddressErrorMessageFromJSON(json['address']),
        'telHome': !exists(json, 'telHome') ? undefined : ((json['telHome'] as Array<any>).map(SwErrorMessageFromJSON)),
        'telLab': !exists(json, 'telLab') ? undefined : ((json['telLab'] as Array<any>).map(SwErrorMessageFromJSON)),
        'sidoTeacherName': !exists(json, 'sidoTeacherName') ? undefined : ((json['sidoTeacherName'] as Array<any>).map(SwErrorMessageFromJSON)),
        'addressFamily': SwAddressErrorMessageFromJSON(json['addressFamily']),
        'telFamily': !exists(json, 'telFamily') ? undefined : ((json['telFamily'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwSinseisyaInfoErrorMessageToJSON(value?: SwSinseisyaInfoErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthdayYear': value.birthdayYear === undefined ? undefined : ((value.birthdayYear as Array<any>).map(SwErrorMessageToJSON)),
        'birthdayMonth': value.birthdayMonth === undefined ? undefined : ((value.birthdayMonth as Array<any>).map(SwErrorMessageToJSON)),
        'birthdayDay': value.birthdayDay === undefined ? undefined : ((value.birthdayDay as Array<any>).map(SwErrorMessageToJSON)),
        'gakunen': value.gakunen === undefined ? undefined : ((value.gakunen as Array<any>).map(SwErrorMessageToJSON)),
        'isKyugaku': value.isKyugaku === undefined ? undefined : ((value.isKyugaku as Array<any>).map(SwErrorMessageToJSON)),
        'isRyunen': value.isRyunen === undefined ? undefined : ((value.isRyunen as Array<any>).map(SwErrorMessageToJSON)),
        'kyugaku': SwSinseisyaInfoErrorMessageKyugakuToJSON(value.kyugaku),
        'address': SwAddressErrorMessageToJSON(value.address),
        'telHome': value.telHome === undefined ? undefined : ((value.telHome as Array<any>).map(SwErrorMessageToJSON)),
        'telLab': value.telLab === undefined ? undefined : ((value.telLab as Array<any>).map(SwErrorMessageToJSON)),
        'sidoTeacherName': value.sidoTeacherName === undefined ? undefined : ((value.sidoTeacherName as Array<any>).map(SwErrorMessageToJSON)),
        'addressFamily': SwAddressErrorMessageToJSON(value.addressFamily),
        'telFamily': value.telFamily === undefined ? undefined : ((value.telFamily as Array<any>).map(SwErrorMessageToJSON)),
    };
}


