import makeStyles from "@material-ui/core/styles/makeStyles";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {YosikiInfoStoreVo} from "common/models/yosiki/YosikiInfoStoreVo";
import {InfoPolicyVo, SinseisyaInfoVo} from "common/models/YosikiInfoModels";
import {Title02} from "components/atoms/titles/Title02";
import {BasicInfoDetailSection} from "components/organisms/yosikiDetail/BasicInfoDetailSection";
import {FamilyInfoDetailSection} from "components/organisms/yosikiDetail/FamilyInfoDetailSection";
import {JugyoryoInfoDetailSection} from "components/organisms/yosikiDetail/JugyoryoInfoDetailSection";
import {NyugakuryoInfoDetailSection} from "components/organisms/yosikiDetail/NyugakuryoInfoDetailSection";
import {SeisekiInfoDetailSection} from "components/organisms/yosikiDetail/SeisekiInfoInfoDetailSection";
import {SinseisyaInfoDetailSection} from "components/organisms/yosikiDetail/SinseisyaInfoDetailSection";
import {SyogakukinInfoDetailSection} from "components/organisms/yosikiDetail/SyogakukinInfoDetailSection";
import {TokubetuKojoInfoDetailSection} from "components/organisms/yosikiDetail/TokubetuKojoInfoDetailSection";
import React, {FC} from "react";
import {TokubetuKojoEnum} from "../../../common/enums/TokubetuKojoEnum";

interface YosikiInfoDetailComponentProps {
    className?: string
    yosikiInfo: YosikiInfoStoreVo
    studentInfo: StudentInfoStoreVo
    sinseisyaInfo: SinseisyaInfoVo
    isDisplayBasicInfoTokubetuKojo: boolean
}

export const YosikiInfoDetailComponent: FC<YosikiInfoDetailComponentProps> = (props) => {

    const classes = useStyles();

    const yosikiInfo = props.yosikiInfo;
    if (yosikiInfo.yosikiInfo === undefined) return <></>;
    const infoPolicy: InfoPolicyVo = yosikiInfo.yosikiInfo.infoPolicy;

    // 特別控除のセクションの表示
    const isDisplayTokubetuKojoInfo: boolean | undefined =
        infoPolicy.requireTokubetuKojoInfo?.bosiHusi
        || infoPolicy.requireTokubetuKojoInfo?.syogaisya
        || infoPolicy.requireTokubetuKojoInfo?.tyokiryoyo
        || infoPolicy.requireTokubetuKojoInfo?.bekkyo
        || infoPolicy.requireTokubetuKojoInfo?.saigaihigai;

    return (
        <div className={`${classes.root} ${props.className}`}>
            <Title02
                title={"様式1詳細画面 / Form 1 Detail Screen"}
            />
            <div className={classes.sectionWrap}>

                <BasicInfoDetailSection
                    className={"section"}
                    basicInfo={yosikiInfo.yosikiInfo.basicInfo}
                    isDisplayBasicInfoTokubetuKojo={props.isDisplayBasicInfoTokubetuKojo}
                />

                <SinseisyaInfoDetailSection
                    className={"section"}
                    requireSinseisyaInfo={yosikiInfo.yosikiInfo.infoPolicy.requireSinseisyaInfo}
                    studentInfo={props.studentInfo}
                    sinseisyaInfo={yosikiInfo.yosikiInfo.sinseisyaInfo}
                />

                <SeisekiInfoDetailSection
                    className={"section"}
                    seisekiInfo={yosikiInfo.yosikiInfo.seisekiInfo}
                />

                <SyogakukinInfoDetailSection
                    className={"section"}
                    syogakukins={yosikiInfo.yosikiInfo.syogakukinInfo.syogakukins}
                    syogakukinErrorMessages={yosikiInfo.yosikiInfoErrorMessages.syogakukinInfoErrorMessage.syogakukins || []}
                />

                <FamilyInfoDetailSection
                    className={"section"}
                    familyInfo={yosikiInfo.yosikiInfo.familyInfo}
                    commonYosikiMasters={props.yosikiInfo.commonYosikiMasters}
                    isGakubu={props.studentInfo.isGakubu}
                    isDokuritu={yosikiInfo.yosikiInfo.basicInfo.tokubetuKojo.includes(TokubetuKojoEnum.独立生計者)}
                />

                {
                    yosikiInfo.yosikiInfo.infoPolicy.requireNyugakuryoInfo &&
                    <NyugakuryoInfoDetailSection
                        className={"section"}
                        nyugakuryoInfo={yosikiInfo.yosikiInfo.nyugakuryoInfo}
                    />
                }

                {
                    yosikiInfo.yosikiInfo.infoPolicy.requireJugyoryoInfo &&
                    <JugyoryoInfoDetailSection
                        className={"section"}
                        jugyoryoInfo={yosikiInfo.yosikiInfo.jugyoryoInfo}
                    />
                }

                {
                    isDisplayTokubetuKojoInfo ?
                        <TokubetuKojoInfoDetailSection
                            className={"section"}
                            requireTokubetuKojoInfo={yosikiInfo.yosikiInfo.infoPolicy.requireTokubetuKojoInfo}
                            tokubetuKojoInfo={yosikiInfo.yosikiInfo.tokubetuKojoInfo}
                        />
                        : null
                }

            </div>
        </div>

    )
};

const useStyles = makeStyles({
        root: {},
        sectionWrap: {
            "& .section": {
                marginTop: 32,
                "& .section_table": {
                    marginTop: 8
                },
                "& .section_button": {
                    marginTop: 8
                }
            },
        },
    }
);

