import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum SyogakukinInfoLabelTextEnum {
    奨学金 = "奨学金",
}

export function SyogakukinInfoLabelTextEnumToNameJaAndEn(e: SyogakukinInfoLabelTextEnum): string {
    switch (e) {
        case SyogakukinInfoLabelTextEnum.奨学金:
            return convertEnumNameToStr({ja: "奨学金", en: "Scholarship"});
    }
}