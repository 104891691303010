/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiFormAYosiki2ErrorMessage
 */
export interface SwAYosikiFormAYosiki2ErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormAYosiki2ErrorMessage
     */
    agreement?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormAYosiki2ErrorMessage
     */
    syogakuseiNumber?: Array<SwErrorMessage>;
}

export function SwAYosikiFormAYosiki2ErrorMessageFromJSON(json: any): SwAYosikiFormAYosiki2ErrorMessage {
    return SwAYosikiFormAYosiki2ErrorMessageFromJSONTyped(json, false);
}

export function SwAYosikiFormAYosiki2ErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormAYosiki2ErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreement': !exists(json, 'agreement') ? undefined : ((json['agreement'] as Array<any>).map(SwErrorMessageFromJSON)),
        'syogakuseiNumber': !exists(json, 'syogakuseiNumber') ? undefined : ((json['syogakuseiNumber'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwAYosikiFormAYosiki2ErrorMessageToJSON(value?: SwAYosikiFormAYosiki2ErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreement': value.agreement === undefined ? undefined : ((value.agreement as Array<any>).map(SwErrorMessageToJSON)),
        'syogakuseiNumber': value.syogakuseiNumber === undefined ? undefined : ((value.syogakuseiNumber as Array<any>).map(SwErrorMessageToJSON)),
    };
}


