/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwStudentCreateAccountPostErrorMessage
 */
export interface SwStudentCreateAccountPostErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountPostErrorMessage
     */
    base?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountPostErrorMessage
     */
    token?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountPostErrorMessage
     */
    password?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountPostErrorMessage
     */
    passwordComfirm?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountPostErrorMessage
     */
    telGakuseiNyuyroku?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountPostErrorMessage
     */
    emailGakuseiNyuyroku?: Array<SwErrorMessage>;
}

export function SwStudentCreateAccountPostErrorMessageFromJSON(json: any): SwStudentCreateAccountPostErrorMessage {
    return SwStudentCreateAccountPostErrorMessageFromJSONTyped(json, false);
}

export function SwStudentCreateAccountPostErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwStudentCreateAccountPostErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base': !exists(json, 'base') ? undefined : ((json['base'] as Array<any>).map(SwErrorMessageFromJSON)),
        'token': !exists(json, 'token') ? undefined : ((json['token'] as Array<any>).map(SwErrorMessageFromJSON)),
        'password': !exists(json, 'password') ? undefined : ((json['password'] as Array<any>).map(SwErrorMessageFromJSON)),
        'passwordComfirm': !exists(json, 'passwordComfirm') ? undefined : ((json['passwordComfirm'] as Array<any>).map(SwErrorMessageFromJSON)),
        'telGakuseiNyuyroku': !exists(json, 'telGakuseiNyuyroku') ? undefined : ((json['telGakuseiNyuyroku'] as Array<any>).map(SwErrorMessageFromJSON)),
        'emailGakuseiNyuyroku': !exists(json, 'emailGakuseiNyuyroku') ? undefined : ((json['emailGakuseiNyuyroku'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwStudentCreateAccountPostErrorMessageToJSON(value?: SwStudentCreateAccountPostErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base': value.base === undefined ? undefined : ((value.base as Array<any>).map(SwErrorMessageToJSON)),
        'token': value.token === undefined ? undefined : ((value.token as Array<any>).map(SwErrorMessageToJSON)),
        'password': value.password === undefined ? undefined : ((value.password as Array<any>).map(SwErrorMessageToJSON)),
        'passwordComfirm': value.passwordComfirm === undefined ? undefined : ((value.passwordComfirm as Array<any>).map(SwErrorMessageToJSON)),
        'telGakuseiNyuyroku': value.telGakuseiNyuyroku === undefined ? undefined : ((value.telGakuseiNyuyroku as Array<any>).map(SwErrorMessageToJSON)),
        'emailGakuseiNyuyroku': value.emailGakuseiNyuyroku === undefined ? undefined : ((value.emailGakuseiNyuyroku as Array<any>).map(SwErrorMessageToJSON)),
    };
}


