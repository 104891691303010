
// NOTE: エラーメッセージのカウント
export function countErrorMessage(obj: any) {

    const walkJSON = (data: any, callback: any) => {
        for (let key in data) {
            callback(key, data[key]);
            if (typeof data[key] === "object") {
                walkJSON(data[key], callback);
            }
        }
    };

    let count: number = 0;
    walkJSON(obj, function (key: any, value: any) {
        if (typeof value === "string") {
            count = count + 1
        }
    });
    // NOTE: 日英で2倍になるので2で割る
    return count / 2
}