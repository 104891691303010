import {KikanTypeEnum} from "common/constant/KIKAN";
import {OfficerKikansStudentIdKikansKikanVo, OfficerKikansStudentIdKikansVo} from "common/models/KikansModels";

export function getCurrentKikan(kikans: OfficerKikansStudentIdKikansVo | undefined): { name: string, kikan: OfficerKikansStudentIdKikansKikanVo } | undefined {
    if (kikans === undefined) return undefined;
    if (kikans.nyuryokuKikan.isCurrent) return {name: KikanTypeEnum.入力期間, kikan: kikans.nyuryokuKikan};
    if (kikans.sinseiKikan.isCurrent) return {name: KikanTypeEnum.申請期間, kikan: kikans.sinseiKikan};
    if (kikans.teiseiKikan.isCurrent) return {name: KikanTypeEnum.訂正期間, kikan: kikans.teiseiKikan};
    return undefined
}

