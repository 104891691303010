import {TextField} from "components/atoms/forms/TextField";
import React, {FC} from 'react';
import {convertZenkakuToHankaku} from "common/functions/char_converter/convertZenkakuToHankaku";
import {trimStrForNotNumber} from "common/functions/char_converter/trimStrForNotNumber";
import {trimNotNumberAndNotDecimalPoint} from "common/functions/char_converter/trimStrForNotNumberAndNotDecimalPoint";
import {trimStrForZenkaku} from "common/functions/char_converter/trimStrForZenkaku";

interface NumberFieldProps {
    // option
    className?: string;
    width?: string;
    widthInput?: string;
    isError?: boolean;
    prefixText?: string;
    suffixText?: string;
    multiline?: boolean;
    rows?: number;
    min?: string;
    max?: string;
    pattern?: string;
    helperText?: string;
    errorMessages?: Array<string>;
    isSyosutenPermitted?: boolean // 小数の可否
    maxLength?: number

    // form
    disabled?: boolean;
    value?: string;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur?: (value: string) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
}

// 数字以外の文字は削除されます。(記号不可、全角文字が入力された場合にうまく削除されない場合があります。)
// valueはstringで扱います。
// input stringではchangeイベントのバグがあるので理解の上使用してください。
// https://qiita.com/FiNGAHOLiC/items/3c19642b6e1d82cc9c79
export const NumberField: FC<NumberFieldProps> = (props) => {

    function sliceMaxLength(value: string, maxLength: number) {
        return value.slice(0, maxLength);
    }

    function handleBlur(newValue: string) {
        const trimedZenkakuValue = trimStrForZenkaku(convertZenkakuToHankaku(newValue));
        let trimedNotNumberValue = "";
        if (props.handleBlur === undefined) return;
        if (props.isSyosutenPermitted) {
            // 小数点を許可
            trimedNotNumberValue = trimNotNumberAndNotDecimalPoint(trimedZenkakuValue)
        } else {
            // 小数点を不許可 デフォルトでこっちが採用
            trimedNotNumberValue = trimStrForNotNumber(trimedZenkakuValue)
        }
        if (props.maxLength) {
            props.handleBlur(sliceMaxLength(trimedNotNumberValue, props.maxLength));
        } else {
            props.handleBlur(trimedNotNumberValue)
        }
    }

    return (

        <TextField
            className={props.className}
            disabled={props.disabled}
            width={props.width}
            widthInput={props.widthInput}
            prefixText={props.prefixText}
            suffixText={props.suffixText}
            multiline={props.multiline}
            rows={props.rows}
            min={props.min}
            max={props.max}
            helperText={props.helperText}
            type={"number"}
            value={props.value}
            handleBlur={handleBlur}
            errorMessages={props.errorMessages}
            isError={props.isError}
            inputMode={props.isSyosutenPermitted ? "decimal" : "numeric"}
        />

    )
};

NumberField.defaultProps = {
    width: "100%",
    widthInput: "100%",
    isError: false,
    disabled: false,
    multiline: false,
};