import makeStyles from "@material-ui/core/styles/makeStyles";
import {beturiTypeEnumToName} from "common/enums/BeturiTypeEnum";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {BosiHusiVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {TitleNameTextEnum, TitleNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TitleNameTextEnum";
import {TokubetuKojoLabelTextEnum, TokubetuKojoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TokubetuKojoLabelTextEnum";
import {Title05} from "components/atoms/titles/Title05";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {FC} from "react";

interface TokubetuKojoBoshiHusiDetailProps {
    bosiHusi: BosiHusiVo
}

export const TokubetuKojoBoshiHusiDetail: FC<TokubetuKojoBoshiHusiDetailProps> = (props) => {

    const classes = useStyles();

    const bosiHusi: BosiHusiVo = props.bosiHusi;

    return (

        <>
            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.母子父子世帯)}
                className={classes.title}
            />

            <FormTable
                className={"section_table"}
            >
                <FormTableRow
                    label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.母子父子世帯)}
                >
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}
                        </div>
                        {bosiHusi.mother ?
                            <div>{TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.母無)}</div> : null}
                        {bosiHusi.father ?
                            <div>{TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.父無)}</div> : null}
                    </div>
                </FormTableRow>

                {
                    bosiHusi.mother ?
                        <FormTableRow
                            label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.母無)}
                        >
                            <div className={"formTableRow_item"}>
                                <div className={"formTableRow_item_label"}>
                                    {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}
                                </div>
                                {bosiHusi.mother.beturiType && convertEnumNameToStr(beturiTypeEnumToName(bosiHusi.mother.beturiType))}
                            </div>
                            <div className={"formTableRow_item"}>
                                <div className={"formTableRow_item_label"}>
                                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.時期)}
                                </div>
                                {bosiHusi.mother!!.jikiYaer}年
                                {bosiHusi.mother!!.jikiMonth}月
                            </div>
                            <div className={"formTableRow_item"}>
                                <div className={"formTableRow_item_label"}>
                                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.理由)}
                                </div>
                                {bosiHusi.mother!!.riyu}
                            </div>
                        </FormTableRow>
                        : null
                }

                {
                    bosiHusi.father ?
                        <FormTableRow
                            label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.父無)}
                        >
                            <div className={"formTableRow_item"}>
                                <div className={"formTableRow_item_label"}>
                                    {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}
                                </div>
                                {bosiHusi.father.beturiType && convertEnumNameToStr(beturiTypeEnumToName(bosiHusi.father.beturiType))}
                            </div>
                            <div className={"formTableRow_item"}>
                                <div className={"formTableRow_item_label"}>
                                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.時期)}
                                </div>
                                {bosiHusi.father!!.jikiYaer}年
                                {bosiHusi.father!!.jikiMonth}月
                            </div>
                            <div className={"formTableRow_item"}>
                                <div className={"formTableRow_item_label"}>
                                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.理由)}
                                </div>
                                {bosiHusi.father!!.riyu}
                            </div>
                        </FormTableRow>
                        : null
                }

            </FormTable>
        </>

    )
};

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        }
    }
);

