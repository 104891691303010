import {YosikiStateEnum} from "common/enums/YosikiStateEnum";

interface YosikiDetailDisplayButtonPolicyVo {
    isDisplayNyuryokuButton: boolean,
    isDisplayKakuteiButton: boolean,
    isDisplayKakuteiKaijoButton: boolean,
}

export function canButtonsDisplayYOsikiDetail(yosikiState: YosikiStateEnum | undefined): YosikiDetailDisplayButtonPolicyVo {
    return {
        isDisplayNyuryokuButton: yosikiState !== YosikiStateEnum.確定,
        isDisplayKakuteiButton: (yosikiState === YosikiStateEnum.未確定) || (yosikiState === YosikiStateEnum.未入力),
        isDisplayKakuteiKaijoButton: yosikiState === YosikiStateEnum.確定,
    }
}