import DialogActions from "@material-ui/core/DialogActions";
import {JugyoryoEnum, jugyoryoEnumToName} from "common/enums/JugyoryoEnum";
import {NyugakuryoEnum, nyugakuryoEnumToName} from "common/enums/NyugakuryoEnum";
import {TokubetuKojoEnum, tokubetuKojoEnumToName} from "common/enums/TokubetuKojoEnum";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {getIsEnteredYosiki} from "common/functions/getIsEnteredYosiki";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {YosikiBasicInfoFromVo} from "common/models/yosiki/YosikiBasicInfoStoreVo";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {
    BasicInfoLabelTextEnum,
    BasicInfoLabelTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/BasicInfoLabelTextEnum";
import {BasicInfoLTextEnum, BasicInfoLTextEnumToNameJaAndEn} from "common/enums_text/yosiki/BasicInfoTextEnum";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {Button} from "components/atoms/Button";
import {Checkbox} from "components/atoms/forms/Checkbox";
import {RadioButton} from "components/atoms/forms/RadioButton";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {DescriptionLink} from "components/atoms/DescriptionLink";
import {Modal} from "components/atoms/Modal";
import {CheckboxGroup} from "components/molecules/forms/CheckboxGroup";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {RadioButtonGroup} from "components/molecules/forms/RadioButtonGroup";
import {DescriptionLinkWrap} from "components/molecules/utilities/DescriptionLinkWrap";
import {WordDescriptionTypeEnum} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {SwBasicInfoErrorMessageResponse} from "openapi/models";
import React from 'react';
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface YosikiEditBasicInfoModalProps {
    openState: boolean
    studentInfo: StudentInfoStoreVo
    basicInfoForm: YosikiBasicInfoFromVo;
    updateYosikiBasicInfoForm: (part: Partial<YosikiBasicInfoFromVo>) => void
    updateBasicInfoErrorMessage: (part: Partial<SwBasicInfoErrorMessageResponse>) => void
    basicInfoErrorMessage: SwBasicInfoErrorMessageResponse;
    onClickWordDescriptionLink: (type: WordDescriptionTypeEnum) => void
    handleClickFirstComfirm: () => void
    handleClickComfirm: () => void
    handleClickClose: () => void
    handleClickBackToDetail: () => void
}

export const _YosikiEditBasicInfoModal: React.FC<YosikiEditBasicInfoModalProps> = (props) => {

    function handleChangeNyugakuryo(value: string) {
        props.updateYosikiBasicInfoForm({
            sinseiType: {
                ...props.basicInfoForm.sinseiType,
                nyugakuryo: value
            }
        });
        props.updateBasicInfoErrorMessage({
            sinseiType: {
                ...props.basicInfoErrorMessage.sinseiType,
                nyugakuryo: undefined,
                either: undefined
            }
        })
    }

    function handleChangeJugyoryo(value: string) {
        props.updateYosikiBasicInfoForm({
            sinseiType: {
                ...props.basicInfoForm.sinseiType,
                jugyoryo: value
            }
        });
        props.updateBasicInfoErrorMessage({
            sinseiType: {
                ...props.basicInfoErrorMessage.sinseiType,
                jugyoryo: undefined,
                either: undefined
            }
        })
    }

    function handleChangeTokubetuKojo(value: string) {
        if (props.basicInfoForm.tokubetuKojo === undefined) {
            props.updateYosikiBasicInfoForm({tokubetuKojo: undefined})
        } else {
            const copied: Array<string> = props.basicInfoForm.tokubetuKojo.slice();
            const index = copied.indexOf(value);
            if (index === -1) {
                copied.push(value)
            } else {
                copied.splice(index, 1)
            }
            props.updateYosikiBasicInfoForm({tokubetuKojo: copied})
        }
    }

    async function handleClickFirstComfirm() {
        await props.handleClickFirstComfirm();
    }

    async function handleClickComfirm() {
        await props.handleClickComfirm();
    }

    function handleClickBackToDetail() {
        props.handleClickBackToDetail()
    }

    function handleClickClose() {
        props.handleClickClose()
    }

    const form: YosikiBasicInfoFromVo = props.basicInfoForm;
    const tokubetuKojo = form.tokubetuKojo;

    const isExistYosiki = getIsEnteredYosiki(props.studentInfo.yosikiState);

    const isNyugakuryoError: boolean = isErrorMessage(props.basicInfoErrorMessage.sinseiType?.nyugakuryo);
    const isJugyoryoError: boolean = isErrorMessage(props.basicInfoErrorMessage.sinseiType?.jugyoryo);
    const isSinseiTypeError: boolean = isErrorMessage(props.basicInfoErrorMessage.sinseiType?.either);

    return (

        <Modal
            id={"basicInfoModal"}
            openState={props.openState}
            title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.様式1基本情報)}
        >

            <FormTable
                id={"basicInfoModal_table"}
            >

                <FormTableRow
                    label={BasicInfoLabelTextEnumToNameJaAndEn(BasicInfoLabelTextEnum.希望する申請種別)}
                >
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {BasicInfoLTextEnumToNameJaAndEn(BasicInfoLTextEnum.入学料について該当する項目を選択してください)}<RequiredTag
                            type={RequiredTagTypeEnum.必須}/>
                        </div>
                        <RadioButtonGroup
                            errorMessages={convertErrorMessageVoToArrayStr(props.basicInfoErrorMessage.sinseiType?.nyugakuryo)}
                            vertical={true}
                        >
                            <RadioButton
                                value={NyugakuryoEnum.免除}
                                checked={NyugakuryoEnum.免除 === props.basicInfoForm.sinseiType.nyugakuryo}
                                label={nyugakuryoEnumToName(NyugakuryoEnum.免除).ja + " / " + nyugakuryoEnumToName(NyugakuryoEnum.免除).en}
                                isError={isNyugakuryoError || isSinseiTypeError}
                                handleChange={handleChangeNyugakuryo}
                            />
                            <RadioButton
                                value={NyugakuryoEnum.猶予}
                                checked={NyugakuryoEnum.猶予 === props.basicInfoForm.sinseiType.nyugakuryo}
                                label={nyugakuryoEnumToName(NyugakuryoEnum.猶予).ja + " / " + nyugakuryoEnumToName(NyugakuryoEnum.猶予).en}
                                isError={isNyugakuryoError || isSinseiTypeError}
                                handleChange={handleChangeNyugakuryo}
                            />
                            <RadioButton
                                value={NyugakuryoEnum.申請しない}
                                checked={NyugakuryoEnum.申請しない === props.basicInfoForm.sinseiType.nyugakuryo}
                                label={nyugakuryoEnumToName(NyugakuryoEnum.申請しない).ja + " / " + nyugakuryoEnumToName(NyugakuryoEnum.申請しない).en}
                                isError={isNyugakuryoError || isSinseiTypeError}
                                handleChange={handleChangeNyugakuryo}
                            />
                        </RadioButtonGroup>
                    </div>

                    <div className={"formTableRow_item"}>
                        <div
                            className={"formTableRow_item_label"}>
                            {BasicInfoLTextEnumToNameJaAndEn(BasicInfoLTextEnum.授業料について該当する項目を選択してください)}<RequiredTag
                            type={RequiredTagTypeEnum.必須}/>
                        </div>
                        <RadioButtonGroup
                            errorMessages={convertErrorMessageVoToArrayStr(props.basicInfoErrorMessage.sinseiType?.jugyoryo)}
                            vertical={true}
                        >
                            <RadioButton
                                value={JugyoryoEnum.免除}
                                checked={JugyoryoEnum.免除 === props.basicInfoForm.sinseiType.jugyoryo}
                                label={jugyoryoEnumToName(JugyoryoEnum.免除).ja + " / " + jugyoryoEnumToName(JugyoryoEnum.免除).en}
                                isError={isJugyoryoError || isSinseiTypeError}
                                handleChange={handleChangeJugyoryo}
                            />
                            <RadioButton
                                value={JugyoryoEnum.猶予}
                                checked={JugyoryoEnum.猶予 === props.basicInfoForm.sinseiType.jugyoryo}
                                label={jugyoryoEnumToName(JugyoryoEnum.猶予).ja + " / " + jugyoryoEnumToName(JugyoryoEnum.猶予).en}
                                isError={isJugyoryoError || isSinseiTypeError}
                                handleChange={handleChangeJugyoryo}
                            />
                            <RadioButton
                                value={JugyoryoEnum.両方}
                                checked={JugyoryoEnum.両方 === props.basicInfoForm.sinseiType.jugyoryo}
                                label={jugyoryoEnumToName(JugyoryoEnum.両方).ja + " / " + jugyoryoEnumToName(JugyoryoEnum.両方).en}
                                isError={isJugyoryoError || isSinseiTypeError}
                                handleChange={handleChangeJugyoryo}
                            />
                            <RadioButton
                                value={JugyoryoEnum.申請しない}
                                checked={JugyoryoEnum.申請しない === props.basicInfoForm.sinseiType.jugyoryo}
                                label={jugyoryoEnumToName(JugyoryoEnum.申請しない).ja + " / " + jugyoryoEnumToName(JugyoryoEnum.申請しない).en}
                                isError={isJugyoryoError || isSinseiTypeError}
                                handleChange={handleChangeJugyoryo}
                            />
                        </RadioButtonGroup>

                    </div>

                    <ErrorMessages
                        errorMessages={convertErrorMessageVoToArrayStr(props.basicInfoErrorMessage.sinseiType?.either)}
                    />

                </FormTableRow>

                <FormTableRow
                    label={BasicInfoLabelTextEnumToNameJaAndEn(BasicInfoLabelTextEnum.特別控除)}
                >
                    <div className={"formTableRow_item"}>

                        <div
                            className={"formTableRow_item_label"}>
                            {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}
                            <RequiredTag type={RequiredTagTypeEnum.任意}/>
                        </div>
                        <CheckboxGroup
                            errorMessages={convertErrorMessageVoToArrayStr(props.basicInfoErrorMessage.tokubetuKojo)}
                            vertical={true}
                        >

                            <Checkbox
                                checked={tokubetuKojo ? tokubetuKojo.indexOf(TokubetuKojoEnum.母子父子) !== -1 : false}
                                label={tokubetuKojoEnumToName(TokubetuKojoEnum.母子父子).ja + " / " + tokubetuKojoEnumToName(TokubetuKojoEnum.母子父子).en}
                                value={TokubetuKojoEnum.母子父子}
                                handleChange={handleChangeTokubetuKojo}
                                isError={isErrorMessage(props.basicInfoErrorMessage.tokubetuKojo)}
                            />

                            <Checkbox
                                checked={tokubetuKojo ? tokubetuKojo.indexOf(TokubetuKojoEnum.障害者) !== -1 : false}
                                label={tokubetuKojoEnumToName(TokubetuKojoEnum.障害者).ja + " / " + tokubetuKojoEnumToName(TokubetuKojoEnum.障害者).en}
                                value={TokubetuKojoEnum.障害者}
                                handleChange={handleChangeTokubetuKojo}
                                isError={isErrorMessage(props.basicInfoErrorMessage.tokubetuKojo)}
                            />

                            <Checkbox
                                checked={tokubetuKojo ? tokubetuKojo.indexOf(TokubetuKojoEnum.生活保護世帯) !== -1 : false}
                                label={tokubetuKojoEnumToName(TokubetuKojoEnum.生活保護世帯).ja + " / " + tokubetuKojoEnumToName(TokubetuKojoEnum.生活保護世帯).en}
                                value={TokubetuKojoEnum.生活保護世帯}
                                handleChange={handleChangeTokubetuKojo}
                                isError={isErrorMessage(props.basicInfoErrorMessage.tokubetuKojo)}
                            />

                            <Checkbox
                                checked={tokubetuKojo ? tokubetuKojo.indexOf(TokubetuKojoEnum.独立生計者) !== -1 : false}
                                label={tokubetuKojoEnumToName(TokubetuKojoEnum.独立生計者).ja + " / " + tokubetuKojoEnumToName(TokubetuKojoEnum.独立生計者).en}
                                value={TokubetuKojoEnum.独立生計者}
                                handleChange={handleChangeTokubetuKojo}
                                isError={isErrorMessage(props.basicInfoErrorMessage.tokubetuKojo)}
                            />

                            <Checkbox
                                checked={tokubetuKojo ? tokubetuKojo.indexOf(TokubetuKojoEnum.長期療養者) !== -1 : false}
                                label={tokubetuKojoEnumToName(TokubetuKojoEnum.長期療養者).ja + " / " + tokubetuKojoEnumToName(TokubetuKojoEnum.長期療養者).en}
                                value={TokubetuKojoEnum.長期療養者}
                                handleChange={handleChangeTokubetuKojo}
                                isError={isErrorMessage(props.basicInfoErrorMessage.tokubetuKojo)}
                            />

                            {/*別居*/}
                            <Checkbox
                                checked={tokubetuKojo ? tokubetuKojo.indexOf(TokubetuKojoEnum.別居) !== -1 : false}
                                label={tokubetuKojoEnumToName(TokubetuKojoEnum.別居).ja + " / " + tokubetuKojoEnumToName(TokubetuKojoEnum.別居).en}
                                value={TokubetuKojoEnum.別居}
                                handleChange={handleChangeTokubetuKojo}
                                isError={isErrorMessage(props.basicInfoErrorMessage.tokubetuKojo)}
                            />

                            <Checkbox
                                checked={tokubetuKojo ? tokubetuKojo.indexOf(TokubetuKojoEnum.災害被害) !== -1 : false}
                                label={tokubetuKojoEnumToName(TokubetuKojoEnum.災害被害).ja + " / " + tokubetuKojoEnumToName(TokubetuKojoEnum.災害被害).en}
                                value={TokubetuKojoEnum.災害被害}
                                handleChange={handleChangeTokubetuKojo}
                                isError={isErrorMessage(props.basicInfoErrorMessage.tokubetuKojo)}
                            />

                        </CheckboxGroup>
                    </div>

                    <DescriptionLinkWrap>
                        <DescriptionLink
                            onClickWordDescriptionLink={props.onClickWordDescriptionLink}
                            type={WordDescriptionTypeEnum.独立生計者欄}
                        />
                    </DescriptionLinkWrap>

                </FormTableRow>

            </FormTable>

            < DialogActions
                className={"modal_actions"}>
                {
                    !isExistYosiki ?
                        <Button
                            type={"default"}
                            label={CommonTextEnumToNameJaAndEn(CommonTextEnum.戻る)}
                            width={"120px"}
                            handleClick={handleClickBackToDetail}
                        /> :
                        <Button
                            type={"default"}
                            label={CommonTextEnumToNameJaAndEn(CommonTextEnum.閉じる)}
                            width={"120px"}
                            handleClick={handleClickClose}
                        />
                }

                {
                    !isExistYosiki ?
                        <Button
                            type={"primary"}
                            label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                            width={"120px"}
                            handleClick={handleClickFirstComfirm}
                        /> :
                        <Button
                            type={"primary"}
                            label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                            width={"120px"}
                            handleClick={handleClickComfirm}
                        />
                }

            </DialogActions>

        </Modal>

    );
};

export const YosikiEditBasicInfoModal = React.memo<YosikiEditBasicInfoModalProps>(_YosikiEditBasicInfoModal, (prev, next) => {
    return prev.openState === next.openState
        && prev.studentInfo === next.studentInfo
        && prev.basicInfoForm === next.basicInfoForm
        && prev.basicInfoErrorMessage === next.basicInfoErrorMessage
        && prev.onClickWordDescriptionLink === next.onClickWordDescriptionLink
        && prev.handleClickFirstComfirm === next.handleClickFirstComfirm
        && prev.handleClickComfirm === next.handleClickComfirm
        && prev.handleClickClose === next.handleClickClose
});


