import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {PictureAsPdf} from "@material-ui/icons";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {CommonSyoruisSyoruiVo} from "stores/common/CommonSyoruiStore";
import {PdfEnum, PdfEnumToName} from "common/enums_text/PdfEnum";
import {SummaryTextEnum, SummaryTextEnumToName} from "common/enums_text/SummaryTextEnum";
import {SyoruiTextEnum, SyoruiTextEnumToName} from "common/enums_text/SyoruiTextEnum";
import {Button} from "components/atoms/Button";
import {TextLink} from "components/atoms/TextLink";
import {Title04} from "components/atoms/titles/Title04";
import {Modal} from "components/atoms/Modal";
import React from 'react';
import {BgColorsEnum, BorderColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";
import {Box} from "@material-ui/core";

interface SyoruiModalProps {
    openState: boolean
    syoruis: Array<CommonSyoruisSyoruiVo>;
    handleClickSyoruiModalClose: () => void
}

export const SyoruiModal: React.FC<SyoruiModalProps> = (props) => {

    const classes = useStyles();

    const syoruis = props.syoruis;
    if (syoruis === undefined) return <></>;

    return (

        <Modal
            openState={props.openState}
            title={convertEnumNameToStr(SyoruiTextEnumToName(SyoruiTextEnum.各種証明書類等一覧))}
        >

            <div className={classes.section}>

                <div className={'section_dsc fw700'}>
                    {convertEnumNameToStr(SyoruiTextEnumToName(SyoruiTextEnum.新入生は以下の証明書類を印刷して記入の上申請してください))}
                </div>

                <div className={'mt16'}>
                    {convertEnumNameToStr(SyoruiTextEnumToName(SyoruiTextEnum.新入生は以下の申請要領を熟読しPDFを印刷記入の上申請してください))}
                </div>

                <div className={`section_syoruiList ${classes.syoruiList}`}>

                    <div className={"syoruiList_inner syoruiList_inner-sinseiyoryo"}>

                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.様式記入例).ja}
                                href={PdfEnumToName(PdfEnum.様式記入例).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.様式記入例).en}
                                href={PdfEnumToName(PdfEnum.様式記入例).en_url}
                                download={true}
                            />
                        </div>

                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.徴収猶予記入例).ja}
                                href={PdfEnumToName(PdfEnum.徴収猶予記入例).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.徴収猶予記入例).en}
                                href={PdfEnumToName(PdfEnum.徴収猶予記入例).en_url}
                                download={true}
                            />
                        </div>

                        {/* NOTE: 申請要領の種類が変更になったのでコメントアウト 2023_spring */}
                        {/*<div className={"syoruiList_inner_item"}>*/}
                        {/*    <TextLink*/}
                        {/*        icon={<PictureAsPdf className={'icon'}/>}*/}
                        {/*        text={PdfEnumToName(PdfEnum.申請要領学部新入生).ja}*/}
                        {/*        href={PdfEnumToName(PdfEnum.申請要領学部新入生).ja_url}*/}
                        {/*        download={true}*/}
                        {/*    />*/}
                        {/*    {" / "}*/}
                        {/*    <TextLink*/}
                        {/*        icon={<PictureAsPdf className={'icon'}/>}*/}
                        {/*        text={PdfEnumToName(PdfEnum.申請要領学部新入生).en}*/}
                        {/*        href={PdfEnumToName(PdfEnum.申請要領学部新入生).en_url}*/}
                        {/*        download={true}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {/* NOTE: 申請要領の種類が変更になったのでコメントアウト 2023_spring */}
                        {/*<div className={"syoruiList_inner_item"}>*/}
                        {/*    <TextLink*/}
                        {/*        icon={<PictureAsPdf className={'icon'}/>}*/}
                        {/*        text={PdfEnumToName(PdfEnum.申請要領学部在学生).ja}*/}
                        {/*        href={PdfEnumToName(PdfEnum.申請要領学部在学生).ja_url}*/}
                        {/*        download={true}*/}
                        {/*    />*/}
                        {/*    {" / "}*/}
                        {/*    <TextLink*/}
                        {/*        icon={<PictureAsPdf className={'icon'}/>}*/}
                        {/*        text={PdfEnumToName(PdfEnum.申請要領学部在学生).en}*/}
                        {/*        href={PdfEnumToName(PdfEnum.申請要領学部在学生).en_url}*/}
                        {/*        download={true}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {/* NOTE: 書類の種類が変更になったので追加 2023_spring */}
                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領学部在学生新制度).ja}
                                href={PdfEnumToName(PdfEnum.申請要領学部在学生新制度).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領学部在学生新制度).en}
                                href={PdfEnumToName(PdfEnum.申請要領学部在学生新制度).en_url}
                                download={true}
                            />
                        </div>

                        {/* NOTE: 書類の種類が変更になったので追加 2023_spring */}
                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領学部在学生留学生コロナ).ja}
                                href={PdfEnumToName(PdfEnum.申請要領学部在学生留学生コロナ).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領学部在学生留学生コロナ).en}
                                href={PdfEnumToName(PdfEnum.申請要領学部在学生留学生コロナ).en_url}
                                download={true}
                            />
                        </div>

                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領大学院新入生).ja}
                                href={PdfEnumToName(PdfEnum.申請要領大学院新入生).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領大学院新入生).en}
                                href={PdfEnumToName(PdfEnum.申請要領大学院新入生).en_url}
                                download={true}
                            />
                        </div>

                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領大学院在学生).ja}
                                href={PdfEnumToName(PdfEnum.申請要領大学院在学生).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.申請要領大学院在学生).en}
                                href={PdfEnumToName(PdfEnum.申請要領大学院在学生).en_url}
                                download={true}
                            />
                        </div>

                    </div>

                    <div className={"syoruiList_inner mt16"}>

                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.授業料免除額家庭状況調書様式1).ja}
                                href={PdfEnumToName(PdfEnum.授業料免除額家庭状況調書様式1).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.授業料免除額家庭状況調書様式1).en}
                                href={PdfEnumToName(PdfEnum.授業料免除額家庭状況調書様式1).en_url}
                                download={true}
                            />
                        </div>

                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.授業料徴収猶予願別紙様式).ja}
                                href={PdfEnumToName(PdfEnum.授業料徴収猶予願別紙様式).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.授業料徴収猶予願別紙様式).en}
                                href={PdfEnumToName(PdfEnum.授業料徴収猶予願別紙様式).en_url}
                                download={true}
                            />
                        </div>

                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.入学料免除入学料徴収猶予願家庭状況調書別紙様式1).ja}
                                href={PdfEnumToName(PdfEnum.入学料免除入学料徴収猶予願家庭状況調書別紙様式1).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.入学料免除入学料徴収猶予願家庭状況調書別紙様式1).en}
                                href={PdfEnumToName(PdfEnum.入学料免除入学料徴収猶予願家庭状況調書別紙様式1).en_url}
                                download={true}
                            />
                        </div>

                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.大学等における修学の支援に関する法律による授業料等減免の対象者の認定に関する申請書A様式1).ja}
                                href={PdfEnumToName(PdfEnum.大学等における修学の支援に関する法律による授業料等減免の対象者の認定に関する申請書A様式1).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.大学等における修学の支援に関する法律による授業料等減免の対象者の認定に関する申請書A様式1).en}
                                href={PdfEnumToName(PdfEnum.大学等における修学の支援に関する法律による授業料等減免の対象者の認定に関する申請書A様式1).en_url}
                                download={true}
                            />
                        </div>

                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.大学等における修学の支援に関する法律による授業料減免の対象者の認定の継続に関する申請書A様式2).ja}
                                href={PdfEnumToName(PdfEnum.大学等における修学の支援に関する法律による授業料減免の対象者の認定の継続に関する申請書A様式2).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.大学等における修学の支援に関する法律による授業料減免の対象者の認定の継続に関する申請書A様式2).en}
                                href={PdfEnumToName(PdfEnum.大学等における修学の支援に関する法律による授業料減免の対象者の認定の継続に関する申請書A様式2).en_url}
                                download={true}
                            />
                        </div>

                    </div>

                    <Box mt={2} color={TextColorsEnum.Red} fontWeight={700}>
                        {convertEnumNameToStr(SummaryTextEnumToName(SummaryTextEnum.上記以外にも提出が必要な書類があります必ず申請要領で確認して申請してください))}
                    </Box>

                </div>

            </div>

            <div className={`${classes.section} mt24`}>

                <Title04
                    title={convertEnumNameToStr(SyoruiTextEnumToName(SyoruiTextEnum.証明書類))}
                />

                <div className={'section_dsc'}>
                    {convertEnumNameToStr(SyoruiTextEnumToName(SyoruiTextEnum.申請要領の提出する証明書類についての項目や))}
                </div>

                {/*<div className={"section_syoruiListLine"}/>*/}

                <div className={`section_syoruiList ${classes.syoruiList}`}>

                    <div className={"syoruiList_inner"}>

                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.別紙1).ja}
                                href={PdfEnumToName(PdfEnum.別紙1).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.別紙1).en}
                                href={PdfEnumToName(PdfEnum.別紙1).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.別紙2).ja}
                                href={PdfEnumToName(PdfEnum.別紙2).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.別紙2).en}
                                href={PdfEnumToName(PdfEnum.別紙2).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.奨学金受給等申告書様式2).ja}
                                href={PdfEnumToName(PdfEnum.奨学金受給等申告書様式2).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.奨学金受給等申告書様式2).en}
                                href={PdfEnumToName(PdfEnum.奨学金受給等申告書様式2).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.手当等申告書様式3).ja}
                                href={PdfEnumToName(PdfEnum.手当等申告書様式3).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.手当等申告書様式3).en}
                                href={PdfEnumToName(PdfEnum.手当等申告書様式3).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.家庭事情申立書様式4).ja}
                                href={PdfEnumToName(PdfEnum.家庭事情申立書様式4).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.家庭事情申立書様式4).en}
                                href={PdfEnumToName(PdfEnum.家庭事情申立書様式4).en_url}
                                download={true}
                            />
                        </div>


                        {/* NOTE: 削除可能 念の為コメントアウト */}
                        {/*<div className={"syoruiList_inner_item"}>*/}
                        {/*    <TextLink*/}
                        {/*        icon={<PictureAsPdf className={'icon'}/>}*/}
                        {/*        text={PdfEnumToName(PdfEnum.無職について様式42).ja}*/}
                        {/*        href={PdfEnumToName(PdfEnum.無職について様式42).ja_url}*/}
                        {/*        download={true}*/}
                        {/*    />*/}
                        {/*    {" / "}*/}
                        {/*    <TextLink*/}
                        {/*        icon={<PictureAsPdf className={'icon'}/>}*/}
                        {/*        text={PdfEnumToName(PdfEnum.無職について様式42).en}*/}
                        {/*        href={PdfEnumToName(PdfEnum.無職について様式42).en_url}*/}
                        {/*        download={true}*/}
                        {/*    />*/}
                        {/*</div>*/}


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.アルバイト証明書様式5).ja}
                                href={PdfEnumToName(PdfEnum.アルバイト証明書様式5).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.アルバイト証明書様式5).en}
                                href={PdfEnumToName(PdfEnum.アルバイト証明書様式5).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.給与見込証明書様式6).ja}
                                href={PdfEnumToName(PdfEnum.給与見込証明書様式6).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.給与見込証明書様式6).en}
                                href={PdfEnumToName(PdfEnum.給与見込証明書様式6).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.在学状況等証明書様式7).ja}
                                href={PdfEnumToName(PdfEnum.在学状況等証明書様式7).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.在学状況等証明書様式7).en}
                                href={PdfEnumToName(PdfEnum.在学状況等証明書様式7).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.退離職証明書様式8).ja}
                                href={PdfEnumToName(PdfEnum.退離職証明書様式8).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.退離職証明書様式8).en}
                                href={PdfEnumToName(PdfEnum.退離職証明書様式8).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.無職証明書様式9).ja}
                                href={PdfEnumToName(PdfEnum.無職証明書様式9).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.無職証明書様式9).en}
                                href={PdfEnumToName(PdfEnum.無職証明書様式9).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.長期療養証明書様式10).ja}
                                href={PdfEnumToName(PdfEnum.長期療養証明書様式10).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.長期療養証明書様式10).en}
                                href={PdfEnumToName(PdfEnum.長期療養証明書様式10).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.生活状況調書独立生計者私費外国人留学生様式11).ja}
                                href={PdfEnumToName(PdfEnum.生活状況調書独立生計者私費外国人留学生様式11).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.生活状況調書独立生計者私費外国人留学生様式11).en}
                                href={PdfEnumToName(PdfEnum.生活状況調書独立生計者私費外国人留学生様式11).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.介護サービス証明書様式12).ja}
                                href={PdfEnumToName(PdfEnum.介護サービス証明書様式12).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.介護サービス証明書様式12).en}
                                href={PdfEnumToName(PdfEnum.介護サービス証明書様式12).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.配偶者用在学状況等証明書様式13).ja}
                                href={PdfEnumToName(PdfEnum.配偶者用在学状況等証明書様式13).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.配偶者用在学状況等証明書様式13).en}
                                href={PdfEnumToName(PdfEnum.配偶者用在学状況等証明書様式13).en_url}
                                download={true}
                            />
                        </div>


                        <div className={"syoruiList_inner_item"}>
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.新型コロナウイルス感染症関係).ja}
                                href={PdfEnumToName(PdfEnum.新型コロナウイルス感染症関係).ja_url}
                                download={true}
                            />
                            {" / "}
                            <TextLink
                                icon={<PictureAsPdf className={'icon'}/>}
                                text={PdfEnumToName(PdfEnum.新型コロナウイルス感染症関係).en}
                                href={PdfEnumToName(PdfEnum.新型コロナウイルス感染症関係).en_url}
                                download={true}
                            />
                        </div>

                    </div>

                </div>


            </div>

            <DialogActions className={"modal_actions"}>

                <Button
                    type={"default"}
                    label={"閉じる"}
                    width={"120px"}
                    handleClick={props.handleClickSyoruiModalClose}
                />

            </DialogActions>

        </Modal>

    );
};

const useStyles = makeStyles({
    section: {
        "& .section_dsc": {
            marginTop: 8,
        },
        "& .section_syoruiList": {
            marginTop: 16
        },
        "& .section_syoruiListLine": {
            marginTop: 16,
            borderTop: `1px ${BorderColorsEnum.LightGray} solid`,
        },
    },
    syoruiList: {
        "& .syoruiList_inner": {
            "& > *:not(:first-child)": {
                marginTop: 8,
            },
            "&.syoruiList_inner-sinseiyoryo": {
                backgroundColor: BgColorsEnum.OffWhite,
                padding: "12px 16px",
            }
        }
    },
});
