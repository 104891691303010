import DialogActions from "@material-ui/core/DialogActions";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {Button} from "components/atoms/Button";
import {Modal} from "components/atoms/Modal";
import React from 'react';
import {Box} from "@material-ui/core";

interface Props {
    openState: boolean
    handleClickClose: () => void
    handleClickKakutei: () => void
}

export const AYosikiKakuteiModal: React.FC<Props> = (props) => {

    return (

        <Modal
            openState={props.openState}
            title={"確定 / Confirm"}
        >

            <Box>
                確定すると書類をダウンロードできます。<br />
                編集を行う場合は、確定を解除してください。<br />
                確定しますか？<br />
                Once confirmed, the documents can be downloaded.<br />
                To edit, cancel the confirmation.<br />
                Confirm?
            </Box>

            <DialogActions className={"modal_actions"}>
                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.閉じる)}
                    handleClick={props.handleClickClose}
                />
                <Button
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                    handleClick={props.handleClickKakutei}
                />
            </DialogActions>

        </Modal>

    );
};


