import {Configuration} from "openapi";

export const apiConfiguration = new Configuration({
    basePath: createBasePath()
});


function createBasePath() {
    if (process.env.REACT_APP_TARGET_SERVER === "mock_server") {
        return "http://localhost:4010"
    } else {
        return window.location.origin
    }
}