import DialogActions from "@material-ui/core/DialogActions";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {Button} from "components/atoms/Button";
import React from "react";

interface TokubetuKojoNoMemberModalContent {
    handleClickClose: () => void
}

export const TokubetuKojoNoMemberModalContent: React.FC<TokubetuKojoNoMemberModalContent> = (props) => {
    return (
        <div>

            <div>
                家族構成を入力されていないため続柄を追加できません。<br/>
                「家庭状況 {`>`} 家族構成」の項目から続柄を追加してください。
            </div>

            <DialogActions className={"modal_actions"}>
                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.キャンセル)}
                    width={"152px"}
                    handleClick={props.handleClickClose}
                />
            </DialogActions>

        </div>
    )
};