import React from 'react';

export const ModalContentKoyohokenRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                <span className={"underLine"}>雇用保険受給資格証</span>の<span className={"fw700"}>“基本手当日額”× “所定給付日数”</span>の額を記入すること。
            </div>

            <div className={"modalContent_item"}>
                Enter the amount calculated by <span className={"fw700"}>“Daily amount of basic allowance” x “Number of days of benefit payment”</span>
                indicated on the <span className={"underLine"}>Certificate of Receipt of Unemployment Insurance Benefits.</span>
            </div>

        </div>

    );
};
