/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwBosiHusiFatherErrorMessage,
    SwBosiHusiFatherErrorMessageFromJSON,
    SwBosiHusiFatherErrorMessageFromJSONTyped,
    SwBosiHusiFatherErrorMessageToJSON,
    SwBosiHusiMotherErrorMessage,
    SwBosiHusiMotherErrorMessageFromJSON,
    SwBosiHusiMotherErrorMessageFromJSONTyped,
    SwBosiHusiMotherErrorMessageToJSON,
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwBosiHusiErrorMessage
 */
export interface SwBosiHusiErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwBosiHusiErrorMessage
     */
    base?: Array<SwErrorMessage>;
    /**
     * 
     * @type {SwBosiHusiMotherErrorMessage}
     * @memberof SwBosiHusiErrorMessage
     */
    mother?: SwBosiHusiMotherErrorMessage;
    /**
     * 
     * @type {SwBosiHusiFatherErrorMessage}
     * @memberof SwBosiHusiErrorMessage
     */
    father?: SwBosiHusiFatherErrorMessage;
}

export function SwBosiHusiErrorMessageFromJSON(json: any): SwBosiHusiErrorMessage {
    return SwBosiHusiErrorMessageFromJSONTyped(json, false);
}

export function SwBosiHusiErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwBosiHusiErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base': !exists(json, 'base') ? undefined : ((json['base'] as Array<any>).map(SwErrorMessageFromJSON)),
        'mother': !exists(json, 'mother') ? undefined : SwBosiHusiMotherErrorMessageFromJSON(json['mother']),
        'father': !exists(json, 'father') ? undefined : SwBosiHusiFatherErrorMessageFromJSON(json['father']),
    };
}

export function SwBosiHusiErrorMessageToJSON(value?: SwBosiHusiErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base': value.base === undefined ? undefined : ((value.base as Array<any>).map(SwErrorMessageToJSON)),
        'mother': SwBosiHusiMotherErrorMessageToJSON(value.mother),
        'father': SwBosiHusiFatherErrorMessageToJSON(value.father),
    };
}


