export const URL_PATHS_STUDENT = {
    login: "#/login", // ログイン
    top: "#/", // トップ
    send_reset_password_link: "#/send_reset_password_link", // パスワード再発行リンク送信
    send_reset_password_link_complete: "#/send_reset_password_link_complete", // パスワード再発行リンク送信
    reset_password: "#/reset_password", // パスワード再発行
    reset_password_complete: "#/reset_password_complete", // パスワード再発行
    send_create_account_link_new_students: "#/send_create_account_link_new_students", // 新入生アカウント作成リンク送信
    send_create_account_link_new_students_complete: "#/send_create_account_link_new_students_complete", // 新入生アカウント作成リンク送信
    send_create_account_link: "#/send_create_account_link", // 在学生アカウント作成リンク送信
    send_create_account_link_complete: "#/send_create_account_link_complete", // 在学生アカウント作成リンク送信

    create_account_new_students: "#/create_account_new_students", // 新入生アカウント作成
    create_account_new_students_complete: "#/create_account_new_students_complete", // 新入生アカウント作成
    create_account: "#/create_account", // 在学生アカウント作成
    create_account_complete: "#/create_account_complete", // 在学生アカウント作成

    yosiki_detail: "#/yosiki_detail", // 様式詳細
    yosiki_edit: "#/yosiki_edit", // 様式入力

    syorui_confirmation_form: "#/syorui_confirmation_form", // 必要な様式の確認機能のフォーム
    syorui_confirmation: "#/syorui_confirmation", // 必要な様式の確認

    a_yosiki_form: "#/a_yosiki_form", // A様式フォーム
};


