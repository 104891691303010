/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSienzumi,
    SwSienzumiFromJSON,
    SwSienzumiFromJSONTyped,
    SwSienzumiToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiFormAYosiki1
 */
export interface SwAYosikiFormAYosiki1 {
    /**
     * 
     * @type {boolean}
     * @memberof SwAYosikiFormAYosiki1
     */
    agreement: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwAYosikiFormAYosiki1
     */
    isSienzumi?: boolean;
    /**
     * 
     * @type {SwSienzumi}
     * @memberof SwAYosikiFormAYosiki1
     */
    sienzumi?: SwSienzumi;
    /**
     * 
     * @type {boolean}
     * @memberof SwAYosikiFormAYosiki1
     */
    menjoBefore?: boolean;
    /**
     * enum
     * @type {string}
     * @memberof SwAYosikiFormAYosiki1
     */
    mosikomiType?: string;
    /**
     * 
     * @type {string}
     * @memberof SwAYosikiFormAYosiki1
     */
    uketukeNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SwAYosikiFormAYosiki1
     */
    syogakuseiNumber: string;
}

export function SwAYosikiFormAYosiki1FromJSON(json: any): SwAYosikiFormAYosiki1 {
    return SwAYosikiFormAYosiki1FromJSONTyped(json, false);
}

export function SwAYosikiFormAYosiki1FromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormAYosiki1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreement': json['agreement'],
        'isSienzumi': !exists(json, 'isSienzumi') ? undefined : json['isSienzumi'],
        'sienzumi': !exists(json, 'sienzumi') ? undefined : SwSienzumiFromJSON(json['sienzumi']),
        'menjoBefore': !exists(json, 'menjoBefore') ? undefined : json['menjoBefore'],
        'mosikomiType': !exists(json, 'mosikomiType') ? undefined : json['mosikomiType'],
        'uketukeNumber': json['uketukeNumber'],
        'syogakuseiNumber': json['syogakuseiNumber'],
    };
}

export function SwAYosikiFormAYosiki1ToJSON(value?: SwAYosikiFormAYosiki1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreement': value.agreement,
        'isSienzumi': value.isSienzumi,
        'sienzumi': SwSienzumiToJSON(value.sienzumi),
        'menjoBefore': value.menjoBefore,
        'mosikomiType': value.mosikomiType,
        'uketukeNumber': value.uketukeNumber,
        'syogakuseiNumber': value.syogakuseiNumber,
    };
}


