/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SyoruiConfirmationHonnin,
    SyoruiConfirmationHonninFromJSON,
    SyoruiConfirmationHonninFromJSONTyped,
    SyoruiConfirmationHonninToJSON,
    SyoruiConfirmationHonninRyugakusei,
    SyoruiConfirmationHonninRyugakuseiFromJSON,
    SyoruiConfirmationHonninRyugakuseiFromJSONTyped,
    SyoruiConfirmationHonninRyugakuseiToJSON,
    SyoruiConfirmationMember,
    SyoruiConfirmationMemberFromJSON,
    SyoruiConfirmationMemberFromJSONTyped,
    SyoruiConfirmationMemberToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSyoruiConfirmationResponse
 */
export interface SwSyoruiConfirmationResponse {
    /**
     * 
     * @type {SyoruiConfirmationHonnin}
     * @memberof SwSyoruiConfirmationResponse
     */
    honnin?: SyoruiConfirmationHonnin;
    /**
     * 
     * @type {SyoruiConfirmationHonninRyugakusei}
     * @memberof SwSyoruiConfirmationResponse
     */
    honninRyugakusei?: SyoruiConfirmationHonninRyugakusei;
    /**
     * 
     * @type {Array<SyoruiConfirmationMember>}
     * @memberof SwSyoruiConfirmationResponse
     */
    members?: Array<SyoruiConfirmationMember>;
}

export function SwSyoruiConfirmationResponseFromJSON(json: any): SwSyoruiConfirmationResponse {
    return SwSyoruiConfirmationResponseFromJSONTyped(json, false);
}

export function SwSyoruiConfirmationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyoruiConfirmationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'honnin': !exists(json, 'honnin') ? undefined : SyoruiConfirmationHonninFromJSON(json['honnin']),
        'honninRyugakusei': !exists(json, 'honninRyugakusei') ? undefined : SyoruiConfirmationHonninRyugakuseiFromJSON(json['honninRyugakusei']),
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(SyoruiConfirmationMemberFromJSON)),
    };
}

export function SwSyoruiConfirmationResponseToJSON(value?: SwSyoruiConfirmationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'honnin': SyoruiConfirmationHonninToJSON(value.honnin),
        'honninRyugakusei': SyoruiConfirmationHonninRyugakuseiToJSON(value.honninRyugakusei),
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(SyoruiConfirmationMemberToJSON)),
    };
}


