/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwAYosikiFormAYosiki1ErrorMessage,
    SwAYosikiFormAYosiki1ErrorMessageFromJSON,
    SwAYosikiFormAYosiki1ErrorMessageFromJSONTyped,
    SwAYosikiFormAYosiki1ErrorMessageToJSON,
    SwAYosikiFormAYosiki2ErrorMessage,
    SwAYosikiFormAYosiki2ErrorMessageFromJSON,
    SwAYosikiFormAYosiki2ErrorMessageFromJSONTyped,
    SwAYosikiFormAYosiki2ErrorMessageToJSON,
    SwAYosikiFormCommonErrorMessage,
    SwAYosikiFormCommonErrorMessageFromJSON,
    SwAYosikiFormCommonErrorMessageFromJSONTyped,
    SwAYosikiFormCommonErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiFormErrorMessageResponse
 */
export interface SwAYosikiFormErrorMessageResponse {
    /**
     * 
     * @type {SwAYosikiFormCommonErrorMessage}
     * @memberof SwAYosikiFormErrorMessageResponse
     */
    common?: SwAYosikiFormCommonErrorMessage;
    /**
     * 
     * @type {SwAYosikiFormAYosiki1ErrorMessage}
     * @memberof SwAYosikiFormErrorMessageResponse
     */
    aYosiki1?: SwAYosikiFormAYosiki1ErrorMessage;
    /**
     * 
     * @type {SwAYosikiFormAYosiki2ErrorMessage}
     * @memberof SwAYosikiFormErrorMessageResponse
     */
    aYosiki2?: SwAYosikiFormAYosiki2ErrorMessage;
}

export function SwAYosikiFormErrorMessageResponseFromJSON(json: any): SwAYosikiFormErrorMessageResponse {
    return SwAYosikiFormErrorMessageResponseFromJSONTyped(json, false);
}

export function SwAYosikiFormErrorMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormErrorMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'common': !exists(json, 'common') ? undefined : SwAYosikiFormCommonErrorMessageFromJSON(json['common']),
        'aYosiki1': !exists(json, 'aYosiki1') ? undefined : SwAYosikiFormAYosiki1ErrorMessageFromJSON(json['aYosiki1']),
        'aYosiki2': !exists(json, 'aYosiki2') ? undefined : SwAYosikiFormAYosiki2ErrorMessageFromJSON(json['aYosiki2']),
    };
}

export function SwAYosikiFormErrorMessageResponseToJSON(value?: SwAYosikiFormErrorMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'common': SwAYosikiFormCommonErrorMessageToJSON(value.common),
        'aYosiki1': SwAYosikiFormAYosiki1ErrorMessageToJSON(value.aYosiki1),
        'aYosiki2': SwAYosikiFormAYosiki2ErrorMessageToJSON(value.aYosiki2),
    };
}


