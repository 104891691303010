// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum BukyokuEnum {
    農学 = "E農学",
    工学 = "E工学",
    農府 = "E農府",
    工府 = "E工府",
    生府 = "E生府",
    農博 = "E農博",
}

export function bukyokuEnumToName(e: BukyokuEnum): { ja: string, en: string } {
    switch (e) {
        case BukyokuEnum.農学: return { ja: "農学部", en: "Faculty of Agriculture" };
        case BukyokuEnum.工学: return { ja: "工学部", en: "Faculty of Engineering" };
        case BukyokuEnum.農府: return { ja: "農学府", en: "Graduate School of Agriculture" };
        case BukyokuEnum.工府: return { ja: "工学府", en: "Graduate School of Engineering" };
        case BukyokuEnum.生府: return { ja: "生物システム応用科学府", en: "Graduate School of Bio-Applications and Systems Engineering" };
        case BukyokuEnum.農博: return { ja: "連合農学研究科", en: "United Graduate School of Agricultural Science" };
    }
}