/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwTyokiryoyoError,
    SwTyokiryoyoErrorFromJSON,
    SwTyokiryoyoErrorFromJSONTyped,
    SwTyokiryoyoErrorToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwTyokiryoyoErrorMessage
 */
export interface SwTyokiryoyoErrorMessage {
    /**
     * 長期療養者が有効で続柄が選択されていない場合にエラーを返す。 
     * @type {Array<SwErrorMessage>}
     * @memberof SwTyokiryoyoErrorMessage
     */
    policy?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwTyokiryoyoError>}
     * @memberof SwTyokiryoyoErrorMessage
     */
    tyokiryoyoErrors?: Array<SwTyokiryoyoError>;
}

export function SwTyokiryoyoErrorMessageFromJSON(json: any): SwTyokiryoyoErrorMessage {
    return SwTyokiryoyoErrorMessageFromJSONTyped(json, false);
}

export function SwTyokiryoyoErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwTyokiryoyoErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policy': !exists(json, 'policy') ? undefined : ((json['policy'] as Array<any>).map(SwErrorMessageFromJSON)),
        'tyokiryoyoErrors': !exists(json, 'tyokiryoyoErrors') ? undefined : ((json['tyokiryoyoErrors'] as Array<any>).map(SwTyokiryoyoErrorFromJSON)),
    };
}

export function SwTyokiryoyoErrorMessageToJSON(value?: SwTyokiryoyoErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policy': value.policy === undefined ? undefined : ((value.policy as Array<any>).map(SwErrorMessageToJSON)),
        'tyokiryoyoErrors': value.tyokiryoyoErrors === undefined ? undefined : ((value.tyokiryoyoErrors as Array<any>).map(SwTyokiryoyoErrorToJSON)),
    };
}


