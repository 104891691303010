export class YearModel {
    // 西暦年
    year: number;

    constructor(year: number) {
        this.year = year
    }

    get 和暦年(): string {
        if (this.year <= 2018) throw new Error("???");
        return `令和${this.year - 2018}年`
    }

    get 西暦年(): string {
        return this.year.toString()
    }

    inc(i=1): YearModel {
        return new YearModel(this.year + i)
    }
    dec(i=1): YearModel {
        return new YearModel(this.year - i)
    }
}
