import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {InfoPolicyRequireTokubetuKojoInfoVo, TokubetuKojoInfoVo} from "common/models/YosikiInfoModels";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {Title04} from "components/atoms/titles/Title04";
import {TokubetuKojoBekkyoDetail} from "components/molecules/yosikiDetail/tokubetu_kojo/TokubetuKojoBekkyoDetail";
import {TokubetuKojoBoshiHusiDetail} from "components/molecules/yosikiDetail/tokubetu_kojo/TokubetuKojoBoshiHusiDetail";
import {TokubetuKojoSaigaihigaiDetail} from "components/molecules/yosikiDetail/tokubetu_kojo/TokubetuKojoSaigaihigaiDetail";
import {TokubetuKojoSyogaisyaDetail} from "components/molecules/yosikiDetail/tokubetu_kojo/TokubetuKojoSyogaisyaDetail";
import {TokubetuKojoTyokiryoyoDetail} from "components/molecules/yosikiDetail/tokubetu_kojo/TokubetuKojoTyokiryoyoDetail";
import React, {FC} from "react";

interface TokubetuKojoInfoDetailSectionProps {
    className?: string
    requireTokubetuKojoInfo: InfoPolicyRequireTokubetuKojoInfoVo
    tokubetuKojoInfo: TokubetuKojoInfoVo;
}

export const _TokubetuKojoInfoDetailSection: FC<TokubetuKojoInfoDetailSectionProps> = (props) => {

    const classes = useStyles();

    const tokubetuKojoInfo: TokubetuKojoInfoVo = props.tokubetuKojoInfo;

    const bosiHusi = tokubetuKojoInfo.bosiHusi;
    const syogaisya = tokubetuKojoInfo.syogaisya;
    const tyokiryoyo = tokubetuKojoInfo.tyokiryoyo;
    const bekkyo = tokubetuKojoInfo.bekkyo;
    const saigaihigai = tokubetuKojoInfo.saigaihigai;

    return (

        <div id={SECTION_ID_NAME.tokubetuKojoInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.特別控除)}
                className={"section_title"}
            />

            {
                props.requireTokubetuKojoInfo.bosiHusi &&
                <TokubetuKojoBoshiHusiDetail
                    bosiHusi={bosiHusi}
                />
            }

            {
                props.requireTokubetuKojoInfo.syogaisya &&
                <TokubetuKojoSyogaisyaDetail
                    syogaisya={syogaisya}
                />
            }

            {
                props.requireTokubetuKojoInfo.tyokiryoyo &&
                <TokubetuKojoTyokiryoyoDetail
                    tyokiryoyo={tyokiryoyo}
                />
            }

            {
                props.requireTokubetuKojoInfo.bekkyo &&
                <TokubetuKojoBekkyoDetail
                    bekkyo={bekkyo}
                />
            }

            {
                props.requireTokubetuKojoInfo.saigaihigai &&
                <TokubetuKojoSaigaihigaiDetail
                    saigaihigai={saigaihigai}
                />
            }

        </div>

    )

};

export const TokubetuKojoInfoDetailSection = React.memo<TokubetuKojoInfoDetailSectionProps>(_TokubetuKojoInfoDetailSection, (prev, next) => {
    return prev.className === next.className
        && prev.requireTokubetuKojoInfo === next.requireTokubetuKojoInfo
        && prev.tokubetuKojoInfo === next.tokubetuKojoInfo
});

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        }
    }
);

