/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwOfficerKikansStudentIdKikans,
    SwOfficerKikansStudentIdKikansFromJSON,
    SwOfficerKikansStudentIdKikansFromJSONTyped,
    SwOfficerKikansStudentIdKikansToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerKikansStudentId
 */
export interface SwOfficerKikansStudentId {
    /**
     * 
     * @type {SwOfficerKikansStudentIdKikans}
     * @memberof SwOfficerKikansStudentId
     */
    kikans?: SwOfficerKikansStudentIdKikans;
}

export function SwOfficerKikansStudentIdFromJSON(json: any): SwOfficerKikansStudentId {
    return SwOfficerKikansStudentIdFromJSONTyped(json, false);
}

export function SwOfficerKikansStudentIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerKikansStudentId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kikans': !exists(json, 'kikans') ? undefined : SwOfficerKikansStudentIdKikansFromJSON(json['kikans']),
    };
}

export function SwOfficerKikansStudentIdToJSON(value?: SwOfficerKikansStudentId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kikans': SwOfficerKikansStudentIdKikansToJSON(value.kikans),
    };
}


