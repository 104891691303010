import React from 'react';
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

interface SyoruiConfirmationFormItemComponentProps {
    label: string
    indent?: number
}

export const FormItem: React.FC<SyoruiConfirmationFormItemComponentProps> = ({label, indent = 0, children}) => {
    return (
        <Grid item xs>
            <Box pl={indent}>
                <Box fontWeight={700}>
                    {label}
                </Box>

                <Box mt={0.5}>
                    {children}
                </Box>
            </Box>
        </Grid>
    )
}

