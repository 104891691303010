/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwInfoPolicyRequireSinseisyaInfo
 */
export interface SwInfoPolicyRequireSinseisyaInfo {
    /**
     * 
     * @type {boolean}
     * @memberof SwInfoPolicyRequireSinseisyaInfo
     */
    addressFamily: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwInfoPolicyRequireSinseisyaInfo
     */
    telFamily: boolean;
}

export function SwInfoPolicyRequireSinseisyaInfoFromJSON(json: any): SwInfoPolicyRequireSinseisyaInfo {
    return SwInfoPolicyRequireSinseisyaInfoFromJSONTyped(json, false);
}

export function SwInfoPolicyRequireSinseisyaInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwInfoPolicyRequireSinseisyaInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressFamily': json['addressFamily'],
        'telFamily': json['telFamily'],
    };
}

export function SwInfoPolicyRequireSinseisyaInfoToJSON(value?: SwInfoPolicyRequireSinseisyaInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressFamily': value.addressFamily,
        'telFamily': value.telFamily,
    };
}


