import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {SeisekiInfoVo} from "common/models/YosikiInfoModels";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {SeisekiInfoLabelTextEnum, SeisekiInfoLabelEnumToNameJaAndEn} from "common/enums_text/yosiki/SeisekiInfoLabelTextEnum";
import {Title04} from "components/atoms/titles/Title04";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import React, {FC} from "react";

interface SeisekiInfoDetailSectionProps {
    className?: string
    seisekiInfo: SeisekiInfoVo
}

export const _SeisekiInfoDetailSection: FC<SeisekiInfoDetailSectionProps> = (props) => {

    const classes = useStyles();

    const seisekiInfo: SeisekiInfoVo = props.seisekiInfo;

    return (
        <div id={SECTION_ID_NAME.seisekiInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.学業成績)}
                className={"section_title"}
            />

            <FormTable
                className={"section_table"}
            >

                <FormTableRow
                    label={SeisekiInfoLabelEnumToNameJaAndEn(SeisekiInfoLabelTextEnum.前年度習得単位数)}
                >

                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {SeisekiInfoLabelEnumToNameJaAndEn(SeisekiInfoLabelTextEnum.学部1年生は無記入とし)}
                        </div>

                        <div className={`${classes.topRow}`}>
                            <FieldWithLabel
                                label={"S・A"}
                                width={"72px"}
                            >
                                {seisekiInfo.SA}単位
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={"B"}
                                width={"72px"}
                            >
                                {seisekiInfo.B}単位
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={"C"}
                                width={"72px"}
                            >
                                {seisekiInfo.C}単位
                            </FieldWithLabel>
                        </div>

                    </div>

                    <div className={"formTableRow_item"}>
                        <FieldWithLabel
                            label={SeisekiInfoLabelEnumToNameJaAndEn(SeisekiInfoLabelTextEnum.前年度までの全学年での総修得単位数)}
                        >
                            {seisekiInfo.total}単位
                        </FieldWithLabel>
                    </div>

                </FormTableRow>


            </FormTable>

        </div>

    )

};

export const SeisekiInfoDetailSection = React.memo<SeisekiInfoDetailSectionProps>(_SeisekiInfoDetailSection, (prev, next) => {
    return prev.className === next.className
        && prev.seisekiInfo === next.seisekiInfo
});

const useStyles = makeStyles({
        root: {},
        topRow: {
            display: "flex",
            alignItems: "baseline",
            "& > *:not(:first-child)": {
                marginLeft: 4
            }
        },
    }
);

