/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwBosiHusiParent
 */
export interface SwBosiHusiParent {
    /**
     * enum
     * @type {string}
     * @memberof SwBosiHusiParent
     */
    beturiType?: string;
    /**
     * 
     * @type {number}
     * @memberof SwBosiHusiParent
     */
    jikiYaer?: number;
    /**
     * 
     * @type {number}
     * @memberof SwBosiHusiParent
     */
    jikiMonth?: number;
    /**
     * 
     * @type {string}
     * @memberof SwBosiHusiParent
     */
    riyu: string;
}

export function SwBosiHusiParentFromJSON(json: any): SwBosiHusiParent {
    return SwBosiHusiParentFromJSONTyped(json, false);
}

export function SwBosiHusiParentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwBosiHusiParent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'beturiType': !exists(json, 'beturiType') ? undefined : json['beturiType'],
        'jikiYaer': !exists(json, 'jikiYaer') ? undefined : json['jikiYaer'],
        'jikiMonth': !exists(json, 'jikiMonth') ? undefined : json['jikiMonth'],
        'riyu': json['riyu'],
    };
}

export function SwBosiHusiParentToJSON(value?: SwBosiHusiParent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'beturiType': value.beturiType,
        'jikiYaer': value.jikiYaer,
        'jikiMonth': value.jikiMonth,
        'riyu': value.riyu,
    };
}


