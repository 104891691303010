import {ScrollTop} from "components/atoms/ScrollTop";
import 'index.css';
import {CreateAccount} from "pages/student/createAccount/CreateAccount";
import {CreateAccountComplete} from "pages/student/createAccount/CreateAccountComplete";
import {Login} from "pages/student/login/Login";
import {ResetPassword} from "pages/student/resetPassword/ResetPassword";
import {ResetPasswordComplete} from "pages/student/resetPassword/ResetPasswordComplete";
import {RouteWrapper} from "pages/student/routeWrapper";
import {SendCreateAccountLink} from "pages/student/sendCreateAccountLink/SendCreateAccountLink";
import {SendCreateAccountLinkComplete} from "pages/student/sendCreateAccountLink/SendCreateAccountLinkComplete";
import {SendResetPasswordLink} from "pages/student/sendResetPasswordLink/SendResetPasswordLink";
import {SendResetPasswordLinkComplete} from "pages/student/sendResetPasswordLink/SendResetPasswordLinkComplete";
import {Summary} from "pages/student/summary/Summary";
import {YosikiDetail} from "pages/student/yosiki/YosikiDetail";
import {YosikiEdit} from "pages/student/yosiki/YosikiEdit";
import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch} from 'react-router';
import {HashRouter} from "react-router-dom";
import {SyoruiConfirmationFormPage} from "./syoruiConfirmationForm/SyoruiConfirmationFormPage";
import {SyoruiConfirmationPage} from "./syoruiConfirmation/SyoruiConfirmationPage";
import {SendCreateAccountLinkNewStudents} from "./sendCreateAccountLinkNewStudents/SendCreateAccountLinkNewStudents";
import {
    SendCreateAccountLinkNewStudentsComplete
} from "./sendCreateAccountLinkNewStudents/SendCreateAccountLinkNewStudentsComplete";
import {CreateAccountNewStudents} from "./createAccountNewStudents/CreateAccountNewStudents";
import {CreateAccountNewStudentsComplete} from "./createAccountNewStudents/CreateAccountNewStudentsComplete";
import {StudentAYosikiFormPage} from "./aYosikiForm/StudentAYosikiFormPage";

// ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
// SPA にしないときは HashRouter 使う。
ReactDOM.render(
    <HashRouter>
        <ScrollTop/>
        <Switch>
            <Route exact path="/login" component={Login}/>

            <Route exact path="/send_reset_password_link" component={SendResetPasswordLink}/>
            <Route exact path="/send_reset_password_link_complete" component={SendResetPasswordLinkComplete}/>
            <Route exact path="/reset_password" component={ResetPassword}/>
            <Route exact path="/reset_password_complete" component={ResetPasswordComplete}/>

            {/* 新入生アカウント作成 */}
            <Route exact path="/send_create_account_link_new_students" component={SendCreateAccountLinkNewStudents}/>
            <Route exact path="/send_create_account_link_new_students_complete" component={SendCreateAccountLinkNewStudentsComplete}/>
            <Route exact path="/create_account_new_students" component={CreateAccountNewStudents}/>
            <Route exact path="/create_account_new_students_complete" component={CreateAccountNewStudentsComplete}/>

            {/* 在学生アカウント作成 */}
            <Route exact path="/send_create_account_link" component={SendCreateAccountLink}/>
            <Route exact path="/send_create_account_link_complete" component={SendCreateAccountLinkComplete}/>
            <Route exact path="/create_account" component={CreateAccount}/>
            <Route exact path="/create_account_complete" component={CreateAccountComplete}/>

            <RouteWrapper>
                <Route exact path="/" component={Summary}/>
                <Route exact path="/yosiki_detail" component={YosikiDetail}/>
                <Route exact path="/yosiki_edit" component={YosikiEdit}/>
                <Route exact path="/syorui_confirmation_form" component={SyoruiConfirmationFormPage}/>
                <Route exact path="/syorui_confirmation" component={SyoruiConfirmationPage}/>
                <Route exact path="/a_yosiki_form" component={StudentAYosikiFormPage}/>
            </RouteWrapper>
        </Switch>
    </HashRouter>,
    document.getElementById('root')
);
