import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from "react";
import {TextColorsEnum} from "common/enums_value/ColorsEnum";

interface AnnotationTextProps {
    className?: string;
    text: string;
    type?: 'default' | 'warning'
}

export const _AnnotationText: FC<AnnotationTextProps> = (props) => {

    const classes = useStyles();

    let textColor;
    switch (props.type) {
        case "default":
            textColor = classes.textDefault
            break;
        case "warning":
            textColor = classes.textWarning
            break;
    }

    return (
        <div className={`${props.className} ${classes.root} ${textColor}`}>
            {props.text}
        </div>
    )

};

_AnnotationText.defaultProps = {
    type: "default"
};

export const AnnotationText = React.memo<AnnotationTextProps>(_AnnotationText, (prev, next) => {
    return prev.className === next.className
        && prev.text === next.text
        && prev.type === next.type
});

const useStyles = makeStyles({
    root: {
        fontWeight: 700,
    },
    textDefault: {
        color: TextColorsEnum.Black
    },
    textWarning: {
        color: TextColorsEnum.Red
    },
});
