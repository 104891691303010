import {ZokugaraEnum, zokugaraEnumToName} from "common/enums/ZokugaraEnum";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

// NOTE: 続柄を表示様に変換
export function convertZokugaraForStr(zokugaraEnum: ZokugaraEnum, zokugaraOther: string) {
    let zokugara = convertEnumNameToStr(zokugaraEnumToName(zokugaraEnum));
    if (zokugaraEnum === ZokugaraEnum.その他) {
        return zokugaraOther
    }
    return zokugara
}