import React from 'react';
import {SwAYosikiFormErrorMessageResponse} from "../../../../openapi";
import {AYosikiFormVo} from "../../../../common/models/AYosikiFormModels";
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import {RadioButton} from "../../../atoms/forms/RadioButton";
import {RadioButtonGroup} from "../../forms/RadioButtonGroup";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "../../../../common/enums_text/CommonTextEnum";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import {isErrorMessage} from "../../../../common/functions/isErrorMessage";
import {YearMonthDay} from "../../forms/YearMonthDay";
import {joinArraySwErrorMessage} from "../../../../common/functions/converter/joinArraySwErrorMessage";
import {convertYearMonthDayToDateStr} from "../../../../common/functions/char_converter/convertYearMonthDayToDateStr";
import {FormTableRow} from "../../forms/FormTableRow";
import {FormTable} from "../../forms/FormTable";
import {RequiredTagTypeEnum} from "../../../../common/enums_value/RequiredTagTypeEnum";
import {NumberField} from "../../../atoms/forms/NumberField";
import {AddressField} from "../../forms/AddressField";
import {FieldWithLabel} from "../../utilities/FieldWithLabel";
import {
    SinseisyaInfoTextEnum,
    SinseisyaInfoTextEnumToNameJaAndEn
} from "../../../../common/enums_text/yosiki/SinseisyaInfoTextEnum";

interface Props {
    formValues: AYosikiFormVo
    errorMessages: SwAYosikiFormErrorMessageResponse | undefined
    updates: (formValues: AYosikiFormVo, errorMessages?: SwAYosikiFormErrorMessageResponse | undefined) => void
    isKakutei: boolean
}

export const AYosikiCommonForm: React.FC<Props> = ({
                                                formValues,
                                                errorMessages,
                                                updates,
                                                isKakutei
                                            }) => {

    function handleBlurBirthYear(v: string) {
        if (!formValues) return
        formValues.common.birthYear = v
        updates(formValues, {
            ...errorMessages,
            common: {...errorMessages?.common, birthYear: undefined}
        })
    }

    function handleBlurBirthMonth(v: string) {
        if (!formValues) return
        formValues.common.birthMonth = v
        updates(formValues, {
            ...errorMessages,
            common: {...errorMessages?.common, birthMonth: undefined}
        })
    }

    function handleBlurBirthDay(v: string) {
        if (!formValues) return
        formValues.common.birthDay = v
        updates(formValues, {
            ...errorMessages,
            common: {...errorMessages?.common, birthDay: undefined}
        })
    }

    function handleBlurGakunen(v: string) {
        if (!formValues) return
        formValues.common.gakunen = v
        updates(formValues, {
            ...errorMessages,
            common: {...errorMessages?.common, gakunen: undefined}
        })
    }

    function handleBlurAddressZipCode(v: string) {
        if (!formValues) return
        formValues.common.address.zipcode = v
        updates(formValues, {
            ...errorMessages,
            common: {
                ...errorMessages?.common,
                address: {
                    ...errorMessages?.common?.address,
                    zipcode: undefined
                }
            }
        })
    }

    function handleBlurAddressAddress(v: string) {
        if (!formValues) return
        formValues.common.address.address = v
        updates(formValues, {
            ...errorMessages,
            common: {
                ...errorMessages?.common,
                address: {
                    ...errorMessages?.common?.address,
                    address: undefined
                }
            }
        })
    }

    function handleBlurZipCodeAndAddress(zipCode: string, address: string) {
        if (!formValues) return
        formValues.common.address.zipcode = zipCode
        formValues.common.address.address = address
        updates(formValues, {
            ...errorMessages,
            common: {
                ...errorMessages?.common,
                address: {
                    ...errorMessages?.common?.address,
                    zipcode: undefined,
                    address: undefined
                }
            }
        })
    }

    function handleChangeIsReceivedAlready(v: boolean) {
        if (!formValues) return
        formValues.common.nihonGakuseiSienKiko.isReceivedAlready = v
        updates(formValues, {
            ...errorMessages,
            common: {
                ...errorMessages?.common,
                nihonGakuseiSienKiko: {
                    ...errorMessages?.common?.nihonGakuseiSienKiko,
                    isReceivedAlready: undefined
                }
            }
        })
    }

    function handleChangeIsApply(v: boolean) {
        if (!formValues) return
        formValues.common.bessi1.isApply = v
        updates(formValues, {
            ...errorMessages,
            common: {
                ...errorMessages?.common,
                bessi1: {
                    ...errorMessages?.common?.bessi1,
                    isApply: undefined
                }
            }
        })
    }

    function handleChangeIsOnlyTuitionWaiver(v: boolean) {
        if (!formValues) return
        formValues.common.bessi1.isOnlyTuitionWaiver = v
        updates(formValues, {
            ...errorMessages,
            common: {
                ...errorMessages?.common,
                bessi1: {
                    ...errorMessages?.common?.bessi1,
                    isOnlyTuitionWaiver: undefined
                }
            }
        })
    }

    function handleChangeIsBeforeEnteredTwoOrMore(v: boolean) {
        if (!formValues) return
        formValues.common.bessi2.isBeforeEnteredTwoOrMore = v
        updates(formValues, {
            ...errorMessages,
            common: {
                ...errorMessages?.common,
                bessi2: {
                    ...errorMessages?.common?.bessi2,
                    isBeforeEnteredTwoOrMore: undefined
                }
            }
        })
    }

    const birthErrorMessages = joinArraySwErrorMessage([
        errorMessages?.common?.birthYear,
        errorMessages?.common?.birthMonth,
        errorMessages?.common?.birthDay
    ])

    return (
        <Grid container spacing={2} direction={"column"}>

            <Grid item xs>

                <FormTable
                    labelWidth={"200px"}
                >

                    <FormTableRow
                        label={"生年月日 / Birthday"}
                        requiredType={RequiredTagTypeEnum.必須}
                    >
                        {
                            isKakutei ? (
                                <Box>
                                    {
                                        convertYearMonthDayToDateStr(
                                            formValues.common.birthYear,
                                            formValues.common.birthMonth,
                                            formValues.common.birthDay
                                        )
                                    }
                                </Box>
                            ) : (
                                <YearMonthDay
                                    valueYear={formValues.common.birthYear}
                                    valueMonth={formValues.common.birthMonth}
                                    valueDay={formValues.common.birthDay}
                                    errorMessages={convertErrorMessageVoToArrayStr(birthErrorMessages)}
                                    isErrorYear={isErrorMessage(errorMessages?.common?.birthYear)}
                                    isErrorMonth={isErrorMessage(errorMessages?.common?.birthMonth)}
                                    isErrorDay={isErrorMessage(errorMessages?.common?.birthDay)}
                                    handleBlurYear={handleBlurBirthYear}
                                    handleBlurMonth={handleBlurBirthMonth}
                                    handleBlurDay={handleBlurBirthDay}
                                />
                            )
                        }
                    </FormTableRow>

                    <FormTableRow
                        label={"学年 / Academic Year"}
                        requiredType={RequiredTagTypeEnum.必須}
                    >
                        {
                            isKakutei ? (
                                <Box>
                                    {formValues.common.gakunen}年次
                                </Box>
                            ) : (
                                <NumberField
                                    value={formValues.common.gakunen}
                                    errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.common?.gakunen)}
                                    isError={convertErrorMessageVoToArrayStr(errorMessages?.common?.gakunen).length !== 0}
                                    widthInput={"48px"}
                                    suffixText={"年次"}
                                    handleBlur={handleBlurGakunen}
                                />
                            )
                        }
                    </FormTableRow>

                    <FormTableRow
                        label={"住所 / Address"}
                    >
                        {
                            isKakutei ? (
                                <Box>
                                    <Box>{formValues.common.address.zipcode}</Box>
                                    <Box>{formValues.common.address.address}</Box>
                                </Box>
                            ) : (
                                <AddressField
                                    valueZip={formValues.common.address.zipcode}
                                    valueAddress={formValues.common.address.address}
                                    requiredTypeZip={RequiredTagTypeEnum.必須}
                                    requiredTypeAddress={RequiredTagTypeEnum.必須}
                                    handleBlurZipCode={handleBlurAddressZipCode}
                                    handleBlurAddress={handleBlurAddressAddress}
                                    handleBlurZipCodeAndAddress={handleBlurZipCodeAndAddress}
                                    errorMessagesZip={convertErrorMessageVoToArrayStr(errorMessages?.common?.address?.zipcode)}
                                    errorMessagesAddress={convertErrorMessageVoToArrayStr(errorMessages?.common?.address?.address)}
                                />
                            )
                        }
                    </FormTableRow>

                    <FormTableRow
                        label={"日本学生支援機構"}
                    >
                        <FieldWithLabel
                            labelNode={
                                (
                                    <Box fontWeight={700} display={"inline"}>
                                        以下のいずれかに該当しますか？<br/>
                                        ・現在日本学生支援機構から給付奨学金の給付を受けている。<br/>
                                        ・過去に日本学生支援機構から給付奨学金の給付を受けていたが、現在は停止されている<br/>
                                        Do any of the following apply to you?<br/>
                                        • I am currently receiving a scholarship grant from the Japan Student
                                        Services Organization.<br/>
                                        • I received a scholarship grant from the Japan Student Services
                                        Organization in the past, but it has been suspended.
                                    </Box>
                                )
                            }
                            requiredType={RequiredTagTypeEnum.必須}
                        >
                            {
                                isKakutei ? (
                                    <Box>
                                        {formValues.common.nihonGakuseiSienKiko.isReceivedAlready ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                    </Box>
                                ) : (
                                    <RadioButtonGroup
                                        errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.common?.nihonGakuseiSienKiko?.isReceivedAlready)}
                                        vertical={true}
                                    >
                                        <RadioButton
                                            value={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                            checked={formValues.common.nihonGakuseiSienKiko.isReceivedAlready === true}
                                            label={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                            isError={isErrorMessage(errorMessages?.common?.nihonGakuseiSienKiko?.isReceivedAlready)}
                                            handleChange={() => handleChangeIsReceivedAlready(true)}
                                        />
                                        <RadioButton
                                            value={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                            checked={formValues.common.nihonGakuseiSienKiko.isReceivedAlready === false}
                                            label={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                            isError={isErrorMessage(errorMessages?.common?.nihonGakuseiSienKiko?.isReceivedAlready)}
                                            handleChange={() => handleChangeIsReceivedAlready(false)}
                                        />
                                    </RadioButtonGroup>
                                )
                            }
                        </FieldWithLabel>
                    </FormTableRow>

                    {
                        formValues.common.nihonGakuseiSienKiko.isReceivedAlready === false ? (
                            <>
                                <FormTableRow
                                    label={"別紙1に関する項目"}
                                >
                                    <Grid container spacing={1}>

                                        <Grid item xs={12}>

                                            <FieldWithLabel
                                                labelNode={
                                                    (
                                                        <Box fontWeight={700} display={"inline"}>
                                                            日本学生支援機構へ給付奨学金の申し込みを行いますか？<br/>
                                                            Are you going to apply to the Japan Student Services Organization for a
                                                            scholarship grant?
                                                        </Box>
                                                    )
                                                }
                                                requiredType={RequiredTagTypeEnum.必須}
                                            >
                                                {
                                                    isKakutei ? (
                                                        <Box>
                                                            {formValues.common.bessi1.isApply ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                                        </Box>
                                                    ) : (
                                                        <RadioButtonGroup
                                                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.common?.bessi1?.isApply)}
                                                            vertical={true}
                                                        >
                                                            <RadioButton
                                                                value={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                                                checked={formValues.common.bessi1.isApply === true}
                                                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                                                isError={isErrorMessage(errorMessages?.common?.bessi1?.isApply)}
                                                                handleChange={() => handleChangeIsApply(true)}
                                                            />
                                                            <RadioButton
                                                                value={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                                                checked={formValues.common.bessi1.isApply === false}
                                                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                                                isError={isErrorMessage(errorMessages?.common?.bessi1?.isApply)}
                                                                handleChange={() => handleChangeIsApply(false)}
                                                            />
                                                        </RadioButtonGroup>
                                                    )
                                                }
                                            </FieldWithLabel>

                                        </Grid>

                                        <Grid item xs={12}>

                                            <FieldWithLabel
                                                labelNode={
                                                    (
                                                        <Box fontWeight={700} display={"inline"}>
                                                            授業料の免除だけを希望しますか？<br/>
                                                            Do you wish to receive only a waiver of tuition?
                                                        </Box>
                                                    )
                                                }
                                                requiredType={RequiredTagTypeEnum.必須}
                                            >
                                                {
                                                    isKakutei ? (
                                                        <Box>
                                                            {formValues.common.bessi1.isOnlyTuitionWaiver ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                                        </Box>
                                                    ) : (
                                                        <RadioButtonGroup
                                                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.common?.bessi1?.isOnlyTuitionWaiver)}
                                                            vertical={true}
                                                        >
                                                            <RadioButton
                                                                value={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                                                checked={formValues.common.bessi1.isOnlyTuitionWaiver === true}
                                                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                                                isError={isErrorMessage(errorMessages?.common?.bessi1?.isOnlyTuitionWaiver)}
                                                                handleChange={() => handleChangeIsOnlyTuitionWaiver(true)}
                                                            />
                                                            <RadioButton
                                                                value={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                                                checked={formValues.common.bessi1.isOnlyTuitionWaiver === false}
                                                                label={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                                                isError={isErrorMessage(errorMessages?.common?.bessi1?.isOnlyTuitionWaiver)}
                                                                handleChange={() => handleChangeIsOnlyTuitionWaiver(false)}
                                                            />
                                                        </RadioButtonGroup>
                                                    )
                                                }
                                            </FieldWithLabel>

                                        </Grid>

                                    </Grid>

                                </FormTableRow>

                                <FormTableRow
                                    label={"別紙2に関する項目"}
                                >
                                    <FieldWithLabel
                                        labelNode={
                                            (
                                                <Box fontWeight={700} display={"inline"}>
                                                    本学に編入学又は転学した学生であって、編入学又は転学する前に在学していた学校(大学、短大、高専、専門学校)が2つ以上ありますか？<br/>
                                                    If you entered this school by mid-course entry or by transfer, were you
                                                    enrolled in two or more schools (universities, junior colleges, colleges of
                                                    technology, professional training colleges) before entering this school by
                                                    mid-course entry or by transfer?
                                                </Box>
                                            )
                                        }
                                        requiredType={RequiredTagTypeEnum.必須}
                                    >
                                        {
                                            isKakutei ? (
                                                <Box>
                                                    {formValues.common.bessi2.isBeforeEnteredTwoOrMore ? CommonTextEnum.はい : CommonTextEnum.いいえ}
                                                </Box>
                                            ) : (
                                                <RadioButtonGroup
                                                    errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.common?.bessi2?.isBeforeEnteredTwoOrMore)}
                                                    vertical={true}
                                                >
                                                    <RadioButton
                                                        value={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                                        checked={formValues.common.bessi2.isBeforeEnteredTwoOrMore === true}
                                                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.はい)}
                                                        isError={isErrorMessage(errorMessages?.common?.bessi2?.isBeforeEnteredTwoOrMore)}
                                                        handleChange={() => handleChangeIsBeforeEnteredTwoOrMore(true)}
                                                    />
                                                    <RadioButton
                                                        value={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                                        checked={formValues.common.bessi2.isBeforeEnteredTwoOrMore === false}
                                                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.いいえ)}
                                                        isError={isErrorMessage(errorMessages?.common?.bessi2?.isBeforeEnteredTwoOrMore)}
                                                        handleChange={() => handleChangeIsBeforeEnteredTwoOrMore(false)}
                                                    />
                                                </RadioButtonGroup>
                                            )
                                        }
                                    </FieldWithLabel>
                                </FormTableRow>
                            </>
                        ) : null
                    }

                </FormTable>

            </Grid>

        </Grid>
    )
}
