/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SwSyorikiInfo,
    SwSyorikiInfoFromJSON,
    SwSyorikiInfoToJSON,
} from '../models';

/**
 * no description
 */
export class StudentSyorikiInfoApi extends runtime.BaseAPI {

    /**
     * 処理期の情報を取得
     */
    async getStudentSyorikiInfoRaw(): Promise<runtime.ApiResponse<SwSyorikiInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/syoriki_info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwSyorikiInfoFromJSON(jsonValue));
    }

    /**
     * 処理期の情報を取得
     */
    async getStudentSyorikiInfo(): Promise<SwSyorikiInfo> {
        const response = await this.getStudentSyorikiInfoRaw();
        return await response.value();
    }

}
