import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {StudentAYosikiSyoruisApi} from "openapi/apis";
import {SwAYosikiSyoruis} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";
import {AYosikiSyoruisStoreVo, AYosikiSyoruisSyoruisVo} from "../../../../common/models/AYosikiSyoruisModels";

class StudentAYosikiSyoruisStore extends Store<AYosikiSyoruisStoreVo> {

    private api = new StudentAYosikiSyoruisApi(apiConfiguration);
    constructor() {
        super({})
    }

    updateAYosikiSyoruis(syoruis: AYosikiSyoruisSyoruisVo | undefined) {
        this.dispatch(() => ({syoruis: syoruis}));
    }

    async getAYosikiSyoruis() {
        try {
            let res: SwAYosikiSyoruis = await this.api.getStudentAYosikiSyoruis();
            this.updateAYosikiSyoruis(res.syoruis);
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const studentAYosikiSyoruisStore = new StudentAYosikiSyoruisStore();
