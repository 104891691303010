import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum TitleNameTextEnum {
    家族構成 = "家族構成",
    収入所得 = "収入・所得",
    家庭の就学者情報 = "家庭の就学者情報",
    申請書作成にあたっての注意事項 = "申請書作成にあたっての注意事項",
    母子父子世帯 = "母子・父子世帯",
    障害者のいる世帯 = "障害者のいる世帯",
    生活保護世帯 = "生活保護世帯",
    申請者本人が独立生計者 = "申請者本人が独立生計者",
    長期療養者のいる世帯 = "長期療養者のいる世帯",
    主たる家計支持者別居の世帯 = "主たる家計支持者別居の世帯",
    火災風水害盗難等の被害を受けた世帯 = "火災・風水害・盗難等の被害を受けた世帯"
}

export function TitleNameTextEnumToNameJaAndEn(e: TitleNameTextEnum): string {
    switch (e) {
        case TitleNameTextEnum.家族構成:
            return convertEnumNameToStr({ja: "家族構成", en: "Family Members"});
        case TitleNameTextEnum.収入所得:
            return convertEnumNameToStr({ja: "収入・所得", en: "Revenue and Income"});
        case TitleNameTextEnum.家庭の就学者情報:
            return convertEnumNameToStr({ja: "家庭の就学者情報", en: "Student of Family Members"});
        case TitleNameTextEnum.申請書作成にあたっての注意事項:
            return convertEnumNameToStr({ja: "申請書作成にあたっての注意事項", en: "Notes for Preparing Application Forms"});
        case TitleNameTextEnum.母子父子世帯:
            return convertEnumNameToStr({ja: "母子・父子世帯", en: "Single-parent households"});
        case TitleNameTextEnum.障害者のいる世帯:
            return convertEnumNameToStr({ja: "障害者のいる世帯", en: "Households with members who are handicapped"});
        case TitleNameTextEnum.生活保護世帯:
            return convertEnumNameToStr({ja: "生活保護世帯", en: "Households receiving public welfare assistance"});
        case TitleNameTextEnum.申請者本人が独立生計者:
            return convertEnumNameToStr({ja: "申請者本人が独立生計者", en: "Applicant is an individual in independent household"});
        case TitleNameTextEnum.長期療養者のいる世帯:
            return convertEnumNameToStr({ja: "長期療養者のいる世帯", en: "Households with long-term care patients"});
        case TitleNameTextEnum.主たる家計支持者別居の世帯:
            return convertEnumNameToStr({ja: "主たる家計支持者別居の世帯", en: "Households in which the primary household supporter lives separately from the family"});
        case TitleNameTextEnum.火災風水害盗難等の被害を受けた世帯:
            return convertEnumNameToStr({ja: "火災・風水害・盗難等の被害を受けた世帯", en: "Households affected by a fire, flood, storm, or other natural disaster, or by theft"});
        default :
            return ""
    }
}