/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SyoruiConfirmationFormHonnin,
    SyoruiConfirmationFormHonninFromJSON,
    SyoruiConfirmationFormHonninFromJSONTyped,
    SyoruiConfirmationFormHonninToJSON,
    SyoruiConfirmationFormHonninRyugakusei,
    SyoruiConfirmationFormHonninRyugakuseiFromJSON,
    SyoruiConfirmationFormHonninRyugakuseiFromJSONTyped,
    SyoruiConfirmationFormHonninRyugakuseiToJSON,
    SyoruiConfirmationFormMember,
    SyoruiConfirmationFormMemberFromJSON,
    SyoruiConfirmationFormMemberFromJSONTyped,
    SyoruiConfirmationFormMemberToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSyoruiConfirmationFormRequest
 */
export interface SwSyoruiConfirmationFormRequest {
    /**
     * 
     * @type {SyoruiConfirmationFormHonnin}
     * @memberof SwSyoruiConfirmationFormRequest
     */
    honnin: SyoruiConfirmationFormHonnin;
    /**
     * 
     * @type {SyoruiConfirmationFormHonninRyugakusei}
     * @memberof SwSyoruiConfirmationFormRequest
     */
    honninRyugakusei: SyoruiConfirmationFormHonninRyugakusei;
    /**
     * 
     * @type {Array<SyoruiConfirmationFormMember>}
     * @memberof SwSyoruiConfirmationFormRequest
     */
    members: Array<SyoruiConfirmationFormMember>;
}

export function SwSyoruiConfirmationFormRequestFromJSON(json: any): SwSyoruiConfirmationFormRequest {
    return SwSyoruiConfirmationFormRequestFromJSONTyped(json, false);
}

export function SwSyoruiConfirmationFormRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyoruiConfirmationFormRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'honnin': SyoruiConfirmationFormHonninFromJSON(json['honnin']),
        'honninRyugakusei': SyoruiConfirmationFormHonninRyugakuseiFromJSON(json['honninRyugakusei']),
        'members': ((json['members'] as Array<any>).map(SyoruiConfirmationFormMemberFromJSON)),
    };
}

export function SwSyoruiConfirmationFormRequestToJSON(value?: SwSyoruiConfirmationFormRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'honnin': SyoruiConfirmationFormHonninToJSON(value.honnin),
        'honninRyugakusei': SyoruiConfirmationFormHonninRyugakuseiToJSON(value.honninRyugakusei),
        'members': ((value.members as Array<any>).map(SyoruiConfirmationFormMemberToJSON)),
    };
}


