import React, {FC} from 'react';
import {BgColorsEnum} from "common/enums_value/ColorsEnum";
import {BoxProps} from "@material-ui/core/Box";
import {Box} from "@material-ui/core";

export interface Props extends BoxProps{
}

export const ContentBox: FC<Props> = (props) => {

    return (

        <Box px={1.5} py={2} bgcolor={BgColorsEnum.OffWhite} {...props}>
            {props.children}
        </Box>

    )
};

