/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwPostAddSinsisyaErrorMessageResponse
 */
export interface SwPostAddSinsisyaErrorMessageResponse {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwPostAddSinsisyaErrorMessageResponse
     */
    studentNumber?: Array<SwErrorMessage>;
}

export function SwPostAddSinsisyaErrorMessageResponseFromJSON(json: any): SwPostAddSinsisyaErrorMessageResponse {
    return SwPostAddSinsisyaErrorMessageResponseFromJSONTyped(json, false);
}

export function SwPostAddSinsisyaErrorMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwPostAddSinsisyaErrorMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentNumber': !exists(json, 'studentNumber') ? undefined : ((json['studentNumber'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwPostAddSinsisyaErrorMessageResponseToJSON(value?: SwPostAddSinsisyaErrorMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentNumber': value.studentNumber === undefined ? undefined : ((value.studentNumber as Array<any>).map(SwErrorMessageToJSON)),
    };
}


