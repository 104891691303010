import {BeturiTypeEnum} from "common/enums/BeturiTypeEnum";
import {JugyoryoEnum} from "common/enums/JugyoryoEnum";
import {NyugakuryoEnum} from "common/enums/NyugakuryoEnum";
import {SchoolTypeEnum} from "common/enums/SchoolTypeEnum";
import {SettiKubunEnum} from "common/enums/SettiKubunEnum";
import {SyogaisyaTypeEnum} from "common/enums/SyogaisyaTypeEnum";
import {SyunyuKubunEnum} from "common/enums/SyunyuKubunEnum";
import {TokubetuKojoEnum} from "common/enums/TokubetuKojoEnum";
import {TyokiryoyoTypeEnum} from "common/enums/TyokiryoyoTypeEnum";
import {ZokugaraEnum} from "common/enums/ZokugaraEnum";
import {
    BasicInfoVo,
    createBlankSyunyuInfoField,
    FamilyInfoVo,
    InfoPolicyVo,
    JugyoryoInfoVo,
    MemberVo,
    NyugakuryoInfoVo,
    SeisekiInfoVo,
    SinseisyaInfoVo,
    SyogakukinInfoVo,
    SyunyuInfoItemFieldVo,
    SyunyuInfoItemOtherVo,
    SyunyuInfoItemVo,
    TokubetuKojoInfoVo
} from "common/models/YosikiInfoModels";
import {
    SwBasicInfo,
    SwFamilyInfo,
    SwInfoPolicy,
    SwJugyoryoInfo,
    SwMember,
    SwNyugakuryoInfo,
    SwSeisekiInfo,
    SwSinseisyaInfo,
    SwSyogaisya,
    SwSyogakukin,
    SwSyogakukinInfo,
    SwSyunyuInfoItem,
    SwSyunyuInfoItemField,
    SwSyunyuInfoItemOther,
    SwTokubetuKojoInfo,
    SwTyokiryoyo
} from "openapi/models";
import {convertNumToStr} from "common/functions/type_converter/convertNumToStr";
import {JugyoryoMenjoStateEnum} from "../../enums/JugyoryoMenjoStateEnum";

export function convertJugyoryoInfoSwToVo(jugyoryoInfo: SwJugyoryoInfo): JugyoryoInfoVo {
    return {
        riyu: jugyoryoInfo.riyu,
    }
}

export function convertNyugakuryoInfoSwToVo(nyugakuryoInfo: SwNyugakuryoInfo): NyugakuryoInfoVo {
    return {
        riyu: nyugakuryoInfo.riyu,
    }
}


// FIXME: モック開発用にenumを設定
export function convertSyunyuInfoItemSwToVo(item?: SwSyunyuInfoItem, kubunEnumForDev?: SyunyuKubunEnum): SyunyuInfoItemVo {
    if (!item) {
        throw new Error('収入情報が不正です。');
    }
    return {
        kubunCode: kubunEnumForDev || item.kubunCode as SyunyuKubunEnum,
        field: convertSyunyuInfoItemFieldSwToVo(item.field)
    }
}

// FIXME: モック開発用にenumを設定
export function convertSyunyuInfoItemOtherSwToVo(item?: SwSyunyuInfoItemOther, kubunEnumForDev?: SyunyuKubunEnum): SyunyuInfoItemOtherVo {
    if (!item) {
        throw new Error('収入情報が不正です。');
    }

    const fields = item.fields.map(f => convertSyunyuInfoItemFieldSwToVo(f))
    // NOTE: 3つに固定(apiで保証されるなら不要)
    for (let i = 0; fields.length < 3; i++) {
        fields.push(createBlankSyunyuInfoField());
    }
    return {
        kubunCode: kubunEnumForDev || item.kubunCode as SyunyuKubunEnum,
        fields: fields
    }

}


export function convertSyunyuInfoItemFieldSwToVo(item: SwSyunyuInfoItemField): SyunyuInfoItemFieldVo {
    return {
        kubunOther: item.kubunOther,
        amount: convertNumToStr(item.amount),
        syotokuYear: convertNumToStr(item.syotokuYear),
        syotokuMonth: convertNumToStr(item.syotokuMonth),
    }
}


export function convertFamilyInfoSwToVo(familyInfo: SwFamilyInfo): FamilyInfoVo {
    const members: Array<MemberVo> = [];
    familyInfo.members.forEach((m: SwMember) => {

        members.push({
            zokugara: m.zokugara as ZokugaraEnum,
            zokugaraOther: m.zokugaraOther,
            isSyugakusya: m.isSyugakusya,
            name: m.name,
            age: convertNumToStr(m.age),
            syugakusya: {
                schoolType: m.syugakusya?.schoolType as SchoolTypeEnum || undefined,
                schoolName: m.syugakusya?.schoolName || "",
                gakunen: convertNumToStr(m.syugakusya?.gakunen || undefined),
                settiKubun: m.syugakusya?.settiKubun as SettiKubunEnum || undefined,
                tugakuFromJitaku: m.syugakusya?.tugakuFromJitaku === undefined ? undefined : m.syugakusya.tugakuFromJitaku,
                jugyoryoMenjoState: m.syugakusya?.jugyoryoMenjoState as JugyoryoMenjoStateEnum || undefined,
                jugyoryoForHalfYear: convertNumToStr(m.syugakusya?.jugyoryoForHalfYear),
            },
            syugakusyaIgai: {
                jobName: m.syugakusyaIgai?.jobName || "",
                jobYears: convertNumToStr(m.syugakusyaIgai?.jobYears),
                syunyuAriNasi: m.syugakusyaIgai?.syunyuAriNasi,
                kakuteisinkokuAriNasi: m.syugakusyaIgai?.kakuteisinkokuAriNasi,
                syunyuInfo: {
                    kyuyo: convertSyunyuInfoItemSwToVo(m.syugakusyaIgai?.syunyuInfo.kyuyo, SyunyuKubunEnum.給料賃金),
                    eigyo: convertSyunyuInfoItemSwToVo(m.syugakusyaIgai?.syunyuInfo?.eigyo, SyunyuKubunEnum.営業),
                    norin: convertSyunyuInfoItemSwToVo(m.syugakusyaIgai?.syunyuInfo?.norin, SyunyuKubunEnum.農林水産業),
                    hudosan: convertSyunyuInfoItemSwToVo(m.syugakusyaIgai?.syunyuInfo?.hudosan, SyunyuKubunEnum.不動産),
                    risi: convertSyunyuInfoItemSwToVo(m.syugakusyaIgai?.syunyuInfo?.risi, SyunyuKubunEnum.利子配当),
                    haito: convertSyunyuInfoItemSwToVo(m.syugakusyaIgai?.syunyuInfo?.haito, SyunyuKubunEnum.利子配当),
                    nenkin: convertSyunyuInfoItemOtherSwToVo(m.syugakusyaIgai?.syunyuInfo?.nenkin, SyunyuKubunEnum.年金恩給),
                    koyohoken: convertSyunyuInfoItemOtherSwToVo(m.syugakusyaIgai?.syunyuInfo?.koyohoken, SyunyuKubunEnum.雇用保険),
                    kakusyuteate: convertSyunyuInfoItemOtherSwToVo(m.syugakusyaIgai?.syunyuInfo?.kakusyuteate, SyunyuKubunEnum.各種手当),
                    syogakukin: convertSyunyuInfoItemOtherSwToVo(m.syugakusyaIgai?.syunyuInfo?.syogakukin, SyunyuKubunEnum.奨学金),
                    sinsekitounosien: convertSyunyuInfoItemOtherSwToVo(m.syugakusyaIgai?.syunyuInfo?.sinsekitounosien, SyunyuKubunEnum.親戚等の援助),
                    yotyokin: convertSyunyuInfoItemOtherSwToVo(m.syugakusyaIgai?.syunyuInfo?.yotyokin, SyunyuKubunEnum.預貯金から),
                    other: convertSyunyuInfoItemOtherSwToVo(m.syugakusyaIgai?.syunyuInfo?.other, SyunyuKubunEnum.その他),
                    taisyokukinn: convertSyunyuInfoItemOtherSwToVo(m.syugakusyaIgai?.syunyuInfo?.taisyokukinn, SyunyuKubunEnum.退職金),
                    hokenkin: convertSyunyuInfoItemOtherSwToVo(m.syugakusyaIgai?.syunyuInfo?.hokenkin, SyunyuKubunEnum.保険金),
                },
            },
        })
    });

    return {
        honnin: {
            syunyuAriNasi: familyInfo.honnin.syunyuAriNasi,
            kakuteisinkokuAriNasi: familyInfo.honnin.kakuteisinkokuAriNasi,
            syunyuInfo: {
                kyuyo: convertSyunyuInfoItemSwToVo(familyInfo.honnin?.syunyuInfo.kyuyo, SyunyuKubunEnum.給料賃金),
                eigyo: convertSyunyuInfoItemSwToVo(familyInfo.honnin?.syunyuInfo?.eigyo, SyunyuKubunEnum.営業),
                norin: convertSyunyuInfoItemSwToVo(familyInfo.honnin?.syunyuInfo?.norin, SyunyuKubunEnum.農林水産業),
                hudosan: convertSyunyuInfoItemSwToVo(familyInfo.honnin?.syunyuInfo?.hudosan, SyunyuKubunEnum.不動産),
                risi: convertSyunyuInfoItemSwToVo(familyInfo.honnin?.syunyuInfo?.risi, SyunyuKubunEnum.利子配当),
                haito: convertSyunyuInfoItemSwToVo(familyInfo.honnin?.syunyuInfo?.haito, SyunyuKubunEnum.利子配当),
                nenkin: convertSyunyuInfoItemOtherSwToVo(familyInfo.honnin?.syunyuInfo?.nenkin, SyunyuKubunEnum.年金恩給),
                koyohoken: convertSyunyuInfoItemOtherSwToVo(familyInfo.honnin?.syunyuInfo?.koyohoken, SyunyuKubunEnum.雇用保険),
                kakusyuteate: convertSyunyuInfoItemOtherSwToVo(familyInfo.honnin?.syunyuInfo?.kakusyuteate, SyunyuKubunEnum.各種手当),
                syogakukin: convertSyunyuInfoItemOtherSwToVo(familyInfo.honnin?.syunyuInfo?.syogakukin, SyunyuKubunEnum.奨学金),
                sinsekitounosien: convertSyunyuInfoItemOtherSwToVo(familyInfo.honnin?.syunyuInfo?.sinsekitounosien, SyunyuKubunEnum.親戚等の援助),
                yotyokin: convertSyunyuInfoItemOtherSwToVo(familyInfo.honnin?.syunyuInfo?.yotyokin, SyunyuKubunEnum.預貯金から),
                other: convertSyunyuInfoItemOtherSwToVo(familyInfo.honnin?.syunyuInfo?.other, SyunyuKubunEnum.その他),
                taisyokukinn: convertSyunyuInfoItemOtherSwToVo(familyInfo.honnin?.syunyuInfo?.taisyokukinn, SyunyuKubunEnum.退職金),
                hokenkin: convertSyunyuInfoItemOtherSwToVo(familyInfo.honnin?.syunyuInfo?.hokenkin, SyunyuKubunEnum.保険金),
            },
            tugakuFromJitaku: familyInfo.honnin.tugakuFromJitaku,
        },
        members: members
    }
}

function createBlankSyogakukin(): SwSyogakukin {
    return {
        "name": "",
        "amountPerMonth": undefined,
        "times": undefined,
        "startYear": undefined,
        "startMonth": undefined,
        "endYear": undefined,
        "endMonth": undefined,
    }
}

export function convertSyogakukinInfoSwToVo(syogakukinInfo: SwSyogakukinInfo): SyogakukinInfoVo {
    // 初期状態でも最低2つ入力欄を用意する必要があるので、2つ以下の場合からの情報を追加する kishi
    for (let i = syogakukinInfo.syogakukins.length; i < 2; i++) {
        syogakukinInfo.syogakukins.push(createBlankSyogakukin())
    }
    return {
        "syogakukins": syogakukinInfo.syogakukins.map((t: SwSyogakukin) => {
            return {
                "name": t.name,
                "amountPerMonth": convertNumToStr(t.amountPerMonth),
                "times": convertNumToStr(t.times),
                "startYear": convertNumToStr(t.startYear),
                "startMonth": convertNumToStr(t.startMonth),
                "endYear": convertNumToStr(t.endYear),
                "endMonth": convertNumToStr(t.endMonth),
            }
        })
    }
}

export function convertSeisekiInfoSwToVo(seisekiInfo: SwSeisekiInfo): SeisekiInfoVo {
    return {
        "SA": convertNumToStr(seisekiInfo.SA),
        "B": convertNumToStr(seisekiInfo.B),
        "C": convertNumToStr(seisekiInfo.C),
        "total": convertNumToStr(seisekiInfo.total),
    }
}

export function convertSinseisyaInfoSwToVo(sinseisyaInfo: SwSinseisyaInfo): SinseisyaInfoVo {
    return {
        "birthdayYear": convertNumToStr(sinseisyaInfo.birthdayYear),
        "birthdayMonth": convertNumToStr(sinseisyaInfo.birthdayMonth),
        "birthdayDay": convertNumToStr(sinseisyaInfo.birthdayDay),
        "gakunen": convertNumToStr(sinseisyaInfo.gakunen),
        "isKyugaku": sinseisyaInfo.isKyugaku,
        "kyugaku": {
            "startYear": convertNumToStr(sinseisyaInfo.kyugaku?.startYear || undefined),
            "startMonth": convertNumToStr(sinseisyaInfo.kyugaku?.startMonth || undefined),
            "endYear": convertNumToStr(sinseisyaInfo.kyugaku?.endYear || undefined),
            "endMonth": convertNumToStr(sinseisyaInfo.kyugaku?.endMonth || undefined),
            "riyu": sinseisyaInfo.kyugaku?.riyu || "",
        },
        "isRyunen": sinseisyaInfo.isRyunen,
        "address": {
            "zipcode": sinseisyaInfo.address.zipcode,
            "address": sinseisyaInfo.address.address,
        },
        "telHome": sinseisyaInfo.telHome,
        "telLab": sinseisyaInfo.telLab,
        "sidoTeacherName": sinseisyaInfo.sidoTeacherName,
        "addressFamily": {
            "zipcode": sinseisyaInfo.addressFamily.zipcode,
            "address": sinseisyaInfo.addressFamily.address,
        },
        "telFamily": sinseisyaInfo.telFamily,
    }
}

export function convertBasicInfoSwToVo(basicInfo: SwBasicInfo): BasicInfoVo {
    return {
        "sinseiType": {
            "nyugakuryo": basicInfo.sinseiType.nyugakuryo as NyugakuryoEnum,
            "jugyoryo": basicInfo.sinseiType.jugyoryo as JugyoryoEnum
        },
        "tokubetuKojo": basicInfo.tokubetuKojo.map(t => {
            return t as TokubetuKojoEnum
        })
    }
}

export function convertInfoPolicySwToVo(infoPolicy: SwInfoPolicy): InfoPolicyVo {
    return {
        "requireSinseisyaInfo": infoPolicy.requireSinseisyaInfo,
        "requireNyugakuryoInfo": infoPolicy.requireNyugakuryoInfo,
        "requireJugyoryoInfo": infoPolicy.requireJugyoryoInfo,
        "requireTokubetuKojoInfo": {
            "bosiHusi": infoPolicy.requireTokubetuKojoInfo.bosiHusi,
            "syogaisya": infoPolicy.requireTokubetuKojoInfo.syogaisya,
            "tyokiryoyo": infoPolicy.requireTokubetuKojoInfo.tyokiryoyo,
            "bekkyo": infoPolicy.requireTokubetuKojoInfo.bekkyo,
            "saigaihigai": infoPolicy.requireTokubetuKojoInfo.saigaihigai
        }
    }
}

export function convertTokubetuKojoSwToVo(tokubetuKojoInfo: SwTokubetuKojoInfo): TokubetuKojoInfoVo {

    return {
        bosiHusi: {
            mother: tokubetuKojoInfo.bosiHusi.mother ? {
                beturiType: tokubetuKojoInfo.bosiHusi.mother.beturiType as BeturiTypeEnum,
                jikiYaer: convertNumToStr(tokubetuKojoInfo.bosiHusi.mother.jikiYaer),
                jikiMonth: convertNumToStr(tokubetuKojoInfo.bosiHusi.mother.jikiMonth),
                riyu: tokubetuKojoInfo.bosiHusi.mother.riyu,
            } : undefined,
            father: tokubetuKojoInfo.bosiHusi.father ? {
                beturiType: tokubetuKojoInfo.bosiHusi.father.beturiType as BeturiTypeEnum,
                jikiYaer: convertNumToStr(tokubetuKojoInfo.bosiHusi.father.jikiYaer),
                jikiMonth: convertNumToStr(tokubetuKojoInfo.bosiHusi.father.jikiMonth),
                riyu: tokubetuKojoInfo.bosiHusi.father.riyu,
            } : undefined,
        },
        syogaisya: tokubetuKojoInfo.syogaisya.map((syogaisya: SwSyogaisya) => {
            return {
                ...syogaisya,
                type: syogaisya.type as SyogaisyaTypeEnum,
                zokugara: syogaisya.zokugara as ZokugaraEnum
            }
        }),
        tyokiryoyo: tokubetuKojoInfo.tyokiryoyo.map((t: SwTyokiryoyo) => {
            return {
                ...t,
                type: t.type as TyokiryoyoTypeEnum,
                zokugara: t.zokugara as ZokugaraEnum,
                startYear: convertNumToStr(t.startYear),
                startMonth: convertNumToStr(t.startMonth),
                amountPerHalfYear: convertNumToStr(t.amountPerHalfYear),
            }
        }),
        bekkyo: {
            riyu: tokubetuKojoInfo.bekkyo.riyu,
            amountPerMonth: convertNumToStr(tokubetuKojoInfo.bekkyo.amountPerMonth),
        },
        saigaihigai: {
            riyu: tokubetuKojoInfo.saigaihigai.riyu,
            amount: convertNumToStr(tokubetuKojoInfo.saigaihigai.amount),
        },
    }
}
