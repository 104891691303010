/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwErrorMessage
 */
export interface SwErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof SwErrorMessage
     */
    ja: string;
    /**
     * 
     * @type {string}
     * @memberof SwErrorMessage
     */
    en: string;
}

export function SwErrorMessageFromJSON(json: any): SwErrorMessage {
    return SwErrorMessageFromJSONTyped(json, false);
}

export function SwErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ja': json['ja'],
        'en': json['en'],
    };
}

export function SwErrorMessageToJSON(value?: SwErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ja': value.ja,
        'en': value.en,
    };
}


