/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InlineResponse20012,
    InlineResponse20012FromJSON,
    InlineResponse20012ToJSON,
    InlineResponse20013,
    InlineResponse20013FromJSON,
    InlineResponse20013ToJSON,
    SwOfficerOsirase,
    SwOfficerOsiraseFromJSON,
    SwOfficerOsiraseToJSON,
    SwOfficerOsirasePostRequest,
    SwOfficerOsirasePostRequestFromJSON,
    SwOfficerOsirasePostRequestToJSON,
    SwOfficerOsirasePutRequest,
    SwOfficerOsirasePutRequestFromJSON,
    SwOfficerOsirasePutRequestToJSON,
} from '../models';

export interface DeleteOfficerOsirasesRequest {
    osiraseId: string;
}

export interface PostOfficerOsirasesRequest {
    swOfficerOsirasePostRequest: SwOfficerOsirasePostRequest;
}

export interface PutOfficerOsirasesRequest {
    osiraseId: string;
    swOfficerOsirasePutRequest: SwOfficerOsirasePutRequest;
}

/**
 * no description
 */
export class OfficerOsirasesApi extends runtime.BaseAPI {

    /**
     * お知らせの削除 
     */
    async deleteOfficerOsirasesRaw(requestParameters: DeleteOfficerOsirasesRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.osiraseId === null || requestParameters.osiraseId === undefined) {
            throw new runtime.RequiredError('osiraseId','Required parameter requestParameters.osiraseId was null or undefined when calling deleteOfficerOsirases.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/osirases/{osiraseId}`.replace(`{${"osiraseId"}}`, encodeURIComponent(String(requestParameters.osiraseId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * お知らせの削除 
     */
    async deleteOfficerOsirases(requestParameters: DeleteOfficerOsirasesRequest): Promise<InlineResponse200> {
        const response = await this.deleteOfficerOsirasesRaw(requestParameters);
        return await response.value();
    }

    /**
     * お知らせ一覧 全件を作成日(createdAt)の新しい順で。 
     */
    async getOfficerOsirasesRaw(): Promise<runtime.ApiResponse<SwOfficerOsirase>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/osirases`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwOfficerOsiraseFromJSON(jsonValue));
    }

    /**
     * お知らせ一覧 全件を作成日(createdAt)の新しい順で。 
     */
    async getOfficerOsirases(): Promise<SwOfficerOsirase> {
        const response = await this.getOfficerOsirasesRaw();
        return await response.value();
    }

    /**
     * お知らせの追加 
     */
    async postOfficerOsirasesRaw(requestParameters: PostOfficerOsirasesRequest): Promise<runtime.ApiResponse<InlineResponse20012>> {
        if (requestParameters.swOfficerOsirasePostRequest === null || requestParameters.swOfficerOsirasePostRequest === undefined) {
            throw new runtime.RequiredError('swOfficerOsirasePostRequest','Required parameter requestParameters.swOfficerOsirasePostRequest was null or undefined when calling postOfficerOsirases.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/officer/osirases`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwOfficerOsirasePostRequestToJSON(requestParameters.swOfficerOsirasePostRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20012FromJSON(jsonValue));
    }

    /**
     * お知らせの追加 
     */
    async postOfficerOsirases(requestParameters: PostOfficerOsirasesRequest): Promise<InlineResponse20012> {
        const response = await this.postOfficerOsirasesRaw(requestParameters);
        return await response.value();
    }

    /**
     * お知らせの更新 
     */
    async putOfficerOsirasesRaw(requestParameters: PutOfficerOsirasesRequest): Promise<runtime.ApiResponse<InlineResponse20013>> {
        if (requestParameters.osiraseId === null || requestParameters.osiraseId === undefined) {
            throw new runtime.RequiredError('osiraseId','Required parameter requestParameters.osiraseId was null or undefined when calling putOfficerOsirases.');
        }

        if (requestParameters.swOfficerOsirasePutRequest === null || requestParameters.swOfficerOsirasePutRequest === undefined) {
            throw new runtime.RequiredError('swOfficerOsirasePutRequest','Required parameter requestParameters.swOfficerOsirasePutRequest was null or undefined when calling putOfficerOsirases.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/officer/osirases/{osiraseId}`.replace(`{${"osiraseId"}}`, encodeURIComponent(String(requestParameters.osiraseId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SwOfficerOsirasePutRequestToJSON(requestParameters.swOfficerOsirasePutRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20013FromJSON(jsonValue));
    }

    /**
     * お知らせの更新 
     */
    async putOfficerOsirases(requestParameters: PutOfficerOsirasesRequest): Promise<InlineResponse20013> {
        const response = await this.putOfficerOsirasesRaw(requestParameters);
        return await response.value();
    }

}
