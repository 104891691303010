/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwSyunyuKubunMaster
 */
export interface SwSyunyuKubunMaster {
    /**
     * enum
     * @type {string}
     * @memberof SwSyunyuKubunMaster
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof SwSyunyuKubunMaster
     */
    nameJa: string;
    /**
     * 
     * @type {string}
     * @memberof SwSyunyuKubunMaster
     */
    nameEn: string;
    /**
     * enum
     * @type {string}
     * @memberof SwSyunyuKubunMaster
     */
    categoryCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof SwSyunyuKubunMaster
     */
    rinji: boolean;
}

export function SwSyunyuKubunMasterFromJSON(json: any): SwSyunyuKubunMaster {
    return SwSyunyuKubunMasterFromJSONTyped(json, false);
}

export function SwSyunyuKubunMasterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyunyuKubunMaster {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'nameJa': json['name_ja'],
        'nameEn': json['name_en'],
        'categoryCode': json['categoryCode'],
        'rinji': json['rinji'],
    };
}

export function SwSyunyuKubunMasterToJSON(value?: SwSyunyuKubunMaster | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name_ja': value.nameJa,
        'name_en': value.nameEn,
        'categoryCode': value.categoryCode,
        'rinji': value.rinji,
    };
}


