import {YosikiInfoResponseVo} from "common/models/YosikiInfoModels";

// 保存した状態から変更がなければtrue
export function isSameYosikiInfo(yosikiInfo: YosikiInfoResponseVo | undefined, original: YosikiInfoResponseVo | undefined): boolean {
    if (yosikiInfo === undefined || original === undefined) return false;
    return JSON.stringify(yosikiInfo.sinseisyaInfo) === JSON.stringify(original.sinseisyaInfo) &&
        JSON.stringify(yosikiInfo.seisekiInfo) === JSON.stringify(original.seisekiInfo) &&
        JSON.stringify(yosikiInfo.syogakukinInfo.syogakukins) === JSON.stringify(original.syogakukinInfo.syogakukins) &&
        JSON.stringify(yosikiInfo.familyInfo) === JSON.stringify(original.familyInfo) &&
        JSON.stringify(yosikiInfo.nyugakuryoInfo) === JSON.stringify(original.nyugakuryoInfo) &&
        JSON.stringify(yosikiInfo.jugyoryoInfo) === JSON.stringify(original.jugyoryoInfo) &&
        JSON.stringify(yosikiInfo.tokubetuKojoInfo) === JSON.stringify(original.tokubetuKojoInfo)
}

