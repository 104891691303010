import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MuiTextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import React, {FC} from 'react';
import {BgColorsEnum, BorderColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";

interface TextFieldProps {
    // option
    className?: string;
    width?: string;
    widthInput?: string;
    isError?: boolean;
    prefixText?: string;
    suffixText?: string;
    multiline?: boolean;
    rows?: number;
    min?: string;
    max?: string;
    helperText?: string;
    errorMessages?: Array<string>;
    step?: string

    // form
    type?: "tel" | "url" | "email" | "password" | "number" // atomにtype別に専用のコンポーネントがあるのでそれらを使用すること kishi
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
    disabled?: boolean;
    value?: string;
    handleChange?: (value: string) => void;
    handleBlur?: (value: string) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
}

export const _TextField: FC<TextFieldProps> = (props) => {

    const classes = useStylesForInput();
    const [value, setValue] = React.useState<string>("");

    React.useEffect(() => {
        if (props.value) setValue(props.value);
    }, [props.value]);

    function handleChange(newValue: string) {
        setValue(newValue);
        props.handleChange && props.handleChange(newValue)
    }

    function handleBlur(newValue: string) {
        if (props.handleBlur) props.handleBlur(newValue);
    }

    return (

        <div
            className={`${classes.wrap} ${props.className && props.className}`}
            style={{width: props.width ? props.width : "100%"}}
        >
            <div
                className={`${classes.root} ${props.isError && classes.error}`}
            >

                {
                    props.prefixText &&
                    <div className={"prefixText"}>
                        {props.prefixText}
                    </div>
                }

                <MuiTextField
                    className={`${props.disabled ? classes.disabled : ""}`}
                    variant={"outlined"}
                    error={props.isError}
                    disabled={props.disabled}
                    value={value}
                    multiline={props.multiline}
                    rows={props.rows}
                    onChange={(e) => {
                        handleChange(e.currentTarget.value)
                    }}
                    onBlur={(e) => {
                        handleBlur(e.currentTarget.value)
                    }}
                    style={{width: props.widthInput}}
                    type={props.type}
                    InputProps={{inputProps: {inputMode : props.inputMode, min: props.min, max: props.max, step: props.step}}}
                    // inputProps={{min: props.min, max: props.max, step: props.step}}
                    onKeyDown={props.onKeyDown}
                />

                {
                    props.suffixText &&
                    <div className={"suffixText"}>
                        {props.suffixText}
                    </div>
                }

            </div>

            {
                props.errorMessages && props.errorMessages.length > 0 ?
                    <ErrorMessages
                        className={classes.errorMessages}
                        errorMessages={props.errorMessages}
                    />
                    : null
            }

            {
                props.helperText && props.helperText.length > 0 ?
                    <Typography className={classes.helperText}>
                        {props.helperText}
                    </Typography>
                    : null
            }

        </div>


    )
};

_TextField.defaultProps = {
    width: "100%",
    widthInput: "100%",
    isError: false,
    disabled: false,
    multiline: false,
};

export const TextField = React.memo<TextFieldProps>(_TextField, (prev, next) => {
    return prev.className === next.className
        && prev.width === next.width
        && prev.widthInput === next.widthInput
        && prev.isError === next.isError
        && prev.prefixText === next.prefixText
        && prev.suffixText === next.suffixText
        && prev.multiline === next.multiline
        && prev.rows === next.rows
        && prev.min === next.min
        && prev.max === next.max
        && prev.helperText === next.helperText
        && prev.errorMessages === next.errorMessages
        && prev.step === next.step
        && prev.type === next.type
        && prev.disabled === next.disabled
        && prev.value === next.value
        && prev.handleChange === next.handleChange
        && prev.handleBlur === next.handleBlur
        && prev.onKeyDown === next.onKeyDown
});

export const useStylesForInput = makeStyles((theme: Theme) =>
    createStyles({
        wrap: {
            maxWidth: "100%!important",
        },
        root: {
            display: "flex",
            alignItems: "center",
            maxWidth: "100%!important",
            width: "100%",
            "& .MuiTextField-root": {
                width: "100%",
                "& .MuiOutlinedInput-root": {
                    borderRadius: 4,
                    width: "100%",
                    "&.Mui-focused": {
                        "& .MuiOutlinedInput-input": {
                            padding: "8px 7px",
                            border: `2px solid #315fb5`, // mui固有の色なのでハードコーディング
                        }
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        display: "none",
                        border: "none",
                    },
                    "& .MuiOutlinedInput-input": {
                        appearance: "none",
                        WebkitAppearance: "none",
                        padding: "9px 8px",
                        fontSize: 12,
                        backgroundColor: BgColorsEnum.White,
                        border: `1px solid #c4c4c4`, // mui固有の色なのでハードコーディング
                        borderRadius: 4,
                    },
                    "&.MuiOutlinedInput-multiline": {
                        padding: 0,
                    },
                },
            },
            "& .prefixText": {
                marginRight: 0,
                whiteSpace: "nowrap",
                paddingRight: 4,
            },
            "& .suffixText": {
                marginLeft: 0,
                whiteSpace: "nowrap",
                paddingLeft: 4,
            },
        },
        error: {
            "& .MuiTextField-root": {
                "& .MuiOutlinedInput-root": {
                    "& .MuiOutlinedInput-input": {
                        border: `1px solid ${BorderColorsEnum.Red}`
                    },
                    "&.Mui-focused": {
                        "& .MuiOutlinedInput-input": {
                            border: `2px solid ${BorderColorsEnum.Red}`,
                        }
                    },
                },
            },
        },
        disabled: {
            "& .MuiOutlinedInput-root.Mui-disabled": {
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: BgColorsEnum.LightGray,
                },
                "& .MuiInputBase-input.Mui-disabled": {
                    padding: "8px",
                    color: TextColorsEnum.Black,
                    backgroundColor: BgColorsEnum.LightGray,
                }
            },
        },
        errorMessages: {
            marginTop: 4,
        },
        helperText: {
            marginTop: 4,
            fontSize: 12
        }
    })
);
