import {captureMessage, init, Severity} from '@sentry/browser';
import {isProd} from "./isProd";

init({dsn: createSentryPath()});

export const PROD_HOST_NAME = "tuat.jumen.jp";
const LOCAL_HOST_NAME = "localhost";

function createSentryPath(): string {
    if (isProd()){
        // 本番
        return "https://903a026fe6e04edabe6aa589c0ef70a4@o12293.ingest.sentry.io/5244287";
    } else {
        // 本番以外
        return "https://232b136f76944425bfa7e0eabc0b175c@o12293.ingest.sentry.io/5244337";
    }
}

export function sentryLog(message: string, obj: object | undefined = undefined, level: Severity = Severity.Warning) {
    if (obj !== undefined) {
        message += " " + JSON.stringify(obj);
    }
    const host = window.location.hostname;

    if (host === LOCAL_HOST_NAME) return;
    captureMessage(host + " / " + message, level);
}