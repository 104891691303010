import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {FamilyInfoVo, MemberSyugakusyaIgaiVo, MemberVo, SyunyuInfoVo} from "common/models/YosikiInfoModels";
import {YosikiMastersVo} from "common/models/YosikiMastersVo";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {RadioButton} from "components/atoms/forms/RadioButton";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {RadioButtonGroup} from "components/molecules/forms/RadioButtonGroup";
import {SwMemberErrorMessagesSyugakusyaIgai, SwSyunyuInfoErrorMessages} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";
import {calcTotalAmounts} from "../../../../common/functions/yosikiInfo/calcTotalAmounts";
import {SyunyuContents} from "./SyunyuContents";

interface FamilyInfoSectionSyunyuProps {
    memberIndex: number // 就学者の就学者以外も含めたindex
    member: MemberVo
    members: Array<MemberVo>
    updateFamilyInfo: (part: Partial<FamilyInfoVo>) => void
    updateSyugakusyaIgaiErrorMessages: (part: Partial<SwMemberErrorMessagesSyugakusyaIgai>, memberIndex: number) => void
    syugakusyaIgaiErrorMessages?: SwMemberErrorMessagesSyugakusyaIgai
    commonYosikiMasters?: YosikiMastersVo
}

export const FamilyInfoSectionSyunyu: FC<FamilyInfoSectionSyunyuProps> = ({
                                                                              memberIndex,
                                                                              member,
                                                                              members,
                                                                              updateFamilyInfo,
                                                                              updateSyugakusyaIgaiErrorMessages,
                                                                              syugakusyaIgaiErrorMessages,
                                                                              commonYosikiMasters,
                                                                          }) => {

    const syugakusyaIgai: MemberSyugakusyaIgaiVo | undefined = member.syugakusyaIgai;

    function handleChangeSyunyuAriNasi(value: boolean) {
        updateMemberAsSyugakusyaIgai({syunyuAriNasi: value}, memberIndex);
        updateSyugakusyaIgaiErrorMessages({
            syunyuAriNasi: undefined,
            kakuteisinkokuAriNasi: undefined,
            syunyuInfo: {
                ...syugakusyaIgaiErrorMessages?.syunyuInfo,
                kakuteisinkokuField: undefined,
                gensentyosyuField: undefined,
                otherField: undefined
            }
        }, memberIndex);
    }

    function handleChangeKakuteisinkokuAriNasi(value: boolean) {
        updateMemberAsSyugakusyaIgai({kakuteisinkokuAriNasi: value}, memberIndex);
        updateSyugakusyaIgaiErrorMessages({
            kakuteisinkokuAriNasi: undefined,
            syunyuInfo: {
                ...syugakusyaIgaiErrorMessages?.syunyuInfo,
                kakuteisinkokuField: undefined,
                gensentyosyuField: undefined,
            }
        }, memberIndex);
        updateSyunyuInfoErrorMessages({...syugakusyaIgaiErrorMessages?.syunyuInfo, kakuteisinkokuField: undefined})
    }

    function updateMemberAsSyugakusyaIgai(value: Partial<MemberSyugakusyaIgaiVo>, index: number) {
        if (syugakusyaIgai === undefined) return;
        members[index] = {
            ...member,
            syugakusyaIgai: {
                ...syugakusyaIgai,
                ...value,
            },
        };
        let part: Partial<FamilyInfoVo> = {members: members};
        updateFamilyInfo(part)
    }

    function updateSyunyuInfo(syunyuInfo: Partial<SyunyuInfoVo>) {
        if (syugakusyaIgai === undefined) return;
        syugakusyaIgai.syunyuInfo = {...syugakusyaIgai.syunyuInfo, ...syunyuInfo}
        members[memberIndex].syugakusyaIgai = syugakusyaIgai;
        updateFamilyInfo({members: members})
    }

    function updateSyunyuInfoErrorMessages(part: Partial<SwSyunyuInfoErrorMessages>) {
        const newErrors = {
            ...syugakusyaIgaiErrorMessages?.syunyuInfo,
            ...part
        };
        updateSyugakusyaIgaiErrorMessages({syunyuInfo: newErrors}, memberIndex)
    }

    // 計算
    const totalAmounts = calcTotalAmounts(syugakusyaIgai?.syunyuInfo, commonYosikiMasters?.syunyuKubun, syugakusyaIgai?.kakuteisinkokuAriNasi)
    if (commonYosikiMasters === undefined) return <></>;
    if (syugakusyaIgai === undefined) return <></>;

    return (
        <FormTableRow
            label={convertZokugaraForStr(member.zokugara, member.zokugaraOther)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.収入または所得がありますか)}<RequiredTag
                    type={RequiredTagTypeEnum.必須}/>
                </div>
                <RadioButtonGroup
                    errorMessages={convertErrorMessageVoToArrayStr(syugakusyaIgaiErrorMessages?.syunyuAriNasi)}
                    vertical={true}
                >
                    <RadioButton
                        value={""}
                        checked={syugakusyaIgai.syunyuAriNasi === true}
                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.あり)}
                        isError={syugakusyaIgaiErrorMessages?.syunyuAriNasi && syugakusyaIgaiErrorMessages.syunyuAriNasi.length !== 0}
                        handleChange={() => {
                            handleChangeSyunyuAriNasi(true)
                        }}
                    />
                    <RadioButton
                        value={""}
                        checked={syugakusyaIgai.syunyuAriNasi === false}
                        label={CommonTextEnumToNameJaAndEn(CommonTextEnum.なし)}
                        isError={syugakusyaIgaiErrorMessages?.syunyuAriNasi && syugakusyaIgaiErrorMessages.syunyuAriNasi.length !== 0}
                        handleChange={() => {
                            handleChangeSyunyuAriNasi(false)
                        }}
                    />
                </RadioButtonGroup>
            </div>

            {
                syugakusyaIgai.syunyuAriNasi ? (
                    <SyunyuContents
                        isHonnin={false}
                        syunyuInfo={syugakusyaIgai.syunyuInfo}
                        syunyuInfoErrorMessages={syugakusyaIgaiErrorMessages?.syunyuInfo}
                        kakuteisinkokuAriNasi={syugakusyaIgai?.kakuteisinkokuAriNasi}
                        kakuteisinkokuAriNasiErrorMessage={syugakusyaIgaiErrorMessages?.kakuteisinkokuAriNasi}
                        totalAmounts={totalAmounts}
                        updateSyunyuInfo={updateSyunyuInfo}
                        updateSyunyuInfoErrorMessages={updateSyunyuInfoErrorMessages}
                        handleChangeKakuteisinkokuAriNasi={handleChangeKakuteisinkokuAriNasi}
                    />
                ) : null
            }

        </FormTableRow>
    )
};
