import {Button} from "components/atoms/Button";
import React, {FC, useCallback, useEffect, useState} from "react";
import {Modal} from "components/atoms/Modal";
import DialogActions from "@material-ui/core/DialogActions";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "../../../../common/enums_text/CommonTextEnum";
import {NumberField} from "../../../atoms/forms/NumberField";
import {SyunyuKubunEnum, syunyuKubunEnumToName} from "../../../../common/enums/SyunyuKubunEnum";
import {toStrJaEn} from "../../../../common/functions/converter/toStrJaEn";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import {SyunyuInfoVo} from "../../../../common/models/YosikiInfoModels";
import {calcRisiHaito} from "common/functions/yosikiInfo/calcRisiHaito";
import lodash from 'lodash';
import {convertErrorMessageVoToArrayStr} from "../../../../common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "../../../../common/functions/isErrorMessage";
import {SwSyunyuInfoErrorMessages} from "../../../../openapi";

interface ItemProps {
    mt?: number
    label: string
    note?: string
}

const FieldItem: FC<ItemProps> = ({label, mt, note, children}) => {
    return (
        <Box mt={mt}>
            <Box mt={0.5} fontWeight={700}>{label}</Box>
            <Box mt={0.5}>
                {children}
            </Box>
            {note ? <Box mt={0.5}>{note}</Box> : null}
        </Box>
    )
}


interface Props {
    openState: boolean
    handleCloseModal: () => void
    handleConfirm: (v: SyunyuInfoVo, errorMessages?: SwSyunyuInfoErrorMessages) => void
    syunyuInfo: SyunyuInfoVo
    syunyuInfoErrorMessages?: SwSyunyuInfoErrorMessages
}

export const KakuteisinkokuFieldModal: FC<Props> = ({openState, handleCloseModal, handleConfirm, syunyuInfo, syunyuInfoErrorMessages}) => {

    const [formValues, updateFormValues] = useState<SyunyuInfoVo>()
    const [errorMessages, updateErrorMessages] = useState<SwSyunyuInfoErrorMessages>()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    useEffect(() => {
        if(openState){
            // NOTE: 参照しないようにオブジェクトをclone
            const copiedSyunyuInfo = lodash.cloneDeep(syunyuInfo) as SyunyuInfoVo;
            const copiedErrorMessages = lodash.cloneDeep(syunyuInfoErrorMessages) as SwSyunyuInfoErrorMessages;
            // NOTE: 初期化
            updateFormValues(copiedSyunyuInfo)
            updateErrorMessages(copiedErrorMessages)
        }
    }, [openState, syunyuInfo, syunyuInfoErrorMessages])

    const updates = useCallback((formValues: SyunyuInfoVo, errorMessages?: SwSyunyuInfoErrorMessages) => {
        updateFormValues({...formValues})
        updateErrorMessages({...errorMessages})
    }, [updateFormValues, updateErrorMessages])

    const updateKyuyo = useCallback((v: string) => {
        if(!formValues) return
        formValues.kyuyo.field.amount = v
        if(errorMessages) errorMessages.kyuyo = undefined
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateEigyo = useCallback((v: string) => {
        if(!formValues) return
        formValues.eigyo.field.amount = v
        if(errorMessages) errorMessages.eigyo = undefined
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateNorin = useCallback((v: string) => {
        if(!formValues) return
        formValues.norin.field.amount = v
        if(errorMessages) errorMessages.norin = undefined
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateFudosan = useCallback((v: string) => {
        if(!formValues) return
        formValues.hudosan.field.amount = v
        if(errorMessages) errorMessages.hudosan = undefined
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateRisi = useCallback((v: string) => {
        if(!formValues) return
        formValues.risi.field.amount = v
        if(errorMessages) errorMessages.risi = undefined
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    const updateHaito = useCallback((v: string) => {
        if(!formValues) return
        formValues.haito.field.amount = v
        if(errorMessages) errorMessages.haito = undefined
        updates(formValues, errorMessages)
    }, [formValues, errorMessages, updates])

    if(formValues === undefined) {
        return <></>
    }

    return (
        <Modal
            openState={openState}
            title={"確定申告書を元に収入・所得を入力 / Enter revenue and income based on tax returns"}
        >

            <Box mb={4}>確定申告書を用意し、画像を参考に収入または所得の金額を入力してください。金額が0円以下の場合は入力不要です。
                / Please prepare tax returns and enter the amount of revenue or income using the image as a reference.
                If the amount is 0 yen or less, entry is unnecessary.</Box>

            <Grid container={true} spacing={4}>
                <Grid item xs={matches ? 12 : 7}>
                    <img src={`${process.env.PUBLIC_URL}/images/yosikiEdit/img_kakuteisinkoku.png`}
                         alt=""/>
                </Grid>
                <Grid item xs={matches ? 12 : 5}>
                    <FieldItem
                        label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.給料賃金))}
                        note={"Aの金額 / Amount of A"}
                    >
                        <NumberField
                            widthInput={"120px"}
                            value={formValues.kyuyo.field.amount}
                            handleBlur={(v) => updateKyuyo(v)}
                            suffixText={"円"}
                            isError={isErrorMessage(errorMessages?.kyuyo)}
                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.kyuyo)}
                        />
                    </FieldItem>

                    <FieldItem
                        label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.営業))}
                        note={"Bの金額 / Amount of B"}
                        mt={2}
                    >
                        <NumberField
                            widthInput={"120px"}
                            value={formValues.eigyo.field.amount}
                            handleBlur={(v) => updateEigyo(v)}
                            suffixText={"円"}
                            isError={isErrorMessage(errorMessages?.eigyo)}
                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.eigyo)}
                        />
                    </FieldItem>

                    <FieldItem
                        label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.農林水産業))}
                        note={"Cの金額 / Amount of C"}
                        mt={2}
                    >
                        <NumberField
                            widthInput={"120px"}
                            value={formValues.norin.field.amount}
                            handleBlur={(v) => updateNorin(v)}
                            suffixText={"円"}
                            isError={isErrorMessage(errorMessages?.norin)}
                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.norin)}
                        />
                    </FieldItem>

                    <FieldItem
                        label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.不動産))}
                        note={"Dの金額 / Amount of D"}
                        mt={2}
                    >
                        <NumberField
                            widthInput={"120px"}
                            value={formValues.hudosan.field.amount}
                            handleBlur={(v) => updateFudosan(v)}
                            suffixText={"円"}
                            isError={isErrorMessage(errorMessages?.hudosan)}
                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.hudosan)}
                        />
                    </FieldItem>

                    <FieldItem
                        label={toStrJaEn(syunyuKubunEnumToName(SyunyuKubunEnum.利子配当))}
                        note={"Eの金額 / Amount of E"}
                        mt={2}
                    >
                        <NumberField
                            widthInput={"120px"}
                            value={formValues.risi.field.amount}
                            handleBlur={(v) => updateRisi(v)}
                            suffixText={"円"}
                            isError={isErrorMessage(errorMessages?.risi)}
                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.risi)}
                        />
                    </FieldItem>

                    <FieldItem
                        label={""}
                        note={"Fの金額 / Amount of F"}
                        mt={1}
                    >
                        <NumberField
                            widthInput={"120px"}
                            value={formValues.haito.field.amount}
                            handleBlur={(v) => updateHaito(v)}
                            suffixText={"円"}
                            isError={isErrorMessage(errorMessages?.haito)}
                            errorMessages={convertErrorMessageVoToArrayStr(errorMessages?.haito)}
                        />
                    </FieldItem>

                    <Box mt={2}>
                        <Box display={"flex"}
                             style={{gap: "4px"}}>
                            <Box>EとFの合計金額</Box>
                            <Box>{calcRisiHaito(formValues.risi.field.amount, formValues.haito.field.amount)}円</Box>
                        </Box>
                        <Box display={"flex"} mt={1} style={{gap: "4px"}}>
                            <Box>Total amount of E and F</Box>
                            <Box>{calcRisiHaito(formValues.risi.field.amount, formValues.haito.field.amount)}円</Box>
                        </Box>
                    </Box>

                </Grid>
            </Grid>

            <Box textAlign={"center"} mt={4}>
                「確定」を押すと入力内容が反映されます。「閉じる」を押すと入力内容は反映されません。<br/>
                Click "Confirm" to apply the information you have entered. If you click "Close", the entered information
                will not be reflected.
            </Box>

            <DialogActions className={"modal_actions"}>

                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.閉じる)}
                    width={"120px"}
                    handleClick={handleCloseModal}
                />

                <Button
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                    width={"120px"}
                    handleClick={() => handleConfirm(formValues, errorMessages)}
                />

            </DialogActions>

        </Modal>
    )
}
