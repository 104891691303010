import makeStyles from "@material-ui/core/styles/makeStyles";
import {SyogaisyaTypeEnum, syogaisyaTypeEnumToName} from "common/enums/SyogaisyaTypeEnum";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {convertZokugaraForStr} from "common/functions/converter/convertZokugaraForStr";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {joinArraySwErrorMessage} from "common/functions/converter/joinArraySwErrorMessage";
import {SyogaisyaVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {TitleNameTextEnum, TitleNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TitleNameTextEnum";
import {TokubetuKojoLabelTextEnum, TokubetuKojoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TokubetuKojoLabelTextEnum";
import {Button} from "components/atoms/Button";
import {RadioButton} from "components/atoms/forms/RadioButton";
import {TextField} from "components/atoms/forms/TextField";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {Title05} from "components/atoms/titles/Title05";
import {FormButton} from "components/molecules/forms/FormButton";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {RadioButtonGroup} from "components/molecules/forms/RadioButtonGroup";
import {SwSyogaisyaError, SwSyogaisyaErrorMessage} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface TokubetuKojoSyogaisyaProps {
    syogaisya: Array<SyogaisyaVo>
    syogaisyaErrorMessage: SwSyogaisyaErrorMessage
    handleChangeSyogaisya: (part: Partial<SyogaisyaVo>, index: number) => void
    handleClickDeleteSyogaisya: (index: number) => void
    handleClickAddSyogaisyaZokugara: () => void
    clearSyogaisyaErrorMessage: (part: Partial<SwSyogaisyaError>, index: number) => void
    clearDeleteSyogaisyaErrorMessage: (index: number) => void
}

interface SyogaisyaRowProps {
    syogaisya: SyogaisyaVo
    syogaisyaError: SwSyogaisyaError | undefined
    handleChangeSyogaisya: (part: Partial<SyogaisyaVo>, index: number) => void
    handleClickDeleteSyogaisya: (index: number) => void
    clearSyogaisyaErrorMessage: (part: Partial<SwSyogaisyaError>, index: number) => void
    index: number
    clearDeleteSyogaisyaErrorMessage: (index: number) => void
}

const SyogaisyaRow: FC<SyogaisyaRowProps> = (props) => {

    const classes = useStyles();

    const syogaisya = props.syogaisya;
    const syogaisyaErrorMessage = props.syogaisyaError;

    function handleChangeSyogaisyaType(value: string) {
        props.handleChangeSyogaisya({type: value as SyogaisyaTypeEnum}, props.index);
        props.clearSyogaisyaErrorMessage({type: undefined}, props.index)
    }

    function handleBlurSyogaisyaTypeOther(value: string) {
        props.handleChangeSyogaisya({typeOther: value}, props.index);
        props.clearSyogaisyaErrorMessage({typeOther: undefined}, props.index)
    }

    function handleBlurTetyoNumber(value: string) {
        props.handleChangeSyogaisya({tetyoNumber: value}, props.index);
        props.clearSyogaisyaErrorMessage({tetyoNumber: undefined}, props.index)
    }

    function handleClickDeleteSyogaisya() {
        props.handleClickDeleteSyogaisya(props.index);
        props.clearDeleteSyogaisyaErrorMessage(props.index)
    }

    let joinedTypeErrorMesssages = joinArraySwErrorMessage([syogaisyaErrorMessage?.type, syogaisyaErrorMessage?.typeOther]);

    return (
        <FormTableRow
            label={convertZokugaraForStr(syogaisya.zokugara, syogaisya.zokugaraOther)}
        >
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}<RequiredTag type={RequiredTagTypeEnum.必須}/>
                </div>
                <RadioButtonGroup
                    errorMessages={convertErrorMessageVoToArrayStr(joinedTypeErrorMesssages)}
                    vertical={true}
                >
                    <RadioButton
                        value={""}
                        checked={syogaisya.type === SyogaisyaTypeEnum.障害者}
                        label={convertEnumNameToStr(syogaisyaTypeEnumToName(SyogaisyaTypeEnum.障害者))}
                        isError={syogaisyaErrorMessage && isErrorMessage(syogaisyaErrorMessage.type)}
                        handleChange={() => {
                            handleChangeSyogaisyaType(SyogaisyaTypeEnum.障害者)
                        }}
                    />
                    <RadioButton
                        value={""}
                        checked={syogaisya.type === SyogaisyaTypeEnum.原爆被爆者}
                        label={convertEnumNameToStr(syogaisyaTypeEnumToName(SyogaisyaTypeEnum.原爆被爆者))}
                        isError={syogaisyaErrorMessage && isErrorMessage(syogaisyaErrorMessage.type)}
                        handleChange={() => {
                            handleChangeSyogaisyaType(SyogaisyaTypeEnum.原爆被爆者)
                        }}
                    />
                    <RadioButton
                        value={""}
                        valueText={syogaisya.typeOther}
                        checked={syogaisya.type === SyogaisyaTypeEnum.その他}
                        label={convertEnumNameToStr(syogaisyaTypeEnumToName(SyogaisyaTypeEnum.その他))}
                        isError={
                            syogaisyaErrorMessage &&
                            (isErrorMessage(syogaisyaErrorMessage.type) || isErrorMessage(syogaisyaErrorMessage.typeOther))
                        }
                        otherTextFieldEnabled={syogaisya.type === SyogaisyaTypeEnum.その他}
                        textFieldWidth={"120px"}
                        handleChange={() => {
                            handleChangeSyogaisyaType(SyogaisyaTypeEnum.その他)
                        }}
                        handleBlurText={handleBlurSyogaisyaTypeOther}
                    />
                </RadioButtonGroup>
            </div>
            <div className={"formTableRow_item"}>
                <div className={"formTableRow_item_label"}>
                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.手帳番号)}<RequiredTag type={RequiredTagTypeEnum.任意}/>
                </div>
                <TextField
                    value={syogaisya.tetyoNumber}
                    handleBlur={(value) => handleBlurTetyoNumber(value)}
                    isError={syogaisyaErrorMessage ? convertErrorMessageVoToArrayStr(syogaisyaErrorMessage.tetyoNumber).length !== 0 : false}
                    errorMessages={syogaisyaErrorMessage ? convertErrorMessageVoToArrayStr(syogaisyaErrorMessage.tetyoNumber) : []}
                />
            </div>

            <div className={`${classes.bottomRow}`}>
                <Button
                    size={'small'}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を削除)}
                    type={"warning"}
                    handleClick={handleClickDeleteSyogaisya}
                />
            </div>

        </FormTableRow>
    )

};

export const TokubetuKojoSyogaisya: FC<TokubetuKojoSyogaisyaProps> = (props) => {

    const classes = useStyles();

    const syogaisya: Array<SyogaisyaVo> = props.syogaisya;
    const syogaisyaErrors: Array<SwSyogaisyaError> | undefined = props.syogaisyaErrorMessage.syogaisyaErrors;

    return (

        <>
            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.障害者のいる世帯)}
                className={classes.title}
            />

            {syogaisya.length === 0 ? <div>{CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を追加してください)}</div> : null}

            {
                <ErrorMessages
                    errorMessages={convertErrorMessageVoToArrayStr(props.syogaisyaErrorMessage.policy)}
                />
            }

            <FormTable
                className={"section_table"}
            >

                {
                    syogaisya.map((s: SyogaisyaVo, index: number) => {
                        return (
                            <SyogaisyaRow
                                key={s.zokugara + index}
                                syogaisyaError={syogaisyaErrors && syogaisyaErrors[index]}
                                index={index}
                                syogaisya={s}
                                handleChangeSyogaisya={props.handleChangeSyogaisya}
                                handleClickDeleteSyogaisya={props.handleClickDeleteSyogaisya}
                                clearSyogaisyaErrorMessage={props.clearSyogaisyaErrorMessage}
                                clearDeleteSyogaisyaErrorMessage={props.clearDeleteSyogaisyaErrorMessage}
                            />
                        )
                    })
                }

            </FormTable>

            <FormButton
                className={"section_button"}
            >
                <Button
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を追加)}
                    handleClick={props.handleClickAddSyogaisyaZokugara}
                />
            </FormButton>

        </>

    )
};

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        },
        bottomRow: {
            marginTop: 4
        },
    }
);

