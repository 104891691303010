import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum CommonTextEnum {
    はい = "はい",
    いいえ = "いいえ",
    あり = "あり",
    なし = "なし",
    ある = "ある",
    ない = "ない",
    郵便番号ハイフンなしで入力してください = "郵便番号(ハイフンなしで入力してください)",
    住所 = "住所",
    該当する項目を選択してください = "該当する項目を選択してください。",
    保存 = "保存",
    確定 = "確定",
    確定解除 = "確定解除",
    入力 = "入力",
    キャンセル = "キャンセル",
    削除 = "削除",
    閉じる = "閉じる",
    戻る = "戻る",
    続柄を追加してください = "続柄を追加してください。",
    わからない場合は空欄にしてください = "わからない場合は空欄にしてください。",
    続柄を削除 = "続柄を削除",
    続柄を追加 = "続柄を追加",
    入力項目を削除 = "入力項目を削除",
    入力項目を追加 = "入力項目を追加",
    画面下の入力ボタンを押して入力してください = "画面下の「入力」ボタンを押して入力してください。",
    現在入力できません = "現在入力できません。",
    申し訳ありません問題が発生しました = "申し訳ありません。問題が発生しました。",
    受験番号 = "受験番号",
    未選択 = "未選択"
}

export function CommonTextEnumToNameJaAndEn(e: CommonTextEnum): string {
    switch (e) {
        case CommonTextEnum.はい:
            return convertEnumNameToStr({ja: "はい", en: "Yes"});
        case CommonTextEnum.いいえ:
            return convertEnumNameToStr({ja: "いいえ", en: "No"});
        case CommonTextEnum.ある:
            return convertEnumNameToStr({ja: "ある", en: "Yes"});
        case CommonTextEnum.ない:
            return convertEnumNameToStr({ja: "ない", en: "No"});
        case CommonTextEnum.あり:
            return convertEnumNameToStr({ja: "あり", en: "Yes"});
        case CommonTextEnum.なし:
            return convertEnumNameToStr({ja: "なし", en: "No"});
        case CommonTextEnum.郵便番号ハイフンなしで入力してください:
            return convertEnumNameToStr({ja: "郵便番号(ハイフンなしで入力してください)", en: "Zip code (without hyphens)"});
        case CommonTextEnum.住所:
            return convertEnumNameToStr({ja: "住所", en: "Address"});
        case CommonTextEnum.該当する項目を選択してください:
            return convertEnumNameToStr({ja: "該当する項目を選択してください。", en: "Select the applicable item."});
        case CommonTextEnum.保存:
            return convertEnumNameToStr({ja: "保存", en: "Save"});
        case CommonTextEnum.確定:
            return convertEnumNameToStr({ja: "確定", en: "Confirm"});
        case CommonTextEnum.確定解除:
            return convertEnumNameToStr({ja: "確定解除", en: "Cancel confirm"});
        case CommonTextEnum.入力:
            return convertEnumNameToStr({ja: "入力", en: "Enter"});
        case CommonTextEnum.キャンセル:
            return convertEnumNameToStr({ja: "キャンセル", en: "Cancel"});
        case CommonTextEnum.削除:
            return convertEnumNameToStr({ja: "削除", en: "Delete"});
        case CommonTextEnum.閉じる:
            return convertEnumNameToStr({ja: "閉じる", en: "Close"});
        case CommonTextEnum.戻る:
            return convertEnumNameToStr({ja: "戻る", en: "Back"});
        case CommonTextEnum.続柄を追加してください:
            return convertEnumNameToStr({ja: "続柄を追加してください。", en: "Please add a relationship."});
        case CommonTextEnum.わからない場合は空欄にしてください:
            return convertEnumNameToStr({ja: "わからない場合は空欄にしてください。", en: "If you do not know, leave this item blank."});
        case CommonTextEnum.続柄を削除:
            return convertEnumNameToStr({ja: "続柄を削除", en: "Delete relationship"});
        case CommonTextEnum.続柄を追加:
            return convertEnumNameToStr({ja: "続柄を追加", en: "Add relationship"});
        case CommonTextEnum.入力項目を削除:
            return convertEnumNameToStr({ja: "入力項目を削除", en: "Delete Input Item"});
        case CommonTextEnum.入力項目を追加:
            return convertEnumNameToStr({ja: "入力項目を追加", en: "Add Input Item"});
        case CommonTextEnum.画面下の入力ボタンを押して入力してください:
            return convertEnumNameToStr({ja: "画面下の「入力」ボタンを押して入力してください。", en: "Press the \"ENTER\" button at the bottom of the screen to enter the information."});
        case CommonTextEnum.現在入力できません:
            return convertEnumNameToStr({ja: "現在入力できません。", en: "Unable to enter now."});
        case CommonTextEnum.申し訳ありません問題が発生しました:
            return convertEnumNameToStr({ja: "申し訳ありません。問題が発生しました。", en: "The problem has occurred."});
        case CommonTextEnum.受験番号:
            return convertEnumNameToStr({ja: "受験番号", en: "Examinee's (seat) number"});
        case CommonTextEnum.未選択:
            return convertEnumNameToStr({ja: "未選択", en: "Not selected"});
        default :
            return ""
    }
}


