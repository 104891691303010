import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {NyugakuryoInfoVo} from "common/models/YosikiInfoModels";
import {
    NyugakuryoInfoLabelTextEnum,
    NyugakuryoInfoLabelTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/NyugakuryoInfoLabelTextEnum";
import {NyugakuryoInfoTextEnum, NyugakuryoInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/NyugakuryoInfoTextEnum";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {TextField} from "components/atoms/forms/TextField";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {AnnotationText} from "components/atoms/AnnotationText";
import {ErrorCountMessage} from "components/atoms/ErrorCountMessage";
import {Title04} from "components/atoms/titles/Title04";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {SwNyugakuryoInfoErrorMessage} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface NyugakuryoInfoInfoSectionProps {
    className?: string
    nyugakuryoInfo: NyugakuryoInfoVo;
    nyugakuryoInfoErrorMessage: SwNyugakuryoInfoErrorMessage
    updateNyugakuryoInfo: (part: Partial<NyugakuryoInfoVo>) => void
    updateNyugakuryoInfoErrorMessage: (part: Partial<SwNyugakuryoInfoErrorMessage>) => void
    errorCount: number
}

export const _NyugakuryoInfoSection: FC<NyugakuryoInfoInfoSectionProps> = (props) => {

    const classes = useStyles();

    function handleBlurRiyu(value: string) {
        props.updateNyugakuryoInfo({riyu: value});
        props.updateNyugakuryoInfoErrorMessage({riyu: undefined});
    }

    const nyugakuryoInfo: NyugakuryoInfoVo = props.nyugakuryoInfo;
    const nyugakuryoInfoErrorMessage: SwNyugakuryoInfoErrorMessage = props.nyugakuryoInfoErrorMessage;

    return (
        <div id={SECTION_ID_NAME.nyugakuryoInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.入学料免除徴収猶予申請理由)}
                className={"section_title"}
            />

            <ErrorCountMessage
                count={props.errorCount}
            />

            <FormTable
                className={"section_table"}
            >
                <FormTableRow
                    label={NyugakuryoInfoLabelTextEnumToNameJaAndEn(NyugakuryoInfoLabelTextEnum.免除または徴収猶予の理由)}
                >
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {NyugakuryoInfoTextEnumToNameJaAndEn(NyugakuryoInfoTextEnum.どういう原因で)}<RequiredTag
                            type={RequiredTagTypeEnum.必須}/>
                        </div>
                        <AnnotationText className={"mb4"} text={NyugakuryoInfoTextEnumToNameJaAndEn(NyugakuryoInfoTextEnum.字以上入力してください)}/>
                        <TextField
                            rows={8}
                            multiline={true}
                            value={nyugakuryoInfo.riyu}
                            errorMessages={convertErrorMessageVoToArrayStr(nyugakuryoInfoErrorMessage.riyu)}
                            isError={isErrorMessage(nyugakuryoInfoErrorMessage.riyu)}
                            handleBlur={handleBlurRiyu}
                        />
                    </div>
                </FormTableRow>
            </FormTable>

        </div>

    )
};

export const NyugakuryoInfoSection = React.memo<NyugakuryoInfoInfoSectionProps>(_NyugakuryoInfoSection, (prev, next) => {
    return prev.className === next.className
        && prev.nyugakuryoInfo === next.nyugakuryoInfo
        && prev.nyugakuryoInfoErrorMessage === next.nyugakuryoInfoErrorMessage
        && prev.updateNyugakuryoInfo === next.updateNyugakuryoInfo
        && prev.updateNyugakuryoInfoErrorMessage === next.updateNyugakuryoInfoErrorMessage
        && prev.errorCount === next.errorCount
});

const useStyles = makeStyles({
        root: {},
    }
);

