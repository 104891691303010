/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwPostBasicInfoRequestSinseiType
 */
export interface SwPostBasicInfoRequestSinseiType {
    /**
     * 
     * @type {string}
     * @memberof SwPostBasicInfoRequestSinseiType
     */
    nyugakuryo?: string;
    /**
     * 
     * @type {string}
     * @memberof SwPostBasicInfoRequestSinseiType
     */
    jugyoryo?: string;
}

export function SwPostBasicInfoRequestSinseiTypeFromJSON(json: any): SwPostBasicInfoRequestSinseiType {
    return SwPostBasicInfoRequestSinseiTypeFromJSONTyped(json, false);
}

export function SwPostBasicInfoRequestSinseiTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwPostBasicInfoRequestSinseiType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nyugakuryo': !exists(json, 'nyugakuryo') ? undefined : json['nyugakuryo'],
        'jugyoryo': !exists(json, 'jugyoryo') ? undefined : json['jugyoryo'],
    };
}

export function SwPostBasicInfoRequestSinseiTypeToJSON(value?: SwPostBasicInfoRequestSinseiType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nyugakuryo': value.nyugakuryo,
        'jugyoryo': value.jugyoryo,
    };
}


