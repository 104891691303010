/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwAYosikiFormCommonBessi1ErrorMessage,
    SwAYosikiFormCommonBessi1ErrorMessageFromJSON,
    SwAYosikiFormCommonBessi1ErrorMessageFromJSONTyped,
    SwAYosikiFormCommonBessi1ErrorMessageToJSON,
    SwAYosikiFormCommonBessi2ErrorMessage,
    SwAYosikiFormCommonBessi2ErrorMessageFromJSON,
    SwAYosikiFormCommonBessi2ErrorMessageFromJSONTyped,
    SwAYosikiFormCommonBessi2ErrorMessageToJSON,
    SwAddressErrorMessage,
    SwAddressErrorMessageFromJSON,
    SwAddressErrorMessageFromJSONTyped,
    SwAddressErrorMessageToJSON,
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwNihonGakuseiSienKikoErrorMessage,
    SwNihonGakuseiSienKikoErrorMessageFromJSON,
    SwNihonGakuseiSienKikoErrorMessageFromJSONTyped,
    SwNihonGakuseiSienKikoErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiFormCommonErrorMessage
 */
export interface SwAYosikiFormCommonErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormCommonErrorMessage
     */
    birthYear?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormCommonErrorMessage
     */
    birthMonth?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormCommonErrorMessage
     */
    birthDay?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormCommonErrorMessage
     */
    gakunen?: Array<SwErrorMessage>;
    /**
     * 
     * @type {SwAddressErrorMessage}
     * @memberof SwAYosikiFormCommonErrorMessage
     */
    address?: SwAddressErrorMessage;
    /**
     * 
     * @type {SwNihonGakuseiSienKikoErrorMessage}
     * @memberof SwAYosikiFormCommonErrorMessage
     */
    nihonGakuseiSienKiko?: SwNihonGakuseiSienKikoErrorMessage;
    /**
     * 
     * @type {SwAYosikiFormCommonBessi1ErrorMessage}
     * @memberof SwAYosikiFormCommonErrorMessage
     */
    bessi1?: SwAYosikiFormCommonBessi1ErrorMessage;
    /**
     * 
     * @type {SwAYosikiFormCommonBessi2ErrorMessage}
     * @memberof SwAYosikiFormCommonErrorMessage
     */
    bessi2?: SwAYosikiFormCommonBessi2ErrorMessage;
}

export function SwAYosikiFormCommonErrorMessageFromJSON(json: any): SwAYosikiFormCommonErrorMessage {
    return SwAYosikiFormCommonErrorMessageFromJSONTyped(json, false);
}

export function SwAYosikiFormCommonErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormCommonErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthYear': !exists(json, 'birthYear') ? undefined : ((json['birthYear'] as Array<any>).map(SwErrorMessageFromJSON)),
        'birthMonth': !exists(json, 'birthMonth') ? undefined : ((json['birthMonth'] as Array<any>).map(SwErrorMessageFromJSON)),
        'birthDay': !exists(json, 'birthDay') ? undefined : ((json['birthDay'] as Array<any>).map(SwErrorMessageFromJSON)),
        'gakunen': !exists(json, 'gakunen') ? undefined : ((json['gakunen'] as Array<any>).map(SwErrorMessageFromJSON)),
        'address': !exists(json, 'address') ? undefined : SwAddressErrorMessageFromJSON(json['address']),
        'nihonGakuseiSienKiko': !exists(json, 'nihonGakuseiSienKiko') ? undefined : SwNihonGakuseiSienKikoErrorMessageFromJSON(json['nihonGakuseiSienKiko']),
        'bessi1': !exists(json, 'bessi1') ? undefined : SwAYosikiFormCommonBessi1ErrorMessageFromJSON(json['bessi1']),
        'bessi2': !exists(json, 'bessi2') ? undefined : SwAYosikiFormCommonBessi2ErrorMessageFromJSON(json['bessi2']),
    };
}

export function SwAYosikiFormCommonErrorMessageToJSON(value?: SwAYosikiFormCommonErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthYear': value.birthYear === undefined ? undefined : ((value.birthYear as Array<any>).map(SwErrorMessageToJSON)),
        'birthMonth': value.birthMonth === undefined ? undefined : ((value.birthMonth as Array<any>).map(SwErrorMessageToJSON)),
        'birthDay': value.birthDay === undefined ? undefined : ((value.birthDay as Array<any>).map(SwErrorMessageToJSON)),
        'gakunen': value.gakunen === undefined ? undefined : ((value.gakunen as Array<any>).map(SwErrorMessageToJSON)),
        'address': SwAddressErrorMessageToJSON(value.address),
        'nihonGakuseiSienKiko': SwNihonGakuseiSienKikoErrorMessageToJSON(value.nihonGakuseiSienKiko),
        'bessi1': SwAYosikiFormCommonBessi1ErrorMessageToJSON(value.bessi1),
        'bessi2': SwAYosikiFormCommonBessi2ErrorMessageToJSON(value.bessi2),
    };
}


