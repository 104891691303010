/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwSienzumiErrorMessage,
    SwSienzumiErrorMessageFromJSON,
    SwSienzumiErrorMessageFromJSONTyped,
    SwSienzumiErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiFormAYosiki1ErrorMessage
 */
export interface SwAYosikiFormAYosiki1ErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormAYosiki1ErrorMessage
     */
    agreement?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormAYosiki1ErrorMessage
     */
    isSienzumi?: Array<SwErrorMessage>;
    /**
     * 
     * @type {SwSienzumiErrorMessage}
     * @memberof SwAYosikiFormAYosiki1ErrorMessage
     */
    sienzumi?: SwSienzumiErrorMessage;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormAYosiki1ErrorMessage
     */
    menjoBefore?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormAYosiki1ErrorMessage
     */
    mosikomiType?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormAYosiki1ErrorMessage
     */
    uketukeNumber?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwAYosikiFormAYosiki1ErrorMessage
     */
    syogakuseiNumber?: Array<SwErrorMessage>;
}

export function SwAYosikiFormAYosiki1ErrorMessageFromJSON(json: any): SwAYosikiFormAYosiki1ErrorMessage {
    return SwAYosikiFormAYosiki1ErrorMessageFromJSONTyped(json, false);
}

export function SwAYosikiFormAYosiki1ErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiFormAYosiki1ErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agreement': !exists(json, 'agreement') ? undefined : ((json['agreement'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isSienzumi': !exists(json, 'isSienzumi') ? undefined : ((json['isSienzumi'] as Array<any>).map(SwErrorMessageFromJSON)),
        'sienzumi': !exists(json, 'sienzumi') ? undefined : SwSienzumiErrorMessageFromJSON(json['sienzumi']),
        'menjoBefore': !exists(json, 'menjoBefore') ? undefined : ((json['menjoBefore'] as Array<any>).map(SwErrorMessageFromJSON)),
        'mosikomiType': !exists(json, 'mosikomiType') ? undefined : ((json['mosikomiType'] as Array<any>).map(SwErrorMessageFromJSON)),
        'uketukeNumber': !exists(json, 'uketukeNumber') ? undefined : ((json['uketukeNumber'] as Array<any>).map(SwErrorMessageFromJSON)),
        'syogakuseiNumber': !exists(json, 'syogakuseiNumber') ? undefined : ((json['syogakuseiNumber'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwAYosikiFormAYosiki1ErrorMessageToJSON(value?: SwAYosikiFormAYosiki1ErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agreement': value.agreement === undefined ? undefined : ((value.agreement as Array<any>).map(SwErrorMessageToJSON)),
        'isSienzumi': value.isSienzumi === undefined ? undefined : ((value.isSienzumi as Array<any>).map(SwErrorMessageToJSON)),
        'sienzumi': SwSienzumiErrorMessageToJSON(value.sienzumi),
        'menjoBefore': value.menjoBefore === undefined ? undefined : ((value.menjoBefore as Array<any>).map(SwErrorMessageToJSON)),
        'mosikomiType': value.mosikomiType === undefined ? undefined : ((value.mosikomiType as Array<any>).map(SwErrorMessageToJSON)),
        'uketukeNumber': value.uketukeNumber === undefined ? undefined : ((value.uketukeNumber as Array<any>).map(SwErrorMessageToJSON)),
        'syogakuseiNumber': value.syogakuseiNumber === undefined ? undefined : ((value.syogakuseiNumber as Array<any>).map(SwErrorMessageToJSON)),
    };
}


