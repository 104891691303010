import {Severity} from "@sentry/types";
import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {sentryLog} from "utilities/sentryLog";

export function handleStudentError(res: any) {
    switch (res.status) {
        // 認証がない場合
        case 401:
            console.log(res);
            if (process.env.REACT_APP_TARGET_SERVER !== "mock_server"){
                // mockでの開発では遷移を無視する。
                window.location.assign(URL_PATHS_STUDENT.login);
            }
            break;
        default:
            console.log(res);
            sentryLog("student error", res, Severity.Error);
            alert(CommonTextEnumToNameJaAndEn(CommonTextEnum.申し訳ありません問題が発生しました));
    }
}