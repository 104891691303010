import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";
import {getParam} from "utilities/getParam";
import {convertStrToNumOrUndefined} from "../../../../common/functions/type_converter/convertStrToNumOrUndefined";
import { SwStudentCreateAccountNewStudentsPostErrorMessage } from "openapi/models/SwStudentCreateAccountNewStudentsPostErrorMessage";
import { StudentCreateAccountNewStudentsApi } from "openapi/apis/StudentCreateAccountNewStudentsApi";

export interface CreateAccountNewStudentsStoreVo {
    form: CreateAccountNewStudentsFormVo
    errorMessages?: SwStudentCreateAccountNewStudentsPostErrorMessage
}

export interface CreateAccountNewStudentsFormVo {
    jukenNumber: string;
    name: string;
    nameKana: string;
    birthYear: string;
    birthMonth: string;
    birthDay: string;
    age: string;
    tel: string;
    bukyokuCode: string;
    gakka: string;
    isRyugakusei?: boolean;
    isEijusya?: boolean;
    password: string;
    passwordComfirm: string;
}

class CreateAccountNewStudentsStore extends Store<CreateAccountNewStudentsStoreVo> {

    private api = new StudentCreateAccountNewStudentsApi(apiConfiguration);

    constructor() {
        super({
            form: {
                jukenNumber: "",
                name: "",
                nameKana: "",
                birthYear: "",
                birthMonth: "",
                birthDay: "",
                age: "",
                tel: "",
                bukyokuCode: "",
                gakka: "",
                isRyugakusei: undefined,
                isEijusya: undefined,
                password: "",
                passwordComfirm: "",
            },
        })
    }

    updateForm(part: Partial<CreateAccountNewStudentsFormVo>) {
        const newForm = {
            ...this.currentState.form,
            ...part
        };
        this.dispatch(() => ({form: newForm}));
    }

    updateErrorMessages(errorMessages: Partial<SwStudentCreateAccountNewStudentsPostErrorMessage> | undefined) {
        this.dispatch(() => ({
            errorMessages: {
                ...this.currentState.errorMessages,
                ...errorMessages
            }
        }));
    }

    async postStudentCreateAccount() {
        const token = getParam("token") || "";
        try {
            const res = await this.api.postStudentCreateAccountNewStudents({
                swStudentCreateAccountNewStudentsPostRequest: {
                    ...this.currentState.form,
                    token: token,
                    age: convertStrToNumOrUndefined(this.currentState.form.age),
                    birthYear: convertStrToNumOrUndefined(this.currentState.form.birthYear),
                    birthMonth: convertStrToNumOrUndefined(this.currentState.form.birthMonth),
                    birthDay: convertStrToNumOrUndefined(this.currentState.form.birthDay),
                }
            });
            if (!res.error) {
                window.location.assign(URL_PATHS_STUDENT.create_account_new_students_complete);
                this.updateErrorMessages(undefined)
            } else {
                this.updateErrorMessages(res.error)
            }
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const createAccountNewStudentsStore = new CreateAccountNewStudentsStore();
