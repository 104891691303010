import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {RequiredSyoruisStoreVo, RequiredSyoruisSyoruisVo} from "common/models/SyoruisModels";
import {StudentSyoruisApi} from "openapi/apis";
import {SwOfficerSyoruis} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";

class StudentSyoruisStore extends Store<RequiredSyoruisStoreVo> {

    private api = new StudentSyoruisApi(apiConfiguration);

    constructor() {
        super({})
    }

    updateStudentSyoruis(syoruis: RequiredSyoruisSyoruisVo | undefined) {
        this.dispatch(() => ({syoruis: syoruis}));
    }

    async getSyoruisStudentId() {
        try {
            let res: SwOfficerSyoruis = await this.api.getStudentSyoruis();
            this.updateStudentSyoruis(res.syoruis);
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const studentRequiedSyoruisStore = new StudentSyoruisStore();
