/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwSyogakukinErrorMessages
 */
export interface SwSyogakukinErrorMessages {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogakukinErrorMessages
     */
    name?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogakukinErrorMessages
     */
    amountPerMonth?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogakukinErrorMessages
     */
    times?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogakukinErrorMessages
     */
    startYear?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogakukinErrorMessages
     */
    startMonth?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogakukinErrorMessages
     */
    endYear?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwSyogakukinErrorMessages
     */
    endMonth?: Array<SwErrorMessage>;
}

export function SwSyogakukinErrorMessagesFromJSON(json: any): SwSyogakukinErrorMessages {
    return SwSyogakukinErrorMessagesFromJSONTyped(json, false);
}

export function SwSyogakukinErrorMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyogakukinErrorMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : ((json['name'] as Array<any>).map(SwErrorMessageFromJSON)),
        'amountPerMonth': !exists(json, 'amountPerMonth') ? undefined : ((json['amountPerMonth'] as Array<any>).map(SwErrorMessageFromJSON)),
        'times': !exists(json, 'times') ? undefined : ((json['times'] as Array<any>).map(SwErrorMessageFromJSON)),
        'startYear': !exists(json, 'startYear') ? undefined : ((json['startYear'] as Array<any>).map(SwErrorMessageFromJSON)),
        'startMonth': !exists(json, 'startMonth') ? undefined : ((json['startMonth'] as Array<any>).map(SwErrorMessageFromJSON)),
        'endYear': !exists(json, 'endYear') ? undefined : ((json['endYear'] as Array<any>).map(SwErrorMessageFromJSON)),
        'endMonth': !exists(json, 'endMonth') ? undefined : ((json['endMonth'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwSyogakukinErrorMessagesToJSON(value?: SwSyogakukinErrorMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name === undefined ? undefined : ((value.name as Array<any>).map(SwErrorMessageToJSON)),
        'amountPerMonth': value.amountPerMonth === undefined ? undefined : ((value.amountPerMonth as Array<any>).map(SwErrorMessageToJSON)),
        'times': value.times === undefined ? undefined : ((value.times as Array<any>).map(SwErrorMessageToJSON)),
        'startYear': value.startYear === undefined ? undefined : ((value.startYear as Array<any>).map(SwErrorMessageToJSON)),
        'startMonth': value.startMonth === undefined ? undefined : ((value.startMonth as Array<any>).map(SwErrorMessageToJSON)),
        'endYear': value.endYear === undefined ? undefined : ((value.endYear as Array<any>).map(SwErrorMessageToJSON)),
        'endMonth': value.endMonth === undefined ? undefined : ((value.endMonth as Array<any>).map(SwErrorMessageToJSON)),
    };
}


