// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum TokubetuKojoEnum {
    母子父子 = "E母子父子",
    障害者 = "E障害者",
    生活保護世帯 = "E生活保護世帯",
    独立生計者 = "E独立生計者",
    長期療養者 = "E長期療養者",
    別居 = "E別居",
    災害被害 = "E災害被害",
}

export function tokubetuKojoEnumToName(e: TokubetuKojoEnum): { ja: string, en: string } {
    switch (e) {
        case TokubetuKojoEnum.母子父子: return { ja: "母子・父子世帯", en: "Single-parent households" };
        case TokubetuKojoEnum.障害者: return { ja: "障害者のいる世帯", en: "Households with members who are handicapped" };
        case TokubetuKojoEnum.生活保護世帯: return { ja: "生活保護世帯", en: "Households receiving public welfare assistance" };
        case TokubetuKojoEnum.独立生計者: return { ja: "申請者本人が独立生計者", en: "Applicant is an individual in independent household" };
        case TokubetuKojoEnum.長期療養者: return { ja: "長期療養者のいる世帯", en: "Households with long-term care patients" };
        case TokubetuKojoEnum.別居: return { ja: "主たる家計支持者別居の世帯", en: "Households in which the primary household supporter lives separately from the family" };
        case TokubetuKojoEnum.災害被害: return { ja: "火災・風水害・盗難等の被害を受けた世帯", en: "Households affected by a fire, flood, storm, or other natural disaster, or by theft" };
    }
}