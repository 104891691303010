/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwOfficerKikansStudentIdKikansKikan
 */
export interface SwOfficerKikansStudentIdKikansKikan {
    /**
     * 
     * @type {boolean}
     * @memberof SwOfficerKikansStudentIdKikansKikan
     */
    isCurrent: boolean;
    /**
     * フロントでDate型に変換
     * @type {string}
     * @memberof SwOfficerKikansStudentIdKikansKikan
     */
    startDate: string;
    /**
     * 00 2桁
     * @type {string}
     * @memberof SwOfficerKikansStudentIdKikansKikan
     */
    startHour: string;
    /**
     * 00 2桁
     * @type {string}
     * @memberof SwOfficerKikansStudentIdKikansKikan
     */
    startMinute: string;
    /**
     * フロントでDate型に変換
     * @type {string}
     * @memberof SwOfficerKikansStudentIdKikansKikan
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerKikansStudentIdKikansKikan
     */
    endHour: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerKikansStudentIdKikansKikan
     */
    endMinute: string;
}

export function SwOfficerKikansStudentIdKikansKikanFromJSON(json: any): SwOfficerKikansStudentIdKikansKikan {
    return SwOfficerKikansStudentIdKikansKikanFromJSONTyped(json, false);
}

export function SwOfficerKikansStudentIdKikansKikanFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerKikansStudentIdKikansKikan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isCurrent': json['isCurrent'],
        'startDate': json['startDate'],
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'endDate': json['endDate'],
        'endHour': json['endHour'],
        'endMinute': json['endMinute'],
    };
}

export function SwOfficerKikansStudentIdKikansKikanToJSON(value?: SwOfficerKikansStudentIdKikansKikan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isCurrent': value.isCurrent,
        'startDate': value.startDate,
        'startHour': value.startHour,
        'startMinute': value.startMinute,
        'endDate': value.endDate,
        'endHour': value.endHour,
        'endMinute': value.endMinute,
    };
}


