/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOsirasePutErrorMessage
 */
export interface SwOsirasePutErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwOsirasePutErrorMessage
     */
    title?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwOsirasePutErrorMessage
     */
    body?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwOsirasePutErrorMessage
     */
    uploadedFileIds?: Array<SwErrorMessage>;
}

export function SwOsirasePutErrorMessageFromJSON(json: any): SwOsirasePutErrorMessage {
    return SwOsirasePutErrorMessageFromJSONTyped(json, false);
}

export function SwOsirasePutErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOsirasePutErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : ((json['title'] as Array<any>).map(SwErrorMessageFromJSON)),
        'body': !exists(json, 'body') ? undefined : ((json['body'] as Array<any>).map(SwErrorMessageFromJSON)),
        'uploadedFileIds': !exists(json, 'uploadedFileIds') ? undefined : ((json['uploadedFileIds'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwOsirasePutErrorMessageToJSON(value?: SwOsirasePutErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title === undefined ? undefined : ((value.title as Array<any>).map(SwErrorMessageToJSON)),
        'body': value.body === undefined ? undefined : ((value.body as Array<any>).map(SwErrorMessageToJSON)),
        'uploadedFileIds': value.uploadedFileIds === undefined ? undefined : ((value.uploadedFileIds as Array<any>).map(SwErrorMessageToJSON)),
    };
}


