import {createStyles, makeStyles} from "@material-ui/core/";
import Link from "@material-ui/core/Link";
import React, {FC, MouseEvent, ReactNode} from 'react';
import {TextColorsEnum} from "common/enums_value/ColorsEnum";

interface TextLinkProps {
    icon?: ReactNode;
    className?: string;
    text: string;
    href?: string;
    target?: string;
    to?: string;
    iconReverse?: boolean;
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void,
    download?: boolean
}

export const TextLink: FC<TextLinkProps> = (props) => {
    const classes = useStyles(props);
    const icon = props.icon ? <span className={"icon"}>{props.icon}</span> : null;
    return (
        <Link
            underline={"always"}
            href={props.href}
            target={props.target}
            className={`${classes.root} ${props.className} ${props.iconReverse && classes.iconReverse}`}
            onClick={props.onClick}
            download={props.download}
        >
            {
                props.iconReverse ?
                    <>{icon}{props.text}</>
                    : <>{props.text}{icon}</>
            }
        </Link>
    )
};

const useStyles = makeStyles((props) => createStyles({
    root: {
        color: TextColorsEnum.Blue,
        fontWeight: "bold",
        cursor: "pointer",
        "& .icon .MuiSvgIcon-root": {
            marginLeft: 2,
            marginBottom: -4,
        },
        '&:hover': {
            filter: " brightness(1.15)",
        },
    },
    iconReverse: {
        flexFlow: "row-reverse",
        "& .icon .MuiSvgIcon-root": {
            marginLeft: 0,
            marginRight: 2,
        },
    },
}));