// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum JugyoryoMenjoStateEnum {
    全額 = "E全額",
    半額 = "E半額",
    無し = "E無し",
}

export function jugyoryoMenjoStateEnumToName(e: JugyoryoMenjoStateEnum): { ja: string, en: string } {
    switch (e) {
        case JugyoryoMenjoStateEnum.全額: return { ja: "全額免除を受けている", en: "Receives full waiver." };
        case JugyoryoMenjoStateEnum.半額: return { ja: "半額免除を受けている", en: "Receives half waiver." };
        case JugyoryoMenjoStateEnum.無し: return { ja: "免除を受けていない", en: "No waiver received." };
    }
}