/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwTyokiryoyo
 */
export interface SwTyokiryoyo {
    /**
     * enum
     * @type {string}
     * @memberof SwTyokiryoyo
     */
    zokugara: string;
    /**
     * 
     * @type {string}
     * @memberof SwTyokiryoyo
     */
    zokugaraOther: string;
    /**
     * enum
     * @type {string}
     * @memberof SwTyokiryoyo
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof SwTyokiryoyo
     */
    startYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SwTyokiryoyo
     */
    startMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof SwTyokiryoyo
     */
    amountPerHalfYear?: number;
}

export function SwTyokiryoyoFromJSON(json: any): SwTyokiryoyo {
    return SwTyokiryoyoFromJSONTyped(json, false);
}

export function SwTyokiryoyoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwTyokiryoyo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zokugara': json['zokugara'],
        'zokugaraOther': json['zokugaraOther'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'startYear': !exists(json, 'startYear') ? undefined : json['startYear'],
        'startMonth': !exists(json, 'startMonth') ? undefined : json['startMonth'],
        'amountPerHalfYear': !exists(json, 'amountPerHalfYear') ? undefined : json['amountPerHalfYear'],
    };
}

export function SwTyokiryoyoToJSON(value?: SwTyokiryoyo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zokugara': value.zokugara,
        'zokugaraOther': value.zokugaraOther,
        'type': value.type,
        'startYear': value.startYear,
        'startMonth': value.startMonth,
        'amountPerHalfYear': value.amountPerHalfYear,
    };
}


