/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSyunyuInfoItemField,
    SwSyunyuInfoItemFieldFromJSON,
    SwSyunyuInfoItemFieldFromJSONTyped,
    SwSyunyuInfoItemFieldToJSON,
} from './';

/**
 * 確定申告、源泉 入力項目は1つ固定
 * @export
 * @interface SwSyunyuInfoItem
 */
export interface SwSyunyuInfoItem {
    /**
     * enum
     * @type {string}
     * @memberof SwSyunyuInfoItem
     */
    kubunCode: string;
    /**
     * 
     * @type {SwSyunyuInfoItemField}
     * @memberof SwSyunyuInfoItem
     */
    field: SwSyunyuInfoItemField;
}

export function SwSyunyuInfoItemFromJSON(json: any): SwSyunyuInfoItem {
    return SwSyunyuInfoItemFromJSONTyped(json, false);
}

export function SwSyunyuInfoItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSyunyuInfoItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kubunCode': json['kubunCode'],
        'field': SwSyunyuInfoItemFieldFromJSON(json['field']),
    };
}

export function SwSyunyuInfoItemToJSON(value?: SwSyunyuInfoItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kubunCode': value.kubunCode,
        'field': SwSyunyuInfoItemFieldToJSON(value.field),
    };
}


