import DialogActions from "@material-ui/core/DialogActions";
import {zokugaraEnumToName} from "common/enums/ZokugaraEnum";
import {FamilyInfoVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {Button} from "components/atoms/Button";
import {Modal} from "components/atoms/Modal";
import {SwFamilyInfoErrorMessage} from "openapi/models";
import React from 'react';

interface YosikiEditDeleteZokugaraModalProps {
    openState: boolean
    selectedZokugaraIndex?: number
    familyInfo: FamilyInfoVo
    updateFamilyInfo: (part: Partial<FamilyInfoVo>) => void
    updateDeleteZokugaraModalOpenState: (value: boolean) => void
    updateFamilyInfoErrorMessage: (part: Partial<SwFamilyInfoErrorMessage>) => void
    familyInfoErrorMessage: SwFamilyInfoErrorMessage;
}

export const YosikiEditDeleteZokugaraModal: React.FC<YosikiEditDeleteZokugaraModalProps> = ({
                                                                                                openState,
                                                                                                selectedZokugaraIndex,
                                                                                                familyInfo,
                                                                                                updateFamilyInfo,
                                                                                                updateDeleteZokugaraModalOpenState,
                                                                                                updateFamilyInfoErrorMessage,
                                                                                                familyInfoErrorMessage,
                                                                                            }) => {


    // NOTE: 続柄の情報を削除
    async function deleteMember(index: number) {
        // 入力情報
        const copyMember = familyInfo.members.slice();
        copyMember.splice(index, 1);
        updateFamilyInfo({members: copyMember});
        // エラー情報
        const copyMemberError = familyInfoErrorMessage.members.slice();
        copyMemberError.splice(index, 1)
        updateFamilyInfoErrorMessage({
            members: copyMemberError,
            dokuritu: undefined,
            bosiHusi: undefined
        })
    }

    async function handleClickComfirm() {
        if (selectedZokugaraIndex === undefined) return;
        await deleteMember(selectedZokugaraIndex);
        updateDeleteZokugaraModalOpenState(false)
    }

    function handleClickClose() {
        updateDeleteZokugaraModalOpenState(false)
    }

    if (selectedZokugaraIndex === undefined) return <></>;
    const member = familyInfo.members[selectedZokugaraIndex];
    if (member === undefined) return <div/>;

    return (

        <Modal
            openState={openState}
            title={CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を削除)}
        >

            <div>
                続柄「{zokugaraEnumToName(member.zokugara).ja} {member.zokugaraOther} {member.name}」を削除しますか？<br/>
                Do you want to delete 「{zokugaraEnumToName(member.zokugara).en} {member.zokugaraOther} {member.name}」?
            </div>

            <DialogActions className={"modal_actions"}>
                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.キャンセル)}
                    width={"152px"}
                    handleClick={handleClickClose}
                />
                <Button
                    type={"destructive"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.削除)}
                    width={"120px"}
                    handleClick={handleClickComfirm}
                />
            </DialogActions>

        </Modal>

    );
};


