/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwKikanRaw,
    SwKikanRawFromJSON,
    SwKikanRawFromJSONTyped,
    SwKikanRawToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerKiakns
 */
export interface SwOfficerKiakns {
    /**
     * 
     * @type {Array<SwKikanRaw>}
     * @memberof SwOfficerKiakns
     */
    zaigakusei: Array<SwKikanRaw>;
    /**
     * 
     * @type {Array<SwKikanRaw>}
     * @memberof SwOfficerKiakns
     */
    sinnyusei: Array<SwKikanRaw>;
}

export function SwOfficerKiaknsFromJSON(json: any): SwOfficerKiakns {
    return SwOfficerKiaknsFromJSONTyped(json, false);
}

export function SwOfficerKiaknsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerKiakns {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zaigakusei': ((json['zaigakusei'] as Array<any>).map(SwKikanRawFromJSON)),
        'sinnyusei': ((json['sinnyusei'] as Array<any>).map(SwKikanRawFromJSON)),
    };
}

export function SwOfficerKiaknsToJSON(value?: SwOfficerKiakns | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zaigakusei': ((value.zaigakusei as Array<any>).map(SwKikanRawToJSON)),
        'sinnyusei': ((value.sinnyusei as Array<any>).map(SwKikanRawToJSON)),
    };
}


