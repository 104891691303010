import {RadioGroup} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import React, {FC} from 'react';
import {TextColorsEnum} from "common/enums_value/ColorsEnum";

interface RadioButtonGroupProps {
    value?: string;
    onChange?: () => void;
    className?: string;
    top?: string;
    helperText?: string;
    errorMessages?: Array<string>;
    vertical?: boolean
}

export const RadioButtonGroup: FC<RadioButtonGroupProps> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("xs"));
    const [value, setValue] = React.useState("");

    function handleChange(event: React.ChangeEvent<unknown>) {
        setValue((event.target as HTMLInputElement).value);
    }

    return (
        <>
            <RadioGroup
                className={`${classes.root} ${props.vertical && classes.vertical} ${props.className} ${matches ? classes.xsRoot : ""}`}
                value={value}
                onChange={handleChange}
                style={{top: props.top}}
            >
                {props.children}
            </RadioGroup>

            {
                props.errorMessages &&
                <ErrorMessages
                    className={classes.errorMessages}
                    errorMessages={props.errorMessages}
                />
            }

            {
                props.helperText &&
                <Typography className={classes.helperText}>
                    {props.helperText}
                </Typography>
            }
        </>

    )
};

RadioButtonGroup.defaultProps = {
    className: "",
    vertical: false
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        position: "relative",
        "& .MuiFormControlLabel-root": {
            marginLeft: -5,
            marginRight: 0,
            "& .MuiTypography-body1": {
                marginLeft: -5,
                position: "relative",
                top: 1,
            }
        },
        "& > *:not(:first-child)": {
            marginLeft: 8,
        },

    },
    vertical: {
        display: "block",
        "& .MuiFormControlLabel-root": {
            display: "block"
        },
        "& > *:not(:first-child)": {
            marginLeft: 0,
        },
    },
    xsRoot: {
        display: "block",
        "& .MuiFormControlLabel-root": {
            display: "block"
        },
        "& > *:not(:first-child)": {
            marginLeft: 0,
        },
    },
    errorMessages: {
        marginTop: 4,
    },
    helperText: {
        marginTop: 4,
        color: TextColorsEnum.Green,
        fontSize: 12
    }

});


