import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {getIsEnteredYosiki} from "common/functions/getIsEnteredYosiki";
import {isSameYosikiInfo} from "common/functions/yosikiInfo/isSameYosikiInfo";
import {StudentInfoStoreVo} from "common/models/yosiki/StudentInfoStoreVo";
import {YosikiBasicInfoFromVo, YosikiBasicInfoStoreVo} from "common/models/yosiki/YosikiBasicInfoStoreVo";
import {
    YosikiEditOpenModalType,
    YosikiInfoModalStoreVo,
    YosikiKakuteiModalType
} from "common/models/yosiki/YosikiInfoModalStoreVo";
import {INIT_YOSIKI_INFO_ERROR_MESSAGES, YosikiInfoStoreVo} from "common/models/yosiki/YosikiInfoStoreVo";
import {YosikiInfoResponseVo} from "common/models/YosikiInfoModels";
import {announceCommonStore, AnnounceCommonStoreVo} from "stores/common/AnnounceCommonStore";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {scrollToError} from "common/functions/scrollToError";
import {Button} from "components/atoms/Button";
import {Container} from "components/layouts/Container";
import {SideListSectionList} from "components/molecules/sideList/SideListSectionList";
import {FollowingBottomNavigation} from "components/molecules/utilities/FollowingBottomNavigation";
import {Announce} from "components/organisms/utilities/Announce";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import {YosikiEditBasicInfoModal} from "components/organisms/yosikiEdit/modal/YosikiEditBasicInfoModal";
import {YosikiKakuteiErrorModal} from "components/organisms/yosikiEdit/modal/YosikiKakuteiErrorModal";
import {YosikiKakuteiModal} from "components/organisms/yosikiEdit/modal/YosikiKakuteiModal";
import {
    WordDescriptionTypeEnum,
    YosikiWordDescriptionModal
} from "components/organisms/yosikiEdit/modal/YosikiWordDescriptionModal";
import {YosikiInfoEditComponent} from "components/organisms/yosikiEdit/YosikiInfoEditComponent";
import {SwBasicInfoErrorMessageResponse, SwYosikiInfoErrorMessageResponse} from "openapi/models";
import {accountInfoStore} from "stores/student/AccountInfoStore";
import {yosikiBasicInfoStore} from "pages/student/yosiki/stores/YosikiBasicInfoStore";
import {yosikiInfoModalStore} from "pages/student/yosiki/stores/YosikiInfoModalStore";
import {yosikiInfoStore} from "pages/student/yosiki/stores/YosikiInfoStore";
import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from "react-router";

const _YosikiEdit: React.FC<RouteComponentProps> = () => {

    const [studentInfo, updateStudentInfo] = React.useState<StudentInfoStoreVo>(accountInfoStore.currentState);
    const [yosikiInfo, onUpdateYosikiInfo] = React.useState<YosikiInfoStoreVo>(yosikiInfoStore.currentState);
    const [yosikiInfoModal, updateYosikiInfoModal] = React.useState<YosikiInfoModalStoreVo>(yosikiInfoModalStore.currentState);
    const [yosikiBasicInfo, updateYosikiBasicInfo] = React.useState<YosikiBasicInfoStoreVo>(yosikiBasicInfoStore.currentState);
    const [announceCommon, updateAnnounceCommon] = React.useState<AnnounceCommonStoreVo>(announceCommonStore.currentState);

    React.useEffect(() => {
        yosikiInfoStore.updateYosikiInfoErrorMessages(INIT_YOSIKI_INFO_ERROR_MESSAGES);
        return yosikiInfoStore.listen(onUpdateYosikiInfo);
    }, []);

    React.useEffect(() => {
        yosikiBasicInfoStore.getBasicInfo().then();
        return yosikiBasicInfoStore.listen(updateYosikiBasicInfo);
    }, []);

    React.useEffect(() => {
        accountInfoStore.getStudentInfo().then();
        return accountInfoStore.listen(updateStudentInfo);
    }, []);

    React.useEffect(() => {
        return yosikiInfoModalStore.listen(updateYosikiInfoModal);
    }, []);

    React.useEffect(() => {
        return announceCommonStore.listen(updateAnnounceCommon);
    }, []);

    useEffect(() => {
        if (getIsEnteredYosiki(studentInfo.yosikiState)) {
            // すでに様式を作成している場合様式の情報をゲットする。
            yosikiInfoStore.getYosikiInfo().then();
        } else {
            // 様式を作成していない場合基本情報の入力画面を表示する。
            yosikiInfoModalStore.updateBasicInfoModalOpenState(true)
        }
    }, [studentInfo.yosikiState]);

    const isSame = isSameYosikiInfo(yosikiInfo.yosikiInfo, yosikiInfo.originalYosikiInfo);

    async function handleClickSave() {
        await yosikiInfoStore.postYosikiInfo();
        scrollToError()
    }

    function handleClickBackButton() {
        if (!isSame) {
            yosikiInfoModalStore.updateBackButtonUnsavedAttentionModalOpenState(true)
        } else {
            window.location.assign(`${URL_PATHS_STUDENT.yosiki_detail}`);
        }
    }

    // 基本情報
    function updateYosikiBasicInfoForm(part: Partial<YosikiBasicInfoFromVo>) {
        if (yosikiBasicInfo.basicInfoForm === undefined) return;
        let newForm: YosikiBasicInfoFromVo = {
            ...yosikiBasicInfo.basicInfoForm,
            ...part
        };
        yosikiBasicInfoStore.updateYosikiBasicInfoForm(newForm)
    }

    function updateBasicInfoErrorMessage(part: Partial<SwBasicInfoErrorMessageResponse>) {
        let basicInfoErrorMessage = yosikiBasicInfo.basicInfoErrorMessage;
        const newYosikiInfoError: SwBasicInfoErrorMessageResponse = {
            ...basicInfoErrorMessage,
            ...part
        };
        yosikiBasicInfoStore.updateYosikiBasicInfoErrorMessage(newYosikiInfoError)
    }

    function handleClickEditBasicInfoButton() {
        yosikiBasicInfoStore.getBasicInfo().then(() => {
            yosikiInfoModalStore.updateBasicInfoModalOpenState(true)
        });
    }

    function updateYosikiInfo(yosikiInfo: YosikiInfoResponseVo) {
        yosikiInfoStore.updateYosikiInfo(yosikiInfo)
    }

    function updateYosikiInfoErrorMessages(yosikiInfoErrorMessage: SwYosikiInfoErrorMessageResponse) {
        yosikiInfoStore.updateYosikiInfoErrorMessages(yosikiInfoErrorMessage)
    }

    function onClickWordDescriptionLink(type: WordDescriptionTypeEnum) {
        yosikiInfoModalStore.updateYosikiEditOpenModalType(YosikiEditOpenModalType.wordDescription);
        yosikiInfoModalStore.updateWordDescriptionType(type)
    }

    function handleClickFirstComfirm() {
        yosikiBasicInfoStore.postFirstBasicInfo().then()
    }

    function handleClickComfirm() {
        yosikiBasicInfoStore.postBasicInfo().then()
    }

    function handleClickClose() {
        yosikiInfoModalStore.updateBasicInfoModalOpenState(false)
    }

    function handleClickBackToDetail() {
        window.location.assign(`${URL_PATHS_STUDENT.yosiki_detail}`);
    }

    function updateSelectZokugaraModalOpenState(value: boolean) {
        yosikiInfoModalStore.updateSelectZokugaraModalOpenState(value)
    }

    function updateDeleteZokugaraModalOpenState(value: boolean) {
        yosikiInfoModalStore.updateDeleteZokugaraModalOpenState(value)
    }

    function updateAddSyogaisyaZokugaraModalOpenState(value: boolean) {
        yosikiInfoModalStore.updateAddSyogaisyaZokugaraModalOpenState(value)
    }

    function updateAddTyokiryoyoZokugaraModalOpenState(value: boolean) {
        yosikiInfoModalStore.updateAddTyokiryoyoZokugaraModalOpenState(value)
    }

    function updateSelectedZokugaraIndex(value: number) {
        yosikiInfoModalStore.updateSelectedZokugaraIndex(value)
    }

    function handleUnsavedAttentionModalExit() {
        yosikiInfoModalStore.updateBackButtonUnsavedAttentionModalOpenState(false);
        window.location.assign(`${URL_PATHS_STUDENT.yosiki_detail}`);
    }

    function handleUnsavedAttentionModalClose() {
        yosikiInfoModalStore.updateBackButtonUnsavedAttentionModalOpenState(false)
    }

    function handleClickKakutei() {
        yosikiInfoModalStore.updateYosikiKakuteiModalType(YosikiKakuteiModalType.kakuteiModal);
    }

    // 確定モーダル
    function handleClickKakuteiModalCancel() {
        yosikiInfoModalStore.updateYosikiKakuteiModalType(undefined);
    }

    function handleClickKakuteiModalKakutei() {
        yosikiInfoStore.postYosikiInfoKakutei(false).then();
    }

    // 確定モーダル - エラー
    function handleClickKakuteiModalClose() {
        yosikiInfoModalStore.updateYosikiKakuteiModalType(undefined);
    }

    // 特別控除の表示
    const isDisplayBasicInfoTokubetuKojo: boolean =
        yosikiInfo.yosikiInfo?.basicInfo.tokubetuKojo ?
            yosikiInfo.yosikiInfo?.basicInfo.tokubetuKojo.length > 0
            : false;


    return (

        <>

            <YosikiKakuteiErrorModal
                openState={yosikiInfoModal.yosikiKakuteiOpenModalType}
                handleClickClose={handleClickKakuteiModalClose}
                errorMessage={yosikiInfoModal.yosikiInfoKakuteiModalErrorMessage}
            />

            <YosikiKakuteiModal
                openState={yosikiInfoModal.yosikiKakuteiOpenModalType}
                handleClickCancel={handleClickKakuteiModalCancel}
                handleClickKakutei={handleClickKakuteiModalKakutei}
            />

            {
                yosikiBasicInfo.basicInfoForm ?
                    <YosikiEditBasicInfoModal
                        openState={yosikiInfoModal.basicInfoModalOpenState}
                        studentInfo={studentInfo}
                        basicInfoForm={yosikiBasicInfo.basicInfoForm}
                        updateYosikiBasicInfoForm={updateYosikiBasicInfoForm}
                        basicInfoErrorMessage={yosikiBasicInfo.basicInfoErrorMessage}
                        updateBasicInfoErrorMessage={updateBasicInfoErrorMessage}
                        onClickWordDescriptionLink={onClickWordDescriptionLink}
                        handleClickFirstComfirm={handleClickFirstComfirm}
                        handleClickComfirm={handleClickComfirm}
                        handleClickClose={handleClickClose}
                        handleClickBackToDetail={handleClickBackToDetail}
                    />
                    : null
            }

            <YosikiWordDescriptionModal
                openState={yosikiInfoModal.yosikiEditOpenModalType === YosikiEditOpenModalType.wordDescription}
                descriptionType={yosikiInfoModal.wordDescriptionType}
                updateYosikiEditOpenModalType={(value?: YosikiEditOpenModalType) => yosikiInfoModalStore.updateYosikiEditOpenModalType(value)}
            />

            <Announce
                announceCommon={announceCommon}
                updateAnnounceCommon={(value) => announceCommonStore.updateAnnounceCommon(value)}
            />

            <HeaderStudent
                menuContent={
                    <SideListSectionList
                        yosikiInfoErrorMessages={yosikiInfo.yosikiInfoErrorMessages}
                        infoPolicy={yosikiInfo.yosikiInfo?.infoPolicy}
                        isDisplayBasicInfoTokubetuKojo={isDisplayBasicInfoTokubetuKojo}
                    />
                }
            />

            <Container
                maxWidth={'864px'}
                minWidth={'864px'}
            >
                <YosikiInfoEditComponent
                    yosikiInfo={yosikiInfo}
                    studentInfo={studentInfo}
                    yosikiInfoModal={yosikiInfoModal}
                    handleClickEditBasicInfoButton={handleClickEditBasicInfoButton}
                    onClickWordDescriptionLink={onClickWordDescriptionLink}
                    updateYosikiInfo={updateYosikiInfo}
                    updateYosikiInfoErrorMessages={updateYosikiInfoErrorMessages}
                    updateSelectZokugaraModalOpenState={updateSelectZokugaraModalOpenState}
                    updateDeleteZokugaraModalOpenState={updateDeleteZokugaraModalOpenState}
                    updateAddSyogaisyaZokugaraModalOpenState={updateAddSyogaisyaZokugaraModalOpenState}
                    updateAddTyokiryoyoZokugaraModalOpenState={updateAddTyokiryoyoZokugaraModalOpenState}
                    updateSelectedZokugaraIndex={updateSelectedZokugaraIndex}
                    isDisplayBasicInfoTokubetuKojo={isDisplayBasicInfoTokubetuKojo}
                    handleUnsavedAttentionModalExit={handleUnsavedAttentionModalExit}
                    handleUnsavedAttentionModalClose={handleUnsavedAttentionModalClose}
                />

            </Container>

            <FollowingBottomNavigation>

                <Button
                    className={"search_row_button"}
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.戻る)}
                    width={"104px"}
                    handleClick={handleClickBackButton}
                />

                <Button
                    className={"search_row_button"}
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.保存)}
                    width={"104px"}
                    handleClick={handleClickSave}
                />

                <Button
                    className={"search_row_button"}
                    type={"secondary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                    width={"120px"}
                    disabled={!isSame}
                    handleClick={() => handleClickKakutei()}
                />

            </FollowingBottomNavigation>

        </>

    );
};

export const YosikiEdit = withRouter(_YosikiEdit);

