/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20015,
    InlineResponse20015FromJSON,
    InlineResponse20015ToJSON,
    SwSyoruiConfirmationFormRequest,
    SwSyoruiConfirmationFormRequestFromJSON,
    SwSyoruiConfirmationFormRequestToJSON,
    SwSyoruiConfirmationFormResponse,
    SwSyoruiConfirmationFormResponseFromJSON,
    SwSyoruiConfirmationFormResponseToJSON,
} from '../models';

export interface GetOfficerSyoruiConfirmationFormRequest {
    studentNumber: string;
}

export interface PostOfficerSyoruiConfirmationFormRequest {
    studentNumber: string;
    swSyoruiConfirmationFormRequest: SwSyoruiConfirmationFormRequest;
}

/**
 * no description
 */
export class OfficerSyoruiConfirmationFormApi extends runtime.BaseAPI {

    /**
     * 必要書類の確認機能のフォームデータ
     */
    async getOfficerSyoruiConfirmationFormRaw(requestParameters: GetOfficerSyoruiConfirmationFormRequest): Promise<runtime.ApiResponse<SwSyoruiConfirmationFormResponse>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling getOfficerSyoruiConfirmationForm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/syorui_confirmation_form/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwSyoruiConfirmationFormResponseFromJSON(jsonValue));
    }

    /**
     * 必要書類の確認機能のフォームデータ
     */
    async getOfficerSyoruiConfirmationForm(requestParameters: GetOfficerSyoruiConfirmationFormRequest): Promise<SwSyoruiConfirmationFormResponse> {
        const response = await this.getOfficerSyoruiConfirmationFormRaw(requestParameters);
        return await response.value();
    }

    /**
     * 必要書類の確認機能のフォームデータの保存 
     */
    async postOfficerSyoruiConfirmationFormRaw(requestParameters: PostOfficerSyoruiConfirmationFormRequest): Promise<runtime.ApiResponse<InlineResponse20015>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling postOfficerSyoruiConfirmationForm.');
        }

        if (requestParameters.swSyoruiConfirmationFormRequest === null || requestParameters.swSyoruiConfirmationFormRequest === undefined) {
            throw new runtime.RequiredError('swSyoruiConfirmationFormRequest','Required parameter requestParameters.swSyoruiConfirmationFormRequest was null or undefined when calling postOfficerSyoruiConfirmationForm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/officer/syorui_confirmation_form/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwSyoruiConfirmationFormRequestToJSON(requestParameters.swSyoruiConfirmationFormRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20015FromJSON(jsonValue));
    }

    /**
     * 必要書類の確認機能のフォームデータの保存 
     */
    async postOfficerSyoruiConfirmationForm(requestParameters: PostOfficerSyoruiConfirmationFormRequest): Promise<InlineResponse20015> {
        const response = await this.postOfficerSyoruiConfirmationFormRaw(requestParameters);
        return await response.value();
    }

}
