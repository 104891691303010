export function strArrayToStrWithSlash(...strArray: Array<string>): string {
    let strWithSlash: string = "";
    strArray.forEach((s: string, index: number) => {
        if (s === "") return;
        if (index === 0) {
            strWithSlash = strWithSlash + s;
        } else {
            // eslint-disable-next-line no-useless-concat
            strWithSlash = strWithSlash + " " + "/" + " " + s
        }
    });
    return strWithSlash
}