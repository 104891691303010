import makeStyles from "@material-ui/core/styles/makeStyles";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {BekkyoVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {
    TokubetuKojoLabelTextEnum,
    TokubetuKojoLabelTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/TokubetuKojoLabelTextEnum";
import {
    TitleNameTextEnum,
    TitleNameTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/TitleNameTextEnum";
import {NumberField} from "components/atoms/forms/NumberField";
import {TextField} from "components/atoms/forms/TextField";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {AnnotationText} from "components/atoms/AnnotationText";
import {Title05} from "components/atoms/titles/Title05";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {SwBekkyoErrorMessage} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface TokubetuKojoBekkyoProps {
    bekkyo: BekkyoVo
    handleBlurBekkyo: (part: Partial<BekkyoVo>) => void
    clearBekkyoErrorMessage: (part: Partial<SwBekkyoErrorMessage>) => void
    bekkyoErrorMessage: SwBekkyoErrorMessage
}

export const TokubetuKojoBekkyo: FC<TokubetuKojoBekkyoProps> = (props) => {

    const classes = useStyles();

    function handleBlurBekkyoRiyu(value: string) {
        props.handleBlurBekkyo({riyu: value});
        props.clearBekkyoErrorMessage({riyu: undefined})
    }

    function handleBlurBekkyoAmountPerMonth(value: string) {
        props.handleBlurBekkyo({amountPerMonth: value});
        props.clearBekkyoErrorMessage({amountPerMonth: undefined})
    }

    const bekkyo: BekkyoVo = props.bekkyo;
    const bekkyoErrorMessage = props.bekkyoErrorMessage;

    return (

        <>
            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.主たる家計支持者別居の世帯)}
                className={classes.title}
            />

            <FormTable
                className={"section_table"}
            >
                <FormTableRow
                    label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.主たる家計支持者別居の世帯)}
                >
                    <AnnotationText
                        type={"warning"}
                        text={CommonTextEnumToNameJaAndEn(CommonTextEnum.わからない場合は空欄にしてください)}
                    />
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.理由)}<RequiredTag type={RequiredTagTypeEnum.任意}/>
                        </div>
                        <TextField
                            value={bekkyo.riyu}
                            handleBlur={(value) => handleBlurBekkyoRiyu(value)}
                            errorMessages={convertErrorMessageVoToArrayStr(bekkyoErrorMessage.riyu)}
                            isError={convertErrorMessageVoToArrayStr(bekkyoErrorMessage.riyu).length !== 0}
                        />
                    </div>
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.カ月当たり住居光熱費等)}<RequiredTag type={RequiredTagTypeEnum.任意}/>
                        </div>
                        <NumberField
                            value={bekkyo.amountPerMonth}
                            handleBlur={(value) => handleBlurBekkyoAmountPerMonth(value)}
                            errorMessages={convertErrorMessageVoToArrayStr(bekkyoErrorMessage.amountPerMonth)}
                            isError={convertErrorMessageVoToArrayStr(bekkyoErrorMessage.amountPerMonth).length !== 0}
                            suffixText={"円"}
                            widthInput={"80px"}
                        />
                    </div>
                </FormTableRow>

            </FormTable>
        </>

    )
};

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        }
    }
);

