import {handleOfficerError} from "common/functions/error_handler/handleOfficerError";
import {OfficerKikansStoreVo, OfficerKikansStudentIdKikansVo} from "common/models/KikansModels";
import {StudentKikansApi} from "openapi/apis";
import {SwOfficerKikansStudentId} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";

class StudentKikansStore extends Store<OfficerKikansStoreVo> {

    private api = new StudentKikansApi(apiConfiguration);

    constructor() {
        super({})
    }

    updateKikansStudentId(kikans: OfficerKikansStudentIdKikansVo | undefined) {
        this.dispatch(() => ({kikans: kikans}));
    }

    async getKikansStudentId() {
        try {
            let res: SwOfficerKikansStudentId = await this.api.getStudentKikans();
            this.updateKikansStudentId(res.kikans);
        } catch (res) {
            handleOfficerError(res)
        }
    }

}

export const studentKikansStore = new StudentKikansStore();
