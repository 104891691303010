/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SwSyoruiConfirmationResponse,
    SwSyoruiConfirmationResponseFromJSON,
    SwSyoruiConfirmationResponseToJSON,
} from '../models';

/**
 * no description
 */
export class SyoruiConfirmationApi extends runtime.BaseAPI {

    /**
     * 必要書類の確認一覧
     */
    async getSyoruiConfirmationRaw(): Promise<runtime.ApiResponse<SwSyoruiConfirmationResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/syorui_confirmation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwSyoruiConfirmationResponseFromJSON(jsonValue));
    }

    /**
     * 必要書類の確認一覧
     */
    async getSyoruiConfirmation(): Promise<SwSyoruiConfirmationResponse> {
        const response = await this.getSyoruiConfirmationRaw();
        return await response.value();
    }

}
