import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {jugyoryoEnumToName} from "common/enums/JugyoryoEnum";
import {nyugakuryoEnumToName} from "common/enums/NyugakuryoEnum";
import {TokubetuKojoEnum, tokubetuKojoEnumToName} from "common/enums/TokubetuKojoEnum";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {BasicInfoVo} from "common/models/YosikiInfoModels";
import {
    BasicInfoLabelTextEnum,
    BasicInfoLabelTextEnumToNameJaAndEn
} from "common/enums_text/yosiki/BasicInfoLabelTextEnum";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {Title04} from "components/atoms/titles/Title04";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {FC} from "react";

interface BasicInfoSectionProps {
    className?: string
    basicInfo: BasicInfoVo
    isDisplayBasicInfoTokubetuKojo?: boolean
}

export const BasicInfoDetailSection: FC<BasicInfoSectionProps> = (props) => {

    const classes = useStyles();

    const basicInfo: BasicInfoVo = props.basicInfo;

    return (
        <div id={SECTION_ID_NAME.basicInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.様式1基本情報)}
                className={"section_title"}
            />

            <FormTable
                className={"section_table"}
            >

                <FormTableRow
                    label={BasicInfoLabelTextEnumToNameJaAndEn(BasicInfoLabelTextEnum.希望する申請種別)}
                >
                    <div>{convertEnumNameToStr(nyugakuryoEnumToName(basicInfo.sinseiType.nyugakuryo))}</div>
                    <div>{convertEnumNameToStr(jugyoryoEnumToName(basicInfo.sinseiType.jugyoryo))}</div>
                </FormTableRow>

                {
                    props.isDisplayBasicInfoTokubetuKojo ?
                        <FormTableRow
                            label={BasicInfoLabelTextEnumToNameJaAndEn(BasicInfoLabelTextEnum.特別控除)}
                        >
                            {
                                basicInfo.tokubetuKojo.map((t: TokubetuKojoEnum, i: number) => {
                                    return <div key={i}>{convertEnumNameToStr(tokubetuKojoEnumToName(t))}</div>
                                })
                            }
                        </FormTableRow>
                        : null
                }

            </FormTable>

        </div>

    )
};

const useStyles = makeStyles({
        root: {},
    }
);

