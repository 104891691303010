import React from 'react';

export const ModalContentKyuyosyotokuIgaiNoSyotokuKingakuRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                営業、農林水産業、不動産、自由業、 利子・配当、親族等からの援助、内職、保険等による所得をそれぞれ該当する欄に記入すること。<br/>
                記入する金額は、<span className={"underLine"}>確定申告書(控)</span>の“所得金額”(=“収入金額”−“必要経費”) 等とすること。 <span
                className={"fw700"}>→ 下の例2を参照</span>
                <div className={"mt8"}>
                    <img src={`${process.env.PUBLIC_URL}/images/yosikiEdit/wordDescription/img_kyuyoTinginRan_02.png`}
                         alt=""/>
                </div>
            </div>

            <div className={"modalContent_item"}>
                Enter income from such sources as sales; agriculture, forestry and fisheries; real estate; freelance
                work; interest and dividends; support from family, relatives, etc.; working at home; insurance, etc. in
                the respective fields.<br/>
                The amount to be entered should be the “Amount of income” (= “Amount of revenue” − “Necessary
                expenses”) from the <span className={"underLine"}>Tax Return (Taxpayer’s Copy).</span>
                <span className={"fw700"}>→ Refer to Example (2) at bottom</span>
                <div className={"mt8"}>
                    <img src={`${process.env.PUBLIC_URL}/images/yosikiEdit/wordDescription/img_kyuyoTinginRan_02_en.png`}
                         alt=""/>
                </div>
            </div>

        </div>

    );
};
