/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwFamilyInfoErrorMessageHonnin,
    SwFamilyInfoErrorMessageHonninFromJSON,
    SwFamilyInfoErrorMessageHonninFromJSONTyped,
    SwFamilyInfoErrorMessageHonninToJSON,
    SwMemberErrorMessages,
    SwMemberErrorMessagesFromJSON,
    SwMemberErrorMessagesFromJSONTyped,
    SwMemberErrorMessagesToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwFamilyInfoErrorMessage
 */
export interface SwFamilyInfoErrorMessage {
    /**
     * 申請者本人が独立生計者かつ「父」、「母」、「祖母」、「祖父」が選択されている場合にエラーを返す。 
     * @type {Array<SwErrorMessage>}
     * @memberof SwFamilyInfoErrorMessage
     */
    dokuritu?: Array<SwErrorMessage>;
    /**
     * 申請者本人が母子・父子世帯かつ「父」、「母」の両方が選択されている場合にエラーを返す。 
     * @type {Array<SwErrorMessage>}
     * @memberof SwFamilyInfoErrorMessage
     */
    bosiHusi?: Array<SwErrorMessage>;
    /**
     * 
     * @type {SwFamilyInfoErrorMessageHonnin}
     * @memberof SwFamilyInfoErrorMessage
     */
    honnin: SwFamilyInfoErrorMessageHonnin;
    /**
     * 
     * @type {Array<SwMemberErrorMessages>}
     * @memberof SwFamilyInfoErrorMessage
     */
    members: Array<SwMemberErrorMessages>;
}

export function SwFamilyInfoErrorMessageFromJSON(json: any): SwFamilyInfoErrorMessage {
    return SwFamilyInfoErrorMessageFromJSONTyped(json, false);
}

export function SwFamilyInfoErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwFamilyInfoErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dokuritu': !exists(json, 'dokuritu') ? undefined : ((json['dokuritu'] as Array<any>).map(SwErrorMessageFromJSON)),
        'bosiHusi': !exists(json, 'bosiHusi') ? undefined : ((json['bosiHusi'] as Array<any>).map(SwErrorMessageFromJSON)),
        'honnin': SwFamilyInfoErrorMessageHonninFromJSON(json['honnin']),
        'members': ((json['members'] as Array<any>).map(SwMemberErrorMessagesFromJSON)),
    };
}

export function SwFamilyInfoErrorMessageToJSON(value?: SwFamilyInfoErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dokuritu': value.dokuritu === undefined ? undefined : ((value.dokuritu as Array<any>).map(SwErrorMessageToJSON)),
        'bosiHusi': value.bosiHusi === undefined ? undefined : ((value.bosiHusi as Array<any>).map(SwErrorMessageToJSON)),
        'honnin': SwFamilyInfoErrorMessageHonninToJSON(value.honnin),
        'members': ((value.members as Array<any>).map(SwMemberErrorMessagesToJSON)),
    };
}


