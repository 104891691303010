import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {CountYosikiErrorMessageVo, countYosiErrorMessage} from "common/functions/yosikiInfo/countYosiErrorMessage";
import {InfoPolicyVo} from "common/models/YosikiInfoModels";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {SwYosikiInfoErrorMessageResponse} from "openapi/models";
import React, {FC} from 'react';
import {BorderColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";

interface SideListSectionListProps {
    className?: string;
    yosikiInfoErrorMessages: SwYosikiInfoErrorMessageResponse
    sinseisyaErrorCount?: number
    seisekiInfoErrorCount?: number
    syogakukinErrorCount?: number
    familyErrorCount?: number
    rentaiHosyoninErrorCount?: number
    nyugakuryoErrorCount?: number
    jugyoryoErrorCount?: number
    tokubetuKojoErrorCount?: number
    infoPolicy?: InfoPolicyVo
    isDisplayBasicInfoTokubetuKojo: boolean
}

interface SideListSectionListCountProps {
    errorCount?: number
}

export const SideListSectionListCount: FC<SideListSectionListCountProps> = (props) => {
    if (props.errorCount === 0 || props.errorCount === undefined) return <></>;
    return <span className={"sectionList_item_count"}>{props.errorCount}</span>
};

export const _SideListSectionList: FC<SideListSectionListProps> = (props) => {

    const classes = useStyles();

    const errorCounts: CountYosikiErrorMessageVo = countYosiErrorMessage(props.yosikiInfoErrorMessages);

    function scrollToSectionId(sectionIdName: string) {
        const target = document.getElementById(sectionIdName);
        if (target === null) return;
        const offset = window.pageYOffset; // 縦方向のページのスクロール量
        const buffer = 60;
        const top = offset + target.getBoundingClientRect().top - buffer; // ブラウザの表示領域の左上を(0, 0)として、そこからの相対位置
        window.scrollTo({
            top: top,
            behavior: "smooth"
        });
    }

    return (

        <div className={classes.sectionList}>
            <div className={"sectionList_item"} onClick={() => scrollToSectionId(SECTION_ID_NAME.basicInfo)}>
                {SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.様式1基本情報)}
            </div>
            <div className={"sectionList_item"} onClick={() => scrollToSectionId(SECTION_ID_NAME.sinseisyaInfo)}>
                {SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.申請者情報)}
                <SideListSectionListCount errorCount={errorCounts.sinseisyaErrorCount}/>
            </div>
            <div className={"sectionList_item"} onClick={() => scrollToSectionId(SECTION_ID_NAME.seisekiInfo)}>
                {SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.学業成績)}
                <SideListSectionListCount errorCount={errorCounts.seisekiInfoErrorCount}/>
            </div>
            <div className={"sectionList_item"} onClick={() => scrollToSectionId(SECTION_ID_NAME.syogakukinInfo)}>
                {SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.奨学金情報)}
                <SideListSectionListCount errorCount={errorCounts.syogakukinErrorCount}/>
            </div>
            <div className={"sectionList_item"} onClick={() => scrollToSectionId(SECTION_ID_NAME.familyInfo)}>
                {SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.家庭状況)}
                <SideListSectionListCount errorCount={errorCounts.familyErrorCount}/>
            </div>
            {
                props.infoPolicy?.requireNyugakuryoInfo &&
                <div className={"sectionList_item"} onClick={() => scrollToSectionId(SECTION_ID_NAME.nyugakuryoInfo)}>
                    {SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.入学料免除徴収猶予申請理由)}
                    <SideListSectionListCount errorCount={errorCounts.nyugakuryoErrorCount}/>
                </div>
            }
            {
                props.infoPolicy?.requireJugyoryoInfo &&
                <div className={"sectionList_item"} onClick={() => scrollToSectionId(SECTION_ID_NAME.jugyoryoInfo)}>
                    {SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.授業料免除徴収猶予申請理由)}
                    <SideListSectionListCount errorCount={errorCounts.jugyoryoErrorCount}/>
                </div>
            }
            {
                props.isDisplayBasicInfoTokubetuKojo &&
                <div className={"sectionList_item"} onClick={() => scrollToSectionId(SECTION_ID_NAME.tokubetuKojoInfo)}>
                    {SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.特別控除)}
                    <SideListSectionListCount errorCount={errorCounts.tokubetuKojoErrorCount}/>
                </div>
            }
        </div>
    )

};

export const SideListSectionList = React.memo<SideListSectionListProps>(_SideListSectionList, (prev, next) => {
    return (
        prev.className === next.className
        && prev.sinseisyaErrorCount === next.sinseisyaErrorCount
        && prev.seisekiInfoErrorCount === next.seisekiInfoErrorCount
        && prev.syogakukinErrorCount === next.syogakukinErrorCount
        && prev.familyErrorCount === next.familyErrorCount
        && prev.rentaiHosyoninErrorCount === next.rentaiHosyoninErrorCount
        && prev.nyugakuryoErrorCount === next.nyugakuryoErrorCount
        && prev.jugyoryoErrorCount === next.jugyoryoErrorCount
        && prev.tokubetuKojoErrorCount === next.tokubetuKojoErrorCount
        && prev.infoPolicy === next.infoPolicy
    )
});

const useStyles = makeStyles({
    sectionList: {
        borderTop: `1px solid ${BorderColorsEnum.LightGray}`,
        "& .sectionList_item": {
            display: "flex",
            padding: "8px 12px",
            borderBottom: `1px solid ${BorderColorsEnum.LightGray}`,
            "& .sectionList_item_count": {
                marginLeft: "auto",
                fontWeight: 700,
                color: TextColorsEnum.Red,
            }
        }
    },
});
