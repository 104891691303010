import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum BasicInfoLabelTextEnum {
    希望する申請種別 = "希望する申請種別",
    国籍永住者配偶者定住者 = "国籍・永住者・配偶者・定住者",
    A様式1提出状況 = "A様式1提出状況",
    日本学生支援機構 = "日本学生支援機構",
    編入学又は転学 = "編入学又は転学",
    特別控除 = "特別控除",
}

export function BasicInfoLabelTextEnumToNameJaAndEn(e: BasicInfoLabelTextEnum): string {
    switch (e) {
        case BasicInfoLabelTextEnum.希望する申請種別:
            return convertEnumNameToStr({ja: "希望する申請種別", en: "Desired application type"});
        case BasicInfoLabelTextEnum.国籍永住者配偶者定住者:
            return convertEnumNameToStr({
                ja: "国籍・永住者・配偶者・定住者",
                en: "Nationality, permanent resident, spouse, permanent resident"
            });
        case BasicInfoLabelTextEnum.A様式1提出状況:
            return convertEnumNameToStr({ja: "A様式1提出状況", en: "A style 1 submission situation"});
        case BasicInfoLabelTextEnum.日本学生支援機構:
            return convertEnumNameToStr({ja: "日本学生支援機構", en: "Japan Student Services Organization"});
        case BasicInfoLabelTextEnum.編入学又は転学:
            return convertEnumNameToStr({ja: "編入学又は転学", en: "Mid-course Entry Admission or Transfer"});
        case BasicInfoLabelTextEnum.特別控除:
            return convertEnumNameToStr({ja: "特別控除", en: "Special Deductions"});
    }
}