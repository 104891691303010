import {AuthTypeEnum} from "common/enums/AuthTypeEnum";
import {handleCommonError} from "common/functions/error_handler/handleCommonError";
import {CommonSyoruisApi} from "openapi/apis";
import {SwCommonSyoruisResponse} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";

export interface CommonSyoruiStoreVo {
    syoruis: Array<CommonSyoruisSyoruiVo>;
    syoruiModalState: boolean
}

export interface CommonSyoruisSyoruiVo {
    nameJa: string;
    nameEn: string;
    urlJa: string;
    urlEn: string;
}

class CommonSyoruiStore extends Store<CommonSyoruiStoreVo> {

    private api = new CommonSyoruisApi(apiConfiguration);

    constructor() {
        super({
            syoruis: [],
            // osiraseDetail: undefined,
            syoruiModalState: false
        })
    }

    updateSyoruis(syoruis: Array<CommonSyoruisSyoruiVo>) {
        this.dispatch(() => ({syoruis: syoruis}));
    }

    updateSyoruiModalState(value: boolean) {
        this.dispatch(() => ({syoruiModalState: value}));
    }

    async getSyoruis(authType: AuthTypeEnum) {
        try {
            let res: SwCommonSyoruisResponse = await this.api.getCommonSyoruis();
            this.updateSyoruis(res.syoruis);
            this.updateSyoruiModalState(true);
        } catch (res) {
            handleCommonError(res, authType)
        }
    }

}


export const commonSyoruiStore = new CommonSyoruiStore();