/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SwYosikiMasters,
    SwYosikiMastersFromJSON,
    SwYosikiMastersToJSON,
} from '../models';

/**
 * no description
 */
export class CommonYosikiMastersApi extends runtime.BaseAPI {

    /**
     * 様式で使われるマスターデータ群
     */
    async getCommonYosikiMastersRaw(): Promise<runtime.ApiResponse<SwYosikiMasters>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/common/yosiki_masters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwYosikiMastersFromJSON(jsonValue));
    }

    /**
     * 様式で使われるマスターデータ群
     */
    async getCommonYosikiMasters(): Promise<SwYosikiMasters> {
        const response = await this.getCommonYosikiMastersRaw();
        return await response.value();
    }

}
