/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SwStudentInfo,
    SwStudentInfoFromJSON,
    SwStudentInfoToJSON,
} from '../models';

export interface GetOfficerStudentInfoRequest {
    studentNumber: string;
}

/**
 * no description
 */
export class OfficerStudentInfoApi extends runtime.BaseAPI {

    /**
     * 学生の情報を取得
     */
    async getOfficerStudentInfoRaw(requestParameters: GetOfficerStudentInfoRequest): Promise<runtime.ApiResponse<SwStudentInfo>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling getOfficerStudentInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/student_info/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwStudentInfoFromJSON(jsonValue));
    }

    /**
     * 学生の情報を取得
     */
    async getOfficerStudentInfo(requestParameters: GetOfficerStudentInfoRequest): Promise<SwStudentInfo> {
        const response = await this.getOfficerStudentInfoRaw(requestParameters);
        return await response.value();
    }

}
