/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2005,
    InlineResponse2005FromJSON,
    InlineResponse2005ToJSON,
    SwBasicInfoRequest,
    SwBasicInfoRequestFromJSON,
    SwBasicInfoRequestToJSON,
    SwBasicInfoResponse,
    SwBasicInfoResponseFromJSON,
    SwBasicInfoResponseToJSON,
} from '../models';

export interface PostStudentBasicInfoRequest {
    swBasicInfoRequest: SwBasicInfoRequest;
}

/**
 * no description
 */
export class StudentBasicInfoApi extends runtime.BaseAPI {

    /**
     * 様式1の基本情報の取得
     */
    async getStudentBasicInfoRaw(): Promise<runtime.ApiResponse<SwBasicInfoResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/basic_info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwBasicInfoResponseFromJSON(jsonValue));
    }

    /**
     * 様式1の基本情報の取得
     */
    async getStudentBasicInfo(): Promise<SwBasicInfoResponse> {
        const response = await this.getStudentBasicInfoRaw();
        return await response.value();
    }

    /**
     * 様式1の基本情報の更新もしくは挿入。 様式の状態が「未入力」または「未確定」の場合のみ可能。 保存の際にチェックをかけ、エラーがある場合は保存せずエラーの内容を返す。 エラーがない場合は基本情報を保存し、様式の状態が「未入力」の場合は「未確定」に更新する。 
     */
    async postStudentBasicInfoRaw(requestParameters: PostStudentBasicInfoRequest): Promise<runtime.ApiResponse<InlineResponse2005>> {
        if (requestParameters.swBasicInfoRequest === null || requestParameters.swBasicInfoRequest === undefined) {
            throw new runtime.RequiredError('swBasicInfoRequest','Required parameter requestParameters.swBasicInfoRequest was null or undefined when calling postStudentBasicInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/student/basic_info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwBasicInfoRequestToJSON(requestParameters.swBasicInfoRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2005FromJSON(jsonValue));
    }

    /**
     * 様式1の基本情報の更新もしくは挿入。 様式の状態が「未入力」または「未確定」の場合のみ可能。 保存の際にチェックをかけ、エラーがある場合は保存せずエラーの内容を返す。 エラーがない場合は基本情報を保存し、様式の状態が「未入力」の場合は「未確定」に更新する。 
     */
    async postStudentBasicInfo(requestParameters: PostStudentBasicInfoRequest): Promise<InlineResponse2005> {
        const response = await this.postStudentBasicInfoRaw(requestParameters);
        return await response.value();
    }

}
