import {URL_PATHS_STUDENT} from "common/constant/URL_PATHS_STUDENT";
import {handleStudentError} from "common/functions/error_handler/handleStudentError";
import {PostStudentSendResetPasswordLinkRequest, StudentSendResetPasswordLinkApi} from "openapi/apis";
import {SwStudentSendResetPasswordLinkPostErrorMessage} from "openapi/models";
import {Store} from "stores/Store";
import {apiConfiguration} from "utilities/apiConfiguration";

export interface SendResetPasswordLinkStoreVo {
    form: SendResetPasswordLinkFormVo
    errorMessages?: SwStudentSendResetPasswordLinkPostErrorMessage
}

export interface SendResetPasswordLinkFormVo {
    studentNumber: string
}

class SendResetPasswordLinkStore extends Store<SendResetPasswordLinkStoreVo> {

    private api = new StudentSendResetPasswordLinkApi(apiConfiguration);

    constructor() {
        super({
            form: {
                studentNumber: "",
            },
        })
    }

    updateForm(part: Partial<SendResetPasswordLinkFormVo>) {
        const newForm = {
            ...this.currentState.form,
            ...part
        };
        this.dispatch(() => ({form: newForm}));
    }

    updateErrorMessages(errorMessages: Partial<SwStudentSendResetPasswordLinkPostErrorMessage> | undefined) {
        const newError: SwStudentSendResetPasswordLinkPostErrorMessage | undefined = {
            ...this.currentState.errorMessages,
            ...errorMessages
        };
        this.dispatch(() => ({errorMessages: newError}));
    }

    async postSendResetPasswordLink() {
        if (this.currentState.form.studentNumber === "") {
            this.updateErrorMessages({studentNumber: [{ja: "学籍番号が入力されていません。", en: "Student number has not been entered."}]})
            return;
        }
        const param: PostStudentSendResetPasswordLinkRequest = {
            studentNumber: this.currentState.form.studentNumber,
        };
        try {
            const res = await this.api.postStudentSendResetPasswordLink(param);
            if (res.isSuccess) {
                window.location.assign(URL_PATHS_STUDENT.send_reset_password_link_complete);
            } else {
                this.updateErrorMessages(res.error)
            }
        } catch (res) {
            handleStudentError(res)
        }
    }

}

export const sendResetPasswordLinkStore = new SendResetPasswordLinkStore();
