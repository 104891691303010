import makeStyles from "@material-ui/core/styles/makeStyles";
import {BeturiTypeEnum, beturiTypeEnumToName} from "common/enums/BeturiTypeEnum";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {joinArraySwErrorMessage} from "common/functions/converter/joinArraySwErrorMessage";
import {ErrorMessageVo} from "common/models/ErrorMessageVo";
import {BosiHusiParentVo, BosiHusiVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {TitleNameTextEnum, TitleNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TitleNameTextEnum";
import {TokubetuKojoLabelTextEnum, TokubetuKojoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/TokubetuKojoLabelTextEnum";
import {Checkbox} from "components/atoms/forms/Checkbox";
import {RadioButton} from "components/atoms/forms/RadioButton";
import {TextField} from "components/atoms/forms/TextField";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {Title05} from "components/atoms/titles/Title05";
import {CheckboxGroup} from "components/molecules/forms/CheckboxGroup";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {RadioButtonGroup} from "components/molecules/forms/RadioButtonGroup";
import {YearMonth} from "components/molecules/forms/YearMonth";
import {SwBosiHusiErrorMessage} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface TokubetuKojoBoshiHusiProps {
    bosiHusi: BosiHusiVo
    bosiHusiErrorMessage: SwBosiHusiErrorMessage
    handleChangeBosiHusi: (part: Partial<BosiHusiVo>) => void
    clearBosiHusiErrorMessage: (part: Partial<SwBosiHusiErrorMessage>) => void
}

export const TokubetuKojoBoshiHusi: FC<TokubetuKojoBoshiHusiProps> = (props) => {

    const classes = useStyles();

    const bosiHusi: BosiHusiVo = props.bosiHusi;
    const bosiHusiErrorMessage: SwBosiHusiErrorMessage = props.bosiHusiErrorMessage;

    function createBlankParent(): BosiHusiParentVo {
        return {
            beturiType: undefined,
            jikiYaer: "",
            jikiMonth: "",
            riyu: "",
        }
    }

    function handleChangeBosiHusiMotherFlag(value: boolean) {
        props.handleChangeBosiHusi({mother: value ? createBlankParent() : undefined});
        props.clearBosiHusiErrorMessage({base: undefined})
    }

    function handleChangeBosiHusiFatherFlag(value: boolean) {
        props.handleChangeBosiHusi({father: value ? createBlankParent() : undefined});
        props.clearBosiHusiErrorMessage({base: undefined})
    }

    function handleChangeMother(part: Partial<BosiHusiParentVo>) {
        let mother: BosiHusiParentVo | undefined =
            bosiHusi.mother ? {...bosiHusi.mother, ...part} : undefined;
        props.handleChangeBosiHusi({
            mother: mother
        });
    }

    function handleChangeFather(part: Partial<BosiHusiParentVo>) {
        let father: BosiHusiParentVo | undefined =
            bosiHusi.father ? {...bosiHusi.father, ...part} : undefined;
        props.handleChangeBosiHusi({
            father: father
        });
    }

    function handleChangeMotherBeturiType(value: string) {
        handleChangeMother({beturiType: value as BeturiTypeEnum});
        props.clearBosiHusiErrorMessage({
            mother: {
                ...bosiHusiErrorMessage.mother,
                beturiType: undefined
            }
        })
    }

    function handleBlurMotherJikiYear(value: string) {
        handleChangeMother({jikiYaer: value});
        props.clearBosiHusiErrorMessage({
            mother: {
                ...bosiHusiErrorMessage.mother,
                jikiYaer: undefined,
                jikiMonth: undefined
            }
        })
    }

    function handleBlurJikiMotherMonth(value: string) {
        handleChangeMother({jikiMonth: value});
        props.clearBosiHusiErrorMessage({
            mother: {
                ...bosiHusiErrorMessage.mother,
                jikiYaer: undefined,
                jikiMonth: undefined
            }
        })
    }

    function handleBlurMotherRiyu(value: string) {
        handleChangeMother({riyu: value});
        props.clearBosiHusiErrorMessage({
            mother: {
                ...bosiHusiErrorMessage.mother,
                riyu: undefined
            }
        })
    }

    function handleChangeFatherBeturiType(value: string) {
        handleChangeFather({beturiType: value as BeturiTypeEnum});
        props.clearBosiHusiErrorMessage({
            father: {
                ...bosiHusiErrorMessage.father,
                beturiType: undefined
            }
        })
    }

    function handleBlurFatherJikiYear(value: string) {
        handleChangeFather({jikiYaer: value});
        props.clearBosiHusiErrorMessage({
            father: {
                ...bosiHusiErrorMessage.father,
                jikiYaer: undefined, jikiMonth: undefined
            }
        })
    }

    function handleBlurJikiFatherMonth(value: string) {
        handleChangeFather({jikiMonth: value});
        props.clearBosiHusiErrorMessage({
            father: {
                ...bosiHusiErrorMessage.father,
                jikiYaer: undefined, jikiMonth: undefined
            }
        })
    }

    function handleBlurFatherRiyu(value: string) {
        handleChangeFather({riyu: value});
        props.clearBosiHusiErrorMessage({
            father: {
                ...bosiHusiErrorMessage.father,
                riyu: undefined
            }
        })
    }

    let motherJikiError: boolean = false;
    if (bosiHusiErrorMessage.mother !== undefined) {
        motherJikiError = bosiHusiErrorMessage.mother.jikiYaer !== undefined ||
            bosiHusiErrorMessage.mother.jikiMonth !== undefined;
    }

    let fatherJikiError: boolean = false;
    if (bosiHusiErrorMessage.father !== undefined) {
        fatherJikiError = bosiHusiErrorMessage.father.jikiYaer !== undefined ||
            bosiHusiErrorMessage.father.jikiMonth !== undefined;
    }

    let motherJikiErrorMessages: Array<ErrorMessageVo> = [];
    if (motherJikiError) {
        motherJikiErrorMessages = joinArraySwErrorMessage([
            bosiHusiErrorMessage.mother?.jikiYaer,
            bosiHusiErrorMessage.mother?.jikiMonth,
        ]);
    }

    let fatherJikiErrorMessages: Array<ErrorMessageVo> = [];
    if (fatherJikiError) {
        fatherJikiErrorMessages = joinArraySwErrorMessage([
            bosiHusiErrorMessage.father?.jikiYaer,
            bosiHusiErrorMessage.father?.jikiMonth,
        ]);
    }

    return (

        <>
            <Title05
                title={TitleNameTextEnumToNameJaAndEn(TitleNameTextEnum.母子父子世帯)}
                className={classes.title}
            />

            <FormTable
                className={"section_table"}
            >
                <FormTableRow
                    label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.母子父子世帯)}
                >
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}<RequiredTag
                            type={RequiredTagTypeEnum.必須}/>
                        </div>
                        <CheckboxGroup
                            errorMessages={convertErrorMessageVoToArrayStr(bosiHusiErrorMessage.base)}
                        >
                            <Checkbox
                                checked={!!bosiHusi.mother}
                                label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.母無)}
                                value={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.母無)}
                                handleChange={() => handleChangeBosiHusiMotherFlag(!bosiHusi.mother)}
                                isError={isErrorMessage(bosiHusiErrorMessage.base)}
                            />
                            <Checkbox
                                checked={!!bosiHusi.father}
                                label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.父無)}
                                value={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.父無)}
                                handleChange={() => handleChangeBosiHusiFatherFlag(!bosiHusi.father)}
                                isError={isErrorMessage(bosiHusiErrorMessage.base)}
                            />
                        </CheckboxGroup>
                    </div>
                </FormTableRow>

                {
                    bosiHusi.mother ?
                        <FormTableRow
                            label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.母無)}
                        >
                            <div className={"formTableRow_item"}>

                                <div className={"formTableRow_item_label"}>
                                    {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}<RequiredTag
                                    type={RequiredTagTypeEnum.必須}/>
                                </div>
                                <RadioButtonGroup
                                    errorMessages={convertErrorMessageVoToArrayStr(bosiHusiErrorMessage.mother?.beturiType)}
                                    vertical={true}
                                >
                                    <RadioButton
                                        value={""}
                                        checked={bosiHusi.mother?.beturiType === BeturiTypeEnum.死別}
                                        label={convertEnumNameToStr(beturiTypeEnumToName(BeturiTypeEnum.死別))}
                                        isError={isErrorMessage(bosiHusiErrorMessage.mother?.beturiType)}
                                        handleChange={() => {
                                            handleChangeMotherBeturiType(BeturiTypeEnum.死別)
                                        }}
                                    />
                                    <RadioButton
                                        value={""}
                                        checked={bosiHusi.mother?.beturiType === BeturiTypeEnum.生別}
                                        label={convertEnumNameToStr(beturiTypeEnumToName(BeturiTypeEnum.生別))}
                                        isError={isErrorMessage(bosiHusiErrorMessage.mother?.beturiType)}
                                        handleChange={() => {
                                            handleChangeMotherBeturiType(BeturiTypeEnum.生別)
                                        }}
                                    />
                                </RadioButtonGroup>
                            </div>
                            <div className={"formTableRow_item"}>
                                <div className={"formTableRow_item_label"}>
                                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.時期)}<RequiredTag
                                    type={RequiredTagTypeEnum.必須}/>
                                </div>
                                <YearMonth
                                    prefixTextYear={"西暦"}
                                    valueYear={bosiHusi.mother?.jikiYaer}
                                    valueMonth={bosiHusi.mother?.jikiMonth}
                                    isErrorYear={isErrorMessage(bosiHusiErrorMessage.mother?.jikiYaer)}
                                    isErrorMonth={isErrorMessage(bosiHusiErrorMessage.mother?.jikiMonth)}
                                    errorMessages={convertErrorMessageVoToArrayStr(motherJikiErrorMessages)}
                                    handleBlurYear={(value) => {
                                        handleBlurMotherJikiYear(value)
                                    }}
                                    handleBlurMonth={(value) => {
                                        handleBlurJikiMotherMonth(value)
                                    }}
                                />
                            </div>
                            <div className={"formTableRow_item"}>
                                <div className={"formTableRow_item_label"}>
                                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.理由)}<RequiredTag
                                    type={RequiredTagTypeEnum.必須}/>
                                </div>
                                <TextField
                                    value={bosiHusi.mother?.riyu}
                                    handleBlur={(value) => handleBlurMotherRiyu(value)}
                                    errorMessages={convertErrorMessageVoToArrayStr(bosiHusiErrorMessage.mother?.riyu)}
                                    isError={isErrorMessage(bosiHusiErrorMessage.mother?.riyu)}
                                />
                            </div>
                        </FormTableRow>
                        : null
                }

                {
                    bosiHusi.father ?
                        <FormTableRow
                            label={TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.父無)}
                        >
                            <div className={"formTableRow_item"}>

                                <div className={"formTableRow_item_label"}>
                                    {CommonTextEnumToNameJaAndEn(CommonTextEnum.該当する項目を選択してください)}<RequiredTag
                                    type={RequiredTagTypeEnum.必須}/>
                                </div>
                                <RadioButtonGroup
                                    errorMessages={convertErrorMessageVoToArrayStr(bosiHusiErrorMessage.father?.beturiType)}
                                    vertical={true}
                                >
                                    <RadioButton
                                        value={""}
                                        checked={bosiHusi.father?.beturiType === BeturiTypeEnum.死別}
                                        label={convertEnumNameToStr(beturiTypeEnumToName(BeturiTypeEnum.死別))}
                                        isError={isErrorMessage(bosiHusiErrorMessage.father?.beturiType)}
                                        handleChange={() => {
                                            handleChangeFatherBeturiType(BeturiTypeEnum.死別)
                                        }}
                                    />
                                    <RadioButton
                                        value={""}
                                        checked={bosiHusi.father?.beturiType === BeturiTypeEnum.生別}
                                        label={convertEnumNameToStr(beturiTypeEnumToName(BeturiTypeEnum.生別))}
                                        isError={isErrorMessage(bosiHusiErrorMessage.father?.beturiType)}
                                        handleChange={() => {
                                            handleChangeFatherBeturiType(BeturiTypeEnum.生別)
                                        }}
                                    />
                                </RadioButtonGroup>
                            </div>
                            <div className={"formTableRow_item"}>
                                <div className={"formTableRow_item_label"}>
                                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.時期)}<RequiredTag
                                    type={RequiredTagTypeEnum.必須}/>
                                </div>
                                <YearMonth
                                    valueYear={bosiHusi.father?.jikiYaer}
                                    valueMonth={bosiHusi.father?.jikiMonth}
                                    isErrorYear={isErrorMessage(bosiHusiErrorMessage.father?.jikiYaer)}
                                    isErrorMonth={isErrorMessage(bosiHusiErrorMessage.father?.jikiMonth)}
                                    errorMessages={convertErrorMessageVoToArrayStr(fatherJikiErrorMessages)}
                                    handleBlurYear={(value) => {
                                        handleBlurFatherJikiYear(value)
                                    }}
                                    handleBlurMonth={(value) => {
                                        handleBlurJikiFatherMonth(value)
                                    }}
                                />
                            </div>
                            <div className={"formTableRow_item"}>
                                <div className={"formTableRow_item_label"}>
                                    {TokubetuKojoLabelTextEnumToNameJaAndEn(TokubetuKojoLabelTextEnum.理由)}<RequiredTag
                                    type={RequiredTagTypeEnum.必須}/>
                                </div>
                                <TextField
                                    value={bosiHusi.father?.riyu}
                                    handleBlur={(value) => handleBlurFatherRiyu(value)}
                                    errorMessages={convertErrorMessageVoToArrayStr(bosiHusiErrorMessage.father?.riyu)}
                                    isError={isErrorMessage(bosiHusiErrorMessage.father?.riyu)}
                                />
                            </div>
                        </FormTableRow>
                        : null
                }

            </FormTable>
        </>

    )
};

const useStyles = makeStyles({
        root: {},
        title: {
            marginTop: 16
        }
    }
);

