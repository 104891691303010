export function scrollToError() {
    const header = document.getElementById('applicationNavigation');
    const headerHeight = header ? header.clientHeight : 0;

    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName("isActiveErrorMessage");
    const offset = window.pageYOffset;
    const buffer = 200;
    const positions: Array<number> = [];
    for (let i = 0; i < elements.length; i++) {
        positions.push(offset + elements[i].getBoundingClientRect().top - headerHeight - buffer);
    }
    const sorted = positions.sort((a: any, b: any) => {
        return a - b;
    });
    window.scrollTo({
        top: sorted[0],
        behavior: "smooth"
    });
}

