/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2007,
    InlineResponse2007FromJSON,
    InlineResponse2007ToJSON,
} from '../models';

/**
 * no description
 */
export class StudentYosikiKakuteiApi extends runtime.BaseAPI {

    /**
     * 様式の確定 「yosikiStatが未確定」、「入力状態がエラーない」場合に確定できる。 職員はどの期間でも確定可能。 
     */
    async postStudentYosikiStudentNumberKakuteiRaw(): Promise<runtime.ApiResponse<InlineResponse2007>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/yosiki/kakutei`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2007FromJSON(jsonValue));
    }

    /**
     * 様式の確定 「yosikiStatが未確定」、「入力状態がエラーない」場合に確定できる。 職員はどの期間でも確定可能。 
     */
    async postStudentYosikiStudentNumberKakutei(): Promise<InlineResponse2007> {
        const response = await this.postStudentYosikiStudentNumberKakuteiRaw();
        return await response.value();
    }

}
