export enum SimulationTextEnum {
    免除結果シミュレーション = "免除結果シミュレーション",
    全額免除の家計基準を満たしていると思われます = "全額免除の家計基準を満たしていると思われます",
    半額免除の家計基準を満たしていると思われます = "半額免除の家計基準を満たしていると思われます",
    免除の家計基準を満たしていないと思われます = "免除の家計基準を満たしていないと思われます",
}

export function SimulationTextEnumToName(e: SimulationTextEnum): { ja: string, en: string } {
    switch (e) {
        case SimulationTextEnum.免除結果シミュレーション:
            return {ja: "免除結果シミュレーション", en: "Waiver Result Simulation"};
        case SimulationTextEnum.全額免除の家計基準を満たしていると思われます:
            return {ja: "全額免除の家計基準を満たしていると思われます。", en: "It seems that the household finances standard for full waiver is met."};
        case SimulationTextEnum.半額免除の家計基準を満たしていると思われます:
            return {ja: "半額免除の家計基準を満たしていると思われます。", en: "It seems that the household finances standard for half waiver is met."};
        case SimulationTextEnum.免除の家計基準を満たしていないと思われます:
            return {ja: "免除の家計基準を満たしていないと思われます。", en: "It seems that the household finances standard for waiver is not met."};
        default:
            return {ja: "", en: ""};
    }
}


