/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwStudentCreateAccountNewStudentsPostErrorMessage
 */
export interface SwStudentCreateAccountNewStudentsPostErrorMessage {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    base?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    token?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    jukenNumber?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    name?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    nameKana?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    birthYear?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    birthMonth?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    birthDay?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    age?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    tel?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    bukyokuCode?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    gakka?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    isRyugakusei?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    isEijusya?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    password?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwStudentCreateAccountNewStudentsPostErrorMessage
     */
    passwordComfirm?: Array<SwErrorMessage>;
}

export function SwStudentCreateAccountNewStudentsPostErrorMessageFromJSON(json: any): SwStudentCreateAccountNewStudentsPostErrorMessage {
    return SwStudentCreateAccountNewStudentsPostErrorMessageFromJSONTyped(json, false);
}

export function SwStudentCreateAccountNewStudentsPostErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwStudentCreateAccountNewStudentsPostErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base': !exists(json, 'base') ? undefined : ((json['base'] as Array<any>).map(SwErrorMessageFromJSON)),
        'token': !exists(json, 'token') ? undefined : ((json['token'] as Array<any>).map(SwErrorMessageFromJSON)),
        'jukenNumber': !exists(json, 'jukenNumber') ? undefined : ((json['jukenNumber'] as Array<any>).map(SwErrorMessageFromJSON)),
        'name': !exists(json, 'name') ? undefined : ((json['name'] as Array<any>).map(SwErrorMessageFromJSON)),
        'nameKana': !exists(json, 'nameKana') ? undefined : ((json['nameKana'] as Array<any>).map(SwErrorMessageFromJSON)),
        'birthYear': !exists(json, 'birthYear') ? undefined : ((json['birthYear'] as Array<any>).map(SwErrorMessageFromJSON)),
        'birthMonth': !exists(json, 'birthMonth') ? undefined : ((json['birthMonth'] as Array<any>).map(SwErrorMessageFromJSON)),
        'birthDay': !exists(json, 'birthDay') ? undefined : ((json['birthDay'] as Array<any>).map(SwErrorMessageFromJSON)),
        'age': !exists(json, 'age') ? undefined : ((json['age'] as Array<any>).map(SwErrorMessageFromJSON)),
        'tel': !exists(json, 'tel') ? undefined : ((json['tel'] as Array<any>).map(SwErrorMessageFromJSON)),
        'bukyokuCode': !exists(json, 'bukyokuCode') ? undefined : ((json['bukyokuCode'] as Array<any>).map(SwErrorMessageFromJSON)),
        'gakka': !exists(json, 'gakka') ? undefined : ((json['gakka'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isRyugakusei': !exists(json, 'isRyugakusei') ? undefined : ((json['isRyugakusei'] as Array<any>).map(SwErrorMessageFromJSON)),
        'isEijusya': !exists(json, 'isEijusya') ? undefined : ((json['isEijusya'] as Array<any>).map(SwErrorMessageFromJSON)),
        'password': !exists(json, 'password') ? undefined : ((json['password'] as Array<any>).map(SwErrorMessageFromJSON)),
        'passwordComfirm': !exists(json, 'passwordComfirm') ? undefined : ((json['passwordComfirm'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwStudentCreateAccountNewStudentsPostErrorMessageToJSON(value?: SwStudentCreateAccountNewStudentsPostErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base': value.base === undefined ? undefined : ((value.base as Array<any>).map(SwErrorMessageToJSON)),
        'token': value.token === undefined ? undefined : ((value.token as Array<any>).map(SwErrorMessageToJSON)),
        'jukenNumber': value.jukenNumber === undefined ? undefined : ((value.jukenNumber as Array<any>).map(SwErrorMessageToJSON)),
        'name': value.name === undefined ? undefined : ((value.name as Array<any>).map(SwErrorMessageToJSON)),
        'nameKana': value.nameKana === undefined ? undefined : ((value.nameKana as Array<any>).map(SwErrorMessageToJSON)),
        'birthYear': value.birthYear === undefined ? undefined : ((value.birthYear as Array<any>).map(SwErrorMessageToJSON)),
        'birthMonth': value.birthMonth === undefined ? undefined : ((value.birthMonth as Array<any>).map(SwErrorMessageToJSON)),
        'birthDay': value.birthDay === undefined ? undefined : ((value.birthDay as Array<any>).map(SwErrorMessageToJSON)),
        'age': value.age === undefined ? undefined : ((value.age as Array<any>).map(SwErrorMessageToJSON)),
        'tel': value.tel === undefined ? undefined : ((value.tel as Array<any>).map(SwErrorMessageToJSON)),
        'bukyokuCode': value.bukyokuCode === undefined ? undefined : ((value.bukyokuCode as Array<any>).map(SwErrorMessageToJSON)),
        'gakka': value.gakka === undefined ? undefined : ((value.gakka as Array<any>).map(SwErrorMessageToJSON)),
        'isRyugakusei': value.isRyugakusei === undefined ? undefined : ((value.isRyugakusei as Array<any>).map(SwErrorMessageToJSON)),
        'isEijusya': value.isEijusya === undefined ? undefined : ((value.isEijusya as Array<any>).map(SwErrorMessageToJSON)),
        'password': value.password === undefined ? undefined : ((value.password as Array<any>).map(SwErrorMessageToJSON)),
        'passwordComfirm': value.passwordComfirm === undefined ? undefined : ((value.passwordComfirm as Array<any>).map(SwErrorMessageToJSON)),
    };
}


