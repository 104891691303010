import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {ZokugaraEnum, zokugaraEnumToName} from "common/enums/ZokugaraEnum";
import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import {createBlankMember, FamilyInfoVo} from "common/models/YosikiInfoModels";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {FamilyInfoTextEnum, FamilyInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/FamilyInfoTextEnum";
import {Button} from "components/atoms/Button";
import {Selectbox} from "components/atoms/forms/Selectbox";
import {TextField} from "components/atoms/forms/TextField";
import {Modal} from "components/atoms/Modal";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import React, {useState} from 'react';
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface YosikiEditSelectZokugaraModalProps {
    openState: boolean
    studentNumber: string
    updateSelectZokugaraModalOpenState: (value: boolean) => void
    familyInfo: FamilyInfoVo
    updateFamilyInfo: (part: Partial<FamilyInfoVo>) => void
}

export const _YosikiEditSelectZokugaraModal: React.FC<YosikiEditSelectZokugaraModalProps> = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const [zokugara, updateZokugara] = useState<ZokugaraEnum>(ZokugaraEnum.父);
    const [zokugaraOther, updateZokugaraOther] = useState<string>("");
    const [zokugaraErrorMessage, updateZokugaraErrorMessage] = useState<Array<string> | undefined>(undefined);
    const [zokugaraOtherErrorMessage, updateZokugaraOtherErrorMessage] = useState<Array<string> | undefined>(undefined);

    React.useEffect(() => {
        updateZokugara(ZokugaraEnum.父);
        updateZokugaraOther("")
    }, [props.openState]);

    function handleChangeSelectbox(value: string) {
        updateZokugara(value as ZokugaraEnum);
        updateZokugaraErrorMessage(undefined);
    }

    function handleBlurZokugaraOther(value: string) {
        updateZokugaraOther(value);
        updateZokugaraOtherErrorMessage(undefined);
    }

    function isSelectedZokugaraCorrect(): boolean {

        // その他の場合、続柄名の入力が必須になる。
        if (zokugara === ZokugaraEnum.その他 && zokugaraOther === "") {
            updateZokugaraOtherErrorMessage(["必須項目です。", "Input is required."]);
            return false
        }

        // 父と母の重複はエラーとする。
        const members = props.familyInfo.members;
        let zokugaras: Array<string> = members.map((m) => {
                return m.zokugara
            }
        );
        if ((zokugara === ZokugaraEnum.母) && zokugaras.indexOf(zokugara) !== -1) {
            updateZokugaraErrorMessage(["母は既に追加されています。", "Mother has already been added."]);
            return false
        }
        if ((zokugara === ZokugaraEnum.父) && zokugaras.indexOf(zokugara) !== -1) {
            updateZokugaraErrorMessage(["父は既に追加されています。", "Father has already been added."]);
            return false
        }
        return true
    }

    function addZokugara(zokugara: ZokugaraEnum, zokugaraOther: string) {
        const members = props.familyInfo.members.slice();
        members.push({
            ...createBlankMember(),
            zokugara: zokugara as ZokugaraEnum,
            zokugaraOther: zokugaraOther,
        });
        let part: Partial<FamilyInfoVo> = {members: members};
        props.updateFamilyInfo(part)
    }

    async function handleClickComfirm() {
        if (!isSelectedZokugaraCorrect()) return;
        addZokugara(
            zokugara,
            zokugara === ZokugaraEnum.その他 ? zokugaraOther : ""
        );
        props.updateSelectZokugaraModalOpenState(false);
        updateZokugaraErrorMessage(undefined);
        updateZokugaraOtherErrorMessage(undefined);
    }

    function handleClickClose() {
        updateZokugaraErrorMessage(undefined);
        updateZokugaraOtherErrorMessage(undefined);
        props.updateSelectZokugaraModalOpenState(false)
    }

    let options: Array<React.ReactNode> = [];
    Object.entries(ZokugaraEnum).forEach(([, value]) => {
        options.push(
            <option
                value={value}
                key={value}
            >
                {convertEnumNameToStr(zokugaraEnumToName(value))}
            </option>
        )
    });

    return (

        <Modal
            openState={props.openState}
            title={CommonTextEnumToNameJaAndEn(CommonTextEnum.続柄を追加)}
        >

            <FormTable>

                <FormTableRow
                    label={FamilyInfoTextEnumToNameJaAndEn(FamilyInfoTextEnum.続柄)}
                    requiredType={RequiredTagTypeEnum.必須}
                >

                    <div className={"formTableRow_item"}>

                        <div className={`${classes.row} ${xs ? classes.xsRow : ""}`}>

                            <Selectbox
                                value={zokugara}
                                isDisabled={false}
                                isError={zokugaraErrorMessage && zokugaraErrorMessage.length !== 0}
                                errorMessages={zokugaraErrorMessage}
                                handleChange={handleChangeSelectbox}
                            >
                                {options}
                            </Selectbox>

                            {
                                zokugara === ZokugaraEnum.その他 ?
                                    <TextField
                                        value={zokugaraOther}
                                        handleBlur={(value) => handleBlurZokugaraOther(value)}
                                        width={"160px"}
                                        isError={zokugaraOtherErrorMessage && zokugaraOtherErrorMessage.length !== 0}
                                        errorMessages={zokugaraOtherErrorMessage}
                                    />
                                    : null
                            }

                        </div>

                    </div>

                </FormTableRow>

            </FormTable>

            <DialogActions className={"modal_actions"}>
                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.キャンセル)}
                    width={"152px"}
                    handleClick={handleClickClose}
                />
                <Button
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                    width={"120px"}
                    handleClick={handleClickComfirm}
                />
            </DialogActions>

        </Modal>

    );
};

export const YosikiEditSelectZokugaraModal = React.memo<YosikiEditSelectZokugaraModalProps>(_YosikiEditSelectZokugaraModal, (prev, next) => {
    return prev.openState === next.openState
        && prev.studentNumber === next.studentNumber
        && prev.updateSelectZokugaraModalOpenState === next.updateSelectZokugaraModalOpenState
        && prev.familyInfo === next.familyInfo
        && prev.updateFamilyInfo === next.updateFamilyInfo
});

const useStyles = makeStyles({
        row: {
            display: "flex",
            width: "100%",
            "& > *:not(:first-child)": {
                marginLeft: 4
            }
        },
        xsRow: {
            display: "block",
            "& > *:not(:first-child)": {
                marginLeft: 0,
                marginTop: 8
            }
        },
    }
)
