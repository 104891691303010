/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwSimulationDetail
 */
export interface SwSimulationDetail {
    /**
     * 世帯の総所得金額
     * @type {number}
     * @memberof SwSimulationDetail
     */
    a: number;
    /**
     * 世帯の特別控除額
     * @type {number}
     * @memberof SwSimulationDetail
     */
    b: number;
    /**
     * 収入基準額(半額)
     * @type {number}
     * @memberof SwSimulationDetail
     */
    c1: number;
    /**
     * 収入基準額(全額)
     * @type {number}
     * @memberof SwSimulationDetail
     */
    c2: number;
}

export function SwSimulationDetailFromJSON(json: any): SwSimulationDetail {
    return SwSimulationDetailFromJSONTyped(json, false);
}

export function SwSimulationDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwSimulationDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'a': json['a'],
        'b': json['b'],
        'c1': json['c1'],
        'c2': json['c2'],
    };
}

export function SwSimulationDetailToJSON(value?: SwSimulationDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'a': value.a,
        'b': value.b,
        'c1': value.c1,
        'c2': value.c2,
    };
}


