/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SwStudentInfo,
    SwStudentInfoFromJSON,
    SwStudentInfoToJSON,
} from '../models';

/**
 * no description
 */
export class StudentAccountInfoApi extends runtime.BaseAPI {

    /**
     * 学生の情報を取得
     */
    async getStudentAccountInfoRaw(): Promise<runtime.ApiResponse<SwStudentInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/account_info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwStudentInfoFromJSON(jsonValue));
    }

    /**
     * 学生の情報を取得
     */
    async getStudentAccountInfo(): Promise<SwStudentInfo> {
        const response = await this.getStudentAccountInfoRaw();
        return await response.value();
    }

}
