/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2006,
    InlineResponse2006FromJSON,
    InlineResponse2006ToJSON,
    SwYosikiInfoRequest,
    SwYosikiInfoRequestFromJSON,
    SwYosikiInfoRequestToJSON,
    SwYosikiInfoResponse,
    SwYosikiInfoResponseFromJSON,
    SwYosikiInfoResponseToJSON,
} from '../models';

export interface PostStudentYosikiInfoRequest {
    swYosikiInfoRequest: SwYosikiInfoRequest;
}

/**
 * no description
 */
export class StudentYosikiInfoApi extends runtime.BaseAPI {

    /**
     * 様式の入力内容を取得
     */
    async getStudentYosikiInfoRaw(): Promise<runtime.ApiResponse<SwYosikiInfoResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/student/yosiki_info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwYosikiInfoResponseFromJSON(jsonValue));
    }

    /**
     * 様式の入力内容を取得
     */
    async getStudentYosikiInfo(): Promise<SwYosikiInfoResponse> {
        const response = await this.getStudentYosikiInfoRaw();
        return await response.value();
    }

    /**
     * 様式の入力内容を保存 エラーが出てもエラー状態で保存する。 
     */
    async postStudentYosikiInfoRaw(requestParameters: PostStudentYosikiInfoRequest): Promise<runtime.ApiResponse<InlineResponse2006>> {
        if (requestParameters.swYosikiInfoRequest === null || requestParameters.swYosikiInfoRequest === undefined) {
            throw new runtime.RequiredError('swYosikiInfoRequest','Required parameter requestParameters.swYosikiInfoRequest was null or undefined when calling postStudentYosikiInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/student/yosiki_info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwYosikiInfoRequestToJSON(requestParameters.swYosikiInfoRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2006FromJSON(jsonValue));
    }

    /**
     * 様式の入力内容を保存 エラーが出てもエラー状態で保存する。 
     */
    async postStudentYosikiInfo(requestParameters: PostStudentYosikiInfoRequest): Promise<InlineResponse2006> {
        const response = await this.postStudentYosikiInfoRaw(requestParameters);
        return await response.value();
    }

}
