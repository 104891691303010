import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from 'react';
import {BgColorsEnum, BorderColorsEnum, TextColorsEnum} from "common/enums_value/ColorsEnum";
import {Theme} from "@material-ui/core";

export interface Props {
    styles?: StylesProps
}

interface StylesProps {
    whiteSpace: "inherit" | "initial" | "normal" | "nowrap" | "break-spaces" | "pre" | "pre-line" | "pre-wrap"
}

export const Table: FC<Props> = (props) => {
    const styleProps: StylesProps = {
        whiteSpace: props.styles?.whiteSpace || "nowrap"
    }
    const classes = useStyles(styleProps);
    return (
        <table className={classes.root}>
            {props.children}
        </table>
    )
};

const useStyles = makeStyles<Theme, StylesProps>((theme) => (
    {
        root: props => ({
            tableLayout: "fixed",
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "8px",
            "& > tbody": {
                "& > tr": {
                    borderBottom: `1px solid ${BorderColorsEnum.LightGray}`,
                    "&:nth-child(2n)": {
                        backgroundColor: BgColorsEnum.OffWhite,
                    },
                    "& > th": {
                        backgroundColor: BgColorsEnum.TableHead,
                        color: TextColorsEnum.White,
                        padding: "4px",
                        textAlign: "left",
                        "&.check": {
                            textAlign: "center"
                        },
                    },
                    "& > td": {
                        // backgroundColor: BgColorsEnum.White,
                        padding: "4px",
                        textOverflow: "ellipsis",
                        whiteSpace: props.whiteSpace,
                        overflow: "hidden",
                        "&.check": {
                            textAlign: "center"
                        },
                    },
                },
            },
        }),
    }
));

