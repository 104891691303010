// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum JugyoryoMenjoHanteiResultEnum {
    全額 = "E全額",
    半額 = "E半額",
    該当なし = "E該当なし",
}

export function jugyoryoMenjoHanteiResultEnumToName(e: JugyoryoMenjoHanteiResultEnum): { ja: string, en: string } {
    switch (e) {
        case JugyoryoMenjoHanteiResultEnum.全額: return { ja: "全額免除の家計基準を満たしていると思われます。", en: "It seems that the household finances standard for full waiver is met." };
        case JugyoryoMenjoHanteiResultEnum.半額: return { ja: "半額免除の家計基準を満たしていると思われます。", en: "It seems that the household finances standard for half waiver is met." };
        case JugyoryoMenjoHanteiResultEnum.該当なし: return { ja: "免除の家計基準を満たしていないと思われます。", en: "It seems that the household finances standard for waiver is not met." };
    }
}