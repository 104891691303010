/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2006,
    InlineResponse2006FromJSON,
    InlineResponse2006ToJSON,
    SwYosikiInfoRequest,
    SwYosikiInfoRequestFromJSON,
    SwYosikiInfoRequestToJSON,
    SwYosikiInfoResponse,
    SwYosikiInfoResponseFromJSON,
    SwYosikiInfoResponseToJSON,
} from '../models';

export interface GetOfficerYosikiInfoRequest {
    studentNumber: string;
}

export interface PostOfficerYosikiInfoRequest {
    studentNumber: string;
    swYosikiInfoRequest: SwYosikiInfoRequest;
}

/**
 * no description
 */
export class OfficerYosikiInfoApi extends runtime.BaseAPI {

    /**
     * 様式の入力内容を取得
     */
    async getOfficerYosikiInfoRaw(requestParameters: GetOfficerYosikiInfoRequest): Promise<runtime.ApiResponse<SwYosikiInfoResponse>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling getOfficerYosikiInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/yosiki_info/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwYosikiInfoResponseFromJSON(jsonValue));
    }

    /**
     * 様式の入力内容を取得
     */
    async getOfficerYosikiInfo(requestParameters: GetOfficerYosikiInfoRequest): Promise<SwYosikiInfoResponse> {
        const response = await this.getOfficerYosikiInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * 様式の入力内容を保存 エラーが出てもエラー状態で保存する。 
     */
    async postOfficerYosikiInfoRaw(requestParameters: PostOfficerYosikiInfoRequest): Promise<runtime.ApiResponse<InlineResponse2006>> {
        if (requestParameters.studentNumber === null || requestParameters.studentNumber === undefined) {
            throw new runtime.RequiredError('studentNumber','Required parameter requestParameters.studentNumber was null or undefined when calling postOfficerYosikiInfo.');
        }

        if (requestParameters.swYosikiInfoRequest === null || requestParameters.swYosikiInfoRequest === undefined) {
            throw new runtime.RequiredError('swYosikiInfoRequest','Required parameter requestParameters.swYosikiInfoRequest was null or undefined when calling postOfficerYosikiInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/officer/yosiki_info/{studentNumber}`.replace(`{${"studentNumber"}}`, encodeURIComponent(String(requestParameters.studentNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwYosikiInfoRequestToJSON(requestParameters.swYosikiInfoRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2006FromJSON(jsonValue));
    }

    /**
     * 様式の入力内容を保存 エラーが出てもエラー状態で保存する。 
     */
    async postOfficerYosikiInfo(requestParameters: PostOfficerYosikiInfoRequest): Promise<InlineResponse2006> {
        const response = await this.postOfficerYosikiInfoRaw(requestParameters);
        return await response.value();
    }

}
