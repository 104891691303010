import React from 'react';

export const ModalContentNenkinOnkyuRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                年金・恩給、各種手当等は最新の<span className={"underLine"}>支払い通知書</span>等から算出される受給(見込)年額を記入すること。
            </div>

            <div className={"modalContent_item"}>
                For pensions and other types of retirement benefits, enter the annual amount received (expected), calculated from the most current <span className={"underLine"}>Payment Notice</span>, etc. of various allowances.
            </div>

        </div>

    );
};
