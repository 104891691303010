/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwMemberErrorMessagesSyugakusya
 */
export interface SwMemberErrorMessagesSyugakusya {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusya
     */
    schoolType?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusya
     */
    schoolName?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusya
     */
    gakunen?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusya
     */
    settiKubun?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusya
     */
    tugakuFromJitaku?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusya
     */
    jugyoryoMenjoState?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusya
     */
    jugyoryoForHalfYear?: Array<SwErrorMessage>;
}

export function SwMemberErrorMessagesSyugakusyaFromJSON(json: any): SwMemberErrorMessagesSyugakusya {
    return SwMemberErrorMessagesSyugakusyaFromJSONTyped(json, false);
}

export function SwMemberErrorMessagesSyugakusyaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwMemberErrorMessagesSyugakusya {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schoolType': !exists(json, 'schoolType') ? undefined : ((json['schoolType'] as Array<any>).map(SwErrorMessageFromJSON)),
        'schoolName': !exists(json, 'schoolName') ? undefined : ((json['schoolName'] as Array<any>).map(SwErrorMessageFromJSON)),
        'gakunen': !exists(json, 'gakunen') ? undefined : ((json['gakunen'] as Array<any>).map(SwErrorMessageFromJSON)),
        'settiKubun': !exists(json, 'settiKubun') ? undefined : ((json['settiKubun'] as Array<any>).map(SwErrorMessageFromJSON)),
        'tugakuFromJitaku': !exists(json, 'tugakuFromJitaku') ? undefined : ((json['tugakuFromJitaku'] as Array<any>).map(SwErrorMessageFromJSON)),
        'jugyoryoMenjoState': !exists(json, 'jugyoryoMenjoState') ? undefined : ((json['jugyoryoMenjoState'] as Array<any>).map(SwErrorMessageFromJSON)),
        'jugyoryoForHalfYear': !exists(json, 'jugyoryoForHalfYear') ? undefined : ((json['jugyoryoForHalfYear'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwMemberErrorMessagesSyugakusyaToJSON(value?: SwMemberErrorMessagesSyugakusya | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schoolType': value.schoolType === undefined ? undefined : ((value.schoolType as Array<any>).map(SwErrorMessageToJSON)),
        'schoolName': value.schoolName === undefined ? undefined : ((value.schoolName as Array<any>).map(SwErrorMessageToJSON)),
        'gakunen': value.gakunen === undefined ? undefined : ((value.gakunen as Array<any>).map(SwErrorMessageToJSON)),
        'settiKubun': value.settiKubun === undefined ? undefined : ((value.settiKubun as Array<any>).map(SwErrorMessageToJSON)),
        'tugakuFromJitaku': value.tugakuFromJitaku === undefined ? undefined : ((value.tugakuFromJitaku as Array<any>).map(SwErrorMessageToJSON)),
        'jugyoryoMenjoState': value.jugyoryoMenjoState === undefined ? undefined : ((value.jugyoryoMenjoState as Array<any>).map(SwErrorMessageToJSON)),
        'jugyoryoForHalfYear': value.jugyoryoForHalfYear === undefined ? undefined : ((value.jugyoryoForHalfYear as Array<any>).map(SwErrorMessageToJSON)),
    };
}


