// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum GakubuEnum {
    農学 = "E農学",
    工学 = "E工学",
    農府修 = "E農府修",
    農博 = "E農博",
    農府博 = "E農府博",
    工府専 = "E工府専",
    工府修 = "E工府修",
    工府博 = "E工府博",
    生府修 = "E生府修",
    生府博 = "E生府博",
    一貫博 = "E一貫博",
}

export function gakubuEnumToName(e: GakubuEnum): { ja: string, en: string } {
    switch (e) {
        case GakubuEnum.農学: return { ja: "農学部", en: "Faculty of Agriculture" };
        case GakubuEnum.工学: return { ja: "工学部", en: "Faculty of Engineering" };
        case GakubuEnum.農府修: return { ja: "農学府", en: "Graduate School of Agriculture" };
        case GakubuEnum.農博: return { ja: "連合農学研究科", en: "" };
        case GakubuEnum.農府博: return { ja: "農学府（4年制博士課程）", en: "" };
        case GakubuEnum.工府専: return { ja: "工学府専門職学位", en: "" };
        case GakubuEnum.工府修: return { ja: "工学府博士前期", en: "" };
        case GakubuEnum.工府博: return { ja: "工学府博士後期", en: "" };
        case GakubuEnum.生府修: return { ja: "生物システム応用科学府博士前期", en: "" };
        case GakubuEnum.生府博: return { ja: "生物システム応用科学府博士後期", en: "" };
        case GakubuEnum.一貫博: return { ja: "生物システム応用科学府一貫制博士", en: "" };
    }
}