import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {YosikiKakuteiModalType} from "common/models/yosiki/YosikiInfoModalStoreVo";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {Button} from "components/atoms/Button";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import {Modal} from "components/atoms/Modal";
import {SwErrorMessage} from "openapi/models";
import React from 'react';

interface YosikiDetailKakuteiModalProps {
    openState?: YosikiKakuteiModalType
    handleClickCancel: () => void
    handleClickKakutei: () => void
    errorMessage?: Array<SwErrorMessage>
}

export const _YosikiDetailKakuteiModal: React.FC<YosikiDetailKakuteiModalProps> = (props) => {

    const classes = useStyles();

    if (props.openState !== YosikiKakuteiModalType.kakuteiModal) return <div/>;

    return (

        <Modal
            openState={props.openState === YosikiKakuteiModalType.kakuteiModal}
            title={"様式1を確定 / Confirm form 1"}
        >

            <ErrorMessages
                className={classes.errorMessages}
                errorMessages={convertErrorMessageVoToArrayStr(props.errorMessage)}
            />

            <div>
                <span className={classes.warnigText}>一度確定すると編集が行えなくなります。</span><br />
                再度編集したい場合は、事務までお問い合わせください。<br />
                様式1を確定にしますか？<br />
                <br />
                <span className={classes.warnigText}>Once it is confirmed, editing will not be possible.</span><br />
                If you want to edit again, please contact the administration office.<br />
                Are you sure you want to confirm?
            </div>

            <DialogActions className={"modal_actions"}>
                <Button
                    type={"default"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.キャンセル)}
                    width={"152px"}
                    handleClick={props.handleClickCancel}
                />
                <Button
                    type={"primary"}
                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                    width={"120px"}
                    handleClick={props.handleClickKakutei}
                />
            </DialogActions>

        </Modal>

    );
};

const useStyles = makeStyles({
    warnigText: {
        fontWeight: 700
    },
});

export const YosikiKakuteiModal = React.memo<YosikiDetailKakuteiModalProps>(_YosikiDetailKakuteiModal, (prev, next) => {
    return prev.openState === next.openState
        && prev.handleClickCancel === next.handleClickCancel
        && prev.handleClickKakutei === next.handleClickKakutei
});

