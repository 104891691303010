import {makeStyles} from '@material-ui/core/styles';
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {CommonTextEnum, CommonTextEnumToNameJaAndEn} from "common/enums_text/CommonTextEnum";
import {LoginLabelTextEnum, LoginLabelTextEnumToNameJaAndEn} from "common/enums_text/LoginLabelTextEnum";
import {Button} from "components/atoms/Button";
import {TextField} from "components/atoms/forms/TextField";
import {ErrorMessages} from "components/atoms/ErrorMessages";
import {Container} from "components/layouts/Container";
import {LoginContainer} from "components/layouts/LoginContainer";
import {FieldWithLabel} from "components/molecules/utilities/FieldWithLabel";
import {HeaderStudent} from "components/organisms/utilities/HeaderStudent";
import {resetPasswordStore, ResetPasswordStoreVo} from "pages/student/resetPassword/stores/ResetPasswordStore";
import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from "react-router";

const _ResetPassword: React.FC<RouteComponentProps> = () => {

    const classes = useStyles();

    const [resetPassword, updateResetPassword] = React.useState<ResetPasswordStoreVo>(resetPasswordStore.currentState);

    useEffect(() => {
        resetPasswordStore.updateErrorMessages(undefined);
        return resetPasswordStore.listen(updateResetPassword)
    }, []);

    function handleClickLoginButton() {
        resetPasswordStore.postSendResetPasswordLink().then()
    }

    function handleBlurPassword(v: string) {
        resetPasswordStore.updateForm({password: v});
        resetPasswordStore.updateErrorMessages({password: undefined})
    }

    function handleBlurPasswordConfirm(v: string) {
        resetPasswordStore.updateForm({passwordComfirm: v});
        resetPasswordStore.updateErrorMessages({passwordComfirm: undefined})
    }

    return (

        <>

            <HeaderStudent signedIn={false}/>

            <Container
                maxWidth={'464px'}
                bgColor={"gray"}
                className={classes.container}
            >

                <div className={"container_inner"}>

                    <LoginContainer
                        className={classes.root}
                    >
                        <div className={"login_title"}>{LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード再設定)}</div>
                        <div className={"login_form"}>

                            <FieldWithLabel
                                label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード)}
                                errorMessages={convertErrorMessageVoToArrayStr(resetPassword.errorMessages?.password)}
                            >
                                <TextField
                                    value={resetPassword.form.password}
                                    type={"password"}
                                    handleBlur={handleBlurPassword}
                                    isError={isErrorMessage(resetPassword.errorMessages?.password)}
                                    helperText={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード組み合わせ)}
                                />
                            </FieldWithLabel>

                            <FieldWithLabel
                                label={LoginLabelTextEnumToNameJaAndEn(LoginLabelTextEnum.パスワード確認)}
                                errorMessages={convertErrorMessageVoToArrayStr(resetPassword.errorMessages?.passwordComfirm)}
                            >
                                <TextField
                                    value={resetPassword.form.passwordComfirm}
                                    type={"password"}
                                    handleBlur={handleBlurPasswordConfirm}
                                    isError={isErrorMessage(resetPassword.errorMessages?.passwordComfirm)}
                                />
                            </FieldWithLabel>

                            <ErrorMessages
                                className={classes.errorMessages}
                                errorMessages={
                                    convertErrorMessageVoToArrayStr(resetPassword.errorMessages?.base).concat(
                                        convertErrorMessageVoToArrayStr(resetPassword.errorMessages?.token)
                                    )
                                }
                            />

                            <div>
                                <Button
                                    type={"primary"}
                                    label={CommonTextEnumToNameJaAndEn(CommonTextEnum.確定)}
                                    width={"100%"}
                                    handleClick={handleClickLoginButton}
                                />
                            </div>

                        </div>
                    </LoginContainer>

                </div>

            </Container>

        </>

    );
};

export const ResetPassword = withRouter(_ResetPassword);

const useStyles = makeStyles({
    container: {
        "& .container_inner": {
            display: "flex"
        }
    },
    root: {
        "& .login_title": {
            textAlign: "center",
            fontSize: 18,
            fontWeight: 700,
            marginBottom: 16,
        },
        "& .login_form": {
            "& > *": {
                marginTop: 16,
                "&:first-child": {
                    marginTop: 0
                }
            },
            "& .password": {
                textAlign: "center"
            }
        }
    },
    errorMessages: {}
});
