/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwMailRow,
    SwMailRowFromJSON,
    SwMailRowFromJSONTyped,
    SwMailRowToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerMail
 */
export interface SwOfficerMail {
    /**
     * 
     * @type {Array<SwMailRow>}
     * @memberof SwOfficerMail
     */
    mails: Array<SwMailRow>;
}

export function SwOfficerMailFromJSON(json: any): SwOfficerMail {
    return SwOfficerMailFromJSONTyped(json, false);
}

export function SwOfficerMailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerMail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mails': ((json['mails'] as Array<any>).map(SwMailRowFromJSON)),
    };
}

export function SwOfficerMailToJSON(value?: SwOfficerMail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mails': ((value.mails as Array<any>).map(SwMailRowToJSON)),
    };
}


