/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwSyunyuInfo,
    SwSyunyuInfoFromJSON,
    SwSyunyuInfoFromJSONTyped,
    SwSyunyuInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwFamilyInfoHonnin
 */
export interface SwFamilyInfoHonnin {
    /**
     * 
     * @type {boolean}
     * @memberof SwFamilyInfoHonnin
     */
    syunyuAriNasi?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwFamilyInfoHonnin
     */
    kakuteisinkokuAriNasi?: boolean;
    /**
     * 
     * @type {SwSyunyuInfo}
     * @memberof SwFamilyInfoHonnin
     */
    syunyuInfo: SwSyunyuInfo;
    /**
     * 
     * @type {boolean}
     * @memberof SwFamilyInfoHonnin
     */
    tugakuFromJitaku?: boolean;
}

export function SwFamilyInfoHonninFromJSON(json: any): SwFamilyInfoHonnin {
    return SwFamilyInfoHonninFromJSONTyped(json, false);
}

export function SwFamilyInfoHonninFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwFamilyInfoHonnin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syunyuAriNasi': !exists(json, 'syunyuAriNasi') ? undefined : json['syunyuAriNasi'],
        'kakuteisinkokuAriNasi': !exists(json, 'kakuteisinkokuAriNasi') ? undefined : json['kakuteisinkokuAriNasi'],
        'syunyuInfo': SwSyunyuInfoFromJSON(json['syunyuInfo']),
        'tugakuFromJitaku': !exists(json, 'tugakuFromJitaku') ? undefined : json['tugakuFromJitaku'],
    };
}

export function SwFamilyInfoHonninToJSON(value?: SwFamilyInfoHonnin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'syunyuAriNasi': value.syunyuAriNasi,
        'kakuteisinkokuAriNasi': value.kakuteisinkokuAriNasi,
        'syunyuInfo': SwSyunyuInfoToJSON(value.syunyuInfo),
        'tugakuFromJitaku': value.tugakuFromJitaku,
    };
}


