/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20017,
    InlineResponse20017FromJSON,
    InlineResponse20017ToJSON,
    SwStudentResetPasswordPutRequest,
    SwStudentResetPasswordPutRequestFromJSON,
    SwStudentResetPasswordPutRequestToJSON,
} from '../models';

export interface PostStudentResetPasswordRequest {
    swStudentResetPasswordPutRequest: SwStudentResetPasswordPutRequest;
}

/**
 * no description
 */
export class StudentResetPasswordApi extends runtime.BaseAPI {

    /**
     * 学生のパスワードリセットのリンクをメール送信 create_account API と同様にトークンが必要。 
     */
    async postStudentResetPasswordRaw(requestParameters: PostStudentResetPasswordRequest): Promise<runtime.ApiResponse<InlineResponse20017>> {
        if (requestParameters.swStudentResetPasswordPutRequest === null || requestParameters.swStudentResetPasswordPutRequest === undefined) {
            throw new runtime.RequiredError('swStudentResetPasswordPutRequest','Required parameter requestParameters.swStudentResetPasswordPutRequest was null or undefined when calling postStudentResetPassword.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/student/reset_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SwStudentResetPasswordPutRequestToJSON(requestParameters.swStudentResetPasswordPutRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20017FromJSON(jsonValue));
    }

    /**
     * 学生のパスワードリセットのリンクをメール送信 create_account API と同様にトークンが必要。 
     */
    async postStudentResetPassword(requestParameters: PostStudentResetPasswordRequest): Promise<InlineResponse20017> {
        const response = await this.postStudentResetPasswordRaw(requestParameters);
        return await response.value();
    }

}
