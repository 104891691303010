/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwMemberSyugakusya
 */
export interface SwMemberSyugakusya {
    /**
     * enum
     * @type {string}
     * @memberof SwMemberSyugakusya
     */
    schoolType?: string;
    /**
     * 
     * @type {string}
     * @memberof SwMemberSyugakusya
     */
    schoolName?: string;
    /**
     * 
     * @type {number}
     * @memberof SwMemberSyugakusya
     */
    gakunen?: number;
    /**
     * enum
     * @type {string}
     * @memberof SwMemberSyugakusya
     */
    settiKubun?: string;
    /**
     * enum
     * @type {string}
     * @memberof SwMemberSyugakusya
     */
    jugyoryoMenjoState?: string;
    /**
     * 
     * @type {number}
     * @memberof SwMemberSyugakusya
     */
    jugyoryoForHalfYear?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SwMemberSyugakusya
     */
    tugakuFromJitaku?: boolean;
}

export function SwMemberSyugakusyaFromJSON(json: any): SwMemberSyugakusya {
    return SwMemberSyugakusyaFromJSONTyped(json, false);
}

export function SwMemberSyugakusyaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwMemberSyugakusya {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schoolType': !exists(json, 'schoolType') ? undefined : json['schoolType'],
        'schoolName': !exists(json, 'schoolName') ? undefined : json['schoolName'],
        'gakunen': !exists(json, 'gakunen') ? undefined : json['gakunen'],
        'settiKubun': !exists(json, 'settiKubun') ? undefined : json['settiKubun'],
        'jugyoryoMenjoState': !exists(json, 'jugyoryoMenjoState') ? undefined : json['jugyoryoMenjoState'],
        'jugyoryoForHalfYear': !exists(json, 'jugyoryoForHalfYear') ? undefined : json['jugyoryoForHalfYear'],
        'tugakuFromJitaku': !exists(json, 'tugakuFromJitaku') ? undefined : json['tugakuFromJitaku'],
    };
}

export function SwMemberSyugakusyaToJSON(value?: SwMemberSyugakusya | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schoolType': value.schoolType,
        'schoolName': value.schoolName,
        'gakunen': value.gakunen,
        'settiKubun': value.settiKubun,
        'jugyoryoMenjoState': value.jugyoryoMenjoState,
        'jugyoryoForHalfYear': value.jugyoryoForHalfYear,
        'tugakuFromJitaku': value.tugakuFromJitaku,
    };
}


