import {FormControlLabel} from "@material-ui/core";
import MuiRadio from "@material-ui/core/Radio";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from "react";
import {trimStrForSpaceBeforeAndAfter} from "common/functions/char_converter/trimStrForSpaceBeforeAndAfter";
import {BgColorsEnum, RadioBoxColorsEnum} from "common/enums_value/ColorsEnum";
import {TextField} from "./TextField";

interface RadioButtonProps {
    label: string;
    labelPlacement?: "top" | "bottom" | "start" | "end";
    className?: string;
    textFieldWidth?: string
    otherTextFieldEnabled?: boolean;
    isError?: boolean | false

    // form
    disabled?: boolean
    checked?: boolean
    value: string;
    valueText?: string;
    handleChange: (value: string) => void
    handleChangeText?: (value: string) => void
    handleBlurText?: (value: string) => void
}

export const RadioButton: FC<RadioButtonProps> = (props) => {
    const classes = useStyles();

    const [textValue, updateTextValue] = React.useState<string>("");

    React.useEffect(() => {
        updateTextValue(props.valueText || "" )
    }, [props.valueText]);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        props.handleChange(e.currentTarget.value)
    }

    function handleChangeText(value: string) {
        updateTextValue(value);
        props.handleChangeText && props.handleChangeText(value)
    }

    function handleBlurText(value: string) {
        updateTextValue(value);
        let trimmedSpace = trimStrForSpaceBeforeAndAfter(value);
        props.handleBlurText && props.handleBlurText(trimmedSpace)
    }

    return (

        <div
            className={`${classes.root} ${props.className}`}
        >

            <FormControlLabel
                value={props.value}
                label={props.label}
                labelPlacement={props.labelPlacement}
                control={
                    <MuiRadio
                        color={"primary"}
                        className={`${props.isError ? classes.error : ""}`}
                        disabled={props.disabled}
                        checked={props.checked}
                        onChange={handleChange}
                    />
                }
            />

            {
                props.otherTextFieldEnabled &&
                <TextField
                    className={"textField"}
                    isError={props.isError}
                    disabled={props.disabled}
                    value={textValue}
                    width={props.textFieldWidth}
                    handleChange={handleChangeText}
                    handleBlur={handleBlurText}
                />
            }

        </div>

    )
};

RadioButton.defaultProps = {
    disabled: false,
    checked: false,
    otherTextFieldEnabled: false,
    valueText: "",
    labelPlacement: "end",
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center",
        "& .MuiRadio-root": {
            padding: 4,
            "& .MuiIconButton-label": {
                "& .MuiSvgIcon-root": {
                    width: 16,
                    height: 16
                },
            },
        },
        "& .MuiRadio-colorPrimary.Mui-checked": {
            color: RadioBoxColorsEnum.Primary
        },
        "& .MuiTypography-root": {
            padding: 4,
        },
        "& .textField": {
            marginRight: 8
        }
    },
    error: {
        color: BgColorsEnum.Red,
    }
});