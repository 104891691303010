import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";
import { getGlobalSyoriki } from "common/functions/globalSyoriki";
import { GakkiEnum } from "common/enums/GakkiEnum";
import { monthToJaAndEn } from "common/functions/monthToJaAndEn";

export enum SyogakukinInfoTextEnum {
    奨学金 = "奨学金",
    名称 = "名称",
    金額 = "金額",
    受給回数 = "受給回数",
    受給期間 = "受給期間",
    説明文 = "説明文",
}

export function SyogakukinInfoTextEnumToNameJaAndEn(e: SyogakukinInfoTextEnum): string {
    switch (e) {
        case SyogakukinInfoTextEnum.奨学金:
            return convertEnumNameToStr({ja: "奨学金", en: "Scholarship"});
        case SyogakukinInfoTextEnum.名称:
            return convertEnumNameToStr({ja: "名称", en: "Title"});
        case SyogakukinInfoTextEnum.金額:
            return convertEnumNameToStr({ja: "金額", en: "Monthly amount"});
        case SyogakukinInfoTextEnum.受給回数:
            return convertEnumNameToStr({ja: "受給回数", en: "Number of receiving"});
        case SyogakukinInfoTextEnum.受給期間:
            return convertEnumNameToStr({ja: "受給期間", en: "Duration of receiving"});
        case SyogakukinInfoTextEnum.説明文:
            const s = getGlobalSyoriki()
            const y = s.yearModel
            const ms = s.gakki == GakkiEnum.前期 ? 4 : 10
            const me = s.gakki == GakkiEnum.前期 ? 3 : 9
            return convertEnumNameToStr({
                ja: `${y.和暦年}${monthToJaAndEn(ms).ja}~${y.inc().和暦年}${monthToJaAndEn(me).ja}に受給する奨学金を記入(貸与奨学金は除く)`,
                en: `Enter scholarships whose receipt has been determined for the period from ${monthToJaAndEn(ms).en} ${y.西暦年} to ${monthToJaAndEn(me).en} ${y.inc().西暦年}.`
            });
    }
}
