/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwKikansErrorMessage,
    SwKikansErrorMessageFromJSON,
    SwKikansErrorMessageFromJSONTyped,
    SwKikansErrorMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerKikanPostErrorMessage
 */
export interface SwOfficerKikanPostErrorMessage {
    /**
     * 
     * @type {SwKikansErrorMessage}
     * @memberof SwOfficerKikanPostErrorMessage
     */
    kikans: SwKikansErrorMessage;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwOfficerKikanPostErrorMessage
     */
    juhuku?: Array<SwErrorMessage>;
}

export function SwOfficerKikanPostErrorMessageFromJSON(json: any): SwOfficerKikanPostErrorMessage {
    return SwOfficerKikanPostErrorMessageFromJSONTyped(json, false);
}

export function SwOfficerKikanPostErrorMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerKikanPostErrorMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kikans': SwKikansErrorMessageFromJSON(json['kikans']),
        'juhuku': !exists(json, 'juhuku') ? undefined : ((json['juhuku'] as Array<any>).map(SwErrorMessageFromJSON)),
    };
}

export function SwOfficerKikanPostErrorMessageToJSON(value?: SwOfficerKikanPostErrorMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kikans': SwKikansErrorMessageToJSON(value.kikans),
        'juhuku': value.juhuku === undefined ? undefined : ((value.juhuku as Array<any>).map(SwErrorMessageToJSON)),
    };
}


