/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwErrorMessage,
    SwErrorMessageFromJSON,
    SwErrorMessageFromJSONTyped,
    SwErrorMessageToJSON,
    SwSyunyuInfoErrorMessages,
    SwSyunyuInfoErrorMessagesFromJSON,
    SwSyunyuInfoErrorMessagesFromJSONTyped,
    SwSyunyuInfoErrorMessagesToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwMemberErrorMessagesSyugakusyaIgai
 */
export interface SwMemberErrorMessagesSyugakusyaIgai {
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusyaIgai
     */
    jobName?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusyaIgai
     */
    jobYears?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusyaIgai
     */
    syunyuAriNasi?: Array<SwErrorMessage>;
    /**
     * 
     * @type {Array<SwErrorMessage>}
     * @memberof SwMemberErrorMessagesSyugakusyaIgai
     */
    kakuteisinkokuAriNasi?: Array<SwErrorMessage>;
    /**
     * 
     * @type {SwSyunyuInfoErrorMessages}
     * @memberof SwMemberErrorMessagesSyugakusyaIgai
     */
    syunyuInfo?: SwSyunyuInfoErrorMessages;
}

export function SwMemberErrorMessagesSyugakusyaIgaiFromJSON(json: any): SwMemberErrorMessagesSyugakusyaIgai {
    return SwMemberErrorMessagesSyugakusyaIgaiFromJSONTyped(json, false);
}

export function SwMemberErrorMessagesSyugakusyaIgaiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwMemberErrorMessagesSyugakusyaIgai {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobName': !exists(json, 'jobName') ? undefined : ((json['jobName'] as Array<any>).map(SwErrorMessageFromJSON)),
        'jobYears': !exists(json, 'jobYears') ? undefined : ((json['jobYears'] as Array<any>).map(SwErrorMessageFromJSON)),
        'syunyuAriNasi': !exists(json, 'syunyuAriNasi') ? undefined : ((json['syunyuAriNasi'] as Array<any>).map(SwErrorMessageFromJSON)),
        'kakuteisinkokuAriNasi': !exists(json, 'kakuteisinkokuAriNasi') ? undefined : ((json['kakuteisinkokuAriNasi'] as Array<any>).map(SwErrorMessageFromJSON)),
        'syunyuInfo': !exists(json, 'syunyuInfo') ? undefined : SwSyunyuInfoErrorMessagesFromJSON(json['syunyuInfo']),
    };
}

export function SwMemberErrorMessagesSyugakusyaIgaiToJSON(value?: SwMemberErrorMessagesSyugakusyaIgai | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobName': value.jobName === undefined ? undefined : ((value.jobName as Array<any>).map(SwErrorMessageToJSON)),
        'jobYears': value.jobYears === undefined ? undefined : ((value.jobYears as Array<any>).map(SwErrorMessageToJSON)),
        'syunyuAriNasi': value.syunyuAriNasi === undefined ? undefined : ((value.syunyuAriNasi as Array<any>).map(SwErrorMessageToJSON)),
        'kakuteisinkokuAriNasi': value.kakuteisinkokuAriNasi === undefined ? undefined : ((value.kakuteisinkokuAriNasi as Array<any>).map(SwErrorMessageToJSON)),
        'syunyuInfo': SwSyunyuInfoErrorMessagesToJSON(value.syunyuInfo),
    };
}


