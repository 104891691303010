/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwAYosikiSyoruisSyoruis,
    SwAYosikiSyoruisSyoruisFromJSON,
    SwAYosikiSyoruisSyoruisFromJSONTyped,
    SwAYosikiSyoruisSyoruisToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwAYosikiSyoruis
 */
export interface SwAYosikiSyoruis {
    /**
     * 
     * @type {SwAYosikiSyoruisSyoruis}
     * @memberof SwAYosikiSyoruis
     */
    syoruis?: SwAYosikiSyoruisSyoruis;
}

export function SwAYosikiSyoruisFromJSON(json: any): SwAYosikiSyoruis {
    return SwAYosikiSyoruisFromJSONTyped(json, false);
}

export function SwAYosikiSyoruisFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwAYosikiSyoruis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syoruis': !exists(json, 'syoruis') ? undefined : SwAYosikiSyoruisSyoruisFromJSON(json['syoruis']),
    };
}

export function SwAYosikiSyoruisToJSON(value?: SwAYosikiSyoruis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'syoruis': SwAYosikiSyoruisSyoruisToJSON(value.syoruis),
    };
}


