// !! backend の enum 情報を元に自動生成されたコードです。手動で書き換えないように!
// !! 修正するには backend 側の enumクラスを修正し、backendルートディレクトリで以下
// !! のコマンドを実行してください(rails/bundlerがinstallされている必要があります)。
//
// $ bin/rails runner script/codegen_enums.rb

export enum NyugakuryoEnum {
    免除 = "E免除",
    猶予 = "E猶予",
    申請しない = "E申請しない",
}

export function nyugakuryoEnumToName(e: NyugakuryoEnum): { ja: string, en: string } {
    switch (e) {
        case NyugakuryoEnum.免除: return { ja: "入学料免除", en: "Applying for enrollment fee waiver" };
        case NyugakuryoEnum.猶予: return { ja: "入学料徴収猶予", en: "Applying for enrollment fee payment deferral" };
        case NyugakuryoEnum.申請しない: return { ja: "申請しない", en: "Not applying" };
    }
}