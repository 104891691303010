import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum SeisekiInfoLabelTextEnum {
    前年度習得単位数 = "前年度習得単位数",
    学部1年生は無記入とし = "学部1年生は無記入とし",
    前年度までの全学年での総修得単位数 = "前年度までの全学年での総修得単位数",
}

export function SeisekiInfoLabelEnumToNameJaAndEn(e: SeisekiInfoLabelTextEnum): string {
    switch (e) {
        case SeisekiInfoLabelTextEnum.前年度習得単位数:
            return convertEnumNameToStr({ja: "前年度習得単位数", en: "Number of Credits Earned in the Previous School Yearr"});
        case SeisekiInfoLabelTextEnum.学部1年生は無記入とし:
            return convertEnumNameToStr({
                ja: "学部1年生は無記入とし、マスター1年生は学部時総修得単位数を、ドクター1年生はマスター時の総修得単位数を入力してください。わからない場合は空欄にしてください。",
                en: "1st-year undergraduate students should leave this item blank, 1st-year Master students should enter the total number of credits acquired while they were undergraduate students, and 1st-year Doctoral students should enter the total number of credits acquired while they were Master students. If you do not know, leave this item blank."
            });
        case SeisekiInfoLabelTextEnum.前年度までの全学年での総修得単位数:
            return convertEnumNameToStr({
                ja: "前年度までの全学年での総修得単位数",
                en: "Total Number of Credits Earned in All School Years Up to the Previous Year"
            });
        default :
            return ""
    }
}