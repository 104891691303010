/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyoruiConfirmationFormHonnin
 */
export interface SyoruiConfirmationFormHonnin {
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonnin
     */
    isTugakuFromJitakuWithoutCertificate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonnin
     */
    isSyotokuwarigaku0yen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonnin
     */
    isKaigoLevel3to6?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonnin
     */
    isTyokiryoyoMoreThan6month?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonnin
     */
    isKokiSinnyuseiEnrolledInJapaneseUniversityBefore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonnin
     */
    isPartTimeJob?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyoruiConfirmationFormHonnin
     */
    isSelectedScholarshipGrant?: boolean;
}

export function SyoruiConfirmationFormHonninFromJSON(json: any): SyoruiConfirmationFormHonnin {
    return SyoruiConfirmationFormHonninFromJSONTyped(json, false);
}

export function SyoruiConfirmationFormHonninFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyoruiConfirmationFormHonnin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isTugakuFromJitakuWithoutCertificate': !exists(json, 'isTugakuFromJitakuWithoutCertificate') ? undefined : json['isTugakuFromJitakuWithoutCertificate'],
        'isSyotokuwarigaku0yen': !exists(json, 'isSyotokuwarigaku0yen') ? undefined : json['isSyotokuwarigaku0yen'],
        'isKaigoLevel3to6': !exists(json, 'isKaigoLevel3to6') ? undefined : json['isKaigoLevel3to6'],
        'isTyokiryoyoMoreThan6month': !exists(json, 'isTyokiryoyoMoreThan6month') ? undefined : json['isTyokiryoyoMoreThan6month'],
        'isKokiSinnyuseiEnrolledInJapaneseUniversityBefore': !exists(json, 'isKokiSinnyuseiEnrolledInJapaneseUniversityBefore') ? undefined : json['isKokiSinnyuseiEnrolledInJapaneseUniversityBefore'],
        'isPartTimeJob': !exists(json, 'isPartTimeJob') ? undefined : json['isPartTimeJob'],
        'isSelectedScholarshipGrant': !exists(json, 'isSelectedScholarshipGrant') ? undefined : json['isSelectedScholarshipGrant'],
    };
}

export function SyoruiConfirmationFormHonninToJSON(value?: SyoruiConfirmationFormHonnin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isTugakuFromJitakuWithoutCertificate': value.isTugakuFromJitakuWithoutCertificate,
        'isSyotokuwarigaku0yen': value.isSyotokuwarigaku0yen,
        'isKaigoLevel3to6': value.isKaigoLevel3to6,
        'isTyokiryoyoMoreThan6month': value.isTyokiryoyoMoreThan6month,
        'isKokiSinnyuseiEnrolledInJapaneseUniversityBefore': value.isKokiSinnyuseiEnrolledInJapaneseUniversityBefore,
        'isPartTimeJob': value.isPartTimeJob,
        'isSelectedScholarshipGrant': value.isSelectedScholarshipGrant,
    };
}


