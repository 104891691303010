import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum SinseisyaInfoTextEnum {
    休学 = "休学",
    休学期間 = "休学期間",
    休学理由 = "休学理由",
    留年 = "留年",
    博士一貫制課程の学生は = "博士一貫制課程の学生は1~5年で入力してください。"
}

export function SinseisyaInfoTextEnumToNameJaAndEn(e: SinseisyaInfoTextEnum): string {
    switch (e) {
        case SinseisyaInfoTextEnum.休学:
            return convertEnumNameToStr({ja: "過去に休学をしましたか？", en: "Did you take a leave of absence in the past?"});
        case SinseisyaInfoTextEnum.休学期間:
            return convertEnumNameToStr({ja: "休学期間", en: "Duration of leave of absence"});
        case SinseisyaInfoTextEnum.休学理由:
            return convertEnumNameToStr({ja: "休学理由", en: "Reason of leave of absence"});
        case SinseisyaInfoTextEnum.留年:
            return convertEnumNameToStr({ja: "過去に留年をしましたか？", en: "Did you repeat years in the past?"});
        case SinseisyaInfoTextEnum.博士一貫制課程の学生は:
            return convertEnumNameToStr({ja: "博士一貫制課程の学生は1~5年で入力してください。", en: "Five-year system, doctoral course students should enter the form in 1-5 years."});
        default :
            return ""
    }
}