/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwOsiraseRaw,
    SwOsiraseRawFromJSON,
    SwOsiraseRawFromJSONTyped,
    SwOsiraseRawToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwOfficerOsirase
 */
export interface SwOfficerOsirase {
    /**
     * 
     * @type {Array<SwOsiraseRaw>}
     * @memberof SwOfficerOsirase
     */
    osirases: Array<SwOsiraseRaw>;
}

export function SwOfficerOsiraseFromJSON(json: any): SwOfficerOsirase {
    return SwOfficerOsiraseFromJSONTyped(json, false);
}

export function SwOfficerOsiraseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerOsirase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'osirases': ((json['osirases'] as Array<any>).map(SwOsiraseRawFromJSON)),
    };
}

export function SwOfficerOsiraseToJSON(value?: SwOfficerOsirase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'osirases': ((value.osirases as Array<any>).map(SwOsiraseRawToJSON)),
    };
}


