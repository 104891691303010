/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SwAccountInfo,
    SwAccountInfoFromJSON,
    SwAccountInfoToJSON,
} from '../models';

/**
 * no description
 */
export class OfficerAccountInfoApi extends runtime.BaseAPI {

    /**
     * 職員のログイン情報を取得
     */
    async getOfficerAccountInfoRaw(): Promise<runtime.ApiResponse<SwAccountInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/officer/account_info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SwAccountInfoFromJSON(jsonValue));
    }

    /**
     * 職員のログイン情報を取得
     */
    async getOfficerAccountInfo(): Promise<SwAccountInfo> {
        const response = await this.getOfficerAccountInfoRaw();
        return await response.value();
    }

}
