import makeStyles from "@material-ui/core/styles/makeStyles";
import {SECTION_ID_NAME} from "common/constant/SECTION_ID_NAME";
import {convertErrorMessageVoToArrayStr} from "common/functions/converter/convertErrorMessageVoToArrayStr";
import {isErrorMessage} from "common/functions/isErrorMessage";
import {JugyoryoInfoVo} from "common/models/YosikiInfoModels";
import {JugyoryoInfoLabelTextEnum, JugyoryoInfoLabelTextEnumToNameJaAndEn} from "common/enums_text/yosiki/JugyoryoInfoLabelTextEnum";
import {JugyoryoInfoTextEnum, JugyoryoInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/JugyoryoInfoTextEnum";
import {NyugakuryoInfoTextEnum, NyugakuryoInfoTextEnumToNameJaAndEn} from "common/enums_text/yosiki/NyugakuryoInfoTextEnum";
import {SectionNameTextEnum, SectionNameTextEnumToNameJaAndEn} from "common/enums_text/yosiki/SectionNameTextEnum";
import {TextField} from "components/atoms/forms/TextField";
import {RequiredTag} from "components/atoms/tags/RequiredTag";
import {AnnotationText} from "components/atoms/AnnotationText";
import {ErrorCountMessage} from "components/atoms/ErrorCountMessage";
import {Title04} from "components/atoms/titles/Title04";
import {FormTable} from "components/molecules/forms/FormTable";
import {FormTableRow} from "components/molecules/forms/FormTableRow";
import {SwJugyoryoInfoErrorMessage} from "openapi/models";
import React, {FC} from "react";
import {RequiredTagTypeEnum} from "common/enums_value/RequiredTagTypeEnum";

interface JugyoryoInfoSectionProps {
    className?: string
    jugyoryoInfo: JugyoryoInfoVo;
    jugyoryoInfoErrorMessage: SwJugyoryoInfoErrorMessage
    updateJugyoryoInfo: (part: Partial<JugyoryoInfoVo>) => void
    updateJugyoryoInfoErrorMessage: (part: Partial<SwJugyoryoInfoErrorMessage>) => void
    errorCount: number
}

export const _JugyoryoInfoSection: FC<JugyoryoInfoSectionProps> = (props) => {

    const classes = useStyles();

    function handleBlurRiyu(value: string) {
        props.updateJugyoryoInfo({riyu: value});
        props.updateJugyoryoInfoErrorMessage({riyu: undefined})
    }

    const jugyoryoInfo: JugyoryoInfoVo = props.jugyoryoInfo;
    const jugyoryoInfoErrorMessage: SwJugyoryoInfoErrorMessage = props.jugyoryoInfoErrorMessage;

    return (
        <div id={SECTION_ID_NAME.jugyoryoInfo} className={`${classes.root} ${props.className}`}>

            <Title04
                title={SectionNameTextEnumToNameJaAndEn(SectionNameTextEnum.授業料免除徴収猶予申請理由)}
                className={"section_title"}
            />

            <ErrorCountMessage
                count={props.errorCount}
            />

            <FormTable
                className={"section_table"}
            >
                <FormTableRow
                    label={JugyoryoInfoLabelTextEnumToNameJaAndEn(JugyoryoInfoLabelTextEnum.免除または徴収猶予の理由)}
                >
                    <div className={"formTableRow_item"}>
                        <div className={"formTableRow_item_label"}>
                            {JugyoryoInfoTextEnumToNameJaAndEn(JugyoryoInfoTextEnum.どういう原因で)}<RequiredTag
                            type={RequiredTagTypeEnum.必須}/>
                        </div>
                        <AnnotationText className={"mb4"} text={NyugakuryoInfoTextEnumToNameJaAndEn(NyugakuryoInfoTextEnum.字以上入力してください)}/>
                        <TextField
                            rows={8}
                            multiline={true}
                            value={jugyoryoInfo.riyu}
                            errorMessages={convertErrorMessageVoToArrayStr(jugyoryoInfoErrorMessage.riyu)}
                            isError={isErrorMessage(jugyoryoInfoErrorMessage.riyu)}
                            handleBlur={handleBlurRiyu}
                        />
                    </div>
                </FormTableRow>

            </FormTable>

        </div>

    )
};

export const JugyoryoInfoSection = React.memo<JugyoryoInfoSectionProps>(_JugyoryoInfoSection, (prev, next) => {
    return prev.className === next.className
        && prev.jugyoryoInfo === next.jugyoryoInfo
        && prev.jugyoryoInfoErrorMessage === next.jugyoryoInfoErrorMessage
        && prev.updateJugyoryoInfo === next.updateJugyoryoInfo
        && prev.updateJugyoryoInfoErrorMessage === next.updateJugyoryoInfoErrorMessage
        && prev.errorCount === next.errorCount
});

const useStyles = makeStyles({
        root: {},
    }
);

