import {convertEnumNameToStr} from "common/functions/converter/convertEnumNameToStr";

export enum SectionNameTextEnum {
    様式1基本情報 = "様式1基本情報",
    申請者情報 = "申請者情報",
    奨学金情報 = "奨学金情報",
    学業成績 = "学業成績",
    家庭状況 = "家庭状況",
    連帯保証人 = "連帯保証人",
    入学料免除徴収猶予申請理由 = "入学料免除・徴収猶予申請理由",
    授業料免除徴収猶予申請理由 = "授業料免除・徴収猶予申請理由",
    A様式1 = "A様式1",
    特別控除 = "特別控除",
}

export function SectionNameTextEnumToNameJaAndEn(e: SectionNameTextEnum): string {
    switch (e) {
        case SectionNameTextEnum.様式1基本情報:
            return convertEnumNameToStr({ja: "様式1基本情報", en: "Basic Information"});
        case SectionNameTextEnum.申請者情報:
            return convertEnumNameToStr({ja: "申請者情報", en: "Applicant Information"});
        case SectionNameTextEnum.奨学金情報:
            return convertEnumNameToStr({ja: "奨学金情報", en: "scholarships Information"});
        case SectionNameTextEnum.学業成績:
            return convertEnumNameToStr({ja: "学業成績", en: "Academic Performance"});
        case SectionNameTextEnum.家庭状況:
            return convertEnumNameToStr({ja: "家庭状況", en: "Household Finances"});
        case SectionNameTextEnum.連帯保証人:
            return convertEnumNameToStr({ja: "連帯保証人", en: "Guarantor"});
        case SectionNameTextEnum.入学料免除徴収猶予申請理由:
            return convertEnumNameToStr({ja: "入学料免除徴収猶予申請理由", en: "Reason for Enrollment Fee Waiver/Deferral"});
        case SectionNameTextEnum.授業料免除徴収猶予申請理由:
            return convertEnumNameToStr({ja: "授業料免除徴収猶予申請理由", en: "Reason for Tuition Waiver/Deferral"});
        case SectionNameTextEnum.A様式1:
            return convertEnumNameToStr({ja: "A様式1", en: "A style 1"});
        case SectionNameTextEnum.特別控除:
            return convertEnumNameToStr({ja: "特別控除", en: "Special Deductions"});
        default:
            return ""
    }
}