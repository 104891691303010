/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwKikan,
    SwKikanFromJSON,
    SwKikanFromJSONTyped,
    SwKikanToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwKikans
 */
export interface SwKikans {
    /**
     * 
     * @type {SwKikan}
     * @memberof SwKikans
     */
    nyuryokuKikan: SwKikan;
    /**
     * 
     * @type {SwKikan}
     * @memberof SwKikans
     */
    sinseiKikan: SwKikan;
    /**
     * 
     * @type {SwKikan}
     * @memberof SwKikans
     */
    teiseiKikan: SwKikan;
}

export function SwKikansFromJSON(json: any): SwKikans {
    return SwKikansFromJSONTyped(json, false);
}

export function SwKikansFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwKikans {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nyuryokuKikan': SwKikanFromJSON(json['nyuryokuKikan']),
        'sinseiKikan': SwKikanFromJSON(json['sinseiKikan']),
        'teiseiKikan': SwKikanFromJSON(json['teiseiKikan']),
    };
}

export function SwKikansToJSON(value?: SwKikans | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nyuryokuKikan': SwKikanToJSON(value.nyuryokuKikan),
        'sinseiKikan': SwKikanToJSON(value.sinseiKikan),
        'teiseiKikan': SwKikanToJSON(value.teiseiKikan),
    };
}


