/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwInfoPolicyRequireSinseisyaInfo,
    SwInfoPolicyRequireSinseisyaInfoFromJSON,
    SwInfoPolicyRequireSinseisyaInfoFromJSONTyped,
    SwInfoPolicyRequireSinseisyaInfoToJSON,
    SwInfoPolicyRequireTokubetuKojoInfo,
    SwInfoPolicyRequireTokubetuKojoInfoFromJSON,
    SwInfoPolicyRequireTokubetuKojoInfoFromJSONTyped,
    SwInfoPolicyRequireTokubetuKojoInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwInfoPolicy
 */
export interface SwInfoPolicy {
    /**
     * 
     * @type {SwInfoPolicyRequireSinseisyaInfo}
     * @memberof SwInfoPolicy
     */
    requireSinseisyaInfo: SwInfoPolicyRequireSinseisyaInfo;
    /**
     * 
     * @type {boolean}
     * @memberof SwInfoPolicy
     */
    requireNyugakuryoInfo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SwInfoPolicy
     */
    requireJugyoryoInfo: boolean;
    /**
     * 
     * @type {SwInfoPolicyRequireTokubetuKojoInfo}
     * @memberof SwInfoPolicy
     */
    requireTokubetuKojoInfo: SwInfoPolicyRequireTokubetuKojoInfo;
}

export function SwInfoPolicyFromJSON(json: any): SwInfoPolicy {
    return SwInfoPolicyFromJSONTyped(json, false);
}

export function SwInfoPolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwInfoPolicy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requireSinseisyaInfo': SwInfoPolicyRequireSinseisyaInfoFromJSON(json['requireSinseisyaInfo']),
        'requireNyugakuryoInfo': json['requireNyugakuryoInfo'],
        'requireJugyoryoInfo': json['requireJugyoryoInfo'],
        'requireTokubetuKojoInfo': SwInfoPolicyRequireTokubetuKojoInfoFromJSON(json['requireTokubetuKojoInfo']),
    };
}

export function SwInfoPolicyToJSON(value?: SwInfoPolicy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requireSinseisyaInfo': SwInfoPolicyRequireSinseisyaInfoToJSON(value.requireSinseisyaInfo),
        'requireNyugakuryoInfo': value.requireNyugakuryoInfo,
        'requireJugyoryoInfo': value.requireJugyoryoInfo,
        'requireTokubetuKojoInfo': SwInfoPolicyRequireTokubetuKojoInfoToJSON(value.requireTokubetuKojoInfo),
    };
}


