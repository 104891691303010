import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from "react";
import {TextColorsEnum} from "common/enums_value/ColorsEnum";

interface ErrorCountMessageProps {
    count: number;
    className?: string;
}

export const _ErrorCountMessage: FC<ErrorCountMessageProps> = (props) => {

    const classes = useStyles();
    if (props.count === 0) return <></>;

    return (
        <div className={`${props.className} ${classes.root}`}>
            {`${props.count}件のエラーがあります。 / There is ${props.count} error.`}
        </div>
    )
};

export const ErrorCountMessage = React.memo<ErrorCountMessageProps>(_ErrorCountMessage, (prev, next) => {
    return prev.className === next.className
        && prev.count === next.count
});

const useStyles = makeStyles({
    root: {
        marginTop: 4,
        fontSize: 16,
        fontWeight: 700,
        color: TextColorsEnum.Red
    },
});