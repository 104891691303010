/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwOfficerLoginRequest
 */
export interface SwOfficerLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof SwOfficerLoginRequest
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof SwOfficerLoginRequest
     */
    password: string;
}

export function SwOfficerLoginRequestFromJSON(json: any): SwOfficerLoginRequest {
    return SwOfficerLoginRequestFromJSONTyped(json, false);
}

export function SwOfficerLoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwOfficerLoginRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': json['account'],
        'password': json['password'],
    };
}

export function SwOfficerLoginRequestToJSON(value?: SwOfficerLoginRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': value.account,
        'password': value.password,
    };
}


