import React from 'react';

export const ModalContentSyugakusyaRan: React.FC = () => {
    return (

        <div className={"modalContent"}>

            <div className={"modalContent_item"}>
                「各種学校等の在学者」は、<span className={"underLine"}>就学者以外</span>を選択すること。
                「就学者」と「各種学校等の在学生」との 区分は以下のとおりです。

                <div className={"mt8"}>
                    <div className={"fw700"}>就学者に該当する学校</div>
                    小学校、中学校、高等学校、高等専門学校(高専)、大学 (大学院、専攻科、短期大学を含む)、専修学校(高等課程、専門課程)等
                </div>

                <div className={"mt8"}>
                    <div className={"fw700"}>各種学校等</div>
                    各種学校(予備校等)、大学の研究生・聴講生・科目等履修生、専門学校(一般課程)、インターナショナルスクール、幼稚園、保育園等
                </div>

            </div>

            <div className={"modalContent_item"}>
                “Enrollees in various schools” must select the <span
                className={"underLine"}>“Other than student” field.</span>

                <div className={"mt8"}>
                    <div className={"fw700"}>Schools in which “students” are enrolled</div>
                    Ellementary schools, junior highschools, high schools, colleges of technology,universities
                    (including graduate schools, non-degree courses for graduates, and juniorrcolleges), specialized
                    training colleges (upper secondary courses, post secondary courses), etc.
                </div>

                <div className={"mt8"}>
                    <div className={"fw700"}>Various schools</div>
                    Various types of schools (such as preparatory schools); research students, auditors, and credited
                    auditors of universities;professional training colleges (general courses); intternational schools;
                    kindergartens; nursery schools; etc.
                </div>

            </div>

        </div>

    );
};
