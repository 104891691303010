/* tslint:disable */
/* eslint-disable */
/**
 * sample API
 * sample API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SwBosiHusiParent,
    SwBosiHusiParentFromJSON,
    SwBosiHusiParentFromJSONTyped,
    SwBosiHusiParentToJSON,
} from './';

/**
 * 
 * @export
 * @interface SwBosiHusi
 */
export interface SwBosiHusi {
    /**
     * 
     * @type {SwBosiHusiParent}
     * @memberof SwBosiHusi
     */
    mother?: SwBosiHusiParent;
    /**
     * 
     * @type {SwBosiHusiParent}
     * @memberof SwBosiHusi
     */
    father?: SwBosiHusiParent;
}

export function SwBosiHusiFromJSON(json: any): SwBosiHusi {
    return SwBosiHusiFromJSONTyped(json, false);
}

export function SwBosiHusiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwBosiHusi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mother': !exists(json, 'mother') ? undefined : SwBosiHusiParentFromJSON(json['mother']),
        'father': !exists(json, 'father') ? undefined : SwBosiHusiParentFromJSON(json['father']),
    };
}

export function SwBosiHusiToJSON(value?: SwBosiHusi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mother': SwBosiHusiParentToJSON(value.mother),
        'father': SwBosiHusiParentToJSON(value.father),
    };
}


